// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class StatusEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  UNREADY("unready", 1),

  FAILED("failed", 2),

  READY("ready", 3),

  PUBLISHED("published", 4);
}

internal class StatusEnumSerializer : KSerializer<StatusEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.videos.StatusEnum")

  override fun serialize(encoder: Encoder, value: StatusEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): StatusEnum {
    if (decoder is JsonDecoder) {
      return StatusEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> StatusEnum.UNSUPPORTED_PLACEHOLDER
      1 -> StatusEnum.UNREADY
      2 -> StatusEnum.FAILED
      3 -> StatusEnum.READY
      4 -> StatusEnum.PUBLISHED
      else -> StatusEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
