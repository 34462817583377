package youversion.red.images.api

import red.platform.http.ContentTypes
import red.platform.http.queryString
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.images.model.ImageMetadata
import youversion.red.images.model.Images

internal object ImagesApi : AbstractApi(ApiDefaults("images", ContentTypes.PROTO, ContentTypes.PROTO, "3.2")) {

    suspend fun getImage(id: Int): ImageMetadata? = execute(
        "/view.proto",
        queryString = "id=$id",
        serializer = ImageMetadata.serializer(),
        authOptional = true
    )

    suspend fun getImages(
        usfms: List<String>,
        languageTag: String?,
        category: String?,
        orientation: String?,
        page: Int = -1
    ) = execute(
        "/items.proto",
        authAllowed = false,
        queryString = queryString {
            usfms.takeIf { it.isNotEmpty() }?.let { addStrings("usfm", usfms) }
            languageTag?.takeIf { it.isNotEmpty() }?.let { add("language_tag", languageTag) }
            category?.takeIf { it.isNotEmpty() }?.let { add("category", category) }
            orientation?.takeIf { it.isNotEmpty() }?.let { add("orientation", orientation) }
            if (page > 0)
                add("page", page)
        },
        serializer = Images.serializer()
    )
}
