package youversion.red.churches.service

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.onStart
import red.service.DefaultService
import red.service.ServiceRegistry
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.churches.service.repository.OrganizationsRepository
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.plans.model.Plan
import youversion.red.security.service.IUsersService

@DefaultService(IChurchesService::class)
internal class ChurchesServiceImpl : IChurchesService {

    private val usersService = ServiceRegistry[IUsersService::class]

    private val _savedOrganization = MutableStateFlow<Organization?>(null)
    override val savedOrganization: Flow<Organization?> = _savedOrganization.onStart {
        if (_savedOrganization.value == null) {
            try {
                usersService.getCurrentUser()?.id?.let { id ->
                    _savedOrganization.value = OrganizationsRepository.getOrganizations(id).firstOrNull()
                    emit(_savedOrganization.value)
                }
            } catch (_: Exception) {
            }
        }
    }

    override suspend fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?) = withIO {
        try {
            OrganizationsRepository.saveOrganization(id, visibilityPreference)
            _savedOrganization.value = usersService.getCurrentUser()?.id?.let { id ->
                OrganizationsRepository.getOrganizations(id).firstOrNull()
            }
        } catch (_: Exception) {
        }
    }

    override suspend fun leaveOrganization(id: String) {
        OrganizationsRepository.leaveOrganization(id)
        _savedOrganization.value = null
    }

    override fun getFeaturedPlan(organizationId: String): Flow<Plan?> {
        return OrganizationsRepository.getFeaturedPlan(organizationId)
    }

    override fun getOrganizations(userId: Int?): Flow<List<Organization>> = flow {
        val user = userId ?: usersService.getCurrentUser()?.id
        if (user != null) {
            emit(OrganizationsRepository.getOrganizations(user))
        }
    }

    override fun getOrganization(id: String): Flow<Organization?> = OrganizationsRepository.getOrganization(id)

    override fun getProfiles(organizationId: String): Flow<List<BaseOrganizationProfile>> =
        OrganizationsRepository.getOrganizationProfiles(organizationId)

    override suspend fun getOrganizationSync(id: String): Organization? =
        OrganizationsRepository.suspendGetOrganization(id)

    override fun getLocations(organizationId: String, latitude: Double?, longitude: Double?): Flow<List<Location>> =
        OrganizationsRepository.getLocations(organizationId, latitude, longitude)

    override fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>> =
        OrganizationsRepository.getChurchServiceTimes(organizationId)

    override fun getChurchFriends(organizationId: String, userId: Int?): Flow<List<Int>> = flow {
        val user = userId ?: usersService.getCurrentUser()?.id
        user?.let {
            emit(OrganizationsRepository.getChurchFriends(organizationId, user))
        }
    }

    override fun getMembersCount(organizationId: String): Flow<Int> =
        OrganizationsRepository.getMembersCount(organizationId)
}
