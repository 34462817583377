// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class OrganizationProfileView(
  /**
   * Id of the organization that was viewed
   */
  @SerialName("organization_id")
  @ProtoNumber(1)
  val organizationId: String = "",
  @SerialName("referrer")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.organizations.api.model.organizations.OrganizationProfileReferrerSerializer::class)
  val referrer: OrganizationProfileReferrer? = null
) : Event(), red.Serializable {
  override val key: String = "OrganizationProfileView"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("organization_id", organizationId)
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    return bundle
  }
}
