package youversion.red.animations.service.js

import kotlin.Int
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.animations.api.model.Animation
import youversion.red.animations.service.IAnimationsService

public object AnimationsService {
  private val service: IAnimationsService by
      service<youversion.red.animations.service.IAnimationsService>()

  @JsName(name = "getAnimation")
  public fun getAnimation(id: Int): Promise<Animation?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getAnimation(id)

  }.toPromise()
}
