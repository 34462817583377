package youversion.red.prayer.guided.model

import red.platform.threads.freeze

internal class PrayerListCandidates(
    private val prayerListIds: List<String>,
    private val sessionLifecycleCache: ViewedPrayersCache,
    private val prayerListLifecycleCache: ViewedPrayersCache
) {

    init {
        freeze()
    }

    val remaining: Int
        get() = prayerListIds.minus(sessionLifecycleCache.ids).size

    fun consume(batchSize: Int): List<String> {
        val batch = mutableListOf<String>()
        val lastShownId = sessionLifecycleCache.ids.lastOrNull() ?: prayerListLifecycleCache.ids.lastOrNull()
        val lastShownIndex = prayerListIds.indexOf(lastShownId) + 1
        for (i in prayerListIds.indices) {
            val index = (lastShownIndex + i) % (prayerListIds.size)
            val id = prayerListIds[index]
            if (batch.size < batchSize && id !in sessionLifecycleCache.ids && id !in prayerListLifecycleCache.ids) {
                batch.add(id)
                removeCandidate(id)
            }
            if (batch.size >= batchSize)
                break
        }
        return batch
    }

    private fun removeCandidate(id: String) {
        sessionLifecycleCache.ids = sessionLifecycleCache.ids.plus(id)
        prayerListLifecycleCache.ids = prayerListLifecycleCache.ids.plus(id)
        if (prayerListIds.minus(prayerListLifecycleCache.ids).isEmpty()) {
            prayerListLifecycleCache.clear()
        }
    }
}
