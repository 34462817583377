package youversion.red.prayer.db.prayer

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import red.platform.Date
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.db.Prayer
import youversion.red.prayer.db.PrayerDatabase
import youversion.red.prayer.db.PrayerQueries
import youversion.red.prayer.db.Prayer_comment
import youversion.red.prayer.db.Prayer_reaction
import youversion.red.prayer.db.Prayer_share
import youversion.red.prayer.db.Prayer_update
import youversion.red.prayer.db.Prayer_users

internal val KClass<PrayerDatabase>.schema: SqlDriver.Schema
  get() = PrayerDatabaseImpl.Schema

internal fun KClass<PrayerDatabase>.newInstance(
  driver: SqlDriver,
  prayerAdapter: Prayer.Adapter,
  prayer_commentAdapter: Prayer_comment.Adapter,
  prayer_reactionAdapter: Prayer_reaction.Adapter,
  prayer_shareAdapter: Prayer_share.Adapter,
  prayer_updateAdapter: Prayer_update.Adapter,
  prayer_usersAdapter: Prayer_users.Adapter
): PrayerDatabase = PrayerDatabaseImpl(driver, prayerAdapter, prayer_commentAdapter,
    prayer_reactionAdapter, prayer_shareAdapter, prayer_updateAdapter, prayer_usersAdapter)

private class PrayerDatabaseImpl(
  driver: SqlDriver,
  internal val prayerAdapter: Prayer.Adapter,
  internal val prayer_commentAdapter: Prayer_comment.Adapter,
  internal val prayer_reactionAdapter: Prayer_reaction.Adapter,
  internal val prayer_shareAdapter: Prayer_share.Adapter,
  internal val prayer_updateAdapter: Prayer_update.Adapter,
  internal val prayer_usersAdapter: Prayer_users.Adapter
) : TransacterImpl(driver), PrayerDatabase {
  public override val prayerQueries: PrayerQueriesImpl = PrayerQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 3

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE prayer (
          |    clientId TEXT NOT NULL PRIMARY KEY,
          |    serverId INTEGER,
          |    title TEXT,
          |    userId INTEGER,
          |    answeredDt INTEGER,
          |    createdDt INTEGER,
          |    updatedDt INTEGER,
          |    content TEXT,
          |    sharingPolicy TEXT,
          |    status TEXT,
          |    lastPrayerUpdate INTEGER,
          |    usfm TEXT,
          |    versionId INTEGER,
          |    reactionCount INTEGER,
          |    fromUsers TEXT,
          |    seenUpdate INTEGER,
          |    updated INTEGER,
          |    state INTEGER,
          |    lastSync INTEGER,
          |    orderIndex INTEGER,
          |    commentEnabled INTEGER
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE prayer_reaction(
          |    prayerClientId TEXT NOT NULL,
          |    prayerServerId INTEGER,
          |    userId INTEGER NOT NULL,
          |    total INTEGER NOT NULL DEFAULT 0,
          |    needsSyncing INTEGER NOT NULL DEFAULT 0,
          |    updatedDt INTEGER NOT NULL,
          |    PRIMARY KEY (userId, prayerClientId),
          |    FOREIGN KEY (prayerClientId) REFERENCES prayer(clientId) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE prayer_users(
          |    userId INTEGER NOT NULL PRIMARY KEY,
          |    updated INTEGER DEFAULT 0,
          |    lastSync INTEGER,
          |    orderIndex INTEGER
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE prayer_update(
          |    prayerClientId TEXT NOT NULL,
          |    prayerServerId INTEGER,
          |    clientId TEXT NOT NULL PRIMARY KEY,
          |    serverId INTEGER,
          |    message TEXT,
          |    updatedDt INTEGER,
          |    createdDt INTEGER,
          |    state INTEGER,
          |    lastSync INTEGER,
          |    FOREIGN KEY (prayerClientId) REFERENCES prayer(clientId) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE prayer_share(
          |    id INTEGER NOT NULL PRIMARY KEY,
          |    prayerServerId INTEGER,
          |    prayerClientId TEXT NOT NULL,
          |    createdDt INTEGER,
          |    message TEXT,
          |    receiverId INTEGER,
          |    senderId INTEGER,
          |    FOREIGN KEY (prayerClientId) REFERENCES prayer(clientId) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE prayer_comment(
          |    prayerClientId TEXT NOT NULL,
          |    prayerServerId INTEGER,
          |    clientId TEXT NOT NULL PRIMARY KEY,
          |    serverId INTEGER,
          |    updatedDt INTEGER,
          |    createdDt INTEGER,
          |    message TEXT,
          |    userId INTEGER,
          |    state INTEGER,
          |    lastSync INTEGER,
          |    FOREIGN KEY (prayerClientId) REFERENCES prayer(clientId) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
      if (oldVersion <= 2 && newVersion > 2) {
        driver.execute(null, """
            |CREATE TABLE prayer_comment(
            |    prayerClientId TEXT NOT NULL,
            |    prayerServerId INTEGER,
            |    clientId TEXT NOT NULL PRIMARY KEY,
            |    serverId INTEGER,
            |    updatedDt INTEGER,
            |    createdDt INTEGER,
            |    message TEXT,
            |    userId INTEGER,
            |    state INTEGER,
            |    lastSync INTEGER,
            |    FOREIGN KEY (prayerClientId) REFERENCES prayer(clientId) ON DELETE CASCADE
            |)
            """.trimMargin(), 0)
        driver.execute(null, "ALTER TABLE prayer ADD COLUMN commentEnabled INTEGER", 0)
      }
    }
  }
}

private class PrayerQueriesImpl(
  private val database: PrayerDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), PrayerQueries {
  internal val getPrayer: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerByServerId: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayersByStatus: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayersByState: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayersByUserId: MutableList<Query<*>> = copyOnWriteList()

  internal val getAnsweredPrayers: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerList: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayersNeedingUpdate: MutableList<Query<*>> = copyOnWriteList()

  internal val getReactions: MutableList<Query<*>> = copyOnWriteList()

  internal val getReactionsByPrayerClientId: MutableList<Query<*>> = copyOnWriteList()

  internal val getReactionsUserIds: MutableList<Query<*>> = copyOnWriteList()

  internal val getDirtyReactions: MutableList<Query<*>> = copyOnWriteList()

  internal val getReaction: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerUsers: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerUser: MutableList<Query<*>> = copyOnWriteList()

  internal val hasUnread: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerUpdatesByPrayerId: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerUpdatesByState: MutableList<Query<*>> = copyOnWriteList()

  internal val getPrayerUpdate: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllUpdates: MutableList<Query<*>> = copyOnWriteList()

  internal val getShares: MutableList<Query<*>> = copyOnWriteList()

  internal val getShare: MutableList<Query<*>> = copyOnWriteList()

  internal val getComments: MutableList<Query<*>> = copyOnWriteList()

  internal val getCommentsByState: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getPrayer(prayerClientId: String, mapper: (
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ) -> T): Query<T> = GetPrayerQuery(prayerClientId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayer(prayerClientId: String): Query<Prayer> = getPrayer(prayerClientId) {
      clientId, serverId, title, userId, answeredDt, createdDt, updatedDt, content, sharingPolicy,
      status, lastPrayerUpdate, usfm, versionId, reactionCount, fromUsers, seenUpdate, updated,
      state, lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayerByServerId(prayerServerId: Int?, mapper: (
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ) -> T): Query<T> = GetPrayerByServerIdQuery(prayerServerId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayerByServerId(prayerServerId: Int?): Query<Prayer> =
      getPrayerByServerId(prayerServerId) { clientId, serverId, title, userId, answeredDt,
      createdDt, updatedDt, content, sharingPolicy, status, lastPrayerUpdate, usfm, versionId,
      reactionCount, fromUsers, seenUpdate, updated, state, lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayersByStatus(
    prayerStatus: StatusType?,
    stateDeleted: Int?,
    mapper: (
      clientId: String,
      serverId: Int?,
      title: String?,
      userId: Long?,
      answeredDt: Date?,
      createdDt: Date?,
      updatedDt: Date?,
      content: String?,
      sharingPolicy: SharingPolicy?,
      status: StatusType?,
      lastPrayerUpdate: Date?,
      usfm: String?,
      versionId: Int?,
      reactionCount: Int?,
      fromUsers: List<Long>?,
      seenUpdate: Date?,
      updated: Boolean?,
      state: Int?,
      lastSync: Date?,
      orderIndex: Int?,
      commentEnabled: Boolean?
    ) -> T
  ): Query<T> = GetPrayersByStatusQuery(prayerStatus, stateDeleted) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayersByStatus(prayerStatus: StatusType?, stateDeleted: Int?):
      Query<Prayer> = getPrayersByStatus(prayerStatus, stateDeleted) { clientId, serverId, title,
      userId, answeredDt, createdDt, updatedDt, content, sharingPolicy, status, lastPrayerUpdate,
      usfm, versionId, reactionCount, fromUsers, seenUpdate, updated, state, lastSync, orderIndex,
      commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayersByState(prayerState: Int?, mapper: (
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ) -> T): Query<T> = GetPrayersByStateQuery(prayerState) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayersByState(prayerState: Int?): Query<Prayer> =
      getPrayersByState(prayerState) { clientId, serverId, title, userId, answeredDt, createdDt,
      updatedDt, content, sharingPolicy, status, lastPrayerUpdate, usfm, versionId, reactionCount,
      fromUsers, seenUpdate, updated, state, lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayersByUserId(prayerUserId: Long?, mapper: (
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ) -> T): Query<T> = GetPrayersByUserIdQuery(prayerUserId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayersByUserId(prayerUserId: Long?): Query<Prayer> =
      getPrayersByUserId(prayerUserId) { clientId, serverId, title, userId, answeredDt, createdDt,
      updatedDt, content, sharingPolicy, status, lastPrayerUpdate, usfm, versionId, reactionCount,
      fromUsers, seenUpdate, updated, state, lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getAnsweredPrayers(mapper: (
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ) -> T): Query<T> = Query(1409138741, getAnsweredPrayers, driver, "Prayer.sq",
      "getAnsweredPrayers", "SELECT * FROM prayer WHERE answeredDt > 0 ORDER BY createdDt DESC") {
      cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getAnsweredPrayers(): Query<Prayer> = getAnsweredPrayers { clientId, serverId,
      title, userId, answeredDt, createdDt, updatedDt, content, sharingPolicy, status,
      lastPrayerUpdate, usfm, versionId, reactionCount, fromUsers, seenUpdate, updated, state,
      lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayerList(
    stateDeleted: Int?,
    statusActive: StatusType?,
    mapper: (
      clientId: String,
      serverId: Int?,
      title: String?,
      userId: Long?,
      answeredDt: Date?,
      createdDt: Date?,
      updatedDt: Date?,
      content: String?,
      sharingPolicy: SharingPolicy?,
      status: StatusType?,
      lastPrayerUpdate: Date?,
      usfm: String?,
      versionId: Int?,
      reactionCount: Int?,
      fromUsers: List<Long>?,
      seenUpdate: Date?,
      updated: Boolean?,
      state: Int?,
      lastSync: Date?,
      orderIndex: Int?,
      commentEnabled: Boolean?
    ) -> T
  ): Query<T> = GetPrayerListQuery(stateDeleted, statusActive) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayerList(stateDeleted: Int?, statusActive: StatusType?): Query<Prayer> =
      getPrayerList(stateDeleted, statusActive) { clientId, serverId, title, userId, answeredDt,
      createdDt, updatedDt, content, sharingPolicy, status, lastPrayerUpdate, usfm, versionId,
      reactionCount, fromUsers, seenUpdate, updated, state, lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getPrayersNeedingUpdate(
    stateDeleted: Int?,
    statusActive: StatusType?,
    now: Date?,
    mapper: (
      clientId: String,
      serverId: Int?,
      title: String?,
      userId: Long?,
      answeredDt: Date?,
      createdDt: Date?,
      updatedDt: Date?,
      content: String?,
      sharingPolicy: SharingPolicy?,
      status: StatusType?,
      lastPrayerUpdate: Date?,
      usfm: String?,
      versionId: Int?,
      reactionCount: Int?,
      fromUsers: List<Long>?,
      seenUpdate: Date?,
      updated: Boolean?,
      state: Int?,
      lastSync: Date?,
      orderIndex: Int?,
      commentEnabled: Boolean?
    ) -> T
  ): Query<T> = GetPrayersNeedingUpdateQuery(stateDeleted, statusActive, now) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2),
      cursor.getLong(3),
      cursor.getLong(4)?.let { database.prayerAdapter.answeredDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayerAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayerAdapter.updatedDtAdapter.decode(it) },
      cursor.getString(7),
      cursor.getString(8)?.let { database.prayerAdapter.sharingPolicyAdapter.decode(it) },
      cursor.getString(9)?.let { database.prayerAdapter.statusAdapter.decode(it) },
      cursor.getLong(10)?.let { database.prayerAdapter.lastPrayerUpdateAdapter.decode(it) },
      cursor.getString(11),
      cursor.getLong(12)?.toInt(),
      cursor.getLong(13)?.toInt(),
      cursor.getString(14)?.let { database.prayerAdapter.fromUsersAdapter.decode(it) },
      cursor.getLong(15)?.let { database.prayerAdapter.seenUpdateAdapter.decode(it) },
      cursor.getLong(16)?.let { it == 1L },
      cursor.getLong(17)?.toInt(),
      cursor.getLong(18)?.let { database.prayerAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(19)?.toInt(),
      cursor.getLong(20)?.let { it == 1L }
    )
  }

  public override fun getPrayersNeedingUpdate(
    stateDeleted: Int?,
    statusActive: StatusType?,
    now: Date?
  ): Query<Prayer> = getPrayersNeedingUpdate(stateDeleted, statusActive, now) { clientId, serverId,
      title, userId, answeredDt, createdDt, updatedDt, content, sharingPolicy, status,
      lastPrayerUpdate, usfm, versionId, reactionCount, fromUsers, seenUpdate, updated, state,
      lastSync, orderIndex, commentEnabled ->
    Prayer(
      clientId,
      serverId,
      title,
      userId,
      answeredDt,
      createdDt,
      updatedDt,
      content,
      sharingPolicy,
      status,
      lastPrayerUpdate,
      usfm,
      versionId,
      reactionCount,
      fromUsers,
      seenUpdate,
      updated,
      state,
      lastSync,
      orderIndex,
      commentEnabled
    )
  }

  public override fun <T : Any> getReactions(mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    userId: Long,
    total: Int,
    needsSyncing: Boolean,
    updatedDt: Date
  ) -> T): Query<T> = Query(1579227728, getReactions, driver, "Prayer.sq", "getReactions",
      "SELECT * FROM prayer_reaction") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getLong(2)!!,
      cursor.getLong(3)!!.toInt(),
      cursor.getLong(4)!! == 1L,
      database.prayer_reactionAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getReactions(): Query<Prayer_reaction> = getReactions { prayerClientId,
      prayerServerId, userId, total, needsSyncing, updatedDt ->
    Prayer_reaction(
      prayerClientId,
      prayerServerId,
      userId,
      total,
      needsSyncing,
      updatedDt
    )
  }

  public override fun <T : Any> getReactionsByPrayerClientId(clientId: String, mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    userId: Long,
    total: Int,
    needsSyncing: Boolean,
    updatedDt: Date
  ) -> T): Query<T> = GetReactionsByPrayerClientIdQuery(clientId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getLong(2)!!,
      cursor.getLong(3)!!.toInt(),
      cursor.getLong(4)!! == 1L,
      database.prayer_reactionAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getReactionsByPrayerClientId(clientId: String): Query<Prayer_reaction> =
      getReactionsByPrayerClientId(clientId) { prayerClientId, prayerServerId, userId, total,
      needsSyncing, updatedDt ->
    Prayer_reaction(
      prayerClientId,
      prayerServerId,
      userId,
      total,
      needsSyncing,
      updatedDt
    )
  }

  public override fun getReactionsUserIds(clientId: String): Query<Long> =
      GetReactionsUserIdsQuery(clientId) { cursor ->
    cursor.getLong(0)!!
  }

  public override fun <T : Any> getDirtyReactions(mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    userId: Long,
    total: Int,
    needsSyncing: Boolean,
    updatedDt: Date
  ) -> T): Query<T> = Query(-1694710638, getDirtyReactions, driver, "Prayer.sq",
      "getDirtyReactions", "SELECT * FROM prayer_reaction WHERE needsSyncing = 1") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getLong(2)!!,
      cursor.getLong(3)!!.toInt(),
      cursor.getLong(4)!! == 1L,
      database.prayer_reactionAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getDirtyReactions(): Query<Prayer_reaction> = getDirtyReactions {
      prayerClientId, prayerServerId, userId, total, needsSyncing, updatedDt ->
    Prayer_reaction(
      prayerClientId,
      prayerServerId,
      userId,
      total,
      needsSyncing,
      updatedDt
    )
  }

  public override fun <T : Any> getReaction(
    clientId: String,
    prayerUserId: Long,
    mapper: (
      prayerClientId: String,
      prayerServerId: Int?,
      userId: Long,
      total: Int,
      needsSyncing: Boolean,
      updatedDt: Date
    ) -> T
  ): Query<T> = GetReactionQuery(clientId, prayerUserId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getLong(2)!!,
      cursor.getLong(3)!!.toInt(),
      cursor.getLong(4)!! == 1L,
      database.prayer_reactionAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getReaction(clientId: String, prayerUserId: Long): Query<Prayer_reaction> =
      getReaction(clientId, prayerUserId) { prayerClientId, prayerServerId, userId, total,
      needsSyncing, updatedDt ->
    Prayer_reaction(
      prayerClientId,
      prayerServerId,
      userId,
      total,
      needsSyncing,
      updatedDt
    )
  }

  public override fun <T : Any> getPrayerUsers(
    stateDeleted: Int?,
    statusActive: StatusType?,
    mapper: (
      userId: Long,
      updated: Boolean?,
      lastSync: Date?,
      orderIndex: Int?
    ) -> T
  ): Query<T> = GetPrayerUsersQuery(stateDeleted, statusActive) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)?.let { it == 1L },
      cursor.getLong(2)?.let { database.prayer_usersAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(3)?.toInt()
    )
  }

  public override fun getPrayerUsers(stateDeleted: Int?, statusActive: StatusType?):
      Query<Prayer_users> = getPrayerUsers(stateDeleted, statusActive) { userId, updated, lastSync,
      orderIndex ->
    Prayer_users(
      userId,
      updated,
      lastSync,
      orderIndex
    )
  }

  public override fun <T : Any> getPrayerUser(prayerUserId: Long, mapper: (
    userId: Long,
    updated: Boolean?,
    lastSync: Date?,
    orderIndex: Int?
  ) -> T): Query<T> = GetPrayerUserQuery(prayerUserId) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      cursor.getLong(1)?.let { it == 1L },
      cursor.getLong(2)?.let { database.prayer_usersAdapter.lastSyncAdapter.decode(it) },
      cursor.getLong(3)?.toInt()
    )
  }

  public override fun getPrayerUser(prayerUserId: Long): Query<Prayer_users> =
      getPrayerUser(prayerUserId) { userId, updated, lastSync, orderIndex ->
    Prayer_users(
      userId,
      updated,
      lastSync,
      orderIndex
    )
  }

  public override fun hasUnread(): Query<Boolean> = Query(-672260403, hasUnread, driver,
      "Prayer.sq", "hasUnread", "SELECT EXISTS(SELECT 1 FROM prayer_users WHERE updated = 1)") {
      cursor ->
    cursor.getLong(0)!! == 1L
  }

  public override fun <T : Any> getPrayerUpdatesByPrayerId(
    inClientId: String,
    stateDeleted: Int?,
    mapper: (
      prayerClientId: String,
      prayerServerId: Int?,
      clientId: String,
      serverId: Int?,
      message: String?,
      updatedDt: Date?,
      createdDt: Date?,
      state: Int?,
      lastSync: Date?
    ) -> T
  ): Query<T> = GetPrayerUpdatesByPrayerIdQuery(inClientId, stateDeleted) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getString(4),
      cursor.getLong(5)?.let { database.prayer_updateAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayer_updateAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(7)?.toInt(),
      cursor.getLong(8)?.let { database.prayer_updateAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getPrayerUpdatesByPrayerId(inClientId: String, stateDeleted: Int?):
      Query<Prayer_update> = getPrayerUpdatesByPrayerId(inClientId, stateDeleted) { prayerClientId,
      prayerServerId, clientId, serverId, message, updatedDt, createdDt, state, lastSync ->
    Prayer_update(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      message,
      updatedDt,
      createdDt,
      state,
      lastSync
    )
  }

  public override fun <T : Any> getPrayerUpdatesByState(prayerState: Int?, mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    message: String?,
    updatedDt: Date?,
    createdDt: Date?,
    state: Int?,
    lastSync: Date?
  ) -> T): Query<T> = GetPrayerUpdatesByStateQuery(prayerState) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getString(4),
      cursor.getLong(5)?.let { database.prayer_updateAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayer_updateAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(7)?.toInt(),
      cursor.getLong(8)?.let { database.prayer_updateAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getPrayerUpdatesByState(prayerState: Int?): Query<Prayer_update> =
      getPrayerUpdatesByState(prayerState) { prayerClientId, prayerServerId, clientId, serverId,
      message, updatedDt, createdDt, state, lastSync ->
    Prayer_update(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      message,
      updatedDt,
      createdDt,
      state,
      lastSync
    )
  }

  public override fun <T : Any> getPrayerUpdate(inClientId: String, mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    message: String?,
    updatedDt: Date?,
    createdDt: Date?,
    state: Int?,
    lastSync: Date?
  ) -> T): Query<T> = GetPrayerUpdateQuery(inClientId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getString(4),
      cursor.getLong(5)?.let { database.prayer_updateAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayer_updateAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(7)?.toInt(),
      cursor.getLong(8)?.let { database.prayer_updateAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getPrayerUpdate(inClientId: String): Query<Prayer_update> =
      getPrayerUpdate(inClientId) { prayerClientId, prayerServerId, clientId, serverId, message,
      updatedDt, createdDt, state, lastSync ->
    Prayer_update(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      message,
      updatedDt,
      createdDt,
      state,
      lastSync
    )
  }

  public override fun <T : Any> getAllUpdates(mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    message: String?,
    updatedDt: Date?,
    createdDt: Date?,
    state: Int?,
    lastSync: Date?
  ) -> T): Query<T> = Query(-973716029, getAllUpdates, driver, "Prayer.sq", "getAllUpdates",
      "SELECT * FROM prayer_update") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getString(4),
      cursor.getLong(5)?.let { database.prayer_updateAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(6)?.let { database.prayer_updateAdapter.createdDtAdapter.decode(it) },
      cursor.getLong(7)?.toInt(),
      cursor.getLong(8)?.let { database.prayer_updateAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getAllUpdates(): Query<Prayer_update> = getAllUpdates { prayerClientId,
      prayerServerId, clientId, serverId, message, updatedDt, createdDt, state, lastSync ->
    Prayer_update(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      message,
      updatedDt,
      createdDt,
      state,
      lastSync
    )
  }

  public override fun <T : Any> getShares(clientId: String, mapper: (
    id: Int,
    prayerServerId: Int?,
    prayerClientId: String,
    createdDt: Date?,
    message: String?,
    receiverId: Long?,
    senderId: Long?
  ) -> T): Query<T> = GetSharesQuery(clientId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.let { database.prayer_shareAdapter.createdDtAdapter.decode(it) },
      cursor.getString(4),
      cursor.getLong(5),
      cursor.getLong(6)
    )
  }

  public override fun getShares(clientId: String): Query<Prayer_share> = getShares(clientId) { id,
      prayerServerId, prayerClientId, createdDt, message, receiverId, senderId ->
    Prayer_share(
      id,
      prayerServerId,
      prayerClientId,
      createdDt,
      message,
      receiverId,
      senderId
    )
  }

  public override fun <T : Any> getShare(prayerId: Int, mapper: (
    id: Int,
    prayerServerId: Int?,
    prayerClientId: String,
    createdDt: Date?,
    message: String?,
    receiverId: Long?,
    senderId: Long?
  ) -> T): Query<T> = GetShareQuery(prayerId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.let { database.prayer_shareAdapter.createdDtAdapter.decode(it) },
      cursor.getString(4),
      cursor.getLong(5),
      cursor.getLong(6)
    )
  }

  public override fun getShare(prayerId: Int): Query<Prayer_share> = getShare(prayerId) { id,
      prayerServerId, prayerClientId, createdDt, message, receiverId, senderId ->
    Prayer_share(
      id,
      prayerServerId,
      prayerClientId,
      createdDt,
      message,
      receiverId,
      senderId
    )
  }

  public override fun <T : Any> getComments(inClientId: String, mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    updatedDt: Date?,
    createdDt: Date?,
    message: String?,
    userId: Long?,
    state: Int?,
    lastSync: Date?
  ) -> T): Query<T> = GetCommentsQuery(inClientId) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getLong(4)?.let { database.prayer_commentAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayer_commentAdapter.createdDtAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)?.let { database.prayer_commentAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getComments(inClientId: String): Query<Prayer_comment> =
      getComments(inClientId) { prayerClientId, prayerServerId, clientId, serverId, updatedDt,
      createdDt, message, userId, state, lastSync ->
    Prayer_comment(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      updatedDt,
      createdDt,
      message,
      userId,
      state,
      lastSync
    )
  }

  public override fun <T : Any> getCommentsByState(prayerState: Int?, mapper: (
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    updatedDt: Date?,
    createdDt: Date?,
    message: String?,
    userId: Long?,
    state: Int?,
    lastSync: Date?
  ) -> T): Query<T> = GetCommentsByStateQuery(prayerState) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getLong(1)?.toInt(),
      cursor.getString(2)!!,
      cursor.getLong(3)?.toInt(),
      cursor.getLong(4)?.let { database.prayer_commentAdapter.updatedDtAdapter.decode(it) },
      cursor.getLong(5)?.let { database.prayer_commentAdapter.createdDtAdapter.decode(it) },
      cursor.getString(6),
      cursor.getLong(7),
      cursor.getLong(8)?.toInt(),
      cursor.getLong(9)?.let { database.prayer_commentAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getCommentsByState(prayerState: Int?): Query<Prayer_comment> =
      getCommentsByState(prayerState) { prayerClientId, prayerServerId, clientId, serverId,
      updatedDt, createdDt, message, userId, state, lastSync ->
    Prayer_comment(
      prayerClientId,
      prayerServerId,
      clientId,
      serverId,
      updatedDt,
      createdDt,
      message,
      userId,
      state,
      lastSync
    )
  }

  public override fun addPrayer(
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ): Unit {
    driver.execute(-539109428, """
    |INSERT INTO prayer (
    |    clientId,
    |    serverId,
    |    title,
    |    userId,
    |    answeredDt,
    |    createdDt,
    |    updatedDt,
    |    content,
    |    sharingPolicy,
    |    status,
    |    lastPrayerUpdate,
    |    usfm,
    |    versionId,
    |    reactionCount,
    |    fromUsers,
    |    seenUpdate,
    |    updated,
    |    state,
    |    lastSync,
    |    orderIndex,
    |    commentEnabled
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 21) {
      bindString(1, clientId)
      bindLong(2, serverId?.let { it.toLong() })
      bindString(3, title)
      bindLong(4, userId)
      bindLong(5, answeredDt?.let { database.prayerAdapter.answeredDtAdapter.encode(it) })
      bindLong(6, createdDt?.let { database.prayerAdapter.createdDtAdapter.encode(it) })
      bindLong(7, updatedDt?.let { database.prayerAdapter.updatedDtAdapter.encode(it) })
      bindString(8, content)
      bindString(9, sharingPolicy?.let { database.prayerAdapter.sharingPolicyAdapter.encode(it) })
      bindString(10, status?.let { database.prayerAdapter.statusAdapter.encode(it) })
      bindLong(11, lastPrayerUpdate?.let { database.prayerAdapter.lastPrayerUpdateAdapter.encode(it)
          })
      bindString(12, usfm)
      bindLong(13, versionId?.let { it.toLong() })
      bindLong(14, reactionCount?.let { it.toLong() })
      bindString(15, fromUsers?.let { database.prayerAdapter.fromUsersAdapter.encode(it) })
      bindLong(16, seenUpdate?.let { database.prayerAdapter.seenUpdateAdapter.encode(it) })
      bindLong(17, updated?.let { if (it) 1L else 0L })
      bindLong(18, state?.let { it.toLong() })
      bindLong(19, lastSync?.let { database.prayerAdapter.lastSyncAdapter.encode(it) })
      bindLong(20, orderIndex?.let { it.toLong() })
      bindLong(21, commentEnabled?.let { if (it) 1L else 0L })
    }
    notifyQueries(-539109428, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updatePrayer(
    clientId: String,
    serverId: Int?,
    title: String?,
    userId: Long?,
    answeredDt: Date?,
    createdDt: Date?,
    updatedDt: Date?,
    content: String?,
    sharingPolicy: SharingPolicy?,
    status: StatusType?,
    lastPrayerUpdate: Date?,
    usfm: String?,
    versionId: Int?,
    reactionCount: Int?,
    fromUsers: List<Long>?,
    seenUpdate: Date?,
    updated: Boolean?,
    state: Int?,
    lastSync: Date?,
    orderIndex: Int?,
    commentEnabled: Boolean?
  ): Unit {
    driver.execute(1683361708, """
    |UPDATE prayer SET
    |    clientId = ?,
    |    serverId = ?,
    |    title = ?,
    |    userId = ?,
    |    answeredDt = ?,
    |    createdDt = ?,
    |    updatedDt = ?,
    |    content = ?,
    |    sharingPolicy = ?,
    |    status = ?,
    |    lastPrayerUpdate = ?,
    |    usfm = ?,
    |    versionId = ?,
    |    reactionCount = ?,
    |    fromUsers = ?,
    |    seenUpdate = ?,
    |    updated = ?,
    |    state = ?,
    |    lastSync = ?,
    |    orderIndex = ?,
    |    commentEnabled = ?
    |WHERE clientId = ?
    """.trimMargin(), 22) {
      bindString(1, clientId)
      bindLong(2, serverId?.let { it.toLong() })
      bindString(3, title)
      bindLong(4, userId)
      bindLong(5, answeredDt?.let { database.prayerAdapter.answeredDtAdapter.encode(it) })
      bindLong(6, createdDt?.let { database.prayerAdapter.createdDtAdapter.encode(it) })
      bindLong(7, updatedDt?.let { database.prayerAdapter.updatedDtAdapter.encode(it) })
      bindString(8, content)
      bindString(9, sharingPolicy?.let { database.prayerAdapter.sharingPolicyAdapter.encode(it) })
      bindString(10, status?.let { database.prayerAdapter.statusAdapter.encode(it) })
      bindLong(11, lastPrayerUpdate?.let { database.prayerAdapter.lastPrayerUpdateAdapter.encode(it)
          })
      bindString(12, usfm)
      bindLong(13, versionId?.let { it.toLong() })
      bindLong(14, reactionCount?.let { it.toLong() })
      bindString(15, fromUsers?.let { database.prayerAdapter.fromUsersAdapter.encode(it) })
      bindLong(16, seenUpdate?.let { database.prayerAdapter.seenUpdateAdapter.encode(it) })
      bindLong(17, updated?.let { if (it) 1L else 0L })
      bindLong(18, state?.let { it.toLong() })
      bindLong(19, lastSync?.let { database.prayerAdapter.lastSyncAdapter.encode(it) })
      bindLong(20, orderIndex?.let { it.toLong() })
      bindLong(21, commentEnabled?.let { if (it) 1L else 0L })
      bindString(22, clientId)
    }
    notifyQueries(1683361708, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun deletePrayer(clientId: String): Unit {
    driver.execute(1863559950, """DELETE FROM prayer WHERE clientId = ?""", 1) {
      bindString(1, clientId)
    }
    notifyQueries(1863559950, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getDirtyReactions + database.prayerQueries.getPrayerUpdate +
        database.prayerQueries.getAllUpdates + database.prayerQueries.getReactionsByPrayerClientId +
        database.prayerQueries.getPrayersByState + database.prayerQueries.getShare +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getShares + database.prayerQueries.getCommentsByState +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getReaction +
        database.prayerQueries.getComments + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getReactionsUserIds +
        database.prayerQueries.getPrayerUpdatesByState + database.prayerQueries.getReactions +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun deleteOldPrayers(now: Date?): Unit {
    driver.execute(2120445164, """DELETE FROM prayer WHERE lastSync < ?""", 1) {
      bindLong(1, now?.let { database.prayerAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(2120445164, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getDirtyReactions + database.prayerQueries.getPrayerUpdate +
        database.prayerQueries.getAllUpdates + database.prayerQueries.getReactionsByPrayerClientId +
        database.prayerQueries.getPrayersByState + database.prayerQueries.getShare +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getShares + database.prayerQueries.getCommentsByState +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getReaction +
        database.prayerQueries.getComments + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getReactionsUserIds +
        database.prayerQueries.getPrayerUpdatesByState + database.prayerQueries.getReactions +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun deletePrayersByStatus(status: StatusType?): Unit {
    driver.execute(null,
        """DELETE FROM prayer WHERE status ${ if (status == null) "IS" else "=" } ?""", 1) {
      bindString(1, status?.let { database.prayerAdapter.statusAdapter.encode(it) })
    }
    notifyQueries(951128430, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getDirtyReactions + database.prayerQueries.getPrayerUpdate +
        database.prayerQueries.getAllUpdates + database.prayerQueries.getReactionsByPrayerClientId +
        database.prayerQueries.getPrayersByState + database.prayerQueries.getShare +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getShares + database.prayerQueries.getCommentsByState +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getReaction +
        database.prayerQueries.getComments + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getReactionsUserIds +
        database.prayerQueries.getPrayerUpdatesByState + database.prayerQueries.getReactions +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun deleteAllPrayers(): Unit {
    driver.execute(1634247730, """DELETE FROM prayer""", 0)
    notifyQueries(1634247730, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getDirtyReactions + database.prayerQueries.getPrayerUpdate +
        database.prayerQueries.getAllUpdates + database.prayerQueries.getReactionsByPrayerClientId +
        database.prayerQueries.getPrayersByState + database.prayerQueries.getShare +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getShares + database.prayerQueries.getCommentsByState +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getReaction +
        database.prayerQueries.getComments + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getReactionsUserIds +
        database.prayerQueries.getPrayerUpdatesByState + database.prayerQueries.getReactions +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateStatus(
    status: StatusType?,
    stateDirty: Int?,
    clientId: String
  ): Unit {
    driver.execute(1771091895,
        """UPDATE prayer SET status = ?, state = (state | ?) WHERE clientId = ?""", 3) {
      bindString(1, status?.let { database.prayerAdapter.statusAdapter.encode(it) })
      bindLong(2, stateDirty?.let { it.toLong() })
      bindString(3, clientId)
    }
    notifyQueries(1771091895, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateLastPrayerUpdate(
    lastPrayerUpdate: Date?,
    stateDirty: Int?,
    clientId: String
  ): Unit {
    driver.execute(-1065161013,
        """UPDATE prayer SET lastPrayerUpdate = ?, state = (state | ?) WHERE clientId = ?""", 3) {
      bindLong(1, lastPrayerUpdate?.let { database.prayerAdapter.lastPrayerUpdateAdapter.encode(it)
          })
      bindLong(2, stateDirty?.let { it.toLong() })
      bindString(3, clientId)
    }
    notifyQueries(-1065161013, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateUpdated(updated: Boolean?, clientId: String): Unit {
    driver.execute(731967894, """UPDATE prayer SET updated = ? WHERE clientId = ?""", 2) {
      bindLong(1, updated?.let { if (it) 1L else 0L })
      bindString(2, clientId)
    }
    notifyQueries(731967894, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateState(state: Int?, clientId: String): Unit {
    driver.execute(-912699348, """UPDATE prayer SET state = ? WHERE clientId = ?""", 2) {
      bindLong(1, state?.let { it.toLong() })
      bindString(2, clientId)
    }
    notifyQueries(-912699348, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateOrder(orderIndex: Int?, clientId: String): Unit {
    driver.execute(-916450583, """UPDATE prayer SET orderIndex = ? WHERE clientId = ?""", 2) {
      bindLong(1, orderIndex?.let { it.toLong() })
      bindString(2, clientId)
    }
    notifyQueries(-916450583, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun updatePrayerAsSeen(
    seenUpdate: Date?,
    updated: Boolean?,
    stateDirty: Int?,
    clientId: String
  ): Unit {
    driver.execute(-916098919,
        """UPDATE prayer SET seenUpdate = ?, updated = ?, state = (state | ?) WHERE clientId = ?""",
        4) {
      bindLong(1, seenUpdate?.let { database.prayerAdapter.seenUpdateAdapter.encode(it) })
      bindLong(2, updated?.let { if (it) 1L else 0L })
      bindLong(3, stateDirty?.let { it.toLong() })
      bindString(4, clientId)
    }
    notifyQueries(-916098919, {database.prayerQueries.getPrayersByState +
        database.prayerQueries.getPrayersNeedingUpdate + database.prayerQueries.getPrayersByStatus +
        database.prayerQueries.getPrayersByUserId + database.prayerQueries.getPrayer +
        database.prayerQueries.getPrayerByServerId + database.prayerQueries.getAnsweredPrayers +
        database.prayerQueries.getPrayerList + database.prayerQueries.getPrayerUsers})
  }

  public override fun addOrReplacePrayerReaction(
    prayerClientId: String,
    prayerServerId: Int?,
    userId: Long,
    total: Int,
    needsSyncing: Boolean,
    updatedDt: Date
  ): Unit {
    driver.execute(117741468, """
    |INSERT OR REPLACE INTO prayer_reaction (
    |    prayerClientId,
    |    prayerServerId,
    |    userId,
    |    total,
    |    needsSyncing,
    |    updatedDt
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 6) {
      bindString(1, prayerClientId)
      bindLong(2, prayerServerId?.let { it.toLong() })
      bindLong(3, userId)
      bindLong(4, total.toLong())
      bindLong(5, if (needsSyncing) 1L else 0L)
      bindLong(6, database.prayer_reactionAdapter.updatedDtAdapter.encode(updatedDt))
    }
    notifyQueries(117741468, {database.prayerQueries.getDirtyReactions +
        database.prayerQueries.getReactionsByPrayerClientId + database.prayerQueries.getReaction +
        database.prayerQueries.getReactionsUserIds + database.prayerQueries.getReactions})
  }

  public override fun deleteReactionsByPrayerClientId(clientId: String): Unit {
    driver.execute(1541259399, """DELETE FROM prayer_reaction WHERE prayerClientId = ?""", 1) {
      bindString(1, clientId)
    }
    notifyQueries(1541259399, {database.prayerQueries.getDirtyReactions +
        database.prayerQueries.getReactionsByPrayerClientId + database.prayerQueries.getReaction +
        database.prayerQueries.getReactionsUserIds + database.prayerQueries.getReactions})
  }

  public override fun deleteReaction(clientId: String, prayerUserId: Long): Unit {
    driver.execute(397881232,
        """DELETE FROM prayer_reaction WHERE prayerClientId = ? AND userId = ?""", 2) {
      bindString(1, clientId)
      bindLong(2, prayerUserId)
    }
    notifyQueries(397881232, {database.prayerQueries.getDirtyReactions +
        database.prayerQueries.getReactionsByPrayerClientId + database.prayerQueries.getReaction +
        database.prayerQueries.getReactionsUserIds + database.prayerQueries.getReactions})
  }

  public override fun deleteAllReactions(): Unit {
    driver.execute(1731109040, """DELETE FROM prayer_reaction""", 0)
    notifyQueries(1731109040, {database.prayerQueries.getDirtyReactions +
        database.prayerQueries.getReactionsByPrayerClientId + database.prayerQueries.getReaction +
        database.prayerQueries.getReactionsUserIds + database.prayerQueries.getReactions})
  }

  public override fun deleteOldReactions(now: Date): Unit {
    driver.execute(815407850, """DELETE FROM prayer_reaction WHERE updatedDt < ?""", 1) {
      bindLong(1, database.prayer_reactionAdapter.updatedDtAdapter.encode(now))
    }
    notifyQueries(815407850, {database.prayerQueries.getDirtyReactions +
        database.prayerQueries.getReactionsByPrayerClientId + database.prayerQueries.getReaction +
        database.prayerQueries.getReactionsUserIds + database.prayerQueries.getReactions})
  }

  public override fun addOrReplacePrayerUser(
    userId: Long?,
    updated: Boolean?,
    lastSync: Date?,
    orderIndex: Int?
  ): Unit {
    driver.execute(-1082697026,
        """INSERT OR REPLACE INTO prayer_users (userId, updated, lastSync, orderIndex) VALUES (?, ?, ?, ?)""",
        4) {
      bindLong(1, userId)
      bindLong(2, updated?.let { if (it) 1L else 0L })
      bindLong(3, lastSync?.let { database.prayer_usersAdapter.lastSyncAdapter.encode(it) })
      bindLong(4, orderIndex?.let { it.toLong() })
    }
    notifyQueries(-1082697026, {database.prayerQueries.hasUnread +
        database.prayerQueries.getPrayerUser + database.prayerQueries.getPrayerUsers})
  }

  public override fun updateUserUpdated(updated: Boolean?, userId: Long): Unit {
    driver.execute(-1440158261, """UPDATE prayer_users SET updated = ? WHERE userId = ?""", 2) {
      bindLong(1, updated?.let { if (it) 1L else 0L })
      bindLong(2, userId)
    }
    notifyQueries(-1440158261, {database.prayerQueries.hasUnread +
        database.prayerQueries.getPrayerUser + database.prayerQueries.getPrayerUsers})
  }

  public override fun deleteOldUsers(now: Date?): Unit {
    driver.execute(1454900616, """DELETE FROM prayer_users WHERE lastSync < ?""", 1) {
      bindLong(1, now?.let { database.prayer_usersAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(1454900616, {database.prayerQueries.hasUnread +
        database.prayerQueries.getPrayerUser + database.prayerQueries.getPrayerUsers})
  }

  public override fun deleteAllUsers(): Unit {
    driver.execute(-1240574386, """DELETE FROM prayer_users""", 0)
    notifyQueries(-1240574386, {database.prayerQueries.hasUnread +
        database.prayerQueries.getPrayerUser + database.prayerQueries.getPrayerUsers})
  }

  public override fun deleteUser(userId: Long): Unit {
    driver.execute(645663922, """DELETE FROM prayer_users WHERE userId = ?""", 1) {
      bindLong(1, userId)
    }
    notifyQueries(645663922, {database.prayerQueries.hasUnread +
        database.prayerQueries.getPrayerUser + database.prayerQueries.getPrayerUsers})
  }

  public override fun addOrReplacePrayerUpdate(
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    message: String?,
    createdDt: Date?,
    updatedDt: Date?,
    state: Int?,
    lastSync: Date?
  ): Unit {
    driver.execute(-1092569348, """
    |INSERT OR REPLACE INTO prayer_update (
    |    prayerClientId,
    |    prayerServerId,
    |    clientId,
    |    serverId,
    |    message,
    |    createdDt,
    |    updatedDt,
    |    state,
    |    lastSync
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 9) {
      bindString(1, prayerClientId)
      bindLong(2, prayerServerId?.let { it.toLong() })
      bindString(3, clientId)
      bindLong(4, serverId?.let { it.toLong() })
      bindString(5, message)
      bindLong(6, createdDt?.let { database.prayer_updateAdapter.createdDtAdapter.encode(it) })
      bindLong(7, updatedDt?.let { database.prayer_updateAdapter.updatedDtAdapter.encode(it) })
      bindLong(8, state?.let { it.toLong() })
      bindLong(9, lastSync?.let { database.prayer_updateAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(-1092569348, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getPrayerUpdate + database.prayerQueries.getAllUpdates +
        database.prayerQueries.getPrayerUpdatesByState})
  }

  public override fun updateUpdateState(state: Int?, clientId: String): Unit {
    driver.execute(-1488397149,
        """UPDATE prayer_update SET state = (state | ?) WHERE clientId = ?""", 2) {
      bindLong(1, state?.let { it.toLong() })
      bindString(2, clientId)
    }
    notifyQueries(-1488397149, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getPrayerUpdate + database.prayerQueries.getAllUpdates +
        database.prayerQueries.getPrayerUpdatesByState})
  }

  public override fun deleteOldUpdates(now: Date?): Unit {
    driver.execute(-2087159734, """DELETE FROM prayer_update WHERE lastSync < ?""", 1) {
      bindLong(1, now?.let { database.prayer_updateAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(-2087159734, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getPrayerUpdate + database.prayerQueries.getAllUpdates +
        database.prayerQueries.getPrayerUpdatesByState})
  }

  public override fun deleteAllUpdates(): Unit {
    driver.execute(1721610128, """DELETE FROM prayer_update""", 0)
    notifyQueries(1721610128, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getPrayerUpdate + database.prayerQueries.getAllUpdates +
        database.prayerQueries.getPrayerUpdatesByState})
  }

  public override fun deleteUpdate(clientId: String): Unit {
    driver.execute(2004925424, """DELETE FROM prayer_update WHERE clientId = ?""", 1) {
      bindString(1, clientId)
    }
    notifyQueries(2004925424, {database.prayerQueries.getPrayerUpdatesByPrayerId +
        database.prayerQueries.getPrayerUpdate + database.prayerQueries.getAllUpdates +
        database.prayerQueries.getPrayerUpdatesByState})
  }

  public override fun addOrReplaceShare(
    id: Int?,
    prayerServerId: Int?,
    prayerClientId: String,
    createdDt: Date?,
    message: String?,
    receiverId: Long?,
    senderId: Long?
  ): Unit {
    driver.execute(-478224909,
        """INSERT OR REPLACE INTO prayer_share (id, prayerServerId, prayerClientId, createdDt, message, receiverId, senderId) VALUES (?, ?, ?, ?, ?, ?, ?)""",
        7) {
      bindLong(1, id?.let { it.toLong() })
      bindLong(2, prayerServerId?.let { it.toLong() })
      bindString(3, prayerClientId)
      bindLong(4, createdDt?.let { database.prayer_shareAdapter.createdDtAdapter.encode(it) })
      bindString(5, message)
      bindLong(6, receiverId)
      bindLong(7, senderId)
    }
    notifyQueries(-478224909, {database.prayerQueries.getShare + database.prayerQueries.getShares})
  }

  public override fun deleteShares(prayerClientId: String): Unit {
    driver.execute(1940205467, """DELETE FROM prayer_share WHERE prayerClientId = ?""", 1) {
      bindString(1, prayerClientId)
    }
    notifyQueries(1940205467, {database.prayerQueries.getShare + database.prayerQueries.getShares})
  }

  public override fun deleteAllShares(): Unit {
    driver.execute(129363182, """DELETE FROM prayer_share""", 0)
    notifyQueries(129363182, {database.prayerQueries.getShare + database.prayerQueries.getShares})
  }

  public override fun addOrReplaceComment(
    prayerClientId: String,
    prayerServerId: Int?,
    clientId: String,
    serverId: Int?,
    updatedDt: Date?,
    createdDt: Date?,
    message: String?,
    userId: Long?,
    state: Int?,
    lastSync: Date?
  ): Unit {
    driver.execute(-1116453005, """
    |INSERT OR REPLACE INTO prayer_comment (
    |    prayerClientId,
    |    prayerServerId,
    |    clientId,
    |    serverId,
    |    updatedDt,
    |    createdDt,
    |    message,
    |    userId,
    |    state,
    |    lastSync
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 10) {
      bindString(1, prayerClientId)
      bindLong(2, prayerServerId?.let { it.toLong() })
      bindString(3, clientId)
      bindLong(4, serverId?.let { it.toLong() })
      bindLong(5, updatedDt?.let { database.prayer_commentAdapter.updatedDtAdapter.encode(it) })
      bindLong(6, createdDt?.let { database.prayer_commentAdapter.createdDtAdapter.encode(it) })
      bindString(7, message)
      bindLong(8, userId)
      bindLong(9, state?.let { it.toLong() })
      bindLong(10, lastSync?.let { database.prayer_commentAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(-1116453005, {database.prayerQueries.getCommentsByState +
        database.prayerQueries.getComments})
  }

  public override fun updateCommentState(state: Int?, clientId: String): Unit {
    driver.execute(-1465366153,
        """UPDATE prayer_comment SET state = (state | ?) WHERE clientId = ?""", 2) {
      bindLong(1, state?.let { it.toLong() })
      bindString(2, clientId)
    }
    notifyQueries(-1465366153, {database.prayerQueries.getCommentsByState +
        database.prayerQueries.getComments})
  }

  public override fun deleteComment(clientId: String): Unit {
    driver.execute(-1086992360, """DELETE FROM prayer_comment WHERE clientId = ?""", 1) {
      bindString(1, clientId)
    }
    notifyQueries(-1086992360, {database.prayerQueries.getCommentsByState +
        database.prayerQueries.getComments})
  }

  public override fun deleteOldComments(now: Date?): Unit {
    driver.execute(2092512884, """DELETE FROM prayer_comment WHERE lastSync < ?""", 1) {
      bindLong(1, now?.let { database.prayer_commentAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(2092512884, {database.prayerQueries.getCommentsByState +
        database.prayerQueries.getComments})
  }

  public override fun deleteAllComments(): Unit {
    driver.execute(-94705682, """DELETE FROM prayer_comment""", 0)
    notifyQueries(-94705682, {database.prayerQueries.getCommentsByState +
        database.prayerQueries.getComments})
  }

  private inner class GetPrayerQuery<out T : Any>(
    public val prayerClientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayer, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(264065729,
        """SELECT * FROM prayer WHERE clientId = ?""", 1) {
      bindString(1, prayerClientId)
    }

    public override fun toString(): String = "Prayer.sq:getPrayer"
  }

  private inner class GetPrayerByServerIdQuery<out T : Any>(
    public val prayerServerId: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerByServerId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer WHERE serverId ${ if (prayerServerId == null) "IS" else "=" } ?""",
        1) {
      bindLong(1, prayerServerId?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getPrayerByServerId"
  }

  private inner class GetPrayersByStatusQuery<out T : Any>(
    public val prayerStatus: StatusType?,
    public val stateDeleted: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayersByStatus, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer WHERE status ${ if (prayerStatus == null) "IS" else "=" } ? AND ((state & ?) ${ if (stateDeleted == null) "IS NOT" else "!=" } ?) ORDER BY createdDt DESC""",
        3) {
      bindString(1, prayerStatus?.let { database.prayerAdapter.statusAdapter.encode(it) })
      bindLong(2, stateDeleted?.let { it.toLong() })
      bindLong(3, stateDeleted?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getPrayersByStatus"
  }

  private inner class GetPrayersByStateQuery<out T : Any>(
    public val prayerState: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayersByState, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer WHERE (state & ?) ${ if (prayerState == null) "IS" else "=" } ?""",
        2) {
      bindLong(1, prayerState?.let { it.toLong() })
      bindLong(2, prayerState?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getPrayersByState"
  }

  private inner class GetPrayersByUserIdQuery<out T : Any>(
    public val prayerUserId: Long?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayersByUserId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer WHERE userId ${ if (prayerUserId == null) "IS" else "=" } ? ORDER BY orderIndex ASC, createdDt DESC""",
        1) {
      bindLong(1, prayerUserId)
    }

    public override fun toString(): String = "Prayer.sq:getPrayersByUserId"
  }

  private inner class GetPrayerListQuery<out T : Any>(
    public val stateDeleted: Int?,
    public val statusActive: StatusType?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerList, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT * FROM prayer
    |    WHERE ((state & ?) ${ if (stateDeleted == null) "IS NOT" else "!=" } ?)
    |    AND (status ${ if (statusActive == null) "IS" else "=" } ?)
    |ORDER BY orderIndex ASC, createdDt DESC
    """.trimMargin(), 3) {
      bindLong(1, stateDeleted?.let { it.toLong() })
      bindLong(2, stateDeleted?.let { it.toLong() })
      bindString(3, statusActive?.let { database.prayerAdapter.statusAdapter.encode(it) })
    }

    public override fun toString(): String = "Prayer.sq:getPrayerList"
  }

  private inner class GetPrayersNeedingUpdateQuery<out T : Any>(
    public val stateDeleted: Int?,
    public val statusActive: StatusType?,
    public val now: Date?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayersNeedingUpdate, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT * FROM prayer
    |WHERE ((state & ?) ${ if (stateDeleted == null) "IS NOT" else "!=" } ?)
    |    AND (status ${ if (statusActive == null) "IS" else "=" } ?)
    |    AND (lastSync IS NULL OR lastSync < ?)
    """.trimMargin(), 4) {
      bindLong(1, stateDeleted?.let { it.toLong() })
      bindLong(2, stateDeleted?.let { it.toLong() })
      bindString(3, statusActive?.let { database.prayerAdapter.statusAdapter.encode(it) })
      bindLong(4, now?.let { database.prayerAdapter.lastSyncAdapter.encode(it) })
    }

    public override fun toString(): String = "Prayer.sq:getPrayersNeedingUpdate"
  }

  private inner class GetReactionsByPrayerClientIdQuery<out T : Any>(
    public val clientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getReactionsByPrayerClientId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-912941580,
        """SELECT * FROM prayer_reaction WHERE prayerClientId = ?""", 1) {
      bindString(1, clientId)
    }

    public override fun toString(): String = "Prayer.sq:getReactionsByPrayerClientId"
  }

  private inner class GetReactionsUserIdsQuery<out T : Any>(
    public val clientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getReactionsUserIds, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1511972477,
        """SELECT DISTINCT userId FROM prayer_reaction WHERE prayerClientId = ?""", 1) {
      bindString(1, clientId)
    }

    public override fun toString(): String = "Prayer.sq:getReactionsUserIds"
  }

  private inner class GetReactionQuery<out T : Any>(
    public val clientId: String,
    public val prayerUserId: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getReaction, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(882226819,
        """SELECT * FROM prayer_reaction WHERE prayerClientId = ? AND userId = ?""", 2) {
      bindString(1, clientId)
      bindLong(2, prayerUserId)
    }

    public override fun toString(): String = "Prayer.sq:getReaction"
  }

  private inner class GetPrayerUsersQuery<out T : Any>(
    public val stateDeleted: Int?,
    public val statusActive: StatusType?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerUsers, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null, """
    |SELECT prayer_users.* FROM prayer_users
    |JOIN
    |    (
    |        SELECT DISTINCT userId
    |        FROM prayer
    |        WHERE ((state & ?) ${ if (stateDeleted == null) "IS NOT" else "!=" } ?) AND (status ${ if (statusActive == null) "IS" else "=" } ?)
    |    ) AS users_with_prayers
    |ON prayer_users.userId = users_with_prayers.userId
    |ORDER BY orderIndex ASC
    """.trimMargin(), 3) {
      bindLong(1, stateDeleted?.let { it.toLong() })
      bindLong(2, stateDeleted?.let { it.toLong() })
      bindString(3, statusActive?.let { database.prayerAdapter.statusAdapter.encode(it) })
    }

    public override fun toString(): String = "Prayer.sq:getPrayerUsers"
  }

  private inner class GetPrayerUserQuery<out T : Any>(
    public val prayerUserId: Long,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerUser, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(2005690924,
        """SELECT * FROM prayer_users WHERE userId = ?""", 1) {
      bindLong(1, prayerUserId)
    }

    public override fun toString(): String = "Prayer.sq:getPrayerUser"
  }

  private inner class GetPrayerUpdatesByPrayerIdQuery<out T : Any>(
    public val inClientId: String,
    public val stateDeleted: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerUpdatesByPrayerId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer_update WHERE prayerClientId = ? AND ((state & ?) ${ if (stateDeleted == null) "IS NOT" else "!=" } ?) ORDER BY createdDt DESC""",
        3) {
      bindString(1, inClientId)
      bindLong(2, stateDeleted?.let { it.toLong() })
      bindLong(3, stateDeleted?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getPrayerUpdatesByPrayerId"
  }

  private inner class GetPrayerUpdatesByStateQuery<out T : Any>(
    public val prayerState: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerUpdatesByState, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer_update WHERE (state & ?) ${ if (prayerState == null) "IS" else "=" } ?""",
        2) {
      bindLong(1, prayerState?.let { it.toLong() })
      bindLong(2, prayerState?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getPrayerUpdatesByState"
  }

  private inner class GetPrayerUpdateQuery<out T : Any>(
    public val inClientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getPrayerUpdate, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-974150934,
        """SELECT * FROM prayer_update WHERE clientId = ?""", 1) {
      bindString(1, inClientId)
    }

    public override fun toString(): String = "Prayer.sq:getPrayerUpdate"
  }

  private inner class GetSharesQuery<out T : Any>(
    public val clientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getShares, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(340711246,
        """SELECT * FROM prayer_share WHERE prayerClientId = ?""", 1) {
      bindString(1, clientId)
    }

    public override fun toString(): String = "Prayer.sq:getShares"
  }

  private inner class GetShareQuery<out T : Any>(
    public val prayerId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getShare, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-681745979,
        """SELECT * FROM prayer_share WHERE id = ?""", 1) {
      bindLong(1, prayerId.toLong())
    }

    public override fun toString(): String = "Prayer.sq:getShare"
  }

  private inner class GetCommentsQuery<out T : Any>(
    public val inClientId: String,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getComments, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1147320910,
        """SELECT * FROM prayer_comment WHERE prayerClientId = ? ORDER BY createdDt ASC""", 1) {
      bindString(1, inClientId)
    }

    public override fun toString(): String = "Prayer.sq:getComments"
  }

  private inner class GetCommentsByStateQuery<out T : Any>(
    public val prayerState: Int?,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getCommentsByState, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(null,
        """SELECT * FROM prayer_comment WHERE (state & ?) ${ if (prayerState == null) "IS" else "=" } ?""",
        2) {
      bindLong(1, prayerState?.let { it.toLong() })
      bindLong(2, prayerState?.let { it.toLong() })
    }

    public override fun toString(): String = "Prayer.sq:getCommentsByState"
  }
}
