package youversion.red.downloads.model

import kotlinx.serialization.Serializable
import red.platform.http.URL
import red.platform.http.UrlSerializer
import red.platform.io.File
import red.platform.io.FileSerializer
import red.platform.threads.freeze

enum class DownloadPriority {
    Immediate,
    Background
}

enum class DownloadState {
    Pending,
    Downloading,
    Failed,
    Complete
}

@Serializable
data class Download(
    val id: Long,
    val identifier: String? = null,
    val headers: DownloadHeaders = DownloadHeaders(emptyMap()),
    @Serializable(with = UrlSerializer::class)
    val url: URL,
    @Serializable(with = FileSerializer::class)
    val destination: File,
    val state: DownloadState = DownloadState.Pending,
    val priority: DownloadPriority = DownloadPriority.Immediate
) {
    init {
        freeze()
    }
}
