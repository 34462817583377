package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.now
import red.platform.threads.freeze

@Serializable
data class Plan(
    @ProtoNumber(1) val id: Int,
    @ProtoNumber(2) val shortUrl: String? = null,
    @ProtoNumber(3) val images: List<PlanImage> = emptyList(),
    @ProtoNumber(4) val totalDays: Int = 0,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(5) val created: Date? = null,
    @ProtoNumber(6) val publisherUrl: String? = null,
    @ProtoNumber(7) val type: String = "",
    @ProtoNumber(8) val versionId: Int = 0,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(9) val defaultStart: Date = now(),
    @ProtoNumber(10) val slug: String = "",
    @ProtoNumber(11) val about: PlanTextAndHtml? = null,
    @ProtoNumber(12) val formattedLength: String = "",
    @ProtoNumber(13) val name: String = "",
    @ProtoNumber(14) val token: String? = null,
    @ProtoNumber(15) val copyright: PlanTextAndHtml? = null,
    @ProtoNumber(16) val imageId: Int = 0,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(17) val updated: Date? = null,
    @ProtoNumber(18) val completionPercentage: Float = 0f,
    @ProtoNumber(19) val emailDelivery: Int = 0,
    @ProtoNumber(20) val emailDeliveryVersionId: Int = 0,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(21) val end: Date? = null,
    @ProtoNumber(22) val languageTag: String? = null,
    @ProtoNumber(23) val isPrivate: Boolean? = null,
    @ProtoNumber(24) val startDay: Int? = null,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(25) val start: Date? = null,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(26) val subscribed: Date? = null,
    @ProtoNumber(27) val subscriptionId: String? = null,
    @ProtoNumber(28) val userAvatarUrl: PlanImageUrls? = null,
    @ProtoNumber(29) val userId: Int = 0,
    @ProtoNumber(30) val username: String? = null,
    @ProtoNumber(31) val rating: Int? = null,
    @Serializable(with = DateSerializer::class)
    @ProtoNumber(32) val lastCompleted: Date? = null,
    @ProtoNumber(33) val canRate: Boolean = false,
    @ProtoNumber(34) val hasDevotionalAudio: Boolean = false,
    @ProtoNumber(35) val languageTags: List<String> = emptyList()
) {
    init {
        freeze()
    }
}
