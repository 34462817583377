package youversion.red.locales.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.locales.api.model.LocaleHeader
import youversion.red.locales.api.model.LocalePlatform

@JsName("LocalesServiceProvider")
fun LocalesService() = service<ILocalesService>()

@ExportedService
interface ILocalesService : IService {

    suspend fun getLocaleHeaders(locales: Set<String> = emptySet(), platform: LocalePlatform? = null): List<LocaleHeader>

    suspend fun getApiTag(locale: String): String
}
