package youversion.red.blue.state

import kotlinx.serialization.ExperimentalSerializationApi
import red.listeners.Listeners
import red.platform.Log
import red.platform.http.FormatType
import red.platform.http.RequestManager
import red.platform.http.newURL
import red.platform.settings.Settings
import red.platform.threads.AtomicReference
import red.platform.threads.Lock
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import youversion.red.api.AbstractApi
import youversion.red.blue.BlueFeatureStateListener
import youversion.red.blue.api.BlueApiSerializer
import youversion.red.blue.api.model.state.FeatureState

internal object StateManager {

    private val listeners = Listeners<BlueFeatureStateListener>().freeze()

    fun addListener(listener: BlueFeatureStateListener) =
        listeners.addListener(listener)

    fun removeListener(listener: BlueFeatureStateListener) =
        listeners.removeListener(listener)

    fun getState(featureId: String): FeatureState? {
        StateMemoryCache.getState(featureId)?.let {
            return it
        }
        Log.w("BlueService", "About to read state from default settings, this will only happen once")
        val cache = StateStorageCache(featureId)
        val state = cache.state ?: return null
        StateMemoryCache.setState(featureId, state)
        return state
    }

    fun setState(newState: FeatureState) {
        StateMemoryCache.setState(newState.featureId, newState)
        StateStorageCache(newState.featureId).state = newState
        listeners.dispatchNotifyListeners {
            it.onFeatureStateChanged(newState.featureId)
        }
    }

    suspend fun clearState(features: List<String>, installationId: String?) {
        installationId?.let {
            RequestManager.clearCache(
                newURL("https://blue.${AbstractApi.urlResolver.resolveSuffix("blue")}/4.1/state/$installationId"),
                false
            )
        }
        features.forEach { featureId ->
            StateStorageCache(featureId).state = null
            listeners.dispatchNotifyListeners {
                it.onFeatureStateChanged(featureId)
            }
        }
        StateMemoryCache.clear()
    }
}

private object StateMemoryCache {

    private val cache = AtomicReference(mapOf<String, FeatureState>().freeze()).freeze()
    private val lock = Lock().freeze()

    fun clear() = lock.sync { cache.setAssertTrue(emptyMap()) }

    fun getState(featureId: String) = cache.value[featureId]

    fun setState(featureId: String, state: FeatureState) {
        lock.sync {
            cache.setAssertTrue(cache.value + Pair(featureId, state.freeze()))
        }
    }
}

private class StateStorageCache(featureId: String) {

    private val key = "red.blue.$featureId.state"

    @OptIn(ExperimentalSerializationApi::class)
    var state: FeatureState?
        get() = Settings.defaultSettings.getString(key)?.encodeToByteArray()?.let {
            BlueApiSerializer.deserialize(
                FormatType.JSON,
                FeatureState.serializer(),
                it
            )
        }
        set(value) {
            if (value == null) {
                Settings.defaultSettings.edit().remove(key).commit()
            } else {
                Settings.defaultSettings.edit()
                    .putString(
                        key,
                        BlueApiSerializer.serialize(
                            FormatType.JSON,
                            FeatureState.serializer(),
                            value
                        ).decodeToString()
                    )
                    .commit()
            }
        }
}
