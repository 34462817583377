// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.state

import kotlin.Boolean
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("state.FeatureState")
data class FeatureState(
  @SerialName("feature_id")
  @ProtoNumber(3)
  val featureId: String = "",
  @SerialName("group")
  @ProtoNumber(4)
  val group: String? = null,
  @SerialName("enabled")
  @ProtoNumber(5)
  val enabled: Boolean = false
) : State, red.Serializable {
  init {
    freeze()
  }
}
