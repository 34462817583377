package youversion.red.prayer.service

import kotlin.js.JsName
import red.Error
import red.lifecycle.LiveData
import red.lifecycle.MutableLiveData
import red.service.IService
import red.service.ServiceRegistry
import red.service.service
import youversion.red.prayer.api.model.PrayerReferrerType
import youversion.red.prayer.api.model.PrayerSuggestionType
import youversion.red.prayer.api.model.PrayerUser
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.model.Prayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate

@Deprecated("use by PrayerService")
val PrayerService: IPrayersService
    get() = ServiceRegistry[IPrayersService::class]

@JsName("PrayerServiceProvider")
fun PrayerService() = service<IPrayersService>()

interface ISimplePrayerService {

    fun getArchivedPrayers(page: Int = 1): LiveData<List<Prayer>>
    fun getAnsweredPrayers(page: Int = 1): LiveData<List<Prayer>>
    fun getReactionsUserIds(prayerClientId: String): LiveData<List<Long>>
    fun savePrayerUpdate(
        prayerClientId: String,
        prayerUpdate: PrayerUpdate,
        completion: ((result: List<PrayerUpdate>?, error: Error?) -> Unit)? = null
    ): LiveData<List<PrayerUpdate>>

    val archivedNextPage: MutableLiveData<Boolean>
    val answeredNextPage: MutableLiveData<Boolean>
    val hasNextPageComments: LiveData<Boolean>
    fun savePrayer(
        prayer: Prayer,
        referrer: PrayerReferrerType? = null,
        completion: ((result: Prayer?, error: Error?) -> Unit)? = null
    ): LiveData<Prayer>

    fun getPrayerUsers(
        page: Int = 1,
        force: Boolean = false
    ): LiveData<List<PrayerUser>>
    fun hasUnreadPrayerLiveData(): LiveData<Boolean>

    fun getPrayerList(): LiveData<List<Prayer>>
    fun deletePrayer(clientId: String, completion: ((error: Error?) -> Unit)?)
    fun removePendingDelete(clientId: String)
    fun getPrayer(clientId: String): LiveData<Prayer>
    fun updateFriendsPrayerStatus(clientId: String, status: StatusType, completion: ((error: Error?) -> Unit))
    fun markPrayerAsSeen(clientId: String)
    fun inviteFriends(
        prayerClientId: String,
        friendIds: List<Long>,
        message: String? = null,
        completion: ((result: List<PrayerShare>?, error: Error?) -> Unit)? = null
    )

    fun getShares(prayerClientId: String): LiveData<List<PrayerShare>>

    fun getPrayerUpdates(prayerClientId: String): LiveData<List<PrayerUpdate>>
    fun addComment(prayerClientId: String, comment: PrayerComment): LiveData<List<PrayerComment>>
    fun getComments(prayerClientId: String): LiveData<List<PrayerComment>>
    fun syncComments(prayerClientId: String, page: Int = 1): LiveData<List<PrayerComment>>
    fun deleteComment(clientId: String)
    fun logPrayerView(clientId: String)
    fun logSuggestionTap(suggestion: PrayerSuggestionType)
    fun clearCache()
    fun deleteAllSync()
    fun deleteAll()
}

interface IPrayersService : ISimplePrayerService, IService {

    companion object {

        const val PRAYER_DESCRIPTION_CHARACTER_MAX = 5000
        const val PRAYER_COMMENT_CHARACTER_MAX = 1000
    }

    suspend fun findServerId(clientId: String): Int?
    suspend fun deletePrayer(clientId: String)
    suspend fun addReaction(prayerClientId: String)
    suspend fun deletePrayerUpdate(clientId: String)
    suspend fun findClientId(serverId: Int): String?
    suspend fun getPrayerListSync(): List<Prayer>
    suspend fun hasUnreadPrayer(): Boolean
}
