// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.collections

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Collection(
  /**
   * collection id
   */
  @SerialName("id")
  @ProtoNumber(6)
  @JvmField
  val id: Int? = null,
  /**
   * title text
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * description text
   */
  @SerialName("description")
  @ProtoNumber(1)
  @JvmField
  val description: String? = null,
  @SerialName("language")
  @ProtoNumber(4)
  @JvmField
  val language: String? = null,
  /**
   * start
   */
  @SerialName("start")
  @ProtoNumber(7)
  @JvmField
  val start: Int? = null,
  /**
   * end
   */
  @SerialName("end")
  @ProtoNumber(8)
  @JvmField
  val end: Int? = null,
  @SerialName("videos")
  @ProtoNumber(9)
  @JvmField
  val videos: List<Int>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
