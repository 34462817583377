package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class MomentBaseImages(
    @ProtoNumber(1) val avatar: MomentImageData? = null,
    @ProtoNumber(2) val body: MomentImageData? = null
) {

    init {
        freeze()
    }
}

@Serializable
data class MomentBase(
    @ProtoNumber(1) val actionUrl: String? = null,
    @ProtoNumber(2) val body: MomentBodyOrTitle? = null,
    @ProtoNumber(3) val images: MomentBaseImages? = null,
    @ProtoNumber(4) val shareUrl: String? = null,
    @ProtoNumber(5) val title: MomentBodyOrTitle? = null
) {

    init {
        freeze()
    }
}
