package youversion.red.prayer.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.prayer.deeplink.PrayerDeepLinkHandler

@ModuleDependencies("core", "analytics")
class PrayerModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(PrayerDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
