package youversion.red.blue.state

import red.platform.settings.Settings
import red.platform.threads.AtomicReference

object DiscoverState {

    private val _searchEnabled = AtomicReference(Settings.defaultSettings.getBoolean("discover.state.search.enabled"))
    private val _discoverEnabled = AtomicReference(Settings.defaultSettings.getBoolean("discover.state.discover.enabled"))

    var searchEnabled: Boolean
        get() = _searchEnabled.value
        set(value) {
            _searchEnabled.value = value
            Settings.defaultSettings.edit().putBoolean("discover.state.search.enabled", value).commit()
        }

    var discoverEnabled: Boolean
        get() = _discoverEnabled.value
        set(value) {
            _discoverEnabled.value = value
            Settings.defaultSettings.edit().putBoolean("discover.state.discover.enabled", value).commit()
        }
}
