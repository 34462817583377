package youversion.red.geoip.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GeoIP(
    @SerialName("country_code") val countryCode: String? = null,
    @SerialName("region") val region: String? = null,
    @SerialName("city") val city: String? = null,
    val gdpr: Boolean = false
)
