// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AnalyticsContext(
  @SerialName("application_id")
  @ProtoNumber(1)
  @JvmField
  val applicationId: String = "",
  @SerialName("client_id")
  @ProtoNumber(8)
  @JvmField
  val clientId: String = "",
  @SerialName("user_id")
  @ProtoNumber(2)
  @JvmField
  val userId: Int? = null,
  @SerialName("session_id")
  @ProtoNumber(3)
  @JvmField
  val sessionId: String = "",
  @SerialName("app_version")
  @ProtoNumber(4)
  @JvmField
  val appVersion: String = "",
  @SerialName("red_version")
  @ProtoNumber(5)
  @JvmField
  val redVersion: String = "",
  @SerialName("geo")
  @ProtoNumber(6)
  @JvmField
  val geo: AnalyticsGeoContext? = null,
  @SerialName("device")
  @ProtoNumber(7)
  @JvmField
  val device: AnalyticsDevice,
  @SerialName("blue_features")
  @ProtoNumber(9)
  @JvmField
  val blueFeatures: List<BlueFeature>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
