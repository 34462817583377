// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The layout a day (i.e., card) should use
 */
enum class GuidedPrayerModuleType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  TEXT("text", 1),

  OUTRO("outro", 2),

  USFM_TEXT("usfm_text", 3),

  PRAYER_LIST("prayer_list", 4);
}

internal class GuidedPrayerModuleTypeSerializer : KSerializer<GuidedPrayerModuleType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.guidedprayer.api.model.GuidedPrayerModuleType")

  override fun serialize(encoder: Encoder, value: GuidedPrayerModuleType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GuidedPrayerModuleType {
    if (decoder is JsonDecoder) {
      return GuidedPrayerModuleType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GuidedPrayerModuleType.UNSUPPORTED_PLACEHOLDER
      1 -> GuidedPrayerModuleType.TEXT
      2 -> GuidedPrayerModuleType.OUTRO
      3 -> GuidedPrayerModuleType.USFM_TEXT
      4 -> GuidedPrayerModuleType.PRAYER_LIST
      else -> GuidedPrayerModuleType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
