// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model.guidedprayer

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class GuidedPrayerAnalyticsModuleType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  TEXT("text", 1),

  OUTRO("outro", 2),

  USFM_TEXT("usfm_text", 3),

  PRAYER_LIST("prayer_list", 4);
}

internal class GuidedPrayerAnalyticsModuleTypeSerializer :
    KSerializer<GuidedPrayerAnalyticsModuleType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType")

  override fun serialize(encoder: Encoder, value: GuidedPrayerAnalyticsModuleType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GuidedPrayerAnalyticsModuleType {
    if (decoder is JsonDecoder) {
      return GuidedPrayerAnalyticsModuleType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
      1 -> GuidedPrayerAnalyticsModuleType.TEXT
      2 -> GuidedPrayerAnalyticsModuleType.OUTRO
      3 -> GuidedPrayerAnalyticsModuleType.USFM_TEXT
      4 -> GuidedPrayerAnalyticsModuleType.PRAYER_LIST
      else -> GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
