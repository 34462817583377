package youversion.red.installation

import red.platform.UUID
import red.platform.io.ByteBuffer
import red.platform.toBase64

internal actual object Installer {

    actual fun generateId(): String {
        val uuid = UUID.randomUUID()
        val buffer: ByteBuffer =
            ByteBuffer.allocate(20)
        buffer.putInt(0b01110000)
        val components: Array<String> = uuid.toString().split("-").toTypedArray()
        var mostSigBits: Long = components[0].toLong(16)
        mostSigBits = mostSigBits shl 16
        mostSigBits = mostSigBits or components[1].toLong(16)
        mostSigBits = mostSigBits shl 16
        mostSigBits = mostSigBits or components[2].toLong(16)
        var leastSigBits: Long = components[3].toLong(16)
        leastSigBits = leastSigBits shl 48
        leastSigBits = leastSigBits or components[4].toLong(16)
        buffer.putLong(leastSigBits)
        buffer.putLong(mostSigBits)
        return buffer.array()
            .toBase64()
            .replace('/', '_')
            .replace('+', '-')
            .substring(0, 22)
    }

    actual suspend fun getToken(): String? = null

    actual suspend fun getUniqueData(): String? = null
}
