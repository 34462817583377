package youversion.red.versification.service

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.MapSerializer
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.PrimitiveKind
import kotlinx.serialization.descriptors.PrimitiveSerialDescriptor
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import red.platform.Log
import red.platform.LogLevel
import red.platform.PlatformType
import red.platform.http.Request
import red.platform.http.RequestManager
import red.platform.http.RequestMethods
import red.platform.http.json
import red.platform.http.newURL
import red.platform.io.fileSystem
import red.platform.network.ConnectivityState
import red.platform.network.NetworkConnectivity
import red.platform.platformType
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendedLock
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import youversion.red.bible.reference.BibleReference
import youversion.red.bible.reference.ReferenceBuilder
import youversion.red.bible.reference.newBuilder
import youversion.red.versification.Versification
import youversion.red.versification.Versifier

internal class StringToIntSerializer : KSerializer<Int> {

    override val descriptor: SerialDescriptor =
        PrimitiveSerialDescriptor("youversion.red.versification.StringToInt", PrimitiveKind.INT)

    override fun deserialize(decoder: Decoder): Int {
        return decoder.decodeString().toInt()
    }

    override fun serialize(encoder: Encoder, value: Int) {
        encoder.encodeString(value.toString())
    }
}

@Serializable
internal data class VersificationData(
    @SerialName("vs") val verseStart: Int,
    @SerialName("ve") val verseEnd: Int,
    @SerialName("b") val book: String? = null,
    @SerialName("vo") val verseOffset: Int? = null,
    @SerialName("co") val chapterOffset: Int? = null
)

internal typealias VersificationMap = Map<String, Map<String, List<VersificationData>>>

internal class VersificationImpl : Versification() {

    override val versifier: Versifier = VersificationInternal()

    init {
        freeze()
    }
}

internal class VersificationInternal : Versifier {

    private val mappingCache = AtomicReference(emptyMap<String, VersificationMap>().freeze())
    private val versionIdCache = AtomicReference<Map<Int, Int>?>(null)
    private val lock = SuspendedLock()

    private val versificationMapLoader: KSerializer<VersificationMap>
        get() = MapSerializer(
            String.serializer(),
            MapSerializer(String.serializer(), ListSerializer(VersificationData.serializer()))
        )

    private val versionToSchemeIdLoader: KSerializer<Map<Int, Int>>
        get() = MapSerializer(StringToIntSerializer(), Int.serializer())

    init {
        freeze()
    }

    private fun String.splitUsfm(): Triple<String, Int, Int> {
        val usfmSplit = split(".")
        return Triple(usfmSplit[0], usfmSplit[1].toInt(), usfmSplit[2].toInt())
    }

    private fun getVersionVersificationScheme(versionToSchemeId: Map<Int, Int>, versionId: Int): String? {
        val schemeId = versionToSchemeId[versionId] ?: return null
        return vrsSchemeIds[schemeId]
    }

    private fun mapReference(mapping: VersificationMap, ref: Triple<String, Int, Int>): Triple<String, Int, Int> {
        var (book, chap, verse) = ref

        mapping[book]?.get(chap.toString())?.let { allData ->
            for (data in allData) {
                if (verse >= data.verseStart && verse <= data.verseEnd) {
                    if (data.book != null) {
                        book = data.book
                    }
                    if (data.chapterOffset != null) {
                        chap += data.chapterOffset
                    }
                    if (data.verseOffset != null) {
                        verse += data.verseOffset
                    }
                    break
                }
            }
        }
        return Triple(book, chap, verse)
    }

    private suspend fun getBundledFile(name: String): ByteArray? {
        val filename = fileSystem.newFile("vrs/v1/$name")
        return fileSystem.openAsset(filename)
    }

    private suspend fun getMapping(scheme: String): VersificationMap {
        mappingCache.value[scheme]?.let { return it }
        lock.sync {
            val filename = "mappings/$scheme.json"
            val data = try {
                if (NetworkConnectivity.state == ConnectivityState.Online || platformType == PlatformType.JavaScript) {
                    RequestManager.execute(
                        Request(
                            newURL("$baseUrl$filename"),
                            RequestMethods.GET
                        ),
                        null
                    ).body
                } else {
                    getBundledFile(filename)
                }
            } catch (e: Exception) {
                Log.e("Versification", "Failed to get mapping from red-data", e)
                getBundledFile(filename)
            } ?: "{}".encodeToByteArray()

            return json.decodeFromString(versificationMapLoader, data.decodeToString()).also {
                mappingCache.setAssertTrue(mappingCache.value + Pair(scheme, it))
            }
        }
    }

    private suspend fun getMappingToOrg(scheme: String): VersificationMap {
        if (scheme == "org") {
            return emptyMap()
        }
        return getMapping("${scheme}_org")
    }

    private suspend fun getMappingFromOrg(scheme: String): VersificationMap {
        if (scheme == "org") {
            return emptyMap()
        }
        return getMapping("org_$scheme")
    }

    private suspend fun getVersionToSchemeId(): Map<Int, Int> {
        versionIdCache.value?.let { return it }
        lock.sync {
            val filename = "version_to_scheme_id.json"
            val data = try {
                if (NetworkConnectivity.state == ConnectivityState.Online || platformType == PlatformType.JavaScript) {
                    RequestManager.execute(
                        Request(
                            newURL("$baseUrl$filename"),
                            RequestMethods.GET
                        ),
                        null
                    ).body
                } else {
                    getBundledFile(filename)
                }
            } catch (e: Exception) {
                Log.e("Versification", "Failed to get version_to_scheme_id from red-data", e)
                getBundledFile(filename)
            } ?: "{}".encodeToByteArray()
            return json.decodeFromString(versionToSchemeIdLoader, data.decodeToString()).also {
                versionIdCache.setAssertTrue(it)
            }
        }
    }

    override suspend fun versify(reference: BibleReference, versionId: Int): BibleReference {
        if (Log.level == LogLevel.DEBUG) {
            Log.d("Versification", "Versifying : $reference -> $versionId")
        }

        val versionToSchemeId = getVersionToSchemeId()

        // if we have no mapping for dest version just return original list
        val destScheme = getVersionVersificationScheme(versionToSchemeId, versionId) ?: return reference.newBuilder()
            .withVersion(versionId).build()
        val fromOrgMapping = getMappingFromOrg(destScheme)

        val mappedUsfms = mutableListOf<String>()
        val originScheme = getVersionVersificationScheme(versionToSchemeId, reference.version)
        // if we have no mapping for source version or if the mappings are the same
        // just use original reference
        if (originScheme == null || destScheme == originScheme) {
            mappedUsfms += reference.usfmArray
        } else {
            // we want to temp cache the mappings since we might need it again later in the loop
            val toOrgMapping = getMappingToOrg(originScheme)
            reference.usfmArray.forEach { sourceUsfm ->
                val orgRef = mapReference(toOrgMapping, sourceUsfm.splitUsfm())
                val mappedRef = mapReference(fromOrgMapping, orgRef)
                mappedUsfms.add("${mappedRef.first}.${mappedRef.second}.${mappedRef.third}")
            }
        }
        return ReferenceBuilder.newBuilder().withUsfms(mappedUsfms).withVersion(versionId).build().freeze()
    }

    internal companion object {

        internal const val baseUrl = "https://red-data.youversionapi.com/vrs/v1/"

        internal val vrsSchemeIds = mapOf(1 to "eng", 2 to "org", 3 to "rsc", 4 to "lxx", 5 to "rso", 6 to "vul")
    }
}
