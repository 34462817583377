package youversion.red.downloads.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class DownloadModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
