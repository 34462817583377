// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Topic(
  /**
   * topic id
   */
  @SerialName("id")
  @ProtoNumber(2)
  @JvmField
  val id: Int? = null,
  /**
   * topic text
   */
  @SerialName("text")
  @ProtoNumber(1)
  @JvmField
  val text: String? = null,
  /**
   * Words that are similar to the main topic. Often synonyms or antonyms.
   */
  @SerialName("subtopics")
  @ProtoNumber(3)
  @JvmField
  val related: List<String>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
