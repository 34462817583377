/**
 * @module parseQuery
 */
import { isInBrowser } from 'environment'

/**
 * Parses a query string into an object of key/value string pairs.
 *
 * @param {string} queryString - The query string.
 *
 * @throws {Error} - Throws an error if this function is not run in a browser.
 *
 * @returns {object<string>} - The querystring key/value string pairs.
 *
 * @example
 * // returns `{length: '12', status: 'active', disabled: 'false'}`
 * paramsFromQuery('?length=12&status=active&disabled=false')
 *
 * @example
 * import { useLocation } from 'react-router-dom'
 * import { paramsFromQuery } from '@youversion/utils'
 *
 * function MyComponent() {
 *   const location = useLocation()
 *
 *   const [query, setQuery] = React.useState(
 *     () => paramsFromQuery(location.search)
 *   )
 *
 *   React.useEffect(() => {
 *     setQuery(paramsFromQuery(location.search))
 *   }, [location.search])
 *
 *   return (
 *     <>
 *       {query?.status === 'active' ?? 'Displaying active plans'}
 *     </>
 *   )
 * }
 */
export function paramsFromQuery(queryString) {
  if (!isInBrowser()) {
    throw new Error(
      '`paramsFromQuery` only works in a browser. Please use an alternative method or update this function to have server-side support.',
    )
  }

  const output = {}

  const queryIterator = new URLSearchParams(queryString)
  queryIterator.forEach((value, key) => {
    output[key] = value
  })

  return output
}
