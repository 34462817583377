package youversion.red.prayer.model

import red.Serializable
import red.platform.Date
import red.platform.threads.ensureNeverFrozen
import red.platform.threads.freeze
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.service.PrayerStore.NEW_CLIENT_ID

data class Prayer(
    val clientId: String,
    internal val serverId: Int? = null,
    val title: String? = null,
    val userId: Long? = null,
    val answeredDt: Date? = null,
    val createdDt: Date? = null,
    val updatedDt: Date? = null,
    val content: String? = null,
    val sharingPolicy: SharingPolicy = SharingPolicy.ONLYYOU,
    val status: StatusType = StatusType.ACTIVE,
    val lastPrayerUpdate: Date? = null,
    val usfm: String? = null,
    val versionId: Int? = null,
    val reactionCount: Int? = null,
    val fromUsers: List<Long> = emptyList(),
    val updated: Boolean = false,
    val commentEnabled: Boolean = false,
    internal val seenUpdate: Date? = null,
    internal val state: Int = 0,
    internal val lastSync: Date? = null,
    internal val orderIndex: Int = 0
) : Serializable {

    init {
        freeze()
    }

    fun newBuilder() = PrayerBuilder(this)
}

class PrayerBuilder(private val oldPrayer: Prayer? = null) {

    init {
        ensureNeverFrozen()
    }

    var title: String? = oldPrayer?.title
    var content: String? = oldPrayer?.content
    var answeredDt: Date? = oldPrayer?.answeredDt
    var sharingPolicy: SharingPolicy = oldPrayer?.sharingPolicy ?: SharingPolicy.ONLYYOU
    var status: StatusType = oldPrayer?.status ?: StatusType.ACTIVE
    var usfm: String? = oldPrayer?.usfm
    var versionId: Int? = oldPrayer?.versionId

    fun build() = oldPrayer?.copy(
        title = title,
        content = content,
        answeredDt = answeredDt,
        sharingPolicy = sharingPolicy,
        status = status,
        usfm = usfm,
        versionId = versionId
    ) ?: Prayer(
        clientId = NEW_CLIENT_ID,
        title = title,
        content = content,
        answeredDt = answeredDt,
        sharingPolicy = sharingPolicy,
        status = status,
        usfm = usfm,
        versionId = versionId
    )

    fun withTitle(title: String? = null): PrayerBuilder {
        this.title = title
        return this
    }

    fun withContent(content: String? = null): PrayerBuilder {
        this.content = content
        return this
    }

    fun withAnsweredDt(answeredDt: Date? = null): PrayerBuilder {
        this.answeredDt = answeredDt
        return this
    }

    fun withSharingPolicy(sharingPolicy: SharingPolicy): PrayerBuilder {
        this.sharingPolicy = sharingPolicy
        return this
    }

    fun withStatus(status: StatusType): PrayerBuilder {
        this.status = status
        return this
    }

    fun withUsfm(usfm: String? = null): PrayerBuilder {
        this.usfm = usfm
        return this
    }

    fun withVersionId(versionId: Int? = null): PrayerBuilder {
        this.versionId = versionId
        return this
    }
}
