// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SearchVersesFiltersBook(
  /**
   * USFM abbreviation of the Bible book name.
   */
  @SerialName("usfm")
  @ProtoNumber(1)
  @JvmField
  val usfm: String? = null,
  /**
   * Number of search hits for given book.
   */
  @SerialName("count")
  @ProtoNumber(2)
  @JvmField
  val count: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
