package youversion.red.movies.service

import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.movies.Movie
import youversion.red.movies.MovieChapters
import youversion.red.movies.MovieLanguages
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.Movies
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesCollections
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.VideoMetadata
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.videos.VideoTypeEnum

fun MoviesService() = service<IMoviesService>()

@ExportedService
interface IMoviesService : IService {

    suspend fun getConfiguration(): MoviesConfiguration?

    suspend fun getPublisher(id: Int): MoviePublisher?

    suspend fun getMovie(id: Int, context: MoviesUIContext): Movie?

    suspend fun getChapters(): MovieChapters?

    suspend fun getMoviesRelatedTo(usfm: String, page: Int? = null): Pair<Movies, String?>

    suspend fun getMoviesInCollection(
        id: Int,
        type: VideoTypeEnum? = null,
        orientation: MovieOrientation? = null,
        pageSize: Int? = null,
        page: Int? = null
    ): Pair<Movies, String?>

    suspend fun getCollection(id: Int): MoviesCollection?

    suspend fun getLanguages(type: String): MovieLanguages?

    suspend fun getCollections(page: Int? = null): Pair<MoviesCollections, String?>

    suspend fun getLegacyVideo(id: Int): VideoMetadata?

    suspend fun getActualMovieId(id: Int): Pair<Int, MoviesCollection?>?
}
