package youversion.red.analytics.db

import com.squareup.sqldelight.db.SqlDriver
import red.database.DateAdapter
import youversion.red.analytics.CollectorTypeAdapter

fun createQueryWrapper(driver: SqlDriver): AnalyticsDatabase {
    return AnalyticsDatabase(
        driver = driver,
        eventsAdapter = Events.Adapter(
            CollectorTypeAdapter(),
            DateAdapter()
        )
    )
}

object Schema : SqlDriver.Schema by AnalyticsDatabase.Schema {
    override fun create(driver: SqlDriver) {
        AnalyticsDatabase.Schema.create(driver)
        createQueryWrapper(driver)
    }
}
