package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import red.platform.Date
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType

public data class Prayer(
  public val clientId: String,
  public val serverId: Int?,
  public val title: String?,
  public val userId: Long?,
  public val answeredDt: Date?,
  public val createdDt: Date?,
  public val updatedDt: Date?,
  public val content: String?,
  public val sharingPolicy: SharingPolicy?,
  public val status: StatusType?,
  public val lastPrayerUpdate: Date?,
  public val usfm: String?,
  public val versionId: Int?,
  public val reactionCount: Int?,
  public val fromUsers: List<Long>?,
  public val seenUpdate: Date?,
  public val updated: Boolean?,
  public val state: Int?,
  public val lastSync: Date?,
  public val orderIndex: Int?,
  public val commentEnabled: Boolean?
) {
  public override fun toString(): String = """
  |Prayer [
  |  clientId: $clientId
  |  serverId: $serverId
  |  title: $title
  |  userId: $userId
  |  answeredDt: $answeredDt
  |  createdDt: $createdDt
  |  updatedDt: $updatedDt
  |  content: $content
  |  sharingPolicy: $sharingPolicy
  |  status: $status
  |  lastPrayerUpdate: $lastPrayerUpdate
  |  usfm: $usfm
  |  versionId: $versionId
  |  reactionCount: $reactionCount
  |  fromUsers: $fromUsers
  |  seenUpdate: $seenUpdate
  |  updated: $updated
  |  state: $state
  |  lastSync: $lastSync
  |  orderIndex: $orderIndex
  |  commentEnabled: $commentEnabled
  |]
  """.trimMargin()

  public class Adapter(
    public val answeredDtAdapter: ColumnAdapter<Date, Long>,
    public val createdDtAdapter: ColumnAdapter<Date, Long>,
    public val updatedDtAdapter: ColumnAdapter<Date, Long>,
    public val sharingPolicyAdapter: ColumnAdapter<SharingPolicy, String>,
    public val statusAdapter: ColumnAdapter<StatusType, String>,
    public val lastPrayerUpdateAdapter: ColumnAdapter<Date, Long>,
    public val fromUsersAdapter: ColumnAdapter<List<Long>, String>,
    public val seenUpdateAdapter: ColumnAdapter<Date, Long>,
    public val lastSyncAdapter: ColumnAdapter<Date, Long>
  )
}
