// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.discover

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent
import youversion.red.dataman.api.model.DiscoverSectionType

@Serializable
@SerialName("discover.DiscoverItemTap")
data class DiscoverItemTap(
  @SerialName("section_type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.DiscoverSectionTypeSerializer::class)
  val sectionType: DiscoverSectionType? = null,
  /**
   * Index of the section containing the tapped item. The section index for the search bar is null.
   */
  @SerialName("section_index")
  @ProtoNumber(2)
  val sectionIndex: Int? = null,
  /**
   * Index of the tapped item in the list it's contained in
   */
  @SerialName("tapped_index")
  @ProtoNumber(3)
  val tappedIndex: Int? = null,
  /**
   * The item description (e.g. reading plan title, video title, More items (plans, video, image),
   * emotion text)
   */
  @SerialName("item_text")
  @ProtoNumber(4)
  val itemText: String? = null,
  /**
   * plan id, video id, null 
   */
  @SerialName("item_id")
  @ProtoNumber(5)
  val itemId: Int? = null,
  /**
   * App interface language
   */
  @SerialName("app_language")
  @ProtoNumber(6)
  val appLanguage: String? = null,
  /**
   * Plan language
   */
  @SerialName("plan_language")
  @ProtoNumber(7)
  val planLanguage: String? = null,
  /**
   * Video language
   */
  @SerialName("video_language")
  @ProtoNumber(8)
  val videoLanguage: String? = null,
  /**
   * True when the item was tapped within the "See All" list view, otherwise false
   */
  @SerialName("tapped_in_see_all")
  @ProtoNumber(9)
  val tappedInSeeAll: Boolean? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    sectionType: DiscoverSectionType? = null,
    sectionIndex: Int? = null,
    tappedIndex: Int? = null,
    itemText: String? = null,
    itemId: Int? = null,
    appLanguage: String? = null,
    planLanguage: String? = null,
    videoLanguage: String? = null,
    tappedInSeeAll: Boolean? = null
  ) : this(sectionType, sectionIndex, tappedIndex, itemText, itemId, appLanguage, planLanguage,
      videoLanguage, tappedInSeeAll, red.platform.now())
}
