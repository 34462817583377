package youversion.red.blue.module

import red.lifecycle.AppLifecycle
import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleState
import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.threads.freeze
import youversion.red.blue.service.BlueListener
import youversion.red.blue.service.CacheClearListenerImpl
import youversion.red.cache.CacheClearService
import youversion.red.security.service.UsersService

@ModuleDependencies("core", "installation", "geoip")
class BlueModuleInitializer : IModuleInitializer {

    private val usersService by UsersService()
    private val blueListener = BlueListener().freeze()
    private val cacheListener = CacheClearListenerImpl().freeze()

    override fun initialize() {
        AppLifecycle.register(blueListener)
        usersService.addListener(blueListener)
        CacheClearService.register(cacheListener)
        // hack to make things happen sooner
        blueListener.onStateChanged(AppLifecycleState.Allocated, AppLifecycleEventSource.System)
    }
}
