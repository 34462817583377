package youversion.red.prayer.tasks

import com.squareup.sqldelight.internal.AtomicBoolean
import red.platform.Log
import red.platform.currentTimeMillis
import red.platform.io.IOException
import red.platform.settings.Settings
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.PrayerUser
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.service.PrayerStore

object PrayerUsersSync {

    private val syncing = AtomicBoolean(false)
    private const val CACHE_TIME = 200000L // todo: tweak?
    private const val CACHE_PREFIX = "red.prayer.prayer.users.last.sync"
    private fun getCacheKey(page: Int) = "$CACHE_PREFIX.page.$page"

    suspend fun process(page: Int = 1, force: Boolean = false): Boolean {
        assertNotMainThread()
        requireNetwork { return false }
        requireUser { return false }

        PrayerIdsSync.process(force)

        ensureNotCached(getCacheKey(page), CACHE_TIME, force) { return false }

        val updates = mutableListOf<PrayerUser>()
        var nextPage = false
        syncing.sync {
            try {
                val users = PrayerApi.getUsers(page = page)
                users?.data?.forEach {
                    updates.add(it)
                }
                nextPage = users?.nextPage == true
            } catch (e: Exception) {
                // TODO:
                Log.e("red-prayer", "error syncing users page: $page", e)
                @Suppress("DEPRECATION_ERROR")
                if (e !is IOException) {
                    throw e
                }
            }

            Settings.defaultSettings.edit().putLong(getCacheKey(page), currentTimeMillis().toLong()).commit()
            PrayerStore.processUsers(updates.toList())
        }

        return nextPage
    }

    suspend fun syncUnreadState(userId: Long?) {
        assertNotMainThread()
        if (userId == null)
            return
        val prayers = PrayerStore.getPrayersByUserId(userId)
        val userUnread = prayers.find { it.updated && it.status == StatusType.ACTIVE } != null
        PrayerStore.getPrayerUserSync(userId)?.let {
            if (it.isUpdated != userUnread)
                PrayerStore.updateUserUpdated(userId, userUnread)
        }
    }

    internal fun clearCache() {
        val editor = Settings.defaultSettings.edit()
        Settings.defaultSettings.keys.filter { it.startsWith(CACHE_PREFIX) }
            .forEach { editor.remove(it) }
        editor.commit()
    }
}
