// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.images

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class OrganizationImage(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  /**
   * The image proxy pre-pended url to the image from the relevant GCS bucket for the asset.
   */
  @SerialName("image_url")
  @ProtoNumber(2)
  @JvmField
  val imageUrl: String = "",
  /**
   * How tall is this image?
   */
  @SerialName("height")
  @ProtoNumber(3)
  @JvmField
  val height: Int = 0,
  /**
   * How wide is this image?
   */
  @SerialName("width")
  @ProtoNumber(4)
  @JvmField
  val width: Int = 0
) : red.Serializable {
  init {
    freeze()
  }
}
