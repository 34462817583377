package youversion.red.prayer.guided.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.String
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType

public data class Guided_prayer_module(
  public val guideId: Int,
  public val dayId: Int,
  public val id: Int,
  public val type: GuidedPrayerModuleType?,
  public val title: String?,
  public val header: String?,
  public val usfm: String?,
  public val text: String?,
  public val ctaText: String?,
  public val ctaUrl: String?,
  public val animationId: Int?
) {
  public override fun toString(): String = """
  |Guided_prayer_module [
  |  guideId: $guideId
  |  dayId: $dayId
  |  id: $id
  |  type: $type
  |  title: $title
  |  header: $header
  |  usfm: $usfm
  |  text: $text
  |  ctaText: $ctaText
  |  ctaUrl: $ctaUrl
  |  animationId: $animationId
  |]
  """.trimMargin()

  public class Adapter(
    public val typeAdapter: ColumnAdapter<GuidedPrayerModuleType, String>
  )
}
