/**
 * @module getAccount
 */
import { get } from 'partner-portal/core/clients/api-client'
import { getToken } from 'partner-portal/core/clients/auth-client'

/**
 * Gets user account data.
 *
 * @throws {Error} - Throws an error if there is an error response.
 *
 * @returns {Promise<object>} - The user account data.
 */
export async function getAccount() {
  try {
    const accountResponse = get({
      options: {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
      path: 'account',
    })
    return accountResponse.$data.user.$value
  } catch (error) {
    throw new Error(error.message)
  }
}
