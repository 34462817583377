/**
 * @module ConditionalSnackbar
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Snackbar } from '@material-ui/core'

/**
 * A conditionally enabled snackbar wrapper.
 *
 * @alias module:ConditionalSnackbar
 *
 * @param {object} props - The component props object.
 * @param {object} [props.anchorOrigin] - (Optional) The snackbar anchor origin.
 * @param {React.ReactChildren} [props.children] - (Optional) The snackbar children elements.
 * @param {boolean} [props.enabled] - (Optional) Enable the snackbar.
 * @param {boolean} [props.open] - (Optional) The snackbar open state.
 * @param {object} [props.TransitionProps] - (Optional) - The snackbar transition props.
 *
 * @returns {React.ReactElement} - The conditional snackbar.
 */
export function ConditionalSnackbar({
  anchorOrigin,
  children,
  enabled,
  open,
  TransitionProps,
  ...props
}) {
  return (
    <>
      {enabled ? (
        <Snackbar
          TransitionProps={TransitionProps}
          anchorOrigin={anchorOrigin}
          open={open}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        >
          {children}
        </Snackbar>
      ) : (
        children
      )}
    </>
  )
}

ConditionalSnackbar.propTypes = {
  anchorOrigin: PropTypes.objectOf(PropTypes.string),
  children: PropTypes.node,
  enabled: PropTypes.bool,
  open: PropTypes.bool,
  TransitionProps: PropTypes.objectOf(PropTypes.any),
}

ConditionalSnackbar.defaultProps = {
  anchorOrigin: { vertical: 'top', horizontal: 'center' },
  children: undefined,
  enabled: false,
  open: false,
  TransitionProps: {
    enter: false,
    exit: false,
  },
}
