// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Comment(
  /**
   * Unique identifier for the comment
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The user's id number
   */
  @SerialName("user_id")
  @ProtoNumber(2)
  @JvmField
  val userId: Long? = null,
  /**
   * Body of the comment message
   */
  @SerialName("message")
  @ProtoNumber(3)
  @JvmField
  val message: String? = null,
  /**
   * When was this comment updated?
   */
  @SerialName("updated_dt")
  @ProtoNumber(4)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * When was this comment created?
   */
  @SerialName("created_dt")
  @ProtoNumber(5)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
