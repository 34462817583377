@file:Suppress("DEPRECATION")

package youversion.red.versification.service

import kotlin.js.Promise
import red.tasks.CoroutineDispatchers.async
import red.tasks.toPromise
import youversion.red.bible.reference.BibleReference
import youversion.red.versification.VersificationTransaction

fun VersificationTransaction.versify(
    references: BibleReference,
    versionId: Int
): Promise<BibleReference?> =
    async { versification.versifier.versify(references, versionId) }.toPromise()
