package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date

public data class Prayer_users(
  public val userId: Long,
  public val updated: Boolean?,
  public val lastSync: Date?,
  public val orderIndex: Int?
) {
  public override fun toString(): String = """
  |Prayer_users [
  |  userId: $userId
  |  updated: $updated
  |  lastSync: $lastSync
  |  orderIndex: $orderIndex
  |]
  """.trimMargin()

  public class Adapter(
    public val lastSyncAdapter: ColumnAdapter<Date, Long>
  )
}
