// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api

import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.blue.api.model.configuration.Configuration
import youversion.red.blue.api.model.enrollment.DeviceEnrollment
import youversion.red.blue.api.model.enrollment.Enrollment
import youversion.red.blue.api.model.state.DeviceState
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.api.model.state.State
import youversion.red.blue.api.model.state.UserIdBatch

/**
 * Blue API for managing YouVersion feature flags
 */
object BlueApi : AbstractApi(ApiDefaults("blue", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", BlueApiSerializer)) {
  /**
   * @return Successful request
   */
  suspend fun getConfiguration(): Configuration = execute("/configuration", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      Configuration.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param userid The user ID that is used to determine state
   */
  suspend fun enrollDeprecated(userid: Int, body: Enrollment? = null) = execute("/enroll/${userid}",
      version = "4.0", method = RequestMethods.POST, authAllowed = false, authOptional = false, body
      = body, bodySerializer = Enrollment.serializer(), serializer = Unit.serializer())

  /**
   * @param featureid The feature ID that is used to determine state
   * @param userid The user ID that is used to determine state
   * @return Successful request
   */
  suspend fun getStateDeprecated(featureid: String, userid: Int): State =
      execute("/state/${featureid}/${userid}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, serializer = State.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param featureid The feature ID that is used to determine state
   * @param userid The user ID that is used to determine state
   * @return Successful request
   */
  suspend fun setStateDeprecated(
    featureid: String,
    userid: Int,
    body: State? = null
  ): State = execute("/state/${featureid}/${userid}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = false, authOptional = false, body = body, bodySerializer = State.serializer(),
      serializer = State.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param featureid The feature ID that is used to determine state
   * @return Successful request
   */
  suspend fun setBatchState(featureid: String, body: UserIdBatch? = null): UserIdBatch =
      execute("/state/${featureid}", version = "4.0", method = RequestMethods.PUT, authAllowed =
      false, authOptional = false, body = body, bodySerializer = UserIdBatch.serializer(),
      serializer = UserIdBatch.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param installationid The device installation ID
   * @return Successfully enrolled
   */
  suspend fun enroll(installationid: String, body: DeviceEnrollment? = null): DeviceState =
      execute("/enroll/${installationid}", version = "4.1", method = RequestMethods.POST,
      authAllowed = false, authOptional = false, body = body, bodySerializer =
      DeviceEnrollment.serializer(), serializer = DeviceState.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param installationid The device installation ID
   * @return Successfully enrolled
   */
  suspend fun getDeviceState(installationid: String): DeviceState =
      execute("/state/${installationid}", method = RequestMethods.GET, version = "4.1", authAllowed
      = false, authOptional = false, serializer = DeviceState.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param installationid The device installation ID
   * @param featureid The feature ID that is used to determine state
   * @return Successfully enrolled
   */
  suspend fun setFeatureState(
    installationid: String,
    featureid: String,
    body: FeatureState? = null
  ): FeatureState = execute("/state/${installationid}/${featureid}", version = "4.1", method =
      RequestMethods.PUT, authAllowed = false, authOptional = false, body = body, bodySerializer =
      FeatureState.serializer(), serializer = FeatureState.serializer()) ?: throw
      NullPointerException("Response not sent from server")
}
