package youversion.red.analytics

import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.serialization.InternalSerializationApi
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Log
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestBuilder
import red.platform.http.RequestManager
import red.platform.http.RequestMethods
import red.platform.http.newURL
import red.platform.io.ByteArrayOutputStream
import red.platform.io.ByteBuffer
import red.platform.io.ByteOrder
import red.platform.newDate
import red.platform.platformType
import red.platform.threads.AtomicLong
import red.platform.threads.AtomicReference
import red.platform.threads.Lock
import red.platform.threads.freeze
import red.platform.threads.incr
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import red.tasks.assertNotMainThread
import youversion.red.analytics.db.AnalyticsDb
import youversion.red.analytics.db.Events
import youversion.red.analytics.db.queries

@Serializable
internal data class MessageType(
    @Serializable(with = CollectorTypeSerializer::class) @ProtoNumber(1) val type: CollectorType
)

expect object DataManTimer {

    fun register(timeInSeconds: Int, callback: suspend () -> Unit)
    fun unregister()
}

interface DataManEvent

interface DataManInterface {

    fun serialize(event: DataManEvent): ByteArray
    suspend fun send(events: List<Events>): List<Long>
}

object DataMan {

    private val lock = Lock()
    private val dataManInterfaceInstance = AtomicReference<DataManInterface?>(null)
    private val flushing = AtomicReference(false)
    internal val memoryEvents = AtomicReference(emptyList<Events>())
    private val memoryEventIds = AtomicLong(0)

    var dataManInterface: DataManInterface
        get() = dataManInterfaceInstance.value!!
        set(value) {
            dataManInterfaceInstance.setAssertTrue(value)
        }

    val pendingEvents: Int
        get() = if (platformType == PlatformType.JavaScript) {
            memoryEvents.value.size
        } else {
            AnalyticsDb.queries.selectAll().executeAsList().size
        }

    suspend fun collect(event: DataManEvent) = collect(CollectorType.V2, dataManInterface.serialize(event))

    @OptIn(DelicateCoroutinesApi::class)
    internal suspend fun collect(type: CollectorType, message: ByteArray) {
        assertNotMainThread()
        type.freeze()
        message.freeze()
        if (platformType == PlatformType.JavaScript) {
            memoryEvents.setAssertTrue(
                memoryEvents.value + Events(
                    memoryEventIds.incr(),
                    type,
                    newDate(),
                    message
                )
            )
            GlobalScope.launch {
                flushIfNeeded()
            }
        } else {
            AnalyticsDb.queries.add(ctype = type, created = newDate(), message = message)
        }
    }

    internal suspend fun flushIfNeeded() {
        Log.d("DataMan", "flushIfNeeded")
        val pending = if (platformType == PlatformType.JavaScript) {
            memoryEvents.value.size
        } else {
            AnalyticsDb.queries.count().executeAsOne().toInt()
        }
        if (pending > 0) {
            flushNow()
        }
    }

    private fun getPendingEventsV1(): List<Events> = if (platformType == PlatformType.JavaScript) {
        memoryEvents.value.filter { it.ctype != CollectorType.V2 }
    } else {
        AnalyticsDb.queries.selectAllV1().executeAsList()
    }

    private fun getPendingEventsV2(): List<Events> {
        return if (platformType == PlatformType.JavaScript) {
            memoryEvents.value.filter { it.ctype == CollectorType.V2 }
        } else {
            AnalyticsDb.queries.selectAllV2().executeAsList()
        }
    }

    private fun evictPendingEvents(ids: List<Long>) {
        if (platformType == PlatformType.JavaScript) {
            val idSet = ids.toSet()
            memoryEvents.setAssertTrue(memoryEvents.value.filter { it.id !in idSet })
        } else {
            AnalyticsDb.queries.transaction {
                AnalyticsDb.queries.deleteByIds(ids)
            }
            Log.d("DataMan", "evictPendingEvents")
        }
    }

    suspend fun forceFlushNow() {
        flushing.setAssertTrue(false)
        flushNow()
    }

    @OptIn(InternalSerializationApi::class)
    suspend fun flushNow() {
        assertNotMainThread()
        lock.sync {
            // ensure we're only running one at a time
            if (flushing.value)
                return
            flushing.setAssertTrue(true)
        }
        try {
            try {
                flushV1()
            } catch (e: Exception) {
                Log.e("DataMan", "Failed to process events", e)
            }
            flushV2()
        } catch (e: Exception) {
            Log.e("DataMan", "Failed to process events", e)
        } finally {
            flushing.setAssertTrue(false)
        }
    }

    @OptIn(InternalSerializationApi::class)
    private suspend fun flushV1() {
        val eventBatch = getPendingEventsV1()
        if (eventBatch.isEmpty()) {
            return
        }
        val ids = mutableListOf<Long>()
        val out = ByteArrayOutputStream()
        if (Int.SIZE_BYTES != 4) {
            Log.e("DataMan", "Ooops, invalid Int size ${Int.SIZE_BYTES} != 4")
        }
        eventBatch.forEach {
            val type = ProtoBuf.encodeToByteArray(MessageType.serializer(), MessageType(it.ctype))
            out.write(type.size.toArray())
            out.write(type)
            out.write(it.message.size.toArray())
            out.write(it.message)
            ids.add(it.id)
        }
        ids.freeze()
        val request = RequestBuilder()
            .url(newURL("https://dataman.${if (RequestManager.staging) "youversionapistaging.com" else "youversionapi.com"}"))
            .method(RequestMethods.POST)
            .header("Content-Type", ContentTypes.PROTO.contentType)
            .body(out.toByteArray())
            .build()
        val response = RequestManager.execute(request, null)
        response.throwIfNeeded()
        evictPendingEvents(ids)
    }

    private suspend fun flushV2() {
        val eventBatch = getPendingEventsV2()
        if (eventBatch.isEmpty()) {
            return
        }
        val ids = dataManInterface.send(eventBatch)
        evictPendingEvents(ids.freeze())
    }
}

@Suppress("DEPRECATION_ERROR")
private fun Int.toArray(): ByteArray {
    val buf = ByteBuffer.allocate(Int.SIZE_BYTES)
    buf.order(ByteOrder.LITTLE_ENDIAN)
    buf.putInt(this)
    return buf.array()
}
