package youversion.red.login

enum class AuthError {
    USERNAME_INVALID,
    PASSWORD_INVALID,
    ACCOUNT_ALREADY_VERIFIED
}

interface AuthViewModelListener {
    fun showAuthView(type: AuthViewType)
    fun showError(error: AuthError)
    fun formFieldValidationError(fields: Set<FieldValidationError>)
}
