package youversion.red.prayer.guided.util

import red.platform.settings.Settings

object GuidedPrayerPreferences {

    private const val KEY_MUSIC = "red.guided-prayer.music.selected-variation"
    private const val KEY_AUDIO_SHOWN = "red.guided-prayer.music.shown"

    private val prefs by lazy {
        Settings.defaultSettings
    }

    var currentBackgroundMusic: String?
        set(value) {
            prefs.edit().apply {
                if (value == null) {
                    remove(KEY_MUSIC)
                } else {
                    putString(KEY_MUSIC, value)
                }
            }.commit()
        }
        get() = prefs.getString(KEY_MUSIC)

    var isAudioOptionShown: Boolean
        set(value) = prefs.edit().putBoolean(KEY_AUDIO_SHOWN, value).commit()
        get() = prefs.getBoolean(KEY_AUDIO_SHOWN, false)

    fun clear() {
        prefs.edit().apply {
            remove(KEY_MUSIC)
        }.commit()
    }
}
