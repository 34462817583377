// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Kind of suggestions
 */
enum class Kinds(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALL("all", 1),

  EVENTS("events", 2),

  FEATURES("features", 3),

  PLANS("plans", 4),

  SUPPORT_ARTICLES("support-articles", 5),

  TOPICS("topics", 6),

  USERS("users", 7),

  VERSES("verses", 8),

  VIDEOS("videos", 9),

  IMAGES("images", 10),

  PODCASTS("podcasts", 11),

  CHURCHES("churches", 12);
}

internal class KindsSerializer : KSerializer<Kinds> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.Kinds")

  override fun serialize(encoder: Encoder, value: Kinds) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): Kinds {
    if (decoder is JsonDecoder) {
      return Kinds.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> Kinds.UNSUPPORTED_PLACEHOLDER
      1 -> Kinds.ALL
      2 -> Kinds.EVENTS
      3 -> Kinds.FEATURES
      4 -> Kinds.PLANS
      5 -> Kinds.SUPPORT_ARTICLES
      6 -> Kinds.TOPICS
      7 -> Kinds.USERS
      8 -> Kinds.VERSES
      9 -> Kinds.VIDEOS
      10 -> Kinds.IMAGES
      11 -> Kinds.PODCASTS
      12 -> Kinds.CHURCHES
      else -> Kinds.UNSUPPORTED_PLACEHOLDER
    }
  }
}
