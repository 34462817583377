/**
 * @module AuthClient
 */
import { isInBrowser } from '@youversion/utils'
import * as partnerPortalApiClient from 'partner-portal/core/clients/api-client'
import { AuthenticationError } from 'partner-portal/core/errors'

const baseUrl = process.env.REACT_APP_API_ADDRESS || 'http://localhost:3001'

const localStorageKey = 'token'

const method = 'POST'

const headers = {
  'Content-Type': 'application/json',
}

/**
 * Deletes the token from local storage.
 */
function deleteToken() {
  if (!isInBrowser()) {
    return
  }

  if (window.localStorage) {
    window.localStorage.removeItem(localStorageKey)
  }
}

/**
 * Checks if a token is valid and current.
 *
 * @param {string} token - The token.
 *
 * @returns {boolean} - Whether or not the token is valid.
 */
async function isTokenValid(token) {
  if (!token) {
    return false
  }
  try {
    // Per API docs, the token is valid if there is a 204 response to session DELETE.
    await partnerPortalApiClient.del({
      path: 'session',
      token,
    })
    return true
  } catch (error) {
    // Token is invalid if non-20x response to session DELETE.
    deleteToken()
    return false
  }
}

/**
 * Saves the token object to local storage.
 *
 * @param {string} token - The token.
 *
 * @throws {Error} - Throws an error if running on a server.
 *
 * @returns {boolean} The success of the storage attempt.
 */
function saveTokenToStorage(token) {
  if (!isInBrowser()) {
    throw new Error('Cannot save token while running on server.')
  }

  try {
    localStorage.setItem(localStorageKey, JSON.stringify(token))
    return true
  } catch (error) {
    return false
  }
}

/**
 * Fetches and stores a token.
 *
 * @param {string} url - The fetch url.
 * @param {object} options - The fetch options.
 *
 * @throws {Error} - Throws an AuthenticationError if there is no token in the response.
 *
 * @returns {Promise<string>} The token string.
 */
async function fetchAndStoreToken(url, options = {}) {
  const response = await fetch(url, options)
  const json = await response.json()

  if (!json?.meta?.token) {
    deleteToken()
    throw new AuthenticationError(json)
  }

  if (isInBrowser()) {
    saveTokenToStorage(json.meta.token)
  }
  return json.meta.token
}

/**
 * Fetch a new token.
 *
 * @param {object} authenticationParams - The authentication parameters.
 *
 * @returns {Promise<string>} A token.
 */
async function newToken(authenticationParams) {
  return fetchAndStoreToken(`${baseUrl}/session`, {
    body: JSON.stringify(authenticationParams),
    headers,
    method,
  })
}

/**
 * Gets the token.
 *
 * @returns {string} A token.
 *
 * @example
 * // Token is saved as `12345` in local storage. Function returns `12345`.
 * const token = getToken()
 */
export function getToken() {
  if (!isInBrowser()) {
    return null
  }

  let token
  try {
    token = JSON.parse(localStorage.getItem(localStorageKey))
  } catch (error) {
    token = null
  }
  return token
}

/**
 * Signs in a user.
 *
 * @param {object} params - The function params.
 * @param {string} params.password - The password.
 * @param {string} params.username - The username.
 *
 * @returns {Promise<string>} A token.
 *
 * @example
 * // Sets the token in storage and returns the value.
 * const token = await signIn({username: 'admin', password: '12345'})
 */
export async function signIn({ password, username }) {
  return newToken({ user: { password, username } }, false)
}

/**
 * Signs out the user and destroys the local storage tokens.
 *
 * @param {Function} [callback] - The callback function to run once signOut is complete.
 *
 * @returns {Function} - Returns the callback function if one is supplied.
 *
 * @example
 * // Signs out the user and redirects them to the sign in page.
 * signOut(() => history.push('/sign-in'))
 */
export function signOut(callback) {
  if (callback) {
    deleteToken()
    return callback()
  }
  return deleteToken()
}

/**
 * Checks if the user is signed in.
 *
 * @returns {Promise<boolean>} - Whether or not the user is signed in.
 *
 * @example
 * // Returns true or false depending on if a valid token exists or not.
 * const signedIn = await isSignedIn()
 */
export async function isSignedIn() {
  const token = getToken()
  return isTokenValid(token)
}
