package youversion.red.churches.service.repository

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import red.service.ServiceRegistry
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.churches.ext.toConcreteBaseProfile
import youversion.red.churches.ext.toLocation
import youversion.red.churches.ext.toOrganization
import youversion.red.churches.ext.toServiceTime
import youversion.red.churches.ext.toUserId
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.organizations.api.OrganizationsApi
import youversion.red.organizations.api.model.organizations.OrganizationJoin
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.plans.model.Plan
import youversion.red.plans.service.IPlansService

@Suppress("UNUSED_PARAMETER")
internal object OrganizationsRepository {

    suspend fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?) = withIO {
        val preference = OrganizationJoin(visibilityPreference)
        OrganizationsApi.joinOrganization(id, preference)
    }

    suspend fun leaveOrganization(id: String) = withIO {
        OrganizationsApi.leaveOrganization(id)
    }

    fun getFeaturedPlan(organizationId: String): Flow<Plan?> = flow {
        val plansService = ServiceRegistry[IPlansService::class]
        val featuredPlans = OrganizationsApi.getOrganizationFeaturedPlans(organizationId)?.data?.map {
            plansService.getPlan(it.planId)
        }
        emit(featuredPlans?.first())
    }

    suspend fun getOrganizations(userId: Int): List<Organization> = withIO {
        try {
            OrganizationsApi.getOrganizations(userId.toLong()).data.map { it.toOrganization() }
        } catch (e: NullPointerException) {
            emptyList()
        }
    }

    fun getOrganizationProfiles(id: String): Flow<List<BaseOrganizationProfile>> = flow {
        val profiles = OrganizationsApi.getOrganizationProfiles(id)?.data?.mapNotNull {
            try {
                it.toConcreteBaseProfile()
            } catch (e: Exception) {
                null
            }
        }
        emit(profiles ?: emptyList())
    }

    fun getOrganization(id: String): Flow<Organization?> = flow {
        val organization = OrganizationsApi.getOrganization(id)?.toOrganization()
        emit(organization)
    }

    suspend fun suspendGetOrganization(id: String) = withIO {
        OrganizationsApi.getOrganization(id)?.toOrganization()
    }

    fun getLocations(organizationId: String, latitude: Double?, longitude: Double?): Flow<List<Location>> = flow {
        val locations = OrganizationsApi.getOrganizationsOrganizationLocations(
            organizationId,
            clientLatitude = latitude,
            clientLongitude = longitude
        )?.data?.map { it.toLocation() }
        emit(locations ?: emptyList())
    }

    fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>> = flow {
        val serviceTimes = OrganizationsApi.getServiceTimes(organizationId)?.data?.map { it.toServiceTime() }
        emit(serviceTimes ?: emptyList())
    }

    suspend fun getChurchFriends(organizationId: String, userId: Int): List<Int> = withIO {
        OrganizationsApi.getOrganizationMembers(organizationId, userId.toLong())?.data?.map { it.toUserId() }
            ?: emptyList()
    }

    fun getMembersCount(organizationId: String): Flow<Int> = flow {
        val churchMembers = OrganizationsApi.getOrganizationMembers(organizationId)?.totalMembers ?: 0
        emit(churchMembers)
    }
}
