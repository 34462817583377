// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchFilterTap(
  /**
   * text searched for (includes mis-spellings)
   */
  @SerialName("query")
  @ProtoNumber(3)
  val query: String? = null,
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(9)
  val appLanguage: String? = null,
  /**
   * Plans language
   */
  @SerialName("plan_language")
  @ProtoNumber(10)
  val planLanguage: String? = null,
  /**
   * Bible version id
   */
  @SerialName("version_id")
  @ProtoNumber(11)
  val versionId: Int? = null,
  /**
   * Filterchip selected (ex. plans, videos, all)
   */
  @SerialName("selected_filter")
  @ProtoNumber(13)
  val selectedFilter: String? = null,
  /**
   * Previously selected filter (ex. none, plans, videos, all)
   */
  @SerialName("previous_filter")
  @ProtoNumber(14)
  val previousFilter: String? = null,
  /**
   * Topical or text (this is the final intent returned by the server)
   */
  @SerialName("user_intent")
  @ProtoNumber(15)
  val userIntent: String? = null,
  /**
   * Index of the filter, ordered by server
   */
  @SerialName("filter_index")
  @ProtoNumber(16)
  val filterIndex: Int? = null,
  /**
   * Videos language
   */
  @SerialName("video_language")
  @ProtoNumber(17)
  val videoLanguage: String? = null
) : Event(), red.Serializable {
  override val key: String = "SearchFilterTap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    query?.let { bundle.putString("query", query) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    selectedFilter?.let { bundle.putString("selected_filter", selectedFilter) }
    previousFilter?.let { bundle.putString("previous_filter", previousFilter) }
    userIntent?.let { bundle.putString("user_intent", userIntent) }
    filterIndex?.let { bundle.putInt("filter_index", filterIndex) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    return bundle
  }
}
