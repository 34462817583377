/**
 * @module ContentCard
 */
import React from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Chip,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { LazyImage } from 'components/ui/lazy-image'

const MAX_IMAGE_SIZE = 125

const useStyles = makeStyles((theme) => ({
  artwork: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
  },
  card: {
    borderRadius: theme.spacing(1),
    boxSizing: 'content-box',
    width: 'fit-content',
  },
  cardContent: {
    '&:last-child': {
      padding: theme.spacing(2),
    },
    width: ({ cardWidth }) => cardWidth,
  },
  darkOverlay: {
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);',
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    boxSizing: 'border-box',
    height: '50%',
    padding: theme.spacing(1),
  },
  secondaryActionIcon: {
    color: 'white',
  },
  skeletonActionButton: {
    borderRadius: 99,
  },
}))

/**
 * Content Card.
 *
 * @alias module:ContentCard
 *
 * @param {object} props - The component's props object.
 * @param {object} [props.actionChipProps] - [Material-UI Chip](https://material-ui.com/api/chip/) props spread onto a Chip component to be used for a supplemental action. Action Chip will appear if these props are present.
 * @param {string} [props.caption] - Caption text. Concise, descriptive text about the content in the card.
 * @param {number} [props.imageAspectRatio] - Aspect ratio of the image provided. This is necessary to properly display the image in its loading and loaded states. Defaults to `1 / 1`. For more information, see [CSS aspect-ratio property](https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio).
 * @param {string} [props.imageArtworkUrl] - Source URL for the image art.
 * @param {boolean} [props.isLoading] - The card loading state is displayed.
 * @param {React.ReactElement} [props.linkComponent] - A link component, typically from React Router.
 * @param {boolean} [props.openInNewTab] - Determines if the row will open the link in a new tab. This requires the `to` prop to be passed.
 * @param {object} [props.secondaryAction] - Create an overlay in the top right of the provided image artwork with an icon button. Example: secondaryAction={{ icon: AddIcon, label: 'Add to collection', onClick: () => {} }}.
 * @param {string} props.title - The title of the content. The first 40 characters will be displayed and the rest truncated.
 * @param {string} [props.to] - The URL path to open when the content row is clicked.
 *
 * @returns {React.ReactElement} - The ContentCard Component.
 *
 * @example
 * import React from 'react'
 * import { Link } from 'react-router-dom'
 * import { ContentCard } from '@youversion/react'
 * import { List } from '@material-ui/core'
 * import { handleAddToCollection } from './helpers'.
 *
 * function MyComponent() {
 *   return (
 *    <List>
 *      <ContentCard
 *        caption="7 Days"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        linkComponent={Link}.
 *        secondaryAction={{
 *          icon: AddCircleIcon,
 *          label: 'Add to collection',
 *          onClick: () => handleAddToCollection(1234),
 *        }}.
 *        title="Bold Leaders"
 *        to="https://bible.com/plans/1234"
 *      />
 *      <ContentCard
 *        caption="5 Days"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        title="The Spiritual Life of a Leader"
 *        to="https://bible.com/plans/1234"
 *      />
 *      <ContentCard
 *        caption="3 Days"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        title="You Are Unique & You Are Loved"
 *        to="https://bible.com/plans/1234"
 *      />
 *    </List>
 *   )
 * }
 */
export function ContentCard({
  actionChipProps,
  caption,
  imageArtworkUrl,
  imageAspectRatio,
  isLoading,
  linkComponent,
  openInNewTab,
  secondaryAction,
  title,
  to,
  ...props
}) {
  const classes = useStyles({ cardWidth: MAX_IMAGE_SIZE * imageAspectRatio })

  function handleSecondaryAction(event) {
    // These two function calls help the action button be clickable, without navigating the user to
    // another page.
    event.stopPropagation()
    event.preventDefault()

    secondaryAction.onClick()
  }

  function handleActionChipClick(event) {
    // These two function calls help the action button be clickable, without navigating the user to another page.
    event.stopPropagation()
    event.preventDefault()

    const { onClick } = actionChipProps
    if (onClick && typeof onClick === 'function') {
      onClick()
    }
  }

  const actionChip =
    Object.keys(actionChipProps).length > 1 ? (
      <Chip
        // Default to 'outlined' variant, but this can easily be overridden with `actionChipProps`.
        variant="outlined"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...actionChipProps}
        onClick={handleActionChipClick}
        size="small"
      />
    ) : null

  // I want to only show a specific amount of characters, so that the text does not expand too long.
  let displayTitle = title
  if (title?.length > 40) {
    displayTitle = `${title.substring(0, 40)}…`
  }

  if (isLoading) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Card className={classes.card} elevation={0} {...props}>
        <CardContent className={classes.cardContent}>
          <Box mb={1} width="100%">
            <Skeleton
              className={classes.artwork}
              height={MAX_IMAGE_SIZE}
              variant="rect"
              width={MAX_IMAGE_SIZE * imageAspectRatio}
            />
          </Box>
          {caption ? (
            <Typography
              color="textSecondary"
              display="block"
              gutterBottom={true}
              noWrap={true}
              variant="caption"
            >
              <Skeleton width="50%" />
            </Typography>
          ) : null}
          <Typography color="textPrimary" gutterBottom={true} variant="body1">
            <Skeleton width="100%" />
            <Skeleton width="75%" />
          </Typography>
          {actionChip ? (
            <Box mt={1}>
              <Skeleton className={classes.skeletonActionButton} variant="rect">
                {actionChip}
              </Skeleton>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    )
  }

  const cardContent = (
    <CardContent className={classes.cardContent}>
      <Box height={MAX_IMAGE_SIZE} mb={1} position="relative" width="100%">
        <LazyImage
          alt={`Main image for ${title}`}
          className={classes.artwork}
          height={MAX_IMAGE_SIZE}
          src={imageArtworkUrl}
          width={MAX_IMAGE_SIZE * imageAspectRatio}
        />
        {secondaryAction ? (
          <Box
            alignItems="flex-start"
            className={classes.darkOverlay}
            display="flex"
            height="100%"
            justifyContent="flex-end"
            left={0}
            position="absolute"
            top={0}
            width="100%"
          >
            <IconButton
              aria-label={secondaryAction.label}
              className={classes.secondaryActionIcon}
              onClick={handleSecondaryAction}
              size="small"
            >
              {React.cloneElement(secondaryAction.icon, {
                fontSize: 'small',
              })}
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {caption ? (
        <Typography
          color="textSecondary"
          display="block"
          gutterBottom={true}
          variant="caption"
        >
          {caption}
        </Typography>
      ) : null}
      <Typography color="textPrimary" gutterBottom={true} variant="body1">
        {displayTitle}
      </Typography>
      {actionChip ? <Box mt={1}>{actionChip}</Box> : null}
    </CardContent>
  )

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Card className={classes.card} elevation={0} {...props}>
      {to ? (
        <CardActionArea
          component={linkComponent}
          href={!linkComponent && to ? to : null}
          rel={!linkComponent && to ? 'noopener noreferrer' : null}
          target={openInNewTab ? '_blank' : null}
          to={linkComponent && to ? to : null}
        >
          {cardContent}
        </CardActionArea>
      ) : (
        cardContent
      )}
    </Card>
  )
}

ContentCard.propTypes = {
  /**
   * [Material-UI Chip](https://material-ui.com/api/chip/) props spread onto a Chip component to be used for a supplemental action. Action Chip will appear if these props are present.
   */
  actionChipProps: PropTypes.shape({
    onClick: PropTypes.func,
  }),
  /**
   * Caption text. Concise, descriptive text about the content in the card.
   */
  caption: PropTypes.string,
  /**
   * Source URL for the image art.
   */
  imageArtworkUrl: PropTypes.string,
  /**
   * Aspect ratio of the image provided. This is necessary to properly display the image in its loading and loaded states. Defaults to `1 / 1`. For more information, see [CSS aspect-ratio property](https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio).
   */
  imageAspectRatio: PropTypes.number,
  /**
   * The card loading state is displayed.
   */
  isLoading: PropTypes.bool,
  /**
   * A link component, typically from React Router.
   */
  linkComponent: PropTypes.elementType,
  /**
   * Determines if the row will open the link in a new tab. This requires the `to` prop to be passed.
   */
  openInNewTab: PropTypes.bool,
  /**
   * Create an overlay in the top right of the provided image artwork with an icon button. Example: secondaryAction={{ icon: AddIcon, label: 'Add to collection', onClick: () => {} }}.
   */
  secondaryAction: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  /**
   * The title of the content. The first 40 characters will be displayed and the rest truncated.
   */
  title: PropTypes.string.isRequired,
  /**
   * The URL path to open when the content row is clicked.
   */
  to: PropTypes.string,
}

ContentCard.defaultProps = {
  actionChipProps: {},
  caption: '',
  cardProps: {},
  imageArtworkUrl: '',
  imageAspectRatio: 1 / 1,
  isLoading: false,
  linkComponent: undefined,
  openInNewTab: false,
  secondaryAction: undefined,
  to: '',
}
