// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.collections

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class DeleteCollectionRequest(
  @SerialName("language")
  @ProtoNumber(4)
  @JvmField
  val language: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
