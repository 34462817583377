package youversion.red.plans.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.bible.model.BibleLocale
import youversion.red.plans.model.Discover
import youversion.red.plans.model.Plan
import youversion.red.plans.model.PlanCollectionItems
import youversion.red.plans.model.PlanDay
import youversion.red.plans.model.PlanResults
import youversion.red.plans.model.PlansConfiguration
import youversion.red.plans.model.RecommendedCollection

@JsName("PlansServiceProvider")
fun PlansService() = service<IPlansService>()

@ExportedService
interface IPlansService : IService {

    suspend fun getPlan(id: Int, languageTag: String? = null): Plan?

    suspend fun getPlanDay(id: Int, day: Int): PlanDay

    suspend fun saveForLater(id: Int)

    suspend fun getConfiguration(): PlansConfiguration

    suspend fun getDiscover(languageTag: String): Discover?

    suspend fun getDiscoverCollection(collectionId: Int, page: Int): PlanCollectionItems?

    suspend fun getSuggestedPlans(planId: Int, languageTag: String): RecommendedCollection?

    suspend fun getPlansByCategory(category: String): PlanResults

    suspend fun getPlansByReference(usfm: String): PlanResults

    suspend fun getLanguages(): List<BibleLocale>
}
