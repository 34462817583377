package youversion.red.blue.state

import red.service.ServiceRegistry
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.service.BlueService
import youversion.red.blue.service.IBlueService

object GuidedPrayerState {

    private val blueService by BlueService()

    val enrolled: Boolean
        get() = blueService.isEnrolled(featureId)

    val enabled: Boolean
        get() = blueService.getState(featureId)?.enabled ?: false

    internal suspend fun setEnabledInternal(enabled: Boolean) {
        ServiceRegistry[IBlueService::class].setState(
            FeatureState(
                featureId,
                group = "ENABLED",
                enabled = enabled
            )
        )
    }

    const val featureId = "guided_prayer"
}
