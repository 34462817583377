// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.images.OrganizationImage

@Serializable
data class Organization(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("website_url")
  @ProtoNumber(3)
  @JvmField
  val websiteUrl: String? = null,
  @SerialName("phone")
  @ProtoNumber(4)
  @JvmField
  val phone: String? = null,
  @SerialName("email")
  @ProtoNumber(5)
  @JvmField
  val email: String? = null,
  @SerialName("logo")
  @ProtoNumber(6)
  @JvmField
  val logo: OrganizationImage,
  @SerialName("description")
  @ProtoNumber(7)
  @JvmField
  val description: String? = null,
  /**
   * The hex color code for the background color of an organization
   */
  @SerialName("background_color")
  @ProtoNumber(8)
  @JvmField
  val backgroundColor: String = "",
  @SerialName("parent_organization")
  @ProtoNumber(9)
  @JvmField
  val parentOrganization: ParentOrganization? = null,
  /**
   * The primary language of the organization represented as a an iso 639 language code
   */
  @SerialName("primary_language")
  @ProtoNumber(10)
  @JvmField
  val primaryLanguage: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
