// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.publishers

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PostPublisher(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  @SerialName("url")
  @ProtoNumber(3)
  @JvmField
  val url: String? = null,
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  @SerialName("tracking_id")
  @ProtoNumber(5)
  @JvmField
  val trackingId: String? = null,
  @SerialName("language")
  @ProtoNumber(7)
  @JvmField
  val language: String? = null,
  @SerialName("created_dt")
  @ProtoNumber(6)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
