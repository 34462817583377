package youversion.red.podcasts.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * A podcast can either be NotStarted or InProgress or Completed states
 */
enum class ListeningStatus(
    internal val serialName: String,
    internal val serialId: Int
) {
    UNKNOWN("Unknown", 0),

    INPROGRESS("InProgress", 1),

    COMPLETED("Completed", 2),

    NOTSTARTED("NotStarted", 3),

    MARKASPLAYED("MarkAsPlayed", 4);
}

internal class StatusTypeSerializer : KSerializer<ListeningStatus> {
    override val descriptor: SerialDescriptor =
        kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.podcasts.test.ListeningStatus")

    override fun serialize(encoder: Encoder, value: ListeningStatus) {
        if (encoder is JsonEncoder) {
            encoder.encodeString(value.serialName)
        } else {
            encoder.encodeInt(value.serialId)
        }
    }

    override fun deserialize(decoder: Decoder): ListeningStatus {
        if (decoder is JsonDecoder) {
            return ListeningStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
        }
        return when (decoder.decodeInt()) {
            0 -> ListeningStatus.UNKNOWN
            1 -> ListeningStatus.INPROGRESS
            2 -> ListeningStatus.COMPLETED
            3 -> ListeningStatus.NOTSTARTED
            4 -> ListeningStatus.MARKASPLAYED
            else -> throw IllegalArgumentException("Unknown enum value")
        }
    }
}
