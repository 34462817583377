package youversion.red.downloads.service

import red.listeners.Listeners
import youversion.red.downloads.model.Download

internal actual object PlatformDownloads {

    private val listeners = Listeners<DownloadListener>()

    actual fun addListener(listener: DownloadListener) = listeners.addListener(listener)

    actual fun removeListener(listener: DownloadListener) = listeners.removeListener(listener)

    actual suspend fun cancel(download: Download) {
        TODO("Cancelling not implemented")
    }

    actual suspend fun resume(resume: ResumeState) {
        TODO("Resuming not implemented")
    }

    actual suspend fun download(download: Download) {
        TODO("Downloading not implemented")
    }
}
