package youversion.red.moments.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentBodyOrTitle(
    @SerialName("l_args") @ProtoNumber(1) val lArgs: MomentLArgs? = null,
    @SerialName("l_str") @ProtoNumber(2) val lStr: String? = null,
    @ProtoNumber(3) val str: String? = null,
    @ProtoNumber(100) val displayContent: String? = null
)
