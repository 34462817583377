/**
 * @module imgLoaded
 */

/* eslint-disable no-param-reassign */

/**
 * @typedef ImageObject.
 * @param {boolean} complete - Boolean flag indicating loaded status of the image.
 * @param {number} naturalHeight - The natural height value of the image.
 */

/**
 * Checks to see if image is loaded.
 *
 * @alias module:imgLoaded
 *
 * @param {ImageObject} imgElement - The ImageObject element.
 *
 * @returns {boolean} - A boolean on whether or not the image is considered fully loaded.
 *
 * @example
 * // returns true
 * imgLoaded({complete: true, naturalHeight: 1})
 */

export function imgLoaded(imgElement) {
  return imgElement && imgElement.complete && imgElement.naturalHeight !== 0
}
