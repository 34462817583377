package youversion.red.analytics

import com.squareup.sqldelight.ColumnAdapter
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toLowerCase

enum class CollectorType {
    Test,
    VerseLookupEvent,
    PlanDayCompleteEvent,
    UserRecencyEvent,
    InstallationEvent,
    V2
}

class CollectorTypeSerializer : KSerializer<CollectorType> {

    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("CollectorType")

    override fun deserialize(decoder: Decoder): CollectorType {
        if (decoder is JsonDecoder) {
            val str = decoder.decodeString()
            return CollectorType.valueOf(str)
        }
        val index = decoder.decodeInt()
        return CollectorType.values()[index - 1]
    }

    override fun serialize(encoder: Encoder, value: CollectorType) {
        if (encoder is JsonEncoder) {
            encoder.encodeString(value.name.toLowerCase(Locales.getEnglish()))
        } else {
            encoder.encodeInt(value.ordinal + 1)
        }
    }
}

class CollectorTypeAdapter : ColumnAdapter<CollectorType, String> {
    override fun decode(databaseValue: String): CollectorType {
        return CollectorType.valueOf(databaseValue)
    }

    override fun encode(value: CollectorType): String {
        return value.name
    }
}
