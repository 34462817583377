// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.users

import kotlin.Float
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("users.UserCreated")
data class UserCreated(
  @SerialName("recaptcha_score")
  @ProtoNumber(3)
  val recaptchaScore: Float? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(recaptchaScore: Float? = null) : this(recaptchaScore, red.platform.now())
}
