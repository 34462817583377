// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.users.api.model.CreateAccountMethod
import youversion.red.users.api.model.CreateAccountReferrer
import youversion.red.users.api.model.OfflineDownloadReferrer
import youversion.red.users.api.model.PlanSubscribeReferrer

@Serializable
data class CreateAccount(
  @SerialName("referrer")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.users.api.model.CreateAccountReferrerSerializer::class)
  val referrer: CreateAccountReferrer =
      youversion.red.users.api.model.CreateAccountReferrer.UNKNOWN,
  @SerialName("method")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.users.api.model.CreateAccountMethodSerializer::class)
  val method: CreateAccountMethod = youversion.red.users.api.model.CreateAccountMethod.UNKNOWN,
  @SerialName("plan_subscribe_referrer")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.users.api.model.PlanSubscribeReferrerSerializer::class)
  val planSubscribeReferrer: PlanSubscribeReferrer? = null,
  @SerialName("offline_download_referrer")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.users.api.model.OfflineDownloadReferrerSerializer::class)
  val offlineDownloadReferrer: OfflineDownloadReferrer? = null
) : Event(), red.Serializable {
  override val key: String = "CreateAccount"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("referrer", referrer.serialName)
    bundle.putString("method", method.serialName)
    planSubscribeReferrer?.let { bundle.putString("plan_subscribe_referrer",
        planSubscribeReferrer.serialName) }
    offlineDownloadReferrer?.let { bundle.putString("offline_download_referrer",
        offlineDownloadReferrer.serialName) }
    return bundle
  }
}
