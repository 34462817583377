package youversion.red.images.service

import red.platform.currentTimeMillis
import red.platform.http.DefaultSerializer
import red.platform.http.FormatType
import red.platform.io.File
import red.platform.io.fileSystem
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendedLock
import red.platform.threads.set
import red.platform.threads.sync
import red.platform.toLong
import red.service.DefaultService
import red.tasks.CoroutineDispatchers
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.bible.reference.BibleReference
import youversion.red.images.api.ImagesApi
import youversion.red.images.model.ImageCategory
import youversion.red.images.model.ImageOrientation
import youversion.red.images.model.Images

@DefaultService(IImagesService::class)
internal class ImagesServiceImpl : IImagesService {

    private val lock = SuspendedLock()
    private val imagesCache = AtomicReference<ImagesCache?>(null)

    override suspend fun getImage(id: Int) = ImagesApi.getImage(id)
    override suspend fun getImages(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): Images = getCachedImages(reference, languageTag, category, orientation, page)?.images?.also {
        CoroutineDispatchers.launch {
            getImagesAndSetCache(reference, languageTag, category, orientation, page)
        }
    } ?: getImagesAndSetCache(reference, languageTag, category, orientation, page).images ?: Images(null, null)

    private suspend fun getCachedImages(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): ImagesCache? {
        return imagesCache.value?.takeIf {
            it.reference == reference &&
                    it.languageTag == languageTag &&
                    it.category == category &&
                    it.orientation == orientation &&
                    it.page == page
        } ?: getCachedImagesFromDisk(reference, languageTag, category, orientation, page)
    }

    private suspend fun getCachedImagesFromDisk(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): ImagesCache? = withIO {
        lock.sync {
            try {
                ImagesCache(
                    reference = reference,
                    languageTag = languageTag,
                    category = category,
                    orientation = orientation,
                    page = page,
                    images = null
                ).file().read()
            } catch (e: Exception) {
                null
            }
        }
    }

    private suspend fun getImagesAndSetCache(
        reference: BibleReference?,
        languageTag: String?,
        category: ImageCategory?,
        orientation: ImageOrientation?,
        page: Int
    ): ImagesCache = withIO {
        val images = ImagesApi.getImages(
            reference?.usfmArray?.toList() ?: emptyList(),
            languageTag,
            category?.type,
            orientation?.type,
            page
        )
        val cache = ImagesCache(
            images = images,
            reference = reference,
            languageTag = languageTag,
            category = category,
            orientation = orientation,
            page = page,
            cacheTimeInMillis = currentTimeMillis().toLong()
        )
        lock.sync {
            fileSystem.writeFile(
                cache.file(),
                DefaultSerializer.serialize(FormatType.PROTOBUF, ImagesCache.serializer(), cache)
            )
        }
        imagesCache.set(cache)
        cache
    }

    internal suspend fun purgeExpiredDiskCache() = lock.sync {
        fileSystem.listFiles(cacheDir).forEach {
            try {
                val expirationTime = it.read().cacheTimeInMillis + CACHE_TIME
                if (currentTimeMillis().toLong() > expirationTime) {
                    fileSystem.deleteFile(it)
                }
            } catch (e: Exception) {
            }
        }
    }

    private val cacheDir = fileSystem.newFile(fileSystem.internalStorage, "images")

    private suspend fun ImagesCache.file(): File {
        val cacheDir = cacheDir
        if (!fileSystem.exists(cacheDir)) {
            fileSystem.mkdirs(cacheDir)
        }
        return fileSystem.newFile(
            cacheDir, listOfNotNull(
                reference,
                languageTag,
                category,
                orientation,
                page
            ).joinToString("_") + ".cache"
        )
    }

    private suspend fun File.read(): ImagesCache {
        if (!fileSystem.exists(this)) {
            error("file does not exist")
        }
        return fileSystem.open(this)?.let {
            DefaultSerializer.deserialize(FormatType.PROTOBUF, ImagesCache.serializer(), it)
        } ?: error("Could not deserialize images cache")
    }

    companion object {
        private const val CACHE_TIME = 1814400000 // 21 days
    }
}

@kotlinx.serialization.Serializable
private data class ImagesCache(
    val images: Images?,
    val reference: BibleReference?,
    val languageTag: String?,
    val category: ImageCategory?,
    val orientation: ImageOrientation?,
    val page: Int,
    val cacheTimeInMillis: Long = 0
)
