package youversion.red.geoip.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core")
class GeoIPModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
