package youversion.red.geoip.api

import red.platform.http.ContentTypes
import red.platform.http.URL
import red.platform.http.newURL
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.geoip.model.GeoIP
import youversion.red.security.UserId
import youversion.red.security.impl.tokens.EmailToken
import youversion.red.security.impl.tokens.EmailTokenParameters

object GeoIPApi : AbstractApi(ApiDefaults("geoip", ContentTypes.JSON, ContentTypes.JSON, "")) {

    override fun toURL(path: String, hostPrefix: String, host: String, version: String, queryString: String?): URL =
        newURL("https://geoip.youversionapi.com/json")

    suspend fun getGeoIP() = execute(
        "/", serializer = GeoIP.serializer(), token = EmailToken(
            UserId(0, "youversion"),
            EmailTokenParameters("ZqkRX8awCkhfAHw0lV8h47eFEEXnbEGiA")
        )
    )
}
