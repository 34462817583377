// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bible

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent
import youversion.red.dataman.api.model.ChapterRequestIntent
import youversion.red.dataman.api.model.ChapterRequestStorageLocation

@Serializable
@SerialName("bible.ChapterRequestEvent")
data class ChapterRequestEvent(
  @SerialName("book_chapter_usfm")
  @ProtoNumber(1)
  val bookChapterUsfm: String? = null,
  @SerialName("version")
  @ProtoNumber(2)
  val version: Int? = null,
  @SerialName("usfms")
  @ProtoNumber(3)
  val usfms: List<String>? = null,
  @SerialName("offline")
  @ProtoNumber(4)
  val offline: Boolean? = null,
  @SerialName("metadata_build")
  @ProtoNumber(5)
  val metadataBuild: Int? = null,
  @SerialName("success")
  @ProtoNumber(6)
  val success: Boolean? = null,
  @SerialName("storage_location")
  @ProtoNumber(7)
  @Serializable(with=youversion.red.dataman.api.model.ChapterRequestStorageLocationSerializer::class)
  val storageLocation: ChapterRequestStorageLocation? = null,
  @SerialName("request_intent")
  @ProtoNumber(8)
  @Serializable(with=youversion.red.dataman.api.model.ChapterRequestIntentSerializer::class)
  val requestIntent: ChapterRequestIntent? = null,
  @SerialName("trial_active")
  @ProtoNumber(11)
  val trialActive: Boolean? = null,
  /**
   * Amount of milliseconds to succeed or fail
   */
  @SerialName("request_time")
  @ProtoNumber(9)
  val requestTime: Int? = null,
  @SerialName("error_message")
  @ProtoNumber(10)
  val errorMessage: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    bookChapterUsfm: String? = null,
    version: Int? = null,
    usfms: List<String>? = null,
    offline: Boolean? = null,
    metadataBuild: Int? = null,
    success: Boolean? = null,
    storageLocation: ChapterRequestStorageLocation? = null,
    requestIntent: ChapterRequestIntent? = null,
    trialActive: Boolean? = null,
    requestTime: Int? = null,
    errorMessage: String? = null
  ) : this(bookChapterUsfm, version, usfms, offline, metadataBuild, success, storageLocation,
      requestIntent, trialActive, requestTime, errorMessage, red.platform.now())
}
