package youversion.red.churches.model

import red.Serializable
import red.platform.threads.freeze

data class Organization(
    val id: String,
    val name: String,
    val websiteUrl: String?,
    val phone: String?,
    val email: String?,
    val description: String?,
    val imageUrl: String,
    val brandColor: String,
    val parentOrganization: ParentOrganization?
) : Serializable {
    init {
        freeze()
    }
}

data class ParentOrganization(
    val id: String,
    val name: String
) : Serializable {
    init {
        freeze()
    }
}
