// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.crowdin_events

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class TranslationUpdatedEvent(
  @SerialName("event")
  @ProtoNumber(1)
  @JvmField
  val event: String? = null,
  @SerialName("project")
  @ProtoNumber(2)
  @JvmField
  val project: String? = null,
  @SerialName("project_id")
  @ProtoNumber(3)
  @JvmField
  val projectId: Int? = null,
  @SerialName("language")
  @ProtoNumber(4)
  @JvmField
  val language: String? = null,
  @SerialName("source_string_id")
  @ProtoNumber(5)
  @JvmField
  val sourceStringId: Int? = null,
  @SerialName("old_translation_id")
  @ProtoNumber(6)
  @JvmField
  val oldTranslationId: Int? = null,
  @SerialName("new_translation_id")
  @ProtoNumber(7)
  @JvmField
  val newTranslationId: Int? = null,
  @SerialName("user")
  @ProtoNumber(9)
  @JvmField
  val user: String? = null,
  @SerialName("user_id")
  @ProtoNumber(10)
  @JvmField
  val userId: Int? = null,
  @SerialName("file_id")
  @ProtoNumber(11)
  @JvmField
  val fileId: Int? = null,
  @SerialName("file")
  @ProtoNumber(12)
  @JvmField
  val file: String? = null,
  @SerialName("step_name")
  @ProtoNumber(13)
  @JvmField
  val stepName: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
