package youversion.red.discover.service

import red.platform.http.RequestManager
import youversion.red.discover.api.model.DiscoverGradient
import youversion.red.discover.api.model.DiscoverPlan
import youversion.red.discover.api.model.RecommendedPlans
import youversion.red.discover.util.Renditions
import youversion.red.discovery.api.DiscoveryApi
import youversion.red.movies.Movie
import youversion.red.movies.MoviePublisher
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.service.MoviesService
import youversion.red.plans.model.CollectionDisplay
import youversion.red.plans.service.PlansService
import youversion.red.security.service.UsersService

object DiscoverService {

    private val usersService by UsersService()
    private val plansService by PlansService()
    private val moviesService by MoviesService()

    suspend fun getTeachingClips(
        languageTag: String? = null,
        pageSize: Int? = null
    ): Pair<List<Pair<Movie, MoviePublisher?>>, Boolean> {
        val movies = DiscoveryApi.getStoryVideos(
            languageTag = languageTag,
            pageSize = pageSize
        )?.storyvideos?.mapNotNull {
            it.id?.let { videoId ->
                val movie = moviesService.getMovie(videoId, MoviesUIContext.Movies)
                movie?.let {
                    Pair(movie, moviesService.getPublisher(movie.publisherId ?: -1))
                }
            }
        }
        return Pair(movies ?: emptyList(), false)
    }

    suspend fun getFeaturedPlans(languageTag: String, page: Int): Pair<List<DiscoverPlan>?, Int?>? {
        val collectionId =
            plansService.getDiscover(languageTag)?.items?.firstOrNull { it.display == CollectionDisplay.banner }?.id
                ?: return null
        val collection = plansService.getDiscoverCollection(collectionId, page)?.collections?.firstOrNull()
        val plans = collection?.items?.map { DiscoverPlan(it.id ?: -1, it.title) }
        return Pair(plans, collection?.nextPage)
    }

    suspend fun getFeaturedVideos(page: Int? = null, pageSize: Int? = null) = moviesService.getMoviesInCollection(
        if (RequestManager.staging) 33 else 130,
        pageSize = pageSize,
        page = page
    ).first.let { movies ->
        Pair(
            movies.movies.map {
                Pair(it, moviesService.getPublisher(it.publisherId ?: -1))
            },
            movies.hasNextPage
        )
    }

    suspend fun getRecommendedPlans(
        languageTag: String,
        planId: Int,
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<RecommendedPlans?, Boolean?> {
        val title = plansService.getPlan(planId, languageTag)?.name

        val recommendedPlans = DiscoveryApi.getRelatedPlans(planId, languageTag, pageSize, page)
        val plans = recommendedPlans?.relatedPlans?.mapNotNull {
            val relatedPlanId = it.id

            if (relatedPlanId != null)
                DiscoverPlan(
                    relatedPlanId,
                    plansService.getPlan(relatedPlanId, languageTag)?.name
                )
            else
                null
        }
        return Pair(RecommendedPlans(title, plans), recommendedPlans?.nextPage)
    }

    suspend fun getTrendingPlans(
        languageTag: String,
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<List<DiscoverPlan>?, Boolean?> {
        val trends = DiscoveryApi.getTrends(languageTag, page = page, pageSize = pageSize)
        val plans = trends?.trends?.first { it.kind == "plans" }?.kindIds?.map {
            DiscoverPlan(
                it,
                plansService.getPlan(it, languageTag)?.name
            )
        }
        return Pair(plans, trends?.nextPage)
    }

    suspend fun getFriendsPlans(
        languageTag: String,
        page: Int? = null,
        pageSize: Int? = null
    ): Pair<List<DiscoverPlan>?, Boolean?>? {
        if (usersService.getCurrentUser() == null) return null
        val plansOfFriends = DiscoveryApi.getFriendsPlans(page = page, pageSize = pageSize)
        val plans = plansOfFriends?.planOfFriends?.map {
            DiscoverPlan(
                it.planId ?: -1,
                plansService.getPlan(it.planId ?: -1, languageTag)?.name,
                it.userIds
            )
        }
        return Pair(plans, plansOfFriends?.nextPage)
    }

    suspend fun getGradients(languageTag: String, page: Int): List<DiscoverGradient>? {
        val collectionId =
            plansService.getDiscover(languageTag)?.items?.find { it.display == CollectionDisplay.gradient }?.id
                ?: return null
        return plansService.getDiscoverCollection(collectionId, page)?.collections?.firstOrNull()?.items?.map {
            DiscoverGradient(it.gradient, it.title)
        }
    }

    suspend fun getPlanThumbnail(id: Int, minWidth: Int): String? {
        val configuration = plansService.getConfiguration()
        return Renditions.getImageUrlFormatSquare(id, minWidth, configuration.images.plans)
    }
}
