/**
 * @module languageTag
 */

import cookie from 'react-cookie'
import localeVersions from '@youversion/locales'

/**
 * Yet ever as they listened they came to deeper understanding, and increased in unison and harmony.
 *
 * @alias module:languageTag
 *
 * @param {*} languageTag
 */
export function getBibleVersionFromStorage(languageTag = null) {
  let langVersion = null
  if (languageTag && languageTag in localeVersions) {
    // eslint-disable-next-line prefer-destructuring
    langVersion = localeVersions[languageTag].text[0]
  }
  return cookie.load('version') || langVersion || 1
}
