// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.deeplink

import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object PodcastsDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides")) {
  private val _listener: AtomicReference<PodcastsDeepLinkListener?> = AtomicReference(null)

  var listener: PodcastsDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/podcasts") { context, _, _ -> 
        listener.onPodcasts(context)
    }
    register("/podcasts/$INTEGER_EXPRESSION/episodes/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val showId = values[1].toIntOrNull() ?: return@register false
        val episodeId = values[2].toIntOrNull() ?: return@register false
        listener.onEpisode(context, showId, episodeId)
    }
    register("/podcasts/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val showId = values[1].toIntOrNull() ?: return@register false
        listener.onPodcast(context, showId)
    }
    register("/podcasts/playlists/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val playlistIdPath = values[1].toIntOrNull() ?: return@register false
        listener.onPlaylist(context, playlistIdPath)
    }
    freeze()
  }
}
