package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.security.User

@Serializable
data class MomentExtras(
    @ProtoNumber(1) val color: String? = null,
    @ProtoNumber(2) val content: String? = null,
    @ProtoNumber(3) val description: String? = null,
    @ProtoNumber(4) val formattedLength: String? = null,
    @ProtoNumber(5) val friendUserId: Int? = null,
    @ProtoNumber(6) val images: List<MomentImages>? = null,
    @ProtoNumber(7) val labels: List<String>? = null,
    @ProtoNumber(8) val languageTag: String? = null,
    @ProtoNumber(9) val percentComplete: Float? = null,
    @ProtoNumber(10) val planId: Int? = null,
    @ProtoNumber(11) val references: List<MomentReference>? = null,
    @ProtoNumber(12) val segment: Int? = null,
    @ProtoNumber(13) val slug: String? = null,
    @ProtoNumber(14) val systemStatus: MomentSystemStatus? = null,
    @ProtoNumber(15) val title: String? = null,
    @ProtoNumber(16) val totalSegments: Int? = null,
    @ProtoNumber(17) val userId: Int? = null,
    @ProtoNumber(18) val userStatus: MomentUserStatus? = null,
    @ProtoNumber(19) val badgeId: Int? = null,
    @ProtoNumber(20) val levelCount: Int? = null,
    @Transient val friend: User? = null
) {

    init {
        freeze()
    }
}
