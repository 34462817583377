export const localeVersions = JSON.stringify({
  af: {
    text: [5, 6, 117, 50],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  ar: {
    text: [13, 14, 67, 101],
    audio: [13, 101, 67],
  },
  bg: {
    text: [22, 23, 901, 196],
    audio: [23, 111, 12],
  },
  ca: {
    text: [335],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  cs: {
    text: [15, 162, 44, 509],
    audio: [15, 111, 59, 12, 1],
  },
  cy: {
    text: [394, 221, 329, 287],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  da: {
    text: [20, 49, 121],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  de: {
    text: [51, 57, 58, 65],
    audio: [51, 12, 1, 111, 59, 116],
  },
  el: {
    text: [173, 921, 346, 423],
    audio: [173, 921, 209, 12, 111],
  },
  en: {
    text: [1, 111, 12, 116],
    audio: [1, 111, 116, 59, 12, 97, 100, 72, 206],
  },
  'en-GB': {
    text: [1, 111, 12, 116],
    audio: [1, 111, 116, 59, 12, 97, 100, 72, 206, 113],
  },
  es: {
    text: [149, 128, 176, 127],
    audio: [149, 89],
  },
  'es-ES': {
    text: [149, 128, 176, 127],
    audio: [149, 89],
  },
  fa: {
    text: [181, 136, 118],
    audio: [181, 118, 12, 111],
  },
  fi: {
    text: [365, 330, 61, 160],
    audio: [1, 111, 116, 59, 12, 97, 100, 160],
  },
  fr: {
    text: [93, 133, 152, 63],
    audio: [504, 133, 93],
  },
  he: {
    text: [24, 380, 904],
    audio: [380],
  },
  hi: {
    text: [819, 820],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  hr: {
    text: [39],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  hu: {
    text: [26, 526, 198, 84],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  id: {
    text: [306, 27, 35, 17],
    audio: [306, 12, 111],
  },
  it: {
    text: [123, 12, 122, 1196],
    audio: [1196, 12, 111],
  },
  ja: {
    text: [81, 83],
    audio: [83, 12, 111],
  },
  km: {
    text: [315, 85, 1270],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  ko: {
    text: [88, 86, 142, 111],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  lt: {
    text: [3309, 3317, 420, 419, 321, 310],
    audio: [],
  },
  lv: {
    text: [318, 488, 400, 143],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  mk: {
    text: [311, 322],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  mn: {
    text: [369],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  ms: {
    text: [402, 111, 12, 48],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  my: {
    text: [404, 386, 1391, 1459],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  'my-MM': {
    text: [1459, 404, 386, 1391],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  nl: {
    text: [75, 328, 165],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  no: {
    text: [102, 29, 121, 30],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  pl: {
    text: [319, 132, 138, 137],
    audio: [319, 12, 111],
  },
  pt: {
    text: [211, 129, 212, 277],
    audio: [211, 129, 12, 111],
  },
  'pt-PT': {
    text: [211, 215, 432, 129],
    audio: [211, 129, 12, 111],
  },
  ro: {
    text: [191, 126, 192, 903],
    audio: [126, 12, 111],
  },
  ru: {
    text: [400, 143, 167, 201],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  sk: {
    text: [163, 464, 644, 465],
    audio: [15, 12, 111],
  },
  sq: {
    text: [7, 292],
    audio: [292, 12, 111],
  },
  sv: {
    text: [160, 154, 1223, 417],
    audio: [160, 12, 111],
  },
  sw: {
    text: [74, 164, 393],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  ta: {
    text: [339],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  th: {
    text: [174, 275, 179, 175],
    audio: [174, 179, 175, 12, 111],
  },
  tl: {
    text: [399, 144, 177, 111],
    audio: [111, 12, 562],
  },
  tr: {
    text: [170, 118, 181, 101],
    audio: [181, 12, 111],
  },
  uk: {
    text: [400, 186, 143, 167],
    audio: [1, 111, 116, 59, 12, 97, 100],
  },
  ur: {
    text: [481, 187, 189],
    audio: [1, 111, 116, 59, 12],
  },
  vi: {
    text: [193, 151, 449, 19, 205],
    audio: [193, 12, 111],
  },
  'zh-CN': {
    text: [36, 41, 43, 47],
    audio: [36, 41, 43, 47, 48, 140],
  },
  'zh-TW': {
    text: [46, 414, 40, 139],
    audio: [46, 40, 139, 414, 312],
  },
  zu: {
    text: [286],
    audio: [],
  },
})
