package youversion.red.discover.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core", "plans", "moments")
class DiscoverModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
