package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference

@Serializable
data class MomentReference(
    @ProtoNumber(1) val human: String? = null,
    @ProtoNumber(2) val usfms: List<String>? = null,
    @ProtoNumber(3) val versionId: Int? = null
) {

    init {
        freeze()
    }

    fun toReference(): BibleReference = BibleReference(usfms?.joinToString("+") ?: "", versionId ?: 0)
}
