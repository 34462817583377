package youversion.red.prayer.ext

import red.platform.Calendar
import red.platform.newDate
import red.platform.toCalendar
import youversion.red.prayer.api.model.Comment
import youversion.red.prayer.api.model.Prayer as ApiPrayer
import youversion.red.prayer.api.model.PrayerParticipant
import youversion.red.prayer.api.model.PrayerUpdate as ApiPrayerUpdate
import youversion.red.prayer.api.model.Share
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate
import youversion.red.prayer.service.PrayerStore.NEW_CLIENT_ID
import youversion.red.prayer.util.PrayerUtil

fun DbPrayer.toPrayerPost(): ApiPrayer {
    val prayer = this
    return ApiPrayer(
        title = prayer.title,
        description = prayer.content,
        sharingPolicy = prayer.sharingPolicy,
        createdDt = prayer.createdDt,
        versionId = prayer.versionId,
        usfm = prayer.usfm,
        seenOverview = null
    )
}

fun DbPrayer.toPrayerPut(): ApiPrayer {
    val prayer = this
    return ApiPrayer(
        id = prayer.serverId,
        userId = prayer.userId,
        title = prayer.title,
        description = prayer.content,
        status = prayer.status,
        lastPrayerUpdate = prayer.lastPrayerUpdate,
        createdDt = prayer.createdDt,
        usfm = prayer.usfm,
        isUpdated = prayer.updated,
        seenUpdate = prayer.seenUpdate,
        versionId = prayer.versionId,
        fromUsers = prayer.fromUsers,
        sharingPolicy = prayer.sharingPolicy,
        answered = prayer.answeredDt,
        updatedDt = prayer.updatedDt,
        commentEnabled = prayer.commentEnabled,
        seenOverview = null // currently not using this
    )
}

fun DbPrayer.toPrayerParticipant(): PrayerParticipant {
    val prayer = this
    return PrayerParticipant(
        seenUpdate = prayer.seenUpdate,
        status = prayer.status,
        seenOverview = null,
        deleted = if (prayer.state and PrayerUtil.STATE_DELETED == PrayerUtil.STATE_DELETED)
            newDate()
        else
            null
    )
}

fun ApiPrayer.toDb(oldPrayer: DbPrayer? = null): DbPrayer {
    val prayer = this
    return DbPrayer(
        answeredDt = prayer.answered,
        createdDt = prayer.createdDt ?: oldPrayer?.createdDt,
        serverId = prayer.id ?: oldPrayer?.serverId,
        userId = prayer.userId ?: oldPrayer?.userId,
        title = prayer.title ?: oldPrayer?.title,
        content = prayer.description ?: oldPrayer?.content,
        sharingPolicy = prayer.sharingPolicy ?: oldPrayer?.sharingPolicy ?: SharingPolicy.ONLYYOU,
        status = prayer.status ?: oldPrayer?.status ?: StatusType.ACTIVE,
        seenUpdate = prayer.seenUpdate,
        updatedDt = prayer.updatedDt ?: oldPrayer?.updatedDt,
        usfm = prayer.usfm ?: oldPrayer?.usfm,
        versionId = prayer.versionId ?: oldPrayer?.versionId,
        updated = prayer.isUpdated == true,
        commentEnabled = prayer.commentEnabled == true,
        clientId = oldPrayer?.clientId ?: NEW_CLIENT_ID,
        state = oldPrayer?.state ?: 0,
        lastSync = oldPrayer?.lastSync,
        orderIndex = oldPrayer?.orderIndex ?: 0,
        reactionCount = oldPrayer?.reactionCount,
        fromUsers = prayer.fromUsers ?: oldPrayer?.fromUsers ?: emptyList(),
        lastPrayerUpdate = prayer.lastPrayerUpdate
    )
}

fun ApiPrayerUpdate.toDb(oldUpdate: PrayerUpdate?): PrayerUpdate {
    val update = this
    return PrayerUpdate(
        serverId = update.id ?: oldUpdate?.serverId,
        message = update.message ?: oldUpdate?.message,
        updatedDt = update.updatedDt ?: oldUpdate?.updatedDt,
        createdDt = update.createdDt ?: oldUpdate?.createdDt,
        lastSync = oldUpdate?.lastSync,
        state = oldUpdate?.state ?: 0,
        clientId = oldUpdate?.clientId ?: NEW_CLIENT_ID,
        prayerClientId = oldUpdate?.prayerClientId,
        prayerServerId = oldUpdate?.prayerServerId
    )
}

fun PrayerUpdate.toApi(): ApiPrayerUpdate {
    val o = this
    return ApiPrayerUpdate(
        id = o.serverId,
        message = o.message,
        createdDt = o.createdDt,
        updatedDt = o.updatedDt
    )
}

fun Share.toDb(oldShare: PrayerShare? = null): PrayerShare {
    val share = this
    return PrayerShare(
        id = share.id ?: oldShare?.id ?: 0,
        createdDt = share.createdDt,
        message = share.message,
        receiverId = share.receiverId,
        senderId = share.senderId,
        prayerServerId = share.prayerId,
        prayerClientId = oldShare?.prayerClientId
    )
}

fun Comment.toDb(oldComment: PrayerComment? = null): PrayerComment {
    val comment = this
    return PrayerComment(
        clientId = oldComment?.clientId ?: NEW_CLIENT_ID,
        serverId = comment.id ?: oldComment?.serverId,
        createdDt = comment.createdDt ?: oldComment?.createdDt,
        updatedDt = comment.updatedDt ?: oldComment?.updatedDt,
        message = comment.message,
        userId = comment.userId ?: oldComment?.userId,
        prayerClientId = oldComment?.prayerClientId,
        prayerServerId = oldComment?.prayerServerId,
        lastSync = oldComment?.lastSync,
        state = oldComment?.state ?: 0
    )
}

fun PrayerComment.toPost(): Comment {
    val comment = this
    return Comment(
        id = comment.serverId,
        userId = comment.userId,
        message = comment.message,
        createdDt = comment.createdDt
    )
}

fun PrayerComment.toPut(): Comment {
    val comment = this
    return Comment(
        id = comment.serverId,
        userId = comment.userId,
        message = comment.message,
        updatedDt = comment.updatedDt,
        createdDt = comment.createdDt
    )
}

fun DbPrayer.prayActionEnabled() = prayActionEnabledForDay()

fun DbPrayer.prayActionEnabledForDay(calendar: Calendar = newDate().toCalendar()): Boolean {
    val answered = this.answeredDt != null
    if (answered)
        return false

    return PrayerUtil.prayActionEnabledForDay(this.clientId, calendar)
}
