package youversion.red.analytics.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.ByteArray
import kotlin.Long
import kotlin.String
import kotlin.collections.contentToString
import red.platform.Date
import youversion.red.analytics.CollectorType

public data class Events(
  public val id: Long,
  public val ctype: CollectorType,
  public val created: Date,
  public val message: ByteArray
) {
  public override fun toString(): String = """
  |Events [
  |  id: $id
  |  ctype: $ctype
  |  created: $created
  |  message: ${message.contentToString()}
  |]
  """.trimMargin()

  public class Adapter(
    public val ctypeAdapter: ColumnAdapter<CollectorType, String>,
    public val createdAdapter: ColumnAdapter<Date, Long>
  )
}
