// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SharesMade(
  /**
   * Array of share objects that have been created as a result of shares being sent.
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<Share> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
