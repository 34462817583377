package youversion.red.versification.service.js

import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.versification.VersificationTransaction
import youversion.red.versification.service.IVersificationService

public object VersificationService {
  private val service: IVersificationService by
      service<youversion.red.versification.service.IVersificationService>()

  @JsName(name = "newVersification")
  public fun newVersification(): Promise<VersificationTransaction?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.newVersification()

  }.toPromise()
}
