// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("modules.ReflectionModule")
data class ReflectionModule(
  @SerialName("question")
  @ProtoNumber(3)
  val question: String? = null,
  @SerialName("answers")
  @ProtoNumber(4)
  val answers: List<ReflectionAnswer>? = null,
  @SerialName("language_tag")
  @ProtoNumber(5)
  val languageTag: String? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  override val title: String? = null
) : BaseModule, red.Serializable {
  init {
    freeze()
  }
}
