package youversion.red.banner.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.protobuf.ProtoNumber

interface Parameters {

    val size: Int

    fun contains(key: String): Boolean

    operator fun plus(parameter: IParameter<*>)

    operator fun plus(parameters: Map<String, IParameter<*>>)

    operator fun get(key: String): IParameter<*>?

    val values: Collection<IParameter<*>>

    val hasUserState: Boolean

    fun fetchUserState()
}

interface IParameter<T> {

    val key: String
    val value: T?
    val valueSet: Set<T>?
    val comparable: Comparable<Any>
    val valid: Boolean

    fun resolveParameter(
        parameters: Parameters,
        localization: BannerLocalization,
        variables: Map<BannerLocalization, Map<String, IParameter<*>>>
    ): IParameter<*>
}

@Serializable
data class Parameter<T>(
    @ProtoNumber(1) override val key: String,
    @ProtoNumber(2) override val value: T?,
    @ProtoNumber(3) override val valueSet: Set<T>? = null
) : IParameter<T> {

    init {
        if (value != null && valueSet != null) {
            throw IllegalArgumentException("You cannot assign both value & valueSet")
        }
        if (value == null && valueSet == null) {
            throw IllegalArgumentException("You must assign one of value or valueSet")
        }
    }

    @Transient
    private var _comparable: Comparable<Any>? = null

    override val comparable: Comparable<Any>
        get() {
            if (valueSet != null) {
                throw IllegalStateException("Value is a Set, use contains or some other supported operator")
            }
            if (value == null) {
                throw IllegalStateException("Value not set")
            }
            if (value !is String) {
                throw IllegalStateException("The only value data type that is supported is a string")
            }
            if (_comparable == null) {
                _comparable = ParameterSchema.toComparable(key, value as String)
            }
            return _comparable!!
        }

    override val valid: Boolean
        get() = try {
            if (valueSet != null) {
                true
            } else {
                comparable
                true
            }
        } catch (e: Exception) {
            false
        }

    override fun resolveParameter(
        parameters: Parameters,
        localization: BannerLocalization,
        variables: Map<BannerLocalization, Map<String, IParameter<*>>>
    ): IParameter<*> = this
}
