package youversion.red.blue.service.js

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.platform.DateTime
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.blue.BlueFeatureStateListener
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.service.IBlueService

public object BlueService {
  private val service: IBlueService by service<youversion.red.blue.service.IBlueService>()

  public val lastUpdated: DateTime
    get() = service.lastUpdated

  @JsName(name = "addStateListener")
  public fun addStateListener(listener: BlueFeatureStateListener): Unit =
      service.addStateListener(listener)

  @JsName(name = "removeStateListener")
  public fun removeStateListener(listener: BlueFeatureStateListener): Unit =
      service.removeStateListener(listener)

  @JsName(name = "isEnrolled")
  public fun isEnrolled(featureId: String): Boolean = service.isEnrolled(featureId)

  @JsName(name = "getState")
  public fun getState(featureId: String): FeatureState? = service.getState(featureId)

  @JsName(name = "getAvailableFeatures")
  public fun getAvailableFeatures(): List<String> = service.getAvailableFeatures()

  @JsName(name = "waitUntilUpdated")
  public fun waitUntilUpdated(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.waitUntilUpdated()

  }.toPromise()

  @JsName(name = "setState")
  public fun setState(state: FeatureState): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.setState(state)

  }.toPromise()

  @JsName(name = "update")
  public fun update(): Promise<Unit?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default)
      {
    service.update()

  }.toPromise()

  @JsName(name = "clearState")
  public fun clearState(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.clearState()

  }.toPromise()

  @JsName(name = "clearEnrollment")
  public fun clearEnrollment(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.clearEnrollment()

  }.toPromise()
}
