package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentUpdate(
    @ProtoNumber(100) val clientId: String = "",
    @ProtoNumber(1) val id: Long,
    @ProtoNumber(3) val references: List<MomentReferences>? = null,
    @ProtoNumber(4) val color: String? = null,
    @ProtoNumber(5) val title: String? = null,
    @ProtoNumber(6) val content: String? = null,
    @ProtoNumber(7) val labels: List<String>? = null,
    @ProtoNumber(8) val userStatus: MomentUserStatus? = null
)
