// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * This is the user_intent the server used to get the results it returned. Normally, it would be the
 * same as the client supplied to get results. *But* if the client did not supply it the API tries to
 * intelligently chose an intent.
 */
enum class UserIntent(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  TOPICAL("topical", 1),

  TEXT("text", 2);
}

internal class UserIntentSerializer : KSerializer<UserIntent> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.UserIntent")

  override fun serialize(encoder: Encoder, value: UserIntent) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): UserIntent {
    if (decoder is JsonDecoder) {
      return UserIntent.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> UserIntent.UNKNOWN
      1 -> UserIntent.TOPICAL
      2 -> UserIntent.TEXT
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
