/**
 * @module sortDNDArray
 */
import { arrayMove } from '@youversion/utils'

/**
 * Sorts a React state array for `react-beautiful-dnd`.
 *
 * @param {object} params - The function params.
 * @param {object} params.destination - The destination object from `react-beautiful-dnd`.
 * @param {Function} params.onChange - The state updater function. Typically a `setState` function.
 * @param {object} params.source - The source object from `react-beautiful-dnd`.
 *
 * @example
 * import React from 'react'
 * import { DragDropContext } from 'react-beautiful-dnd'
 * import { sortDNDArray } from 'utils'
 *
 * function MyComponent() {
 *   const [data, setData] = React.useState([1, 2, 3])
 *
 *   return (
 *     <DragDropContext onDragEnd={
 *       ({ destination, source }) => sortDNDArray({ destination, onChange: setData, source})
 *     }>
 *       // <Droppable> et. al.
 *     </DragDropContext>
 *   )
 * }
 */
export function sortDNDArray({ destination, onChange, source }) {
  if (!source || !destination) return

  if (
    typeof source.index === 'number' &&
    typeof destination.index === 'number'
  ) {
    onChange((prevState) => {
      return arrayMove({
        array: prevState,
        from: source.index,
        to: destination.index,
      })
    })
  }
}
