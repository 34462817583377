package youversion.red.analytics.db.analytics

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.ByteArray
import kotlin.Int
import kotlin.Long
import kotlin.Unit
import kotlin.collections.Collection
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import red.platform.Date
import youversion.red.analytics.CollectorType
import youversion.red.analytics.db.AnalyticsDatabase
import youversion.red.analytics.db.AnalyticsDatabaseQueries
import youversion.red.analytics.db.Events

internal val KClass<AnalyticsDatabase>.schema: SqlDriver.Schema
  get() = AnalyticsDatabaseImpl.Schema

internal fun KClass<AnalyticsDatabase>.newInstance(driver: SqlDriver,
    eventsAdapter: Events.Adapter): AnalyticsDatabase = AnalyticsDatabaseImpl(driver, eventsAdapter)

private class AnalyticsDatabaseImpl(
  driver: SqlDriver,
  internal val eventsAdapter: Events.Adapter
) : TransacterImpl(driver), AnalyticsDatabase {
  public override val analyticsDatabaseQueries: AnalyticsDatabaseQueriesImpl =
      AnalyticsDatabaseQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 1

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE events (
          |    id INTEGER NOT NULL PRIMARY KEY AUTOINCREMENT,
          |    ctype TEXT NOT NULL,
          |    created INTEGER NOT NULL,
          |    message BLOB NOT NULL
          |)
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
    }
  }
}

private class AnalyticsDatabaseQueriesImpl(
  private val database: AnalyticsDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), AnalyticsDatabaseQueries {
  internal val selectAll: MutableList<Query<*>> = copyOnWriteList()

  internal val selectAllV1: MutableList<Query<*>> = copyOnWriteList()

  internal val selectAllV2: MutableList<Query<*>> = copyOnWriteList()

  internal val count: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> selectAll(mapper: (
    id: Long,
    ctype: CollectorType,
    created: Date,
    message: ByteArray
  ) -> T): Query<T> = Query(2132048600, selectAll, driver, "AnalyticsDatabase.sq", "selectAll", """
  |SELECT *
  |FROM events
  |ORDER BY created DESC
  |LIMIT 50
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.eventsAdapter.ctypeAdapter.decode(cursor.getString(1)!!),
      database.eventsAdapter.createdAdapter.decode(cursor.getLong(2)!!),
      cursor.getBytes(3)!!
    )
  }

  public override fun selectAll(): Query<Events> = selectAll { id, ctype, created, message ->
    Events(
      id,
      ctype,
      created,
      message
    )
  }

  public override fun <T : Any> selectAllV1(mapper: (
    id: Long,
    ctype: CollectorType,
    created: Date,
    message: ByteArray
  ) -> T): Query<T> = Query(199307123, selectAllV1, driver, "AnalyticsDatabase.sq", "selectAllV1",
      """
  |SELECT *
  |FROM events
  |WHERE ctype != 'V2'
  |ORDER BY created DESC
  |LIMIT 50
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.eventsAdapter.ctypeAdapter.decode(cursor.getString(1)!!),
      database.eventsAdapter.createdAdapter.decode(cursor.getLong(2)!!),
      cursor.getBytes(3)!!
    )
  }

  public override fun selectAllV1(): Query<Events> = selectAllV1 { id, ctype, created, message ->
    Events(
      id,
      ctype,
      created,
      message
    )
  }

  public override fun <T : Any> selectAllV2(mapper: (
    id: Long,
    ctype: CollectorType,
    created: Date,
    message: ByteArray
  ) -> T): Query<T> = Query(199307124, selectAllV2, driver, "AnalyticsDatabase.sq", "selectAllV2",
      """
  |SELECT *
  |FROM events
  |WHERE ctype = 'V2'
  |ORDER BY created DESC
  |LIMIT 50
  """.trimMargin()) { cursor ->
    mapper(
      cursor.getLong(0)!!,
      database.eventsAdapter.ctypeAdapter.decode(cursor.getString(1)!!),
      database.eventsAdapter.createdAdapter.decode(cursor.getLong(2)!!),
      cursor.getBytes(3)!!
    )
  }

  public override fun selectAllV2(): Query<Events> = selectAllV2 { id, ctype, created, message ->
    Events(
      id,
      ctype,
      created,
      message
    )
  }

  public override fun count(): Query<Long> = Query(1041945186, count, driver,
      "AnalyticsDatabase.sq", "count", "SELECT count(*) FROM events") { cursor ->
    cursor.getLong(0)!!
  }

  public override fun add(
    ctype: CollectorType,
    created: Date,
    message: ByteArray
  ): Unit {
    driver.execute(1341862580, """
    |INSERT INTO events (
    |ctype,
    |created,
    |message)
    |VALUES (
    |?,
    |?,
    |?)
    """.trimMargin(), 3) {
      bindString(1, database.eventsAdapter.ctypeAdapter.encode(ctype))
      bindLong(2, database.eventsAdapter.createdAdapter.encode(created))
      bindBytes(3, message)
    }
    notifyQueries(1341862580, {database.analyticsDatabaseQueries.selectAllV1 +
        database.analyticsDatabaseQueries.selectAllV2 + database.analyticsDatabaseQueries.count +
        database.analyticsDatabaseQueries.selectAll})
  }

  public override fun deleteByIds(ids: Collection<Long>): Unit {
    val idsIndexes = createArguments(count = ids.size)
    driver.execute(null, """DELETE FROM events WHERE id IN $idsIndexes""", ids.size) {
      ids.forEachIndexed { index, ids_ ->
          bindLong(index + 1, ids_)
          }
    }
    notifyQueries(-1136692471, {database.analyticsDatabaseQueries.selectAllV1 +
        database.analyticsDatabaseQueries.selectAllV2 + database.analyticsDatabaseQueries.count +
        database.analyticsDatabaseQueries.selectAll})
  }

  public override fun deleteById(id: Long): Unit {
    driver.execute(794616490, """DELETE FROM events WHERE id = ?""", 1) {
      bindLong(1, id)
    }
    notifyQueries(794616490, {database.analyticsDatabaseQueries.selectAllV1 +
        database.analyticsDatabaseQueries.selectAllV2 + database.analyticsDatabaseQueries.count +
        database.analyticsDatabaseQueries.selectAll})
  }
}
