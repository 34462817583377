package youversion.red.blue.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class BlueSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
