// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model.achievements

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class ProgressPostAdmin(
  /**
   * This is the date when the badge was earned.
   */
  @SerialName("earned_dt")
  @ProtoNumber(1)
  @JvmField
  val earnedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * Which user should this progress be attributed to
   */
  @SerialName("user_id")
  @ProtoNumber(2)
  @JvmField
  val userId: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
