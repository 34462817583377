// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The open-player-control represents lottie button, the player-view represents the podcast audio
 * player, the quick-play-control represents the pill button on each episode, and the audio-widget
 * represnts the system audio player controls
 */
enum class PodcastPlayerControlType(
  internal val serialName: String,
  internal val serialId: Int
) {
  OPEN_PLAYER_CONTROL("open-player-control", 0),

  PLAYER_VIEW("player-view", 1),

  QUICK_PLAY_CONTROL("quick-play-control", 2),

  AUDIO_WIDGET("audio-widget", 3);
}

internal class PodcastPlayerControlTypeSerializer : KSerializer<PodcastPlayerControlType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.PodcastPlayerControlType")

  override fun serialize(encoder: Encoder, value: PodcastPlayerControlType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): PodcastPlayerControlType {
    if (decoder is JsonDecoder) {
      return PodcastPlayerControlType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> PodcastPlayerControlType.OPEN_PLAYER_CONTROL
      1 -> PodcastPlayerControlType.PLAYER_VIEW
      2 -> PodcastPlayerControlType.QUICK_PLAY_CONTROL
      3 -> PodcastPlayerControlType.AUDIO_WIDGET
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
