// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Translation(
  /**
   * Identifier of string in translation tool's system
   */
  @SerialName("remote_string_id")
  @ProtoNumber(1)
  @JvmField
  val remote_string_id: String? = null,
  /**
   * The language tag for the string in the translation tool's system
   */
  @SerialName("remote_language_tag")
  @ProtoNumber(2)
  @JvmField
  val remote_language_tag: String? = null,
  /**
   * The translation identifier in the translation tool's system.
   */
  @SerialName("remote_translation_id")
  @ProtoNumber(3)
  @JvmField
  val remote_translation_id: String? = null,
  /**
   * The translation for the string.
   */
  @SerialName("translation")
  @ProtoNumber(4)
  @JvmField
  val translation: String? = null,
  /**
   * The language tag for the string used by our APIs
   */
  @SerialName("language_tag")
  @ProtoNumber(5)
  @JvmField
  val language_tag: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
