// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Kind of search object
 */
enum class Kind(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALL("all", 1),

  EVENTS("events", 2),

  FEATURES("features", 3),

  PLANS("plans", 4),

  SUPPORT_ARTICLES("support-articles", 5),

  TOPICS("topics", 6),

  USERS("users", 7),

  VERSES("verses", 8),

  VIDEOS("videos", 9),

  IMAGES("images", 10),

  PODCASTS("podcasts", 11),

  CHURCHES("churches", 12);
}

internal class KindSerializer : KSerializer<Kind> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.Kind")

  override fun serialize(encoder: Encoder, value: Kind) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): Kind {
    if (decoder is JsonDecoder) {
      return Kind.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> Kind.UNSUPPORTED_PLACEHOLDER
      1 -> Kind.ALL
      2 -> Kind.EVENTS
      3 -> Kind.FEATURES
      4 -> Kind.PLANS
      5 -> Kind.SUPPORT_ARTICLES
      6 -> Kind.TOPICS
      7 -> Kind.USERS
      8 -> Kind.VERSES
      9 -> Kind.VIDEOS
      10 -> Kind.IMAGES
      11 -> Kind.PODCASTS
      12 -> Kind.CHURCHES
      else -> Kind.UNSUPPORTED_PLACEHOLDER
    }
  }
}
