package youversion.red.prayer.guided.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.threads.freeze
import youversion.red.cache.CacheClearService
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.guidedprayer.deeplink.GuidedPrayerDeepLinkHandler
import youversion.red.prayer.guided.service.GuidedPrayerCacheClearListener

@ModuleDependencies("core", "prayer")
class GuidedPrayerModuleInitializer : IModuleInitializer {

    private val cacheClearListener = GuidedPrayerCacheClearListener().freeze()

    override fun initialize() {
        CacheClearService.register(cacheClearListener)
        DeepLinks.addHandlers(GuidedPrayerDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
