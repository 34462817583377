// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.accounts

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class InviteToken(
  @SerialName("verify")
  @ProtoNumber(1)
  @JvmField
  val verify: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
