// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class OrganizationProfiles(
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<BaseProfile> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
