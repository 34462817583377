package youversion.red.analytics.module

import red.lifecycle.AppLifecycle
import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.Log
import red.platform.PlatformType
import red.platform.platformType
import youversion.red.analytics.DataMan
import youversion.red.analytics.DataManInterfaceImpl
import youversion.red.analytics.alps.AlpsListener
import youversion.red.analytics.dataman.DataManListener
import youversion.red.security.service.UsersService

@ModuleDependencies("core", "installation", "blue")
class AnalyticsDatamanModuleInitializer : IModuleInitializer {

    private val usersService by UsersService()
    private val alpsListener = AlpsListener()
    private val dataManListener = DataManListener()

    override fun initialize() {
        DataMan.dataManInterface = DataManInterfaceImpl()
        AppLifecycle.register(dataManListener)
        if (platformType != PlatformType.JavaScript) {
            usersService.register(alpsListener)
            AppLifecycle.register(alpsListener)
        } else {
            Log.w("DataManModule", "Alps isn't supported using JS yet")
        }
    }
}
