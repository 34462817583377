package youversion.red.achievements.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.achievements.api.model.Achievement
import youversion.red.achievements.api.model.Progress
import youversion.red.achievements.model.ActionShareAchievement

@JsName("AchievementsServiceProvider")
fun AchievementsService() = service<IAchievementsService>()

@ExportedService
interface IAchievementsService : IService {
    suspend fun getAchievements(plansLanguageTag: String? = null): List<Achievement>

    suspend fun getProgress(userId: Int): List<Progress>

    suspend fun getAchievement(id: Int, plansLanguageTag: String? = null): Achievement?

    suspend fun incrementProgress(achievement: ActionShareAchievement)
}
