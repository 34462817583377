package youversion.red.plans.service.js

import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.bible.model.BibleLocale
import youversion.red.plans.model.Discover
import youversion.red.plans.model.Plan
import youversion.red.plans.model.PlanCollectionItems
import youversion.red.plans.model.PlanDay
import youversion.red.plans.model.PlanResults
import youversion.red.plans.model.PlansConfiguration
import youversion.red.plans.model.RecommendedCollection
import youversion.red.plans.service.IPlansService

public object PlansService {
  private val service: IPlansService by service<youversion.red.plans.service.IPlansService>()

  @JsName(name = "getPlan")
  public fun getPlan(id: Int, languageTag: String?): Promise<Plan?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPlan(id, languageTag)

  }.toPromise()

  @JsName(name = "getPlanDay")
  public fun getPlanDay(id: Int, day: Int): Promise<PlanDay?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPlanDay(id, day)

  }.toPromise()

  @JsName(name = "saveForLater")
  public fun saveForLater(id: Int): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.saveForLater(id)

  }.toPromise()

  @JsName(name = "getConfiguration")
  public fun getConfiguration(): Promise<PlansConfiguration?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getConfiguration()

  }.toPromise()

  @JsName(name = "getDiscover")
  public fun getDiscover(languageTag: String): Promise<Discover?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getDiscover(languageTag)

  }.toPromise()

  @JsName(name = "getDiscoverCollection")
  public fun getDiscoverCollection(collectionId: Int, page: Int): Promise<PlanCollectionItems?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getDiscoverCollection(collectionId, page)

  }.toPromise()

  @JsName(name = "getSuggestedPlans")
  public fun getSuggestedPlans(planId: Int, languageTag: String): Promise<RecommendedCollection?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getSuggestedPlans(planId, languageTag)

  }.toPromise()

  @JsName(name = "getPlansByCategory")
  public fun getPlansByCategory(category: String): Promise<PlanResults?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPlansByCategory(category)

  }.toPromise()

  @JsName(name = "getPlansByReference")
  public fun getPlansByReference(usfm: String): Promise<PlanResults?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPlansByReference(usfm)

  }.toPromise()

  @JsName(name = "getLanguages")
  public fun getLanguages(): Promise<List<BibleLocale>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLanguages()

  }.toPromise()
}
