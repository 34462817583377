// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchSuggestionItemTap(
  /**
   * text searched for (includes mis-spellings)
   */
  @SerialName("query")
  @ProtoNumber(3)
  val query: String? = null,
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(9)
  val appLanguage: String? = null,
  /**
   * Plans language
   */
  @SerialName("plan_language")
  @ProtoNumber(10)
  val planLanguage: String? = null,
  /**
   * Bible version id
   */
  @SerialName("version_id")
  @ProtoNumber(11)
  val versionId: Int? = null,
  /**
   * USFM of the book/chapter
   */
  @SerialName("usfm")
  @ProtoNumber(12)
  val usfm: String? = null,
  /**
   * Suggestion type (ex: plans, videos, features, articles)
   */
  @SerialName("suggestion_type")
  @ProtoNumber(13)
  val suggestionType: String? = null,
  /**
   * Order of the section that the selected suggestion is in
   */
  @SerialName("section_index")
  @ProtoNumber(14)
  val sectionIndex: Int? = null,
  /**
   * The text of the suggestion that was tapped 
   */
  @SerialName("suggested_text")
  @ProtoNumber(15)
  val suggestedText: String? = null,
  /**
   * order of the suggestion within the section. (ex: "Joy in Serving: Devotions from Time of Grace
   * Ministry")
   */
  @SerialName("tapped_index")
  @ProtoNumber(16)
  val tappedIndex: Int? = null,
  /**
   * Videos language
   */
  @SerialName("video_language")
  @ProtoNumber(17)
  val videoLanguage: String? = null
) : Event(), red.Serializable {
  override val key: String = "SearchSuggestionItemTap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    query?.let { bundle.putString("query", query) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    usfm?.let { bundle.putString("usfm", usfm) }
    suggestionType?.let { bundle.putString("suggestion_type", suggestionType) }
    sectionIndex?.let { bundle.putInt("section_index", sectionIndex) }
    suggestedText?.let { bundle.putString("suggested_text", suggestedText) }
    tappedIndex?.let { bundle.putInt("tapped_index", tappedIndex) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    return bundle
  }
}
