// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class PodcastPlaylistKind(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  UNKNOWN("unknown", 1),

  FEATURED("featured", 2);
}

internal class PodcastPlaylistKindSerializer : KSerializer<PodcastPlaylistKind> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.podcasts.api.model.PodcastPlaylistKind")

  override fun serialize(encoder: Encoder, value: PodcastPlaylistKind) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): PodcastPlaylistKind {
    if (decoder is JsonDecoder) {
      return PodcastPlaylistKind.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> PodcastPlaylistKind.UNSUPPORTED_PLACEHOLDER
      1 -> PodcastPlaylistKind.UNKNOWN
      2 -> PodcastPlaylistKind.FEATURED
      else -> PodcastPlaylistKind.UNSUPPORTED_PLACEHOLDER
    }
  }
}
