package youversion.red.locales.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.localization.LocalesServiceResolver
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.locales.service.LocalesService

@ModuleDependencies("core")
class LocalesModuleInitializer : IModuleInitializer {

    override fun initialize() {
        // BDM/KJB: temporarily disabling this. caused a deadlock to occur on some devices.
        if (enabled) {
            LocalesServiceResolver.resolver = LocalesServiceResolverImpl()
        }
    }

    companion object {

        private val _enabled = AtomicReference(false)

        var enabled: Boolean
            get() = _enabled.value
            set(value) {
                _enabled.setAssertTrue(value)
            }
    }
}

private class LocalesServiceResolverImpl : LocalesServiceResolver {

    private val service by LocalesService()

    init {
        freeze()
    }

    override suspend fun getLocaleHeaders(locales: Set<String>): List<red.platform.localization.LocaleHeader> {
        return service.getLocaleHeaders(locales).map {
            red.platform.localization.LocaleHeader(
                bibleLanguageTag = it.bibleLanguageTag,
                apiLanguageTag = it.apiLanguageTag,
                locale = it.locale,
                name = it.name,
                localName = it.localName
            )
        }
    }

    override suspend fun getApiTag(locale: String): String {
        return service.getApiTag(locale)
    }
}
