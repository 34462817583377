package youversion.red.installation

import red.platform.Log
import red.platform.newDate
import red.platform.settings.Settings
import red.platform.threads.SuspendedLock
import red.platform.threads.sync
import red.service.DefaultService
import youversion.red.installation.api.InstallationApi
import youversion.red.installation.model.Installation
import youversion.red.security.impl.tokens.YouVersionToken

@DefaultService(IInstallationService::class)
internal class InstallationServiceImpl : IInstallationService {

    private val lock = SuspendedLock()

    override suspend fun install() = lock.sync {
        if (installationId != null) {
            Log.d("Installer", "Already registered with installation service, skipping that step")
            return
        }
        val newId = Installer.generateId()
        val token = Installer.getToken()
        register(newId, token)
    }

    private suspend fun register(id: String, token: String?) {
        val uniqueData: String? = Installer.getUniqueData()
        try {
            val serverId = InstallationApi.install(Installation(id, newDate(), uniqueData, token, YouVersionToken.clientId))
            if (serverId != null) {
                installationId = serverId.id
                InstallationEvent.fire()
            } else {
                throw IllegalStateException("Failed to register installation")
            }
        } catch (e: Throwable) {
            Log.e("Installer", "Error registering installation", e)
        }
    }

    override var installationId: String?
        get() = Settings.redSettings.getString("installation_id")
        private set(value) =
            Settings.redSettings.edit().putString(
                "installation_id",
                value ?: throw IllegalArgumentException("installation id cannot be null")
            ).commit()
}
