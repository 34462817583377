// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.Date
import red.platform.threads.freeze

interface PostBaseModule : Serializable {
  val id: Int?

  val title: String?

  val createdDt: @kotlinx.serialization.Serializable(with=red.platform.DateSerializer::class) Date?

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<PostBaseModule> = PolymorphicSerializer(PostBaseModule::class)
  }
}
