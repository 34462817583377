package youversion.red.analytics

import red.Red
import red.RedPlatform
import red.lifecycle.AppLifecycle
import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleListener
import red.lifecycle.AppLifecycleState
import red.platform.Log
import red.platform.PlatformType
import red.platform.TimeZones
import red.platform.UUID
import red.platform.localization.Locales
import red.platform.platformType
import red.platform.threads.AtomicReference
import red.platform.threads.Lock
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import red.tasks.CoroutineDispatchers.launch
import youversion.red.blue.BlueFeatureStateListener
import youversion.red.blue.service.BlueService
import youversion.red.dataman.api.model.AnalyticsContext
import youversion.red.dataman.api.model.AnalyticsDevice
import youversion.red.dataman.api.model.AnalyticsDevicePlatform
import youversion.red.dataman.api.model.AnalyticsGeoContext
import youversion.red.dataman.api.model.BlueFeature
import youversion.red.geoip.service.GeoIPService
import youversion.red.installation.InstallationService
import youversion.red.security.User
import youversion.red.security.UserListener
import youversion.red.security.impl.tokens.YouVersionToken
import youversion.red.security.service.UsersService

object AnalyticsContextManager {

    private val lock = Lock().freeze()
    private val _context = AtomicReference<AnalyticsContext?>(null).freeze()

    private val usersService by UsersService()
    private val installationService by InstallationService()
    private val geoIPService by GeoIPService()
    private val blueService by BlueService()

    private val locale: String
        get() = try {
            if (platformType == PlatformType.JavaScript) {
                "UNKNOWN"
            } else {
                Locales.getDefault().tag
            }
        } catch (e: Error) {
            Log.e("AnalyticsContext", "Failed to get locale", e)
            "UNKNOWN"
        }

    private val timeZone: String
        get() = try {
            if (platformType == PlatformType.JavaScript) {
                "UNKNOWN"
            } else {
                TimeZones.getDefault().id
            }
        } catch (e: Error) {
            Log.e("AnalyticsContext", "Failed to get time zone", e)
            "UNKNOWN"
        }

    internal var context: AnalyticsContext
        get() = _context.value
            ?.ensureInstallationId()
            ?.ensureTimeZoneAndLocale() ?: error("Context not initialized")
        private set(value) {
            _context.setAssertTrue(value)
        }

    private val userListener = AnalyticsContextUserListener().freeze()
    private val lifecycleListener = AnalyticsContextLifecycleListener().freeze()
    private val blueListener = AnalyticsBlueFeatureListener().freeze()

    private fun AnalyticsContext.ensureTimeZoneAndLocale(): AnalyticsContext {
        if (locale != device.primaryLocale ||
            timeZone != device.timezone
        ) {
            lock.sync {
                val newContext = copy(
                    device = device.copy(
                        primaryLocale = locale,
                        timezone = timeZone
                    )
                )
                _context.setAssertTrue(newContext)
                return newContext
            }
        }
        return this
    }

    private fun AnalyticsContext.ensureInstallationId(): AnalyticsContext {
        if (device.installationId == "--") {
            installationService.installationId?.let {
                lock.sync {
                    val newContext = copy(
                        device = device.copy(
                            installationId = installationService.installationId ?: "--"
                        )
                    )
                    _context.setAssertTrue(newContext)
                    return newContext
                }
            }
        }
        return this
    }

    internal val clientId: String
        get() = try {
            YouVersionToken.clientId
        } catch (e: IllegalStateException) {
            ""
        }

    private fun newContext(appVersion: String) =
        AnalyticsContext(
            Red.applicationId,
            clientId,
            null,
            UUID.randomUUID().toString(),
            appVersion = appVersion,
            redVersion = Red.version,
            device = AnalyticsDevice(
                installationId = installationService.installationId ?: "--",
                manufacturer = RedPlatform.manufacturer,
                model = RedPlatform.model,
                product = RedPlatform.product,
                platform = RedPlatform.platform,
                version = RedPlatform.version,
                primaryLocale = locale,
                timezone = timeZone,
                type = when (platformType) {
                    PlatformType.Android -> AnalyticsDevicePlatform.ANDROID
                    PlatformType.iOS -> AnalyticsDevicePlatform.IOS
                    PlatformType.Mac -> AnalyticsDevicePlatform.UNKNOWN
                    PlatformType.Windows -> AnalyticsDevicePlatform.UNKNOWN
                    PlatformType.JavaScript -> AnalyticsDevicePlatform.WEB
                    PlatformType.Jvm -> AnalyticsDevicePlatform.UNKNOWN
                }
            ),
            geo = AnalyticsGeoContext(),
            blueFeatures = blueFeatures
        ).freeze()

    fun initialize(appVersion: String) {
        _context.setAssertTrue(newContext(appVersion))
        usersService.addListener(userListener)
        blueService.addStateListener(blueListener)
        AppLifecycle.register(lifecycleListener)
        launch { updateGeoIP() }
    }

    internal suspend fun updateGeoIP() {
        geoIPService.getGeoIP()?.let {
            mutateContext {
                copy(
                    device = device.copy(
                        primaryLocale = locale,
                        timezone = timeZone
                    ),
                    geo = geo?.copy(
                        country = it.countryCode,
                        city = it.city,
                        region = it.region
                    ) ?: AnalyticsGeoContext(
                        country = it.countryCode,
                        city = it.city,
                        region = it.region
                    )
                )
            }
        }
    }

    internal fun mutateContext(body: AnalyticsContext.() -> AnalyticsContext) {
        lock.sync {
            context = context.body()
        }
    }

    internal val blueFeatures: List<BlueFeature>
        get() = blueService.getAvailableFeatures().mapNotNull {
            blueService.getState(it)?.let {
                BlueFeature(it.featureId, it.enabled, it.group)
            }
        }
}

private class AnalyticsContextUserListener : UserListener {

    override fun onUserChanged(user: User?, error: Throwable?) {
        launch {
            AnalyticsContextManager.mutateContext {
                copy(userId = user?.id, clientId = AnalyticsContextManager.clientId)
            }
        }
    }
}

private class AnalyticsBlueFeatureListener : BlueFeatureStateListener {

    override fun onFeatureStateChanged(featureId: String) {
        launch {
            AnalyticsContextManager.mutateContext {
                copy(blueFeatures = AnalyticsContextManager.blueFeatures)
            }
        }
    }
}

private class AnalyticsContextLifecycleListener : AppLifecycleListener {

    private val usersService by UsersService()

    override fun onSessionStarted() {
        launch {
            val userId = usersService.getCurrentUser()?.id
            AnalyticsContextManager.mutateContext {
                copy(
                    sessionId = UUID.randomUUID().toString(),
                    userId = userId,
                    clientId = AnalyticsContextManager.clientId
                )
            }
            AnalyticsContextManager.updateGeoIP()
        }
    }

    override fun onStateChanged(state: AppLifecycleState, source: AppLifecycleEventSource) {
    }
}
