package youversion.red.prayer.service

import red.IdGenerator
import red.database.toListLiveData
import red.database.toLiveData
import red.lifecycle.LiveData
import red.platform.Date
import red.platform.currentTimeMillis
import red.platform.newDate
import red.platform.now
import red.platform.threads.freeze
import red.platform.toDateTime
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.model.PrayerUser
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.db.PrayerDb
import youversion.red.prayer.db.queries
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerReaction
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate
import youversion.red.prayer.util.PrayerUtil.STATE_DELETED
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY

internal object PrayerStore {

    internal val NEW_CLIENT_ID
        get() = IdGenerator.generate()

    private const val TWO_WEEKS = 1209600000L

    /**
     * PRAYERS -------------------------------------------------------------------------------------
     */

    fun addPrayer(prayer: DbPrayer) {
        assertNotMainThread()
        // todo: is there a more efficient way of doing this?
        var oldPrayer = getPrayerSync(prayer.clientId)
        if (prayer.serverId != null && oldPrayer == null)
            oldPrayer = getPrayerByServerIdSync(prayer.serverId)
        if (oldPrayer != null)
            PrayerDb.queries.updatePrayer(
                serverId = prayer.serverId,
                clientId = prayer.clientId,
                title = prayer.title,
                userId = prayer.userId,
                answeredDt = prayer.answeredDt,
                createdDt = prayer.createdDt,
                updatedDt = prayer.updatedDt,
                content = prayer.content,
                sharingPolicy = prayer.sharingPolicy,
                status = prayer.status,
                lastPrayerUpdate = prayer.lastPrayerUpdate,
                usfm = prayer.usfm,
                versionId = prayer.versionId,
                reactionCount = prayer.reactionCount,
                fromUsers = prayer.fromUsers,
                seenUpdate = prayer.seenUpdate,
                updated = prayer.updated,
                commentEnabled = prayer.commentEnabled,
                state = prayer.state,
                lastSync = prayer.lastSync,
                orderIndex = prayer.orderIndex
            )
        else
            PrayerDb.queries.addPrayer(
                serverId = prayer.serverId,
                clientId = prayer.clientId,
                title = prayer.title,
                userId = prayer.userId,
                answeredDt = prayer.answeredDt,
                createdDt = prayer.createdDt,
                updatedDt = prayer.updatedDt,
                content = prayer.content,
                sharingPolicy = prayer.sharingPolicy,
                status = prayer.status,
                lastPrayerUpdate = prayer.lastPrayerUpdate,
                usfm = prayer.usfm,
                versionId = prayer.versionId,
                reactionCount = prayer.reactionCount,
                fromUsers = prayer.fromUsers,
                seenUpdate = prayer.seenUpdate,
                updated = prayer.updated,
                commentEnabled = prayer.commentEnabled,
                state = prayer.state,
                lastSync = prayer.lastSync,
                orderIndex = prayer.orderIndex
            )
    }

    fun updateAndDeletePrayers(
        updates: List<DbPrayer>,
        deletes: List<DbPrayer>
    ) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            deletes.forEach {
                PrayerDb.queries.deletePrayer(it.clientId)
            }
            updates.forEach {
                addPrayer(it)
            }
        }
    }

    fun process(updatePrayers: List<DbPrayer>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            updatePrayers.forEach {
                addPrayer(it)
            }
            deleteOldPrayers(currentTimeMillis().toLong() - TWO_WEEKS)
        }
    }

    fun getPrayersByStateSync(state: Int): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayersByState(
            state, prayerMapper
        ).executeAsList()
    }

    fun getPrayerSync(clientId: String): DbPrayer? {
        assertNotMainThread()
        return PrayerDb.queries.getPrayer(clientId, prayerMapper).executeAsOneOrNull()
    }

    fun getPrayer(clientId: String): LiveData<DbPrayer> {
        return PrayerDb.queries.getPrayer(clientId, prayerMapper).toLiveData()
    }

    fun getPrayerByServerIdSync(serverId: Int): DbPrayer? {
        assertNotMainThread()
        return PrayerDb.queries.getPrayerByServerId(serverId, prayerMapper).executeAsOneOrNull()
    }

    fun getPrayerListSync(): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayerList(
            stateDeleted = STATE_DELETED,
            statusActive = StatusType.ACTIVE,
            mapper = prayerMapper
        ).executeAsList()
    }

    fun getPrayerList(): LiveData<List<DbPrayer>> {
        return PrayerDb.queries.getPrayerList(
            stateDeleted = STATE_DELETED,
            statusActive = StatusType.ACTIVE,
            mapper = prayerMapper
        ).toListLiveData()
    }

    fun getPrayersByUserId(userId: Long): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayersByUserId(userId, prayerMapper).executeAsList()
    }

    fun getPrayersByStatusSync(status: StatusType): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayersByStatus(status, STATE_DELETED, prayerMapper)
            .executeAsList()
    }

    fun getPrayersByStatus(status: StatusType): LiveData<List<DbPrayer>> {
        return PrayerDb.queries.getPrayersByStatus(status, STATE_DELETED, prayerMapper)
            .toListLiveData()
    }

    fun getAnsweredPrayers(): LiveData<List<DbPrayer>> {
        return PrayerDb.queries.getAnsweredPrayers(prayerMapper).toListLiveData()
    }

    fun getAnsweredPrayersSync(): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getAnsweredPrayers(prayerMapper).executeAsList()
    }

    fun getPrayersNeedingUpdateSync(syncTime: Date): List<DbPrayer> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayersNeedingUpdate(
            stateDeleted = STATE_DELETED,
            statusActive = StatusType.ACTIVE,
            now = syncTime,
            mapper = prayerMapper
        ).executeAsList()
    }

    fun updatePrayerState(state: Int, clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.updateState(state, clientId)
    }

    fun updateLastPrayerUpdate(clientId: String, lastPrayerUpdate: Date) {
        assertNotMainThread()
        PrayerDb.queries.updateLastPrayerUpdate(
            lastPrayerUpdate = lastPrayerUpdate,
            clientId = clientId,
            stateDirty = STATE_DIRTY
        )
    }

    fun updatePrayerAsSeen(clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.updatePrayerAsSeen(
            seenUpdate = now(),
            updated = false,
            stateDirty = STATE_DIRTY,
            clientId = clientId
        )
    }

    fun updatePrayerStatus(clientId: String, status: StatusType) {
        assertNotMainThread()
        PrayerDb.queries.updateStatus(
            status = status,
            stateDirty = STATE_DIRTY,
            clientId = clientId
        )
    }

    fun updateUpdated(clientId: String, updated: Boolean) {
        assertNotMainThread()
        PrayerDb.queries.updateUpdated(updated, clientId)
    }

    fun deleteOldPrayers(now: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteOldPrayers(newDate(now.toDateTime()))
    }

    fun deletePrayer(clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.deletePrayer(clientId)
    }

    fun deleteAllPrayers() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllPrayers()
    }

    /**
     * PRAYER REACTIONS ----------------------------------------------------------------------------
     */

    fun processReactions(updateReactions: List<PrayerReaction>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            updateReactions.forEach {
                addReaction(it)
            }
            deleteOldReactions(currentTimeMillis().toLong() - TWO_WEEKS)
        }
    }

    fun updateAndDeleteReactions(
        updates: List<PrayerReaction>,
        deletes: List<PrayerReaction>? = null
    ) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            deletes?.forEach {
                deleteReaction(it.prayerClientId, it.userId)
            }
            updates.forEach {
                addReaction(it)
            }
        }
    }

    fun addReaction(prayerReaction: PrayerReaction) {
        assertNotMainThread()
        PrayerDb.queries.addOrReplacePrayerReaction(
            prayerClientId = prayerReaction.prayerClientId,
            prayerServerId = prayerReaction.prayerServerId,
            userId = prayerReaction.userId,
            total = prayerReaction.total,
            needsSyncing = prayerReaction.needsSyncing,
            updatedDt = prayerReaction.updatedDt
        )
    }

    fun getReactionSync(prayerClientId: String, userId: Long): PrayerReaction? {
        assertNotMainThread()
        return PrayerDb.queries.getReaction(prayerClientId, userId, reactionMapper)
            .executeAsList().firstOrNull()
    }

    fun getDirtyReactionsSync(): List<PrayerReaction> {
        assertNotMainThread()
        return PrayerDb.queries.getDirtyReactions(reactionMapper).executeAsList()
    }

    fun getReactionsSync(prayerClientId: String): List<PrayerReaction> {
        assertNotMainThread()
        return PrayerDb.queries.getReactionsByPrayerClientId(prayerClientId, reactionMapper)
            .executeAsList()
    }

    fun getReactionsUserIds(prayerClientId: String): LiveData<List<Long>> {
        return PrayerDb.queries.getReactionsUserIds(prayerClientId).toListLiveData()
    }

    fun deleteReactions(prayerClientId: String) {
        assertNotMainThread()
        PrayerDb.queries.deleteReactionsByPrayerClientId(prayerClientId)
    }

    fun deleteReaction(prayerClientId: String, userId: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteReaction(prayerClientId, userId)
    }

    fun deleteAllReactions() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllReactions()
    }

    fun deleteOldReactions(now: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteOldReactions(newDate(now.toDateTime()))
    }

    /**
     * PRAYER USERS --------------------------------------------------------------------------------
     */

    fun processUsers(updates: List<PrayerUser>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            updates.forEachIndexed { index, it ->
                addPrayerUser(it, index)
            }
            deleteOldUsers(currentTimeMillis().toLong() - 172800000L) // two days
        }
    }

    fun addPrayerUser(user: PrayerUser, orderIndex: Int? = null) {
        assertNotMainThread()
        PrayerDb.queries.addOrReplacePrayerUser(
            userId = user.userId,
            updated = user.isUpdated == true,
            lastSync = newDate(),
            orderIndex = orderIndex
        )
    }

    fun getPrayerUsers(): LiveData<List<PrayerUser>> {
        return PrayerDb.queries.getPrayerUsers(
            stateDeleted = STATE_DELETED,
            statusActive = StatusType.ACTIVE
        ) { userId, updated, _, _ ->
            PrayerUser(userId, updated)
        }.toListLiveData()
    }

    fun getPrayerUsersSync(): List<PrayerUser> {
        return PrayerDb.queries.getPrayerUsers(
            stateDeleted = STATE_DELETED,
            statusActive = StatusType.ACTIVE
        ) { userId, updated, _, _ ->
            PrayerUser(userId, updated)
        }.executeAsList()
    }

    fun getPrayerUserSync(userId: Long): PrayerUser? {
        assertNotMainThread()
        return PrayerDb.queries.getPrayerUser(userId) { id, updated, _, _ ->
            PrayerUser(id, updated)
        }.executeAsOneOrNull()
    }

    fun updateUserUpdated(userId: Long, updated: Boolean) {
        assertNotMainThread()
        PrayerDb.queries.updateUserUpdated(updated, userId)
    }

    fun deleteUser(userId: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteUser(userId)
    }

    fun deleteOldUsers(now: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteOldUsers(newDate(now.toDateTime()))
    }

    fun deleteAllUsers() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllUsers()
    }

    fun hasUnread(): LiveData<Boolean> {
        return PrayerDb.queries.hasUnread().toLiveData()
    }

    fun hasUnreadSync(): Boolean {
        assertNotMainThread()
        return PrayerDb.queries.hasUnread().executeAsOne()
    }

    /**
     * PRAYER UPDATES ------------------------------------------------------------------------------
     */

    fun addPrayerUpdate(update: PrayerUpdate) {
        assertNotMainThread()
        PrayerDb.queries.addOrReplacePrayerUpdate(
            prayerClientId = update.prayerClientId!!,
            prayerServerId = update.prayerServerId,
            clientId = update.clientId,
            serverId = update.serverId,
            message = update.message,
            updatedDt = update.updatedDt,
            createdDt = update.createdDt,
            lastSync = update.lastSync,
            state = update.state
        )
    }

    fun processUpdates(updates: List<PrayerUpdate>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            updates.forEach {
                addPrayerUpdate(it)
            }
            deleteOldUpdates(currentTimeMillis().toLong() - TWO_WEEKS)
        }
    }

    fun updateAndDeleteUpdates(
        updates: List<PrayerUpdate>,
        deletes: List<PrayerUpdate>
    ) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            deletes.forEach {
                PrayerDb.queries.deleteUpdate(it.clientId)
            }
            updates.forEach {
                addPrayerUpdate(it)
            }
        }
    }

    fun getPrayerUpdatesByPrayerIdSync(clientId: String): List<PrayerUpdate> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayerUpdatesByPrayerId(
            inClientId = clientId,
            stateDeleted = STATE_DELETED,
            mapper = prayerUpdateMapper
        ).executeAsList()
    }

    fun getPrayerUpdatesByPrayerId(prayerClientId: String): LiveData<List<PrayerUpdate>> {
        return PrayerDb.queries.getPrayerUpdatesByPrayerId(
            inClientId = prayerClientId,
            stateDeleted = STATE_DELETED,
            mapper = prayerUpdateMapper
        ).toListLiveData()
    }

    fun getPrayerUpdatesByStateSync(state: Int): List<PrayerUpdate> {
        assertNotMainThread()
        return PrayerDb.queries.getPrayerUpdatesByState(state, prayerUpdateMapper).executeAsList()
    }

    fun updateUpdateState(state: Int, clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.updateUpdateState(state, clientId)
    }

    fun deletePrayerUpdate(clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.deleteUpdate(clientId)
    }

    fun deleteOldUpdates(now: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteOldUpdates(newDate(now.toDateTime()))
    }

    fun deleteAllUpdates() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllUpdates()
    }

    /**
     * SHARES
     */

    fun processShares(shares: List<PrayerShare>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            shares.forEach {
                addShare(it)
            }
        }
    }

    fun addShare(share: PrayerShare) {
        assertNotMainThread()
        PrayerDb.queries.addOrReplaceShare(
            id = share.id,
            prayerServerId = share.prayerServerId,
            prayerClientId = share.prayerClientId!!,
            createdDt = share.createdDt,
            senderId = share.senderId,
            receiverId = share.receiverId,
            message = share.message
        )
    }

    fun getShares(clientId: String): LiveData<List<PrayerShare>> {
        return PrayerDb.queries.getShares(clientId, shareMapper).toListLiveData()
    }

    fun getSharesSync(clientId: String): List<PrayerShare> {
        assertNotMainThread()
        return PrayerDb.queries.getShares(clientId, shareMapper).executeAsList()
    }

    fun getShareSync(id: Int): PrayerShare? {
        assertNotMainThread()
        return PrayerDb.queries.getShare(id, shareMapper).executeAsOneOrNull()
    }

    fun deleteShares(prayerClientId: String) {
        assertNotMainThread()
        PrayerDb.queries.deleteShares(prayerClientId)
    }

    fun deleteAllShares() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllShares()
    }

    /**
     * COMMENTS
     */

    fun processComments(comments: List<PrayerComment>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            comments.forEach {
                addComment(it)
            }
            deleteOldComments(currentTimeMillis().toLong() - TWO_WEEKS)
        }
    }

    fun updateAndDeleteComments(updates: List<PrayerComment>, deletes: List<PrayerComment>) {
        assertNotMainThread()
        PrayerDb.queries.transaction {
            deletes.forEach {
                deleteComment(it.clientId)
            }
            updates.forEach {
                addComment(it)
            }
        }
    }

    fun addComment(comment: PrayerComment) {
        assertNotMainThread()
        PrayerDb.queries.addOrReplaceComment(
            clientId = comment.clientId,
            serverId = comment.serverId,
            prayerServerId = comment.prayerServerId,
            prayerClientId = comment.prayerClientId
                ?: throw NullPointerException("comment had a null prayerClientId"),
            userId = comment.userId,
            message = comment.message,
            updatedDt = comment.updatedDt,
            createdDt = comment.createdDt,
            state = comment.state,
            lastSync = comment.lastSync
        )
    }

    fun getComments(prayerClientId: String): LiveData<List<PrayerComment>> {
        return PrayerDb.queries.getComments(inClientId = prayerClientId, mapper = commentMapper)
            .toListLiveData()
    }

    fun getCommentsSync(prayerClientId: String): List<PrayerComment> {
        assertNotMainThread()
        return PrayerDb.queries.getComments(inClientId = prayerClientId, mapper = commentMapper)
            .executeAsList()
    }

    fun getCommentsByStateSync(state: Int): List<PrayerComment> {
        assertNotMainThread()
        return PrayerDb.queries.getCommentsByState(state, commentMapper).executeAsList()
    }

    fun updateCommentState(state: Int, clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.updateCommentState(state, clientId)
    }

    fun deleteComment(clientId: String) {
        assertNotMainThread()
        PrayerDb.queries.deleteComment(clientId)
    }

    fun deleteOldComments(now: Long) {
        assertNotMainThread()
        PrayerDb.queries.deleteOldComments(newDate(now.toDateTime()))
    }

    fun deleteAllComments() {
        assertNotMainThread()
        PrayerDb.queries.deleteAllComments()
    }

    /**
     * MAPPERS ------------------------------------------------------------------------------------
     */

    private val prayerMapper =
        { clientId: String,
          serverId: Int?,
          title: String?,
          userId: Long?,
          answeredDt: Date?,
          createdDt: Date?,
          updatedDt: Date?,
          content: String?,
          sharingPolicy: SharingPolicy?,
          status: StatusType?,
          lastPrayerUpdate: Date?,
          usfm: String?,
          versionId: Int?,
          reactionCount: Int?,
          fromUsers: List<Long>?,
          seenUpdate: Date?,
          updated: Boolean?,
          state: Int?,
          lastSync: Date?,
          orderIndex: Int?,
          commentEnabled: Boolean? ->
            DbPrayer(
                clientId = clientId,
                serverId = serverId,
                title = title,
                userId = userId,
                answeredDt = answeredDt,
                createdDt = createdDt,
                updatedDt = updatedDt,
                content = content,
                sharingPolicy = sharingPolicy ?: SharingPolicy.ONLYYOU,
                status = status ?: StatusType.ACTIVE,
                lastPrayerUpdate = lastPrayerUpdate,
                usfm = usfm,
                versionId = versionId,
                reactionCount = reactionCount,
                fromUsers = fromUsers ?: emptyList(),
                seenUpdate = seenUpdate,
                updated = updated ?: false,
                commentEnabled = commentEnabled == true,
                state = state ?: 0,
                lastSync = lastSync,
                orderIndex = orderIndex ?: 0
            ).freeze()
        }.freeze()

    private val prayerUpdateMapper =
        { prayerClientId: String?,
          prayerServerId: Int?,
          clientId: String,
          serverId: Int?,
          message: String?,
          updatedDt: Date?,
          createdDt: Date?,
          state: Int?,
          lastSync: Date? ->
            PrayerUpdate(
                prayerClientId = prayerClientId,
                prayerServerId = prayerServerId,
                clientId = clientId,
                serverId = serverId,
                message = message,
                updatedDt = updatedDt,
                createdDt = createdDt,
                state = state ?: 0,
                lastSync = lastSync
            ).freeze()
        }

    private val shareMapper = { id: Int,
                                prayerServerId: Int?,
                                prayerClientId: String?,
                                createdDt: Date?,
                                message: String?,
                                receiverId: Long?,
                                senderId: Long? ->
        PrayerShare(
            id = id,
            prayerServerId = prayerServerId,
            prayerClientId = prayerClientId,
            createdDt = createdDt,
            message = message,
            receiverId = receiverId,
            senderId = senderId
        ).freeze()
    }.freeze()

    private val reactionMapper = { prayerClientId: String,
                                   prayerServerId: Int?,
                                   userId: Long,
                                   total: Int,
                                   needsSyncing: Boolean,
                                   updatedDt: Date ->
        PrayerReaction(
            prayerClientId, prayerServerId, userId, total, needsSyncing, updatedDt
        ).freeze()
    }.freeze()

    private val commentMapper = { prayerClientId: String,
                                  prayerServerId: Int?,
                                  clientId: String,
                                  serverId: Int?,
                                  updatedDt: Date?,
                                  createdDt: Date?,
                                  message: String?,
                                  userId: Long?,
                                  state: Int?,
                                  lastSync: Date? ->

        PrayerComment(
            prayerClientId = prayerClientId,
            prayerServerId = prayerServerId,
            clientId = clientId,
            serverId = serverId,
            message = message,
            updatedDt = updatedDt,
            createdDt = createdDt,
            userId = userId,
            state = state ?: 0,
            lastSync = lastSync
        ).freeze()
    }.freeze()
}
