// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.deeplink

import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object OrganizationsDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides")) {
  private val _listener: AtomicReference<OrganizationsDeepLinkListener?> = AtomicReference(null)

  var listener: OrganizationsDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/organizations/$STRING_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val organizationId = values[1]
        listener.onOrganization(context, organizationId)
    }
    freeze()
  }
}
