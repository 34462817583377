import 'cross-fetch/polyfill'
/**
 * Base apiClient that wraps fetch.
 *
 * @param {object} config
 * @param {string} config.url
 * @param {object} config.options
 */
export default function apiClient({ url, options }) {
  return fetch(url, options)
}
