// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.profiles

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the current approval state of a Profile
 */
enum class ProfileStatus(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  CREATED("created", 1),

  APPROVED("approved", 2),

  PUBLISHED("published", 3),

  HIDDEN("hidden", 4);
}

internal class ProfileStatusSerializer : KSerializer<ProfileStatus> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.profiles.ProfileStatus")

  override fun serialize(encoder: Encoder, value: ProfileStatus) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ProfileStatus {
    if (decoder is JsonDecoder) {
      return ProfileStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ProfileStatus.UNSUPPORTED_PLACEHOLDER
      1 -> ProfileStatus.CREATED
      2 -> ProfileStatus.APPROVED
      3 -> ProfileStatus.PUBLISHED
      4 -> ProfileStatus.HIDDEN
      else -> ProfileStatus.UNSUPPORTED_PLACEHOLDER
    }
  }
}
