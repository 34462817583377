// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.module_types

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ModuleType(
  @SerialName("type")
  @ProtoNumber(1)
  @JvmField
  val type: String? = null,
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
