package youversion.red.moments.service

import kotlinx.coroutines.flow.Flow
import red.platform.io.File
import red.service.IService
import red.service.service
import red.tasks.CoroutineDispatchContext
import youversion.red.bible.model.BibleLocale
import youversion.red.bible.reference.BibleReference
import youversion.red.moments.MomentsListener
import youversion.red.moments.model.Moment
import youversion.red.moments.model.MomentBadgeProgress
import youversion.red.moments.model.MomentConfiguration
import youversion.red.moments.model.MomentCreate
import youversion.red.moments.model.MomentLabel
import youversion.red.moments.model.MomentPlan
import youversion.red.moments.model.MomentUpdate
import youversion.red.moments.model.MomentUserStatus
import youversion.red.moments.model.MomentsFetchRequest
import youversion.red.moments.model.PlansFriendsCollection
import youversion.red.moments.util.PageListener
import youversion.red.moments.util.PagedList

fun MomentsService() = service<IMomentsService>()

interface MomentPageListener : PageListener<Moment>

interface Moment

interface InitialMomentListener {

    fun onRefreshed()
}

interface IMomentsService : IService {

    var kidsBibleExperienceEnabled: Boolean

    fun setInitialMomentListener(listener: InitialMomentListener)

    suspend fun clearCache()

    suspend fun clearPendingOps()

    suspend fun getConfiguration(): MomentConfiguration

    suspend fun getColors(): List<String>

    suspend fun getLabels(): List<MomentLabel>

    suspend fun refreshOnSessionStart()

    suspend fun flushPendingMomentOps()

    suspend fun getCurrentLanguage(): BibleLocale?

    suspend fun getClientSide(): List<Moment>

    suspend fun refreshMoment(id: Long)

    suspend fun getMoment(id: Long): Flow<Moment?>

    suspend fun getMoment(clientId: String): Flow<Moment?>

    suspend fun addComment(clientId: String, comment: String): Moment

    suspend fun deleteComment(clientId: String, commentId: Long): Moment

    suspend fun setLiked(clientId: String, liked: Boolean): Moment

    suspend fun deleteMoment(clientId: String)

    fun getTodayMoments(): Flow<List<Moment>>

    suspend fun getMoments(request: MomentsFetchRequest, listener: MomentPageListener, fetchDispatcherContext: CoroutineDispatchContext, pageDispatchContext: CoroutineDispatchContext): PagedList<Moment>

    suspend fun getMoments(request: MomentsFetchRequest, listener: MomentPageListener): PagedList<Moment>

    suspend fun fetchVerseContents(clientId: List<String>)
    suspend fun fetchVerseContents(clientId: String)

    suspend fun create(moment: MomentCreate): Moment

    suspend fun update(moment: MomentUpdate): Moment

    suspend fun addListener(listener: MomentsListener)

    suspend fun addHighlight(reference: BibleReference, color: String): Moment

    suspend fun editHighlight(clientId: String, reference: BibleReference, color: String): Moment

    suspend fun addBookmark(reference: BibleReference, color: String?, title: String?, labels: List<String>): Moment

    suspend fun editBookmark(clientId: String, reference: BibleReference, color: String?, title: String?, labels: List<String>): Moment

    suspend fun addImage(reference: BibleReference, width: Int? = null, height: Int? = null, imageId: String?, file: File?): Moment

    suspend fun addNote(references: List<BibleReference>, title: String?, content: String?, color: String?, status: MomentUserStatus): Moment

    suspend fun editNote(clientId: String, references: List<BibleReference>, title: String?, content: String?, color: String?, status: MomentUserStatus): Moment

    suspend fun getPlansFriends(page: Int): PlansFriendsCollection

    suspend fun dismissMoment(moment: Moment)

    suspend fun createBannerMoment()

    suspend fun removeBannerMoment()

    suspend fun createLanguageMoment()

    suspend fun removeLanguageMoment()

    suspend fun createDownloadMoment()

    suspend fun removeDownloadMoment()

    suspend fun setAddFriendMoment(friendId: Int)

    suspend fun removeAddFriendMoment()

    suspend fun createPlansMoment(themePlanCategory: String?, myPlans: List<MomentPlan>?)

    suspend fun removePlansMoment()

//    suspend fun createPlansCarouselMoment(usfm: String?)

    suspend fun createKidsBibleExperienceMoment()

    suspend fun removeKidsBibleExperienceMoment()

    suspend fun createVotdMoment()

    suspend fun removeVotdMoment()

    suspend fun createVotdOfferMoment()

    suspend fun removeVotdOfferMoment()

    suspend fun createBadgesMoment(badges: List<MomentBadgeProgress>)

    suspend fun removeBadgesMoment()

    suspend fun createPrayerMoment(guideId: Int = 1)

    suspend fun removePrayerMoment()

    suspend fun createPrayerListMoment()

    suspend fun removePrayerListMoment()

    suspend fun createCommunityPlaceholderMoment(hasFriends: Boolean)

    suspend fun removeSuggestedFriendsMoment()
}
