// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.enrollment

import kotlin.String
import kotlin.collections.List
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("enrollment.DeviceEnrollment")
data class DeviceEnrollment(
  @SerialName("country")
  @ProtoNumber(3)
  val country: String = "",
  @SerialName("language_tag")
  @ProtoNumber(4)
  val languageTag: String = "",
  @SerialName("user_id")
  @ProtoNumber(5)
  val userId: String? = null,
  @SerialName("supported_features")
  @ProtoNumber(6)
  val supportedFeatures: List<String> = emptyList(),
  @SerialName("feature_id")
  @ProtoNumber(1)
  override val featureId: String? = null
) : Enrollment, red.Serializable {
  init {
    freeze()
  }
}
