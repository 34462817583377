package youversion.red.analytics.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core")
class AnimationsModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
