package youversion.red.prayer.guided.db

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import youversion.red.prayer.guided.db.guidedprayer.newInstance
import youversion.red.prayer.guided.db.guidedprayer.schema

public interface GuidedPrayerDatabase : Transacter {
  public val guidedPrayerQueries: GuidedPrayerQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = GuidedPrayerDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      guided_prayer_dayAdapter: Guided_prayer_day.Adapter,
      guided_prayer_moduleAdapter: Guided_prayer_module.Adapter
    ): GuidedPrayerDatabase = GuidedPrayerDatabase::class.newInstance(driver,
        guided_prayer_dayAdapter, guided_prayer_moduleAdapter)
  }
}
