/**
 * @module readingPlanDefaultImages
 */

/**
 * //d233bqaih2ivzn.cloudfront.net/default/720x405.jpg.
 *
 * @constant
 * @type {string}
 */
export const DEFAULT = '//d233bqaih2ivzn.cloudfront.net/default/720x405.jpg'

/**
 * //d233bqaih2ivzn.cloudfront.net/default/320x320.jpg.
 *
 * @constant
 * @type {string}
 */
export const SQUARE = '//d233bqaih2ivzn.cloudfront.net/default/320x320.jpg'
