package youversion.red.locales.service.js

import kotlin.String
import kotlin.collections.List
import kotlin.collections.Set
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.locales.api.model.LocaleHeader
import youversion.red.locales.api.model.LocalePlatform
import youversion.red.locales.service.ILocalesService

public object LocalesService {
  private val service: ILocalesService by service<youversion.red.locales.service.ILocalesService>()

  @JsName(name = "getLocaleHeaders")
  public fun getLocaleHeaders(locales: Set<String>, platform: LocalePlatform?):
      Promise<List<LocaleHeader>?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLocaleHeaders(locales, platform)

  }.toPromise()

  @JsName(name = "getApiTag")
  public fun getApiTag(locale: String): Promise<String?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getApiTag(locale)

  }.toPromise()
}
