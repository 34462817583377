package youversion.red.plans.service

import red.platform.localization.Locales
import red.platform.threads.AtomicReference
import red.platform.threads.SharedImmutable
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.service.DefaultService
import red.tasks.blockingSuspendCoroutine
import youversion.red.bible.model.BibleLocale
import youversion.red.bible.service.BibleLocaleService
import youversion.red.plans.api.PlansApi
import youversion.red.plans.model.Plan
import youversion.red.plans.model.PlanDay
import youversion.red.plans.model.PlanResults

@DefaultService(IPlansService::class)
internal class PlansServiceImpl : IPlansService {

    private val localeService by BibleLocaleService()

    override suspend fun getPlan(id: Int, languageTag: String?) = _repository.value?.let {
        blockingSuspendCoroutine<Plan> {
            repository.getPlan(id, it)
        }
    } ?: try {
        PlansApi.getPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
            ?: PlansApi.getUnAuthedPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
    } catch (e: Exception) {
        PlansApi.getUnAuthedPlan(id, languageTag ?: Locales.getDefault().getApiTag2())
    }

    override suspend fun getPlanDay(id: Int, day: Int): PlanDay =
        blockingSuspendCoroutine {
            repository.getPlanDay(id, day, it)
        }

    override suspend fun saveForLater(id: Int) =
        blockingSuspendCoroutine<Unit> {
            repository.saveForLater(id, it)
        }

    override suspend fun getConfiguration() = PlansApi.getConfiguration() ?: error("Configuration not found")

    override suspend fun getDiscover(languageTag: String) = PlansApi.getDiscover(languageTag)

    override suspend fun getDiscoverCollection(collectionId: Int, page: Int) = PlansApi.getDiscoverCollection(collectionId, page)

    override suspend fun getSuggestedPlans(planId: Int, languageTag: String) =
        PlansApi.getSuggestedPlans(planId, languageTag)

    override suspend fun getPlansByCategory(category: String): PlanResults =
        PlansApi.getPlansByCategory(category) ?: PlanResults()

    override suspend fun getPlansByReference(usfm: String): PlanResults =
        PlansApi.getPlansByReference(usfm) ?: PlanResults()

    override suspend fun getLanguages(): List<BibleLocale> = localeService.getLocales(getConfiguration().languageTags.toSet())
}

@SharedImmutable
private val _repository = AtomicReference<PlanRepository?>(null).freeze()

var repository: PlanRepository
    get() = _repository.value!!
    set(repository) {
        _repository.setAssertTrue(repository.freeze())
    }
