package youversion.red.movies.service.js

import kotlin.Int
import kotlin.Pair
import kotlin.String
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.movies.Movie
import youversion.red.movies.MovieChapters
import youversion.red.movies.MovieLanguages
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.Movies
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesCollections
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.VideoMetadata
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.videos.VideoTypeEnum
import youversion.red.movies.service.IMoviesService

public object MoviesService {
  private val service: IMoviesService by service<youversion.red.movies.service.IMoviesService>()

  @JsName(name = "getConfiguration")
  public fun getConfiguration(): Promise<MoviesConfiguration?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getConfiguration()

  }.toPromise()

  @JsName(name = "getPublisher")
  public fun getPublisher(id: Int): Promise<MoviePublisher?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getPublisher(id)

  }.toPromise()

  @JsName(name = "getMovie")
  public fun getMovie(id: Int, context: MoviesUIContext): Promise<Movie?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getMovie(id, context)

  }.toPromise()

  @JsName(name = "getChapters")
  public fun getChapters(): Promise<MovieChapters?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getChapters()

  }.toPromise()

  @JsName(name = "getMoviesRelatedTo")
  public fun getMoviesRelatedTo(usfm: String, page: Int?): Promise<Pair<Movies, String?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getMoviesRelatedTo(usfm, page)

  }.toPromise()

  @JsName(name = "getMoviesInCollection")
  public fun getMoviesInCollection(
    id: Int,
    type: VideoTypeEnum?,
    orientation: MovieOrientation?,
    pageSize: Int?,
    page: Int?
  ): Promise<Pair<Movies, String?>?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default)
      {
    service.getMoviesInCollection(id, type, orientation, pageSize, page)

  }.toPromise()

  @JsName(name = "getCollection")
  public fun getCollection(id: Int): Promise<MoviesCollection?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getCollection(id)

  }.toPromise()

  @JsName(name = "getLanguages")
  public fun getLanguages(type: String): Promise<MovieLanguages?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLanguages(type)

  }.toPromise()

  @JsName(name = "getCollections")
  public fun getCollections(page: Int?): Promise<Pair<MoviesCollections, String?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getCollections(page)

  }.toPromise()

  @JsName(name = "getLegacyVideo")
  public fun getLegacyVideo(id: Int): Promise<VideoMetadata?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getLegacyVideo(id)

  }.toPromise()

  @JsName(name = "getActualMovieId")
  public fun getActualMovieId(id: Int): Promise<Pair<Int, MoviesCollection?>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getActualMovieId(id)

  }.toPromise()
}
