package youversion.red.prayer.guided.tasks

import red.platform.Date
import red.platform.Log
import red.platform.LogLevel
import red.platform.threads.SuspendedLock
import red.platform.threads.currentThread
import red.platform.threads.freeze
import red.platform.threads.sync
import red.platform.toCalendar
import youversion.red.guidedprayer.api.GuidedPrayerApi
import youversion.red.prayer.guided.ext.toDb
import youversion.red.prayer.guided.model.GuidedPrayerDay
import youversion.red.prayer.guided.service.GuidedPrayerStore

internal object DaysSync {

    private val lock = SuspendedLock().freeze()

    suspend fun process(
        guideId: Int,
        daysOfYear: List<Int>
    ) {
        val lockOwner = currentThread()
        lock.sync(lockOwner) {
            if (Log.level == LogLevel.DEBUG)
                Log.i("red-guided-prayer", "running Days Sync")

            val guidedPrayerList = mutableListOf<GuidedPrayerDay>()

            daysOfYear.forEach { dayOfYear ->
                val apiDay = GuidedPrayerApi.getDay(guideId, dayOfYear)
                apiDay?.toDb(guideId)?.let {
                    guidedPrayerList.add(it)
                }
            }

            GuidedPrayerStore.processDays(guidedPrayerList)
        }
    }
}

// TODO: test this method
fun calculateFutureDaysOfYear(start: Date, numberOfDaysInFuture: Int): List<Int> {
    val cal = start.toCalendar()
    val startDay = cal.dayOfYear
    val lastDayOfYear = cal.maximumDayOfYear
    return List(numberOfDaysInFuture) {
        (startDay + it) % (lastDayOfYear + 1)
    }
}
