package youversion.red.analytics.shared

import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

data class PushPromptEvent(val granted: Boolean) : Event() {

    override val key = "push_prompt"

    override fun toBundle() = Bundle().apply {
        putBoolean("granted", granted)
    }
}
