// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class SharesSent(
  /**
   * Array of receiver user Ids that should receive a share of the given prayer.
   */
  @SerialName("receiver_ids")
  @ProtoNumber(1)
  @JvmField
  val receiverIds: List<Long>? = null,
  /**
   * This is the date when the share occurred.
   */
  @SerialName("created_dt")
  @ProtoNumber(2)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * User can input a reason why this prayer was shared to the selected people/person.
   */
  @SerialName("message")
  @ProtoNumber(3)
  @JvmField
  val message: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
