package youversion.red.prayer.tasks

import red.platform.Log
import red.platform.LogLevel
import red.platform.currentTimeMillis
import red.platform.http.RequestException
import red.platform.io.IOException
import red.platform.newDate
import red.platform.threads.AtomicLong
import red.platform.threads.SuspendedLock
import red.platform.threads.Thread
import red.platform.threads.currentThread
import red.platform.threads.freeze
import red.platform.threads.getValue
import red.platform.threads.setValue
import red.platform.threads.sync
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.PrayerReaction
import youversion.red.prayer.ext.toApi
import youversion.red.prayer.ext.toPost
import youversion.red.prayer.ext.toPrayerParticipant
import youversion.red.prayer.ext.toPrayerPost
import youversion.red.prayer.ext.toPrayerPut
import youversion.red.prayer.ext.toPut
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerReaction as DbReaction
import youversion.red.prayer.model.PrayerUpdate
import youversion.red.prayer.service.PrayerService
import youversion.red.prayer.service.PrayerStore
import youversion.red.prayer.util.PrayerUtil.STATE_DELETED
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY
import youversion.red.prayer.util.PrayerUtil.STATE_NEW
import youversion.red.security.service.UsersService

object PrayerChangesSync {

    private val syncLock = SuspendedLock().freeze()
    private val prayerService by PrayerService()

    private val nextAvailableDeleteSync = AtomicLong(0L).freeze()
    private const val PENDING_CHANGE_TIMEOUT = 30_000L

    private val usersService by UsersService()

    internal fun updateNextAvailableDeleteSyncTime() {
        nextAvailableDeleteSync.setValue(currentTimeMillis().toLong() + PENDING_CHANGE_TIMEOUT)
    }

    suspend fun process(lockOwner: Thread = currentThread()) {
        assertNotMainThread()
        requireUser { return }
        requireNetwork { return }
        changesLock.sync(lockOwner) {
            try {
                if (Log.level == LogLevel.DEBUG)
                    Log.i("red-prayer", "running PrayerChangesSync")
                syncPrayers()
                syncPrayerUpdates()
                syncReactions()
                syncComments()
                if (Log.level == LogLevel.DEBUG)
                    Log.i("red-prayer", "done running PrayerChangesSync")
            } catch (e: Exception) {
                Log.e("red-prayer", "error syncing changes", e)
                @Suppress("DEPRECATION_ERROR")
                if (e !is IOException) {
                    throw e
                }
            }
        }
    }

    private suspend fun syncPrayerUpdates() {
        assertNotMainThread()
        val dirtyUpdates = PrayerStore.getPrayerUpdatesByStateSync(STATE_DIRTY)
        if (dirtyUpdates.isEmpty()) {
            if (Log.level == LogLevel.DEBUG)
                Log.i("red-prayer", "no dirty updates, returning early")
            return
        }
        val updateUpdates = mutableListOf<PrayerUpdate>()
        val deleteUpdates = mutableListOf<PrayerUpdate>()

        fun handleError(e: Exception, update: PrayerUpdate) {
            when ((e as? RequestException)?.errorCode) {
                404, 403, 400 -> {
                    // for 400 case, we probably need to do something better and show an error state with a prayer
                    // and let the user know how to fix it, but this is fine for now. shouldn't really happen anyway with our
                    // up front validation
                    Log.e(
                        "red-prayer",
                        "server doesn't know about prayer update, we're deleting it",
                        e
                    )
                    deleteUpdates.add(update)
                }
                else -> {
                }
            }
        }

        for (u in dirtyUpdates) {
            var update = u
            if ((update.state and STATE_DELETED) == STATE_DELETED) {
                try {
                    if (update.serverId != null && update.serverId!! > 0)
                        PrayerApi.deleteUpdate(update.serverId!!)
                    deleteUpdates.add(update)
                } catch (e: Exception) {
                    Log.e(
                        "red-prayer",
                        "error deleting prayer update: update id: ${update.serverId}",
                        e
                    )
                    handleError(e, update)
                }
            } else {
                try {
                    var syncedWithServer = true
                    when {
                        (update.state and STATE_NEW) == STATE_NEW -> {
                            val p = PrayerStore.getPrayerSync(
                                update.prayerClientId
                                    ?: throw NullPointerException("couldn't sync update because there was no prayer associated with it: $update")
                            )
                            update = update.copy(
                                serverId = PrayerApi.addUpdate(
                                    prayerId = p?.serverId
                                        ?: throw NullPointerException("no prayer server id found for: $update"),
                                    body = update.toApi()
                                ).id
                            )
                        }
                        (update.serverId ?: 0) > 0 -> PrayerApi.editPrayerUpdate(
                            update.serverId!!,
                            update.toApi()
                        )
                        else -> syncedWithServer = false
                    }
                    if (syncedWithServer) {
                        var state = update.state
                        if (state and STATE_NEW == STATE_NEW)
                            state = state xor STATE_NEW
                        if (state and STATE_DIRTY == STATE_DIRTY)
                            state = state xor STATE_DIRTY
                        update = update.copy(lastSync = newDate(), state = state)
                        updateUpdates.add(update)
                    }
                } catch (e: Exception) {
                    Log.e("red-prayer", "error sending prayer update", e)
                    handleError(e, update)
                }
            }
        }

        PrayerStore.updateAndDeleteUpdates(updateUpdates, deleteUpdates)
    }

    internal suspend fun syncReactions() {
        assertNotMainThread()
        val updates = mutableListOf<DbReaction>()
        val deletes = mutableListOf<DbReaction>()
        PrayerStore.getDirtyReactionsSync().forEach {
            try {
                val serverId = it.prayerServerId ?: prayerService.findServerId(it.prayerClientId)
                ?: throw Exception("couldn't find server id")
                PrayerApi.editPrayerReaction(
                    serverId,
                    PrayerReaction(total = it.total, updatedDt = it.updatedDt)
                )
                updates.add(
                    it.copy(
                        prayerServerId = serverId,
                        needsSyncing = false
                    )
                )
            } catch (e: Exception) {
                Log.e("red-prayer", "error syncing prayer reaction $it", e)
                if ((e as? RequestException)?.errorCode == 400) {
                    Log.e(
                        "red-prayer",
                        "error syncing prayer reaction, we're deleting it",
                        e
                    )
                    deletes.add(it)
                }
            }
        }
        PrayerStore.updateAndDeleteReactions(updates, deletes)
    }

    suspend fun syncPrayers() {
        assertNotMainThread()
        val dirtyPrayers = PrayerStore.getPrayersByStateSync(STATE_DIRTY)
        if (dirtyPrayers.isEmpty()) {
            if (Log.level == LogLevel.DEBUG)
                Log.i("red-prayer", "no dirty prayers, returning early")
            return
        }
        val updatePrayers = mutableListOf<DbPrayer>()
        val deletePrayers = mutableListOf<DbPrayer>()

        fun handle4xError(e: Exception, handle: () -> Unit) {
            when ((e as? RequestException)?.errorCode) {
                404, 403, 400 -> {
                    handle()
                }
                429 -> {
                    throw Exception("Too many requests", e)
                }
                else -> {
                }
            }
        }

        for (p in dirtyPrayers) {
            var prayer = p
            val prayerIsMine = (prayer.userId?.toInt() == usersService.getCurrentUser()?.id)
            if (prayer.state and STATE_DELETED == STATE_DELETED) {
                if (nextAvailableDeleteSync.getValue() <= currentTimeMillis().toLong()) {
                    try {
                        if ((prayer.serverId ?: 0) > 0) {
                            if (prayerIsMine)
                                PrayerApi.deletePrayer(prayer.serverId!!)
                            else
                                PrayerApi.updateParticipantPrayer(
                                    prayer.serverId!!,
                                    prayer.toPrayerParticipant()
                                )
                        }
                        deletePrayers.add(prayer)
                    } catch (e: Exception) {
                        Log.e("red-prayer", "error deleting prayer", e)
                        handle4xError(e) {
                            Log.e(
                                "red-prayer",
                                "server doesn't know about prayer, we're deleting it",
                                e
                            )
                            deletePrayers.add(prayer)
                        }
                    }
                } else {
                    if (Log.level == LogLevel.DEBUG)
                        Log.i(
                            "red-prayer",
                            "delete is pending, we're skipping the delete for now in case user wants to undo"
                        )
                }
            } else {
                try {
                    var syncedWithServer = true
                    when {
                        (prayer.state and STATE_NEW) == STATE_NEW -> {
                            if (Log.level == LogLevel.DEBUG)
                                Log.i("red-prayer", "posting prayer ${prayer.toPrayerPost()}")
                            prayer =
                                prayer.copy(serverId = PrayerApi.addPrayer(body = prayer.toPrayerPost()).id)
                        }
                        (prayer.serverId ?: 0) > 0 -> {
                            if (prayerIsMine) {
                                if (Log.level == LogLevel.DEBUG)
                                    Log.i("red-prayer", "putting prayer ${prayer.toPrayerPut()}")
                                PrayerApi.editPrayer(prayer.serverId!!, prayer.toPrayerPut())
                            } else {
                                if (Log.level == LogLevel.DEBUG)
                                    Log.i(
                                        "red-prayer",
                                        "putting prayer participant ${prayer.toPrayerParticipant()}"
                                    )
                                PrayerApi.updateParticipantPrayer(
                                    prayer.serverId!!,
                                    prayer.toPrayerParticipant()
                                )
                            }
                        }
                        else -> {
                            if (Log.level == LogLevel.DEBUG)
                                Log.i(
                                    "red-prayer",
                                    "not syncing prayer, we don't have a server id: $prayer"
                                )
                            syncedWithServer = false
                        }
                    }
                    if (syncedWithServer) {
                        var state = prayer.state
                        if (state and STATE_NEW == STATE_NEW)
                            state = state xor STATE_NEW
                        if (state and STATE_DIRTY == STATE_DIRTY)
                            state = state xor STATE_DIRTY
                        prayer = prayer.copy(lastSync = newDate(), state = state)
                        updatePrayers.add(prayer)
                    }
                } catch (e: Exception) {
                    Log.e(
                        "red-prayer",
                        "error sending prayer ${if (prayer.state and STATE_NEW == STATE_NEW) "POST" else "PUT"}: $prayer",
                        e
                    )
                    handle4xError(e) {
                        if (prayer.state and STATE_NEW != STATE_NEW) {
                            if (prayerIsMine) {
                                Log.e(
                                    "red-prayer",
                                    "trying to edit our prayer but it didn't exist on the server, we're going to create it: prayer: $prayer",
                                    e
                                )
                                prayer = prayer.copy(state = prayer.state or STATE_NEW)
                                updatePrayers.add(prayer)
                            } else {
                                Log.e(
                                    "red-prayer",
                                    "trying to edit friend's prayer but it didn't exist on the server, we're going to delete it: prayer: $prayer",
                                    e
                                )
                                deletePrayers.add(prayer)
                            }
                        }
                    }
                }
            }
        }

        PrayerStore.updateAndDeletePrayers(updatePrayers, deletePrayers)
    }

    private suspend fun syncComments() {
        val dirtyComments = PrayerStore.getCommentsByStateSync(STATE_DIRTY)
        if (dirtyComments.isEmpty()) {
            if (Log.level == LogLevel.DEBUG)
                Log.i("red-prayer", "no dirty comments, returning early")
            return
        }
        val updates = mutableListOf<PrayerComment>()
        val deletes = mutableListOf<PrayerComment>()

        dirtyComments.forEach {
            var comment = it
            if (comment.state.and(STATE_DELETED) == STATE_DELETED) {
                try {
                    if (comment.serverId != null)
                        PrayerApi.deleteComment(comment.serverId!!)
                    deletes.add(comment)
                } catch (e: Exception) {
                    Log.e("red-prayer", "error deleting prayer comment: $comment", e)
                    when ((e as? RequestException)?.errorCode) {
                        404, 403, 400 -> {
                            deletes.add(comment)
                        }
                        else -> {
                        }
                    }
                }
            } else {
                try {
                    var syncedWithServer = true
                    when {
                        (comment.state.and(STATE_NEW) == STATE_NEW) -> {
                            val prayerServerId =
                                comment.prayerServerId
                                    ?: prayerService.findServerId(comment.prayerClientId!!)
                                    ?: throw NullPointerException("unable to sync comment because we don't have a prayer server id associated with it")
                            val serverId = PrayerApi.addComment(prayerId = prayerServerId, body = comment.toPost()).id
                            comment = comment.copy(serverId = serverId, prayerServerId = prayerServerId)
                        }
                        (comment.serverId ?: 0) > 0 -> {
                            if (Log.level == LogLevel.DEBUG)
                                Log.i(
                                    "red-prayer",
                                    "putting prayer comment ${comment.toPut()}"
                                )
                            PrayerApi.updateComment(commentId = comment.serverId!!, body = comment.toPut())
                        }
                        else -> {
                            if (Log.level == LogLevel.DEBUG)
                                Log.i(
                                    "red-prayer",
                                    "not syncing prayer comment, we don't have a server id: $comment"
                                )
                            syncedWithServer = false
                        }
                    }

                    if (syncedWithServer) {
                        var state = comment.state
                        if (state and STATE_NEW == STATE_NEW)
                            state = state xor STATE_NEW
                        if (state and STATE_DIRTY == STATE_DIRTY)
                            state = state xor STATE_DIRTY
                        comment = comment.copy(lastSync = newDate(), state = state)
                        updates.add(comment)
                    }
                } catch (e: Exception) {
                    Log.e("red-prayer", "error creating/updating prayer comment: $comment", e)
                    when ((e as? RequestException)?.errorCode) {
                        404, 403, 400 -> {
                            Log.e("red-prayer", "we're going to delete the comment because we got a 4x error")
                            deletes.add(comment)
                        }
                        else -> {
                        }
                    }
                }
            }
        }

        PrayerStore.updateAndDeleteComments(updates, deletes)
    }
}
