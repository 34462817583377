/**
 * @module isVerseOrChapter
 */

/**
 * Checks to see if the usfm is a chapter and/or a verse.
 *
 * @alias module:isVerseOrChapter
 *
 * @param {(Array|string)} usfmArg - A usfm array or string value with the Bible book and a chapter and/or verse reference.
 *
 * @returns {object} - Object with keys and Boolean values representing whether or not usfmArg is a chapter and/or a verse.
 *
 * @example
 * // returns {isVerse: false, isChapter: true}
 * isVerseOrChapter('Mat.1')
 * // returns {isVerse: true, isChapter: false}
 * isVerseOrChapter(['Mat.1.1', 'esv'])
 */

export function isVerseOrChapter(usfmArg) {
  const IS_BOOK = /^\d?[a-zA-Z]{2,3}$/
  const IS_CHAPTER = /^(INTRO)?[0-9_]+$/
  const IS_VERSE = /^[0-9-,_]+$/
  const FALLBACK_VALUE = { isVerse: false, isChapter: false }
  const usfm = Array.isArray(usfmArg) ? usfmArg[0] : usfmArg
  if (typeof usfm !== 'string' || usfm.length === 0) {
    return FALLBACK_VALUE
  }

  const usfmParts = usfm.split('+')[0].split('.')

  let isVerse = usfmParts.length >= 4
  let isChapter = usfmParts.length === 2

  if (
    usfm.length === 0 ||
    !IS_BOOK.test(usfmParts[0]) ||
    !IS_CHAPTER.test(usfmParts[1])
  ) {
    return FALLBACK_VALUE
  }
  if (usfmParts.length >= 3) {
    isVerse = IS_VERSE.test(usfmParts[2])
    isChapter = !isVerse
  }

  return { isVerse, isChapter }
}
