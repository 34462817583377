import React from 'react'

/**
 * # useAsync Hook
 * Rather then litter your components with a bunch of useState calls to keep track of the state of
 * an async function, you can use our custom hook which takes an async function as an input and
 * returns the pending, value, and error values we need to properly update our UI. As you'll see
 * in the code below, our hook allows both immediate execution and delayed execution using the
 * returned execute function. (source: https://usehooks.com/useAsync/).
 *
 * _Adapted to have an idle state_.
 *
 * @param {Promise<*>} asyncFunction - Async function used to keep track of its current state.
 * @param {boolean} immediate - Runs the async function immediately if true, otherwise refer to the `execute` function returned from this hook to be executed at the time of your choosing.
 */
export function useAsync(asyncFunction, immediate = true) {
  const [idle, setIdle] = React.useState(true)
  const [pending, setPending] = React.useState(false)
  const [value, setValue] = React.useState(null)
  const [error, setError] = React.useState(null)

  // The execute function wraps asyncFunction and
  // handles setting state for pending, value, and error.
  // useCallback ensures the below useEffect is not called
  // on every render, but only if asyncFunction changes.
  const execute = React.useCallback(() => {
    setIdle(false)
    setPending(true)
    setValue(null)
    setError(null)
    return asyncFunction()
      .then((response) => {
        if (response === undefined) {
          throw new Error('Async function returned undefined')
        }
        setValue(response)
      })
      .catch((e) => setError(e))
      .finally(() => setPending(false))
  }, [asyncFunction])

  // Call execute if we want to fire it right away.
  // Otherwise execute can be called later, such as
  // in an onClick handler.
  React.useEffect(() => {
    if (immediate) {
      execute()
    }
  }, [execute, immediate])

  return { idle, execute, pending, value, error }
}
