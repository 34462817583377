package red

import kotlin.js.JsName
import red.lifecycle.AppLifecycleRegistrar
import red.module.ModuleRegistry
import red.module.RedRedFrameworkModuleBuilder
import red.platform.Log
import red.platform.http.RequestManager
import red.platform.metrics.Metrics
import red.platform.metrics.use
import red.platform.threads.AtomicReference
import red.platform.threads.set
import youversion.red.analytics.AnalyticsContextManager
import youversion.red.analytics.DataMan
import youversion.red.analytics.DataManInterfaceImpl
import youversion.red.analytics.log
import youversion.red.cache.CacheClearListener
import youversion.red.cache.CacheClearService
import youversion.red.dataman.api.model.installations.InstallationsV2
import youversion.red.deeplink.DeepLinks
import youversion.red.installation.InstallationEvent
import youversion.red.installation.InstallationEventFirer
import youversion.red.security.impl.tokens.YouVersionToken
import youversion.red.security.service.UsersService

private class InstallationEventFirerImpl : InstallationEventFirer {
    override fun fire() {
        InstallationsV2().log()
    }
}

object REDInitializer {

    private val initialized = AtomicReference(false)

    private val usersService by UsersService()

    @JsName("initialize")
    fun initialize(
        context: AppContext,
        appVersion: String,
        clientId: String,
        clientSecret: String,
        accessGroup: String? = null,
        googleWebClientId: String? = null,
        cacheClearListener: CacheClearListener? = null
    ) {
        Metrics.newTrace("RED#initialize").use {
            REDPlatformInit.initialize(context, accessGroup, googleWebClientId)
            YouVersionToken.clientId = clientId
            YouVersionToken.clientSecret = clientSecret
            if (!initialized.value) {
                initialized.set(true)
                InstallationEvent.firer = InstallationEventFirerImpl()
                DataMan.dataManInterface = DataManInterfaceImpl()
                Red.versionCode = RedMetaData.appVersionCode
                Red.version = RedMetaData.version
                RequestManager.registerDefaultHeaders()
                RedRedFrameworkModuleBuilder().build()
                ModuleRegistry.initialize()
                AppLifecycleRegistrar.register(context)
                cacheClearListener?.let {
                    CacheClearService.register(it)
                }
                CacheClearService.check()
                AnalyticsContextManager.initialize(appVersion)
                DeepLinks.validate().also {
                    if (it.isNotEmpty()) {
                        Log.e(
                            "AppInitializer",
                            "****************************\nThe following deep link handlers haven't been configured: $it\n****************************\n"
                        )
                    }
                }
            }
        }
    }
}

internal expect object REDPlatformInit {

    fun initialize(context: AppContext, accessGroup: String?, googleWebClientId: String?)
}
