package youversion.red.prayer.guided.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date

public data class Guided_prayer_day(
  public val guideId: Int,
  public val id: Int,
  public val imageUrl: String?,
  public val title: String?,
  public val lightBgColor: String?,
  public val darkBgColor: String?,
  public val lastSync: Date?
) {
  public override fun toString(): String = """
  |Guided_prayer_day [
  |  guideId: $guideId
  |  id: $id
  |  imageUrl: $imageUrl
  |  title: $title
  |  lightBgColor: $lightBgColor
  |  darkBgColor: $darkBgColor
  |  lastSync: $lastSync
  |]
  """.trimMargin()

  public class Adapter(
    public val lastSyncAdapter: ColumnAdapter<Date, Long>
  )
}
