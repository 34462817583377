// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.guidedprayer

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent

@Serializable
@SerialName("guidedprayer.GPBackgroundAudioCompleteEvent")
data class GPBackgroundAudioCompleteEvent(
  @SerialName("audioCategoryId")
  @ProtoNumber(1)
  val audiocategoryid: Int? = null,
  /**
   * session for this media event
   */
  @SerialName("mediaSessionId")
  @ProtoNumber(50)
  override val mediasessionid: String? = null,
  /**
   * seconds skipped while playing
   */
  @SerialName("secondsSkipped")
  @ProtoNumber(51)
  override val secondsskipped: Int? = null,
  /**
   * seconds played
   */
  @SerialName("secondsPlayed")
  @ProtoNumber(52)
  override val secondsplayed: Int? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractMediaCompleteEvent(), red.Serializable {
  init {
    freeze()
  }
}
