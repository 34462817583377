package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PlanDays(
    @ProtoNumber(1) val days: List<PlanDay>,
    @ProtoNumber(2) val pageSize: Int = 0,
    @ProtoNumber(3) val nextPage: Boolean = false
) {
    init {
        freeze()
    }
}
