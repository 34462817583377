package youversion.red.podcasts.service.js

import kotlin.Deprecated
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import kotlinx.coroutines.flow.Flow
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.podcasts.api.model.shows.Show
import youversion.red.podcasts.api.model.shows.Shows
import youversion.red.podcasts.model.ListeningProgress
import youversion.red.podcasts.model.PodcastEpisode
import youversion.red.podcasts.model.PodcastEpisodes
import youversion.red.podcasts.service.IPodcastsService

public object PodcastsService {
  private val service: IPodcastsService by
      service<youversion.red.podcasts.service.IPodcastsService>()

  @JsName(name = "getFeaturedEpisodesWithLanguageTag")
  public fun getFeaturedEpisodes(
    page: Int?,
    pageSize: Int?,
    languageTag: String?
  ): Promise<PodcastEpisodes?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getFeaturedEpisodes(page, pageSize, languageTag)

  }.toPromise()

  @JsName(name = "getFeaturedEpisodes")
  public fun getFeaturedEpisodes(page: Int?, pageSize: Int?): Promise<PodcastEpisodes?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getFeaturedEpisodes(page, pageSize)

  }.toPromise()

  @JsName(name = "getFeaturedEpisodesFlowAndLanguageTag")
  public fun getFeaturedEpisodesFlow(
    page: Int?,
    pageSize: Int?,
    languageTag: String?
  ): Flow<PodcastEpisodes?> = service.getFeaturedEpisodesFlow(page, pageSize, languageTag)

  @JsName(name = "getFeaturedEpisodesFlow")
  public fun getFeaturedEpisodesFlow(page: Int?, pageSize: Int?): Flow<PodcastEpisodes?> =
      service.getFeaturedEpisodesFlow(page, pageSize)

  @Deprecated(message =
      "This method will be removed in a future release. Please getEpisode(episodeId) instead.")
  @JsName(name = "getEpisodeWithShowId")
  @Suppress("deprecation")
  public fun getEpisode(showId: Int, episodeId: Int): Promise<PodcastEpisode?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEpisode(showId, episodeId)

  }.toPromise()

  @JsName(name = "getEpisode")
  public fun getEpisode(episodeId: Int): Promise<PodcastEpisode?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEpisode(episodeId)

  }.toPromise()

  @JsName(name = "addOrUpdateListeningProgress")
  public fun addOrUpdateListeningProgress(
    episodeId: Int,
    timeElapsed: Int,
    totalTime: Int
  ): Promise<Unit?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.addOrUpdateListeningProgress(episodeId, timeElapsed, totalTime)

  }.toPromise()

  @JsName(name = "getListeningProgress")
  public fun getListeningProgress(episodeId: Int): Promise<ListeningProgress?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getListeningProgress(episodeId)

  }.toPromise()

  @JsName(name = "getListeningProgressFlow")
  public fun getListeningProgressFlow(episodeId: Int): Flow<ListeningProgress?> =
      service.getListeningProgressFlow(episodeId)

  @JsName(name = "getContinueListeningEpisodes")
  public fun getContinueListeningEpisodes(): Promise<List<PodcastEpisode>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getContinueListeningEpisodes()

  }.toPromise()

  @JsName(name = "getContinueListeningEpisodesFlow")
  public fun getContinueListeningEpisodesFlow(): Flow<List<PodcastEpisode>> =
      service.getContinueListeningEpisodesFlow()

  @JsName(name = "markPodcastAsPlayed")
  public fun markPodcastAsPlayed(episodeId: Int): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.markPodcastAsPlayed(episodeId)

  }.toPromise()

  @JsName(name = "getShow")
  public fun getShow(showId: Int): Promise<Show?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getShow(showId)

  }.toPromise()

  @JsName(name = "getShows")
  public fun getShows(page: Int?, pageSize: Int?): Promise<Shows?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getShows(page, pageSize)

  }.toPromise()

  @JsName(name = "getEpisodesByShowId")
  public fun getEpisodesByShowId(
    showId: Int,
    page: Int?,
    pageSize: Int?
  ): Promise<PodcastEpisodes?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEpisodesByShowId(showId, page, pageSize)

  }.toPromise()

  @JsName(name = "getEpisodesByPlaylistId")
  public fun getEpisodesByPlaylistId(
    playlistId: Int,
    page: Int?,
    pageSize: Int?
  ): Promise<PodcastEpisodes?> = CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getEpisodesByPlaylistId(playlistId, page, pageSize)

  }.toPromise()

  @JsName(name = "deleteListeningProgress")
  public fun deleteListeningProgress(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.deleteListeningProgress()

  }.toPromise()
}
