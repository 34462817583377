// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.api

import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.podcasts.api.model.PodcastPlaylistKind
import youversion.red.podcasts.api.model.episodes.Episode
import youversion.red.podcasts.api.model.episodes.Episodes
import youversion.red.podcasts.api.model.playlists.Playlist
import youversion.red.podcasts.api.model.playlists.Playlists
import youversion.red.podcasts.api.model.shows.Show
import youversion.red.podcasts.api.model.shows.Shows

/**
 * The Podcast API, an API for podcasts
 */
object PodcastsApi : AbstractApi(ApiDefaults("podcasts", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", PodcastsApiSerializer)) {
  /**
   * All the podcast shows
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param playlistId On collections this is used to filter episodes & shows returned by those
   * belonging to the given playlist id.
   * @return return available podcast shows
   */
  suspend fun getShows(
    page: Int? = null,
    pageSize: Int? = null,
    playlistId: Int? = null
  ): Shows = execute("/shows", method = RequestMethods.GET, version = "4.0", authAllowed = false,
      authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      playlistId?.let { add("playlist_id", playlistId) }
  }, serializer = Shows.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Use this to create a show so that you can add episodes to it. Episodes can not be added without
   * a show existing first
   *
   * @return Return the created show
   */
  suspend fun createShow(body: Show? = null): Show = execute("/shows", version = "4.0", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      Show.serializer(), serializer = Show.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Get a collection of episodes across multiple shows.
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param playlistId On collections this is used to filter episodes & shows returned by those
   * belonging to the given playlist id.
   * @return Return available episodes for given parameters
   */
  suspend fun getAllShowsEpisodes(
    page: Int? = null,
    pageSize: Int? = null,
    playlistId: Int? = null
  ): Episodes = execute("/shows/-/episodes", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      playlistId?.let { add("playlist_id", playlistId) }
  }, serializer = Episodes.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * all the episodes of a show
   *
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param showId The id of the show resource.
   * @return return available episodes for a podcast
   */
  suspend fun getEpisodes(
    page: Int? = null,
    pageSize: Int? = null,
    showId: Int
  ): Episodes = execute("/shows/${showId}/episodes", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Episodes.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Use this to create an epsiode, must have a pre-existing show the episode is and episode of
   *
   * @param showId The id of the show resource.
   * @return Return the created episode
   */
  suspend fun createEpisode(showId: Int, body: Episode? = null): Episode =
      execute("/shows/${showId}/episodes", version = "4.0", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer = Episode.serializer(),
      serializer = Episode.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param showId The id of the show resource.
   * @return returns a show resource
   */
  suspend fun getShow(showId: Int): Show? = execute("/shows/${showId}", method = RequestMethods.GET,
      version = "4.0", authAllowed = false, authOptional = false, serializer = Show.serializer())

  /**
   * @param showId The id of the show resource.
   * @return returns show resource values
   */
  suspend fun editShow(showId: Int, body: Show? = null): Show? = execute("/shows/${showId}", version
      = "4.0", method = RequestMethods.PUT, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Show.serializer(), serializer = Show.serializer())

  /**
   * @param showId The id of the show resource.
   */
  suspend fun deleteShow(showId: Int) = execute("/shows/${showId}", method = RequestMethods.DELETE,
      version = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * This API returns a single Episode Resource. It is the same Resource returned from getEpisode,
   * but the difference is that this API doesn't require a show id. This is useful when getting episode
   * ids from search where a show id is not provided.
   *
   * @param episodeId The id of the episode resource
   * @return return a specific episode
   */
  suspend fun getAllShowsEpisode(episodeId: Int): Episode? =
      execute("/shows/-/episodes/${episodeId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, serializer = Episode.serializer())

  /**
   * one episode
   *
   * @param showId The id of the show resource.
   * @param episodeId The id of the episode resource
   * @return return a specific episode
   */
  suspend fun getEpisode(showId: Int, episodeId: Int): Episode? =
      execute("/shows/${showId}/episodes/${episodeId}", method = RequestMethods.GET, version =
      "4.0", authAllowed = false, authOptional = false, serializer = Episode.serializer())

  /**
   * @param showId The id of the show resource.
   * @param episodeId The id of the episode resource
   * @return returns episode resource values
   */
  suspend fun editEpisode(
    showId: Int,
    episodeId: Int,
    body: Episode? = null
  ): Episode? = execute("/shows/${showId}/episodes/${episodeId}", version = "4.0", method =
      RequestMethods.PUT, authAllowed = true, authOptional = false, body = body, bodySerializer =
      Episode.serializer(), serializer = Episode.serializer())

  /**
   * @param showId The id of the show resource.
   * @param episodeId The id of the episode resource
   */
  suspend fun deleteEpisode(showId: Int, episodeId: Int) =
      execute("/shows/${showId}/episodes/${episodeId}", method = RequestMethods.DELETE, version =
      "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * Is used for creating a playlist of featured episodes for a specific language.
   *
   * @return Return the created playlist post schema
   */
  suspend fun createPlaylist(body: Playlist? = null): Playlist = execute("/playlists", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Playlist.serializer(), serializer = Playlist.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This returns a list of playlists according to params passed in the query parameters.
   *
   * @param kind This describes what kind of podcast playlist you want to filter by. The default
   * value is "featured".
   * @param languageTag The iso 639 language tag of the show. To retrieve featured episodes for a
   * language use this paremeter in addition to a true value in the featured paremeter.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return returns available playlists for the given params.
   */
  suspend fun getPlaylists(
    kind: PodcastPlaylistKind? = null,
    languageTag: String,
    page: Int? = null,
    pageSize: Int? = null
  ): Playlists? = execute("/playlists", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      kind?.let { add("kind", kind.serialName) }
      add("language_tag", languageTag)
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Playlists.serializer())

  /**
   * @param playlistIdPath The id of the playlist resource to return.
   * @return returns a playlist
   */
  suspend fun editPlaylist(playlistIdPath: Int, body: Playlist? = null): Playlist? =
      execute("/playlists/${playlistIdPath}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer = Playlist.serializer(),
      serializer = Playlist.serializer())

  /**
   * @param playlistIdPath The id of the playlist resource to return.
   */
  suspend fun deletePlaylist(playlistIdPath: Int) = execute("/playlists/${playlistIdPath}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())
}
