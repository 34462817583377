package youversion.red.prayer.guided.db

import kotlin.Int
import kotlin.String

public data class Guided_prayer_guide(
  public val id: Int,
  public val languageTags: String?
) {
  public override fun toString(): String = """
  |Guided_prayer_guide [
  |  id: $id
  |  languageTags: $languageTags
  |]
  """.trimMargin()
}
