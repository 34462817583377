package youversion.red.plans.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import red.platform.localization.LocaleContext
import red.platform.localization.Locales
import red.platform.localization.resolve
import red.platform.threads.freeze

@Serializable
data class PlanResults(
    @SerialName("next_page")
    val nextPage: Int? = null,
    val total: Int? = null,
    @SerialName("reading_plans")
    val plans: List<PlanResult>? = null
) {

    init {
        freeze()
    }
}

@Serializable
data class PlanRendition(
    val url: String,
    val width: Int,
    val height: Int
)

@Serializable
data class PlanResult(
    val id: Int,
    val name: Map<String, String>,
    val images: List<PlanRendition>?,
    @SerialName("total_days")
    val totalDays: Int
) {

    init {
        freeze()
    }

    suspend fun getTitle(): String? {
        val plansLocale =
            Locales.resolve(LocaleContext.PLANS, null).firstOrNull() ?: Locales.resolve(LocaleContext.DEFAULT, null)
                .firstOrNull() ?: Locales.getDefault()
        return name[plansLocale.getApiTag2()] ?: name["en"] ?: name["default"]
    }
}
