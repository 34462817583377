// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.api.model.playlists

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Playlist(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * An array of episode ids. The order of the ids in the array represents the order in which the
   * playlist will be returned.
   */
  @SerialName("episodes")
  @ProtoNumber(2)
  @JvmField
  val episodes: List<Int>? = null,
  /**
   * The iso 639 language tag of the playlist.
   */
  @SerialName("language_tag")
  @ProtoNumber(3)
  @JvmField
  val languageTag: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
