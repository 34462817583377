package youversion.red.blue.service

import kotlin.js.JsName
import red.platform.DateTime
import red.service.ExportedService
import red.service.IService
import red.service.ServiceRegistry
import red.service.service
import youversion.red.blue.BlueFeatureStateListener
import youversion.red.blue.api.model.state.FeatureState

@Deprecated("use by BlueService")
val BlueService: IBlueService
    get() = ServiceRegistry[IBlueService::class]

@JsName("BlueServiceDelegate")
fun BlueService() = service<IBlueService>()

/**
 * Listen for state changes by using `BlueFeatureStateListener`
 *   * You can do this by adding a listener to `BlueService#addListener` on Android (NOTE: it's a weak reference to the listener that was added).
 *   * You can do this by adding a listener to `BlueService#addDelegate` on iOS (NOTE: it's a weak reference to the listener, but in order for the weak reference to remain, you have to retain the opaque handle that is returned to you).
 *
 * When the state changes and the `featureId` is == to `FeatureClass.featureId` then you can recheck `FeatureClass.enabled` and do the appropriate things.
 */
@ExportedService
interface IBlueService : IService {

    // a transient value used to determine if this has been updated during the run of the app (aka: it's not saved between app restarts)
    val lastUpdated: DateTime

    fun addStateListener(listener: BlueFeatureStateListener)
    fun removeStateListener(listener: BlueFeatureStateListener)
    fun isEnrolled(featureId: String): Boolean
    fun getState(featureId: String): FeatureState?
    fun getAvailableFeatures(): List<String>

    suspend fun waitUntilUpdated()
    suspend fun setState(state: FeatureState)
    suspend fun update()
    suspend fun clearState()
    suspend fun clearEnrollment()
}
