/**
 * @module paramsToJSON
 */

/**
 * Convert params object to JSON encoded string.
 *
 * @alias module:paramsToJSON
 *
 * @param {object} params - A params object.
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The JSON encoded string.
 */
export function paramsToJSON(params) {
  if (!params) return ''
  if (!(typeof params === 'object')) {
    throw new Error('Supplied `params` must be an object.')
  }
  return JSON.stringify(params)
}
