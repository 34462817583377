package youversion.red.podcasts.service

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.combine
import kotlinx.coroutines.flow.first
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.transform
import red.platform.threads.freeze
import red.service.DefaultService
import red.tasks.CoroutineDispatchers.withIO
import youversion.red.podcasts.api.PodcastsApi
import youversion.red.podcasts.api.model.episodes.Episode
import youversion.red.podcasts.api.model.episodes.Episodes
import youversion.red.podcasts.api.model.shows.Show
import youversion.red.podcasts.api.model.shows.Shows
import youversion.red.podcasts.model.ListeningProgress
import youversion.red.podcasts.model.PodcastEpisode
import youversion.red.podcasts.model.PodcastEpisodes

@DefaultService(IPodcastsService::class)
internal class PodcastsServiceImpl : IPodcastsService {

    private fun getAllFeaturedEpisodes(
        page: Int?,
        pageSize: Int?,
        languageTag: String?
    ): Flow<Episodes?> = flow {
        val playlists = PodcastsApi.getPlaylists(
            languageTag = languageTag ?: ENGLISH
        )

        val episodes: Episodes? = playlists?.data?.firstOrNull()?.let { playlist ->
            PodcastsApi.getAllShowsEpisodes(
                playlistId = playlist.id,
                page = page,
                pageSize = pageSize
            )
        }

        emit(episodes)
    }

    override suspend fun getFeaturedEpisodes(
        page: Int?,
        pageSize: Int?,
        languageTag: String?
    ): PodcastEpisodes? = getFeaturedEpisodesFlow(page, pageSize, languageTag).first()

    override suspend fun getFeaturedEpisodes(page: Int?, pageSize: Int?): PodcastEpisodes? =
        getFeaturedEpisodesFlow(page, pageSize).first()

    override fun getFeaturedEpisodesFlow(
        page: Int?,
        pageSize: Int?,
        languageTag: String?
    ): Flow<PodcastEpisodes?> =
        getAllFeaturedEpisodes(
            page,
            pageSize,
            languageTag
        ).combine(
            PodcastsStore.getContinueListeningProgress()
        ) { allFeaturedEpisodes, continueListeningProgress ->

            val inProgressEpisodesMap =
                continueListeningProgress.associateByTo(mutableMapOf()) { it.episodeId }

            var filteredEpisodes: List<Episode>? = allFeaturedEpisodes?.data
            if (inProgressEpisodesMap.isNotEmpty()) {
                filteredEpisodes = allFeaturedEpisodes?.data?.filterNot { episode ->
                    inProgressEpisodesMap.containsKey(episode.id)
                }
            }

            val podcastEpisodes: List<PodcastEpisode>? = filteredEpisodes?.mapNotNull {
                assemblePodcastEpisode(it)
            }

            PodcastEpisodes(
                data = podcastEpisodes,
                pageSize = allFeaturedEpisodes?.pageSize,
                nextPage = allFeaturedEpisodes?.nextPage == true
            )
        }

    override fun getFeaturedEpisodesFlow(page: Int?, pageSize: Int?): Flow<PodcastEpisodes?> =
        getFeaturedEpisodesFlow(page, pageSize, ENGLISH)

    override suspend fun getEpisode(showId: Int, episodeId: Int): PodcastEpisode? = withIO {
        assemblePodcastEpisode(
            PodcastsApi.getEpisode(showId = showId, episodeId = episodeId)
        )
    }

    override suspend fun getEpisode(episodeId: Int): PodcastEpisode? = withIO {
        assemblePodcastEpisode(
            PodcastsApi.getAllShowsEpisode(episodeId = episodeId)
        )
    }

    private suspend fun assemblePodcastEpisode(episode: Episode?): PodcastEpisode? = withIO {
        episode ?: return@withIO null
        with(episode) {
            id ?: return@withIO null
            showId ?: return@withIO null
            title ?: return@withIO null
            audioUrl ?: return@withIO null
            length ?: return@withIO null

            val show = episode.showId?.let {
                PodcastsApi.getShow(it)
            }

            PodcastEpisode(
                id = id,
                showId = showId,
                title = title,
                publishedDt = publishedDt,
                audioUrl = audioUrl,
                episodeDuration = length,
                episodeArt = episodeArt,
                episodeDescription = description,
                showDescription = show?.description,
                showLink = show?.website,
                partner = show?.partner
            )
        }
    }

    override suspend fun addOrUpdateListeningProgress(
        episodeId: Int,
        timeElapsed: Int,
        totalTime: Int
    ) = PodcastsStore.addOrUpdateListeningProgress(
        episodeId,
        timeElapsed,
        totalTime
    )

    override suspend fun getListeningProgress(episodeId: Int): ListeningProgress? =
        getListeningProgressFlow(episodeId).first()

    override fun getListeningProgressFlow(episodeId: Int): Flow<ListeningProgress?> =
        PodcastsStore.getListeningProgressByEpisodeId(episodeId)

    override suspend fun getContinueListeningEpisodes(): List<PodcastEpisode> =
        getContinueListeningEpisodesFlow().first()

    override fun getContinueListeningEpisodesFlow(): Flow<List<PodcastEpisode>> =
        PodcastsStore.getContinueListeningProgress().transform { value ->
            val episodes = mutableListOf<PodcastEpisode>()
            value.mapNotNullTo(episodes) { progress -> getEpisode(progress.episodeId) }
            emit(episodes.freeze())
        }

    override suspend fun markPodcastAsPlayed(episodeId: Int): Unit = withIO {
        getEpisode(episodeId)?.episodeDuration?.let {
            PodcastsStore.markAsPlayed(episodeId, it)
        }
    }

    override suspend fun getShow(showId: Int): Show? =
        withIO { PodcastsApi.getShow(showId) }

    override suspend fun getShows(page: Int?, pageSize: Int?): Shows =
        withIO {
            PodcastsApi.getShows(
                page = page,
                pageSize = pageSize
            )
        }

    override suspend fun getEpisodesByShowId(
        showId: Int,
        page: Int?,
        pageSize: Int?
    ): PodcastEpisodes = withIO {
        val episodes = PodcastsApi.getEpisodes(page, pageSize, showId)
        PodcastEpisodes(
            data = episodes.data?.mapNotNull {
                assemblePodcastEpisode(it)
            },
            pageSize = episodes.pageSize,
            nextPage = episodes.nextPage
        )
    }

    override suspend fun getEpisodesByPlaylistId(
        playlistId: Int,
        page: Int?,
        pageSize: Int?
    ): PodcastEpisodes = withIO {
        val episodes = PodcastsApi.getAllShowsEpisodes(page, pageSize, playlistId)
        PodcastEpisodes(
            data = episodes.data?.mapNotNull {
                assemblePodcastEpisode(it)
            },
            pageSize = episodes.pageSize,
            nextPage = episodes.nextPage == true
        )
    }

    override suspend fun deleteListeningProgress() = PodcastsStore.deleteListeningProgress()

    companion object {

        private const val ENGLISH = "en"
    }
}
