package youversion.red.podcasts.model

@kotlinx.serialization.Serializable
data class ListeningProgress(
    val episodeId: Int,
    val timeElapsed: Int = 0,
    val totalTime: Int,
    @kotlinx.serialization.Serializable(with = StatusTypeSerializer::class)
    val status: ListeningStatus? = ListeningStatus.NOTSTARTED
)
