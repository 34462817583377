// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Elevation(
  /**
   * Primary key identifier
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The English search query to elevate results for.
   */
  @SerialName("query")
  @ProtoNumber(2)
  @JvmField
  val query: String = "",
  /**
   * List of USFM references to elevate for query
   */
  @SerialName("usfms")
  @ProtoNumber(3)
  @JvmField
  val usfms: List<String>? = null,
  /**
   * List of Plan ids to elevate for query
   */
  @SerialName("plan_ids")
  @ProtoNumber(4)
  @JvmField
  val plan_ids: List<Int>? = null,
  /**
   * List of Video ids to elevate for query
   */
  @SerialName("video_ids")
  @ProtoNumber(5)
  @JvmField
  val video_ids: List<Int>? = null,
  /**
   * ISO 639 date & time resource was last updated
   */
  @SerialName("updated_dt")
  @ProtoNumber(6)
  @JvmField
  val updated_dt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
