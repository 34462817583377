package youversion.red.downloads.service

import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.protobuf.ProtoBuf
import red.platform.currentTimeMillis
import red.platform.http.URL
import red.platform.io.File
import red.platform.io.fileSystem
import red.platform.threads.AtomicLong
import red.platform.threads.SuspendLockOwner
import red.platform.threads.SuspendedLock
import red.platform.threads.currentThread
import red.platform.threads.incr
import red.platform.threads.sync
import red.platform.toLong
import youversion.red.downloads.model.Download
import youversion.red.downloads.model.DownloadHeaders
import youversion.red.downloads.model.DownloadPriority
import youversion.red.downloads.model.DownloadState

internal object DownloadStore {

    private val lock = SuspendedLock()
    private val ids = AtomicLong(currentTimeMillis().toLong())

    suspend fun getDownloads(): List<Download> = getDownloads(currentThread())

    private suspend fun getDownloads(owner: SuspendLockOwner) = lock.sync(owner) {
        val downloads = fileSystem.newFile(fileSystem.internalStorage, "downloads/downloads.db")
        if (!fileSystem.exists(downloads)) {
            emptyList()
        } else {
            fileSystem.open(downloads)?.let {
                ProtoBuf.decodeFromByteArray(ListSerializer(Download.serializer()), it)
            } ?: emptyList()
        }
    }

    private suspend fun setDownloads(downloads: List<Download>, owner: SuspendLockOwner) = lock.sync(owner) {
        val downloadsFile = fileSystem.newFile(fileSystem.internalStorage, "downloads/downloads.db")
        val downloadsDir = fileSystem.getParent(downloadsFile) ?: error("Missing downloads parent")
        if (!fileSystem.exists(downloadsDir)) {
            fileSystem.mkdirs(downloadsDir)
        }
        val data = ProtoBuf.encodeToByteArray(ListSerializer(Download.serializer()), downloads)
        fileSystem.writeFile(downloadsFile, data)
    }

    private suspend fun editDownload(id: Long, owner: SuspendLockOwner, block: (download: Download) -> Download) {
        lock.sync(owner) {
            val downloads = getDownloads(owner)
            val download = downloads.first { it.id == id }
            val newDownloads = downloads.filter { it.id != id } + block(download)
            setDownloads(newDownloads, owner)
        }
    }

    suspend fun getDownload(downloadId: Long) = getDownloads().firstOrNull { it.id == downloadId }

    suspend fun addPendingDownload(
        headers: DownloadHeaders,
        url: URL,
        destination: File,
        priority: DownloadPriority
    ): Download {
        val owner = currentThread()
        return lock.sync(owner) {
            val id = ids.incr()
            val newDownload = Download(id, null, headers, url, destination, DownloadState.Pending, priority)
            val newDownloads = getDownloads(owner) + newDownload
            setDownloads(newDownloads, owner)
            newDownload
        }
    }

    suspend fun setDownloadState(id: Long, state: DownloadState) = editDownload(id, currentThread()) {
        it.copy(state = state)
    }

    suspend fun setDownloadStateAndIdentifier(id: Long, state: DownloadState, identifier: String?) = editDownload(id, currentThread()) {
        it.copy(state = state, identifier = identifier)
    }

    suspend fun deleteDownload(id: Long) {
        val owner = currentThread()
        lock.sync(owner) {
            val downloads = getDownloads(owner).filter { it.id != id }
            setDownloads(downloads, owner)
        }
    }
}
