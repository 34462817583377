// Auto-Generated from OpenAPI Spec
package youversion.red.settings.api.model.settings

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class OnDeepLinkEvent(
  /**
   * The deep link url
   */
  @SerialName("deeplink")
  @ProtoNumber(1)
  val deeplink: String = "",
  /**
   * Referral source of the deeplink. This may be a website url, an application name, or null.
   */
  @SerialName("referrer")
  @ProtoNumber(2)
  val referrer: String? = null
) : Event(), red.Serializable {
  override val key: String = "OnDeepLinkEvent"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putString("deeplink", deeplink)
    referrer?.let { bundle.putString("referrer", referrer) }
    return bundle
  }
}
