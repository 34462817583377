package youversion.red.analytics.dataman

import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleListener
import red.lifecycle.AppLifecycleState
import youversion.red.analytics.DataMan
import youversion.red.analytics.DataManTimer

class DataManListener : AppLifecycleListener {

    override fun onStateChanged(state: AppLifecycleState, source: AppLifecycleEventSource) {
        if (state == AppLifecycleState.Foreground) {
            DataManTimer.register(30) {
                DataMan.flushIfNeeded()
            }
        }
        if (state == AppLifecycleState.Background) {
            DataManTimer.unregister()
        }
    }

    override fun onSessionStarted() {
    }
}
