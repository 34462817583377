package youversion.red.installation.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer

@Serializable
data class Installation(
    @ProtoNumber(1) val id: String,
    @ProtoNumber(2) @Serializable(with = DateSerializer::class) val created: Date,
    @ProtoNumber(3) val uniqueData: String?,
    @ProtoNumber(4) val deviceCheckId: String?,
    @ProtoNumber(5) val clientId: String
)

@Serializable
data class InstallationId(@ProtoNumber(1) val id: String)
