// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.metrics

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("metrics.RequestManagerUrlMetricEvent")
data class RequestManagerUrlMetricEvent(
  /**
   * The executed request url
   */
  @SerialName("url")
  @ProtoNumber(1)
  val url: String? = null,
  /**
   * The request method of the executed request
   */
  @SerialName("request_method")
  @ProtoNumber(2)
  val requestMethod: String? = null,
  /**
   * The average request body size in bytes
   */
  @SerialName("average_request_body_bytes")
  @ProtoNumber(3)
  val averageRequestBodyBytes: Int? = null,
  /**
   * The average response body size in bytes
   */
  @SerialName("average_response_body_bytes")
  @ProtoNumber(4)
  val averageResponseBodyBytes: Int? = null,
  /**
   * The average response time in milliseconds
   */
  @SerialName("average_response_time_millis")
  @ProtoNumber(5)
  val averageResponseTimeMillis: Int? = null,
  /**
   * The number of times the request is made
   */
  @SerialName("count")
  @ProtoNumber(6)
  val count: Int? = null,
  /**
   * The session ID that the metrics are collected
   */
  @SerialName("collected_session_id")
  @ProtoNumber(7)
  val collectedSessionId: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    url: String? = null,
    requestMethod: String? = null,
    averageRequestBodyBytes: Int? = null,
    averageResponseBodyBytes: Int? = null,
    averageResponseTimeMillis: Int? = null,
    count: Int? = null,
    collectedSessionId: String? = null
  ) : this(url, requestMethod, averageRequestBodyBytes, averageResponseBodyBytes,
      averageResponseTimeMillis, count, collectedSessionId, red.platform.now())
}
