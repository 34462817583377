package youversion.red.plans.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.UrlSerializer
import red.platform.threads.freeze

@Serializable
data class PlanImage(
    @Serializable(with = UrlSerializer::class)
    @ProtoNumber(2) val url: String,
    @ProtoNumber(1) val height: Int = 0,
    @ProtoNumber(3) val width: Int = 0
) {
    init {
        freeze()
    }
}

@Serializable
data class PlanImageUrls(
    @ProtoNumber(1)
    @SerialName("px_24x24")
    @Serializable(with = UrlSerializer::class)
    val px24: String? = null,
    @ProtoNumber(2)
    @SerialName("px_48x48")
    @Serializable(with = UrlSerializer::class)
    val px48: String? = null,
    @ProtoNumber(3)
    @SerialName("px_128x128")
    @Serializable(with = UrlSerializer::class)
    val px128: String? = null,
    @ProtoNumber(4)
    @SerialName("px_512x512")
    @Serializable(with = UrlSerializer::class)
    val px512: String? = null
) : red.Serializable {

    init {
        freeze()
    }
}
