package youversion.red.versification.service

import red.platform.PlatformType
import red.platform.WeakReference
import red.platform.platformType
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendedLock
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import red.service.DefaultService
import youversion.red.versification.Versification
import youversion.red.versification.VersificationTransaction

@DefaultService(IVersificationService::class)
internal class VersificationServiceImpl : IVersificationService {

    private val ref = AtomicReference<WeakReference<Versification>?>(null)
    private val lock = SuspendedLock()

    override suspend fun newVersification(): VersificationTransaction {
        if (platformType == PlatformType.JavaScript) {
            return VersificationTransaction(VersificationImpl())
        }
        ref.value?.get()?.let { return VersificationTransaction(it) }
        lock.sync {
            ref.value?.get()?.let { return VersificationTransaction(it) }
            val versification = VersificationImpl()
            ref.setAssertTrue(WeakReference(versification))
            return VersificationTransaction(versification)
        }
    }
}
