package youversion.red.movies.api

import red.platform.http.ContentTypes
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.movies.VideoMetadata

internal object LegacyVideosApi :
    AbstractApi(ApiDefaults("videos", ContentTypes.JSON, ContentTypes.JSON, "3.1")) {

    suspend fun getLegacyVideo(id: Int) = execute(
        "/view.json",
        queryString = "id=$id",
        accept = ContentTypes.JSON,
        serializer = VideoMetadata.serializer(),
        authAllowed = false
    )
}
