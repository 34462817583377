/**
 * @module AudioPlayer
 */
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Box,
  CircularProgress,
  Collapse,
  Fade,
  Hidden,
  IconButton,
  Paper,
  Slider,
  Typography,
  makeStyles,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PauseCircleFilledIcon from '@material-ui/icons/PauseCircleFilled'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import Replay10Icon from '@material-ui/icons/Replay10'
import Forward10Icon from '@material-ui/icons/Forward10'
import { button, yvRed } from 'styles/colors-v3'
import { LazyImage } from 'components/ui/lazy-image'
import { useAudioPlayer } from 'providers'

const useStyles = makeStyles((theme) => ({
  artwork: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  audioInfoSection: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    order: -1,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
      marginLeft: theme.spacing(4),
      marginTop: 0,
      minWidth: '33%',
      order: 0,
    },
  },
  circularProgress: {
    color: yvRed,
    position: 'absolute',
  },
  closeMobilePlayer: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
  formattedTimeSection: {
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    order: -2,
    whiteSpace: 'nowrap',
    width: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'unset',
      marginBottom: 0,
      marginLeft: theme.spacing(2),
      marginTop: 0,
      order: 0,
    },
  },
  playerControls: {
    minWidth: 'fit-content',
  },
  playPauseBtn: {
    '& svg': {
      fill: theme.palette.primary[theme.palette.type],
      fontSize: 48,
    },
    '&:disabled svg': {
      fill: button[theme.palette.type].default,
    },
    padding: theme.spacing(0.5),
  },
  rail: {
    height: 4,
  },
  root: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    // The 4px is to offset from the Slider component, so that everything appears vertically aligned.
    padding: `${theme.spacing(1) + 4}px ${theme.spacing(2)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(2)}px`,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  slider: {
    left: 0,
    paddingTop: 0,
    position: 'absolute',
    top: 0,
  },
  thumb: {
    color: yvRed,
  },
  titleAndTime: {
    // This is the width minus the artwork and minus the margin to create a proper width for this section.
    maxWidth: 'calc(100% - 48px - 16px)',
  },
  track: {
    color: yvRed,
    height: 4,
  },
}))

function getFormattedTimeFromSeconds(seconds) {
  let format = 'mm:ss'
  if (seconds > 3600) {
    format = 'H:mm:ss'
  }

  return moment
    .utc(moment.duration(seconds, 'seconds').asMilliseconds())
    .format(format)
}

function getRemainingTimeLeft(seconds) {
  if (!seconds || typeof seconds !== 'number') {
    return ''
  }

  const timeRemaining = Math.floor(seconds / 60)

  if (timeRemaining < 1) {
    return 'Completed'
  }

  return `${timeRemaining} ${seconds >= 120 ? 'mins' : 'min'} left`
}

// This component was built following this tutorial https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks

/**
 * Audio Player Component.
 *
 * This component requires being a child of our AudioPlayerProvider for audio playing to work properly.
 *
 * @alias module:AudioPlayer
 *
 * @param {object} props - The component's props object.
 * @param {boolean} [props.autoPlay] - Automatically play the audio when it's ready.
 * @param {Function} [props.onCloseAudioPlayer] - Handles closing or dismissing the audio player.
 *
 * @returns {React.ReactElement} - The AudioPlayer Component.
 *
 * @example
 * import { AudioPlayer, AudioPlayerContext, useAudioPlayer } from '@youversion/react'
 *
 * function MyPage() {
 *   return (
 *     <AudioPlayerContext audioSrcUrl="https://example.com/some.mp3" artworkUrl="https://example.com/some.jpg">
 *       <AudioSection />
 *     </AudioPlayerContext>
 *   )
 * }
 *
 * function AudioSection() {
 *   const { handlePlay } = useAudioPlayer()
 *
 *   return (
 *     <>
 *       <button onClick={handlePlay}>Play from this button</button>
 *       <AudioPlayer />
 *     </>
 *   )
 * }
 */
export function AudioPlayer({ autoPlay, onCloseAudioPlayer }) {
  const classes = useStyles()
  const {
    artworkAltText,
    artworkUrl,
    duration,
    hasLoaded,
    handleGoBack,
    handleGoForward,
    handleGoToStart,
    handlePause,
    handlePlay,
    handleScrub,
    isLoading,
    isPlaying,
    title,
    trackProgress,
  } = useAudioPlayer()

  // Plays the audio player whenever it's loaded. Due to browser restrictions on playing audio,
  // the user must first interact with the page before auto play can work.
  React.useEffect(() => {
    if (hasLoaded && autoPlay) {
      handlePlay()
    }
  }, [autoPlay, hasLoaded, handlePlay])

  const formattedTrackProgress = getFormattedTimeFromSeconds(
    Math.ceil(trackProgress),
  )
  const formattedDuration = getFormattedTimeFromSeconds(Math.floor(duration))
  const formattedTime = `${formattedTrackProgress} / ${formattedDuration}`
  const remainingTime = getRemainingTimeLeft(
    Math.floor(duration - trackProgress),
  )

  function handleCloseAudioPlayer() {
    handleGoToStart()
    handlePause()
    onCloseAudioPlayer()
  }

  return (
    <Paper className={classes.root} component="section" elevation={3}>
      <Slider
        aria-labelledby="disabled-slider"
        classes={{
          rail: classes.rail,
          root: classes.slider,
          thumb: classes.thumb,
          track: classes.track,
        }}
        defaultValue={0}
        getAriaValueText={getFormattedTimeFromSeconds}
        max={Math.floor(duration) || 0}
        min={0}
        onChange={handleScrub}
        value={Math.floor(trackProgress) || 0}
      />
      <div className={classes.playerControls}>
        <IconButton
          aria-label="Go back 10 seconds"
          disabled={!hasLoaded}
          onClick={() => handleGoBack(10)}
          size="small"
        >
          <Replay10Icon fontSize="medium" />
        </IconButton>
        <IconButton
          aria-label={`${isPlaying ? 'Pause' : 'Play'} Audio`}
          className={classes.playPauseBtn}
          disabled={!hasLoaded}
          onClick={isPlaying ? handlePause : handlePlay}
        >
          {isPlaying ? (
            <PauseCircleFilledIcon fontSize="large" />
          ) : (
            <PlayCircleFilledIcon fontSize="large" />
          )}
          {isLoading ? (
            <CircularProgress className={classes.circularProgress} />
          ) : null}
        </IconButton>
        <IconButton
          aria-label="Go forward 10 seconds"
          disabled={!hasLoaded}
          onClick={() => handleGoForward(10)}
          size="small"
        >
          <Forward10Icon fontSize="medium" />
        </IconButton>
      </div>
      <Box
        alignItems="center"
        className={classes.audioInfoSection}
        display="flex"
        flexGrow={1}
      >
        {artworkUrl ? (
          <LazyImage
            alt={artworkAltText || `Artwork for ${title}`}
            className={classes.artwork}
            height={48}
            src={artworkUrl}
            width={48}
          />
        ) : null}
        <div className={classes.titleAndTime}>
          <Typography color="textPrimary" noWrap={true} variant="body1">
            {title}
          </Typography>
          <Collapse in={Boolean(hasLoaded && duration)}>
            <Typography color="textSecondary" variant="caption">
              {remainingTime}
            </Typography>
          </Collapse>
        </div>
      </Box>
      <div className={classes.formattedTimeSection}>
        <Fade in={Boolean(hasLoaded && duration)}>
          <Typography color="textSecondary" variant="body1">
            {duration ? formattedTime : null}
          </Typography>
        </Fade>
      </div>
      {onCloseAudioPlayer ? (
        <>
          <Hidden xsDown={true}>
            <Box ml={2}>
              <IconButton
                aria-label="Close audio player"
                onClick={handleCloseAudioPlayer}
                size="small"
              >
                <CloseIcon fontSize="medium" />
              </IconButton>
            </Box>
          </Hidden>
          <Hidden smUp={true}>
            <IconButton
              aria-label="Close audio player"
              className={classes.closeMobilePlayer}
              onClick={handleCloseAudioPlayer}
              size="small"
            >
              <CloseIcon fontSize="medium" />
            </IconButton>
          </Hidden>
        </>
      ) : null}
    </Paper>
  )
}

AudioPlayer.propTypes = {
  /**
   * Automatically play the audio when it's ready.
   */
  autoPlay: PropTypes.bool,
  /**
   * Handles closing or dismissing the audio player.
   */
  onCloseAudioPlayer: PropTypes.func,
}

AudioPlayer.defaultProps = {
  autoPlay: false,
  onCloseAudioPlayer: undefined,
}
