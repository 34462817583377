package youversion.red.analytics.shared

import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

enum class AccountCreateMethod {
    Email,
    Google,
    Facebook
}

data class AccountCreatedEvent(
    val method: AccountCreateMethod,
    val referrer: String
) : Event() {

    override val key = "account_created"

    override fun toBundle() = Bundle().apply {
        putString("method", method.name)
        putString("referrer", referrer)
    }
}
