// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GCSPresignResponse(
  /**
   * id
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Image upload URL
   */
  @SerialName("upload_url")
  @ProtoNumber(2)
  @JvmField
  val uploadUrl: String? = null,
  @SerialName("params")
  @ProtoNumber(3)
  @JvmField
  val params: GCSPresignParams? = null
) : red.Serializable {
  init {
    freeze()
  }
}
