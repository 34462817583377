package youversion.red.achievements.service

import red.service.DefaultService
import youversion.red.achievements.api.AchievementsApi
import youversion.red.achievements.api.model.Achievement
import youversion.red.achievements.api.model.Progress
import youversion.red.achievements.api.model.achievements.ProgressPost
import youversion.red.achievements.model.ActionShareAchievement

@DefaultService(IAchievementsService::class)
class AchievementsServiceImpl : IAchievementsService {
    override suspend fun getAchievements(plansLanguageTag: String?): List<Achievement> =
        AchievementsApi.getAchievements(plansLanguageTag = plansLanguageTag).data ?: emptyList()

    override suspend fun getProgress(userId: Int): List<Progress> =
        AchievementsApi.getProgress(userId = userId)?.data ?: emptyList()

    override suspend fun getAchievement(id: Int, plansLanguageTag: String?) =
        AchievementsApi.getAchievement(id, plansLanguageTag = plansLanguageTag)

    override suspend fun incrementProgress(achievement: ActionShareAchievement) {
        AchievementsApi2.addProgress(ProgressPost(achievement.achievementId))
    }
}
