package youversion.red.podcasts.db

import com.squareup.sqldelight.ColumnAdapter
import com.squareup.sqldelight.db.SqlDriver
import red.database.DateAdapter
import youversion.red.podcasts.model.ListeningStatus

fun createQueryWrapper(driver: SqlDriver): PodcastsDatabase {
    return PodcastsDatabase(
        driver = driver,
        podcasts_progressAdapter = Podcasts_progress.Adapter(
            createdDtAdapter = DateAdapter(),
            updatedDtAdapter = DateAdapter(),
            statusAdapter = EnumAdapter { ListeningStatus.valueOf(it) }
        )
    )
}

object Schema : SqlDriver.Schema by PodcastsDatabase.Schema {
    override fun create(driver: SqlDriver) {
        PodcastsDatabase.Schema.create(driver)
        createQueryWrapper(driver)
    }
}

internal class EnumAdapter<E : Enum<*>>(private val enumFactory: (databaseValue: String) -> E) :
    ColumnAdapter<E, String> {
    override fun decode(databaseValue: String): E {
        return enumFactory(databaseValue)
    }

    override fun encode(value: E): String {
        return value.name
    }
}
