package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentLabel(@ProtoNumber(1) val count: Int = 0, @ProtoNumber(2) val label: String? = null)

@Serializable
data class MomentLabels(@ProtoNumber(1) val labels: List<MomentLabel>? = null)
