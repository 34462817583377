// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchResultCorrectionTap(
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(3)
  val appLanguage: String? = null,
  /**
   * Plans language
   */
  @SerialName("plan_language")
  @ProtoNumber(4)
  val planLanguage: String? = null,
  /**
   * Bible version id
   */
  @SerialName("version_id")
  @ProtoNumber(5)
  val versionId: Int? = null,
  /**
   * The text the user searched for (includes mis-spellings)
   */
  @SerialName("original_query")
  @ProtoNumber(6)
  val originalQuery: String? = null,
  /**
   * The text the server automatically corrected for the user
   */
  @SerialName("corrected_query")
  @ProtoNumber(7)
  val correctedQuery: String? = null,
  /**
   * The text of the "Did You Mean" suggestion the user tapped
   */
  @SerialName("did_you_mean_suggestion_tapped")
  @ProtoNumber(8)
  val didYouMeanSuggestionTapped: String? = null,
  /**
   * 1- Did You Mean, 2-Search Instead For
   */
  @SerialName("spelling_suggestion_type")
  @ProtoNumber(9)
  val spellingSuggestionType: Int? = null,
  /**
   * The filter that was selected when the user tapped the spelling suggestion
   */
  @SerialName("selected_filter")
  @ProtoNumber(10)
  val selectedFilter: String? = null,
  /**
   * The index of the spelling suggestion the user tapped
   */
  @SerialName("tapped_suggestion_index")
  @ProtoNumber(11)
  val tappedSuggestionIndex: Int? = null,
  /**
   * Videos language
   */
  @SerialName("video_language")
  @ProtoNumber(13)
  val videoLanguage: String? = null
) : Event(), red.Serializable {
  override val key: String = "SearchResultCorrectionTap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    originalQuery?.let { bundle.putString("original_query", originalQuery) }
    correctedQuery?.let { bundle.putString("corrected_query", correctedQuery) }
    didYouMeanSuggestionTapped?.let { bundle.putString("did_you_mean_suggestion_tapped",
        didYouMeanSuggestionTapped) }
    spellingSuggestionType?.let { bundle.putInt("spelling_suggestion_type", spellingSuggestionType)
        }
    selectedFilter?.let { bundle.putString("selected_filter", selectedFilter) }
    tappedSuggestionIndex?.let { bundle.putInt("tapped_suggestion_index", tappedSuggestionIndex) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    return bundle
  }
}
