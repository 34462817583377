/**
 * @module getVerseAudioTiming
 */

/**
 * ‘...And since I have kindled you with the Flame Imperishable, ye shall show forth your powers in adorning this theme, each with his own thoughts and devices, if he will...’.
 *
 * @alias module:getVerseAudioTiming
 *
 * @param {*} startRef
 * @param {*} endRef
 * @param {*} timing
 */
export function getVerseAudioTiming(startRef, endRef, timing) {
  const audioTiming = {
    startTime: null,
    endTime: null,
  }

  if (!Array.isArray(timing)) {
    return audioTiming
  }

  for (let i = 0; i < timing.length; i += 1) {
    const ref = timing[i]
    if (
      startRef.toString().toLowerCase() === ref.usfm.toString().toLowerCase()
    ) {
      audioTiming.startTime = ref.start
    }
    if (endRef.toString().toLowerCase() === ref.usfm.toString().toLowerCase()) {
      audioTiming.endTime = ref.end
    }

    if (audioTiming.startTime && audioTiming.endTime) {
      return audioTiming
    }
  }

  return audioTiming
}
