// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.prayer.api.model.PrayerReferrerType

@Serializable
data class CreatePrayer(
  /**
   * Id of the prayer that has been shared.
   */
  @SerialName("prayer_id")
  @ProtoNumber(1)
  val prayerId: Int? = null,
  /**
   * Bible version id for text to be referenced in the prayer.
   */
  @SerialName("version_id")
  @ProtoNumber(2)
  val versionId: Int? = null,
  /**
   * Unified Standard Format Markers
   */
  @SerialName("reference")
  @ProtoNumber(3)
  val reference: String? = null,
  @SerialName("referrer")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.prayer.api.model.PrayerReferrerTypeSerializer::class)
  val referrer: PrayerReferrerType? = null
) : Event(), red.Serializable {
  override val key: String = "CreatePrayer"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    prayerId?.let { bundle.putInt("prayer_id", prayerId) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    reference?.let { bundle.putString("reference", reference) }
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    return bundle
  }
}
