package youversion.red.blue.state

import red.Red
import red.RedAppType
import red.platform.Log
import red.platform.http.RequestManager
import red.platform.http.newURL
import red.platform.localization.LocaleContext
import red.platform.localization.Locales
import red.platform.localization.resolve
import red.platform.settings.Settings
import red.platform.threads.SuspendedLock
import red.platform.threads.sync
import red.platform.urlEncode
import youversion.red.api.AbstractApi
import youversion.red.blue.api.BlueApi
import youversion.red.blue.api.model.enrollment.DeviceEnrollment
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.geoip.service.GeoIPService
import youversion.red.installation.InstallationService
import youversion.red.security.service.UsersService

internal open class EnrollmentManager {

    companion object {
        private const val featuresKey = "red.blue.enrollment.features"
        private const val usersKey = "red.blue.enrollment.user"
        private const val languageKey = "red.blue.enrollment.language"
    }

    private val installationService by InstallationService()
    private val usersService by UsersService()
    private val geoIPService by GeoIPService()

    internal val lock = SuspendedLock()

    private val enrolledFeatures: Set<String>
        get() = Settings.defaultSettings.getString(featuresKey)?.split(",")?.toSet() ?: emptySet()
    private val enrolledUser: Int
        get() = Settings.defaultSettings.getInt(usersKey, 0)
    private val enrolledLanguageTag: String?
        get() = Settings.defaultSettings.getString(languageKey)

    private fun setEnrolledUser(userId: Int, languageTag: String) {
        Settings.defaultSettings
            .edit()
            .putString(featuresKey, availableEnrollmentFeatures.joinToString(","))
            .putString(languageKey, languageTag)
            .putInt(usersKey, userId)
            .commit()
    }

    fun isEnrolled(featureId: String) = enrolledFeatures.contains(featureId)

    protected open suspend fun enroll(installationId: String, enrollment: DeviceEnrollment) =
        BlueApi.enroll(installationId.urlEncode(), enrollment)

    suspend fun clearEnrollment(installationId: String?) {
        installationId?.let {
            RequestManager.clearCache(
                newURL("https://blue.${AbstractApi.urlResolver.resolveSuffix("blue")}/4.1/enroll/$installationId"),
                false
            )
            RequestManager.clearCache(
                newURL("https://blue.${AbstractApi.urlResolver.resolveSuffix("blue")}/4.1/state/$installationId"),
                false
            )
        }
        Settings.defaultSettings.edit()
            .remove(featuresKey)
            .commit()
        Settings.defaultSettings.edit()
            .remove(usersKey)
            .commit()
    }

    suspend fun enroll(): List<FeatureState> = try {
        lock.sync {
            val currentUser = usersService.getCurrentUser()
            val userId = currentUser?.id ?: 0
            val languageTag = (Locales.resolve(LocaleContext.DEFAULT).firstOrNull() ?: Locales.getDefault()).getApiTag2()
            Log.w("Blue", "Enrolling with language tag: $languageTag")
            if (!enrolledFeatures.containsAll(availableEnrollmentFeatures) || enrolledUser != userId || enrolledLanguageTag != languageTag) {
                var installationId = installationService.installationId
                if (installationId == null) {
                    installationService.install() // try and force installation service
                    installationId = installationService.installationId
                }
                val state = enroll(
                    installationId ?: error("Failed to get installation id"),
                    createEnrollment(languageTag, currentUser?.euid)
                )
                setEnrolledUser(userId, languageTag)
                return@sync state.features ?: emptyList()
            } else {
                Log.w("Blue", "Already enrolled, not doing it a second time")
                emptyList()
            }
        }
    } catch (e: Exception) {
        Log.e("BlueService", "Failed to enroll", e)
        emptyList()
    }

    val availableEnrollmentFeatures: List<String> = if (Red.applicationType == RedAppType.Bible) {
        listOf(OnBoardingState.featureId, GuidedPrayerState.featureId)
    } else {
        emptyList()
    }

    private fun createEnrollment(languageTag: String, euid: String?) = DeviceEnrollment(
        Locales.getCountry(Locales.getDefault()).takeIf { it.isNotEmpty() } ?: "zz",
        languageTag.replace('-', '_'),
        euid,
        availableEnrollmentFeatures
    )
}
