package youversion.red.images.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.threads.freeze

@Serializable
data class UrlSize(val width: Int, val height: Int)

@Serializable
data class Urls(
    @ProtoNumber(1) val regular: String? = null,
    @ProtoNumber(2) val blurred: String? = null,
    @ProtoNumber(100) val sizes: List<UrlSize>? = null
) {
    init {
        freeze()
    }
}

@Serializable
data class ImageMetadata(
    @ProtoNumber(1) val id: Int? = 0,
    @ProtoNumber(2) val category: String? = null,
    @ProtoNumber(3) val usfm: List<String>? = emptyList(),
    @SerialName("version_id") @ProtoNumber(4) val versionId: Int? = 0,
    @SerialName("language_tag") @ProtoNumber(5) val languageTag: String? = null,
    @ProtoNumber(6) val width: Int? = 0,
    @ProtoNumber(7) val height: Int? = 0,
    @SerialName("action_url") @ProtoNumber(8) val actionUrl: String? = null,
    @ProtoNumber(9) val editable: Boolean = false,
    @ProtoNumber(10) val attributions: String? = null,
    @ProtoNumber(11) val urls: Urls? = null,
    @SerialName("updated_dt") @Serializable(with = DateSerializer::class) @ProtoNumber(12) val updatedDate: Date? = null,
    @SerialName("created_dt") @Serializable(with = DateSerializer::class) @ProtoNumber(13) val createdDt: Date? = null
) : red.Serializable {
    init {
        freeze()
    }

    fun getRegularImageUrl(width: Int, height: Int) = urls
        ?.regular
        ?.replace("{w}", width.toString())
        ?.replace("{h}", height.toString())

    fun getBlurredImageUrl(width: Int, height: Int) = urls
        ?.blurred
        ?.replace("{w}", width.toString())
        ?.replace("{h}", height.toString())
}

@Serializable
data class Images(
    @ProtoNumber(1) val images: List<ImageMetadata>? = emptyList(),
    @ProtoNumber(2) @SerialName("next_page") val nextPage: Int? = 0
) {
    init {
        freeze()
    }
}
