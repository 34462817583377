package red.platform.threads

interface ILock {

    fun lock()
    fun unlock()
}

expect class Lock() : ILock {

    override fun lock()
    override fun unlock()
}

inline fun <T> ILock.sync(block: () -> T): T {
    return try {
        lock()
        block()
    } finally {
        unlock()
    }
}
