package red.service

import kotlin.properties.ReadOnlyProperty
import kotlin.reflect.KClass
import kotlin.reflect.KProperty

interface ServiceProperty<T : IService> : ReadOnlyProperty<Any?, T>

private class ServicePropertyImpl<T : IService>(
    private val serviceClass: KClass<T>
) : ServiceProperty<T> {

    override fun getValue(thisRef: Any?, property: KProperty<*>): T = ServiceRegistry[serviceClass]

    override fun toString(): String = serviceClass.toString()
}

interface ServicePropertyProvider<T : IService> {

    operator fun provideDelegate(
        thisRef: Any?,
        prop: KProperty<*>
    ): ServiceProperty<T>
}

class ServicePropertyProviderImpl<T : IService>(
    private val serviceClass: KClass<T>
) : ServicePropertyProvider<T> {

    override fun provideDelegate(
        thisRef: Any?,
        prop: KProperty<*>
    ): ServiceProperty<T> = ServicePropertyImpl(serviceClass)
}

inline fun <reified T : IService> service(): ServicePropertyProvider<T> =
    ServicePropertyProviderImpl(T::class)
