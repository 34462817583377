package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class MomentImages(
    @ProtoNumber(2) val url: String? = null,
    @ProtoNumber(1) var height: Int = 0,
    @ProtoNumber(3) var width: Int = 0
) {

    init {
        freeze()
    }
}
