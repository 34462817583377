package youversion.red.banner.api

import red.RedPlatform
import red.platform.http.URL
import red.platform.http.getProxyUrl
import red.platform.http.newURL

actual fun newBannerUrl(url: String): URL = if (RedPlatform.isNode) {
    newURL(url)
} else {
    newURL(getProxyUrl(url))
}
