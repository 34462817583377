package youversion.red.moments.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.now
import red.platform.threads.freeze

@Serializable
data class MomentCreate(
    @ProtoNumber(2) val kind: MomentKind = MomentKind.UNKNOWN,
    @ProtoNumber(3) val references: List<MomentReferences>? = null,
    @ProtoNumber(4) val color: String? = null,
    @ProtoNumber(5) val title: String? = null,
    @ProtoNumber(6) val content: String? = null,
    @ProtoNumber(7) val labels: List<String>? = null,
    @ProtoNumber(8) val userStatus: MomentUserStatus? = null,
    @ProtoNumber(9) @Serializable(with = DateSerializer::class) @SerialName("created_dt") val created: Date = now(),
    @ProtoNumber(10) val imageId: String? = null,
    @ProtoNumber(11) val width: Int? = null,
    @ProtoNumber(12) val height: Int? = null,
    @ProtoNumber(13) val source: MomentImageSource? = null
) {

    init {
        freeze()
    }
}
