package youversion.red.podcasts.db.podcasts

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.Collection
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import red.platform.Date
import youversion.red.podcasts.db.PodcastsDatabase
import youversion.red.podcasts.db.PodcastsQueries
import youversion.red.podcasts.db.Podcasts_progress
import youversion.red.podcasts.model.ListeningStatus

internal val KClass<PodcastsDatabase>.schema: SqlDriver.Schema
  get() = PodcastsDatabaseImpl.Schema

internal fun KClass<PodcastsDatabase>.newInstance(driver: SqlDriver,
    podcasts_progressAdapter: Podcasts_progress.Adapter): PodcastsDatabase =
    PodcastsDatabaseImpl(driver, podcasts_progressAdapter)

private class PodcastsDatabaseImpl(
  driver: SqlDriver,
  internal val podcasts_progressAdapter: Podcasts_progress.Adapter
) : TransacterImpl(driver), PodcastsDatabase {
  public override val podcastsQueries: PodcastsQueriesImpl = PodcastsQueriesImpl(this, driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 2

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE podcasts_progress (
          |    episodeId INTEGER NOT NULL PRIMARY KEY,
          |    timeElapsed INTEGER NOT NULL,
          |    totalTime INTEGER NOT NULL,
          |    status TEXT NOT NULL,
          |    createdDt INTEGER NOT NULL,
          |    updatedDt INTEGER NOT NULL
          |)
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
      if (oldVersion <= 1 && newVersion > 1) {
        driver.execute(null, "DROP TABLE IF EXISTS podcasts_progress", 0)
        driver.execute(null, """
            |CREATE TABLE podcasts_progress (
            |    episodeId INTEGER NOT NULL PRIMARY KEY,
            |    timeElapsed INTEGER NOT NULL,
            |    totalTime INTEGER NOT NULL,
            |    status TEXT NOT NULL,
            |    createdDt INTEGER NOT NULL,
            |    updatedDt INTEGER NOT NULL
            |)
            """.trimMargin(), 0)
      }
    }
  }
}

private class PodcastsQueriesImpl(
  private val database: PodcastsDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), PodcastsQueries {
  internal val getListeningProgressByEpisodeId: MutableList<Query<*>> = copyOnWriteList()

  internal val getListeningProgressByStatus: MutableList<Query<*>> = copyOnWriteList()

  internal val getAllListeningProgress: MutableList<Query<*>> = copyOnWriteList()

  internal val getListeningProgressByStatusList: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getListeningProgressByEpisodeId(episodeId: Int, mapper: (
    episodeId: Int,
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    createdDt: Date,
    updatedDt: Date
  ) -> T): Query<T> = GetListeningProgressByEpisodeIdQuery(episodeId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      database.podcasts_progressAdapter.statusAdapter.decode(cursor.getString(3)!!),
      database.podcasts_progressAdapter.createdDtAdapter.decode(cursor.getLong(4)!!),
      database.podcasts_progressAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getListeningProgressByEpisodeId(episodeId: Int): Query<Podcasts_progress> =
      getListeningProgressByEpisodeId(episodeId) { episodeId_, timeElapsed, totalTime, status,
      createdDt, updatedDt ->
    Podcasts_progress(
      episodeId_,
      timeElapsed,
      totalTime,
      status,
      createdDt,
      updatedDt
    )
  }

  public override fun <T : Any> getListeningProgressByStatus(status: ListeningStatus, mapper: (
    episodeId: Int,
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    createdDt: Date,
    updatedDt: Date
  ) -> T): Query<T> = GetListeningProgressByStatusQuery(status) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      database.podcasts_progressAdapter.statusAdapter.decode(cursor.getString(3)!!),
      database.podcasts_progressAdapter.createdDtAdapter.decode(cursor.getLong(4)!!),
      database.podcasts_progressAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getListeningProgressByStatus(status: ListeningStatus):
      Query<Podcasts_progress> = getListeningProgressByStatus(status) { episodeId, timeElapsed,
      totalTime, status_, createdDt, updatedDt ->
    Podcasts_progress(
      episodeId,
      timeElapsed,
      totalTime,
      status_,
      createdDt,
      updatedDt
    )
  }

  public override fun <T : Any> getAllListeningProgress(mapper: (
    episodeId: Int,
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    createdDt: Date,
    updatedDt: Date
  ) -> T): Query<T> = Query(260720113, getAllListeningProgress, driver, "Podcasts.sq",
      "getAllListeningProgress", "SELECT * FROM podcasts_progress ORDER BY updatedDt DESC") {
      cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      database.podcasts_progressAdapter.statusAdapter.decode(cursor.getString(3)!!),
      database.podcasts_progressAdapter.createdDtAdapter.decode(cursor.getLong(4)!!),
      database.podcasts_progressAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getAllListeningProgress(): Query<Podcasts_progress> =
      getAllListeningProgress { episodeId, timeElapsed, totalTime, status, createdDt, updatedDt ->
    Podcasts_progress(
      episodeId,
      timeElapsed,
      totalTime,
      status,
      createdDt,
      updatedDt
    )
  }

  public override fun <T : Any>
      getListeningProgressByStatusList(status: Collection<ListeningStatus>, mapper: (
    episodeId: Int,
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    createdDt: Date,
    updatedDt: Date
  ) -> T): Query<T> = GetListeningProgressByStatusListQuery(status) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      database.podcasts_progressAdapter.statusAdapter.decode(cursor.getString(3)!!),
      database.podcasts_progressAdapter.createdDtAdapter.decode(cursor.getLong(4)!!),
      database.podcasts_progressAdapter.updatedDtAdapter.decode(cursor.getLong(5)!!)
    )
  }

  public override fun getListeningProgressByStatusList(status: Collection<ListeningStatus>):
      Query<Podcasts_progress> = getListeningProgressByStatusList(status) { episodeId, timeElapsed,
      totalTime, status_, createdDt, updatedDt ->
    Podcasts_progress(
      episodeId,
      timeElapsed,
      totalTime,
      status_,
      createdDt,
      updatedDt
    )
  }

  public override fun addListeningProgress(
    episodeId: Int?,
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    createdDt: Date,
    updatedDt: Date
  ): Unit {
    driver.execute(1304002771, """
    |INSERT INTO podcasts_progress (
    |    episodeId,
    |    timeElapsed,
    |    totalTime,
    |    status,
    |    createdDt,
    |    updatedDt
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 6) {
      bindLong(1, episodeId?.let { it.toLong() })
      bindLong(2, timeElapsed.toLong())
      bindLong(3, totalTime.toLong())
      bindString(4, database.podcasts_progressAdapter.statusAdapter.encode(status))
      bindLong(5, database.podcasts_progressAdapter.createdDtAdapter.encode(createdDt))
      bindLong(6, database.podcasts_progressAdapter.updatedDtAdapter.encode(updatedDt))
    }
    notifyQueries(1304002771, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  public override fun updateListeningProgress(
    timeElapsed: Int,
    totalTime: Int,
    status: ListeningStatus,
    updatedDt: Date,
    episodeId: Int
  ): Unit {
    driver.execute(503381843, """
    |UPDATE podcasts_progress SET
    |    timeElapsed = ?,
    |    totalTime = ?,
    |    status = ?,
    |    updatedDt = ?
    |WHERE episodeId = ?
    """.trimMargin(), 5) {
      bindLong(1, timeElapsed.toLong())
      bindLong(2, totalTime.toLong())
      bindString(3, database.podcasts_progressAdapter.statusAdapter.encode(status))
      bindLong(4, database.podcasts_progressAdapter.updatedDtAdapter.encode(updatedDt))
      bindLong(5, episodeId.toLong())
    }
    notifyQueries(503381843, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  public override fun updateListeningStatus(
    status: ListeningStatus,
    updatedDt: Date,
    episodeId: Int
  ): Unit {
    driver.execute(-2137842024, """
    |UPDATE podcasts_progress SET
    |    status = ?,
    |    updatedDt = ?
    |WHERE episodeId = ?
    """.trimMargin(), 3) {
      bindString(1, database.podcasts_progressAdapter.statusAdapter.encode(status))
      bindLong(2, database.podcasts_progressAdapter.updatedDtAdapter.encode(updatedDt))
      bindLong(3, episodeId.toLong())
    }
    notifyQueries(-2137842024, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  public override fun deleteListeningProgressByEpisodeId(episodeId: Int): Unit {
    driver.execute(1907304046, """DELETE FROM podcasts_progress WHERE episodeId = ?""", 1) {
      bindLong(1, episodeId.toLong())
    }
    notifyQueries(1907304046, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  public override fun deleteListeningProgressByStatus(status: ListeningStatus): Unit {
    driver.execute(1521934586, """DELETE FROM podcasts_progress WHERE status = ?""", 1) {
      bindString(1, database.podcasts_progressAdapter.statusAdapter.encode(status))
    }
    notifyQueries(1521934586, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  public override fun deleteListeningProgress(): Unit {
    driver.execute(1816515249, """DELETE FROM podcasts_progress""", 0)
    notifyQueries(1816515249, {database.podcastsQueries.getListeningProgressByStatusList +
        database.podcastsQueries.getAllListeningProgress +
        database.podcastsQueries.getListeningProgressByEpisodeId +
        database.podcastsQueries.getListeningProgressByStatus})
  }

  private inner class GetListeningProgressByEpisodeIdQuery<out T : Any>(
    public val episodeId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getListeningProgressByEpisodeId, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(293229633,
        """SELECT * FROM podcasts_progress WHERE episodeId = ?""", 1) {
      bindLong(1, episodeId.toLong())
    }

    public override fun toString(): String = "Podcasts.sq:getListeningProgressByEpisodeId"
  }

  private inner class GetListeningProgressByStatusQuery<out T : Any>(
    public val status: ListeningStatus,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getListeningProgressByStatus, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1233107975,
        """SELECT * FROM podcasts_progress WHERE status= ? ORDER BY updatedDt DESC""", 1) {
      bindString(1, database.podcasts_progressAdapter.statusAdapter.encode(status))
    }

    public override fun toString(): String = "Podcasts.sq:getListeningProgressByStatus"
  }

  private inner class GetListeningProgressByStatusListQuery<out T : Any>(
    public val status: Collection<ListeningStatus>,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getListeningProgressByStatusList, mapper) {
    public override fun execute(): SqlCursor {
      val statusIndexes = createArguments(count = status.size)
      return driver.executeQuery(null,
          """SELECT * FROM podcasts_progress WHERE status IN $statusIndexes ORDER BY updatedDt DESC""",
          status.size) {
        status.forEachIndexed { index, status_ ->
            bindString(index + 1, database.podcasts_progressAdapter.statusAdapter.encode(status_))
            }
      }
    }

    public override fun toString(): String = "Podcasts.sq:getListeningProgressByStatusList"
  }
}
