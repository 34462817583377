/**
 * @module parseVerseFromContent
 */

import ServerXPath from 'xpath'
import { DOMParser as ServerDOMParser } from 'xmldom'

const isServerRendering = typeof window === 'undefined'

/**
 * Then the voices of the Ainur, like unto harps and lutes, and pipes and trumpets, and viols and organs, and like unto countless choirs singing with words, began to fashion the theme of Ilúvatar to a great music; and a sound arose of endless interchanging melodies woven in harmony that passed beyond hearing into the depths and into the heights, and the places of the dwelling of Ilúvatar were filled to overflowing, and the music and the echo of the music went out into the Void, and it was not void.
 *
 * @alias module:parseVerseFromContent
 *
 * @param {*} param0
 */
export function parseVerseFromContent({ usfms, fullContent }) {
  /* global siAgent */
  let siSpan
  if (isServerRendering && typeof siAgent !== 'undefined') {
    siSpan = siAgent.profile('parseVerseFromContent')
  }

  let returnValue

  if (usfms && fullContent) {
    const textOutput = []
    const htmlOutput = []

    let doc
    let xpath
    if (isServerRendering) {
      // parsing on the server
      xpath = ServerXPath
      doc = new ServerDOMParser({
        locator: {},
        errorHandler: {
          warning() {},
          error() {},
          fatalError() {},
        },
      }).parseFromString(fullContent)
    } else {
      doc = new DOMParser().parseFromString(fullContent, 'text/html')
    }

    let usfmList = []
    // convert string or array to appropriate split array to single out every verse
    // i.e. [rev.20.1, rev.20.4+rev.20.5+rev.20.6] -> [rev.20.1, rev.20.4, rev.20.5, rev.20.6]
    // or rev.20.4+rev.20.5+rev.20.6 -> [rev.20.4, rev.20.5, rev.20.6]
    if (Array.isArray(usfms)) {
      let split = []
      usfms.forEach((usfm) => {
        split = split.concat(usfm.split('+'))
      })
      usfmList = split
    } else {
      usfmList = usfms.split('+')
    }

    usfmList.forEach((usfm) => {
      const htmlXpath = `//div/div/div/span[contains(concat('+',@data-usfm,'+'),'+${usfm}+')]`
      const textXpath = `${htmlXpath}/node()[not(contains(concat(' ',@class,' '),' note '))][not(contains(concat(' ',@class,' '),' label '))]`

      let html
      let text
      if (isServerRendering) {
        html = xpath.evaluate(
          htmlXpath,
          doc,
          null,
          xpath.XPathResult.ANY_TYPE,
          null,
        )
        text = xpath.evaluate(
          textXpath,
          doc,
          null,
          xpath.XPathResult.ANY_TYPE,
          null,
        )
      } else {
        html = doc.evaluate(htmlXpath, doc, null, XPathResult.ANY_TYPE, null)
        text = doc.evaluate(textXpath, doc, null, XPathResult.ANY_TYPE, null)
      }

      // text
      let nextText = text.iterateNext()
      while (nextText) {
        textOutput.push(nextText.textContent)
        nextText = text.iterateNext()
      }

      // html
      let nextHtml = html.iterateNext()
      while (nextHtml) {
        const htmlContent = isServerRendering
          ? nextHtml.toString()
          : nextHtml.outerHTML
        htmlOutput.push(htmlContent)
        nextHtml = html.iterateNext()
      }
    })

    returnValue = {
      text: textOutput.join(' ').replace(/\s\s+/g, ' '),
      html: htmlOutput.join(' '),
    }
  } else {
    returnValue = {
      text: '',
      html: '',
    }
  }

  if (isServerRendering && typeof siSpan !== 'undefined') {
    siSpan.stop()
  }

  return returnValue
}
