package youversion.red.podcasts.service

import kotlinx.coroutines.flow.Flow
import red.database.asFlowList
import red.database.asFlowOneOrNull
import red.platform.Date
import red.platform.now
import red.tasks.CoroutineDispatchers.withDatabase
import youversion.red.podcasts.db.PodcastsDb
import youversion.red.podcasts.db.queries
import youversion.red.podcasts.model.ListeningProgress
import youversion.red.podcasts.model.ListeningStatus

internal object PodcastsStore {

    private val progressMapper = { episodeId: Int,
                                   timeElapsed: Int,
                                   totalTime: Int,
                                   status: ListeningStatus?,
                                   _: Date?,
                                   _: Date?
        ->
        ListeningProgress(
            episodeId,
            timeElapsed,
            totalTime,
            status
        )
    }

    private fun computeStatus(
        timeElapsed: Int,
        totalTime: Int
    ): ListeningStatus =
        if (((timeElapsed * 1f) / totalTime) >= .98f)
            ListeningStatus.COMPLETED
        else
            ListeningStatus.INPROGRESS

    suspend fun addOrUpdateListeningProgress(
        episodeId: Int,
        timeElapsed: Int,
        totalTime: Int
    ) = withDatabase {
        val oldProgress =
            PodcastsDb.queries.getListeningProgressByEpisodeId(episodeId).executeAsOneOrNull()
        val newStatus = computeStatus(timeElapsed, totalTime)

        if (oldProgress != null) {
            PodcastsDb.queries.updateListeningProgress(
                episodeId = episodeId,
                timeElapsed = if (newStatus == ListeningStatus.COMPLETED) 0 else timeElapsed,
                totalTime = totalTime,
                status = newStatus,
                updatedDt = now()
            )
        } else {
            PodcastsDb.queries.addListeningProgress(
                episodeId = episodeId,
                timeElapsed = if (newStatus == ListeningStatus.COMPLETED) 0 else timeElapsed,
                totalTime = totalTime,
                status = newStatus,
                createdDt = now(),
                updatedDt = now()
            )
        }
    }

    suspend fun markAsPlayed(
        episodeId: Int,
        totalTime: Int
    ): Unit = withDatabase {
        val oldProgress =
            PodcastsDb.queries.getListeningProgressByEpisodeId(episodeId).executeAsOneOrNull()
        val nowDt = now()

        if (oldProgress != null)
            PodcastsDb.queries.updateListeningProgress(
                episodeId = episodeId,
                timeElapsed = 0,
                totalTime = totalTime,
                status = ListeningStatus.MARKASPLAYED,
                updatedDt = nowDt
            )
        else
            PodcastsDb.queries.addListeningProgress(
                episodeId = episodeId,
                timeElapsed = 0,
                totalTime = totalTime,
                status = ListeningStatus.MARKASPLAYED,
                createdDt = nowDt,
                updatedDt = nowDt
            )
    }

    suspend fun deleteListeningProgress() = withDatabase {
        PodcastsDb.queries.deleteListeningProgress()
    }

    suspend fun deleteListeningProgressByStatus(status: ListeningStatus?) = withDatabase {
        status?.let {
            PodcastsDb.queries.deleteListeningProgressByStatus(status)
        }
    }

    suspend fun deleteListeningProgressByEpisodeId(episodeId: Int?) = withDatabase {
        episodeId?.let {
            PodcastsDb.queries.deleteListeningProgressByEpisodeId(episodeId)
        }
    }

    suspend fun getListeningProgressByStatus(status: ListeningStatus): List<ListeningProgress> =
        withDatabase {
            val progress = mutableListOf<ListeningProgress>()
            val storeProgress =
                PodcastsDb.queries.getListeningProgressByStatus(status).executeAsList()
            storeProgress.forEach {
                progress.add(
                    ListeningProgress(
                        episodeId = it.episodeId,
                        timeElapsed = it.timeElapsed,
                        totalTime = it.totalTime,
                        status = it.status
                    )
                )
            }

            progress
        }

    fun getListeningProgressByEpisodeId(episodeId: Int): Flow<ListeningProgress?> =
        PodcastsDb.queries.getListeningProgressByEpisodeId(episodeId, progressMapper)
            .asFlowOneOrNull()

    fun getAllListeningProgress(): Flow<List<ListeningProgress>> =
        PodcastsDb.queries.getAllListeningProgress(progressMapper).asFlowList()

    suspend fun getFinishedListeningProgress(): List<ListeningProgress> = withDatabase {
        PodcastsDb.queries.getListeningProgressByStatusList(
            status = listOf(ListeningStatus.COMPLETED, ListeningStatus.MARKASPLAYED),
            mapper = progressMapper
        ).executeAsList()
    }

    fun getContinueListeningProgress(): Flow<List<ListeningProgress>> =
        PodcastsDb.queries.getListeningProgressByStatusList(
            status = listOf(ListeningStatus.NOTSTARTED, ListeningStatus.INPROGRESS),
            mapper = progressMapper
        ).asFlowList()
}
