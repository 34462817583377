package youversion.red.churches.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.organizations.deeplink.OrganizationsDeepLinkHandler

@ModuleDependencies("core")
class OrganizationsModuleInitializer : IModuleInitializer {
    override fun initialize() {
        DeepLinks.addHandlers(OrganizationsDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
