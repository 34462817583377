package youversion.red.versification.service

import kotlin.js.JsName
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.versification.VersificationTransaction

@JsName("VersificationServiceProvider")
fun VersificationService() = service<IVersificationService>()

@ExportedService
interface IVersificationService : IService {

    suspend fun newVersification(): VersificationTransaction
}
