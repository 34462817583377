package youversion.red.prayer.guided.tasks

import red.platform.Log
import red.platform.LogLevel
import youversion.red.guidedprayer.api.GuidedPrayerApi
import youversion.red.prayer.guided.ext.toGuide
import youversion.red.prayer.guided.service.GuidedPrayerStore

object GuideSync {

    suspend fun process(guideId: Int) {
        if (Log.level == LogLevel.DEBUG)
            Log.i("red-guided-prayer", "running Guide Sync")
        val apiGuide = GuidedPrayerApi.getGuide(guideId)
        apiGuide?.toGuide()?.let {
            GuidedPrayerStore.addGuide(it)
        }
    }
}
