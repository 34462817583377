package youversion.red.analytics.shared

import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

data class VersionSetEvent(
    val versionId: Int,
    val previousVersionId: Int
) : Event() {

    override val key = "version_set"

    override fun toBundle() = Bundle().apply {
        putInt("version_id", versionId)
        putInt("previous_version_id", previousVersionId)
    }
}
