package youversion.red.moments.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.now
import red.platform.threads.AtomicReference
import red.platform.threads.set
import youversion.red.security.User

@Serializable
data class MomentLike(
    @SerialName("created_dt")
    @ProtoNumber(1) @Serializable(with = DateSerializer::class) val created: Date = now(),
    @SerialName("user_id")
    @ProtoNumber(2) val userId: Int = 0,
    @Transient val user: User? = null
)

@Serializable
data class MomentLiking(
    @SerialName("all_users")
    @ProtoNumber(1) val allUsers: List<Int>? = emptyList(),
    @ProtoNumber(2) val enabled: Boolean = false,
    @ProtoNumber(3) val likes: List<MomentLike>? = emptyList(),
    @ProtoNumber(4) val total: Int = 0,
    @ProtoNumber(5) private val myId: Int = 0
) {

    @Transient
    private val _likedByMe = AtomicReference(allUsers?.contains(myId) ?: false)

    // transient property for immediate ui feedback
    var likedByMe: Boolean
        get() = _likedByMe.value
        set(value) {
            _likedByMe.set(value)
        }
}
