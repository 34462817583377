package youversion.red.installation

import red.platform.http.HeaderSource
import red.platform.http.RequestBuilder

class InstallationHeader : HeaderSource {

    private val service by InstallationService()

    override suspend fun addHeaders(request: RequestBuilder, additionalContext: Any?) {
        service.installationId?.let { request.header("X-YV-INST-ID", it) }
    }
}
