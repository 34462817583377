package youversion.red.podcasts.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date
import youversion.red.podcasts.model.ListeningStatus

public data class Podcasts_progress(
  public val episodeId: Int,
  public val timeElapsed: Int,
  public val totalTime: Int,
  public val status: ListeningStatus,
  public val createdDt: Date,
  public val updatedDt: Date
) {
  public override fun toString(): String = """
  |Podcasts_progress [
  |  episodeId: $episodeId
  |  timeElapsed: $timeElapsed
  |  totalTime: $totalTime
  |  status: $status
  |  createdDt: $createdDt
  |  updatedDt: $updatedDt
  |]
  """.trimMargin()

  public class Adapter(
    public val statusAdapter: ColumnAdapter<ListeningStatus, String>,
    public val createdDtAdapter: ColumnAdapter<Date, Long>,
    public val updatedDtAdapter: ColumnAdapter<Date, Long>
  )
}
