// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.publishers

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PublisherDeleteRequest(
  @SerialName("language")
  @ProtoNumber(7)
  @JvmField
  val language: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
