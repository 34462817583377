/**
 * @module AsyncForm
 * @example
 * import { AsyncForm, FormLoadError, FormLoading, FormError, FormContent, FormActions } from '@youversion/react'
 *
 * export function DemoForm() {
 *   return (
 *     <AsyncForm
 *       status={status}
 *       error={error}
 *       onSave={handleSave}
 *       onDelete={handleDelete}
 *     >
 *       <FormLoadError>
 *         Form load error ...
 *       </FormLoadError>
 *
 *       <FormLoading>
 *         Loading...
 *       </FormLoading>
 *
 *       <FormError>
 *         Form error...
 *       </FormError>
 *
 *       <FormContent>
 *         Form content ...
 *       </FormContent>
 *
 *       <FormActions>
 *         Form actions ...
 *       </FormActions>
 *     </AsyncForm>
 *   )
 * }
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { useModel } from 'hooks/use-model'

const { statusTypes } = useModel

/**
 * @typedef {object} AsyncFormContext
 * @property {string} status The form loading status.
 * @property {Error} error
 * @property {Function} handleDelete
 * @property {Function} handleSave
 */
const AsyncFormContext = React.createContext({
  status: statusTypes.idle,
  error: null,
  handleDelete: null,
  handleSave: null,
})
AsyncFormContext.displayName = 'AsyncFormContext'

/**
 * React Hook for accessing AsyncFormContext.
 *
 * @returns {AsyncFormContext}
 */
function useAsyncForm() {
  const context = React.useContext(AsyncFormContext)
  if (context === undefined) {
    throw new Error('useAsyncForm must be used within an <AsyncForm />')
  }
  return context
}

/**
 * Wrapper for conditionally displaying children when AsyncForm encounters an error while loading.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormLoadError({ children }) {
  const { status } = useAsyncForm()
  return status === statusTypes.loadError ? children : null
}

/**
 * Wrapper for conditionally displaying children
 * when AsyncForm encounters an error saving or deleting.
 *
 * - Adds 'error' prop to all children.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormError({ children }) {
  const { status, error } = useAsyncForm()
  return status === statusTypes.error
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { error }),
      )
    : null
}

/**
 * Wrapper for conditionally displaying children when AsyncForm is loading a model.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormLoading({ children }) {
  const { status } = useAsyncForm()
  return [
    statusTypes.updating,
    statusTypes.deleting,
    statusTypes.loading,
  ].indexOf(status) > -1
    ? children
    : null
}

/**
 * Wrapper for conditionally displaying children when AsyncForm is ready for user interaction.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormContent({ children }) {
  const { status } = useAsyncForm()
  return [
    statusTypes.idle,
    statusTypes.error,
    statusTypes.loadSuccess,
    statusTypes.updating,
    statusTypes.updateSuccess,
    statusTypes.deleting,
  ].indexOf(status) > -1
    ? children
    : null
}

/**
 * Wrapper for conditionally displaying children when AsyncForm is ready for user interaction.
 *
 * - Adds 'status', 'handleDelete', 'handleSave' to all children.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormActions({ children }) {
  const { status, handleDelete, handleSave } = useAsyncForm()
  return [
    statusTypes.idle,
    statusTypes.error,
    statusTypes.loadSuccess,
    statusTypes.updating,
    statusTypes.updateSuccess,
    statusTypes.deleting,
  ].indexOf(status) > -1
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, {
          status,
          handleDelete,
          handleSave,
        }),
      )
    : null
}
/**
 * Wrapper for conditionally displaying children when AsyncForm successfully performs delete operation.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function FormDeleteSuccess({ children }) {
  const { status } = useAsyncForm()
  return status === statusTypes.deleteSuccess ? children : null
}

/**
 * Top-Level Context Wrapper for all AsyncForm components.
 * Best used with 'useModel' hook to get status, error, handleSave, handleDelete props.
 *
 * Any props not listed explicitly are passed through to the `<form {...props}>` component.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 * @param {string} config.status
 * @param {Error} config.error
 * @param {Function} config.onSave
 * @param {Function} config.onDelete
 * @param {Function} config.afterSave
 * @param {Function} config.afterDelete
 */
export function AsyncForm({
  status,
  error,
  onSave,
  onDelete,
  afterSave,
  afterDelete,
  ...props
}) {
  async function handleSaveAndUpdate(event) {
    if (Boolean(event) && Boolean(event.preventDefault)) event.preventDefault()

    if (typeof onSave !== 'function') {
      throw new Error(
        'You did not specify a `handleSave` prop for `AsyncForm`.',
      )
    }

    await onSave()
    if (typeof afterSave === 'function') {
      afterSave()
    }
  }

  async function handleDeleteAndUpdate(event) {
    if (Boolean(event) && Boolean(event.preventDefault)) event.preventDefault()

    if (typeof onDelete !== 'function') {
      throw new Error(
        'You did not specify a `handleDelete` prop for `AsyncForm`.',
      )
    }

    await onDelete()
    if (typeof afterDelete === 'function') {
      afterDelete()
    }
  }

  return (
    <AsyncFormContext.Provider
      value={{
        status,
        error,
        handleDelete: handleDeleteAndUpdate,
        handleSave: handleSaveAndUpdate,
      }}
    >
      <form
        autoComplete="off"
        noValidate={true}
        onSubmit={handleSaveAndUpdate}
        {...props}
      />
    </AsyncFormContext.Provider>
  )
}

AsyncForm.propTypes = {
  status: PropTypes.oneOf(statusTypes.$array),
  error: PropTypes.oneOfType([PropTypes.instanceOf(Error)]),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  afterSave: PropTypes.func,
  afterDelete: PropTypes.func,
}

AsyncForm.defaultProps = {
  status: statusTypes.loading,
  error: null,
  onDelete: null,
  afterSave: null,
  afterDelete: null,
}
