package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference

@Serializable
data class MomentReferences(
    @ProtoNumber(1) val usfm: List<String>? = null,
    @ProtoNumber(2) val versionId: Int? = null
) {
    init {
        freeze()
    }

    fun toReference(): BibleReference = BibleReference((usfm ?: emptyList()).joinToString("+"), versionId ?: 0)
}
