package youversion.red.prayer.guided.db.guidedprayer

import com.squareup.sqldelight.Query
import com.squareup.sqldelight.TransacterImpl
import com.squareup.sqldelight.`internal`.copyOnWriteList
import com.squareup.sqldelight.db.SqlCursor
import com.squareup.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.collections.MutableList
import kotlin.reflect.KClass
import red.platform.Date
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.prayer.guided.db.GuidedPrayerDatabase
import youversion.red.prayer.guided.db.GuidedPrayerQueries
import youversion.red.prayer.guided.db.Guided_prayer_day
import youversion.red.prayer.guided.db.Guided_prayer_guide
import youversion.red.prayer.guided.db.Guided_prayer_module

internal val KClass<GuidedPrayerDatabase>.schema: SqlDriver.Schema
  get() = GuidedPrayerDatabaseImpl.Schema

internal fun KClass<GuidedPrayerDatabase>.newInstance(
  driver: SqlDriver,
  guided_prayer_dayAdapter: Guided_prayer_day.Adapter,
  guided_prayer_moduleAdapter: Guided_prayer_module.Adapter
): GuidedPrayerDatabase = GuidedPrayerDatabaseImpl(driver, guided_prayer_dayAdapter,
    guided_prayer_moduleAdapter)

private class GuidedPrayerDatabaseImpl(
  driver: SqlDriver,
  internal val guided_prayer_dayAdapter: Guided_prayer_day.Adapter,
  internal val guided_prayer_moduleAdapter: Guided_prayer_module.Adapter
) : TransacterImpl(driver), GuidedPrayerDatabase {
  public override val guidedPrayerQueries: GuidedPrayerQueriesImpl = GuidedPrayerQueriesImpl(this,
      driver)

  public object Schema : SqlDriver.Schema {
    public override val version: Int
      get() = 1

    public override fun create(driver: SqlDriver): Unit {
      driver.execute(null, """
          |CREATE TABLE guided_prayer_guide(
          |    id INTEGER NOT NULL PRIMARY KEY,
          |    languageTags TEXT
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE guided_prayer_day(
          |    guideId INTEGER NOT NULL,
          |    id INTEGER NOT NULL,
          |    imageUrl TEXT,
          |    title TEXT,
          |    lightBgColor TEXT,
          |    darkBgColor TEXT,
          |    lastSync INTEGER,
          |    PRIMARY KEY (guideId, id),
          |    FOREIGN KEY (guideId) REFERENCES guided_prayer_guide(id) ON DELETE CASCADE
          |)
          """.trimMargin(), 0)
      driver.execute(null, """
          |CREATE TABLE guided_prayer_module (
          |    guideId INTEGER NOT NULL,
          |    dayId INTEGER NOT NULL,
          |    id INTEGER NOT NULL,
          |    type TEXT,
          |    title TEXT,
          |    header TEXT,
          |    usfm TEXT,
          |    text TEXT,
          |    ctaText TEXT,
          |    ctaUrl TEXT,
          |    animationId INTEGER,
          |    FOREIGN KEY (guideId, dayId) REFERENCES guided_prayer_day(guideId, id) ON DELETE CASCADE,
          |    PRIMARY KEY (guideId, dayId, id)
          |)
          """.trimMargin(), 0)
    }

    public override fun migrate(
      driver: SqlDriver,
      oldVersion: Int,
      newVersion: Int
    ): Unit {
    }
  }
}

private class GuidedPrayerQueriesImpl(
  private val database: GuidedPrayerDatabaseImpl,
  private val driver: SqlDriver
) : TransacterImpl(driver), GuidedPrayerQueries {
  internal val getGuide: MutableList<Query<*>> = copyOnWriteList()

  internal val guideExists: MutableList<Query<*>> = copyOnWriteList()

  internal val getGuides: MutableList<Query<*>> = copyOnWriteList()

  internal val getModule: MutableList<Query<*>> = copyOnWriteList()

  internal val getModules: MutableList<Query<*>> = copyOnWriteList()

  internal val getDay: MutableList<Query<*>> = copyOnWriteList()

  public override fun <T : Any> getGuide(id: Int, mapper: (id: Int, languageTags: String?) -> T):
      Query<T> = GetGuideQuery(id) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)
    )
  }

  public override fun getGuide(id: Int): Query<Guided_prayer_guide> = getGuide(id) { id_,
      languageTags ->
    Guided_prayer_guide(
      id_,
      languageTags
    )
  }

  public override fun guideExists(id: Int): Query<Boolean> = GuideExistsQuery(id) { cursor ->
    cursor.getLong(0)!! == 1L
  }

  public override fun <T : Any> getGuides(mapper: (id: Int, languageTags: String?) -> T): Query<T> =
      Query(-1457666235, getGuides, driver, "GuidedPrayer.sq", "getGuides",
      "SELECT * FROM guided_prayer_guide") { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getString(1)
    )
  }

  public override fun getGuides(): Query<Guided_prayer_guide> = getGuides { id, languageTags ->
    Guided_prayer_guide(
      id,
      languageTags
    )
  }

  public override fun <T : Any> getModule(
    id: Int,
    dayId: Int,
    guideId: Int,
    mapper: (
      guideId: Int,
      dayId: Int,
      id: Int,
      type: GuidedPrayerModuleType?,
      title: String?,
      header: String?,
      usfm: String?,
      text: String?,
      ctaText: String?,
      ctaUrl: String?,
      animationId: Int?
    ) -> T
  ): Query<T> = GetModuleQuery(id, dayId, guideId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      cursor.getString(3)?.let { database.guided_prayer_moduleAdapter.typeAdapter.decode(it) },
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getLong(10)?.toInt()
    )
  }

  public override fun getModule(
    id: Int,
    dayId: Int,
    guideId: Int
  ): Query<Guided_prayer_module> = getModule(id, dayId, guideId) { guideId_, dayId_, id_, type,
      title, header, usfm, text, ctaText, ctaUrl, animationId ->
    Guided_prayer_module(
      guideId_,
      dayId_,
      id_,
      type,
      title,
      header,
      usfm,
      text,
      ctaText,
      ctaUrl,
      animationId
    )
  }

  public override fun <T : Any> getModules(
    dayId: Int,
    guideId: Int,
    mapper: (
      guideId: Int,
      dayId: Int,
      id: Int,
      type: GuidedPrayerModuleType?,
      title: String?,
      header: String?,
      usfm: String?,
      text: String?,
      ctaText: String?,
      ctaUrl: String?,
      animationId: Int?
    ) -> T
  ): Query<T> = GetModulesQuery(dayId, guideId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getLong(2)!!.toInt(),
      cursor.getString(3)?.let { database.guided_prayer_moduleAdapter.typeAdapter.decode(it) },
      cursor.getString(4),
      cursor.getString(5),
      cursor.getString(6),
      cursor.getString(7),
      cursor.getString(8),
      cursor.getString(9),
      cursor.getLong(10)?.toInt()
    )
  }

  public override fun getModules(dayId: Int, guideId: Int): Query<Guided_prayer_module> =
      getModules(dayId, guideId) { guideId_, dayId_, id, type, title, header, usfm, text, ctaText,
      ctaUrl, animationId ->
    Guided_prayer_module(
      guideId_,
      dayId_,
      id,
      type,
      title,
      header,
      usfm,
      text,
      ctaText,
      ctaUrl,
      animationId
    )
  }

  public override fun <T : Any> getDay(
    id: Int,
    guideId: Int,
    mapper: (
      guideId: Int,
      id: Int,
      imageUrl: String?,
      title: String?,
      lightBgColor: String?,
      darkBgColor: String?,
      lastSync: Date?
    ) -> T
  ): Query<T> = GetDayQuery(id, guideId) { cursor ->
    mapper(
      cursor.getLong(0)!!.toInt(),
      cursor.getLong(1)!!.toInt(),
      cursor.getString(2),
      cursor.getString(3),
      cursor.getString(4),
      cursor.getString(5),
      cursor.getLong(6)?.let { database.guided_prayer_dayAdapter.lastSyncAdapter.decode(it) }
    )
  }

  public override fun getDay(id: Int, guideId: Int): Query<Guided_prayer_day> = getDay(id,
      guideId) { guideId_, id_, imageUrl, title, lightBgColor, darkBgColor, lastSync ->
    Guided_prayer_day(
      guideId_,
      id_,
      imageUrl,
      title,
      lightBgColor,
      darkBgColor,
      lastSync
    )
  }

  public override fun addGuide(id: Int?, languageTags: String?): Unit {
    driver.execute(-2012593021, """
    |INSERT INTO guided_prayer_guide (
    |    id,
    |    languageTags
    |) VALUES (
    |    ?,
    |    ?
    |)
    """.trimMargin(), 2) {
      bindLong(1, id?.let { it.toLong() })
      bindString(2, languageTags)
    }
    notifyQueries(-2012593021, {database.guidedPrayerQueries.getGuides +
        database.guidedPrayerQueries.guideExists + database.guidedPrayerQueries.getGuide})
  }

  public override fun updateLanguages(languageTags: String?, id: Int): Unit {
    driver.execute(432352938, """
    |UPDATE guided_prayer_guide
    |SET languageTags = ?
    |WHERE id = ?
    """.trimMargin(), 2) {
      bindString(1, languageTags)
      bindLong(2, id.toLong())
    }
    notifyQueries(432352938, {database.guidedPrayerQueries.getGuides +
        database.guidedPrayerQueries.guideExists + database.guidedPrayerQueries.getGuide})
  }

  public override fun deleteAllGuides(): Unit {
    driver.execute(2011330021, """DELETE FROM guided_prayer_guide""", 0)
    notifyQueries(2011330021, {database.guidedPrayerQueries.getDay +
        database.guidedPrayerQueries.getGuides + database.guidedPrayerQueries.guideExists +
        database.guidedPrayerQueries.getGuide})
  }

  public override fun addModule(
    guideId: Int,
    dayId: Int,
    id: Int,
    type: GuidedPrayerModuleType?,
    title: String?,
    header: String?,
    usfm: String?,
    text: String?,
    ctaText: String?,
    ctaUrl: String?,
    animationId: Int?
  ): Unit {
    driver.execute(-2094740027, """
    |INSERT INTO guided_prayer_module (
    |    guideId,
    |    dayId,
    |    id,
    |    type,
    |    title,
    |    header,
    |    usfm,
    |    text,
    |    ctaText,
    |    ctaUrl,
    |    animationId
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 11) {
      bindLong(1, guideId.toLong())
      bindLong(2, dayId.toLong())
      bindLong(3, id.toLong())
      bindString(4, type?.let { database.guided_prayer_moduleAdapter.typeAdapter.encode(it) })
      bindString(5, title)
      bindString(6, header)
      bindString(7, usfm)
      bindString(8, text)
      bindString(9, ctaText)
      bindString(10, ctaUrl)
      bindLong(11, animationId?.let { it.toLong() })
    }
    notifyQueries(-2094740027, {database.guidedPrayerQueries.getModules +
        database.guidedPrayerQueries.getModule})
  }

  public override fun updateModule(
    guideId: Int,
    dayId: Int,
    id: Int,
    type: GuidedPrayerModuleType?,
    title: String?,
    header: String?,
    usfm: String?,
    text: String?,
    ctaText: String?,
    ctaUrl: String?,
    animationId: Int?
  ): Unit {
    driver.execute(2047701565, """
    |UPDATE guided_prayer_module SET
    |    guideId = ?,
    |    dayId = ?,
    |    id = ?,
    |    type = ?,
    |    title = ?,
    |    header = ?,
    |    usfm = ?,
    |    text = ?,
    |    ctaText = ?,
    |    ctaUrl = ?,
    |    animationId = ?
    |WHERE guideId = ? AND dayId = ? AND id = ?
    """.trimMargin(), 14) {
      bindLong(1, guideId.toLong())
      bindLong(2, dayId.toLong())
      bindLong(3, id.toLong())
      bindString(4, type?.let { database.guided_prayer_moduleAdapter.typeAdapter.encode(it) })
      bindString(5, title)
      bindString(6, header)
      bindString(7, usfm)
      bindString(8, text)
      bindString(9, ctaText)
      bindString(10, ctaUrl)
      bindLong(11, animationId?.let { it.toLong() })
      bindLong(12, guideId.toLong())
      bindLong(13, dayId.toLong())
      bindLong(14, id.toLong())
    }
    notifyQueries(2047701565, {database.guidedPrayerQueries.getModules +
        database.guidedPrayerQueries.getModule})
  }

  public override fun deleteModule(
    id: Int,
    dayId: Int,
    guideId: Int
  ): Unit {
    driver.execute(-2067067489,
        """DELETE FROM guided_prayer_module WHERE id = ? AND dayId = ? AND guideId = ?""", 3) {
      bindLong(1, id.toLong())
      bindLong(2, dayId.toLong())
      bindLong(3, guideId.toLong())
    }
    notifyQueries(-2067067489, {database.guidedPrayerQueries.getModules +
        database.guidedPrayerQueries.getModule})
  }

  public override fun deleteModules(dayId: Int, guideId: Int): Unit {
    driver.execute(345417396,
        """DELETE FROM guided_prayer_module WHERE dayId = ? AND guideId = ?""", 2) {
      bindLong(1, dayId.toLong())
      bindLong(2, guideId.toLong())
    }
    notifyQueries(345417396, {database.guidedPrayerQueries.getModules +
        database.guidedPrayerQueries.getModule})
  }

  public override fun deleteAllModules(): Unit {
    driver.execute(-1219103655, """DELETE FROM guided_prayer_module""", 0)
    notifyQueries(-1219103655, {database.guidedPrayerQueries.getModules +
        database.guidedPrayerQueries.getModule})
  }

  public override fun addDay(
    guideId: Int,
    id: Int,
    imageUrl: String?,
    title: String?,
    lightBgColor: String?,
    darkBgColor: String?,
    lastSync: Date?
  ): Unit {
    driver.execute(-1914944797, """
    |INSERT INTO guided_prayer_day (
    |    guideId,
    |    id,
    |    imageUrl,
    |    title,
    |    lightBgColor,
    |    darkBgColor,
    |    lastSync
    |) VALUES (
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?,
    |    ?
    |)
    """.trimMargin(), 7) {
      bindLong(1, guideId.toLong())
      bindLong(2, id.toLong())
      bindString(3, imageUrl)
      bindString(4, title)
      bindString(5, lightBgColor)
      bindString(6, darkBgColor)
      bindLong(7, lastSync?.let { database.guided_prayer_dayAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(-1914944797, {database.guidedPrayerQueries.getDay})
  }

  public override fun updateDay(
    id: Int,
    imageUrl: String?,
    title: String?,
    lightBgColor: String?,
    darkBgColor: String?,
    lastSync: Date?,
    guideId: Int
  ): Unit {
    driver.execute(426946923, """
    |UPDATE guided_prayer_day SET
    |    guideId = guideId,
    |    id = ?,
    |    imageUrl = ?,
    |    title = ?,
    |    lightBgColor = ?,
    |    darkBgColor = ?,
    |    lastSync = ?
    |WHERE id = ? AND guideId = ?
    """.trimMargin(), 8) {
      bindLong(1, id.toLong())
      bindString(2, imageUrl)
      bindString(3, title)
      bindString(4, lightBgColor)
      bindString(5, darkBgColor)
      bindLong(6, lastSync?.let { database.guided_prayer_dayAdapter.lastSyncAdapter.encode(it) })
      bindLong(7, id.toLong())
      bindLong(8, guideId.toLong())
    }
    notifyQueries(426946923, {database.guidedPrayerQueries.getDay})
  }

  public override fun deleteDay(id: Int, guideId: Int): Unit {
    driver.execute(2017435977, """DELETE FROM guided_prayer_day WHERE id = ? AND guideId = ?""", 2)
        {
      bindLong(1, id.toLong())
      bindLong(2, guideId.toLong())
    }
    notifyQueries(2017435977, {database.guidedPrayerQueries.getDay +
        database.guidedPrayerQueries.getModules + database.guidedPrayerQueries.getModule})
  }

  public override fun deleteAllDays(): Unit {
    driver.execute(-1794661179, """DELETE FROM guided_prayer_day""", 0)
    notifyQueries(-1794661179, {database.guidedPrayerQueries.getDay +
        database.guidedPrayerQueries.getModules + database.guidedPrayerQueries.getModule})
  }

  public override fun deleteOldDays(expiration: Date?): Unit {
    driver.execute(2033067595, """DELETE FROM guided_prayer_day WHERE lastSync < ?""", 1) {
      bindLong(1, expiration?.let { database.guided_prayer_dayAdapter.lastSyncAdapter.encode(it) })
    }
    notifyQueries(2033067595, {database.guidedPrayerQueries.getDay +
        database.guidedPrayerQueries.getModules + database.guidedPrayerQueries.getModule})
  }

  private inner class GetGuideQuery<out T : Any>(
    public val id: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getGuide, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(1199904494,
        """SELECT g.* FROM guided_prayer_guide g WHERE g.id = ?""", 1) {
      bindLong(1, id.toLong())
    }

    public override fun toString(): String = "GuidedPrayer.sq:getGuide"
  }

  private inner class GuideExistsQuery<out T : Any>(
    public val id: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(guideExists, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-130353200,
        """SELECT EXISTS(SELECT 1 FROM guided_prayer_guide WHERE id = ?)""", 1) {
      bindLong(1, id.toLong())
    }

    public override fun toString(): String = "GuidedPrayer.sq:guideExists"
  }

  private inner class GetModuleQuery<out T : Any>(
    public val id: Int,
    public val dayId: Int,
    public val guideId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getModule, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1291564870,
        """SELECT * FROM guided_prayer_module WHERE id = ? AND dayId = ? AND guideId = ?""", 3) {
      bindLong(1, id.toLong())
      bindLong(2, dayId.toLong())
      bindLong(3, guideId.toLong())
    }

    public override fun toString(): String = "GuidedPrayer.sq:getModule"
  }

  private inner class GetModulesQuery<out T : Any>(
    public val dayId: Int,
    public val guideId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getModules, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1383805191,
        """SELECT * FROM guided_prayer_module WHERE dayId = ? AND guideId = ?""", 2) {
      bindLong(1, dayId.toLong())
      bindLong(2, guideId.toLong())
    }

    public override fun toString(): String = "GuidedPrayer.sq:getModules"
  }

  private inner class GetDayQuery<out T : Any>(
    public val id: Int,
    public val guideId: Int,
    mapper: (SqlCursor) -> T
  ) : Query<T>(getDay, mapper) {
    public override fun execute(): SqlCursor = driver.executeQuery(-1741769714,
        """SELECT * FROM guided_prayer_day WHERE id = ? AND guideId = ?""", 2) {
      bindLong(1, id.toLong())
      bindLong(2, guideId.toLong())
    }

    public override fun toString(): String = "GuidedPrayer.sq:getDay"
  }
}
