package youversion.red.podcasts.service

import kotlin.js.JsName
import kotlinx.coroutines.flow.Flow
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.podcasts.api.model.shows.Show
import youversion.red.podcasts.api.model.shows.Shows
import youversion.red.podcasts.model.ListeningProgress
import youversion.red.podcasts.model.PodcastEpisode
import youversion.red.podcasts.model.PodcastEpisodes

@JsName("PodcastsServiceProvider")
fun PodcastsService() = service<IPodcastsService>()

@ExportedService
interface IPodcastsService : IService {

    @JsName("getFeaturedEpisodesWithLanguageTag")
    suspend fun getFeaturedEpisodes(
        page: Int? = null,
        pageSize: Int? = null,
        languageTag: String?
    ): PodcastEpisodes?

    suspend fun getFeaturedEpisodes(page: Int? = null, pageSize: Int? = null): PodcastEpisodes?

    @JsName("getFeaturedEpisodesFlowAndLanguageTag")
    fun getFeaturedEpisodesFlow(
        page: Int? = null,
        pageSize: Int? = null,
        languageTag: String?
    ): Flow<PodcastEpisodes?>

    @JsName("getFeaturedEpisodesFlow")
    fun getFeaturedEpisodesFlow(page: Int? = null, pageSize: Int? = null): Flow<PodcastEpisodes?>

    @Deprecated(
        message = "This method will be removed in a future release. Please getEpisode(episodeId) instead.",
        replaceWith = ReplaceWith(
            expression = "PodcastsService.getEpisode(episodeId)",
            imports = ["youversion.red.podcasts.service.getEpisode"]
        )
    )
    @JsName("getEpisodeWithShowId")
    suspend fun getEpisode(showId: Int, episodeId: Int): PodcastEpisode?

    @JsName("getEpisode")
    suspend fun getEpisode(episodeId: Int): PodcastEpisode?

    suspend fun addOrUpdateListeningProgress(
        episodeId: Int,
        timeElapsed: Int,
        totalTime: Int
    )

    suspend fun getListeningProgress(episodeId: Int): ListeningProgress?

    fun getListeningProgressFlow(episodeId: Int): Flow<ListeningProgress?>

    suspend fun getContinueListeningEpisodes(): List<PodcastEpisode>

    fun getContinueListeningEpisodesFlow(): Flow<List<PodcastEpisode>>

    suspend fun markPodcastAsPlayed(episodeId: Int)

    suspend fun getShow(showId: Int): Show?

    suspend fun getShows(page: Int? = null, pageSize: Int? = null): Shows

    suspend fun getEpisodesByShowId(
        showId: Int,
        page: Int? = null,
        pageSize: Int? = null
    ): PodcastEpisodes

    suspend fun getEpisodesByPlaylistId(
        playlistId: Int,
        page: Int? = null,
        pageSize: Int? = null
    ): PodcastEpisodes

    @JsName("deleteListeningProgress")
    suspend fun deleteListeningProgress()
}
