package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
enum class MomentImageSource {
    @ProtoNumber(0) Unknown,
    @ProtoNumber(1) Lens
}
