import { isInBrowser } from 'environment/is-in-browser'

/**
 * Returns either a valid environment or default environment.
 *
 * @module getEnvironment
 *
 * @param {Array} validEnvironments - An array of valid environment strings.
 * @param {string} defaultEnvironment - The default environment to fall back on.
 *
 * @returns {string} Environment.
 */
function getEnvironment(validEnvironments, defaultEnvironment) {
  let environment
  if (isInBrowser()) {
    /* eslint-disable-next-line no-underscore-dangle */
    environment = window.API_ENV || window.__ENV__ || defaultEnvironment
  } else {
    environment = process.env.NODE_ENV || defaultEnvironment
  }

  environment = environment.toLowerCase()

  return validEnvironments.indexOf(environment) > -1
    ? environment
    : defaultEnvironment
}

/**
 * Gets the API environment.
 *
 * @returns {string} Api environment.
 *
 * @example
 * let apiUrl = null
 * const env = getAPIEnvironment()
 * if (env === production) {
 *   apiUrl = 'https://api.example.com'
 * } else {
 *   apiUrl = 'https://staging-api.example.com'
 * }
 */
export function getAPIEnvironment() {
  const validAPIEnvironments = ['production', 'staging', 'test']
  return getEnvironment(validAPIEnvironments, 'staging')
}

/**
 * Gets the API environment.
 *
 * @returns {string} Run environment.
 *
 * @example
 * const env = getRunEnvironment()
 * if (env === development) {
 *   console.log('development mode')
 * }
 */
export function getRunEnvironment() {
  const validRunEnvironments = ['production', 'development', 'review', 'test']
  return getEnvironment(validRunEnvironments, 'development')
}
