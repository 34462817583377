// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.prayer.api.model.PrayerSuggestionType

@Serializable
data class PrayerSuggestionAction(
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.prayer.api.model.PrayerSuggestionTypeSerializer::class)
  val type: PrayerSuggestionType? = null
) : Event(), red.Serializable {
  override val key: String = "PrayerSuggestionAction"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    type?.let { bundle.putString("type", type.serialName) }
    return bundle
  }
}
