// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class LookupType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  GREETING_MODULE("GreetingModule", 1),

  PLAN_MODULE("PlanModule", 2),

  PRAYER_MODULE("PrayerModule", 3),

  REFLECTION_MODULE("ReflectionModule", 4),

  SCRIPTURE_IMAGE_MODULE("ScriptureImageModule", 5),

  SCRIPTURE_MODULE("ScriptureModule", 6),

  SERMON_MODULE("SermonModule", 7),

  UP_NEXT_MODULE("UpNextModule", 8),

  VIDEO_MODULE("VideoModule", 9),

  COMPLETION_MODULE("CompletionModule", 10);
}

internal class LookupTypeSerializer : KSerializer<LookupType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.stories.api.model.LookupType")

  override fun serialize(encoder: Encoder, value: LookupType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): LookupType {
    if (decoder is JsonDecoder) {
      return LookupType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> LookupType.UNSUPPORTED_PLACEHOLDER
      1 -> LookupType.GREETING_MODULE
      2 -> LookupType.PLAN_MODULE
      3 -> LookupType.PRAYER_MODULE
      4 -> LookupType.REFLECTION_MODULE
      5 -> LookupType.SCRIPTURE_IMAGE_MODULE
      6 -> LookupType.SCRIPTURE_MODULE
      7 -> LookupType.SERMON_MODULE
      8 -> LookupType.UP_NEXT_MODULE
      9 -> LookupType.VIDEO_MODULE
      10 -> LookupType.COMPLETION_MODULE
      else -> LookupType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
