package youversion.red.prayer.model

import red.platform.Date

data class PrayerReaction(
    val prayerClientId: String,
    val prayerServerId: Int?,
    val userId: Long,
    val total: Int,
    val needsSyncing: Boolean,
    val updatedDt: Date
)
