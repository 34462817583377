package youversion.red.analytics.alps

import kotlinx.serialization.ExperimentalSerializationApi
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.protobuf.ProtoBuf
import red.platform.Log
import red.platform.http.RequestManager
import red.platform.network.Socket
import red.platform.network.use
import red.platform.threads.freeze

object AlpsApi {

    /* visible for testing only */
    internal val context = SerializersModule {
        polymorphic(AlpsContext::class, ExampleContext::class, ExampleContext.serializer())
    }.freeze()

    @OptIn(ExperimentalSerializationApi::class)
    fun send(event: AlpsEvent) {
        val url = if (RequestManager.staging) {
            "alps.youversionapistaging.com"
        } else {
            "alps.youversionapi.com"
        }
        Log.d("AlpsApi", "sending: $event")
        Socket.createUdp(url, 64286).use {
            send(ProtoBuf { encodeDefaults = true; serializersModule = context }.encodeToByteArray(AlpsEvent.serializer(), event))
        }
    }
}
