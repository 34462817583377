// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.blue.api.model.enrollment.CountryEnrollment
import youversion.red.blue.api.model.enrollment.DeviceEnrollment
import youversion.red.blue.api.model.enrollment.Enrollment
import youversion.red.blue.api.model.state.BooleanState
import youversion.red.blue.api.model.state.DeviceState
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.api.model.state.State

object BlueApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      polymorphic(youversion.red.blue.api.model.state.State::class,
          youversion.red.blue.api.model.state.FeatureState::class,
          youversion.red.blue.api.model.state.FeatureState.serializer())
      polymorphic(youversion.red.blue.api.model.state.State::class,
          youversion.red.blue.api.model.state.DeviceState::class,
          youversion.red.blue.api.model.state.DeviceState.serializer())
      polymorphic(youversion.red.blue.api.model.state.State::class,
          youversion.red.blue.api.model.state.BooleanState::class,
          youversion.red.blue.api.model.state.BooleanState.serializer())
      polymorphic(youversion.red.blue.api.model.enrollment.Enrollment::class,
          youversion.red.blue.api.model.enrollment.DeviceEnrollment::class,
          youversion.red.blue.api.model.enrollment.DeviceEnrollment.serializer())
      polymorphic(youversion.red.blue.api.model.enrollment.Enrollment::class,
          youversion.red.blue.api.model.enrollment.CountryEnrollment::class,
          youversion.red.blue.api.model.enrollment.CountryEnrollment.serializer())
      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; classDiscriminator = "_type";  serializersModule =
      context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
