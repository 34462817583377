package youversion.red.prayer.guided.tasks

import red.platform.threads.freeze
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.prayer.guided.model.GuideModuleItems
import youversion.red.prayer.guided.model.GuidedPrayerModule
import youversion.red.prayer.guided.model.PrayerItemSeedingStrategy
import youversion.red.prayer.guided.model.PrayerListCandidates
import youversion.red.prayer.model.Prayer

internal class PrayerListItemsSync(
    modules: List<GuidedPrayerModule>,
    private val prayerList: List<Prayer>,
    private val candidates: PrayerListCandidates,
    private val prayerItemSeedingStrategy: PrayerItemSeedingStrategy
) {

    private val fetcher: suspend (fetchSize: Int) -> Pair<List<GuidedPrayerModule>, Int> = {
        val prayerModule: GuidedPrayerModule? = modules.findLast { module ->
            module.type == GuidedPrayerModuleType.PRAYER_LIST
        }
        val (prayers, availableInNextBatch) = fetchNextBatchOfPrayers(it)
        Pair(
            prayers.mapToGuidedPrayerModules(prayerModule!!),
            availableInNextBatch
        )
    }

    val moduleItems: GuideModuleItems by lazy {
        GuideModuleItems(
            modules,
            fetcher,
            prayerItemSeedingStrategy
        )
    }

    init {
        freeze()
    }

    private fun fetchNextBatchOfPrayers(batchSize: Int): Pair<List<Prayer>, Int> {
        val nextBatch = candidates.consume(batchSize).map {
            prayerList.find { p -> p.clientId == it }!!
        }
        return Pair(nextBatch, minOf(candidates.remaining, batchSize))
    }
}

fun List<Prayer?>.mapToGuidedPrayerModules(guidedPrayerListModule: GuidedPrayerModule): List<GuidedPrayerModule> {
    return map { prayer -> guidedPrayerListModule.copy(prayer = prayer) }
}
