package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import youversion.red.achievements.module.AchievementsModuleInitializer

public object RedAchievementsModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: AchievementsModuleInitializer = AchievementsModuleInitializer()

  public override val name: String = "achievements"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    ModuleRegistry["core"].registerServiceFactories()
    red.service.ServiceRegistry.setFactoryIfNotSet(youversion.red.achievements.service.IAchievementsService::class)
        { youversion.red.achievements.service.AchievementsServiceImpl() }
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    ModuleRegistry["core"].initialize()
    initializer.initialize()
    
  }
}
