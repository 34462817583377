// Auto-Generated from OpenAPI Spec
package youversion.red.search.api

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.search.api.model.Elevation
import youversion.red.search.api.model.Elevations
import youversion.red.search.api.model.Feature
import youversion.red.search.api.model.FeatureSource
import youversion.red.search.api.model.Features
import youversion.red.search.api.model.Images
import youversion.red.search.api.model.Kind
import youversion.red.search.api.model.Plans
import youversion.red.search.api.model.Podcasts
import youversion.red.search.api.model.Results
import youversion.red.search.api.model.SearchHistories
import youversion.red.search.api.model.SearchParamCanon
import youversion.red.search.api.model.Suggestions
import youversion.red.search.api.model.Topic
import youversion.red.search.api.model.Topics
import youversion.red.search.api.model.UserIntent
import youversion.red.search.api.model.Verses
import youversion.red.search.api.model.Videos

/**
 * Search service with Unified Results & Suggestions
 */
object SearchApi : AbstractApi(ApiDefaults("search", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", SearchApiSerializer)) {
  /**
   * This will return an array of elevation objects based on parameters.
   *
   * @param query Search data input when we want the default value to be all records (aka *). This
   * parameter is used by search and ask services.
   * @return Returns collection of elevation objects based on parameters
   */
  suspend fun getElevations(query: String? = null): Elevations = execute("/elevations", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      query?.let { add("query", query) }
  }, serializer = Elevations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This should *NOT* be used by end user clients. It will be used from an admin tool.
   */
  suspend fun addElevation(body: Elevation? = null) = execute("/elevations", version = "4.0", method
      = RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer
      = Elevation.serializer(), serializer = Unit.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Delete a elevation resource.
   *
   * @param elevationId The unique identifier for the Elevation resource.
   */
  suspend fun deleteElevation(elevationId: Int) = execute("/elevations/${elevationId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = false, authOptional = false, serializer
      = Unit.serializer())

  /**
   * Get an elevation by its id.
   *
   * @param elevationId The unique identifier for the Elevation resource.
   * @return Successful request
   */
  suspend fun getElevation(elevationId: Int): Elevation? = execute("/elevations/${elevationId}",
      method = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false,
      serializer = Elevation.serializer())

  /**
   * Updates an existing elevation
   *
   * @param elevationId The unique identifier for the Elevation resource.
   * @return Successful request
   */
  suspend fun editElevation(elevationId: Int, body: Elevation? = null): Elevation =
      execute("/elevations/${elevationId}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = false, authOptional = false, body = body, bodySerializer =
      Elevation.serializer(), serializer = Elevation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This will return an array of feature objects based on parameters. For now there is no need to
   * paginate with such a small set of results and limited use for this API.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param source Query parameter which informs the API that the client wants features from a
   * specific source.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @return Returns collection of feature objects based on parameters
   */
  suspend fun getFeatures(
    checkSpelling: Boolean? = null,
    source: FeatureSource? = null,
    query: String
  ): Features = execute("/features", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      source?.let { add("source", source.serialName) }
      add("query", query)
  }, serializer = Features.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This should *NOT* be used by clients. Called on a build pipeline from our service specs
   * project. It may also be used from an admin tool.
   */
  suspend fun addFeature(body: Feature? = null) = execute("/features", version = "4.0", method =
      RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer =
      Feature.serializer(), serializer = Unit.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Delete a feature resource.
   *
   * @param featureId The unique identifier for the Feature resource.
   */
  suspend fun deleteFeature(featureId: Int) = execute("/features/${featureId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = false, authOptional = false, serializer
      = Unit.serializer())

  /**
   * Get a feature by its id.
   *
   * @param featureId The unique identifier for the Feature resource.
   * @return Successful request
   */
  suspend fun getFeature(featureId: Int): Feature? = execute("/features/${featureId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      Feature.serializer())

  /**
   * Updates an existing feature
   *
   * @param featureId The unique identifier for the Feature resource.
   * @return Successful request
   */
  suspend fun editFeature(featureId: Int, body: Feature? = null): Feature =
      execute("/features/${featureId}", version = "4.0", method = RequestMethods.PUT, authAllowed =
      false, authOptional = false, body = body, bodySerializer = Feature.serializer(), serializer =
      Feature.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * This will return an array of history objects for the authenticated user based on parameters.
   *
   * @param kind Kind parameter to indicate which facet of search is desired.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input when we want the default value to be all records (aka *). This
   * parameter is used by search and ask services.
   * @return Returns collection of history search objects for the user based on parameters
   */
  suspend fun getHistory(
    kind: Kind? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String? = null
  ): SearchHistories? = execute("/history", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      kind?.let { add("kind", kind.serialName) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      query?.let { add("query", query) }
  }, serializer = SearchHistories.serializer())

  /**
   * This will remove all search history associated with the authenticated user.
   */
  suspend fun deleteHistory() = execute("/history", method = RequestMethods.DELETE, version = "4.0",
      authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * This will return an array of image objects based on parameters.
   *
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Returns collection of image objects based on parameters
   */
  suspend fun getImages(
    bibleVersionId: Int,
    query: String,
    page: Int? = null,
    pageSize: Int? = null
  ): Images = execute("/images", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      add("bible_version_id", bibleVersionId)
      add("query", query)
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Images.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This API endpoint returns a collection object with a data array of plan objects along with the
   * page number and page size the query used.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param languageTag The localization the user has selected for the API object being returned.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @return Successful request
   */
  suspend fun getPlans(
    checkSpelling: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    languageTag: String? = null,
    query: String,
    userIntent: UserIntent
  ): Plans? = execute("/plans", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      languageTag?.let { add("language_tag", languageTag) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Plans.serializer())

  /**
   * This API endpoint returns a collection object with a data array of podcast objects along with
   * the page number and page size the query used.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param languageTag The localization the user has selected for the API object being returned.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @return Successful request
   */
  suspend fun getPodcasts(
    checkSpelling: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    languageTag: String? = null,
    query: String,
    userIntent: UserIntent
  ): Podcasts? = execute("/podcasts", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      languageTag?.let { add("language_tag", languageTag) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Podcasts.serializer())

  /**
   * This API endpoint returns an array of result kinds based on search query input. This is the API
   * where a user's search history gets saved under Unified Search so make sure and pass auth when it
   * is available.
   *
   * @param appLanguageTag App localization language tag
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param book The Bible USFM book name to filter the query results by.
   * @param canon The Bible canon section to filter query results by. Either NT (New Testament), OT
   * (Old Testament), or AP (Apocrypha). The "all" default state means to return all results
   * unfiltered.
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param videosLanguageTag The localization to use for showing Videos to the user
   * @return Successful request
   */
  suspend fun getResults(
    appLanguageTag: String,
    bibleVersionId: Int,
    book: String? = null,
    canon: SearchParamCanon? = null,
    checkSpelling: Boolean? = null,
    plansLanguageTag: String,
    query: String,
    userIntent: UserIntent,
    videosLanguageTag: String
  ): Results? = execute("/results", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = true, queryString = red.platform.http.queryString {
      add("app_language_tag", appLanguageTag)
      add("bible_version_id", bibleVersionId)
      book?.let { add("book", book) }
      canon?.let { add("canon", canon.serialName) }
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      add("plans_language_tag", plansLanguageTag)
      add("query", query)
      add("user_intent", userIntent.serialName)
      add("videos_language_tag", videosLanguageTag)
  }, serializer = Results.serializer())

  /**
   * This will return an array of suggestions based on parameters.
   *
   * @param appLanguageTag App localization language tag
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param kind Kind parameter to indicate which facet of search is desired.
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param videosLanguageTag The localization to use for showing Videos to the user
   * @return Returns collection of suggestions objects based on search data input
   */
  suspend fun getSuggestions(
    appLanguageTag: String,
    bibleVersionId: Int,
    kind: Kind? = null,
    plansLanguageTag: String,
    query: String,
    videosLanguageTag: String
  ): Suggestions? = execute("/suggestions", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = true, queryString = red.platform.http.queryString {
      add("app_language_tag", appLanguageTag)
      add("bible_version_id", bibleVersionId)
      kind?.let { add("kind", kind.serialName) }
      add("plans_language_tag", plansLanguageTag)
      add("query", query)
      add("videos_language_tag", videosLanguageTag)
  }, serializer = Suggestions.serializer())

  /**
   * This API endpoint returns a collection object with a data array of topic objects along with the
   * page number and page size the query used.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @return Successful request
   */
  suspend fun getTopics(
    checkSpelling: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String
  ): Topics? = execute("/topics", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
  }, serializer = Topics.serializer())

  /**
   * Get a topic by its id.
   *
   * @param topicId The id of the topic resource
   * @return Successful request
   */
  suspend fun getTopic(topicId: Int): Topic? = execute("/topics/${topicId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      Topic.serializer())

  /**
   * This API endpoint returns a collection object with a data array of verse objects along with the
   * page number and page size the query used. If this is being used in RED for bundling data in a
   * build, there is a special token that can be used instead of auth. *Note* Page size with the user
   * intent "text" is currently locked to 25. This is because it calls the monolith 3.1 API internally
   * which had no notion of dynamic page sizing.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param bibleVersionId The user's current Bible version selected in the reader.
   * @param book The Bible USFM book name to filter the query results by.
   * @param canon The Bible canon section to filter query results by. Either NT (New Testament), OT
   * (Old Testament), or AP (Apocrypha). The "all" default state means to return all results
   * unfiltered.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @return Successful request.
   */
  suspend fun getVerses(
    checkSpelling: Boolean? = null,
    bibleVersionId: Int,
    book: String? = null,
    canon: SearchParamCanon? = null,
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String,
    userIntent: UserIntent
  ): Verses? = execute("/verses", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      add("bible_version_id", bibleVersionId)
      book?.let { add("book", book) }
      canon?.let { add("canon", canon.serialName) }
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
      add("user_intent", userIntent.serialName)
  }, serializer = Verses.serializer())

  /**
   * This API endpoint returns a collection object with a data array of video objects along with the
   * page number and page size the query used.
   *
   * @param checkSpelling Tell the API whether spell checking should be ran. You will want to set
   * this to "false" when a user taps the "did you mean" suggestion or tapped "search instead for". The
   * default value is "true".
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param query Search data input by user. This parameter is used by search and ask services.
   * @param userIntent Query parameter which informs the API what type of search the user wants
   * results for.
   * @param languageTag The localization the user has selected for the API object being returned.
   * @return Successful request
   */
  suspend fun getVideos(
    checkSpelling: Boolean? = null,
    page: Int? = null,
    pageSize: Int? = null,
    query: String,
    userIntent: UserIntent,
    languageTag: String? = null
  ): Videos? = execute("/videos", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = true, queryString = red.platform.http.queryString {
      checkSpelling?.let { add("check_spelling", checkSpelling) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      add("query", query)
      add("user_intent", userIntent.serialName)
      languageTag?.let { add("language_tag", languageTag) }
  }, serializer = Videos.serializer())
}
