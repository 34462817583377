// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.media

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import red.Serializable
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@SerialName("media.AbstractMediaStopEvent")
abstract class AbstractMediaStopEvent : AbstractAnalyticsEvent(), Serializable {
  /**
   * session for this media event
   */
  abstract val mediasessionid: String?

  /**
   * seconds skipped while playing
   */
  abstract val secondsskipped: Int?

  /**
   * seconds played
   */
  abstract val secondsplayed: Int?

  /**
   * position (in seconds) in the stream when stopped
   */
  abstract val position: Int?
}
