package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PlanTextAndHtml(
    @ProtoNumber(2) val html: String? = null,
    @ProtoNumber(1) val text: String? = null
) {
    init {
        freeze()
    }
}
