package youversion.red.geoip.service.js

import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.geoip.model.GeoIP
import youversion.red.geoip.service.IGeoIPService

public object GeoIPService {
  private val service: IGeoIPService by service<youversion.red.geoip.service.IGeoIPService>()

  @JsName(name = "getGeoIP")
  public fun getGeoIP(): Promise<GeoIP?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getGeoIP()

  }.toPromise()
}
