// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model

import kotlin.Boolean
import kotlin.Int
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Progresses(
  /**
   * Array of progress objects.
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<Progress>? = null,
  @SerialName("page_size")
  @ProtoNumber(2)
  @JvmField
  val pageSize: Int? = null,
  @SerialName("next_page")
  @ProtoNumber(3)
  @JvmField
  val nextPage: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
