package youversion.red.stories.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class StoriesSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
