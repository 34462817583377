// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api

import kotlin.Int
import kotlin.String
import kotlin.collections.Map
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.achievements.api.model.Achievement
import youversion.red.achievements.api.model.Achievements
import youversion.red.achievements.api.model.Progress
import youversion.red.achievements.api.model.Progresses
import youversion.red.achievements.api.model.TitleAndDescription
import youversion.red.achievements.api.model.achievements.ProgressPost
import youversion.red.achievements.api.model.achievements.ProgressPostAdmin
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults

/**
 * The achievement service contains all the CRUD API calls a client would need to work with the
 * achievements feature.
 */
object AchievementsApi : AbstractApi(ApiDefaults("achievements", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", AchievementsApiSerializer)) {
  /**
   * This is an unauthenticated list of all available achievements without any user data of progress
   * towards any specific achievements. We also strategically decided to remove pagination from this
   * collection GET so it could have the most efficient caching possible.
   *
   * @param fields A comma separated list of fields to return. This has a different default than
   * standard fields usage. This is due to * being used for internal admin usage. Clients should
   * normally stick with the default. A 400 Bad Request will be returned if given invalid field names.
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @return Successful request.
   */
  suspend fun getAchievements(fields: String? = null, plansLanguageTag: String? = null):
      Achievements = execute("/", method = RequestMethods.GET, version = "4.0", authAllowed = false,
      authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      plansLanguageTag?.let { add("plans_language_tag", plansLanguageTag) }
  }, serializer = Achievements.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Creates a new progress post
   *
   * @return Progress created
   */
  suspend fun addProgress(body: ProgressPost? = null): Progress = execute("/progress", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = ProgressPost.serializer(), serializer = Progress.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param userId The unique identifier for the User.
   * @return Successful request
   */
  suspend fun getProgress(fields: String? = null, userId: Int): Progresses? = execute("/progress",
      method = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false,
      queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      add("user_id", userId)
  }, serializer = Progresses.serializer())

  /**
   * Creates a new progress post (from an admin)
   *
   * @param achievementId The unique identifier for the Achievement resource.
   * @return Progress created
   */
  suspend fun addProgressFromAdmin(achievementId: Int, body: ProgressPostAdmin? = null): Progress =
      execute("in/4.0/achievements/${achievementId}/progress", version = "admin", method =
      RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer =
      ProgressPostAdmin.serializer(), serializer = Progress.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * User id is supplied in the query string
   *
   * @param achievementId The unique identifier for the Achievement resource.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param userId The unique identifier for the User.
   * @return Successful request
   */
  suspend fun getAchievementProgress(
    achievementId: Int,
    fields: String? = null,
    userId: Int
  ): Progress? = execute("/progress/${achievementId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      add("user_id", userId)
  }, serializer = Progress.serializer())

  /**
   * This is an unauthenticated view of a single achievement without any user data or progress.
   *
   * @param achievementId The unique identifier for the Achievement resource.
   * @param fields A comma separated list of fields to return. This has a different default than
   * standard fields usage. This is due to * being used for internal admin usage. Clients should
   * normally stick with the default. A 400 Bad Request will be returned if given invalid field names.
   * @param plansLanguageTag The localization the user has selected for their reading plans.
   * @return Successful request
   */
  suspend fun getAchievement(
    achievementId: Int,
    fields: String? = null,
    plansLanguageTag: String? = null
  ): Achievement? = execute("/${achievementId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      plansLanguageTag?.let { add("plans_language_tag", plansLanguageTag) }
  }, serializer = Achievement.serializer())

  /**
   * @param achievementId The unique identifier for the Achievement resource.
   * @return Localization created
   */
  suspend fun editAchievementLocalizations(achievementId: Int, body: Map<String,
      TitleAndDescription>? = null): Map<String, TitleAndDescription> =
      execute("/${achievementId}:update_localizations", version = "4.0", method =
      RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer =
      kotlinx.serialization.builtins.MapSerializer(String.serializer(),
      TitleAndDescription.serializer()), serializer =
      kotlinx.serialization.builtins.MapSerializer(String.serializer(),
      TitleAndDescription.serializer())) ?: throw
      NullPointerException("Response not sent from server")
}
