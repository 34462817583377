/**
 * @module SortableStack
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { sortDNDArray } from 'utils'

/**
 * The SortableStack component.
 * Drag handle is not supported at this time.
 *
 * @alias module:SortableStack
 *
 * @param {object} props - The component props object.
 * @param {React.ReactChildren} props.children - The list children.
 * @param {(React.ElementType|string)} [props.component] - The wrapper component to use.
 * @param {('horizontal'|'vertical')} [props.direction] - Sort direction. Defaults to 'horizontal'.
 * @param {string} [props.display] - The display prop. Defaults to 'flex'.
 * @param {Function} props.onChange - The onChange handler.
 * @param {(number|string)} [props.spacing] - The `gap` spacing value between items. Only works with `display="flex"`.
 * @param {boolean} [props.useRenderProps] - If true, switches to give you control of the render props pattern.
 *
 * @returns {React.ReactElement} - The SortableStack component.
 *
 * @example
 * // Default behavior
 * import { Card } from '@material-ui/core'
 * import { SortableStack } from '@youversion/react'
 *
 * function MySortableList() {
 *   const [data, setData] = React.useState([{id: 1, name: 'One'}, {id: 2, name: 'One'}, {id:3, name: 'Three'}])
 *
 *   return (
 *     <SortableStack direction="vertical" onChange={setData}>
 *       {data.map((item) => (
 *         <Box key={item.id}>
 *           <p>{item.name}</p>
 *         </Box>
 *       ))}
 *     </SortableStack>
 *   )
 * }
 *
 * @example
 * // With useRenderProps enabled.
 * // You will need to fully implement the provided.draggableProps and provided.dragHandle props in the sortable children.
 * import { Card } from '@material-ui/core'
 * import { SortableStack } from '@youversion/react'
 *
 * function MySortableList() {
 *   const [data, setData] = React.useState([{id: 1, name: 'One'}, {id: 2, name: 'One'}, {id:3, name: 'Three'}])
 *
 *   return (
 *     <SortableStack direction="vertical" onChange={setData} useRenderProps={true}>
 *       {data.map((item) => (
 *         <React.Fragment key={item.id}> // This must be a React component with a key.
 *           {(provided, snapshot) => (
 *             <Box
 *               key={item.id}
 *               innerRef={provided.innerRef}
 *               style={{backgroundColor: snapshot.isDragging ? 'grey' : 'none'}}
 *               {...provided.draggableProps}
 *               {...provided.dragHandleProps}
 *             >
 *               <p>{item.name}</p>
 *             </Box>
 *           )}
 *         </React.Fragment>
 *       ))}
 *     </SortableStack>
 *   )
 * }
 */
export function SortableStack({
  children,
  component,
  direction,
  display,
  onChange,
  spacing,
  useRenderProps,
}) {
  return (
    <DragDropContext
      onDragEnd={(params) => sortDNDArray({ ...params, onChange })}
    >
      <Droppable direction={direction} droppableId="sortable-stack-droppable">
        {(providedDroppable) => (
          <Box
            component={component}
            display={display}
            flexDirection={direction === 'vertical' ? 'column' : 'row'}
            style={{ gap: spacing }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...providedDroppable.droppableProps}
            ref={providedDroppable.innerRef}
          >
            {React.Children.map(children, (child, index) => {
              return (
                <Draggable
                  draggableId={child.key}
                  index={index}
                  key={child.key}
                >
                  {(providedDraggable, snapshot) => {
                    return React.cloneElement(
                      child,
                      useRenderProps
                        ? {
                            children: child.props.children(
                              providedDraggable,
                              snapshot,
                            ),
                          }
                        : {
                            innerRef: providedDraggable.innerRef,
                            ...providedDraggable.draggableProps,
                            ...providedDraggable.dragHandleProps,
                          },
                    )
                  }}
                </Draggable>
              )
            })}
            {providedDroppable.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  )
}

SortableStack.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  component: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  display: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  useRenderProps: PropTypes.bool,
}

SortableStack.defaultProps = {
  component: undefined,
  direction: 'horizontal',
  display: 'flex',
  spacing: 8,
  useRenderProps: false,
}
