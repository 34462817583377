// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The pubsub channel to which messages are sent
 */
enum class TopicType(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  MOMENT_CREATE("moment-create", 1),

  NEW_PLAN_SUBSCRIPTION("new-plan-subscription", 2),

  PLAN_COMPLETION("plan-completion", 3),

  PLAN_DAY_COMPLETION("plan-day-completion", 4),

  NONE("none", 5);
}

internal class TopicTypeSerializer : KSerializer<TopicType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.achievements.api.model.TopicType")

  override fun serialize(encoder: Encoder, value: TopicType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): TopicType {
    if (decoder is JsonDecoder) {
      return TopicType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> TopicType.UNSUPPORTED_PLACEHOLDER
      1 -> TopicType.MOMENT_CREATE
      2 -> TopicType.NEW_PLAN_SUBSCRIPTION
      3 -> TopicType.PLAN_COMPLETION
      4 -> TopicType.PLAN_DAY_COMPLETION
      5 -> TopicType.NONE
      else -> TopicType.UNSUPPORTED_PLACEHOLDER
    }
  }
}
