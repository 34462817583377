package youversion.red.analytics

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.newDate

@Suppress("DEPRECATION")
internal class Test(id: Int, name: String) : MetricsEvent() {

    private val data = TestData(id, name, newDate())

    override val key = "test"
    override val type = CollectorType.Test

    override fun toBytes(): ByteArray = ProtoBuf.encodeToByteArray(TestData.serializer(), data)
}

@Serializable
internal data class TestData(
    @ProtoNumber(2) val id: Int,
    @ProtoNumber(1) val name: String,
    @Serializable(with = DateSerializer::class) @ProtoNumber(3) val created: Date
)
