package youversion.red.login

import red.Intent
import red.RedException
import red.lifecycle.LiveData
import red.lifecycle.MutableLiveData
import red.listeners.Listeners
import red.platform.Log
import red.platform.threads.atomic
import red.platform.threads.atomicNullable
import red.platform.ui.UiHandleFragment
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers.launch
import red.tasks.isMainThread
import red.viewmodel.LoadingBaseViewModel
import youversion.red.geoip.service.GeoIPService
import youversion.red.model.SavedCredentials
import youversion.red.model.UserHint
import youversion.red.security.TokenClass
import youversion.red.security.User
import youversion.red.security.impl.PlatformUsersService
import youversion.red.security.service.UsersService
import youversion.red.users.api.model.CreateAccountMethod
import youversion.red.users.api.model.events.CreateAccount

private const val RESULT_LOGIN_APPLE = 1
private const val RESULT_LOGIN_GOOGLE = 2
private const val RESULT_LOGIN_FACEBOOK = 3

data class PendingUser(
    var firstName: String? = null,
    var lastName: String? = null,
    var email: String? = null,
    var password: String? = null
)

fun TokenClass.createAccountMethod() = when (this) {
    TokenClass.Email -> CreateAccountMethod.EMAIL
    TokenClass.Facebook -> CreateAccountMethod.FACEBOOK
    TokenClass.Google -> CreateAccountMethod.GOOGLE
    TokenClass.Apple -> CreateAccountMethod.APPLE
    else -> CreateAccountMethod.UNKNOWN
}

data class LoginResult(val user: User?, val exception: Exception?)

open class AuthViewModel() : LoadingBaseViewModel() {

    internal val usersService by UsersService()
    private val geoIPService by GeoIPService()

    private val authProgressListeners = Listeners<AuthViewModelListener>()
    private val authLifecycleListeners = Listeners<AuthLifecycleListener>()
    private var authDataProvider by atomicNullable<AuthDataProvider>()

    private var requiresGdpr by atomic(false)
    var hasExtraGdprChecks by atomic(false)
    private var verified by atomic(false)
    private var tokenClass by atomic(TokenClass.Undefined)
    private var canCreateAccount by atomic(false)
    var agreedToEmail by atomic(false)
    var agreedToTermsOfService by atomic(false)
    var meetsAgeRequirement by atomic(false)
    var acknowledgedEmailRequirement by atomic(false)
    var currentAuthViewType by atomic(AuthViewType.SIGN_IN)
        private set
    var pendingUser by atomicNullable<PendingUser>()
        private set
    var savedCredentials by atomicNullable<SavedCredentials>()
    internal var resolvingCredentials by atomic(false)

    internal val loginResultData = MutableLiveData<LoginResult>()
    internal val formInvalidFieldsData = MutableLiveData<Set<FieldValidationError>>()
    internal val userHintData = MutableLiveData<UserHint>()
    val loginResult: LiveData<LoginResult> = loginResultData
    val formInvalidFields: LiveData<Set<FieldValidationError>> = formInvalidFieldsData
    val userHint: LiveData<UserHint> = userHintData

    init {
        async {
            signInWithSavedCredentials()
            checkForPendingUser()
        }
    }

    internal fun showView(authViewType: AuthViewType) {
        currentAuthViewType = authViewType
        launch(CoroutineDispatchContext.Main) {
            authProgressListeners.notifyListeners { it.showAuthView(authViewType) }
        }
    }

    private fun showError(error: AuthError) {
        launch(CoroutineDispatchContext.Main) {
            authProgressListeners.notifyListeners { it.showError(error) }
        }
    }

    private fun formFieldsInvalidKeys(keys: Map<String, String>) {
        val fields = mutableSetOf<FieldValidationError>()
        keys.forEach {
            when (it.key) {
                "users.email.not_available" -> FieldValidationError.EMAIL_NOT_AVAILABLE
                "users.auth_username.required" -> FieldValidationError.EMAIL_INVALID
                "users.password.length",
                "users.password.less_than_minimum_value_6",
                "users.auth_password.required" -> FieldValidationError.PASSWORD_LENGTH
                else -> null
            }?.let { fields.add(it) }
        }
        launch(CoroutineDispatchContext.Main) {
            formInvalidFieldsData.setValue(fields)
            authProgressListeners.notifyListeners { it.formFieldValidationError(fields) }
        }
    }

    private fun formFieldsInvalid(fields: Set<FieldValidationError>) =
        launch(CoroutineDispatchContext.Main) {
            formInvalidFieldsData.setValue(fields)
            authProgressListeners.notifyListeners { it.formFieldValidationError(fields) }
        }

    private fun removeFormInvalidField(field: FieldValidationError) {
        val fields = formInvalidFieldsData.getValue()?.toMutableSet() ?: return
        fields.remove(field)
        if (isMainThread)
            formInvalidFieldsData.setValue(fields)
        else
            formInvalidFieldsData.postValue(fields)

        launch(CoroutineDispatchContext.Main) {
            authProgressListeners.notifyListeners { it.formFieldValidationError(fields) }
        }
    }

    private fun addFormInvalidField(field: FieldValidationError) {
        val fields = formInvalidFieldsData.getValue()?.toMutableSet() ?: mutableSetOf()
        fields.add(field)
        if (isMainThread)
            formInvalidFieldsData.setValue(fields)
        else
            formInvalidFieldsData.postValue(fields)

        launch(CoroutineDispatchContext.Main) {
            authProgressListeners.notifyListeners { it.formFieldValidationError(fields) }
        }
    }

    internal fun updateUserHint(hint: UserHint?) {
        if (isMainThread)
            userHintData.setValue(hint)
        else
            userHintData.postValue(hint)
    }

    private suspend fun updateGDPRState() {
        try {
            val geoIp = geoIPService.getGeoIP()
            requiresGdpr = geoIp?.gdpr == true
            hasExtraGdprChecks = geoIp?.countryCode == "KR"
        } catch (e: RedException) {
            Log.e("red-login", "Error getting GDPR status: $e")
        }
    }

    fun canContinueWithGDPR(): Boolean {
        return if (hasExtraGdprChecks) {
            agreedToTermsOfService && meetsAgeRequirement && acknowledgedEmailRequirement
        } else {
            agreedToTermsOfService
        }
    }

    private suspend fun checkForPendingUser() {
        usersService.getCurrentUser() ?: return
        try {
            usersService.checkAccountConfirmation()
        } catch (error: RedException) {
            error.keys.keys.forEach { key ->
                when (key) {
                    "users.hash.not_verified",
                    "users.user.not_verified" -> {
                        showView(AuthViewType.PENDING_EMAIL)
                    }
                    else -> usersService.logout()
                }
            }
        }
    }

    suspend fun continueWithEmail() {
        tokenClass = TokenClass.Email

        if (currentAuthViewType == AuthViewType.SIGN_UP) {
            updateGDPRState()
            showView(if (requiresGdpr) AuthViewType.GDPR else AuthViewType.SIGN_UP_FORM)
        } else {
            showView(AuthViewType.SIGN_IN_FORM)
        }
    }

    suspend fun continueWithApple(fragment: UiHandleFragment) {
        continueWithThirdParty(TokenClass.Apple) {
            usersService.loginWithApple(
                RESULT_LOGIN_APPLE,
                fragment
            )
        }
    }

    suspend fun continueWithFacebook(fragment: UiHandleFragment) {
        continueWithThirdParty(TokenClass.Facebook) {
            usersService.loginWithFacebook(
                RESULT_LOGIN_FACEBOOK,
                fragment
            )
        }
    }

    suspend fun continueWithGoogle(fragment: UiHandleFragment) {
        continueWithThirdParty(TokenClass.Google) {
            usersService.loginWithGoogle(
                RESULT_LOGIN_GOOGLE,
                fragment
            )
        }
    }

    private suspend fun continueWithThirdParty(token: TokenClass, login: suspend () -> User?) = try {
        tokenClass = token
        onUser(login())
    } catch (err: RedException) {
        canCreateAccount = err.errorCode == 403 && err.keys.keys.contains("users.token.invalid")
        onUser(null, exception = err)
    }

    fun shouldAllowCurrentFormComplete(
        firstName: String? = null,
        lastName: String? = null,
        email: String? = null,
        password: String? = null
    ) = shouldAllowFormComplete(currentAuthViewType, firstName, lastName, email, password)

    fun shouldAllowFormComplete(
        authViewType: AuthViewType,
        firstName: String? = null,
        lastName: String? = null,
        email: String? = null,
        password: String? = null
    ) = when (authViewType) {
        AuthViewType.FORGOT_PASSWORD -> !email.isNullOrEmpty()
        AuthViewType.UPDATE_MASKED_EMAIL,
        AuthViewType.UPDATE_EMAIL -> isEmailValid(email)
        AuthViewType.UPDATE_INFO -> !firstName.isNullOrEmpty() && !lastName.isNullOrEmpty()
        AuthViewType.SIGN_IN_FORM -> !email.isNullOrEmpty() && !password.isNullOrEmpty()
        AuthViewType.SIGN_UP_FORM -> !firstName.isNullOrEmpty() && !lastName.isNullOrEmpty() && isEmailValid(email) && isPasswordValid(
            password
        )
        else -> false
    }

    private fun isEmailValid(email: String?) = email?.let {
        Regex(
            "[a-zA-Z0-9\\+\\.\\_\\%\\-\\+]{1,256}" +
                    "\\@" +
                    "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}" +
                    "(" +
                    "\\." +
                    "[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25}" +
                    ")+"
        ).matches(it)
    } ?: false

    private fun isPasswordValid(password: String?) = password?.let { it.length >= 6 } ?: false

    fun validateEmail(email: String?) = validateFieldWithError(FieldValidationError.EMAIL_INVALID, email) {
        isEmailValid(email)
    }

    fun validatePassword(password: String?) = validateFieldWithError(FieldValidationError.PASSWORD_LENGTH, password) {
        isPasswordValid(password)
    }

    private fun validateFieldWithError(error: FieldValidationError, text: String?, valid: () -> Boolean) =
        valid().also {
            if (it || text.isNullOrBlank()) removeFormInvalidField(error) else addFormInvalidField(error)
        }

    suspend fun signIn(
        firstName: String? = null,
        lastName: String? = null,
        email: String,
        password: String,
        savedCredentials: Boolean = false
    ) {
        val emailTrimmed = email.trim()
        try {
            canCreateAccount = currentAuthViewType == AuthViewType.SIGN_UP_FORM
            pendingUser = PendingUser(
                firstName,
                lastName,
                emailTrimmed,
                password
            )
            if (canCreateAccount && !savedCredentials) {
                if (!validateEmail(emailTrimmed) && !validatePassword(password)) return
                createAccount()
            } else {
                onUser(
                    usersService.login(
                        emailTrimmed,
                        password
                    )
                )
            }
        } catch (err: RedException) {
            onUser(null, exception = err)
        }
    }

    internal suspend fun signInWithSavedCredentials(credentials: SavedCredentials? = null) {
        savedCredentials = (credentials ?: usersService.getCredentials()).let {
            val email = it.email ?: return
            val password = it.password ?: return
            signIn(email = email, password = password, savedCredentials = true)
            it
        }
    }

    private suspend fun checkGDPRAndCreateAccount() {
        updateGDPRState()

        if (requiresGdpr && !agreedToTermsOfService) {
            showView(AuthViewType.GDPR)
        } else {
            createAccount()
        }
    }

    private suspend fun createAccount() {
        val tosAgreed = !requiresGdpr || agreedToTermsOfService
        val canSendEmail = !requiresGdpr || agreedToEmail
        try {
            val languageTag = authDataProvider?.getLanguageTag()
                ?: throw RedException("Language Tag Required")
            val user: User?
            if (tokenClass == TokenClass.Email) {
                verified = false
                user = usersService.create(
                    pendingUser?.email ?: throw RedException("Must provide email"),
                    pendingUser?.firstName ?: throw RedException("Must provide first name"),
                    pendingUser?.lastName ?: throw RedException("Must provide last name"),
                    pendingUser?.password ?: throw RedException("Must provide password"),
                    false,
                    languageTag,
                    tosAgreed,
                    canSendEmail,
                    requiresGdpr
                )
            } else {
                verified = true
                user = usersService.createThirdParty(
                    pendingUser?.email,
                    pendingUser?.firstName,
                    pendingUser?.lastName,
                    tokenClass,
                    tosAgreed,
                    languageTag,
                    canSendEmail,
                    requiresGdpr
                )
            }
            onUser(user)
            logEvent()
            launch(CoroutineDispatchContext.Main) {
                authLifecycleListeners.notifyListeners {
                    it.onAccountCreated()
                }
            }
        } catch (e: RedException) {
            onUser(null, e)
        }
    }

    private fun logEvent() = authDataProvider?.let {
        CreateAccount(
            it.getAuthReferrer(),
            tokenClass.createAccountMethod(),
            it.getPlansReferrer(),
            it.getOfflineDownloadReferrer()
        ).log()
    }

    suspend fun finishGDPR() {
        if (agreedToTermsOfService) {
            if (tokenClass == TokenClass.Email) {
                showView(AuthViewType.SIGN_UP_FORM)
            } else {
                createAccount()
            }
        }
    }

    suspend fun updateMaskedEmail(email: String) {
        if (!isEmailValid(email)) {
            formFieldsInvalid(setOf(FieldValidationError.EMAIL_INVALID))
            return
        }
        try {
            usersService.updateEmail(email, "override_masked_email")?.let {
                pendingUser = PendingUser(email = it.email)
                showView(AuthViewType.PENDING_EMAIL)
            }
        } catch (err: RedException) {
            onUser(null, exception = err)
        }
    }

    suspend fun sendForgotPasswordConfirmation(usernameOrEmail: String) {
        try {
            usersService.forgotPassword(usernameOrEmail)?.let {
                pendingUser = PendingUser(email = it.email)
                showView(AuthViewType.PENDING_PASSWORD_EMAIL)
            } ?: throw RedException("No account found with the provided username or email", errorCode = 404, keys = mapOf("users.email_or_username.not_found" to ""))
        } catch (err: RedException) {
            onUser(null, exception = err)
        }
    }

    suspend fun finishCreatingAccount(firstName: String?, lastName: String?, email: String?) {
        pendingUser = PendingUser(
            firstName ?: pendingUser?.firstName,
            lastName ?: pendingUser?.lastName,
            email ?: pendingUser?.email
        )
        createAccount()
    }

    fun switchSignInSignUp() {
        showView(
            if (currentAuthViewType == AuthViewType.SIGN_IN)
                AuthViewType.SIGN_UP
            else
                AuthViewType.SIGN_IN
        )
    }

    suspend fun restartAuth() {
        verified = false
        cancelForm()

        try {
            usersService.logout()
        } catch (ignore: Exception) {
        }
    }

    fun cancelForm() {
        showView(
            if (currentAuthViewType == AuthViewType.SIGN_UP_FORM || currentAuthViewType == AuthViewType.GDPR || currentAuthViewType == AuthViewType.PENDING_EMAIL)
                AuthViewType.SIGN_UP
            else
                AuthViewType.SIGN_IN
        )
    }

    suspend fun getPendingEmail() = pendingUser?.email ?: usersService.getCurrentUser()?.email ?: ""

    suspend fun resendAccountVerification(email: String? = null) = try {
        val confirmationEmail = email ?: getPendingEmail()
        usersService.resendConfirmation(confirmationEmail)
    } catch (err: RedException) {
        onUser(null, exception = err)
    }

    fun forgotPassword() = showView(AuthViewType.FORGOT_PASSWORD)

    fun onBackPressed(): Boolean {
        var setNewView = true
        if (loginResultData.getValue()?.user?.id ?: 0 > 0) {
            cancelForm()
        } else {
            when (currentAuthViewType) {
                AuthViewType.SIGN_IN_FORM -> showView(AuthViewType.SIGN_IN)
                AuthViewType.SIGN_UP_FORM -> showView(if (requiresGdpr) AuthViewType.GDPR else AuthViewType.SIGN_UP)
                AuthViewType.FORGOT_PASSWORD -> showView(AuthViewType.SIGN_IN_FORM)
                AuthViewType.GDPR -> showView(AuthViewType.SIGN_UP)
                AuthViewType.PENDING_PASSWORD_EMAIL -> showView(AuthViewType.SIGN_IN_FORM)
                else -> setNewView = false
            }
        }
        updateUserHint(null)
        formFieldsInvalid(setOf())
        return setNewView
    }

    internal suspend fun handleLoginResult(request: Int, result: Int, intent: Intent) {
        try {
            when (request) {
                RESULT_LOGIN_APPLE -> {
                    onUser(
                        usersService.loginWithAppleResult(
                            request,
                            result,
                            intent
                        ), null
                    )
                }
                RESULT_LOGIN_GOOGLE -> {
                    usersService.loginWithGoogleResult(
                        request,
                        result,
                        intent
                    ).let {
                        onUser(it.user)
                    }
                }
                // Hack: Facebook uses its own generated request code
                else -> onUser(
                    usersService.loginWithFacebookResult(
                        request,
                        result,
                        intent
                    ), null
                )
            }
        } catch (err: RedException) {
            canCreateAccount = err.errorCode == 403 && err.keys.keys.contains("users.token.invalid")
            onUser(null, exception = err)
        }
    }

    private suspend fun onUser(user: User?, exception: RedException? = null) {
        user?.let {
            if (canCreateAccount) {
                if (it.hasMaskedEmail == true) {
                    showView(AuthViewType.UPDATE_MASKED_EMAIL)
                    return
                } else if (!verified) {
                    showView(AuthViewType.PENDING_EMAIL)
                    return
                }
            }
            saveCredentials(it, pendingUser?.password)
        }
        exception?.let {
            when (it.errorCode) {
                401 -> {
                    restartAuth()
                    return
                }
                400, 403 -> {
                    it.keys.keys.forEach { key ->
                        when (key) {
                            "users.first_name.required",
                            "users.last_name.required" -> {
                                showView(AuthViewType.UPDATE_INFO)
                                return
                            }
                            "users.third_party_email.required" -> {
                                verified = false
                                showView(AuthViewType.UPDATE_EMAIL)
                                return
                            }
                            "users.token.invalid" -> {
                                if (canCreateAccount) {
                                    checkGDPRAndCreateAccount()
                                    return
                                }
                            }
                            "users.hash.not_verified",
                            "users.user.not_verified" -> {
                                showView(AuthViewType.PENDING_EMAIL)
                                return
                            }
                            "users.hash.verified" -> {
                                showError(AuthError.ACCOUNT_ALREADY_VERIFIED)
                                return
                            }
                            "users.username.invalid" -> {
                                showError(AuthError.USERNAME_INVALID)
                                return
                            }
                            "users.password.invalid" -> {
                                showError(AuthError.PASSWORD_INVALID)
                                return
                            }
                            "users.auth_username.required",
                            "users.password.length",
                            "users.password.less_than_minimum_value_6",
                            "users.email.not_available",
                            "users.auth_password.required" -> {
                                formFieldsInvalidKeys(it.keys)
                                return
                            }
                        }
                    }
                }
            }
        }

        loginResultData.postValue(
            LoginResult(
                user,
                exception
            )
        )
    }

    private suspend fun saveCredentials(user: User, password: String?) {
        if (authDataProvider?.shouldSaveCredentials() == false) {
            return
        }

        if (tokenClass == TokenClass.Google) {
            usersService.saveCredentials(
                user.email ?: "",
                null,
                true,
                user.name,
                user.userAvatarUrl?.px128
            )
        } else if (tokenClass == TokenClass.Email) {
            val email = pendingUser?.email ?: user.email ?: return
            val pw = password ?: return

        if (PlatformUsersService.supportsGoogle())
            usersService.saveCredentials(
                email,
                pw,
                false,
                null,
                null
            )
        }
    }

    fun registerListener(listener: AuthViewModelListener) {
        authProgressListeners.addListener(listener)
    }

    fun unregisterListener(listener: AuthViewModelListener) {
        authProgressListeners.removeListener(listener)
    }

    fun registerAuthLifecycleListener(listener: AuthLifecycleListener) {
        authLifecycleListeners.addListener(listener)
    }

    fun unregisterAuthLifecycleListener(listener: AuthLifecycleListener) {
        authLifecycleListeners.removeListener(listener)
    }

    fun registerDataProvider(dataProvider: AuthDataProvider) {
        authDataProvider = dataProvider
    }

    fun unregisterDataProvider() {
        authDataProvider = null
    }
}
