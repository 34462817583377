package youversion.red.installation

import red.service.ExportedService
import red.service.IService
import red.service.service

fun InstallationService() = service<IInstallationService>()

@ExportedService
interface IInstallationService : IService {

    suspend fun install()
    val installationId: String?
}
