// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Ping(
  /**
   * List of unavailable services.
   */
  @SerialName("down")
  @ProtoNumber(1)
  @JvmField
  val down: List<String>? = null,
  /**
   * List of available services.
   */
  @SerialName("up")
  @ProtoNumber(2)
  @JvmField
  val up: List<String>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
