package youversion.red.prayer.guided.service

import red.platform.now
import red.platform.toCalendar
import youversion.red.prayer.guided.model.GuidedPrayerColors

class GuidedPrayerColorBuilder {
    enum class Mode { DARK, LIGHT }
    enum class ColorNames { GREEN, YELLOW, PURPLE, TEAL, BLUE, ORANGE, MAGENTA }
    enum class ColorWeights { W10, W30, W40, W50 }

    private val dayOfYear = now().toCalendar().dayOfYear
    private val colorCount = ColorNames.values().size
    private val curDayInt = dayOfYear % colorCount
    private val colorOfTheDay: ColorNames = ColorNames.values()[curDayInt]

    // Ordered by ColorWeights
    private val green = arrayOf(
        "#EEF6F0",
        "#57A16C",
        "#346148",
        "#1A3026"
    )

    // Ordered by ColorWeights
    private val yellow = arrayOf(
        "#FEF5EB",
        "#F09728",
        "#975C11",
        "#4C2D08"
    )

    // Ordered by ColorWeights
    private val purple = arrayOf(
        "#F2EEF3",
        "#79518A",
        "#483550",
        "#241829"
    )

    // Ordered by ColorWeights
    private val teal = arrayOf(
        "#E6F2F3",
        "#007B89",
        "#004A52",
        "#004A52"
    )

    // Ordered by ColorWeights
    private val blue = arrayOf(
        "#E7F2FD",
        "#3D79C2",
        "#3D79C2",
        "#1C2A3B"
    )

    // Ordered by ColorWeights
    private val orange = arrayOf(
        "#FAECEB",
        "#CA3E35",
        "#7A2629",
        "#3D1310"
    )

    // Ordered by ColorWeights
    private val magenta = arrayOf(
        "#F5EAEE",
        "#992C55",
        "#5C1A33",
        "#2E0D19"
    )

    // Ordered by ColorNames
    private val colorMap = arrayOf(
        green,
        yellow,
        purple,
        teal,
        blue,
        orange,
        magenta
    )

    private fun guidedPrayerColors(mode: Mode = Mode.DARK): GuidedPrayerColors {
        return GuidedPrayerColors(
            w10 = colorMap[colorOfTheDay.ordinal][ColorWeights.W10.ordinal],
            w30 = colorMap[colorOfTheDay.ordinal][ColorWeights.W30.ordinal],
            w40 = colorMap[colorOfTheDay.ordinal][ColorWeights.W40.ordinal],
            w50 = colorMap[colorOfTheDay.ordinal][ColorWeights.W50.ordinal],
            dayOfYear = dayOfYear,
            colorCount = colorCount,
            curDayInt = curDayInt,
            mode = mode
        )
    }

    fun guidedPrayerColors(isDark: Boolean): GuidedPrayerColors {
        return guidedPrayerColors(if (isDark) Mode.DARK else Mode.LIGHT)
    }
}
