package youversion.red.prayer.guided.service

import red.lifecycle.LiveData
import red.lifecycle.MutableLiveData
import red.platform.Log
import red.platform.localization.Locale
import red.platform.localization.LocaleContext
import red.platform.localization.Locales
import red.platform.localization.LocalizationKey
import red.platform.localization.Localizer
import red.platform.localization.resolve
import red.platform.newDate
import red.platform.now
import red.platform.threads.atomicNullable
import red.platform.threads.freeze
import red.platform.toCalendar
import red.platform.toDateTime
import red.platform.toLong
import red.platform.toMillis
import red.service.DefaultService
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers.launch
import red.tasks.CoroutineDispatchers.withIO
import red.tasks.assertNotMainThread
import youversion.red.blue.state.GuidedPrayerState
import youversion.red.guidedprayer.api.GuidedPrayerApi
import youversion.red.guidedprayer.api.model.GuidedPrayerMusic
import youversion.red.prayer.guided.model.BackgroundAudioVariation
import youversion.red.prayer.guided.model.FullPrayerListDiskCache
import youversion.red.prayer.guided.model.GuideModuleItems
import youversion.red.prayer.guided.model.GuidedPrayerColors
import youversion.red.prayer.guided.model.GuidedPrayerDay
import youversion.red.prayer.guided.model.GuidedPrayerGuide
import youversion.red.prayer.guided.model.IBackgroundAudioVariation
import youversion.red.prayer.guided.model.PrayerListCandidates
import youversion.red.prayer.guided.model.RandomVariation
import youversion.red.prayer.guided.model.SeedWithSeen
import youversion.red.prayer.guided.model.SeedWithUnseen
import youversion.red.prayer.guided.model.SessionDiskCache
import youversion.red.prayer.guided.tasks.DaysSync
import youversion.red.prayer.guided.tasks.GuideSync
import youversion.red.prayer.guided.tasks.ModulesSync
import youversion.red.prayer.guided.tasks.PrayerListItemsSync
import youversion.red.prayer.guided.tasks.calculateFutureDaysOfYear
import youversion.red.prayer.guided.util.GuidedPrayerPreferences
import youversion.red.prayer.service.PrayerService
import youversion.red.prayer.util.PrayerUtil

@DefaultService(IGuidedPrayerService::class)
internal class GuidedPrayerServiceImpl : IGuidedPrayerService {

    private val prayerService by PrayerService()

    private suspend fun syncGuideIfNeeded(guideId: Int) {
        try {
            withIO {
                GuideSync.process(guideId)
            }
        } catch (e: Exception) {
            Log.e("guided-prayer", "error syncing guide", e)
        }
    }

    private suspend fun syncDaysIfNeeded(dayOfYear: Int, guideId: Int) {
        try {
            withIO {
                syncBatch(dayOfYear) {
                    DaysSync.process(guideId, it)
                }
            }
        } catch (e: Exception) {
            Log.e("guided-prayer", "error syncing days", e)
        }
    }

    private suspend fun syncModulesIfNeeded(dayOfYear: Int, guideId: Int) {
        try {
            withIO {
                syncBatch(dayOfYear) {
                    ModulesSync.process(guideId, it)
                }
            }
        } catch (e: Exception) {
            Log.e("guided-prayer", "error syncing modules", e)
        }
    }

    override suspend fun getGuide(guideId: Int): GuidedPrayerGuide? = withIO {
        syncGuideIfNeeded(guideId)
        GuidedPrayerStore.getGuide(guideId)
    }

    override fun getGuideLiveData(guideId: Int): LiveData<GuidedPrayerGuide> {
        launch(CoroutineDispatchContext.Network) {
            syncGuideIfNeeded(guideId)
        }
        return GuidedPrayerStore.getGuideLiveData(guideId)
    }

    override fun getDayLiveData(guideId: Int, dayOfYear: Int): LiveData<GuidedPrayerDay> {
        launch(CoroutineDispatchContext.Network) {
            syncGuideIfNeeded(guideId)
            syncDaysIfNeeded(dayOfYear = dayOfYear, guideId = guideId)
        }
        return GuidedPrayerStore.getDayLiveData(guideId = guideId, id = dayOfYear)
    }

    override suspend fun getDay(guideId: Int, dayOfYear: Int): GuidedPrayerDay? {
        syncGuideIfNeeded(guideId)
        syncDaysIfNeeded(dayOfYear = dayOfYear, guideId = guideId)
        return GuidedPrayerStore.getDay(guideId = guideId, id = dayOfYear)
    }

    override suspend fun getModules(
        guideId: Int,
        dayOfYear: Int
    ): GuideModuleItems {
        syncGuideIfNeeded(guideId)
        syncDaysIfNeeded(dayOfYear = dayOfYear, guideId = guideId)
        syncModulesIfNeeded(dayOfYear = dayOfYear, guideId = guideId)
        return syncModulesWithPrayers(guideId, dayOfYear)
    }

    override fun getModulesLiveData(
        guideId: Int,
        dayOfYear: Int
    ): LiveData<GuideModuleItems> {
        val liveData = MutableLiveData<GuideModuleItems>()
        launch(CoroutineDispatchContext.IO) {
            liveData.postValue(getModules(guideId, dayOfYear))
        }
        return liveData
    }

    private suspend fun syncModulesWithPrayers(
        guideId: Int,
        dayOfYear: Int
    ): GuideModuleItems = withIO {
        val syncedModules = GuidedPrayerStore.getModulesForDay(guideId, dayOfYear)
        if (syncedModules.isNullOrEmpty())
            throw NullPointerException("No modules available for syncing with prayer list")

        val newPrayerWindow = PrayerUtil.prayActionEnabledForDay("guide_$guideId")
        if (newPrayerWindow)
            SessionDiskCache.clear()

        PrayerUtil.setLastPrayedTime("guide_$guideId", now())

        val prayerList = prayerService.getPrayerListSync()
        val candidates = PrayerListCandidates(
            prayerList.map { it.clientId },
            SessionDiskCache,
            FullPrayerListDiskCache
        )
        val guideModuleItems = PrayerListItemsSync(
            modules = syncedModules,
            prayerList = prayerList,
            candidates = candidates,
            prayerItemSeedingStrategy = if (newPrayerWindow)
                SeedWithUnseen()
            else
                SeedWithSeen(
                    sessionCache = SessionDiskCache,
                    prayerListCycleCache = FullPrayerListDiskCache
                )
        ).moduleItems
        with(guideModuleItems) {
            seed(prayerList)
        }
        guideModuleItems
    }

    private suspend fun syncBatch(
        dayOfYear: Int,
        syncBlock: suspend (daysToSync: List<Int>) -> Unit
    ) = withIO {
        syncBlock.freeze()

        // prioritize dayOfYear, then sync future days in another thread
        val startDate = newDate().toCalendar().apply {
            this.dayOfYear = dayOfYear
        }.toDate()
        val prioritizedDay = calculateFutureDaysOfYear(startDate, 1)
        syncBlock(prioritizedDay)

        launch(CoroutineDispatchContext.Network) {
            val nextDate = newDate((startDate.toMillis().toLong() + ONE_DAY).toDateTime())
            val futureDays = calculateFutureDaysOfYear(nextDate, DAYS_CACHE - 1)
            syncBlock(futureDays)
        }
    }

    override suspend fun getMusic(): GuidedPrayerMusic = withIO {
        GuidedPrayerApi.getMusic()
    }

    private var variationsCache: List<IBackgroundAudioVariation>? by atomicNullable()
    override suspend fun getVariations(): List<IBackgroundAudioVariation> = withIO {
        variationsCache?.let {
            return@withIO it
        }
        val apiVariations = getMusic().data
        // todo get localized string
        (apiVariations.map {
            BackgroundAudioVariation(
                it.title,
                it.id,
                it.url,
                it.variations
            )
        } + arrayListOf(
            RandomVariation(
                Localizer.provider?.localizedString(LocalizationKey.RANDOM) ?: "Random", -1)
        ).freeze()).also {
            variationsCache = it.also {
                freeze()
            }
        }
    }

    private val _selectedVariation: MutableLiveData<IBackgroundAudioVariation> =
        object : MutableLiveData<IBackgroundAudioVariation>() {
            override fun onActive() {
                super.onActive()
                launch(CoroutineDispatchContext.IO) {
                    val variations = variationsCache ?: getVariations()
                    val selected = variations.find { it.title == GuidedPrayerPreferences.currentBackgroundMusic }
                        ?: variations.find { it is RandomVariation } ?: variations.firstOrNull()
                    selected?.let {
                        postValue(it)
                    }
                }
            }

            override fun postValue(value: IBackgroundAudioVariation?) {
                super.postValue(value)
                updateSettings(value)
            }

            override fun setValue(value: IBackgroundAudioVariation?) {
                super.setValue(value)
                updateSettings(value)
            }

            private fun updateSettings(variation: IBackgroundAudioVariation?) {
                GuidedPrayerPreferences.currentBackgroundMusic = variation?.title
            }
        }

    override val selectedVariation: LiveData<IBackgroundAudioVariation> = _selectedVariation

    override fun selectVariation(variation: IBackgroundAudioVariation) {
        _selectedVariation.setValue(variation)
    }

    private val _isAudioOptionShown: MutableLiveData<Boolean> = object : MutableLiveData<Boolean>() {
        override fun onActive() {
            super.onActive()
            setValue(GuidedPrayerPreferences.isAudioOptionShown)
        }

        override fun postValue(value: Boolean?) {
            super.postValue(value)
            updateSettings(value)
        }

        override fun setValue(value: Boolean?) {
            super.setValue(value)
            updateSettings(value)
        }

        private fun updateSettings(shown: Boolean?) {
            GuidedPrayerPreferences.isAudioOptionShown = shown == true
        }
    }

    override val isAudioOptionShown: LiveData<Boolean> = _isAudioOptionShown

    override fun setAudioOptionShown(shown: Boolean) {
        _isAudioOptionShown.setValue(shown)
    }

    override suspend fun clearCache() = withIO {
        FullPrayerListDiskCache.clear()
        SessionDiskCache.clear()
        deleteAllDataSync()
        GuidedPrayerPreferences.clear()
    }

    override fun getColors(isDark: Boolean): GuidedPrayerColors {
        return GuidedPrayerColorBuilder().guidedPrayerColors(isDark)
    }

    override suspend fun isSupported(guideId: Int): Boolean =
        isSupportedWithLocale(guideId, Locales.resolve(LocaleContext.DEFAULT).firstOrNull() ?: Locales.getDefault())

    override suspend fun isSupportedWithLocale(guideId: Int, locale: Locale): Boolean = withIO {
        if (!GuidedPrayerState.enabled) {
            Log.w("GuidedPrayerService", "Blue says guided prayer IS NOT enabled.")
            return@withIO false
        } else {
            Log.w("GuidedPrayerService", "Blue says guided prayer IS enabled.")
        }
        val supportedTags = getGuide(guideId)?.languageTags
        if (supportedTags == null) {
            Log.w("GuidedPrayerService", "Failed to find the languages tags for the guide: $guideId")
            return@withIO false
        }
        val supported = supportedTags.contains(locale.getApiTag2())
        if (!supported) {
            Log.w("GuidedPrayerService", "The guide ($guideId) DOES NOT support the language ${locale.getApiTag2()} in $supportedTags")
        } else {
            Log.w("GuidedPrayerService", "The guide ($guideId) DOES support the language ${locale.getApiTag2()} in $supportedTags")
        }
        supported
    }

    private fun deleteAllDataSync() {
        assertNotMainThread()
        GuidedPrayerStore.deleteAllGuides()
        GuidedPrayerStore.deleteAllDays()
        GuidedPrayerStore.deleteAllModules()
    }

    companion object {
        private const val DAYS_CACHE = 10
        private const val ONE_DAY = 86_400_000L
    }
}
