/**
 * @module calcCurrentPlanDay
 */

import moment from 'moment'

/**
 * If no day is passed to a subscription then we want to figure out
 * what day to start on based on the date and the start date of the plan.
 *
 * @alias module:calcCurrentPlanDay
 *
 * @param {object} params
 * @param  {number} params.totalDays - [total days in plan].
 * @param  {number} params.startDt -   [start date of plan].
 * @returns {number}            [current day of plan].
 */
export function calcCurrentPlanDay({ totalDays, startDt }) {
  const calculatedDay =
    Math.abs(
      parseInt(moment().diff(moment(startDt, 'YYYY-MM-DD'), 'days'), 10),
    ) + 1
  let currentDay
  if (calculatedDay > totalDays) {
    currentDay = totalDays
  } else {
    currentDay = calculatedDay
  }

  if (Number.isNaN(currentDay)) {
    return 1
  }
  return currentDay
}
