package youversion.red.churches.model

import red.platform.threads.freeze

interface BaseOrganizationProfile {
    val id: String
    val organizationId: String
}

data class ChurchProfile(
    override val id: String,
    override val organizationId: String,
    val denomination: String,
    val pastor: Pastor,
    val locationCount: Int
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class LocationProfile(
    override val id: String,
    override val organizationId: String,
    val addressLine1: String,
    val addressLine2: String,
    val longitude: Float,
    val latitude: Float,
    val city: String,
    val state: String,
    val postalCode: String,
    val country: String,
    val timezone: String
) : BaseOrganizationProfile {
    init {
        freeze()
    }
}

data class Pastor(
    val id: String,
    val name: String,
    val churchProfileId: String,
    val title: String,
    val imageUrl: String
) {
    init {
        freeze()
    }
}
