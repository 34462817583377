package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PlansImageSize(
    @ProtoNumber(1) val width: Int = 0,
    @ProtoNumber(2) val height: Int = 0
) {
    init {
        freeze()
    }
}

@Serializable
data class PlansImageSizes(
    @ProtoNumber(1) val thumbnails: List<PlansImageSize> = emptyList(),
    @ProtoNumber(2) val standard: List<PlansImageSize> = emptyList()
) {
    init {
        freeze()
    }
}

@Serializable
data class PlansConfiguration(
    @ProtoNumber(2) val images: PlansConfigurationImage,
    @ProtoNumber(3) val languageTags: List<String>,
    @ProtoNumber(4) val maxPageSize: Int,
    @ProtoNumber(5) val content: PlansConfigurationContent,
    @ProtoNumber(6) val additionalContentAudioUrl: String,
    @ProtoNumber(7) val updateStartDtTimer: Int,
    @ProtoNumber(8) val maxCommentLength: Int,
    @ProtoNumber(9) val maxParticipants: Int
) {
    init {
        freeze()
    }
}

@Serializable
data class PlansConfigurationContent(
    @ProtoNumber(1) val brotli: String? = null,
    @ProtoNumber(2) val zlib: String? = null
)

@Serializable
data class PlansConfigurationImage(
    @ProtoNumber(1) val collections: PlansConfigurationImageUrl,
    @ProtoNumber(2) val plans: PlansConfigurationImageUrl
) {
    init {
        freeze()
    }
}

@Serializable
data class PlansConfigurationImageUrl(
    @ProtoNumber(1) val url: String,
    @ProtoNumber(2) val sizes: PlansImageSizes
) {
    init {
        freeze()
    }
}
