package youversion.red.versification.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class VersificationSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
