package youversion.red.settings.module

import red.platform.http.URL
import youversion.red.deeplink.DeepLinkContext
import youversion.red.deeplink.DeepLinkHandledListener
import youversion.red.settings.api.model.settings.OnDeepLinkEvent

object AnalyticsDeepLinkHandledListener : DeepLinkHandledListener {
    override fun onHandled(context: DeepLinkContext, url: URL) {
        logDeepLinkHandled(context, url)
    }

    fun logDeepLinkHandled(context: DeepLinkContext, url: URL) {
        OnDeepLinkEvent(deeplink = url.toString(), referrer = context.referrer).log()
    }
}
