// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Abbreviation of the canon section represented. Either, AP, NT, or OT.
 */
enum class SearchCanonEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  NT("nt", 1),

  OT("ot", 2),

  AP("ap", 3);
}

internal class SearchCanonEnumSerializer : KSerializer<SearchCanonEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.SearchCanonEnum")

  override fun serialize(encoder: Encoder, value: SearchCanonEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): SearchCanonEnum {
    if (decoder is JsonDecoder) {
      return SearchCanonEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> SearchCanonEnum.UNSUPPORTED_PLACEHOLDER
      1 -> SearchCanonEnum.NT
      2 -> SearchCanonEnum.OT
      3 -> SearchCanonEnum.AP
      else -> SearchCanonEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
