// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Do you want to access all prayers or just answered ones.
 */
enum class PrayerAnswer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALL("all", 1),

  ANSWERED("answered", 2);
}

internal class PrayerAnswerSerializer : KSerializer<PrayerAnswer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.prayer.api.model.PrayerAnswer")

  override fun serialize(encoder: Encoder, value: PrayerAnswer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): PrayerAnswer {
    if (decoder is JsonDecoder) {
      return PrayerAnswer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> PrayerAnswer.UNSUPPORTED_PLACEHOLDER
      1 -> PrayerAnswer.ALL
      2 -> PrayerAnswer.ANSWERED
      else -> PrayerAnswer.UNSUPPORTED_PLACEHOLDER
    }
  }
}
