package youversion.red.plans.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Discover(
    val items: List<PlanCollection>? = null
)

@Serializable
data class CollectionGradient(
    val angle: Int? = 0,
    val colors: List<Colors>
)

@Serializable
data class GradientColor(
    val color: String?,
    val percentage: Float
)

@Serializable
data class Colors(
    @SerialName("color_set")
    val colorSet: GradientColor?
)
