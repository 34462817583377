// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ReactionUsers(
  /**
   * Array of users that have reacted with your prayer, the users will be unordered.
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<PrayerReactionUser> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
