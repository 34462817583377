/* eslint-disable camelcase */
import { localizedLink } from '../localized-link'
import { queryifyParamsObj } from '../queryify-params-obj'

class Route {
  constructor({ path, query, serverLanguageTag }) {
    this.route = localizedLink(path, serverLanguageTag)
    this.query = query
  }

  queryify() {
    if (this.query && typeof this.query === 'object') {
      // convert query: { redirect: true } to route?redirect=true
      const queryParams = queryifyParamsObj(this.query)
      return `${this.route}?${queryParams}`
    }
    return this.route
  }

  get() {
    const route = this.query ? this.queryify() : this.route

    return route
  }
}

/**
 * Query is an object containing key value pairs that are converted to the
 * correct format for the url.
 *
 * @namespace
 */
export const Routes = {
  // signup/signin -------------------------------------------------------------
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  signIn: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/sign-in',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  signUp: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/sign-up',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // users ---------------------------------------------------------------------
  /**
   * @param params0
   * @param params0.username
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  user: ({ username, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/users/${username}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // subscriptions -------------------------------------------------------------
  /**
   * @param params0
   * @param params0.username
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptions: ({ username, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionsSaved: ({
    username,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/saved-reading-plans`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionsCompleted: ({
    username,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/completed-reading-plans`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscription: ({
    username,
    plan_id,
    slug,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionSettings: ({
    username,
    plan_id,
    slug,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}/edit`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.day
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionDay: ({
    username,
    plan_id,
    slug,
    day,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}/day/${day}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.day
   * @param params0.content
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionContent: ({
    username,
    plan_id,
    slug,
    day,
    content,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}/day/${day}/segment/${content}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.day
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionDayComplete: ({
    username,
    plan_id,
    slug,
    day,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}/day/${day}/completed`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.day
   * @param params0.subscription_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  sharedDayComplete: ({
    plan_id,
    slug,
    day,
    subscription_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/subscription/${subscription_id}/day/${day}/completed`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  subscriptionComplete: ({
    username,
    plan_id,
    slug,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/completed`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },

  // plans ---------------------------------------------------------------------
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  plans: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/reading-plans',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  plan: ({ plan_id, slug, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/reading-plans/${plan_id}${slug ? `-${slug}` : ''}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },

  // together ------------------------------------------------------------------
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  togetherCreate: ({
    username,
    plan_id,
    slug,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/together/create`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.username
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.together_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  togetherInvite: ({
    username,
    plan_id,
    slug,
    together_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/users/${username}/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/together/${together_id}/invite`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.together_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  togetherInvitation: ({
    plan_id,
    slug,
    together_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/together/${together_id}/invitation`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.plan_id
   * @param params0.slug
   * @param params0.together_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  togetherParticipants: ({
    plan_id,
    slug,
    together_id,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/reading-plans/${plan_id}${
        slug ? `-${slug}` : ''
      }/together/${together_id}/participants`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // bible ---------------------------------------------------------------------
  /**
   * @param params0
   * @param params0.version_id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  version: ({ version_id, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/versions/${version_id}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.version_id
   * @param params0.usfm
   * @param params0.version_abbr
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  reference: ({
    version_id,
    usfm,
    version_abbr = null,
    query = null,
    serverLanguageTag = null,
  }) => {
    const route = new Route({
      path: `/bible/${version_id}/${usfm}${
        version_abbr ? `.${version_abbr}` : ''
      }`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // verse of the day ----------------------------------------------------------
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  votd: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/verse-of-the-day',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.usfm
   * @param params0.id
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  votdImage: ({ usfm, id, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/verse-of-the-day/${usfm}/${id}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // notifications -------------------------------------------------------------
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  notifications: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/notifications',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  notificationsEdit: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/notifications/edit',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  // explore -------------------------------------------------------------------
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  explore: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/explore',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.topic
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  exploreTopic: ({ topic, query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: `/explore/${topic}`,
      query,
      serverLanguageTag,
    })

    return route.get()
  },
  /**
   * @param params0
   * @param params0.query
   * @param params0.serverLanguageTag
   * @function
   */
  exploreStories: ({ query = null, serverLanguageTag = null }) => {
    const route = new Route({
      path: '/explore/stories',
      query,
      serverLanguageTag,
    })

    return route.get()
  },
}
