/**
 * @module AudioPlayerContext
 */
import React from 'react'
import PropTypes from 'prop-types'

/**
 * @typedef {object} AudioPlayerContext
 * @property {string} artworkAltText
 * @property {string} artworkUrl
 * @property {number} duration
 * @property {Function} handleGoBack
 * @property {Function} handleGoForward
 * @property {Function} handleGoToStart
 * @property {Function} handleLoadNewAudio
 * @property {Function} handleLoadSourceUrl
 * @property {Function} handlePause
 * @property {Function} handlePlay
 * @property {Function} handleScrub
 * @property {Function} handleSetArtworkAltText
 * @property {Function} handleSetArtworkUrl
 * @property {Function} handleSetTitle
 * @property {boolean} hasLoaded
 * @property {boolean} isLoading
 * @property {boolean} isPlaying
 * @property {boolean} shouldShowPlayer - Determines if the audio player should be shown.
 * @property {string} title
 * @property {number} trackProgress
 */
const AudioPlayerContext = React.createContext({
  artworkAltText: null,
  artworkUrl: null,
  duration: null,
  handleGoBack: null,
  handleGoForward: null,
  handleGoToStart: null,
  handleLoadNewAudio: null,
  handleLoadSourceUrl: null,
  handlePause: null,
  handlePlay: null,
  handleScrub: null,
  handleSetArtworkAltText: null,
  handleSetArtworkUrl: null,
  handleSetTitle: null,
  hasLoaded: false,
  isLoading: false,
  isPlaying: false,
  shouldShowPlayer: false,
  title: null,
  trackProgress: null,
})

AudioPlayerContext.displayName = 'AudioPlayerContext'

/**
 * Audio Player Provider.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.artworkAltText] - The alt text to be applied to the artwork displayed.
 * @param {string} [props.artworkUrl] - The artwork image that helps describe the audio.
 * @param {string} [props.audioSrcUrl] - The audio source URL.
 * @param {string} [props.title] - Title of the audio.
 *
 * @returns {React.ReactElement} - The audio player provider.
 */
export function AudioPlayerProvider({
  artworkAltText,
  artworkUrl,
  audioSrcUrl,
  title,
  ...props
}) {
  const [isPlaying, setIsPlaying] = React.useState(false)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [sourceUrl, setSourceUrl] = React.useState(audioSrcUrl)
  const [shouldShowPlayer, setShouldShowPlayer] = React.useState(false)

  const [audioTitle, setAudioTitle] = React.useState(title || '')
  const [artworkImageUrl, setArtworkImageUrl] = React.useState(artworkUrl || '')
  const [artworkImageAltText, setArtworkImageAltText] = React.useState(
    artworkAltText || '',
  )

  const [trackProgress, setTrackProgress] = React.useState(0)

  const audioRef = React.useRef(new Audio(sourceUrl))
  const intervalRef = React.useRef(null)

  function startTimer() {
    clearInterval(intervalRef.current)

    intervalRef.current = setInterval(() => {
      setTrackProgress(audioRef.current.currentTime)
    }, [1000])
  }

  const { duration } = audioRef.current

  React.useEffect(() => {
    if (!audioRef.current || !sourceUrl || !hasLoaded) {
      return
    }

    if (isPlaying) {
      audioRef.current.play()
      startTimer()
    } else {
      clearInterval(intervalRef.current)
      audioRef.current.pause()
    }
  }, [hasLoaded, isPlaying, sourceUrl])

  // Pause and clean up on unmount.
  React.useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearInterval(intervalRef.current)
    }
  }, [])

  // Handle setup when changing tracks.
  React.useEffect(() => {
    audioRef.current.pause()
    setHasLoaded(false)
    setIsLoading(true)

    audioRef.current = new Audio(sourceUrl)
    setTrackProgress(audioRef.current.currentTime)
  }, [sourceUrl])

  // Update the audio to have proper event handlers.
  React.useEffect(() => {
    if (audioRef.current && sourceUrl) {
      audioRef.current.oncanplay = () => {
        setIsLoading(false)
      }

      audioRef.current.onloadeddata = () => {
        setHasLoaded(true)
      }

      audioRef.current.onended = () => {
        setIsPlaying(false)
      }
    }
    // This will run when the audio element is retrieved.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceUrl, audioRef.current])

  const handlePlay = React.useCallback(() => {
    setIsPlaying(true)
  }, [])

  const handlePause = React.useCallback(() => {
    setIsPlaying(false)
  }, [])

  const handleScrub = React.useCallback((_, newTime) => {
    if (typeof newTime !== 'number') {
      return
    }

    // Upon scrub, it must load content again to start playing.
    setIsLoading(true)

    // Clear any timers already running.
    clearInterval(intervalRef.current)
    audioRef.current.currentTime = Math.floor(newTime)
    setTrackProgress(audioRef.current.currentTime)
    startTimer()
  }, [])

  const handleGoBack = React.useCallback(
    (seconds) => {
      if (audioRef.current.currentTime < seconds) {
        handleScrub(undefined, 0)
      } else {
        handleScrub(undefined, audioRef.current.currentTime - seconds)
      }
    },
    [handleScrub],
  )

  const handleGoForward = React.useCallback(
    (seconds) => {
      if (duration - audioRef.current.currentTime > seconds) {
        handleScrub(undefined, audioRef.current.currentTime + seconds)
      }
    },
    [duration, handleScrub],
  )

  const handleGoToStart = React.useCallback(() => {
    handleScrub(undefined, 0)
  }, [handleScrub])

  const handleLoadNewAudio = React.useCallback(
    ({ artworkAlt = '', artworkSrc = '', audioSrc, titleText }) => {
      setSourceUrl(audioSrc)
      setAudioTitle(titleText)
      setArtworkImageUrl(artworkSrc)
      setArtworkImageAltText(artworkAlt)
      setIsPlaying(true)
    },
    [],
  )

  const value = React.useMemo(
    () => ({
      artworkAltText: artworkImageAltText,
      artworkUrl: artworkImageUrl,
      audioSrcUrl: sourceUrl,
      duration,
      handleGoBack,
      handleGoForward,
      handleGoToStart,
      handleLoadNewAudio,
      handleLoadSourceUrl: setSourceUrl,
      handlePause,
      handlePlay,
      handleScrub,
      handleSetArtworkAltText: setArtworkImageAltText,
      handleSetArtworkUrl: setArtworkImageUrl,
      handleSetShouldShowPlayer: setShouldShowPlayer,
      handleSetTitle: setAudioTitle,
      hasLoaded,
      isLoading,
      isPlaying,
      shouldShowPlayer,
      title: audioTitle,
      trackProgress,
    }),
    [
      artworkImageAltText,
      artworkImageUrl,
      audioTitle,
      duration,
      handleGoBack,
      handleGoForward,
      handleGoToStart,
      handleLoadNewAudio,
      handlePause,
      handlePlay,
      handleScrub,
      hasLoaded,
      isLoading,
      isPlaying,
      shouldShowPlayer,
      sourceUrl,
      trackProgress,
    ],
  )
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AudioPlayerContext.Provider value={value} {...props} />
}

AudioPlayerProvider.propTypes = {
  artworkAltText: PropTypes.string,
  artworkUrl: PropTypes.string,
  audioSrcUrl: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  title: PropTypes.string,
}

AudioPlayerProvider.defaultProps = {
  artworkAltText: '',
  artworkUrl: '',
  audioSrcUrl: '',
  title: '',
}

/**
 * UseAudioPlayerState hook to grab the audio player state.
 *
 * @returns {React.Context} - The story theme context.
 *
 * @throws {Error}
 */
export function useAudioPlayer() {
  const context = React.useContext(AudioPlayerContext)
  if (context === undefined) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider')
  }
  return context
}
