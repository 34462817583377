package youversion.red.analytics.shared

import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Deprecated("We're migrating away from firebase for certain events")
data class LanguageSetEvent(
    val languageTag: String,
    val previousLanguageTag: String
) : Event() {

    override val key = "language_set"

    override fun toBundle() = Bundle().apply {
        putString("language_tag", languageTag)
        putString("previous_language_tag", previousLanguageTag)
    }
}
