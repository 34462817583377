// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GuidedPrayerModule(
  /**
   * Unique id for a Prayer Guide module ("card")
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The layout a day (i.e., card) should use
   */
  @SerialName("_type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.guidedprayer.api.model.GuidedPrayerModuleTypeSerializer::class)
  @JvmField
  val Type: GuidedPrayerModuleType? = null,
  /**
   * Title for a module/card
   */
  @SerialName("title")
  @ProtoNumber(3)
  @JvmField
  val title: String? = null,
  /**
   * Over-sized copy for a module/card
   */
  @SerialName("header")
  @ProtoNumber(4)
  @JvmField
  val header: String? = null,
  /**
   * The USFM for a Scripture that needs to be loaded into the module/card.
   */
  @SerialName("usfm")
  @ProtoNumber(5)
  @JvmField
  val usfm: String? = null,
  /**
   * Standard-sized copy for a module/card
   */
  @SerialName("text")
  @ProtoNumber(6)
  @JvmField
  val text: String? = null,
  @SerialName("cta")
  @ProtoNumber(7)
  @JvmField
  val cta: GuidedPrayerCta? = null,
  /**
   * Unique identifier for a module's background animation. Call the Animations API for details.
   */
  @SerialName("animation_id")
  @ProtoNumber(8)
  @JvmField
  val animationId: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
