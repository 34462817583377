package youversion.red.prayer.tasks

import com.squareup.sqldelight.internal.AtomicBoolean
import red.platform.Log
import red.platform.LogLevel
import red.platform.currentTimeMillis
import red.platform.io.IOException
import red.platform.settings.Settings
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.Prayer
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.ext.toDb
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.service.PrayerStore
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY

object PrayerIdsSync {

    private val syncing = AtomicBoolean(false)
    private const val CACHE_TIME = 200000L // todo: tweak?
    private const val LAST_SYNC_KEY = "red.prayer.prayer.ids.last.sync"
    private const val MAX_PAGES = 5

    suspend fun process(force: Boolean = false) {
        assertNotMainThread()
        requireNetwork { return }
        requireUser { return }
        ensureNotCached(LAST_SYNC_KEY, CACHE_TIME, force) {
            if (Log.level == LogLevel.DEBUG)
                Log.i("red-prayer", "prayer ids are cached, returning")
            return
        }

        syncing.sync {
            val prayers = mutableListOf<Prayer>()
            var hasNextPage = true
            var pageNum = 1

            try {
                PrayerChangesSync.process()

                while (hasNextPage) {
                    try {
                        val data = PrayerApi.getPrayers(
                            fields = "user_id,id",
                            status = StatusType.ACTIVE,
                            page = pageNum
                        )
                        data?.data?.let {
                            prayers.addAll(it)
                        }
                        hasNextPage = data?.nextPage == true && pageNum < MAX_PAGES
                        pageNum++
                    } catch (e: Exception) {
                        Log.e("red-prayer", "error getting prayer ids")
                        hasNextPage = false
                    }
                }

                if (prayers.isEmpty())
                    return
                val updates = mutableListOf<DbPrayer>()
                val deletes = PrayerStore.getPrayerListSync().toMutableList()
                prayers.forEachIndexed { index, it ->
                    val oldPrayer = PrayerStore.getPrayerByServerIdSync(it.id!!)
                    if (oldPrayer?.state?.and(STATE_DIRTY) != STATE_DIRTY) {
                        val updated = (oldPrayer ?: it.toDb()).copy(
                            orderIndex = index,
                            userId = it.userId,
                            serverId = it.id,
                            status = oldPrayer?.status ?: StatusType.ACTIVE,
                            state = oldPrayer?.state ?: 0
                        )
                        updates.add(updated)
                    }
                    val stillActivePrayer =
                        deletes.firstOrNull { it.clientId == oldPrayer?.clientId || it.serverId == oldPrayer?.serverId }
                    if (stillActivePrayer != null) {
                        deletes.remove(stillActivePrayer)
                    }
                }

                Settings.defaultSettings.edit().putLong(
                    LAST_SYNC_KEY,
                    currentTimeMillis().toLong()
                ).commit()

                PrayerStore.updateAndDeletePrayers(updates, deletes)
            } catch (e: Exception) {
                Log.e("red-prayer", "error syncing ids", e)
                @Suppress("DEPRECATION_ERROR")
                if (e !is IOException) {
                    throw e
                }
            }
        }
    }

    internal fun clearCache() {
        Settings.defaultSettings.edit().remove(LAST_SYNC_KEY).commit()
    }
}
