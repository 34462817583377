// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api

import kotlin.Double
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.Serializable
import kotlinx.serialization.builtins.serializer
import red.platform.Date
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.organizations.api.model.admin.organizations.AdminFeaturedPlan
import youversion.red.organizations.api.model.admin.organizations.AdminFeaturedPlans
import youversion.red.organizations.api.model.admin.organizations.AdminOrganization
import youversion.red.organizations.api.model.admin.organizations.AdminOrganizations
import youversion.red.organizations.api.model.admin.organizations.AdminServiceTimes
import youversion.red.organizations.api.model.admin.organizations.ChurchPostMutation
import youversion.red.organizations.api.model.admin.organizations.FeaturedPlanCreate
import youversion.red.organizations.api.model.admin.organizations.LocationPostMutation
import youversion.red.organizations.api.model.admin.organizations.Schedule
import youversion.red.organizations.api.model.images.ImageProperties
import youversion.red.organizations.api.model.images.ImageType
import youversion.red.organizations.api.model.images.PresignedImageResponse
import youversion.red.organizations.api.model.organizations.FeaturedPlans
import youversion.red.organizations.api.model.organizations.Organization
import youversion.red.organizations.api.model.organizations.OrganizationJoin
import youversion.red.organizations.api.model.organizations.OrganizationLocations
import youversion.red.organizations.api.model.organizations.OrganizationMembers
import youversion.red.organizations.api.model.organizations.Organizations
import youversion.red.organizations.api.model.organizations.ServiceTimes
import youversion.red.organizations.api.model.organizations.accounts.Account
import youversion.red.organizations.api.model.organizations.accounts.Accounts
import youversion.red.organizations.api.model.organizations.accounts.Invite
import youversion.red.organizations.api.model.organizations.accounts.InviteToken
import youversion.red.organizations.api.model.organizations.organization_profiles.OrganizationProfiles

/**
 * The Organizations API is a service that currently houses non-user org and persona entities that
 * can be used to create many types of content. It is possible that this service could be the future
 * home of user identity information not pertaining to authentication and authorization.
 */
object OrganizationsApi : AbstractApi(ApiDefaults("organizations", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", OrganizationsApiSerializer)) {
  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param parentId Organization parent ID to filter children
   * @param forUser Filter for organizations this user has admin access to
   * @return return all organizations
   */
  suspend fun getAdminOrganizations(
    fields: String? = null,
    page: Int? = null,
    pageSize: Int? = null,
    parentId: String? = null,
    forUser: Long? = null
  ): AdminOrganizations = execute("in/4.0/organizations", method = RequestMethods.GET, version =
      "admin", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      parentId?.let { add("parent_id", parentId) }
      forUser?.let { add("for_user", forUser) }
  }, serializer = AdminOrganizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns an organization resource
   */
  suspend fun getAdminOrganization(organizationId: String, fields: String? = null):
      AdminOrganization? = execute("in/4.0/organizations/${organizationId}", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = AdminOrganization.serializer())

  /**
   * @param imageType The type of image to be uploaded
   * @param imageResourceId The id of the resource that the image is of
   * @return Return presigned url for image upload
   */
  suspend fun signOrganizationImage(
    imageType: ImageType,
    imageResourceId: String,
    body: ImageProperties? = null
  ): PresignedImageResponse = execute("in/4.0/images/${imageType}/${imageResourceId}", version =
      "admin", method = RequestMethods.POST, authAllowed = false, authOptional = false, body = body,
      bodySerializer = ImageProperties.serializer(), serializer =
      PresignedImageResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return return all profiles for an organization
   */
  suspend fun getAdminOrganizationProfiles(organizationId: String, fields: String? = null):
      OrganizationProfiles? = execute("in/4.0/organizations/${organizationId}/profiles", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = OrganizationProfiles.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return all accounts for an organization.
   */
  suspend fun getAdminOrganizationAccounts(
    organizationId: String,
    page: Int? = null,
    pageSize: Int? = null
  ): Accounts? = execute("in/4.0/organizations/${organizationId}/accounts", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Accounts.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return Return the created Account resource.
   */
  suspend fun createAdminOrganizationAccount(organizationId: String, body: Invite? = null): Account?
      = execute("in/4.0/organizations/${organizationId}/accounts", version = "admin", method =
      RequestMethods.POST, authAllowed = false, authOptional = false, body = body, bodySerializer =
      Invite.serializer(), serializer = Account.serializer())

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param organizationId The id of the organization resource
   * @return return all service times for organization
   */
  suspend fun getAdminServiceTimes(fields: String? = null, organizationId: String):
      AdminServiceTimes? = execute("in/4.0/organizations/${organizationId}/service_times", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = AdminServiceTimes.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   */
  suspend fun resendAdminOrganizationAccountInvite(organizationId: String, accountId: String) =
      execute("in/4.0/organizations/${organizationId}/accounts/${accountId}:resend", method =
      RequestMethods.POST, version = "admin", authAllowed = false, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   * @return Returns the successfully claimed account.
   */
  suspend fun claimAdminOrganizationAccount(
    organizationId: String,
    accountId: String,
    body: InviteToken? = null
  ): Account? = execute("in/4.0/organizations/${organizationId}/accounts/${accountId}:claim",
      version = "admin", method = RequestMethods.POST, authAllowed = false, authOptional = false,
      body = body, bodySerializer = InviteToken.serializer(), serializer = Account.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   * @param verify A JWT token used to verify authorization.
   * @return Return an account for an organization.
   */
  suspend fun getAdminOrganizationAccount(
    organizationId: String,
    accountId: String,
    verify: String? = null
  ): Account? = execute("in/4.0/organizations/${organizationId}/accounts/${accountId}", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      verify?.let { add("verify", verify) }
  }, serializer = Account.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param accountId The id of the account resource
   */
  suspend fun deleteAdminOrganizationAccount(organizationId: String, accountId: String) =
      execute("in/4.0/organizations/${organizationId}/accounts/${accountId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = false, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @return return the created church
   */
  suspend fun createChurch(body: ChurchPostMutation? = null): ChurchPostMutation =
      execute("in/4.0/churches", version = "admin", method = RequestMethods.POST, authAllowed =
      true, authOptional = false, body = body, bodySerializer = ChurchPostMutation.serializer(),
      serializer = ChurchPostMutation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun deleteChurch(organizationId: String) = execute("in/4.0/churches/${organizationId}",
      method = RequestMethods.DELETE, version = "admin", authAllowed = true, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return return the created church schedule
   */
  suspend fun updateSchedule(organizationId: String, body: Schedule? = null): Schedule? =
      execute("in/4.0/churches/${organizationId}/schedule", version = "admin", method =
      RequestMethods.PUT, authAllowed = false, authOptional = false, body = body, bodySerializer =
      Schedule.serializer(), serializer = Schedule.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return return the created church location
   */
  suspend fun createChurchLocation(organizationId: String, body: LocationPostMutation? = null):
      LocationPostMutation = execute("in/4.0/churches/${organizationId}/locations", version =
      "admin", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = LocationPostMutation.serializer(), serializer =
      LocationPostMutation.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param locationId The id of the location resource
   */
  suspend fun deleteChurchLocation(organizationId: String, locationId: String) =
      execute("in/4.0/churches/${organizationId}/locations/${locationId}", method =
      RequestMethods.DELETE, version = "admin", authAllowed = false, authOptional = false,
      serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param from The time the query will begin querying from
   * @param first Grab n number of record(s) after the `from` timestamp
   * @param last Grab n number of record(s) before the `from` timestamp
   * @return return featured plans for an organization
   */
  suspend fun getAdminOrganizationFeaturedPlans(
    organizationId: String,
    from: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    first: Int? = null,
    last: Int? = null
  ): AdminFeaturedPlans = execute("in/4.0/organizations/${organizationId}/featured_plans", method =
      RequestMethods.GET, version = "admin", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      from?.let { add("from", from) }
      first?.let { add("first", first) }
      last?.let { add("last", last) }
  }, serializer = AdminFeaturedPlans.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @return Return presigned url for image upload
   */
  suspend fun createOrganizationFeaturedPlan(organizationId: String, body: FeaturedPlanCreate? =
      null): AdminFeaturedPlan = execute("in/4.0/organizations/${organizationId}/featured_plans",
      version = "admin", method = RequestMethods.POST, authAllowed = false, authOptional = false,
      body = body, bodySerializer = FeaturedPlanCreate.serializer(), serializer =
      AdminFeaturedPlan.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun endOrganizationFeaturedPlan(organizationId: String) =
      execute("in/4.0/organizations/${organizationId}/featured_plans:end", method =
      RequestMethods.POST, version = "admin", authAllowed = false, authOptional = false, serializer
      = Unit.serializer())

  /**
   * @param withUser Filter for organizations this user is a member of
   * @return Return a collection of organizations
   */
  suspend fun getOrganizations(withUser: Long): Organizations = execute("/organizations", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = true, queryString =
      red.platform.http.queryString {
      add("with_user", withUser)
  }, serializer = Organizations.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns an organization resource
   */
  suspend fun getOrganization(organizationId: String, fields: String? = null): Organization? =
      execute("/organizations/${organizationId}", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Organization.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns the profiles for an organization resource
   */
  suspend fun getOrganizationProfiles(organizationId: String, fields: String? = null):
      OrganizationProfiles? = execute("/organizations/${organizationId}/profiles", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = OrganizationProfiles.serializer())

  /**
   * @param parentIdPath The id of a parent organization resource to be used in path parameters
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param clientLatitude The latitude of the client
   * @param clientLongitude The longitude of the client
   * @return returns an organization location collection
   */
  suspend fun getOrganizationsOrganizationLocations(
    parentIdPath: String,
    fields: String? = null,
    clientLatitude: Double? = null,
    clientLongitude: Double? = null
  ): OrganizationLocations? = execute("/organizations/${parentIdPath}/locations", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      clientLatitude?.let { add("client_latitude", clientLatitude) }
      clientLongitude?.let { add("client_longitude", clientLongitude) }
  }, serializer = OrganizationLocations.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @return returns an organization featured plans
   */
  suspend fun getOrganizationFeaturedPlans(organizationId: String): FeaturedPlans? =
      execute("/organizations/${organizationId}/featured_plans", method = RequestMethods.GET,
      version = "4.0", authAllowed = false, authOptional = false, serializer =
      FeaturedPlans.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return returns a collection of service times
   */
  suspend fun getServiceTimes(organizationId: String, fields: String? = null): ServiceTimes? =
      execute("/organizations/${organizationId}/service_times", method = RequestMethods.GET, version
      = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = ServiceTimes.serializer())

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun joinOrganization(organizationId: String, body: OrganizationJoin? = null) =
      execute("/organizations/${organizationId}:join", version = "4.0", method =
      RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      OrganizationJoin.serializer(), serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   */
  suspend fun leaveOrganization(organizationId: String) =
      execute("/organizations/${organizationId}:leave", method = RequestMethods.POST, version =
      "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * @param organizationId The id of the organization resource
   * @param friends A user_id for which to restrict data to only containing the friends of the id
   * provided.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return return members of an organization
   */
  suspend fun getOrganizationMembers(
    organizationId: String,
    friends: Long? = null,
    page: Int? = null,
    pageSize: Int? = null
  ): OrganizationMembers? = execute("/organizations/${organizationId}/members", method =
      RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      friends?.let { add("friends", friends) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = OrganizationMembers.serializer())
}
