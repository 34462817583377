/**
 * @module arrayMove
 */

/* eslint-disable no-param-reassign */

// This came from index.js of npm package array-move v2.1.0 https://github.com/sindresorhus/array-move

function arrayMoveMutate(array, from, to) {
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0])
}

/**
 * Moves an item in an array from one index to another. Useful for surgical array sorting.
 *
 * @alias module:arrayMove
 *
 * @param {object} params - The function params.
 * @param {Array} params.array - An array.
 * @param {number} params.from - The starting index.
 * @param {number} params.to - The new index.
 *
 * @throws {Error} - Throws an error if function params are incorrect or missing.
 * @throws {Error} - Throws an error if index values are not in the array.
 *
 * @returns {Array} - The updated array.
 *
 * @example
 * // returns ['a','d','b','c']
 * arrayMove(['a','b','c','d'], 3, 1)
 */
export function arrayMove({ array, from, to }) {
  if (!array || !Array.isArray(array)) {
    throw new Error('Param `array` must be an array.')
  }

  if (typeof from !== 'number' || typeof to !== 'number') {
    throw new Error('Missing or incorrect numeric params.')
  }

  if (typeof array[from] === 'undefined') {
    throw new Error(`Index ${from} does not exist in the array.`)
  }

  if (typeof array[to] === 'undefined') {
    throw new Error(`Index ${to} does not exist in the array.`)
  }

  array = array.slice()
  arrayMoveMutate(array, from, to)
  return array
}
