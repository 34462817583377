package youversion.red.achievements.service

import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import red.platform.platformType
import youversion.red.achievements.api.AchievementsApiSerializer
import youversion.red.achievements.api.model.Progress
import youversion.red.achievements.api.model.achievements.ProgressPost
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults

// TODO: Anthony 8/17/2021
//  The API side of this endpoint is not ready to support PROTO. This should be removed once that
//  work is done.
object AchievementsApi2 : AbstractApi(
    ApiDefaults(
        "achievements",
        if (platformType == PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO,
        if (platformType == PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO,
        "4.0",
        AchievementsApiSerializer
    )
) {
    suspend fun addProgress(body: ProgressPost? = null): Progress =
        execute(
            "/progress",
            version = "4.0",
            method = RequestMethods.POST,
            authAllowed = true,
            authOptional = false,
            body = body,
            bodySerializer = ProgressPost.serializer(),
            serializer = Progress.serializer(),
            contentType = ContentTypes.JSON,
            accept = ContentTypes.JSON
        ) ?: throw NullPointerException("Response not sent from server")
}
