/**
 * @module handlePresignedUpload
 */
/**
 * Uploads a file to a presigned request endpoint.
 *
 * @alias module:handlePresignedUpload
 *
 * @param {object} params - The function params.
 * @param {File} params.file - The file to upload.
 * @param {string} params.url - The url to which the form is posted.
 * @param {object} params.params - Form params.
 *
 * @throws {Error} - Throws an error if function params are missing or incorrect.
 * @throws {Error} - Throws an error if the file upload fails.
 *
 * @returns {Promise<string|object>} - The upload response.
 */
export async function handlePresignedUpload({ file, url, params }) {
  const formData = new FormData()

  if (!file) {
    throw new Error('File not found. Please try again.')
  }

  if (!url || typeof url !== 'string') {
    throw new Error('`url` is missing or incorrect type.')
  }

  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    throw new Error('`params` object is missing or incorrect type.')
  }

  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value)
  })

  formData.append('file', file)

  const response = fetch(url, { method: 'POST', body: formData })
    .then((fetchResponse) => {
      return fetchResponse
    })
    .catch((error) => {
      throw new Error(error.message)
    })
  return response
}
