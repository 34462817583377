package red.tasks

import kotlin.coroutines.Continuation
import kotlin.coroutines.resume
import kotlin.coroutines.resumeWithException
import kotlinx.coroutines.CoroutineDispatcher
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.coroutines.withContext
import red.platform.Log
import red.platform.threads.freeze

// @Deprecated("Use GlobalScope.async")
fun <Input, Output> launch(
    input: Input,
    dispatcher: Dispatcher = Dispatchers.backgroundDispatcher,
    block: suspend Input.() -> Output
): IFuture<Output> {
    input.freeze()
    block.freeze()
    val (future, completion) = Future.newFuture<Output>()
    dispatcher.dispatchCoroutine {
        try {
            val result = input.block()
            completion.complete(result)
        } catch (e: Throwable) {
            completion.complete(e)
        }
    }
    return future
}

// @Deprecated("Use GlobalScope.launch")
fun <Output> launch(
    dispatcher: Dispatcher = Dispatchers.backgroundDispatcher,
    block: suspend () -> Output
): IFuture<Output> {
    block.freeze()
    @Suppress("deprecation")
    return launch(Unit, dispatcher, { block() })
}

suspend inline fun <T> blockingSuspendCoroutine(
    dispatcher: Dispatcher = Dispatchers.backgroundDispatcher,
    crossinline block: (completion: Completion<T>) -> Unit
): T {
    return suspendCancellableCoroutine { continuation ->
        continuation.freeze()
        val (future, completion) = Future.newFuture<T>()
        future.addCallback { _, _, _ ->
            future.complete(continuation)
        }
        dispatcher.dispatch {
            try {
                block(completion)
            } catch (e: Throwable) {
                try {
                    completion.complete(e)
                } catch (e: IllegalStateException) {
                    Log.e("Coroutines", "Failed to complete with error", e)
                }
            }
        }
    }
}

suspend fun <T> runOnMainThread(mainDispatcher: CoroutineDispatcher = CoroutineDispatchers.Main, block: suspend () -> T): T {
    return if (isMainThread) {
        block()
    } else {
        block.freeze()
        return withContext(mainDispatcher) {
            block()
        }
    }
}

fun <T> Future<T>.complete(continuation: Continuation<T>) {
    val error = error
    if (error != null) {
        continuation.resumeWithException(error)
    } else {
        if (value != null) {
            continuation.resume(value!!)
        } else {
            @Suppress("UNCHECKED_CAST")
            continuation.resume(value as T) // try and force
        }
    }
}
