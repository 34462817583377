// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.giving

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Referral source of the Giving Landing screen
 */
enum class GivingLandingReferralSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  NAVIGATION_DRAWER("navigation_drawer", 1),

  MORE_SCREEN("more_screen", 2),

  ABOUT_SCREEN("about_screen", 3),

  DEEP_LINK("deep_link", 4);
}

internal class GivingLandingReferralSourceSerializer : KSerializer<GivingLandingReferralSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.giving.GivingLandingReferralSource")

  override fun serialize(encoder: Encoder, value: GivingLandingReferralSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingLandingReferralSource {
    if (decoder is JsonDecoder) {
      return GivingLandingReferralSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingLandingReferralSource.UNKNOWN
      1 -> GivingLandingReferralSource.NAVIGATION_DRAWER
      2 -> GivingLandingReferralSource.MORE_SCREEN
      3 -> GivingLandingReferralSource.ABOUT_SCREEN
      4 -> GivingLandingReferralSource.DEEP_LINK
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
