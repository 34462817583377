// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class TitleAndDescription(
  /**
   * The description of the achievement in the specific language
   */
  @SerialName("description")
  @ProtoNumber(1)
  @JvmField
  val description: String? = null,
  /**
   * The title of the achievement in the specific language
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * The action_text that takes a user towards discovering more about the achievement
   */
  @SerialName("action_text")
  @ProtoNumber(3)
  @JvmField
  val actionText: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
