/**
 * @module stringReplacer
 */

/**
 * Replaces bracketed string params with values.
 *
 * @alias module:stringReplacer
 *
 * @param {string} sourceString - The source string.
 * @param {object} params - The params object with key/value pairs to replace.
 *
 * @throws {Error} - Throws an error if function params are missing or incorrect.
 *
 * @example
 * // Returns http://example.com/en.jpg
 * stringReplacer('http://example.com/{langauge}.jpg', {language: 'en'})
 *
 * @returns {string} - The string with replaced params.
 */
export function stringReplacer(sourceString, params) {
  if (!sourceString || typeof sourceString !== 'string') {
    throw new Error('sourceString missing or incorrect type.')
  }

  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    throw new Error('params object missing or incorrect type.')
  }

  let transformString = sourceString

  Object.keys(params).forEach((key) => {
    const value = params[key]
    const rx = new RegExp(`{${key}}`, 'g')
    transformString = transformString.replace(rx, value)
  })

  return transformString
}

/**
 * An aliased version of stringReplacer to support older systems.
 */
export const urlReplacer = stringReplacer
