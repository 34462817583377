package youversion.red.prayer.guided.model

import red.platform.threads.freeze
import youversion.red.guidedprayer.api.model.GuidedPrayerVariation

interface IBackgroundAudioVariation {
    val title: String?
    val audioCategoryId: Int?
}

data class BackgroundAudioVariation(
    override val title: String? = null,
    override val audioCategoryId: Int? = null,
    val url: String? = null,
    val variations: List<GuidedPrayerVariation> = emptyList()

) : IBackgroundAudioVariation {

    init {
        freeze()
    }
}

data class RandomVariation(override val title: String?, override val audioCategoryId: Int?) : IBackgroundAudioVariation {

    init {
        freeze()
    }
}

data class OffVariation(override val title: String?, override val audioCategoryId: Int?) : IBackgroundAudioVariation {

    init {
        freeze()
    }
}
