package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import youversion.red.moments.module.MomentsSharedModuleInitializer

public object RedMomentsSharedModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: MomentsSharedModuleInitializer = MomentsSharedModuleInitializer()

  public override val name: String = "moments-shared"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    initializer.initialize()
    
  }
}
