package youversion.red.installation.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.http.RequestManager
import red.tasks.CoroutineDispatchers.launch
import youversion.red.installation.InstallationHeader
import youversion.red.installation.InstallationService

@ModuleDependencies("core")
class InstallationModuleInitializer : IModuleInitializer {

    private val service by InstallationService()

    override fun initialize() {
        RequestManager.register(InstallationHeader())
        launch { service.install() }
    }
}
