// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.onboarding

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class OnboardingChoiceType(
  internal val serialName: String,
  internal val serialId: Int
) {
  EXPLORE("explore", 0),

  HABIT("habit", 1),

  READ("read", 2);
}

internal class OnboardingChoiceTypeSerializer : KSerializer<OnboardingChoiceType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.onboarding.OnboardingChoiceType")

  override fun serialize(encoder: Encoder, value: OnboardingChoiceType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): OnboardingChoiceType {
    if (decoder is JsonDecoder) {
      return OnboardingChoiceType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> OnboardingChoiceType.EXPLORE
      1 -> OnboardingChoiceType.HABIT
      2 -> OnboardingChoiceType.READ
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
