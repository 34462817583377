package youversion.red.moments.model

enum class MomentViewType(val fetchable: Boolean) {
    UNKNOWN(true),
    PLANS(false),
    FRIENDSHIP_MOMENT(true),
    DEFAULT_MOMENT(true),
    VOTD(false),
    HIGHLIGHT_MOMENT(true),
    BOOKMARK_MOMENT(true),
    PLAN_COMPLETE_MOMENT(true),
    PLAN_SEGMENT_MOMENT(true),
    PLAN_SUBSCRIBED_MOMENT(true),
    SYSTEM_MOMENT(true),
    NOTE_MOMENT(true),
    BANNER_RICH_MOMENT(false),
    BANNER_SIMPLE_MOMENT(false),
    IMAGE_MOMENT(true),
    PROFILE(false),
    DOWNLOAD_BIBLE(false),
    SET_LANGUAGE(false),
    ADD_FRIEND(false),
    FRIEND_CONNECT(false),
    FRIEND_SUGGESTED(false),
    VOTD_OFFER(false),
    VOTD_SET(false),
    BADGE_MOMENT(true),
    BADGE_PROGRESS(true),
    GUIDED_PRAYER(false),
    KIDS_STORIES(false),
    COMMUNITY_PLACEHOLDER(false),
    PRAYER_LIST(false)
}
