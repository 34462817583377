/**
 * @module YouVersionAuth
 * @deprecated - Deprecated since 3.3.11. Auth was split into `@youversion/auth`, but drops support for legacy auth tokens. It will be removed from `@youversion/react` in a future release.
 */

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import { AuthClient } from '@youversion/api/4.0/core'
import {
  BAD_TOKEN,
  SCRIPT_LOAD_ERROR,
} from '@youversion/api/4.0/core/common/constants'
import { Me } from '@youversion/api/4.0/users-service'
import { makeModel, makeStateModel } from '@youversion/api/core'
import * as yup from 'yup'
import { useModel as baseUseModel } from 'hooks/use-model'

/**
 * List of valid async statusTypes possible within YouVersionAuthContext
 * `['loading', 'error', 'success', 'idle']`.
 */
const statusTypes = makeStateModel(['loading', 'error', 'success', 'idle'])

/**
 * @typedef {object} YouVersionAuthContext
 * @property {object} user
 * @property {boolean} isLoading
 * @property {boolean} isIdle
 * @property {boolean} isSuccess
 * @property {boolean} isSignedIn
 * @property {boolean} isError
 * @property {Error} error
 * @property {Function} signIn
 * @property {Function} signOut
 * @property {Function} getToken
 * @property {boolean} hasValidScopes
 */
const YouVersionAuthContext = React.createContext({
  user: null,
  isLoading: false,
  isIdle: false,
  isSuccess: false,
  isSignedIn: false,
  isError: false,
  error: null,
  signIn: null,
  signOut: null,
  getToken: null,
  hasValidScopes: false,
})
YouVersionAuthContext.displayName = 'YouVersionAuthContext'

/**
 * React Hook for accessing YouVersionAuthContext.
 *
 * @returns {YouVersionAuthContext}
 */
export function useYouVersionAuth() {
  const context = React.useContext(YouVersionAuthContext)
  if (context === undefined) {
    throw new Error(
      `useYouVersionAuth must be used within a YouVersionAuthProvider`,
    )
  }
  return context
}

/**
 * React Hook for accessing YouVersion Auth Sign In.
 *
 * @returns {object} SignInModel object.
 */
export function useSignInModel() {
  const { signIn } = useYouVersionAuth()

  const queryFn = React.useCallback(() => {
    return Promise.resolve(
      makeModel({
        name: 'SignInModel',
        id: 'SignInModel',
        data: {
          email: '',
          password: '',
        },
        schema: yup.object().shape({
          email: yup.string().email().required('Email is required'),
          password: yup.string().required('Password is required'),
        }),
      }),
    )
  }, [])

  const buildValidationError = React.useCallback((model) => {
    return new Error(`Sign In Error: ${model.$error}`)
  }, [])

  const updateFn = React.useCallback(
    async (model) => {
      if (!model.$valid) throw new Error('Sign in Error')
      await signIn({
        username: model.email.$value,
        password: model.password.$value,
      })
    },
    [signIn],
  )

  return baseUseModel({
    queryKey: 'SignInModel:Default',
    queryFn,
    buildValidationError,
    updateFn,
    queryConfig: { staleTime: Infinity },
  })
}

useSignInModel.statusTypes = baseUseModel.statusTypes

/**
 * Wrapper for conditionally displaying children when auth is loading.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function YouVersionAuthLoading({ children }) {
  const { isIdle, isLoading } = useYouVersionAuth()
  return isIdle || isLoading ? children : null
}

/**
 * Wrapper for conditionally displaying children when auth encounters an error.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function YouVersionAuthError({ children }) {
  const { isError, error } = useYouVersionAuth()
  return isError
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { error }),
      )
    : null
}

/**
 * Wrapper for conditionally displaying children when auth is available.
 *
 * @param {object} config
 * @param {React.ReactElement} config.children
 */
export function YouVersionAuthContent({ children }) {
  const context = useYouVersionAuth()
  return context.isSuccess
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { ...context }),
      )
    : null
}

/**
 * Fetch User from YouVersion API.
 *
 * @param {string} token
 */
async function getUser(token) {
  try {
    const userResponse = await Me.resource.get({ token })
    return userResponse.$data
  } catch (error) {
    throw new Error('Failed to get user data.')
  }
}

/**
 * Retrieve access token if available.
 *
 * @returns {Promise<string|null>} The token value.
 */
async function getToken() {
  try {
    const tokenResponse = await AuthClient.getToken()
    if (tokenResponse && tokenResponse.access_token) {
      return tokenResponse.access_token
    }
    return null
  } catch (error) {
    throw new Error('Failed to get token.')
  }
}

/**
 * React Context Provider for YouVersion Authentication.
 *
 * @param {object} [props]
 * @param {string} [props.scopes] - A space-separated list of required scopes.
 * @param {*} [props.props] - Any other props.
 */
export function YouVersionAuthProvider({ scopes, ...props }) {
  if (process?.env !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      'YouVersionAuthProvider and related functions/components are deprecated since 3.3.11. Auth was split into `@youversion/auth`, but drops support for legacy auth tokens. Auth will be removed from `@youversion/react` in a future release.',
    )
  }

  const [user, setUser] = React.useState()
  const [status, setStatus] = React.useState(statusTypes.idle)
  const [error, setError] = React.useState()
  const [hasValidScopes, setHasValidScopes] = React.useState(false)

  const isLoading = status === statusTypes.loading
  const isIdle = status === statusTypes.idle
  const isSuccess = status === statusTypes.success
  const isError = status === statusTypes.error

  const isSignedIn = Boolean(user)

  function updateStatus({ userState, errorState, statusState }) {
    setUser(userState)
    setError(errorState)
    setStatus(statusState)
  }
  /**
   * Run this effect hook once when component mounts
   * - check for assertion token in query parameters
   * - fetch token
   * - use token to fetch user
   * - list for native Event for token expiration.
   */
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const acFunction = {
      name: 'getToken',
      params: null,
    }
    if (queryParams.has('assertion') && queryParams.get('assertion')) {
      acFunction.name = 'activateAssertion'
      acFunction.params = { assertionToken: queryParams.get('assertion') }
    }
    setStatus(statusTypes.loading)
    // Rather than call separate functions with the same return value requiring
    // the same callback logic, use dynamic function name and params to call
    // AuthClient.getToken() or AuthClient.activateAssertion({ assertionToken })
    AuthClient[acFunction.name](acFunction.params)
      .then((tokenResult) => {
        if (tokenResult && tokenResult.access_token) {
          if (scopes && tokenResult.scope !== scopes) {
            setHasValidScopes(false)
            updateStatus({
              userState: null,
              errorState: new Error('Unauthorized access'),
              statusState: statusTypes.error,
            })
            return
          }
          if (scopes && tokenResult.scope === scopes) {
            setHasValidScopes(true)
          }
          getUser(tokenResult.access_token)
            .then((response) => {
              updateStatus({
                userState: response,
                errorState: null,
                statusState: statusTypes.success,
              })
            })
            .catch((userError) => {
              updateStatus({
                userState: null,
                errorState: userError,
                statusState: statusTypes.error,
              })
            })
        } else {
          updateStatus({
            userState: null,
            errorState: null,
            statusState: statusTypes.success,
          })
        }
      })
      .catch((tokenError) => {
        updateStatus({
          userState: null,
          errorState: tokenError,
          statusState: statusTypes.error,
        })
      })

    /* List for Token Expirations */
    const listener = () => {
      setUser(null)
    }

    const scriptErrorListener = () => {
      updateStatus({
        userState: null,
        errorState: new Error('Script Load Error'),
        statusState: statusTypes.error,
      })
    }

    window.addEventListener(BAD_TOKEN, listener)
    window.addEventListener(SCRIPT_LOAD_ERROR, scriptErrorListener)

    /* Remove Listener When Dismounting */
    return () => {
      window.removeEventListener(BAD_TOKEN, listener)
      window.removeEventListener(SCRIPT_LOAD_ERROR, scriptErrorListener)
    }
  }, [scopes])

  /**
   * Sign In using YouVersion Auth.
   *
   * @param {object} config
   * @param {string} config.username
   * @param {string} config.password
   */
  const signIn = React.useCallback(
    async ({ username, password }) => {
      try {
        const tokenResponse = await AuthClient.signIn({
          username,
          password,
          scopes,
        })
        if (tokenResponse && tokenResponse.access_token) {
          if (scopes && tokenResponse.scope !== scopes) {
            setHasValidScopes(false)
            throw new Error('Unauthorized access')
          } else if (scopes && tokenResponse.scope === scopes) {
            setHasValidScopes(true)
          }
          setUser(await getUser(tokenResponse.access_token))
        } else {
          throw new Error('Bad Auth')
        }
      } catch (signInError) {
        throw new Error('Sorry, unable to sign in with those credentials.')
      }
    },
    [scopes],
  )

  /**
   * Sign Out using YouVersion Auth.
   */
  const signOut = React.useCallback(() => {
    AuthClient.signOut(() => {
      setUser(null)
    })
  }, [])

  /**
   * Memoized value to pass to YouVersionAuthContextProvider.
   */
  const value = React.useMemo(
    () => ({
      user,
      isLoading,
      isIdle,
      isSuccess,
      isSignedIn,
      isError,
      error,
      signIn,
      signOut,
      getToken,
      hasValidScopes,
    }),
    [
      user,
      isLoading,
      isIdle,
      isSuccess,
      isSignedIn,
      isError,
      error,
      signIn,
      signOut,
      hasValidScopes,
    ],
  )

  return <YouVersionAuthContext.Provider value={value} {...props} />
}

YouVersionAuthProvider.propTypes = {
  scopes: PropTypes.string,
}

YouVersionAuthProvider.defaultProps = {
  scopes: null,
}
