package youversion.red.prayer.guided.service

import red.database.toListLiveData
import red.database.toLiveData
import red.lifecycle.LiveData
import red.platform.Date
import red.platform.currentTimeMillis
import red.platform.newDate
import red.platform.now
import red.platform.threads.freeze
import red.platform.toDateTime
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.guidedprayer.api.model.GuidedPrayerModuleType
import youversion.red.prayer.guided.db.GuidedPrayerDb
import youversion.red.prayer.guided.db.queries
import youversion.red.prayer.guided.model.GuidedPrayerDay as DbDay
import youversion.red.prayer.guided.model.GuidedPrayerGuide
import youversion.red.prayer.guided.model.GuidedPrayerModule as DbModule

internal object GuidedPrayerStore {

    private const val ONE_DAY = 86400000L
    private const val LANGUAGE_TAG_DELIM = ","

    /**
     * Guide
     */

    fun addGuide(guide: GuidedPrayerGuide) {
        assertNotMainThread()
        val exists = GuidedPrayerDb.queries.guideExists(guide.id).executeAsOne()
        if (!exists)
            GuidedPrayerDb.queries.addGuide(guide.id, guide.languageTags.joinToString(LANGUAGE_TAG_DELIM))
        else
            GuidedPrayerDb.queries.updateLanguages(guide.languageTags.joinToString(LANGUAGE_TAG_DELIM), guide.id)
    }

    fun getGuide(id: Int): GuidedPrayerGuide? {
        assertNotMainThread()
        return GuidedPrayerDb.queries.getGuide(id, guideMapper).executeAsOneOrNull()
    }

    fun getGuideLiveData(id: Int): LiveData<GuidedPrayerGuide> =
        GuidedPrayerDb.queries.getGuide(id, guideMapper).toLiveData()

    fun deleteAllGuides() {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteAllGuides()
    }

    /**
     * Day -------------------------------------------------------------------------------------
     */
    fun processDays(days: List<DbDay>) {
        assertNotMainThread()
        GuidedPrayerDb.queries.transaction {
            deleteOldDays(newDate((currentTimeMillis().toLong() - ONE_DAY).toDateTime()))
            days.forEach {
                addDay(it)
            }
        }
    }

    fun addDay(day: DbDay) {
        assertNotMainThread()
        if (getDay(id = day.id, guideId = day.guideId) != null)
            GuidedPrayerDb.queries.updateDay(
                guideId = day.guideId,
                id = day.id,
                imageUrl = day.imageUrl,
                title = day.title,
                lightBgColor = day.lightBgColor,
                darkBgColor = day.darkBgColor,
                lastSync = now()
            )
        else
            GuidedPrayerDb.queries.addDay(
                guideId = day.guideId,
                id = day.id,
                imageUrl = day.imageUrl,
                title = day.title,
                lightBgColor = day.lightBgColor,
                darkBgColor = day.darkBgColor,
                lastSync = now()
            )
    }

    fun getDay(id: Int, guideId: Int): DbDay? {
        assertNotMainThread()
        return GuidedPrayerDb.queries.getDay(id = id, guideId = guideId, mapper = dayMapper).executeAsOneOrNull()
    }

    fun getDayLiveData(id: Int, guideId: Int): LiveData<DbDay> {
        return GuidedPrayerDb.queries.getDay(id = id, guideId = guideId, mapper = dayMapper).toLiveData()
    }

    fun deleteDay(id: Int, guideId: Int) {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteDay(id = id, guideId = guideId)
    }

    fun deleteAllDays() {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteAllDays()
    }

    fun deleteOldDays(expiration: Date) {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteOldDays(expiration)
    }

    /**
     * Module -------------------------------------------------------------------------------------
     */

    fun processModules(modules: List<DbModule>) {
        assertNotMainThread()
        GuidedPrayerDb.queries.transaction {
            modules.firstOrNull()?.let {
                GuidedPrayerDb.queries.deleteModules(it.dayId, it.guideId)
            }
            modules.forEach {
                addModule(it)
            }
        }
    }

    fun addModule(module: DbModule) {
        assertNotMainThread()
        if (getModule(id = module.id, dayId = module.dayId, guideId = module.guideId) != null)
            GuidedPrayerDb.queries.updateModule(
                guideId = module.guideId,
                dayId = module.dayId,
                id = module.id,
                type = module.type,
                title = module.title,
                header = module.header,
                usfm = module.usfm,
                text = module.text,
                ctaText = module.ctaText,
                ctaUrl = module.ctaUrl,
                animationId = module.animationId
            )
        else
            GuidedPrayerDb.queries.addModule(
                guideId = module.guideId,
                dayId = module.dayId,
                id = module.id,
                type = module.type,
                title = module.title,
                header = module.header,
                usfm = module.usfm,
                text = module.text,
                ctaText = module.ctaText,
                ctaUrl = module.ctaUrl,
                animationId = module.animationId
            )
    }

    fun getModulesForDay(guideId: Int, dayId: Int): List<DbModule> {
        assertNotMainThread()
        return GuidedPrayerDb.queries.getModules(guideId = guideId, dayId = dayId, mapper = moduleMapper)
            .executeAsList()
    }

    fun getModulesForDayLiveData(guideId: Int, dayId: Int): LiveData<List<DbModule>> {
        return GuidedPrayerDb.queries.getModules(guideId = guideId, dayId = dayId, mapper = moduleMapper)
            .toListLiveData()
    }

    fun getModule(id: Int, dayId: Int, guideId: Int): DbModule? {
        assertNotMainThread()
        return GuidedPrayerDb.queries.getModule(id = id, dayId = dayId, guideId = guideId, mapper = moduleMapper)
            .executeAsOneOrNull()
    }

    fun getModuleLiveData(id: Int, dayId: Int, guideId: Int): LiveData<DbModule> {
        return GuidedPrayerDb.queries.getModule(id = id, dayId = dayId, guideId = guideId, mapper = moduleMapper)
            .toLiveData()
    }

    fun deleteModule(id: Int, dayId: Int, guideId: Int) {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteModule(id = id, dayId = dayId, guideId = guideId)
    }

    fun deleteAllModules() {
        assertNotMainThread()
        GuidedPrayerDb.queries.deleteAllModules()
    }

    /**
     * MAPPERS ------------------------------------------------------------------------------------
     */

    private val dayMapper = { guideId: Int,
                              id: Int,
                              imageUrl: String?,
                              title: String?,
                              lightBgColor: String?,
                              darkBgColor: String?,
                              _: Date? ->
        DbDay(
            guideId = guideId,
            id = id,
            imageUrl = imageUrl,
            title = title,
            lightBgColor = lightBgColor,
            darkBgColor = darkBgColor
        ).freeze()
    }.freeze()

    private val moduleMapper = { guideId: Int,
                                 dayId: Int,
                                 id: Int,
                                 type: GuidedPrayerModuleType?,
                                 title: String?,
                                 header: String?,
                                 usfm: String?,
                                 text: String?,
                                 ctaText: String?,
                                 ctaUrl: String?,
                                 animationId: Int? ->
        DbModule(
            guideId = guideId,
            id = id,
            dayId = dayId,
            type = type,
            title = title,
            header = header,
            usfm = usfm,
            text = text,
            ctaText = ctaText,
            ctaUrl = ctaUrl,
            animationId = animationId
        ).freeze()
    }.freeze()

    private val guideMapper = { id: Int, languageTags: String? ->
        GuidedPrayerGuide(
            id,
            languageTags?.split(LANGUAGE_TAG_DELIM) ?: emptyList()
        ).freeze()
    }.freeze()
}
