/**
 * Checks if we're running in the browser or not.
 *
 * @module isInBrowser
 *
 * @returns {boolean}
 */
export function isInBrowser() {
  try {
    return Boolean(window) === true && typeof window !== 'undefined'
  } catch (e) {
    return false
  }
}
