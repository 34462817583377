// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api

import kotlin.Int
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.guidedprayer.api.model.GuidedPrayerDay
import youversion.red.guidedprayer.api.model.GuidedPrayerGuide
import youversion.red.guidedprayer.api.model.GuidedPrayerModules
import youversion.red.guidedprayer.api.model.GuidedPrayerMusic

/**
 * Prayer Stories, I mean Guided Prayer, is essentially VOTD Stories for Prayer. Guides provide
 * available daily (by day_of_year) walk-throughs as stacks of cards (modules).
 */
object GuidedPrayerApi : AbstractApi(ApiDefaults("guidedprayers", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", GuidedPrayerApiSerializer)) {
  /**
   * View which languages are supported for the given guide
   *
   * @param guideId Unique identifier of the Prayer Guide being referenced
   * @return Return overview details for single Prayer Guide.
   */
  suspend fun getGuide(guideId: Int): GuidedPrayerGuide? = execute("/guides/${guideId}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, serializer =
      GuidedPrayerGuide.serializer())

  /**
   * View overview details for a single Prayer Guide day, by day of year.
   *
   * @param guideId Unique identifier of the Prayer Guide being referenced
   * @param dayOfYear Unique identifier, using the Day of the Year, for the specific day being
   * referenced
   * @return Return overview details for single Prayer Guide day.
   */
  suspend fun getDay(guideId: Int, dayOfYear: Int): GuidedPrayerDay? =
      execute("/guides/${guideId}/days/${dayOfYear}", method = RequestMethods.GET, version = "4.0",
      authAllowed = false, authOptional = false, serializer = GuidedPrayerDay.serializer())

  /**
   * View all "cards/pages" for a Prayer Guide's day, by day of year.
   *
   * @param guideId Unique identifier of the Prayer Guide being referenced
   * @param dayOfYear Unique identifier, using the Day of the Year, for the specific day being
   * referenced
   * @return Return overview details for single Prayer Guide day.
   */
  suspend fun getModules(guideId: Int, dayOfYear: Int): GuidedPrayerModules? =
      execute("/guides/${guideId}/days/${dayOfYear}/modules", method = RequestMethods.GET, version =
      "4.0", authAllowed = false, authOptional = false, serializer =
      GuidedPrayerModules.serializer())

  /**
   * View all music available for use with each module
   *
   * @return Returns all music and its variations for background music
   */
  suspend fun getMusic(): GuidedPrayerMusic = execute("/music", method = RequestMethods.GET, version
      = "4.0", authAllowed = false, authOptional = false, serializer =
      GuidedPrayerMusic.serializer()) ?: throw NullPointerException("Response not sent from server")
}
