package youversion.red.installation

import red.platform.threads.AtomicReference
import red.platform.threads.set

interface InstallationEventFirer {

    fun fire()
}

object InstallationEvent {

    private val _firer = AtomicReference<InstallationEventFirer?>(null)

    var firer: InstallationEventFirer?
        get() = _firer.value
        set(value) {
            _firer.set(value)
        }

    fun fire() {
        _firer.value?.fire()
    }
}
