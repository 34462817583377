/**
 * @module capitalize
 */

/**
 * Capitalize the first character of a string and lowercase the rest of the string.
 *
 * @alias module:capitalize
 *
 * @param {string} string - A string.
 * @param {object} [options] - Optional options.
 * @param {boolean} [options.preserveCase] - Don't lowercase the rest of the string.
 *
 * @throws {Error} - Throws an error if `string` is missing or incorrect type.
 *
 * @returns {string} - The capitalized string.
 *
 * @example
 * // returns 'Capitalize me'
 * capitalize('capitalize me')
 */
export function capitalize(string, { preserveCase = false } = {}) {
  if (!string || typeof string !== 'string') {
    throw new Error('string is missing or incorrect type.')
  }
  const stringRest = preserveCase
    ? string.slice(1)
    : string.toLowerCase().slice(1)
  return string.charAt(0).toUpperCase() + stringRest
}
