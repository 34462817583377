// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import red.Serializable
import red.platform.threads.freeze
import youversion.red.stories.shared.IStoriesModule

@SerialName("modules.BaseModule")
interface BaseModule : IStoriesModule, Serializable {
  override val id: Int?

  override val title: String?

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<BaseModule> = PolymorphicSerializer(BaseModule::class)
  }
}
