package youversion.red.movies.service

import red.service.DefaultService
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviesCollection
import youversion.red.movies.api.LegacyVideosApi
import youversion.red.movies.api.MoviesApi2
import youversion.red.movies.api.MoviesUIContext
import youversion.red.movies.api.model.videos.VideoTypeEnum

@DefaultService(IMoviesService::class)
internal class MoviesServiceImpl : IMoviesService {

    override suspend fun getConfiguration() = MoviesApi2.getConfiguration()

    override suspend fun getPublisher(id: Int) = MoviesApi2.getPublisher(id)

    override suspend fun getMovie(id: Int, context: MoviesUIContext) =
        MoviesApi2.getMovie(id, context)

    override suspend fun getChapters() = MoviesApi2.getChapters()

    override suspend fun getMoviesRelatedTo(usfm: String, page: Int?) =
        MoviesApi2.getMoviesRelatedTo(usfm, page)

    override suspend fun getMoviesInCollection(
        id: Int,
        type: VideoTypeEnum?,
        orientation: MovieOrientation?,
        pageSize: Int?,
        page: Int?
    ) = MoviesApi2.getMoviesInCollection(id, type, orientation, pageSize, page)

    override suspend fun getCollection(id: Int) = MoviesApi2.getCollection(id)

    override suspend fun getLanguages(type: String) = MoviesApi2.getLanguages(type)

    override suspend fun getCollections(page: Int?) = MoviesApi2.getCollections(page)

    override suspend fun getLegacyVideo(id: Int) = LegacyVideosApi.getLegacyVideo(id)

    override suspend fun getActualMovieId(id: Int): Pair<Int, MoviesCollection?>? {
        if (id < 25000) {
            val video = getLegacyVideo(id) ?: return null
            val movieId = video.moviesId ?: return null
            if (video.subVideos?.isNotEmpty() == true) {
                return Pair(movieId, getCollection(video.moviesId))
            }
            return Pair(movieId, null)
        }
        return Pair(id, null)
    }
}
