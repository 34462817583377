/**
 * @module paramsToQuery
 */

/**
 * Convert params object to valid query string with support for bracket-less arrays.
 *
 * @param {object} params - A params object.
 *
 * @alias module:paramsToQuery
 *
 * @throws {Error} - Throws an error if `params` is not an object.
 *
 * @returns {string} - The query string.
 */
export function paramsToQuery(params) {
  if (!(typeof params === 'object')) {
    throw new Error('Supplied `params` must be an object.')
  }

  return Object.keys(params)
    .map((name) => {
      const value = params[name]

      if (typeof value === 'undefined') {
        return ''
      }

      if (Array.isArray(value)) {
        return value
          .map((arrayValue) => {
            return `${name}=${arrayValue}`
          })
          .sort()
          .join('&')
      }

      if (['number', 'string'].indexOf(typeof value) === -1) {
        const type = value === null ? 'null' : typeof value
        throw new Error(
          `Param value with the name ${name} was of type ${type} which is not allowed.`,
        )
      }

      return `${name}=${value}`
    })
    .filter((value) => value && value.length)
    .sort()
    .join('&')
}
