package youversion.red.prayer.model

import red.platform.Date
import red.platform.threads.ensureNeverFrozen
import red.platform.threads.freeze
import youversion.red.prayer.service.PrayerStore

data class PrayerComment(
    val prayerClientId: String? = null,
    internal val prayerServerId: Int? = null,
    val clientId: String,
    internal val serverId: Int? = null,
    val message: String? = null,
    val updatedDt: Date? = null,
    val createdDt: Date? = null,
    val userId: Long? = null,
    internal val state: Int = 0,
    internal val lastSync: Date? = null
) {

    init {
        this.freeze()
    }

    fun newBuilder() = PrayerCommentBuilder(this)
}

class PrayerCommentBuilder(private val oldComment: PrayerComment? = null) {

    init {
        ensureNeverFrozen()
    }

    var message: String? = oldComment?.message
    var updatedDt: Date? = oldComment?.updatedDt

    fun build() = oldComment?.copy(
        message = message,
        updatedDt = updatedDt
    ) ?: PrayerComment(
        clientId = PrayerStore.NEW_CLIENT_ID,
        message = message,
        updatedDt = updatedDt
    )

    fun withMessage(message: String? = null): PrayerCommentBuilder {
        this.message = message
        return this
    }

    fun withUpdatedDt(updatedDt: Date? = null): PrayerCommentBuilder {
        this.updatedDt = updatedDt
        return this
    }
}
