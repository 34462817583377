// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api

import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.prayer.api.model.Comment
import youversion.red.prayer.api.model.CommentOrder
import youversion.red.prayer.api.model.Comments
import youversion.red.prayer.api.model.Prayer
import youversion.red.prayer.api.model.PrayerAnswer
import youversion.red.prayer.api.model.PrayerList
import youversion.red.prayer.api.model.PrayerParticipant
import youversion.red.prayer.api.model.PrayerReaction
import youversion.red.prayer.api.model.PrayerUpdate
import youversion.red.prayer.api.model.PrayerUpdates
import youversion.red.prayer.api.model.Prayers
import youversion.red.prayer.api.model.ReactionUsers
import youversion.red.prayer.api.model.RelationStatus
import youversion.red.prayer.api.model.Shares
import youversion.red.prayer.api.model.SharesMade
import youversion.red.prayer.api.model.SharesSent
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.api.model.Users

/**
 * The prayer service contains all the CRUD API calls a client would need to work with the prayer
 * feature.
 */
object PrayerApi : AbstractApi(ApiDefaults("prayers", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", PrayerApiSerializer)) {
  /**
   * Create a prayer list that will eventually house a collection of prayers
   *
   * @return Prayer list created successfully
   */
  suspend fun addPrayerList(body: PrayerList? = null): PrayerList = execute("/lists", version =
      "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PrayerList.serializer(), serializer = PrayerList.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated insert of a prayer object into the database. You must include the
   * title, description, usfm, version_id, created_dt, and sharing policy.
   *
   * @return Prayer created successfully
   */
  suspend fun addPrayer(body: Prayer? = null): Prayer = execute("/prayers", version = "4.0", method
      = RequestMethods.POST, authAllowed = true, authOptional = false, body = body, bodySerializer =
      Prayer.serializer(), serializer = Prayer.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated list of prayers that are available for your interaction.  Status and
   * fields allow the call to be configured for flexible usage.
   *
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @param status Status for a prayer can be active or archived. Pass in a status string to filter
   * prayers.
   * @param answer Status for a prayer answer can be 'all' or 'answered'. Pass in a status string to
   * filter prayers.
   * @param ids List of prayer ids to fetch.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request.
   */
  suspend fun getPrayers(
    fields: String? = null,
    status: StatusType? = null,
    answer: PrayerAnswer? = null,
    ids: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): Prayers? = execute("/prayers", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      status?.let { add("status", status.serialName) }
      answer?.let { add("answer", answer.serialName) }
      ids?.let { add("ids", ids) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = Prayers.serializer())

  /**
   * This is an authenticated update to an already existing prayer.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer updated successfully
   */
  suspend fun editPrayer(prayerId: Int, body: Prayer? = null): Prayer? =
      execute("/prayers/${prayerId}", version = "4.0", method = RequestMethods.PUT, authAllowed =
      true, authOptional = false, body = body, bodySerializer = Prayer.serializer(), serializer =
      Prayer.serializer())

  /**
   * This is an authenticated single prayer resource available for retrieval.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @return Prayer retrieved successfully
   */
  suspend fun getPrayer(prayerId: Int, fields: String? = null): Prayer? =
      execute("/prayers/${prayerId}", method = RequestMethods.GET, version = "4.0", authAllowed =
      true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Prayer.serializer())

  /**
   * This is an authenticated deletion of a prayer resource.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   */
  suspend fun deletePrayer(prayerId: Int) = execute("/prayers/${prayerId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * This is an authenticated insert of a prayer update into the database. You must include the
   * message and created date.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer update created successfully
   */
  suspend fun addUpdate(prayerId: Int, body: PrayerUpdate? = null): PrayerUpdate =
      execute("/prayers/${prayerId}/updates", version = "4.0", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      PrayerUpdate.serializer(), serializer = PrayerUpdate.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated list of prayer updates that are available for your interaction.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request.
   */
  suspend fun getUpdates(
    prayerId: Int,
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): PrayerUpdates = execute("/prayers/${prayerId}/updates", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = PrayerUpdates.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated update to an already existing prayer update.
   *
   * @param updateId The unique identifier for the update resource.
   * @return Prayer updated successfully
   */
  suspend fun editPrayerUpdate(updateId: Int, body: PrayerUpdate? = null): PrayerUpdate =
      execute("/prayers/updates/${updateId}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      PrayerUpdate.serializer(), serializer = PrayerUpdate.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated deletion of a prayer update resource.
   *
   * @param updateId The unique identifier for the update resource.
   */
  suspend fun deleteUpdate(updateId: Int) = execute("/prayers/updates/${updateId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * This is an authenticated update to an already existing prayer reaction.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer reaction updated successfully
   */
  suspend fun editPrayerReaction(prayerId: Int, body: PrayerReaction? = null): PrayerReaction =
      execute("/prayers/${prayerId}/reactions", version = "4.0", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      PrayerReaction.serializer(), serializer = PrayerReaction.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated list of users who reacted by clicking "prayed for."
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Successful request.
   */
  suspend fun getReactions(prayerId: Int): ReactionUsers = execute("/prayers/${prayerId}/reactions",
      method = RequestMethods.GET, version = "4.0", authAllowed = true, authOptional = false,
      serializer = ReactionUsers.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated insert of a prayer comment into the database. You must include the
   * message and created date.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer comment created successfully
   */
  suspend fun addComment(prayerId: Int, body: Comment? = null): Comment =
      execute("/prayers/${prayerId}/comments", version = "4.0", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer = Comment.serializer(),
      serializer = Comment.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated fetch of comments on a prayer from the database.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param order Order for comments can be time ascending or descending. Pass in a order string to
   * change how results are ordered.
   * @return Prayer comments retrieved successfully
   */
  suspend fun getComments(
    prayerId: Int,
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null,
    order: CommentOrder? = null
  ): Comments = execute("/prayers/${prayerId}/comments", method = RequestMethods.GET, version =
      "4.0", authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
      order?.let { add("order", order.serialName) }
  }, serializer = Comments.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated delete of a comment on a prayer from the database. The creator of a
   * comment can delete it, or the creator of the prayer can delete it.
   *
   * @param commentId The unique identifier for the Comment resource.
   */
  suspend fun deleteComment(commentId: Int) = execute("/prayers/comments/${commentId}", method =
      RequestMethods.DELETE, version = "4.0", authAllowed = true, authOptional = false, serializer =
      Unit.serializer())

  /**
   * This is an authenticated update of a comment on a prayer in the database. You must include the
   * message and updated date.
   *
   * @param commentId The unique identifier for the Comment resource.
   * @return Prayer comment updated successfully
   */
  suspend fun updateComment(commentId: Int, body: Comment? = null): Comment =
      execute("/prayers/comments/${commentId}", version = "4.0", method = RequestMethods.PUT,
      authAllowed = true, authOptional = false, body = body, bodySerializer = Comment.serializer(),
      serializer = Comment.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated list of prayers that are available for your interaction.  Using the
   * fields parameter you can fetch the quick form of this or fill out more of the details- this
   * enables the call to be used in multiple areas of the feature without sacrificing performance.
   *
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @return Successful request.
   */
  suspend fun getUsers(
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null
  ): Users? = execute("/users", method = RequestMethods.GET, version = "4.0", authAllowed = true,
      authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
  }, serializer = Users.serializer())

  /**
   * This is an authenticated deletion of all references to a user in the prayer system (prayers,
   * comments, participants, etc.)  When a YouVersion user deletes their account we will call this to
   * remove all connected resources.
   *
   * @param userId unique ID that identifies a user in the YouVersion ecosystem.
   */
  suspend fun deleteUser(userId: Long) = execute("/users/${userId}", method = RequestMethods.DELETE,
      version = "4.0", authAllowed = true, authOptional = false, serializer = Unit.serializer())

  /**
   * This is an authenticated insert of prayer shares into the database. You must include all
   * receivers, and the created date.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer share created successfully
   */
  suspend fun sharePrayer(prayerId: Int, body: SharesSent? = null): SharesMade =
      execute("/prayers/${prayerId}/shares", version = "4.0", method = RequestMethods.POST,
      authAllowed = true, authOptional = false, body = body, bodySerializer =
      SharesSent.serializer(), serializer = SharesMade.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * This is an authenticated list of shares that have stemmed from a given prayer.  This list is
   * accessible to the person who created the prayer and those in the prayer chain.  Using the fields
   * parameter you can fetch the quick form of this or fill out more of the details- this enables the
   * call to be used in multiple areas of the feature without sacrificing performance.
   *
   * @param fields A comma separated list of fields to return. Clients should normally stick with
   * the default. A 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result.
   * @param prayerId The unique identifier for the Prayer resource.
   * @param onlySeen Filter for showing only seen shares/prayers or seeing ALL shares/prayers.
   * @param relation Describes if the prayer was shared directly or from anyone
   * @return Successful request.
   */
  suspend fun getShares(
    fields: String? = null,
    pageSize: Int? = null,
    page: Int? = null,
    prayerId: Int,
    onlySeen: Boolean? = null,
    relation: RelationStatus? = null
  ): Shares? = execute("/prayers/${prayerId}/shares", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
      onlySeen?.let { add("only_seen", onlySeen) }
      relation?.let { add("relation", relation.serialName) }
  }, serializer = Shares.serializer())

  /**
   * A user can send a seen time or hidden time to verify that they have seen a prayer card.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @return Prayer seen or hidden successfully
   */
  suspend fun updateParticipantPrayer(prayerId: Int, body: PrayerParticipant? = null):
      PrayerParticipant? = execute("/prayers/${prayerId}/participant", version = "4.0", method =
      RequestMethods.PUT, authAllowed = true, authOptional = false, body = body, bodySerializer =
      PrayerParticipant.serializer(), serializer = PrayerParticipant.serializer())

  /**
   * An authorized (prayer owner/admin) user can update the properties for a participant.
   *
   * @param prayerId The unique identifier for the Prayer resource.
   * @param userId unique ID that identifies a user in the YouVersion ecosystem.
   * @return Prayer participant attribute updated successfully
   */
  suspend fun updateParticipantPrayerAsAdmin(
    prayerId: Int,
    userId: Long,
    body: PrayerParticipant? = null
  ): PrayerParticipant? = execute("/prayers/${prayerId}/participant/${userId}", version = "4.0",
      method = RequestMethods.PUT, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PrayerParticipant.serializer(), serializer = PrayerParticipant.serializer())
}
