package youversion.red.podcasts.db

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import youversion.red.podcasts.db.podcasts.newInstance
import youversion.red.podcasts.db.podcasts.schema

public interface PodcastsDatabase : Transacter {
  public val podcastsQueries: PodcastsQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = PodcastsDatabase::class.schema

    public operator fun invoke(driver: SqlDriver,
        podcasts_progressAdapter: Podcasts_progress.Adapter): PodcastsDatabase =
        PodcastsDatabase::class.newInstance(driver, podcasts_progressAdapter)
  }
}
