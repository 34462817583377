package youversion.red.banner.model.blocks

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.UUID
import red.platform.threads.freeze
import youversion.red.banner.model.BannerBody
import youversion.red.banner.model.ImageId

@Serializable
@SerialName("banner.DefaultBlock")
data class DefaultBlock(
    @ProtoNumber(1) val icon: ImageId? = null,
    @ProtoNumber(2) val title: String? = null,
    @ProtoNumber(3) val titleAlignment: BlockAlignment = BlockAlignment.Leading,
    @ProtoNumber(4) val body: BannerBody? = null,
    @ProtoNumber(5) val callToAction: String,
    @ProtoNumber(6) val url: String,
    @ProtoNumber(7) val id: String = UUID.randomUUID().toString()
) : BannerBlock {

    init {
        freeze()
    }
}
