// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.string_translations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class StringTranslation(
  @SerialName("source")
  @ProtoNumber(1)
  @JvmField
  val source: String? = null,
  @SerialName("language_tag")
  @ProtoNumber(2)
  @JvmField
  val languageTag: String? = null,
  @SerialName("crowdin_string_id")
  @ProtoNumber(3)
  @JvmField
  val crowdinStringId: Int? = null,
  @SerialName("translation")
  @ProtoNumber(4)
  @JvmField
  val translation: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
