// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class FilteredVerseSearch(
  /**
   * text searched for (includes mis-spellings)
   */
  @SerialName("query")
  @ProtoNumber(1)
  val query: String? = null,
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(2)
  val appLanguage: String? = null,
  /**
   * Bible version id of the history item
   */
  @SerialName("version_id")
  @ProtoNumber(3)
  val versionId: Int? = null,
  /**
   * Entry point to search (ex. unified, plans, emotion, what does the bible say)
   */
  @SerialName("referrer")
  @ProtoNumber(4)
  val referrer: String? = null,
  /**
   * The canon filter used to filter verse results (represented by the string values returned by the
   * API e.g. "OT")
   */
  @SerialName("canon")
  @ProtoNumber(5)
  val canon: String? = null,
  /**
   * The book filter used to filter verse results (represented by the string USFM values returned by
   * the API e.g. "GEN")
   */
  @SerialName("book")
  @ProtoNumber(6)
  val book: String? = null
) : Event(), red.Serializable {
  override val key: String = "FilteredVerseSearch"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    query?.let { bundle.putString("query", query) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    referrer?.let { bundle.putString("referrer", referrer) }
    canon?.let { bundle.putString("canon", canon) }
    book?.let { bundle.putString("book", book) }
    return bundle
  }
}
