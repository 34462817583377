/**
 * @module selectImageFromList
 */

/**
 * @typedef ImagesDimensionsObject.
 * @param {Array} images - An array of image objects, each with a width (number), height (number), and url (string) attribute.
 * @param {number} width - The width to match from the images array.
 * @param {number} [height] - The height to match from the images array.
 */

/**
 * Returns an object selected from an array of images according to the width that most closely matches with the passed width in the parameter.
 * If the difference is width matches more than one image in the array, it will return the first match.
 * If both height and width are provided, it will use the width to select from the list.
 *
 * @alias module:selectImageFromList
 *
 * @param {ImagesDimensionsObject} imagesDimensionsObject - The ImagesDimensionsObject.
 *
 * @returns {object} - Returns an object with keys of url (string), width (number), and height (number).
 *
 * @example
 * // returns null
 * selectImageFromList({width: 350, height: 500})
 * // returns {width: 550, height: 300, url: 'httsp://hello.world'}
 * const images = [{width: 450,  height: 600, url: 'https://hello.mundo'}, {width: 550, height: 300, url:'https://hello.nara'}]
 * selectImageFromList({images, width: 501})
 */
export function selectImageFromList({ images, width, height }) {
  if (!images || !width) return null

  const selectedImage = images.reduce(
    (lastMatch, currentSize) => {
      const currentDiff = Math.abs(currentSize.width - width)
      return currentDiff < lastMatch.diff
        ? {
            diff: currentDiff,
            width: currentSize.width,
            height: currentSize.height,
            url: currentSize.url,
          }
        : lastMatch
    },
    { diff: width, width, height: height || width },
  )

  return {
    url: selectedImage.url,
    width: selectedImage.width,
    height: selectedImage.height,
  }
}
