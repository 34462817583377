package youversion.red.prayer.guided.ext

import red.Error
import red.platform.threads.freeze
import red.service.ServiceRegistry
import red.tasks.CoroutineDispatchers.launch
import red.tasks.CoroutineDispatchers.withMain
import red.toError
import youversion.red.prayer.guided.model.BackgroundAudioVariation
import youversion.red.prayer.guided.model.IBackgroundAudioVariation
import youversion.red.prayer.guided.model.RandomVariation
import youversion.red.prayer.guided.service.IGuidedPrayerService

suspend fun IBackgroundAudioVariation.getAudioUrl(day: Int): String? = when (this) {
    is BackgroundAudioVariation -> {
        val variationForToday = variations[day % variations.size]
        this.url?.replace(
            "{variation}",
            variationForToday.tag ?: throw Error("need variation tag to calculate audio url for variation: $this")
        )
    }
    is RandomVariation -> {
        val parentVariants = ServiceRegistry[IGuidedPrayerService::class].getVariations().filterNot { it is RandomVariation }
        val parentVariationForToday = parentVariants[day % parentVariants.size]
        parentVariationForToday.getAudioUrl(day)
    }
    else -> null
}

fun IBackgroundAudioVariation.getAudioUrl(day: Int, completion: (url: String?, error: Error?) -> Unit) {
    completion.freeze()
    launch {
        try {
            val result = getAudioUrl(day)
            withMain {
                completion.invoke(result, null)
            }
        } catch (e: Exception) {
            e.freeze()
            withMain {
                completion.invoke(null, e.toError())
            }
        }
    }
}
