// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api

import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.locales.api.model.ApiTag
import youversion.red.locales.api.model.LocaleHeaders
import youversion.red.locales.api.model.LocalePlatform

/**
 * Locales API for managing YouVersion locales (experimental)
 */
object LocalesApi : AbstractApi(ApiDefaults("locales", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0")) {
  /**
   * @param locales The list of locales as a comma separated string. Ex. "en,en-GB"
   * @param platform The platform for a locale
   * @return Successful request.
   */
  suspend fun getLocaleHeaders(locales: String? = null, platform: LocalePlatform? = null):
      LocaleHeaders = execute("/locales", method = RequestMethods.GET, version = "4.0", authAllowed
      = false, authOptional = false, queryString = red.platform.http.queryString {
      locales?.let { add("locales", locales) }
      platform?.let { add("platform", platform.serialName) }
  }, serializer = LocaleHeaders.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param locale The locale
   * @param platform The platform for a locale
   * @return Successful request.
   */
  suspend fun getApiTag(locale: String, platform: LocalePlatform? = null): ApiTag =
      execute("/locales/tag/${locale}", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      platform?.let { add("platform", platform.serialName) }
  }, serializer = ApiTag.serializer()) ?: throw
      NullPointerException("Response not sent from server")
}
