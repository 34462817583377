// Auto-Generated from OpenAPI Spec
package youversion.red.stories.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object StoriesDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides")) {
  private val _listener: AtomicReference<StoriesDeepLinkListener?> = AtomicReference(null)

  var listener: StoriesDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/stories") { context, _, url -> 
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val cohort = queries["cohort"]?.firstOrNull()
        listener.onCurrentStory(context, cohort)
    }
    register("/stories/$INTEGER_EXPRESSION") { context, parts, _ -> 
        val values = parts.first().groupValues
        val lessonId = values[1].toIntOrNull() ?: return@register false
        listener.onStory(context, lessonId)
    }
    freeze()
  }
}
