package youversion.red.analytics

import red.platform.Log
import red.platform.http.FormatType
import red.platform.threads.freeze
import youversion.red.analytics.db.Events
import youversion.red.dataman.api.DataManApi
import youversion.red.dataman.api.DataManApiSerializer
import youversion.red.dataman.api.model.AnalyticsEvent

internal class DataManEventImpl(val event: AnalyticsEvent) : DataManEvent {

    init {
        freeze()
    }
}

class DataManInterfaceImpl : DataManInterface {

    @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
    override fun serialize(event: DataManEvent) = DataManApiSerializer.serialize(
        FormatType.PROTOBUF,
        AnalyticsEvent.serializer(),
        (event as DataManEventImpl).event
    )

    override suspend fun send(events: List<Events>): List<Long> {
        val ids = mutableListOf<Long>()
        val dataManEvents = youversion.red.dataman.api.model.Events(events.mapNotNull { evs ->
            val ev = DataManApiSerializer.deserialize(
                FormatType.PROTOBUF,
                AnalyticsEvent.serializer(), evs.message
            )
            ev?.let { ids.add(evs.id) }
            ev
        }, AnalyticsContextManager.context)
        Log.d("DataManInterfaceImpl", "Events: $dataManEvents")
        DataManApi.addEvents(dataManEvents)
        return ids
    }
}
