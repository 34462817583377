package youversion.red.analytics.shared

import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

data class GenericShareEvent(val sharedToAppId: String) : Event() {

    override val key = "share"

    override fun toBundle() = Bundle().apply {
        putString("shared_to_app_id", sharedToAppId)
    }
}
