package youversion.red.prayer.model

import red.platform.Date

data class PrayerShare(
    val id: Int,
    val createdDt: Date? = null,
    val message: String? = null,
    val receiverId: Long? = null,
    val senderId: Long? = null,
    val prayerServerId: Int? = null,
    val prayerClientId: String? = null
)
