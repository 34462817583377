// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchHistoryTap(
  /**
   * USFM of the book for the history item
   */
  @SerialName("book_usfm")
  @ProtoNumber(1)
  val bookUsfm: String? = null,
  /**
   * Chapter id of the history item
   */
  @SerialName("chapter_id")
  @ProtoNumber(2)
  val chapterId: String? = null,
  /**
   * Bible version id of the history item
   */
  @SerialName("version_id")
  @ProtoNumber(3)
  val versionId: Int? = null
) : Event(), red.Serializable {
  override val key: String = "SearchHistoryTap"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bookUsfm?.let { bundle.putString("book_usfm", bookUsfm) }
    chapterId?.let { bundle.putString("chapter_id", chapterId) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    return bundle
  }
}
