// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bible

import kotlin.Boolean
import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bible.TrialEndEvent")
data class TrialEndEvent(
  @SerialName("version")
  @ProtoNumber(1)
  val version: Int? = null,
  @SerialName("success")
  @ProtoNumber(2)
  val success: Boolean? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(version: Int? = null, success: Boolean? = null) : this(version, success,
      red.platform.now())
}
