// Auto-Generated from OpenAPI Spec
package youversion.red.discovery.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Trend(
  @SerialName("kind")
  @ProtoNumber(1)
  @JvmField
  val kind: String? = null,
  @SerialName("kind_ids")
  @ProtoNumber(2)
  @JvmField
  val kindIds: List<Int>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
