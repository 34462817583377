// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Kind of historical search
 */
enum class SearchHistoryKind(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALL("all", 1),

  EVENTS("events", 2),

  FEATURES("features", 3),

  PLANS("plans", 4),

  SUPPORT_ARTICLES("support-articles", 5),

  TOPICS("topics", 6),

  USERS("users", 7),

  VERSES("verses", 8),

  VIDEOS("videos", 9),

  UNIFIED("unified", 10);
}

internal class SearchHistoryKindSerializer : KSerializer<SearchHistoryKind> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.SearchHistoryKind")

  override fun serialize(encoder: Encoder, value: SearchHistoryKind) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): SearchHistoryKind {
    if (decoder is JsonDecoder) {
      return SearchHistoryKind.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> SearchHistoryKind.UNSUPPORTED_PLACEHOLDER
      1 -> SearchHistoryKind.ALL
      2 -> SearchHistoryKind.EVENTS
      3 -> SearchHistoryKind.FEATURES
      4 -> SearchHistoryKind.PLANS
      5 -> SearchHistoryKind.SUPPORT_ARTICLES
      6 -> SearchHistoryKind.TOPICS
      7 -> SearchHistoryKind.USERS
      8 -> SearchHistoryKind.VERSES
      9 -> SearchHistoryKind.VIDEOS
      10 -> SearchHistoryKind.UNIFIED
      else -> SearchHistoryKind.UNSUPPORTED_PLACEHOLDER
    }
  }
}
