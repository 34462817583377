/**
 * @module AuthClient
 */
import { getAPIEnvironment, isInBrowser } from '@youversion/utils'
import {
  BAD_TOKEN,
  SCRIPT_LOAD_ERROR,
  TOKEN_AVAILABLE,
} from '4.0/core/common/constants'
import { AuthenticationError } from '4.0/core/errors'

const REFRESH_INTERVAL_MILLISECONDS = 60 * 1000

// Choose an explicit environment
const environment = getAPIEnvironment()

const baseUrl =
  environment === 'production'
    ? 'https://nodejs.bible.com'
    : 'https://nodejs-staging.bible.com'

// inject new YV login.bible.com if x-yv-login meta tag == true
let yvLoginEnabled = false

/**
 * A token object.
 *
 * @typedef {object} Token
 * @property {string} access_token The auth token.
 * @property {string} [scope] Optional space-separated list of scopes.
 * @property {string} [refresh_token] Does the token need to be refreshed?
 * @property {number} [expiresAt] The token expiration time.
 * @property {number} [expires_in] The token expiration time.
 */

/**
 * Creates a non-yv-login token object.
 *
 * @param {string} token - The token value.
 * @param {*} [scopes] - User scopes.
 *
 * @returns {Token} A token object.
 */
function convertLoginToken(token, scopes) {
  if (!token) {
    return null
  }
  return {
    access_token: token,
    scope: scopes,
    refresh_token: 'nope',
    expiresAt: new Date().getTime() + 60_000,
  }
}

/**
 * Creates an event and event listener.
 *
 * @param {object} e - The object to attach the event and listener to.
 * @param {string} eventName - The event name.
 * @param {Function} fn - The callback function.
 */
const addEvent = (e, eventName, fn) => {
  if (e.addEventListener) {
    e.addEventListener(eventName, fn, false)
  } else if (e.attachEvent) {
    e.attachEvent(`on${eventName}`, fn)
  }
}

/**
 * Waits for the yv-login script to be ready.
 *
 * @returns {Promise<object>} The yv script object.
 */
async function getLogin() {
  return new Promise((resolve) => {
    // @ts-ignore
    if (typeof yv === 'undefined') {
      addEvent(window, 'loginattached', () => {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        resolve(yv)
      })
    } else {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      resolve(yv)
    }
  })
}

/**
 * Loads a script asynchronously and injects it into the dom.
 *
 * @param {string} uri - The uri of the script to be loaded.
 *
 * @returns {Promise} Resolves once the script is loaded.
 */
async function loadScriptAsync(uri) {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script')
    tag.src = uri
    tag.async = true
    tag.onload = () => {
      resolve()
    }
    tag.onerror = () => {
      reject(new Error(`Failed to load async script at ${uri}`))
    }
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  })
}

if (isInBrowser()) {
  const metaTags = Array.prototype.slice.call(
    document.getElementsByTagName('meta'),
  )

  // find meta tag
  const yvLoginMetaTag = metaTags.filter((item) => {
    return item.name === 'x-yv-login' && item.content === 'true'
  })[0]

  if (yvLoginMetaTag) {
    yvLoginEnabled = true
    if (!document.getElementById('yv-login')) {
      const initialize = async () => {
        try {
          await loadScriptAsync(
            environment === 'production'
              ? 'https://login.bible.com/js/api/authorization.js'
              : 'https://login-staging.bible.com/js/api/authorization.js',
          )
        } catch (error) {
          window.dispatchEvent(
            new CustomEvent(SCRIPT_LOAD_ERROR, { detail: error }),
          )
        }
        const login = await getLogin()
        login.remote.login.getIdentity((user, scopes, token) => {
          if (token) {
            // eslint-disable-next-line no-use-before-define
            saveTokenToStorage(convertLoginToken(token, scopes)) // due to circular dependencies, it's ok to use this in its hoisted capacity
            window.dispatchEvent(new Event(TOKEN_AVAILABLE))
          }
        })
      }
      initialize()
    }
  }
}

// Store tokens for production/staging separately
const localStorageKey = `YouVersion:OAuth:${environment}`

const tokenRefreshThreshold = 300000 // 5 Minutes
const method = 'POST'

const headers = {
  'Content-Type': 'application/json',
}

/**
 * Checks if a fatal error exists in an API response.
 *
 * @param {object} response - The response object.
 *
 * @returns {boolean}
 */
function isFatalError(response) {
  const fatalErrors = ['invalid_grant']
  return (
    response && 'error' in response && fatalErrors.indexOf(response.error) > -1
  )
}

/**
 * Checks if a token is valid and current.
 *
 * @param {Token} token - The token object.
 *
 * @returns {boolean}
 */
function isTokenValid(token) {
  if (token && yvLoginEnabled) {
    return true
  }
  return (
    token !== null &&
    typeof token === 'object' &&
    'refresh_token' in token &&
    'expires_in' in token &&
    !Number.isNaN(token.expires_in)
  )
}

/**
 * Checks if a token has expired.
 *
 * @param {Token} token - The token object.
 *
 * @returns {boolean}
 */
function isTokenExpired(token) {
  if (token && yvLoginEnabled) {
    return false
  }
  if (token && 'expiresAt' in token) {
    const now = new Date().getTime()
    const diff = token.expiresAt - now
    return diff <= tokenRefreshThreshold
  }
  return false
}

/**
 * Gets the token from local storage.
 *
 * @returns {Token} A token object.
 */
function getTokenFromStorage() {
  if (!isInBrowser()) return null

  let token
  try {
    token = JSON.parse(localStorage.getItem(localStorageKey))
  } catch (error) {
    token = null
  }
  return token
}

/**
 * Saves the token object to local storage.
 *
 * @param {Token} token - The token object.
 *
 * @returns {boolean} The success of the storage attempt.
 */
function saveTokenToStorage(token) {
  if (!isInBrowser())
    throw new Error('Cannot save token while running on server.')

  if (isTokenValid(token) && !isTokenExpired(token)) {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(token))
      return true
    } catch (error) {
      return false
    }
  }
  return false
}

/**
 * Deletes the token from local storage.
 *
 * @param {object} params
 * @param {boolean} params.dispatchEvent - Whether or not there is a dispatch event.
 */
function deleteToken({ dispatchEvent = false }) {
  if (!isInBrowser()) return

  if (window.localStorage) {
    window.localStorage.removeItem(localStorageKey)
  }

  if (dispatchEvent && window.dispatchEvent) {
    window.dispatchEvent(new Event(BAD_TOKEN))
  }
}

/**
 * Fetches and stores a token.
 *
 * @param {string} url - The fetch url.
 * @param {object} options - The fetch options.
 * @param {boolean} dispatchEvent - Whether or not there is a dispatch event.
 *
 * @returns {Promise<Token>} A token object.
 */
async function fetchAndStoreToken(url, options = {}, dispatchEvent = true) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    const token = await new Promise((resolve) => {
      // eslint-disable-next-line no-shadow
      login.remote.login.getIdentity((user, scopes, token) => {
        resolve(convertLoginToken(token, scopes))
      })
    })
    if (token == null) {
      throw new AuthenticationError({
        error_name: 'NO_TOKEN',
        error_description: 'Token Not Available',
      })
    }
    if (isInBrowser()) {
      saveTokenToStorage(token)
    }
    return token
  }
  const response = await fetch(url, options)
  const json = await response.json()

  if (!isTokenValid(json) || isTokenExpired(json)) {
    if (isFatalError(json)) {
      deleteToken({ dispatchEvent })
    }
    throw new AuthenticationError(json)
  }

  const expiresAt = new Date().getTime() + json.expires_in * 1000
  const token = {
    ...json,
    expiresAt,
  }

  if (isInBrowser()) saveTokenToStorage(token)
  return token
}

/**
 * Fetch a new token.
 *
 * @param {object} authenticationParams - The authentication parameters.
 * @param {boolean} dispatchEvent - Whether or not there is a dispatch event.
 *
 * @returns {Promise<Token>} A token object.
 */
async function newToken(authenticationParams, dispatchEvent = false) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    return new Promise((resolve, reject) => {
      if (
        authenticationParams &&
        authenticationParams.username &&
        authenticationParams.password
      ) {
        login.remote.login.login(
          authenticationParams.username,
          authenticationParams.password,
          authenticationParams.scopes || '',
          (user, scopes, token) => {
            if (!token) {
              reject(new Error('Authentication error'))
            }
            const t = convertLoginToken(token, scopes)
            saveTokenToStorage(t)
            resolve(t)
          },
        )
      } else {
        login.remote.login.getIdentity((user, scopes, token) => {
          const t = convertLoginToken(token, scopes)
          saveTokenToStorage(t)
          resolve(t)
        })
      }
    })
  }
  return fetchAndStoreToken(
    `${baseUrl}/oauth/token`,
    {
      headers,
      method,
      body: JSON.stringify(authenticationParams),
    },
    dispatchEvent,
  )
}

/**
 * Fetches a token in a refresh interval.
 *
 * @param {string} refreshToken - The token to refresh.
 * @param {boolean} dispatchEvent - Whether or not there is a dispatch event.
 *
 * @returns {Promise<Token>} A token object.
 */
async function newTokenFromRefresh(refreshToken, dispatchEvent = false) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    return new Promise((resolve) => {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      login.remote.login.getIdentity((user, scopes, token) => {
        const t = convertLoginToken(token, scopes)
        saveTokenToStorage(t)
        resolve(t)
      })
    })
  }
  return fetchAndStoreToken(
    `${baseUrl}/oauth/refresh`,
    {
      headers,
      method,
      body: JSON.stringify({ refresh_token: refreshToken }),
    },
    dispatchEvent,
  )
}

let refreshing = false
/**
 * Refreshes the token on a set interval.
 * *Not a real function.
 *
 * @function refreshOnInterval
 */
if (isInBrowser() && typeof window.setInterval === 'function') {
  window.setInterval(() => {
    if (refreshing) return
    refreshing = true
    if (yvLoginEnabled) {
      getLogin().then((login) => {
        login.remote.login.getIdentity((user, scopes, token) => {
          if (token) {
            saveTokenToStorage(convertLoginToken(token, scopes))
          } else {
            deleteToken({ dispatchEvent: true })
          }
          refreshing = false
        })
      })

      return
    }
    const token = getTokenFromStorage()
    const isValid = isTokenValid(token)
    const isExpired = isTokenExpired(token)
    if (isValid && isExpired) {
      newTokenFromRefresh(token.refresh_token, true)
    } else if (!isValid) {
      deleteToken({ dispatchEvent: true })
    }
    refreshing = false
  }, REFRESH_INTERVAL_MILLISECONDS)
}

/**
 * Gets a valid token.
 *
 * @returns {Promise<Token>} A token object.
 */
export async function getToken() {
  if (yvLoginEnabled) {
    const login = await getLogin()

    return new Promise((resolve) => {
      login.remote.login.getIdentity((user, scopes, token) => {
        resolve(convertLoginToken(token, scopes))
      })
    })
  }
  const token = getTokenFromStorage()
  if (isTokenValid(token)) {
    if (isTokenExpired(token)) {
      return newTokenFromRefresh(token.refresh_token, false)
    }
    return token
  }
  return null
}

/**
 * Signs in a user.
 *
 * @param {object} params
 * @param {string} params.username - The username.
 * @param {string} params.password - The password.
 * @param {string} [params.scopes] - Optional string of space-separated scopes.
 *
 * @returns {Promise<Token>} A token object.
 */
export async function signIn({ username, password, scopes }) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    return new Promise((resolve, reject) => {
      login.remote.login.login(
        username,
        password,
        scopes,
        (user, scope, token) => {
          if (!token) {
            reject(new Error('Authentication error'))
          }
          const convertedToken = convertLoginToken(token, scope)
          saveTokenToStorage(convertedToken)
          resolve(convertedToken)
        },
      )
    })
  }
  return newToken({ username, password, scopes }, false)
}

/**
 * Method to activate an assertion and create an auth token.
 *
 * Note: This function only works and returns a Promise when used with
 * login.bible.com and when YV login is enabled.
 *
 * @param {object} params
 * @param {string} params.assertionToken - The assertion token value.
 *
 * @returns {Promise<Token>} A token object.
 */
export async function activateAssertion({ assertionToken }) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    return new Promise((resolve) => {
      login.remote.login.activateAssertion(
        assertionToken,
        (user, scope, token) => {
          const convertedToken = convertLoginToken(token, scope)
          saveTokenToStorage(convertedToken)
          resolve(convertedToken)
        },
      )
    })
  }
  return null
}

/**
 * Signs in using a third-party authentication token.
 *
 * @param {object} params
 * @param {string} [params.facebook] - The Facebook token.
 * @param {string} [params.googlejwt] - The Google JWT token.
 * @param {string} [params.apple] - The Apple token.
 * @param {string} [params.scopes] - A string of space-separated scopes.
 *
 * @returns {Promise<Token>} A token object.
 */
export async function thirdPartySignIn({ facebook, googlejwt, apple, scopes }) {
  if (yvLoginEnabled) {
    const login = await getLogin()
    return new Promise((resolve, reject) => {
      if (!facebook && !googlejwt && !apple) {
        resolve()
      }
      let fn
      let thirdPartyToken
      if (facebook) {
        fn = 'loginWithFacebookCustom'
        thirdPartyToken = facebook
      } else if (googlejwt) {
        fn = 'loginWithGoogleCustom'
        thirdPartyToken = googlejwt
      } else if (apple) {
        fn = 'loginWithAppleCustom'
        thirdPartyToken = apple
      }
      if (fn && thirdPartyToken) {
        login.remote.login[fn](
          null,
          scopes,
          thirdPartyToken,
          (user, scope, token) => {
            if (!token) {
              reject(new Error('Authentication error'))
            }
            const convertedToken = convertLoginToken(token, scope)
            saveTokenToStorage(convertedToken)
            resolve(convertedToken)
          },
        )
      } else {
        resolve()
      }
    })
  }
  return newToken({ facebook, googlejwt, apple, scopes }, false)
}

/**
 * Signs out the user and destroys the local storage tokens.
 *
 * @param {Function} callback - The callback function to run once signOut is complete.
 */
export function signOut(callback) {
  if (yvLoginEnabled) {
    getLogin().then((login) => {
      login.remote.login.logout(callback)
    })
  }
  if (callback) {
    deleteToken({ dispatchEvent: false })
    return callback()
  }
  return deleteToken({ dispatchEvent: false })
}

/**
 * Synchronously checks if the user is signed in.
 *
 * Note: This is not compatible with yv-login. It can potentially return stale tokens, and should only be used in extreme cases.
 *
 * @returns {boolean}
 */
export function isSignedInSync() {
  return isInBrowser() && localStorage.getItem(localStorageKey) != null
}

/**
 * Checks if the user is signed in.
 *
 * @returns {Promise<boolean>}
 */
export async function isSignedIn() {
  const token = await getToken()
  return isTokenValid(token) && !isTokenExpired(token)
}
