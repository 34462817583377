// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.Float
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("organizations.organization_profiles.LocationProfile")
data class LocationProfile(
  @SerialName("address_line_1")
  @ProtoNumber(11)
  val addressLine1: String = "",
  @SerialName("address_line_2")
  @ProtoNumber(12)
  val addressLine2: String = "",
  @SerialName("city")
  @ProtoNumber(13)
  val city: String = "",
  @SerialName("state")
  @ProtoNumber(14)
  val state: String = "",
  @SerialName("postal_code")
  @ProtoNumber(15)
  val postalCode: String = "",
  @SerialName("country")
  @ProtoNumber(16)
  val country: String = "",
  @SerialName("timezone")
  @ProtoNumber(17)
  val timezone: String = "",
  @SerialName("latitude")
  @ProtoNumber(18)
  val latitude: Float = 0f,
  @SerialName("longitude")
  @ProtoNumber(19)
  val longitude: Float = 0f,
  @SerialName("weekly_attendance")
  @ProtoNumber(20)
  val weeklyAttendance: Int = 0,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: String = "",
  @SerialName("organization_id")
  @ProtoNumber(2)
  override val organizationId: String = ""
) : BaseProfile, red.Serializable {
  init {
    freeze()
  }
}
