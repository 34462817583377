package youversion.red.podcasts.model

import kotlinx.serialization.Serializable
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PodcastEpisode(
    val id: Int? = null,
    val showId: Int? = null,
    val title: String? = null,
    val audioUrl: String? = null,
    val episodeDuration: Int? = null,
    val episodeArt: String? = null,
    val episodeDescription: String? = null,
    @Serializable(with = red.platform.DateSerializer::class)
    val publishedDt: Date? = null,
    val partner: String? = null,
    val showDescription: String? = null,
    val showLink: String? = null
) : red.Serializable {

    init {
        freeze()
    }
}
