/**
 * @module AlertContext
 */
import React from 'react'

const AlertContext = React.createContext()

/**
 * The alert context provider.
 *
 * @param {object} props - The component props object.
 *
 * @returns {React.Provider} - The alert context provider.
 *
 * @example
 * import { AlertProvider } from '@youversion/react'
 *
 * function PageContainer() {
 *   return (
 *     <AlertProvider>
 *       <PageComponent />
 *     </AlertProvider>
 *   )
 * }
 */
export function AlertProvider(props) {
  const [alerts, setAlerts] = React.useState([])

  /**
   * Dismisses an alert by removing it from the array of alerts.
   *
   * @param {number} index - The index of the alert to clear.
   */
  function handleDismissAlert(index) {
    setAlerts((prevState) => {
      const splicedAlerts = [...prevState]
      splicedAlerts.splice(index, 1)
      return splicedAlerts
    })
  }

  /**
   * Resets alerts to an empty array.
   *
   * @example
   * import { Alerts, useAlert } from '@youversion/react'
   *
   * function Component() {
   *   const { clearAlerts, throwAlert } = useAlert()
   *
   *   async function handleSave() {
   *     clearAlerts()
   *     const errors = validateContent()
   *     if (errors.length) {
   *       errors.forEach((error) => throwAlert())
   *     }
   *   }
   *
   *   return (
   *     <>
   *       <Alerts />
   *       <Button onClick={handleSave}>Save Content</Button>
   *     </>
   *   )
   * }
   */
  function clearAlerts() {
    setAlerts([])
  }

  /**
   * Throws an alert to the Alerts component.
   *
   * @param {object} newAlert - The alert object.
   * @param {string} newAlert.type - The alert type. Corresponds to MUI alert severity.
   * @param {string} newAlert.id - The alert ID. Must be a unique string.
   * @param {string} newAlert.message - The alert message.
   * @param {Function} [newAlert.onDismiss] - Custom onDismiss function. Overrides default dismiss behavior.
   * @example
   * import { Alerts, useAlert } from '@youversion/react'
   *
   * const { throwAlert } = useAlert()
   *
   * throwAlert({
   *   type: 'success',
   *   id: 'language_delete_success',
   *   message: `Language ${language.toUpperCase()} successfully deleted.`,
   *   onDismiss: someFunction,
   * })
   *
   * return (
   *   <>
   *     <Alerts />
   *     <OtherComponent />
   *   </>
   * )
   */
  const throwAlert = React.useCallback(
    ({ type, id, message, onDismiss = undefined }) => {
      if (!type || !id || !message) {
        throw new Error('Missing required alert properties.')
      }
      setAlerts((prevState) => {
        // check if alert already exists
        const alertIds = prevState.map((alert) => alert.id)
        if (alertIds.includes(id)) {
          // alert exists. do nothing.
          return prevState
        }
        // alert doesn't exist. add to array.
        return [...prevState, { type, id, message, onDismiss }]
      })
    },
    [],
  )

  const value = { alerts, throwAlert, handleDismissAlert, clearAlerts }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AlertContext.Provider value={value} {...props} />
}

/**
 * The alert context custom hook.
 *
 * @throws {Error} - Throws an error if used outside of an AlertProvider.
 *
 * @returns {React.Context} - The alert context.
 *
 * @example
 * import { useAlert } from '@youversion/react'
 *
 * // Typically only `throwAlert` will be used in your component. `alerts` and `handleDismissAlert` are already baked in to the `<Alerts />` component, but are exposed in case you want to create your own alert display UI.
 * const { alerts, throwAlert, handleDismissAlert } = useAlert()
 */
export function useAlert() {
  const context = React.useContext(AlertContext)
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider')
  }
  return context
}
