// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AnalyticsGeoContext(
  @SerialName("city")
  @ProtoNumber(1)
  @JvmField
  val city: String? = null,
  @SerialName("region")
  @ProtoNumber(2)
  @JvmField
  val region: String? = null,
  @SerialName("country")
  @ProtoNumber(3)
  @JvmField
  val country: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
