package youversion.red.banner.model

import red.platform.PlatformType
import red.platform.localization.Locales
import red.platform.localization.toLowerCase
import red.platform.platformType

enum class ParameterSchemaType {
    Int,
    Boolean,
    String,
    SetString,
    SetInt
}

private data class ParameterSchemaItem(val name: String, val type: ParameterSchemaType, val bit: Long)

object ParameterSchema {

    private val parameters = listOf(
        ParameterSchemaItem("signed_in", ParameterSchemaType.Boolean, 0),
        ParameterSchemaItem("language_tag", ParameterSchemaType.String, 1L shl 1),
        ParameterSchemaItem("app_store", ParameterSchemaType.String, 1L shl 2),
        ParameterSchemaItem("timezone", ParameterSchemaType.String, 1L shl 3),
        ParameterSchemaItem("app_version", ParameterSchemaType.String, 1L shl 4),
        ParameterSchemaItem("has_kids_app", ParameterSchemaType.Boolean, 1L shl 5),
        ParameterSchemaItem("has_lens_app", ParameterSchemaType.Boolean, 1L shl 6),
        ParameterSchemaItem("version_id", ParameterSchemaType.Int, 1L shl 7),
        ParameterSchemaItem("first_session_ever", ParameterSchemaType.Boolean, 1L shl 8),
        ParameterSchemaItem("first_session_after_update", ParameterSchemaType.Boolean, 1L shl 9),
        ParameterSchemaItem("has_highlights", ParameterSchemaType.Boolean, 1L shl 10),
        ParameterSchemaItem("has_bookmarks", ParameterSchemaType.Boolean, 1L shl 11),
        ParameterSchemaItem("has_images", ParameterSchemaType.Boolean, 1L shl 12),
        ParameterSchemaItem("has_notes", ParameterSchemaType.Boolean, 1L shl 13),
        ParameterSchemaItem("has_friends", ParameterSchemaType.Boolean, 1L shl 14),
        ParameterSchemaItem("has_subscribed_plans", ParameterSchemaType.Boolean, 1L shl 15),
        ParameterSchemaItem("has_completed_plans", ParameterSchemaType.Boolean, 1L shl 16),
        ParameterSchemaItem("has_visited_themes", ParameterSchemaType.Boolean, 1L shl 17),
        ParameterSchemaItem("user_id", ParameterSchemaType.Int, 1L shl 18),
        ParameterSchemaItem("referrer", ParameterSchemaType.String, 1L shl 19),
        ParameterSchemaItem("theme_id", ParameterSchemaType.Int, 1L shl 20),
        ParameterSchemaItem("country", ParameterSchemaType.String, 1L shl 21),
        ParameterSchemaItem("state", ParameterSchemaType.String, 1L shl 22),
        ParameterSchemaItem("city", ParameterSchemaType.String, 1L shl 23),
        ParameterSchemaItem("zip", ParameterSchemaType.String, 1L shl 24),
        ParameterSchemaItem("device_and_os", ParameterSchemaType.String, 1L shl 25),
        ParameterSchemaItem("carrier", ParameterSchemaType.String, 1L shl 26),
        ParameterSchemaItem("rate", ParameterSchemaType.Int, 1L shl 27),
        ParameterSchemaItem("placement", ParameterSchemaType.Int, 1L shl 28),
        ParameterSchemaItem("dismissible", ParameterSchemaType.Int, 1L shl 29),
        ParameterSchemaItem("creative_id", ParameterSchemaType.Int, 1L shl 30),
        ParameterSchemaItem("simple_moment", ParameterSchemaType.Boolean, 1L shl 31),
        ParameterSchemaItem("platform", ParameterSchemaType.String, 1L shl 32),
        ParameterSchemaItem("platform_version", ParameterSchemaType.String, 1L shl 33),
        ParameterSchemaItem("red_version", ParameterSchemaType.String, 1L shl 34),
        ParameterSchemaItem("red_version_code", ParameterSchemaType.Int, 1L shl 35),
        ParameterSchemaItem("type", ParameterSchemaType.String, 1L shl 36), /* ex: homefeed, help_prayer_request */
        ParameterSchemaItem("gdpr", ParameterSchemaType.Boolean, 1L shl 37)
    ).associateByTo(mutableMapOf()) { it.name }

    val parameterKeys: Set<String>
        get() = parameters.keys

    fun addSystemParameter(key: String, type: ParameterSchemaType) {
        if (platformType == PlatformType.Android || platformType == PlatformType.iOS || platformType == PlatformType.JavaScript) {
            throw IllegalStateException("This is only supported on the server")
        }
        parameters[key] = ParameterSchemaItem(key, type, 1.toLong() shl parameters.size)
    }

    fun isValidKey(key: String) = parameters.containsKey(key)
    fun getType(key: String) = parameters[key]?.type
    fun getBit(key: String) = parameters[key]?.bit

    @Suppress("unchecked_cast")
    fun toComparable(key: String, value: String): Comparable<Any> = when (parameters[key]?.type) {
        ParameterSchemaType.Int -> value.toInt() as Comparable<Any>
        ParameterSchemaType.String -> value as Comparable<Any>
        ParameterSchemaType.Boolean -> when (value.toLowerCase(Locales.getEnglish())) {
            "1" -> true
            "true" -> true
            else -> false
        } as Comparable<Any>
        else -> throw IllegalArgumentException("Unsupported comparable key: $key")
    }
}
