// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.builtins.serializer
import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.movies.api.model.chapters.Chapters
import youversion.red.movies.api.model.collections.Collection
import youversion.red.movies.api.model.collections.CollectionPresignResponse
import youversion.red.movies.api.model.collections.Collections
import youversion.red.movies.api.model.collections.DeleteCollectionRequest
import youversion.red.movies.api.model.collections.PostCollection
import youversion.red.movies.api.model.collections.ReserveCollectionResponse
import youversion.red.movies.api.model.configuration.Configuration
import youversion.red.movies.api.model.languages.Languages
import youversion.red.movies.api.model.movies.ImageTypeEnum
import youversion.red.movies.api.model.publishers.PostPublisher
import youversion.red.movies.api.model.publishers.Publisher
import youversion.red.movies.api.model.publishers.PublisherDeleteRequest
import youversion.red.movies.api.model.publishers.PublisherPresignResponse
import youversion.red.movies.api.model.publishers.Publishers
import youversion.red.movies.api.model.publishers.ReservePublisherResponse
import youversion.red.movies.api.model.videos.OrientationQSEnum
import youversion.red.movies.api.model.videos.PostVideo
import youversion.red.movies.api.model.videos.PublishAndDeleteResponse
import youversion.red.movies.api.model.videos.StatusQSEnum
import youversion.red.movies.api.model.videos.Suggestions
import youversion.red.movies.api.model.videos.Video
import youversion.red.movies.api.model.videos.VideoPublishAndDeleteRequest
import youversion.red.movies.api.model.videos.Videos

/**
 * The Videos section of the YouVersion API is where end users can consume available video content
 * and privileged users can add and update video content
 */
object MoviesApi : AbstractApi(ApiDefaults("movies", if (red.platform.platformType ==
    PlatformType.JavaScript) ContentTypes.JSON else ContentTypes.PROTO, if
    (red.platform.platformType == PlatformType.JavaScript) ContentTypes.JSON else
    ContentTypes.PROTO, "4.0", MoviesApiSerializer)) {
  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Return configuration values for video
   */
  suspend fun getConfiguration(fields: String? = null): Configuration = execute("/configuration",
      method = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false,
      queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Configuration.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param id The unique identifier.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Return video resource values
   */
  suspend fun getVideo(id: Int, fields: String? = null): Video = execute("/videos/${id}", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Video.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Mutually exclusive params:
   *   [references, title],
   *   [references, description],
   *   [references, tracking_id],
   *   [type, title],
   *   [type, description],
   *   [type, tracking_id]
   * XOR params:
   *   [language, references]
   *
   * @param id The unique identifier.
   * @return Return video resource values
   */
  suspend fun editVideo(id: Int, body: Video? = null): Video = execute("/videos/${id}", version =
      "4.0", method = RequestMethods.PUT, authAllowed = true, authOptional = false, body = body,
      bodySerializer = Video.serializer(), serializer = Video.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param id The unique identifier.
   */
  suspend fun removeVideo(id: Int, body: VideoPublishAndDeleteRequest? = null) =
      execute("/videos/${id}", version = "4.0", method = RequestMethods.DELETE, authAllowed = true,
      authOptional = false, body = body, bodySerializer = VideoPublishAndDeleteRequest.serializer(),
      serializer = Unit.serializer())

  /**
   * @param id The unique identifier.
   * @return Return video resource values
   */
  suspend fun publishVideo(id: Int, body: VideoPublishAndDeleteRequest? = null):
      PublishAndDeleteResponse = execute("/videos/${id}:publish", version = "4.0", method =
      RequestMethods.PUT, authAllowed = true, authOptional = false, body = body, bodySerializer =
      VideoPublishAndDeleteRequest.serializer(), serializer = PublishAndDeleteResponse.serializer())
      ?: throw NullPointerException("Response not sent from server")

  /**
   * Mutually exclusive params:
   *   [related, collection, status]
   *   [related, collection, publisher]
   *
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result. In some cases, page=* is acceptable if passing a limited
   * list of fields.
   * @param related Filter videos by chapter usfm
   * @param collection Filter videos by series id
   * @param publisher Filter videos by publisher id
   * @param types A comma-delimited string to be used for filtering videos to a specific set of
   * types. `*` is to be used if you want all video types. A default value of
   * `\"reenactment,artistic_explanation\"` will be used for backwards-compatibility with existing
   * clients as we add more potentially unplanned for video types.\nThe full list of available values
   * are influencer_selfie, reenactment, artistic_explanation, sermon, prayer, worship, meditation,
   * how_to, podcast, interview, tween_song, tween_head, and tween_verse_doodle.
   * @return Return video values
   */
  suspend fun getVideos(
    fields: String? = null,
    pageSize: Int? = null,
    page: String? = null,
    related: String? = null,
    collection: Int? = null,
    publisher: Int? = null,
    status: StatusQSEnum? = null,
    types: String? = null,
    orientation: OrientationQSEnum? = null
  ): Videos = execute("/videos", method = RequestMethods.GET, version = "4.0", authAllowed = false,
      authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      pageSize?.let { add("page_size", pageSize) }
      page?.let { add("page", page) }
      related?.let { add("related", related) }
      collection?.let { add("collection", collection) }
      publisher?.let { add("publisher", publisher) }
      status?.let { add("status", status.serialName) }
      types?.let { add("types", types) }
      orientation?.let { add("orientation", orientation.serialName) }
  }, serializer = Videos.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Mutually exclusive params:
   *   [id, references]
   * XOR params:
   *   [id, uuid]
   *   [id, publisher_id]
   *   [id, type]
   * Required params:
   *   created_dt
   *   source_url
   *   title
   *   language
   *   orientation
   *
   * @return Return video resource values
   */
  suspend fun addVideo(body: PostVideo? = null): PostVideo = execute("/videos", version = "4.0",
      method = RequestMethods.POST, authAllowed = true, authOptional = false, body = body,
      bodySerializer = PostVideo.serializer(), serializer = PostVideo.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param query search query
   * @param language language param
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result. In some cases, page=* is acceptable if passing a limited
   * list of fields.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return search results
   */
  suspend fun searchVideos(
    query: String,
    language: String? = null,
    page: String? = null,
    pageSize: Int? = null
  ): Videos = execute("/videos:search", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      add("query", query)
      language?.let { add("language", language) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Videos.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param query search query
   * @param language language param
   * @return suggestion results
   */
  suspend fun suggestVideos(query: String, language: String? = null): Suggestions =
      execute("/videos:suggest", method = RequestMethods.GET, version = "4.0", authAllowed = false,
      authOptional = false, queryString = red.platform.http.queryString {
      add("query", query)
      language?.let { add("language", language) }
  }, serializer = Suggestions.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param id The unique identifier.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Return collection values
   */
  suspend fun getCollection(id: Int, fields: String? = null): Collection =
      execute("/collections/${id}", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Collection.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Mutually exclusive params:
   *   [title, start]
   *   [title, end]
   *   [description, start]
   *   [description, end]
   *   [title, videos]
   *   [description, videos]
   * XOR params:
   *   [language, start]
   *   [language, end]
   *   [language, videos]
   *
   * @param id The unique identifier.
   * @return Return collection resource values
   */
  suspend fun editCollection(id: Int, body: Collection? = null): Collection =
      execute("/collections/${id}", version = "4.0", method = RequestMethods.PUT, authAllowed =
      true, authOptional = false, body = body, bodySerializer = Collection.serializer(), serializer
      = Collection.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * Delete a collection
   *
   * @param id The unique identifier.
   */
  suspend fun removeCollection(id: Int, body: DeleteCollectionRequest? = null) =
      execute("/collections/${id}", version = "4.0", method = RequestMethods.DELETE, authAllowed =
      true, authOptional = false, body = body, bodySerializer =
      DeleteCollectionRequest.serializer(), serializer = Unit.serializer())

  /**
   * @param id The unique identifier.
   * @param language language param
   * @return Return collection presign values
   */
  suspend fun presignCollection(id: Int, language: String): CollectionPresignResponse =
      execute("/collections/${id}:presign", method = RequestMethods.GET, version = "4.0",
      authAllowed = true, authOptional = false, queryString = red.platform.http.queryString {
      add("language", language)
  }, serializer = CollectionPresignResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result. In some cases, page=* is acceptable if passing a limited
   * list of fields.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @param showSeasonal Inform the API if seasonal collections should be returned.
   * @param showEmpty Inform the API if empty collections should be returned.
   * @return Return collection values
   */
  suspend fun getCollections(
    fields: String? = null,
    page: String? = null,
    pageSize: Int? = null,
    videoId: Int? = null,
    showSeasonal: Boolean? = null,
    showEmpty: Boolean? = null
  ): Collections = execute("/collections", method = RequestMethods.GET, version = "4.0", authAllowed
      = false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
      videoId?.let { add("video_id", videoId) }
      showSeasonal?.let { add("show_seasonal", showSeasonal) }
      showEmpty?.let { add("show_empty", showEmpty) }
  }, serializer = Collections.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return collection values
   */
  suspend fun addCollection(body: PostCollection? = null): PostCollection = execute("/collections",
      version = "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body
      = body, bodySerializer = PostCollection.serializer(), serializer =
      PostCollection.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @return Return collection id
   */
  suspend fun reserveCollection(): ReserveCollectionResponse = execute("/collections:reserve",
      method = RequestMethods.POST, version = "4.0", authAllowed = true, authOptional = false,
      serializer = ReserveCollectionResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param id The unique identifier.
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Return publisher values
   */
  suspend fun getPublisher(id: Int, fields: String? = null): Publisher =
      execute("/publishers/${id}", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Publisher.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * Mutually exclusive params:
   *   [language, name]
   *   [language, url]
   *   [language, tracking_id]
   *   [description, name]
   *   [description, url]
   *   [description, tracking_id]
   *
   * @param id The unique identifier.
   * @return Return publisher resource values
   */
  suspend fun editPublisher(id: Int, body: Publisher? = null): Publisher =
      execute("/publishers/${id}", version = "4.0", method = RequestMethods.PUT, authAllowed = true,
      authOptional = false, body = body, bodySerializer = Publisher.serializer(), serializer =
      Publisher.serializer()) ?: throw NullPointerException("Response not sent from server")

  /**
   * @param id The unique identifier.
   */
  suspend fun removePublisher(id: Int, body: PublisherDeleteRequest? = null) =
      execute("/publishers/${id}", version = "4.0", method = RequestMethods.DELETE, authAllowed =
      true, authOptional = false, body = body, bodySerializer = PublisherDeleteRequest.serializer(),
      serializer = Unit.serializer())

  /**
   * @param id The unique identifier.
   * @param language language param
   * @return Return publisher presign values
   */
  suspend fun presignPublisher(
    id: Int,
    language: String,
    imageType: ImageTypeEnum
  ): PublisherPresignResponse = execute("/publishers/${id}:presign", method = RequestMethods.GET,
      version = "4.0", authAllowed = true, authOptional = false, queryString =
      red.platform.http.queryString {
      add("language", language)
      add("image_type", imageType.serialName)
  }, serializer = PublisherPresignResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @param page Pagination page number of results to return. Defaults to 1. If an invalid page is
   * requested a 400 Bad Request will result. In some cases, page=* is acceptable if passing a limited
   * list of fields.
   * @param pageSize Size of page for pagination purposes. Defaults to 25. Maximum page size is
   * generally 50. Requesting a page size over 50 will result in a 400 Bad Request.
   * @return Return collection values
   */
  suspend fun getPublishers(
    fields: String? = null,
    page: String? = null,
    pageSize: Int? = null
  ): Publishers = execute("/publishers", method = RequestMethods.GET, version = "4.0", authAllowed =
      false, authOptional = false, queryString = red.platform.http.queryString {
      fields?.let { add("fields", fields) }
      page?.let { add("page", page) }
      pageSize?.let { add("page_size", pageSize) }
  }, serializer = Publishers.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @return Return publishter resource values
   */
  suspend fun addPublisher(body: PostPublisher? = null): PostPublisher = execute("/publishers",
      version = "4.0", method = RequestMethods.POST, authAllowed = true, authOptional = false, body
      = body, bodySerializer = PostPublisher.serializer(), serializer = PostPublisher.serializer())
      ?: throw NullPointerException("Response not sent from server")

  /**
   * @return Return publisher id
   */
  suspend fun reservePublisher(): ReservePublisherResponse = execute("/publishers:reserve", method =
      RequestMethods.POST, version = "4.0", authAllowed = true, authOptional = false, serializer =
      ReservePublisherResponse.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param fields A comma separated list of fields to return. Defaults to * which is all fields. A
   * 400 Bad Request will be returned if given invalid field names.
   * @return Return chapter values
   */
  suspend fun getChapters(fields: String? = null): Chapters = execute("/chapters", method =
      RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString =
      red.platform.http.queryString {
      fields?.let { add("fields", fields) }
  }, serializer = Chapters.serializer()) ?: throw
      NullPointerException("Response not sent from server")

  /**
   * @param type The type of resource to get languages for
   * @param id The id of the resource to get all languages for. If excluded, all languages for the
   * given type will be returned.
   * @return Return languages
   */
  suspend fun getLanguages(type: String, id: Int? = null): Languages = execute("/languages", method
      = RequestMethods.GET, version = "4.0", authAllowed = false, authOptional = false, queryString
      = red.platform.http.queryString {
      add("type", type)
      id?.let { add("id", id) }
  }, serializer = Languages.serializer()) ?: throw
      NullPointerException("Response not sent from server")
}
