// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.state

import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.threads.freeze

interface State : Serializable {
  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<State> = PolymorphicSerializer(State::class)
  }
}
