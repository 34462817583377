// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class SearchVersesFilters(
  /**
   * This is a list of book filter objects with the USFM book name & hit count. Ordered by count
   * descending.
   */
  @SerialName("books")
  @ProtoNumber(1)
  @JvmField
  val books: List<SearchVersesFiltersBook>? = null,
  /**
   * This is a list of canon filter objects with the canon name & hit count. Ordered by count
   * descending.
   */
  @SerialName("canons")
  @ProtoNumber(2)
  @JvmField
  val canons: List<SearchVersesFiltersCanon>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
