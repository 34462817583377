package youversion.red.settings.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.settings.deeplink.SettingsDeepLinkHandler

@ModuleDependencies
class SettingsModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(SettingsDeepLinkHandler as AbstractDeepLinkHandler)
        DeepLinks.addListeners(AnalyticsDeepLinkHandledListener)
    }
}
