package youversion.red.blue.service

import kotlinx.coroutines.delay
import red.platform.DateTime
import red.platform.Log
import red.platform.now
import red.platform.settings.Settings
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendLockOwner
import red.platform.threads.currentThread
import red.platform.threads.set
import red.platform.threads.sync
import red.platform.toDateTime
import red.platform.toMillis
import red.platform.urlEncode
import red.service.DefaultService
import youversion.red.blue.BlueFeatureStateListener
import youversion.red.blue.api.BlueApi
import youversion.red.blue.api.model.state.DeviceState
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.state.EnrollmentManager
import youversion.red.blue.state.StateManager
import youversion.red.installation.InstallationService

@DefaultService(IBlueService::class)
internal open class BlueServiceImpl : IBlueService {

    protected open val enrollmentManager = EnrollmentManager()

    private val lastUpdatedTime = AtomicReference(0L.toDateTime())
    private val installationService by InstallationService()

    override val lastUpdated: DateTime
        get() = lastUpdatedTime.value

    override fun addStateListener(listener: BlueFeatureStateListener) =
        StateManager.addListener(listener)

    override fun removeStateListener(listener: BlueFeatureStateListener) =
        StateManager.removeListener(listener)

    override fun isEnrolled(featureId: String) = enrollmentManager.isEnrolled(featureId)

    override fun getState(featureId: String): FeatureState? = StateManager.getState(featureId)

    internal fun getPendingIds() =
        Settings.redSettings.getString("blue.pending.states")?.takeIf { it.isNotEmpty() }
            ?.split(",")?.toMutableList()
            ?: mutableListOf()

    private fun List<String>.savePending() =
        Settings.redSettings.edit()
            .putString("blue.pending.states", toSet().joinToString(","))
            .commit()

    override suspend fun setState(state: FeatureState) {
        val owner = currentThread()
        enrollmentManager.lock.sync(owner) {
            StateManager.setState(state)
            val states = getPendingIds()
            states += state.featureId
            states.savePending()
            syncStates(owner)
        }
    }

    private suspend fun syncStates(owner: SuspendLockOwner) = enrollmentManager.lock.sync(owner) {
        val installationId = installationService.installationId ?: return
        val states = getPendingIds()
        states.toList().forEach {
            try {
                StateManager.getState(it)?.let {
                    setFeatureState(installationId, it)
                }
                states -= it
            } catch (e: Exception) {
                Log.e("BlueService", "Failed to set feature state", e)
            }
        }
        states.savePending()
    }

    override suspend fun waitUntilUpdated() {
        // TODO: 10 second timeout seems a bit much.
        var tries = 100
        while (lastUpdated == 0L.toDateTime() && tries > 0) {
            delay(100)
            tries--
        }
        if (tries == 0) {
            throw IllegalStateException("Failed to update")
        }
    }

    override fun getAvailableFeatures(): List<String> =
        enrollmentManager.availableEnrollmentFeatures

    protected open suspend fun setFeatureState(installationId: String, state: FeatureState) =
        BlueApi.setFeatureState(installationId.urlEncode(), state.featureId, state)

    protected open suspend fun getDeviceState(installationId: String): DeviceState =
        BlueApi.getDeviceState(installationId.urlEncode())

    override suspend fun update() {
        syncStates(currentThread())
        if (enrollmentManager.availableEnrollmentFeatures.isEmpty()) {
            lastUpdatedTime.set(now().toMillis())
            return
        }
        try {
            val enrolledStates = enrollmentManager.enroll()
            enrolledStates.forEach { StateManager.setState(it) }
            if (enrolledStates.isEmpty()) { // we didn't enroll, let's update states
                installationService.installationId?.let {
                    try {
                        getDeviceState(it)
                    } catch (e: NullPointerException) {
                        Log.w("Blue", "No device state from server")
                        null
                    }?.features?.forEach {
                        val currentState = getState(it.featureId)
                        if (currentState != it) {
                            StateManager.setState(it)
                        }
                    }
                }
            }
        } catch (e: Exception) {
            Log.w("Blue", "Failed to get latest device state", e)
        } finally {
            lastUpdatedTime.set(now().toMillis())
        }
    }

    override suspend fun clearState() {
        val features = enrollmentManager.availableEnrollmentFeatures
        StateManager.clearState(features, installationService.installationId)
    }

    override suspend fun clearEnrollment() {
        enrollmentManager.clearEnrollment(installationService.installationId)
        clearState()
    }
}
