package youversion.red.installation.api

import red.platform.http.ContentTypes
import red.platform.http.RequestMethods
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.installation.model.Installation
import youversion.red.installation.model.InstallationId

internal object InstallationApi : AbstractApi(ApiDefaults("installations", ContentTypes.PROTO, ContentTypes.PROTO)) {

    suspend fun install(installation: Installation) =
        execute(
            "/install",
            method = RequestMethods.POST,
            body = installation,
            bodySerializer = Installation.serializer(),
            serializer = InstallationId.serializer(),
            authAllowed = false
        )
}
