package youversion.red.discover.util

import youversion.red.plans.model.PlansConfigurationImageUrl

object Renditions {

    fun getImageUrlFormatSquare(id: Int, minWidth: Int, config: PlansConfigurationImageUrl?): String? {
        var width = 0
        val sizes = config?.sizes?.thumbnails ?: return null
        sizes.forEachIndexed { _, imageSize ->
            val w = imageSize.width
            if (w > minWidth) {
                width = w
            }
        }
        if (width == 0) {
            width = sizes.lastOrNull()?.width ?: minWidth
        }
        return normalizeSecureUrl(
            config.url.replace("{image_id}", id.toString()).replace("{0}", "$width").replace("{1}", "$width")
        )
    }

    private fun normalizeSecureUrl(url: String?) = when {
        url == null -> null
        url.startsWith("//") -> "https:$url"
        !url.startsWith("http") -> "https://$url"
        else -> url
    }
}
