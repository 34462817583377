/**
 * @module calcTodayVsStartDt
 */

import moment from 'moment'

/**
 * Description.
 *
 * @alias module:calcTodayVsStartDt
 *
 * @param {*} startDt
 */
export function calcTodayVsStartDt(startDt) {
  const today = moment().startOf('day')
  const startDate = moment(startDt).startOf('day')
  const diff = today.diff(startDate, 'days')
  return {
    isInFuture: diff < 0,
    isInPast: diff > 0,
    isToday: diff === 0,
    string: `${today.to(startDate)} (${startDate.format('MMM D')})`,
  }
}
