package youversion.red.downloads.model

import kotlinx.serialization.Serializable
import red.platform.threads.freeze

@Serializable
data class DownloadHeaders(val headers: Map<String, List<String>> = emptyMap()) {

    init {
        freeze()
    }

    operator fun get(header: String): String? = headers[header]?.firstOrNull()
    fun getAll(header: String): List<String> = headers[header] ?: emptyList()
}
