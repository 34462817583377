package youversion.red.podcasts.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.podcasts.deeplink.PodcastsDeepLinkHandler

@ModuleDependencies("core")
class PodcastsModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(PodcastsDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
