package youversion.red.analytics

actual class Bundle actual constructor() {

    val data = mutableMapOf<String?, Any?>()

    @JsName("putString")
    actual fun putString(key: String?, value: String?) {
        data[key] = value
    }

    @JsName("putInt")
    actual fun putInt(key: String?, value: Int) {
        data[key] = value
    }

    @JsName("putBoolean")
    actual fun putBoolean(key: String?, value: Boolean) {
        data[key] = value
    }

    @JsName("putFloat")
    actual fun putFloat(key: String?, value: Float) {
        data[key] = value
    }

    @JsName("putLong")
    actual fun putLong(key: String?, value: Long) {
        data[key] = value
    }
}
