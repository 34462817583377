package youversion.red.movies

import kotlinx.serialization.KSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.localization.Locales
import red.platform.localization.toUpperCase
import red.platform.threads.freeze

@Serializable
data class Movie(
    @ProtoNumber(1) @SerialName("description") val itemDescription: String? = null,
    @ProtoNumber(2) val title: String? = null,
    @ProtoNumber(3) val references: List<String> = emptyList(),
    @ProtoNumber(8) val runtime: Int? = null,
    @ProtoNumber(9) val id: Int? = null,
    @ProtoNumber(10) @SerialName("publisher_id") val publisherId: Int? = null,
    @ProtoNumber(11) @SerialName("tracking_id") val trackingId: String? = null,
    @Serializable(with = MovieOrientationEnumSerializer::class) @ProtoNumber(15) val orientation: MovieOrientation = MovieOrientation.Landscape
) {
    init {
        freeze()
    }
}

enum class MovieOrientation(
    internal val serialName: String,
    internal val serialId: Int
) {
    Landscape("landscape", 0),

    Portrait("portrait", 1);
}

internal class MovieOrientationEnumSerializer : KSerializer<MovieOrientation> {
    override val descriptor: SerialDescriptor =
        buildClassSerialDescriptor("youversion.red.movies.MovieOrientation")

    override fun serialize(encoder: Encoder, value: MovieOrientation) {
        if (encoder is JsonEncoder) {
            encoder.encodeString(value.serialName)
        } else {
            encoder.encodeInt(value.serialId)
        }
    }

    override fun deserialize(decoder: Decoder): MovieOrientation {
        if (decoder is JsonDecoder) {
            return MovieOrientation.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
        }
        return when (decoder.decodeInt()) {
            0 -> MovieOrientation.Landscape
            1 -> MovieOrientation.Portrait
            else -> throw IllegalArgumentException("Unknown enum value")
        }
    }
}

@Serializable
@SerialName("VideosCollection")
data class Movies(
    @ProtoNumber(1) @SerialName("videos") val movies: List<Movie> = emptyList(),
    @ProtoNumber(3) @SerialName("next_page") val hasNextPage: Boolean = false
) {
    init {
        freeze()
    }
}

@Serializable
data class MovieImageUrls(
    @ProtoNumber(1) val video: String? = null,
    @ProtoNumber(2) val collection: String? = null,
    @ProtoNumber(3) @SerialName("publisher_logo") val publisherLogo: String? = null,
    @ProtoNumber(4) @SerialName("publisher_banner") val publisherBanner: String? = null,
    @ProtoNumber(5) @SerialName("video_blur") val videoBlur: String? = null
) {
    init {
        freeze()
    }
}

@Serializable
data class MovieUrls(
    @ProtoNumber(1) val webm: String? = null,
    @ProtoNumber(2) val hls: String? = null
) {
    init {
        freeze()
    }
}

@Serializable
data class MoviesConfiguration(
    @ProtoNumber(1) @SerialName("image_urls") val imageUrls: MovieImageUrls? = null,
    @ProtoNumber(2) @SerialName("video_urls") val movieUrls: MovieUrls? = null,
    @ProtoNumber(3) @SerialName("share_url") val shareUrl: String? = null
) {
    init {
        freeze()
    }
}

@Serializable
@SerialName("Publisher")
data class MoviePublisher(
    @ProtoNumber(1) val id: Int? = null,
    @ProtoNumber(2) val name: String? = null,
    @ProtoNumber(3) val url: String? = null,
    @ProtoNumber(4) @SerialName("description") val itemDescription: String? = null,
    @ProtoNumber(5) @SerialName("tracking_id") val trackingId: String? = null
) {
    init {
        freeze()
    }
}

@Serializable
data class MovieChapters(
    @ProtoNumber(1) val chapters: List<String> = emptyList()
) {
    init {
        freeze()
    }
}

@Serializable
data class MovieLanguages(
    @ProtoNumber(1) val languages: List<String> = emptyList()
) {
    init {
        freeze()
    }
}

@Serializable
@SerialName("Collection")
data class MoviesCollection(
    @ProtoNumber(1) @SerialName("description") val itemDescription: String? = null,
    @ProtoNumber(2) val title: String? = null,
    @ProtoNumber(6) val id: Int? = null
) {
    init {
        freeze()
    }
}

@Serializable
@SerialName("CollectionsCollection")
data class MoviesCollections(
    @ProtoNumber(1) val collections: List<MoviesCollection> = emptyList(),
    @ProtoNumber(3) @SerialName("next_page") val hasNextPage: Boolean = false
) {
    init {
        freeze()
    }
}
