package youversion.red.prayer.util

import red.platform.Calendar
import red.platform.Date
import red.platform.newDate
import red.platform.settings.Settings
import red.platform.toCalendar
import red.platform.toDateTime
import red.platform.toLong
import red.platform.toMillis

object PrayerUtil {
    const val STATE_NEW = 1
    const val STATE_DIRTY = 1 shl 1
    const val STATE_DELETED = 1 shl 2

    private fun getLastPrayedKey(id: String): String {
        return "last_prayed_$id"
    }

    fun setLastPrayedTime(id: String, now: Date) {
        Settings.defaultSettings.edit().putLong(
            getLastPrayedKey(id), now.toMillis().toLong()
        ).commit()
    }

    private fun getLastPrayedTime(id: String): Long {
        return Settings.defaultSettings.getLong(
            getLastPrayedKey(id), 0L
        )
    }

    fun deleteLastPrayedKey(id: String) {
        Settings.defaultSettings.edit().remove(getLastPrayedKey(id)).commit()
    }

    fun deleteAllLastPrayedKeys() {
        val editor = Settings.defaultSettings.edit()
        Settings.defaultSettings.keys.filter { it.startsWith("last_prayed") }.forEach {
            editor.remove(it)
        }
        editor.commit()
    }

    fun prayActionEnabledForDay(id: String, calendar: Calendar = newDate().toCalendar()): Boolean {
        val lastPrayedTime = getLastPrayedTime(id)
        if (lastPrayedTime == 0L)
            return true

        val lastPrayedCal = newDate(lastPrayedTime.toDateTime()).toCalendar()
        val lastPrayedDay = lastPrayedCal.dayOfYear
        val day = calendar.dayOfYear

        if (lastPrayedCal.year < calendar.year)
            return true

        if (lastPrayedDay < day)
            return true

        val hour = calendar.hourOfDay
        val lastPrayedHour = lastPrayedCal.hourOfDay

        val alreadyPrayedInFirstAndCurrentTimeWindowOfTheDay =
            (hour in 0..11 && lastPrayedHour in 0..11)
        val alreadyPrayedInSecondAndCurrentTimeWindowOfTheDay =
            (hour in 11..23 && lastPrayedHour in 11..23)

        val enabled = !alreadyPrayedInFirstAndCurrentTimeWindowOfTheDay &&
                !alreadyPrayedInSecondAndCurrentTimeWindowOfTheDay

        if (enabled) // if we're enabling a previously disabled prayer, then it means all prayers are now enabled, because the only way this would happen is that we're in a new time window
            deleteAllLastPrayedKeys()

        return enabled
    }
}
