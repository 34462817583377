@file:Suppress("EXTENSION_SHADOWED_BY_MEMBER", "unused")
package youversion.red.plans.service

import kotlin.js.Promise
import red.tasks.CoroutineDispatchers.async
import red.tasks.toPromise
import youversion.red.plans.model.Plan

@JsName("getPlan")
fun IPlansService.getPlan(id: Int, languageTag: String?): Promise<Plan?> = async { getPlan(id, languageTag) }.toPromise()
