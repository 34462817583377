/**
 * @module youversionStyles
 */
import { createTheme } from '@material-ui/core'

export * from './theme-v3'

/**
 * Green Colors.
 *
 * @readonly
 * @deprecated Deprecated since 3.1.0. For updated YouVersion colors, `import { green } from '@youversion/react/styles/colors-v3'`.
 */
export const yvGreen = (() => {
  if (process?.env !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      "`yvGreen` is deprecated since 3.1.0. For updated YouVersion colors, `import { green } from '@youversion/react/styles/colors-v3'`.",
    )
  }

  return Object.freeze({
    '400': '#6BC66F',
    '500': '#51AC55',
    '600': '#38933C',
  })
})()

/**
 * Gray Colors.
 *
 * @readonly
 * @deprecated Deprecated since 3.1.0. For updated YouVersion colors, `import { gray } from '@youversion/react/styles/colors-v3'`.
 */
export const yvGray = (() => {
  if (process?.env !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      "`yvGray` is deprecated since 3.1.0. For updated YouVersion colors, `import { gray } from '@youversion/react/styles/colors-v3'`.",
    )
  }

  return Object.freeze({
    '400': '#F7F7F7',
    '500': '#EFEFEF',
    '600': '#D4D4D4',
  })
})()

/**
 * YouVersion Material UI Theme.
 *
 * @deprecated Deprecated since 3.1.0. Instead, please use `import { getV3ThemeObject } from '@youversion/react/styles'`.
 */
export const youversionStyles = (() => {
  if (process?.env !== 'production') {
    // eslint-disable-next-line no-console
    console.warn(
      "`youversionStyles` is deprecated since 3.1.0. Instead, please use `import { getV3ThemeObject } from '@youversion/react/styles'`.",
    )
  }

  return createTheme({
    palette: {
      primary: {
        light: yvGreen[400],
        main: yvGreen[500],
        dark: yvGreen[600],
        contrastText: '#ffffff',
      },
      secondary: {
        light: yvGray[400],
        main: yvGray[500],
        dark: yvGray[600],
        contrastText: '#333333',
      },
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontWeight: 600,
        },
        h2: {
          fontWeight: 600,
        },
        h3: {
          fontSize: '1.875rem',
          lineHeight: '2rem',
          fontWeight: 600,
        },
        h4: {
          fontSize: '1.5rem',
          lineHeight: '1.75rem',
          fontWeight: 600,
          marginTop: '0.625rem',
        },
      },
    },
  })
})()
