package youversion.red.versification

import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference

interface Versifier {

    suspend fun versify(reference: BibleReference, versionId: Int): BibleReference
}

abstract class Versification {

    abstract val versifier: Versifier
}

class VersificationTransaction(
    @Deprecated("Don't use, there are extension methods for accessing this.")
    val versification: Versification
) {

    init {
        freeze()
    }
}
