// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Image(
  /**
   * image id
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * USFMs related to the image
   */
  @SerialName("usfms")
  @ProtoNumber(2)
  @JvmField
  val usfms: List<String>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
