package youversion.red.moments.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import red.platform.Date
import red.platform.DateSerializer

@Serializable
data class MomentClientSideBehavior(
    val category: String? = null,
    @SerialName("start_dt") @Serializable(with = DateSerializer::class) val startDate: Date? = null,
    @SerialName("end_dt") @Serializable(with = DateSerializer::class) val endDate: Date? = null,
    val rrule: String? = null,
    @SerialName("has_suggestions") val hasSuggestions: Boolean = false,
    @SerialName("expanded_dt") val expanded: List<String>? = null
)
