package youversion.red.movies

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import red.platform.threads.freeze

@Serializable
data class VideoMetadata(
    @SerialName("movies_id") val moviesId: Int? = null,
    @SerialName("sub_videos") val subVideos: List<SubVideo>? = emptyList()
) {
    init {
        freeze()
    }
}

@Serializable
data class SubVideo(
    val id: Int? = null
) {
    init {
        freeze()
    }
}
