// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.users

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("users.UserRecencyEvent")
data class UserRecencyEvent(
  @SerialName("user_id")
  @ProtoNumber(1)
  val userId: Int? = null,
  @SerialName("client_id")
  @ProtoNumber(2)
  val clientId: String? = null,
  @SerialName("date")
  @ProtoNumber(3)
  val date: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("go_auth")
  @ProtoNumber(4)
  val goAuth: Boolean? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    userId: Int? = null,
    clientId: String? = null,
    date: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
    goAuth: Boolean? = null
  ) : this(userId, clientId, date, goAuth, red.platform.now())
}
