package youversion.red.downloads.service

import red.platform.Log
import red.platform.http.URL
import red.platform.io.File
import red.platform.io.fileSystem
import red.platform.network.ConnectivityImageState
import red.platform.network.ConnectivityListener
import red.platform.network.ConnectivityState
import red.platform.network.ConnectivityType
import red.platform.network.NetworkConnectivity
import red.platform.threads.AtomicReference
import red.platform.threads.Lock
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import red.platform.threads.sync
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers.async
import red.tasks.CoroutineDispatchers.launch
import red.tasks.CoroutineDispatchers.withMain
import red.tasks.Dispatchers
import youversion.red.downloads.model.DownloadHeaders
import youversion.red.downloads.model.DownloadPriority
import youversion.red.downloads.model.DownloadState

internal class ActiveDownloads {

    private val ids = AtomicReference(emptySet<Long>().freeze()).freeze()
    private val lock = Lock()

    fun contains(id: Long) = ids.value.contains(id)

    fun add(id: Long) = lock.sync {
        ids.setAssertTrue(ids.value + id)
    }

    fun remove(id: Long) = lock.sync {
        ids.setAssertTrue(ids.value - id)
    }
}

object Downloads {

    private val activeDownloads = ActiveDownloads()

    private val downloadListener = DownloadListenerImpl(activeDownloads).freeze()

    private val networkListener = ConnectivityListenerImpl().freeze()

    init {
        Dispatchers.mainDispatcher.dispatch {
            PlatformDownloads.addListener(downloadListener)
            NetworkConnectivity.addListener(networkListener)
        }
    }

    fun addListenerSync(listener: DownloadListener) {
        PlatformDownloads.addListener(listener)
    }

    suspend fun addListener(listener: DownloadListener) {
        listener.freeze()
        withMain {
            PlatformDownloads.addListener(listener)
        }
    }

    fun removeListenerSync(listener: DownloadListener) {
        PlatformDownloads.removeListener(listener)
    }

    suspend fun removeListener(listener: DownloadListener) {
        listener.freeze()
        withMain {
            PlatformDownloads.removeListener(listener)
        }
    }

    fun isActive(downloadId: Long) = activeDownloads.contains(downloadId)

    suspend fun resume() {
        val downloads = async { DownloadStore.getDownloads() }.await()
        downloads.forEach { download ->
            if (!activeDownloads.contains(download.id) &&
                (download.priority == DownloadPriority.Immediate && NetworkConnectivity.state == ConnectivityState.Online ||
                        download.priority == DownloadPriority.Background)
            ) {
                val resume = Downloader.prepareForResume(download)
                PlatformDownloads.resume(resume)
            }
        }
    }

    suspend fun download(headers: DownloadHeaders, url: URL, destination: File, priority: DownloadPriority): Long {
        // TODO: uncomment this once connectivity listener is improved
//        if (priority == DownloadPriority.Immediate && NetworkConnectivity.state != ConnectivityState.Online) {
//            throw IllegalStateException("Network is offline")
//        }
        Log.i("Downloads", "Prepping download: $url")
        val download = DownloadStore.addPendingDownload(headers, url, destination, priority)
        Log.i("Downloads", "Pending download logged: $url")
        val parent = fileSystem.getParent(download.destination)
            ?: throw IllegalArgumentException("Must have a parent file")
        if (!fileSystem.exists(parent) && !fileSystem.mkdirs(parent)) {
            throw IllegalStateException("Failed to create directory")
        }
        Log.i("Downloads", "Launching download: $url")
        launch(CoroutineDispatchContext.Network) {
            PlatformDownloads.download(download)
        }
        Log.i("Downloads", "Download launched (${download.id}): $url")
        return download.id
    }

    suspend fun cancel(id: Long) {
        val download = DownloadStore.getDownload(id) ?: return
        PlatformDownloads.cancel(download)
        fileSystem.deleteFile(download.destination)
        DownloadStore.deleteDownload(id)
    }
}

internal class ConnectivityListenerImpl : ConnectivityListener {

    private val lastState = AtomicReference(NetworkConnectivity.state)

    init {
        freeze()
    }

    override fun onImageStateChanged(state: ConnectivityImageState) {
    }

    override fun onStateChanged(state: ConnectivityState) {
        if (lastState.value != state) {
            lastState.setAssertTrue(state)
            if (state == ConnectivityState.Online) {
                launch { Downloads.resume() }
            }
        }
    }

    override fun onTypeChanged(type: ConnectivityType) {
        // TODO: think about what we should actually be downloading right now
    }
}

internal class DownloadListenerImpl(private val activeDownloads: ActiveDownloads) : DownloadListener {

    init {
        freeze()
    }

    override fun onProgress(downloadId: Long, received: Long, size: Long) {
    }

    override fun onDownloadStarted(downloadId: Long, identifier: String?) {
        launch {
            activeDownloads.add(downloadId)
            DownloadStore.setDownloadStateAndIdentifier(downloadId, DownloadState.Downloading, identifier)
        }
    }

    override fun onDownloadFailed(downloadId: Long, error: Exception) {
        launch {
            activeDownloads.remove(downloadId)
            DownloadStore.setDownloadState(downloadId, DownloadState.Failed)
        }
    }

    override fun onDownloadComplete(downloadId: Long) {
        launch {
            activeDownloads.remove(downloadId)
            DownloadStore.deleteDownload(downloadId)
        }
    }
}
