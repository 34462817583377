// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.lessons

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class Status(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  DRAFT("draft", 1),

  LIVE("live", 2);
}

internal class StatusSerializer : KSerializer<Status> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.stories.api.model.lessons.Status")

  override fun serialize(encoder: Encoder, value: Status) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): Status {
    if (decoder is JsonDecoder) {
      return Status.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> Status.UNSUPPORTED_PLACEHOLDER
      1 -> Status.DRAFT
      2 -> Status.LIVE
      else -> Status.UNSUPPORTED_PLACEHOLDER
    }
  }
}
