// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Boolean
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class TranslatableString(
  /**
   * Unique/primary key
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  /**
   * English text to translate
   */
  @SerialName("source")
  @ProtoNumber(2)
  @JvmField
  val source: String? = null,
  /**
   * This is a few sentence description about the string to translate. It is helpful for the
   * translators to understand what they are translating.
   */
  @SerialName("context")
  @ProtoNumber(3)
  @JvmField
  val context: String? = null,
  /**
   * This is the unique key for the string in the remote translation tool (Crowdin, etc).
   */
  @SerialName("remote_string_id")
  @ProtoNumber(4)
  @JvmField
  val remoteStringId: String? = null,
  /**
   * This is a boolean value indicating whether plural forms should be used in the translation.
   */
  @SerialName("plural_forms")
  @ProtoNumber(5)
  @JvmField
  val pluralForms: Boolean? = null,
  /**
   * This is a boolean value indicating whether the string should be uploaded to the remote
   * translation tool for tranlation or not. If "false", the string will not be translated. Defaults to
   * "true".
   */
  @SerialName("translate")
  @ProtoNumber(6)
  @JvmField
  val translate: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
