// Auto-Generated from OpenAPI Spec
package youversion.red.giving.api.model.events

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.giving.api.model.InputMethodType

@Serializable
data class DonateAction(
  /**
   * Gift amount.
   */
  @SerialName("amount")
  @ProtoNumber(1)
  val amount: String? = null,
  @SerialName("type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.giving.api.model.InputMethodTypeSerializer::class)
  val type: InputMethodType? = null
) : Event(), red.Serializable {
  override val key: String = "DonateAction"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    amount?.let { bundle.putString("amount", amount) }
    type?.let { bundle.putString("type", type.serialName) }
    return bundle
  }
}
