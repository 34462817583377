// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class I18n(
  /**
   * The iso 639 language tag for the internationalization of the organization.
   */
  @SerialName("language_tag")
  @ProtoNumber(1)
  @JvmField
  val languageTag: String? = null,
  /**
   * This is the description of the organization and the target part of the world the organization
   * is reaching using this language and possibly a specific url.
   */
  @SerialName("description")
  @ProtoNumber(2)
  @JvmField
  val description: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
