// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Boolean
import kotlin.Long
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PrayerUser(
  /**
   * Id that identifies a user in the YouVersion eco-system.  Can be used to lookup name and/or show
   * avatar image.
   */
  @SerialName("user_id")
  @ProtoNumber(1)
  @JvmField
  val userId: Long? = null,
  /**
   * When the creator of the prayer has added a text update to the prayer this will be true until
   * the user clicks on the prayer.
   */
  @SerialName("is_updated")
  @ProtoNumber(2)
  @JvmField
  val isUpdated: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
