// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine who can view a membership to an organization
 */
enum class VisibilityPreference(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  EVERYONE("everyone", 1),

  FRIENDS("friends", 2),

  NONE("none", 3);
}

internal class VisibilityPreferenceSerializer : KSerializer<VisibilityPreference> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.VisibilityPreference")

  override fun serialize(encoder: Encoder, value: VisibilityPreference) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): VisibilityPreference {
    if (decoder is JsonDecoder) {
      return VisibilityPreference.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> VisibilityPreference.UNSUPPORTED_PLACEHOLDER
      1 -> VisibilityPreference.EVERYONE
      2 -> VisibilityPreference.FRIENDS
      3 -> VisibilityPreference.NONE
      else -> VisibilityPreference.UNSUPPORTED_PLACEHOLDER
    }
  }
}
