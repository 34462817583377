package youversion.red.downloads.service

import red.listeners.Listeners
import red.platform.io.File
import red.platform.io.InputStream
import red.platform.io.fileSystem
import red.platform.threads.freeze
import youversion.red.downloads.model.Download

class ResumeState(val download: Download, val fileLength: Long) {
    init {
        freeze()
    }
}

internal object Downloader {

    suspend fun prepareForResume(download: Download) = if (fileSystem.exists(download.destination)) {
        val fileLength = fileSystem.getFileLength(download.destination)
        ResumeState(download, fileLength)
    } else {
        ResumeState(download, 0)
    }

    @Suppress("unused_parameter")
    suspend fun download(
        listeners: Listeners<DownloadListener>,
        downloadId: Long,
        identifier: String?,
        inputStream: InputStream,
        currentPosition: Long,
        totalContentLength: Long,
        outputFile: File
    ) {
        val buf = ByteArray(8096)
        var segments = 0
        val fileOut = if (currentPosition > 0) {
            fileSystem.newOutputStream(outputFile, true)
        } else {
            fileSystem.newOutputStream(outputFile, false)
        }
        try {
            listeners.dispatchNotifyListeners {
                it.onProgress(downloadId, 0, totalContentLength)
            }
            var downloadedBytes = 0L
            var read: Int
            do {
                read = inputStream.read(buf)
                if (read == -1) {
                    break
                }
                downloadedBytes += read
                segments++
                fileOut.write(buf, 0, read)
                if (segments % 50 == 0) {
                    listeners.dispatchNotifyListeners {
                        it.onProgress(downloadId, downloadedBytes, totalContentLength)
                    }
                }
            } while (true)
            if (downloadedBytes != totalContentLength && totalContentLength != -1L) {
                throw IllegalStateException("Failed to download all bytes")
            }
            fileOut.flush()
            fileOut.close()
            listeners.dispatchNotifyListeners {
                it.onProgress(downloadId, downloadedBytes, totalContentLength)
                it.onDownloadComplete(downloadId)
            }
        } catch (e: Exception) {
            e.freeze()
            listeners.dispatchNotifyListeners {
                it.onDownloadFailed(downloadId, e)
            }
            fileOut.close()
        }
    }
}
