// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class VideoTypeEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  INFLUENCER_SELFIE("influencer_selfie", 1),

  REENACTMENT("reenactment", 2),

  ARTISTIC_EXPLANATION("artistic_explanation", 3),

  SERMON("sermon", 4),

  PRAYER("prayer", 5),

  WORSHIP("worship", 6),

  MEDITATION("meditation", 7),

  HOW_TO("how_to", 8),

  PODCAST("podcast", 9),

  INTERVIEW("interview", 10),

  TWEEN_SONG("tween_song", 11),

  TWEEN_HEAD("tween_head", 12),

  TWEEN_VERSE_DOODLE("tween_verse_doodle", 13);
}

internal class VideoTypeEnumSerializer : KSerializer<VideoTypeEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.videos.VideoTypeEnum")

  override fun serialize(encoder: Encoder, value: VideoTypeEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): VideoTypeEnum {
    if (decoder is JsonDecoder) {
      return VideoTypeEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> VideoTypeEnum.UNSUPPORTED_PLACEHOLDER
      1 -> VideoTypeEnum.INFLUENCER_SELFIE
      2 -> VideoTypeEnum.REENACTMENT
      3 -> VideoTypeEnum.ARTISTIC_EXPLANATION
      4 -> VideoTypeEnum.SERMON
      5 -> VideoTypeEnum.PRAYER
      6 -> VideoTypeEnum.WORSHIP
      7 -> VideoTypeEnum.MEDITATION
      8 -> VideoTypeEnum.HOW_TO
      9 -> VideoTypeEnum.PODCAST
      10 -> VideoTypeEnum.INTERVIEW
      11 -> VideoTypeEnum.TWEEN_SONG
      12 -> VideoTypeEnum.TWEEN_HEAD
      13 -> VideoTypeEnum.TWEEN_VERSE_DOODLE
      else -> VideoTypeEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
