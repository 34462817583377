// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.stories.api.model.events.StoryModuleView
import youversion.red.stories.api.model.modules.BaseModule
import youversion.red.stories.api.model.modules.CompletionModule
import youversion.red.stories.api.model.modules.CustomDevotionalModule
import youversion.red.stories.api.model.modules.GreetingModule
import youversion.red.stories.api.model.modules.PlanModule
import youversion.red.stories.api.model.modules.PostBaseModule
import youversion.red.stories.api.model.modules.PostCompletionModule
import youversion.red.stories.api.model.modules.PostCustomDevotionalModule
import youversion.red.stories.api.model.modules.PostGreetingModule
import youversion.red.stories.api.model.modules.PostPlanModule
import youversion.red.stories.api.model.modules.PostPrayerModule
import youversion.red.stories.api.model.modules.PostReflectionModule
import youversion.red.stories.api.model.modules.PostScriptureImageModule
import youversion.red.stories.api.model.modules.PostScriptureModule
import youversion.red.stories.api.model.modules.PostSermonModule
import youversion.red.stories.api.model.modules.PostUpNextModule
import youversion.red.stories.api.model.modules.PostVideoModule
import youversion.red.stories.api.model.modules.PrayerModule
import youversion.red.stories.api.model.modules.ReflectionModule
import youversion.red.stories.api.model.modules.ScriptureImageModule
import youversion.red.stories.api.model.modules.ScriptureModule
import youversion.red.stories.api.model.modules.SermonModule
import youversion.red.stories.api.model.modules.UpNextModule
import youversion.red.stories.api.model.modules.VideoModule
import youversion.red.stories.deeplink.StoriesDeepLinkHandler

object StoriesApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostScriptureModule::class,
          youversion.red.stories.api.model.modules.PostScriptureModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostPrayerModule::class,
          youversion.red.stories.api.model.modules.PostPrayerModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostGreetingModule::class,
          youversion.red.stories.api.model.modules.PostGreetingModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostPlanModule::class,
          youversion.red.stories.api.model.modules.PostPlanModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostVideoModule::class,
          youversion.red.stories.api.model.modules.PostVideoModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostSermonModule::class,
          youversion.red.stories.api.model.modules.PostSermonModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostReflectionModule::class,
          youversion.red.stories.api.model.modules.PostReflectionModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostScriptureImageModule::class,
          youversion.red.stories.api.model.modules.PostScriptureImageModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostUpNextModule::class,
          youversion.red.stories.api.model.modules.PostUpNextModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostCompletionModule::class,
          youversion.red.stories.api.model.modules.PostCompletionModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.PostBaseModule::class,
          youversion.red.stories.api.model.modules.PostCustomDevotionalModule::class,
          youversion.red.stories.api.model.modules.PostCustomDevotionalModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.ScriptureModule::class,
          youversion.red.stories.api.model.modules.ScriptureModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.PrayerModule::class,
          youversion.red.stories.api.model.modules.PrayerModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.GreetingModule::class,
          youversion.red.stories.api.model.modules.GreetingModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.PlanModule::class,
          youversion.red.stories.api.model.modules.PlanModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.VideoModule::class,
          youversion.red.stories.api.model.modules.VideoModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.SermonModule::class,
          youversion.red.stories.api.model.modules.SermonModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.ReflectionModule::class,
          youversion.red.stories.api.model.modules.ReflectionModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.ScriptureImageModule::class,
          youversion.red.stories.api.model.modules.ScriptureImageModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.UpNextModule::class,
          youversion.red.stories.api.model.modules.UpNextModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.CompletionModule::class,
          youversion.red.stories.api.model.modules.CompletionModule.serializer())
      polymorphic(youversion.red.stories.api.model.modules.BaseModule::class,
          youversion.red.stories.api.model.modules.CustomDevotionalModule::class,
          youversion.red.stories.api.model.modules.CustomDevotionalModule.serializer())
      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; classDiscriminator = "_type";  serializersModule =
      context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
