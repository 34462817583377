// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PrayerList(
  /**
   * Unique identifier
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Title of the prayer list created by user.
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * This is the date when the prayer list was first created.
   */
  @SerialName("created_dt")
  @ProtoNumber(3)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
