// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.colors

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.stories.api.model.lessons.Cohort

@Serializable
data class Color(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("hex")
  @ProtoNumber(2)
  @JvmField
  val hex: String? = null,
  @SerialName("cohorts")
  @ProtoNumber(3)
  @JvmField
  val cohorts: List<Cohort>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
