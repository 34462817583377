// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bafk

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bafk.KidsSetCompletedEvent")
data class KidsSetCompletedEvent(
  @SerialName("set")
  @ProtoNumber(1)
  val set: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(set: String? = null) : this(set, red.platform.now())
}
