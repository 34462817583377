package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentHideVerseColorsResponse(
    @ProtoNumber(1) val hidden: Boolean = false
)

@Serializable
data class HideVerseColors(
    @ProtoNumber(1) val versionId: Long? = null,
    @ProtoNumber(2) val usfm: List<String>? = null
)
