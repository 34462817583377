package youversion.red.analytics.alps

import red.Red
import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleListener
import red.lifecycle.AppLifecycleState
import red.platform.Log
import red.platform.LogLevel
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.set
import red.tasks.CoroutineDispatchers.launch
import youversion.red.analytics.logSuspend
import youversion.red.dataman.api.model.users.UserRecencyEvent
import youversion.red.security.User
import youversion.red.security.service.UsersService
import youversion.red.security.service.UsersServiceListener

class AlpsListener : AppLifecycleListener, UsersServiceListener {

    private val usersService by UsersService()
    private val lastState = AtomicReference(AppLifecycleState.Unknown).freeze()

    override fun onStateChanged(state: AppLifecycleState, source: AppLifecycleEventSource) {
        lastState.set(state)
        launch {
            if (Log.level == LogLevel.DEBUG) {
                Log.d("AppLifecycle", "Logging state change: $state")
            }
            val user = usersService.getCurrentUser()
            AlpsApi.send(AlpsEvent(user?.euid, state = state, source = source, appCode = Red.versionCode))
            if (Log.level == LogLevel.DEBUG) {
                Log.d("AppLifecycle", "Logged state change: $state")
            }
        }
    }

    override fun onSessionStarted() {
        launch { onUserRecency() }
    }

    override suspend fun onLogin(user: User) {
        launch {
            try {
                onUserRecency()
                AlpsApi.send(
                    AlpsEvent(
                        user.euid,
                        state = lastState.value,
                        source = AppLifecycleEventSource.Login,
                        appCode = Red.versionCode
                    )
                )
            } catch (e: Exception) {
                Log.e("AlpsListener", "Error handling login event", e)
            }
        }
    }

    private suspend fun onUserRecency() {
        Log.d("AppLifecycle", "Logging new session")
        UserRecencyEvent().logSuspend()
    }
}
