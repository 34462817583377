// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PublishAndDeleteResponse(
  /**
   * video id
   */
  @SerialName("id")
  @ProtoNumber(9)
  @JvmField
  val id: Int? = null,
  /**
   * title text
   */
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * description text
   */
  @SerialName("description")
  @ProtoNumber(1)
  @JvmField
  val description: String? = null,
  @SerialName("references")
  @ProtoNumber(3)
  @JvmField
  val references: List<String>? = null,
  @SerialName("publisher_id")
  @ProtoNumber(10)
  @JvmField
  val publisherId: Int? = null,
  @SerialName("runtime")
  @ProtoNumber(8)
  @JvmField
  val runtime: Int? = null,
  @SerialName("tracking_id")
  @ProtoNumber(11)
  @JvmField
  val trackingId: String? = null,
  @SerialName("status")
  @ProtoNumber(13)
  @Serializable(with=youversion.red.movies.api.model.videos.StatusEnumSerializer::class)
  @JvmField
  val status: StatusEnum? = null,
  @SerialName("orientation")
  @ProtoNumber(15)
  @Serializable(with=youversion.red.movies.api.model.videos.OrientationEnumSerializer::class)
  @JvmField
  val orientation: OrientationEnum? = null,
  @SerialName("type")
  @ProtoNumber(16)
  @Serializable(with=youversion.red.movies.api.model.videos.VideoTypeEnumSerializer::class)
  @JvmField
  val type: VideoTypeEnum? = null
) : red.Serializable {
  init {
    freeze()
  }
}
