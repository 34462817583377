package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date

public data class Prayer_reaction(
  public val prayerClientId: String,
  public val prayerServerId: Int?,
  public val userId: Long,
  public val total: Int,
  public val needsSyncing: Boolean,
  public val updatedDt: Date
) {
  public override fun toString(): String = """
  |Prayer_reaction [
  |  prayerClientId: $prayerClientId
  |  prayerServerId: $prayerServerId
  |  userId: $userId
  |  total: $total
  |  needsSyncing: $needsSyncing
  |  updatedDt: $updatedDt
  |]
  """.trimMargin()

  public class Adapter(
    public val updatedDtAdapter: ColumnAdapter<Date, Long>
  )
}
