// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model.accounts

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * An enumerator to determine the current state of an Account
 */
enum class AccountStatus(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  INVITED("invited", 1),

  ACTIVE("active", 2),

  DELETED("deleted", 3);
}

internal class AccountStatusSerializer : KSerializer<AccountStatus> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.accounts.AccountStatus")

  override fun serialize(encoder: Encoder, value: AccountStatus) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): AccountStatus {
    if (decoder is JsonDecoder) {
      return AccountStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> AccountStatus.UNSUPPORTED_PLACEHOLDER
      1 -> AccountStatus.INVITED
      2 -> AccountStatus.ACTIVE
      3 -> AccountStatus.DELETED
      else -> AccountStatus.UNSUPPORTED_PLACEHOLDER
    }
  }
}
