// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GuidedPrayerCta(
  /**
   * Text for a button/link
   */
  @SerialName("text")
  @ProtoNumber(1)
  @JvmField
  val text: String? = null,
  /**
   * The URL for a (deep/)link
   */
  @SerialName("url")
  @ProtoNumber(2)
  @JvmField
  val url: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
