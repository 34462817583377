// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Feature(
  /**
   * Primary key identifier of feature
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Name of feature
   */
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  /**
   * Link / deeplink to feature
   */
  @SerialName("link")
  @ProtoNumber(3)
  @JvmField
  val link: String = "",
  /**
   * ISO 639 date & time feature name or link was last updated
   */
  @SerialName("updated_dt")
  @ProtoNumber(4)
  @JvmField
  val updated_dt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This tells the API what the original source of the features was. Currently should be either
   * "admin" or "spec".
   */
  @SerialName("source")
  @ProtoNumber(5)
  @Serializable(with=youversion.red.search.api.model.FeatureSourceSerializer::class)
  @JvmField
  val source: FeatureSource? = null
) : red.Serializable {
  init {
    freeze()
  }
}
