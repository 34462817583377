package youversion.red.moments.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer
import red.platform.threads.freeze
import youversion.red.security.User

@Serializable
data class MomentComment(
    @ProtoNumber(1) val id: Long? = null,
    @ProtoNumber(2) val content: String? = null,
    @SerialName("created_dt")
    @ProtoNumber(3) @Serializable(with = DateSerializer::class) val created: Date? = null,
    @SerialName("updated_dt")
    @ProtoNumber(4) @Serializable(with = DateSerializer::class) val updated: Date? = null,
    @SerialName("user_id")
    @ProtoNumber(5) val userId: Int = 0,
    @Transient val user: User? = null
) {

    init {
        freeze()
    }
}

@Serializable
data class MomentCommenting(
    @ProtoNumber(1) val comments: List<MomentComment>? = emptyList(),
    @ProtoNumber(2) val enabled: Boolean = false,
    @ProtoNumber(3) val total: Int = 0
) {

    init {
        freeze()
    }
}
