package youversion.red.prayer.guided.service

import red.platform.Log
import red.service.ServiceRegistry
import red.tasks.CoroutineDispatchers.launch
import youversion.red.cache.CacheClearListener
import youversion.red.cache.CacheType

internal class GuidedPrayerCacheClearListener : CacheClearListener {
    override fun onClearCache(type: CacheType) {
        if (type == CacheType.GUIDES) {
            launch {
                Log.w("CacheListener", "Clearing cache: $type")
                ServiceRegistry[IGuidedPrayerService::class].clearCache()
            }
        }
    }
}
