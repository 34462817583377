// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Topics(
  /**
   * A list of Topic resource objects
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val topics: List<Topic>? = null,
  @SerialName("did_you_mean")
  @ProtoNumber(4)
  @JvmField
  val did_you_mean: List<String>? = null,
  /**
   * If a correction was applied, search for the original query instead.
   */
  @SerialName("search_instead_for")
  @ProtoNumber(5)
  @JvmField
  val search_instead_for: String? = null,
  /**
   * This is the actual query the server used to get the results it returned. Normally, it would be
   * the same as the query the user input, but if it is a spelling mistake, the API might auto-correct
   * it and run a different query than what the user input.
   */
  @SerialName("query")
  @ProtoNumber(6)
  @JvmField
  val query: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
