// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.configuration

import kotlin.Boolean
import kotlin.Float
import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Feature(
  @SerialName("enrollment")
  @ProtoNumber(1)
  @JvmField
  val enrollment: Int? = null,
  @SerialName("enrollmentRequired")
  @ProtoNumber(2)
  @JvmField
  val enrollmentrequired: Boolean? = null,
  @SerialName("distribution")
  @ProtoNumber(3)
  @JvmField
  val distribution: Float? = null,
  @SerialName("max")
  @ProtoNumber(4)
  @JvmField
  val max: Int? = null,
  @SerialName("current")
  @ProtoNumber(5)
  @JvmField
  val current: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
