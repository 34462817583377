// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Float
import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

@Serializable
data class ChurchPostMutation(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String? = null,
  @SerialName("website_url")
  @ProtoNumber(3)
  @JvmField
  val websiteUrl: String? = null,
  @SerialName("phone")
  @ProtoNumber(4)
  @JvmField
  val phone: String? = null,
  @SerialName("email")
  @ProtoNumber(5)
  @JvmField
  val email: String? = null,
  /**
   * This is the default hex color code for the background color of an organization if none is
   * supplied the hex color for Regent Grey will be used as the default.
   */
  @SerialName("background_color")
  @ProtoNumber(7)
  @JvmField
  val backgroundColor: String? = null,
  @SerialName("denomination")
  @ProtoNumber(8)
  @JvmField
  val denomination: String? = null,
  @SerialName("weekly_attendance")
  @ProtoNumber(9)
  @JvmField
  val weeklyAttendance: Int? = null,
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus? = null,
  @SerialName("address_line_1")
  @ProtoNumber(14)
  @JvmField
  val addressLine1: String? = null,
  @SerialName("address_line_2")
  @ProtoNumber(15)
  @JvmField
  val addressLine2: String? = null,
  @SerialName("city")
  @ProtoNumber(16)
  @JvmField
  val city: String? = null,
  @SerialName("state")
  @ProtoNumber(17)
  @JvmField
  val state: String? = null,
  @SerialName("postal_code")
  @ProtoNumber(18)
  @JvmField
  val postalCode: String? = null,
  @SerialName("country")
  @ProtoNumber(19)
  @JvmField
  val country: String? = null,
  @SerialName("timezone")
  @ProtoNumber(20)
  @JvmField
  val timezone: String? = null,
  @SerialName("latitude")
  @ProtoNumber(21)
  @JvmField
  val latitude: Float? = null,
  @SerialName("longitude")
  @ProtoNumber(22)
  @JvmField
  val longitude: Float? = null,
  /**
   * Who or what referred the organization to sign up for yv4c
   */
  @SerialName("signup_referrer")
  @ProtoNumber(23)
  @JvmField
  val signupReferrer: String? = null,
  /**
   * The primary language of the organization
   */
  @SerialName("primary_language")
  @ProtoNumber(24)
  @JvmField
  val primaryLanguage: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
