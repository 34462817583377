// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bafk

import kotlin.Boolean
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bafk.StoryDownloadResultEvent")
data class StoryDownloadResultEvent(
  @SerialName("story_id")
  @ProtoNumber(1)
  val storyId: String? = null,
  @SerialName("download_succeeded")
  @ProtoNumber(2)
  val downloadSucceeded: Boolean? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(storyId: String? = null, downloadSucceeded: Boolean? = null) : this(storyId,
      downloadSucceeded, red.platform.now())
}
