// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class DiscoverSectionType(
  internal val serialName: String,
  internal val serialId: Int
) {
  TEACHING_CLIPS("teaching-clips", 0),

  FEATURED_VIDEOS("featured-videos", 1),

  BECAUSE_YOU_READ_PLANS("because-you-read-plans", 2),

  TRENDING_PLANS("trending-plans", 3),

  FEATURED_PLANS("featured-plans", 4),

  FRIENDS_PLANS("friends-plans", 5),

  EMOTIONS("emotions", 6),

  DISCOVER_MORE("discover-more", 7),

  SEARCH_BAR("search-bar", 8),

  FEATURED_PODCASTS("featured-podcasts", 9),

  CONTINUE_LISTENING("continue-listening", 10);
}

internal class DiscoverSectionTypeSerializer : KSerializer<DiscoverSectionType> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.DiscoverSectionType")

  override fun serialize(encoder: Encoder, value: DiscoverSectionType) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): DiscoverSectionType {
    if (decoder is JsonDecoder) {
      return DiscoverSectionType.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> DiscoverSectionType.TEACHING_CLIPS
      1 -> DiscoverSectionType.FEATURED_VIDEOS
      2 -> DiscoverSectionType.BECAUSE_YOU_READ_PLANS
      3 -> DiscoverSectionType.TRENDING_PLANS
      4 -> DiscoverSectionType.FEATURED_PLANS
      5 -> DiscoverSectionType.FRIENDS_PLANS
      6 -> DiscoverSectionType.EMOTIONS
      7 -> DiscoverSectionType.DISCOVER_MORE
      8 -> DiscoverSectionType.SEARCH_BAR
      9 -> DiscoverSectionType.FEATURED_PODCASTS
      10 -> DiscoverSectionType.CONTINUE_LISTENING
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
