package youversion.red.banner.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core", "geoip")
class BannerModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
