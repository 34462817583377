// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class PrayerCommentAction(
  /**
   * Id of the prayer that has been commented on.
   */
  @SerialName("prayer_id")
  @ProtoNumber(1)
  val prayerId: Int? = null
) : Event(), red.Serializable {
  override val key: String = "PrayerCommentAction"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    prayerId?.let { bundle.putInt("prayer_id", prayerId) }
    return bundle
  }
}
