package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.DateSerializer

@Serializable
data class MomentUpdateResponse(
    @ProtoNumber(1) val id: Long,
    @ProtoNumber(2) val base: MomentBase? = null,
    @ProtoNumber(3) val commenting: MomentCommenting? = null,
    @ProtoNumber(4) @Serializable(with = DateSerializer::class) val created: Date? = null,
    @ProtoNumber(5) val extras: MomentExtras? = null,
    @ProtoNumber(6) val kindColor: String? = null,
    @ProtoNumber(7) val kindId: String? = null,
    @ProtoNumber(8) val liking: MomentLiking? = null,
    @ProtoNumber(9) @Serializable(with = DateSerializer::class) val updated: Date? = null
)
