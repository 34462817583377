// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.podcasts

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.media.AbstractMediaCompleteEvent

@Serializable
@SerialName("podcasts.EpisodeAudioCompleteEvent")
data class EpisodeAudioCompleteEvent(
  /**
   * The episode title 
   */
  @SerialName("episode_author")
  @ProtoNumber(1)
  val episodeAuthor: String? = null,
  /**
   * The episode title 
   */
  @SerialName("episode_title")
  @ProtoNumber(2)
  val episodeTitle: String? = null,
  /**
   * The unique id of the podcast episode the user is interacting with
   */
  @SerialName("episode_id")
  @ProtoNumber(3)
  val episodeId: Int? = null,
  /**
   * App interface language
   */
  @SerialName("app_language")
  @ProtoNumber(4)
  val appLanguage: String? = null,
  /**
   * session for this media event
   */
  @SerialName("mediaSessionId")
  @ProtoNumber(50)
  override val mediasessionid: String? = null,
  /**
   * seconds skipped while playing
   */
  @SerialName("secondsSkipped")
  @ProtoNumber(51)
  override val secondsskipped: Int? = null,
  /**
   * seconds played
   */
  @SerialName("secondsPlayed")
  @ProtoNumber(52)
  override val secondsplayed: Int? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractMediaCompleteEvent(), red.Serializable {
  init {
    freeze()
  }
}
