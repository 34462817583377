package red.platform.threads

typealias SuspendLockOwner = Thread

expect class SuspendedLock() {

    suspend fun lock(owner: SuspendLockOwner)
    fun unlock(owner: SuspendLockOwner)
}

suspend inline fun <T> SuspendedLock.sync(owner: SuspendLockOwner = currentThread(), block: () -> T): T {
    return try {
        lock(owner)
        block()
    } finally {
        unlock(owner)
    }
}
