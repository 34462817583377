// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.modules

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("modules.PlanModule")
data class PlanModule(
  @SerialName("plan_id")
  @ProtoNumber(3)
  val planId: Int? = null,
  @SerialName("plan_day")
  @ProtoNumber(4)
  val planDay: Int? = null,
  @SerialName("id")
  @ProtoNumber(1)
  override val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  override val title: String? = null
) : BaseModule, red.Serializable {
  init {
    freeze()
  }
}
