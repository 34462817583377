package youversion.red.geoip.service

import red.platform.currentTimeMillis
import red.platform.threads.AtomicReference
import red.platform.threads.SuspendedLock
import red.platform.threads.freeze
import red.platform.threads.set
import red.platform.threads.sync
import red.platform.toLong
import red.service.DefaultService
import youversion.red.geoip.api.GeoIPApi
import youversion.red.geoip.model.GeoIP

private class GeoIPCacheItem(val geoIP: GeoIP) {

    private val created = currentTimeMillis()

    init {
        freeze()
    }

    val expired: Boolean
        get() = created.toLong() + 300000 <= currentTimeMillis().toLong()
}

@DefaultService(IGeoIPService::class)
internal class GeoIPServiceImpl : IGeoIPService {

    private val lock = SuspendedLock()
    private val geoIPCache = AtomicReference<GeoIPCacheItem?>(null)

    private fun getFromCache(): GeoIP? {
        geoIPCache.value?.let {
            if (!it.expired) {
                return it.geoIP
            }
        }
        return null
    }

    override suspend fun getGeoIP(): GeoIP? {
        getFromCache()?.let { return it }
        lock.sync {
            getFromCache()?.let { return it }
            GeoIPApi.getGeoIP()?.let {
                geoIPCache.set(GeoIPCacheItem(it))
                return it
            }
        }
        return null
    }
}
