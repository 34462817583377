// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PrayerParticipant(
  @SerialName("status")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.prayer.api.model.StatusTypeSerializer::class)
  @JvmField
  val status: StatusType? = null,
  /**
   * This is the date when the prayer update was seen.
   */
  @SerialName("seen_update")
  @ProtoNumber(2)
  @JvmField
  val seenUpdate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer overview was seen.
   */
  @SerialName("seen_overview")
  @ProtoNumber(3)
  @JvmField
  val seenOverview: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer was deleted for the participant.
   */
  @SerialName("deleted")
  @ProtoNumber(4)
  @JvmField
  val deleted: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer was revoked for the participant.
   */
  @SerialName("revoked_dt")
  @ProtoNumber(5)
  @JvmField
  val revokedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
