/* eslint-disable max-classes-per-file */

export class AuthenticationError extends Error {
  constructor(response = {}) {
    const { error_name: name, error_description: desc } = response
    const serverMessage = `${name} : ${desc}`
    super(`Authentication Failed. Server said: '${serverMessage}'`)

    this.name = 'AuthenticationError'
    this.serverMessage = serverMessage
  }
}

export class NotFoundError extends Error {
  constructor(url, response = {}) {
    const { message = '' } = response

    super(`API not found at '${url}'. Server said: '${message}'`)

    this.name = 'NotFoundError'
    this.serverMessage = message
    this.url = url
  }
}

export class ServerError extends Error {
  constructor(url, response) {
    const { message = '' } = response

    super(`API failed with a 500-level error at ${url}`)

    this.name = 'ServerError'
    this.serverMessage = message
    this.url = url
  }
}

export class ConflictError extends Error {
  constructor(url, response) {
    const { message = '' } = response

    super(`Conflict Error at ${url}`)
    this.name = 'ConflictError'
    this.response = response
    this.serverMessage = message
    this.url = url
  }
}

export class InvalidResponseError extends Error {
  constructor(url) {
    super('Unable to parse the response from the API. Not valid JSON.')
    this.name = 'InvalidResponseError'
    this.url = url
  }
}

export class ApiError extends Error {
  constructor(url, response) {
    const { message = '' } = response
    super(`General API Error: ${message}`)
    this.name = 'ApiError'
    this.response = response
    this.serverMessage = message
    this.url = url
  }
}
