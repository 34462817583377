package youversion.red.prayer.guided.tasks

import red.platform.Log
import red.platform.LogLevel
import youversion.red.guidedprayer.api.GuidedPrayerApi
import youversion.red.prayer.guided.ext.toDb
import youversion.red.prayer.guided.model.GuidedPrayerModule
import youversion.red.prayer.guided.service.GuidedPrayerStore

internal object ModulesSync {

    suspend fun process(guideId: Int, daysOfYear: List<Int>) = daysOfYear.forEach { process(guideId, it) }

    suspend fun process(guideId: Int, day: Int) {
        if (Log.level == LogLevel.DEBUG)
            Log.i("red-guided-prayer", "running Modules Sync")
        val modulesList = mutableListOf<GuidedPrayerModule>()

        val apiModules = GuidedPrayerApi.getModules(guideId, day)?.data
        apiModules?.forEach {
            modulesList.add(it.toDb(dayId = day, guideId = guideId))
        }

        GuidedPrayerStore.processModules(modulesList)
    }
}
