// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import red.Serializable
import red.platform.threads.freeze

@SerialName("AbstractShareEvent")
abstract class AbstractShareEvent : AbstractAnalyticsEvent(), Serializable
