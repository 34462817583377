// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.guidedprayer

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("guidedprayer.GuideModuleView")
data class GuideModuleView(
  /**
   * Id of the guide that was viewed
   */
  @SerialName("guide_id")
  @ProtoNumber(1)
  val guideId: Int = 0,
  /**
   * Id of the guide module that was viewed
   */
  @SerialName("module_id")
  @ProtoNumber(2)
  val moduleId: Int = 0,
  /**
   * Id of the guide module day that was viewed
   */
  @SerialName("day_id")
  @ProtoNumber(3)
  val dayId: Int = 0,
  /**
   * Type of the module
   */
  @SerialName("type")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.dataman.api.model.guidedprayer.GuidedPrayerAnalyticsModuleTypeSerializer::class)
  val type: GuidedPrayerAnalyticsModuleType =
      youversion.red.dataman.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER,
  /**
   * Animation id of the module
   */
  @SerialName("animation_id")
  @ProtoNumber(5)
  val animationId: Int? = null,
  /**
   * Text animation id of the module. Should be one of GuidedPrayerTextAnimation.
   */
  @SerialName("text_animation_id")
  @ProtoNumber(6)
  val textAnimationId: String? = null,
  /**
   * USFM of the module
   */
  @SerialName("reference")
  @ProtoNumber(7)
  val reference: String? = null,
  /**
   * If the user exited the story from this module guide
   */
  @SerialName("exit_guide")
  @ProtoNumber(8)
  val exitGuide: Boolean = false,
  @SerialName("referrer")
  @ProtoNumber(9)
  @Serializable(with=youversion.red.dataman.api.model.guidedprayer.GuideReferrerSerializer::class)
  val referrer: GuideReferrer? = null,
  /**
   * Duration that user viewed module in seconds
   */
  @SerialName("duration")
  @ProtoNumber(10)
  val duration: Int? = null,
  /**
   * The order of the module in the module set
   */
  @SerialName("weight")
  @ProtoNumber(11)
  val weight: Int? = null,
  /**
   * The title of the module
   */
  @SerialName("title")
  @ProtoNumber(12)
  val title: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    guideId: Int = 0,
    moduleId: Int = 0,
    dayId: Int = 0,
    type: GuidedPrayerAnalyticsModuleType =
        youversion.red.dataman.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER,
    animationId: Int? = null,
    textAnimationId: String? = null,
    reference: String? = null,
    exitGuide: Boolean = false,
    referrer: GuideReferrer? = null,
    duration: Int? = null,
    weight: Int? = null,
    title: String? = null
  ) : this(guideId, moduleId, dayId, type, animationId, textAnimationId, reference, exitGuide,
      referrer, duration, weight, title, red.platform.now())
}
