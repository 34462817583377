package youversion.red.banner.service

import kotlin.js.JsName
import red.service.IService
import red.service.ServiceRegistry
import red.service.service
import youversion.red.banner.model.Banner
import youversion.red.banner.model.BannerConfiguration
import youversion.red.banner.model.BannerConfigurationId
import youversion.red.banner.model.BannerId
import youversion.red.banner.model.BannerServerConfiguration
import youversion.red.banner.model.BannerServerConfigurations

@Deprecated("Replace with by BannerService()")
val BannerService: IBannerService
    get() = ServiceRegistry[IBannerService::class]

@JsName("BannerServiceProvider")
fun BannerService() = service<IBannerService>()

interface IBannerService : IService {

    var parameterResolver: ParameterResolver?

    suspend fun getConfiguration(): BannerConfiguration
    suspend fun getBanners(): BannerServerConfigurations
    suspend fun addBanner(banner: BannerServerConfiguration): BannerConfigurationId
    suspend fun editBanner(banner: BannerServerConfiguration)
    suspend fun deleteBanner(id: BannerId)
    suspend fun findBanner(type: String? = null): Banner?

    // KJB: NOTE: It is possible that parameters passed here aren't equal to the parameters sent to fetch the original banner.
    //            But, for now this is the easiest thing to do... so, until this whole thing is proven out... this is what I'm going to do
    suspend fun click(id: String, type: String? = null)
    suspend fun impression(id: String, type: String? = null)
    suspend fun dismiss(id: String, type: String? = null)
}
