package youversion.red.movies.api

import red.platform.PlatformType
import red.platform.http.ContentTypes
import red.platform.http.queryString
import red.platform.localization.Locales
import red.platform.localization.toLowerCase
import red.platform.platformType
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.set
import youversion.red.api.AbstractApi
import youversion.red.api.ApiDefaults
import youversion.red.movies.Movie
import youversion.red.movies.MovieChapters
import youversion.red.movies.MovieLanguages
import youversion.red.movies.MovieOrientation
import youversion.red.movies.MoviePublisher
import youversion.red.movies.Movies
import youversion.red.movies.MoviesCollection
import youversion.red.movies.MoviesCollections
import youversion.red.movies.MoviesConfiguration
import youversion.red.movies.api.model.videos.VideoTypeEnum

enum class MoviesUIContext {
    Movies,
    Reader,
    Stories,
    Search
}

internal object MoviesApi2 :
    AbstractApi(
        ApiDefaults(
            "movies",
            if (platformType == PlatformType.JavaScript) {
                ContentTypes.JSON
            } else {
                ContentTypes.PROTO
            },
            if (platformType == PlatformType.JavaScript) {
                ContentTypes.JSON
            } else {
                ContentTypes.PROTO
            },
            "4.0"
        )
    ) {

    suspend fun getConfiguration() = execute(
        "/configuration",
        serializer = MoviesConfiguration.serializer(),
        authAllowed = false
    )

    suspend fun getChapters() = execute(
        "/chapters",
        serializer = MovieChapters.serializer(),
        authAllowed = false,
        additionalContext = MoviesUIContext.Reader
    )

    suspend fun getPublisher(id: Int) = execute(
        "/publishers/$id",
        serializer = MoviePublisher.serializer(),
        authAllowed = false
    )

    suspend fun getMoviesRelatedTo(usfm: String, page: Int? = null): Pair<Movies, String?> {
        val contentLanguage = AtomicReference<String?>(null).freeze()
        val movies = execute(
            "/videos",
            queryString = queryString {
                add("related", usfm)
                page?.let { add("page", it) }
            },
            serializer = Movies.serializer(),
            authAllowed = false,
            additionalContext = MoviesUIContext.Reader,
            interceptor = {
                contentLanguage.set(it.findHeader("Content-Language").firstOrNull())
                it
            }
        ) ?: throw NullPointerException("Movies not found")
        return Pair(movies, contentLanguage.value)
    }

    suspend fun getMoviesInCollection(id: Int, type: VideoTypeEnum? = null, orientation: MovieOrientation? = null, pageSize: Int? = null, page: Int? = null): Pair<Movies, String?> {
        val contentLanguage = AtomicReference<String?>(null).freeze()
        val movies = execute(
            "/videos",
            queryString = queryString {
                add("collection", id)
                type?.let { add("types", it.name.toLowerCase(Locales.getEnglish())) }
                orientation?.let { add("orientation", it.name.toLowerCase(Locales.getEnglish())) }
                pageSize?.let { add("page_size", pageSize) }
                page?.let { add("page", it) }
            },
            serializer = Movies.serializer(),
            authAllowed = false,
            additionalContext = MoviesUIContext.Movies,
            interceptor = {
                contentLanguage.set(it.findHeader("Content-Language").firstOrNull())
                it
            }
        ) ?: throw NullPointerException("Movies not found")
        return Pair(movies, contentLanguage.value)
    }

    suspend fun getMovie(id: Int, context: MoviesUIContext = MoviesUIContext.Movies) = execute(
        "/videos/$id",
        serializer = Movie.serializer(),
        authAllowed = false,
        additionalContext = context
    )

    suspend fun getCollection(id: Int) = execute(
        "/collections/$id",
        serializer = MoviesCollection.serializer(),
        authAllowed = false
    )

    suspend fun getLanguages(type: String) = execute(
        "/languages",
        queryString = queryString {
            add("type", type)
        },
        serializer = MovieLanguages.serializer(),
        authAllowed = false
    )

    suspend fun getCollections(page: Int? = null): Pair<MoviesCollections, String?> {
        val contentLanguage = AtomicReference<String?>(null).freeze()
        val collections = execute(
            "/collections",
            queryString = queryString {
                page?.let { add("page", it) }
            },
            serializer = MoviesCollections.serializer(),
            authAllowed = false,
            additionalContext = MoviesUIContext.Movies,
            interceptor = {
                contentLanguage.set(it.findHeader("Content-Language").firstOrNull())
                it
            }
        ) ?: throw NullPointerException("Collections not found")
        return Pair(collections, contentLanguage.value)
    }
}
