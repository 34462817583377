package youversion.red.prayer.model

import red.platform.Date
import red.platform.threads.ensureNeverFrozen
import red.platform.threads.freeze
import youversion.red.prayer.service.PrayerStore.NEW_CLIENT_ID

data class PrayerUpdate(
    val prayerClientId: String? = null,
    internal val prayerServerId: Int? = null,
    val clientId: String,
    internal val serverId: Int? = null,
    val message: String? = null,
    val updatedDt: Date? = null,
    val createdDt: Date? = null,
    internal val state: Int = 0,
    internal val lastSync: Date? = null
) {

    init {
        this.freeze()
    }

    fun newBuilder() = PrayerUpdateBuilder(this)
}

class PrayerUpdateBuilder(private val oldUpdate: PrayerUpdate? = null) {

    init {
        ensureNeverFrozen()
    }

    var message: String? = oldUpdate?.message
    var updatedDt: Date? = oldUpdate?.updatedDt

    fun build() = oldUpdate?.copy(
        message = message,
        updatedDt = updatedDt
    ) ?: PrayerUpdate(clientId = NEW_CLIENT_ID, message = message, updatedDt = updatedDt)

    fun withMessage(message: String? = null): PrayerUpdateBuilder {
        this.message = message
        return this
    }

    fun withUpdatedDt(updatedDt: Date? = null): PrayerUpdateBuilder {
        this.updatedDt = updatedDt
        return this
    }
}
