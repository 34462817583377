package youversion.red.churches.ext

import youversion.red.churches.model.ChurchProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.LocationProfile
import youversion.red.churches.model.Organization
import youversion.red.churches.model.ParentOrganization
import youversion.red.churches.model.Pastor
import youversion.red.organizations.api.model.images.OrganizationImage
import youversion.red.organizations.api.model.images.PastorImage
import youversion.red.organizations.api.model.organizations.Organization as ApiOrganization
import youversion.red.organizations.api.model.organizations.OrganizationLocation as ApiLocation
import youversion.red.organizations.api.model.organizations.OrganizationMember
import youversion.red.organizations.api.model.organizations.ParentOrganization as ApiParentOrganization
import youversion.red.organizations.api.model.organizations.Pastor as ApiPastor
import youversion.red.organizations.api.model.organizations.ServiceTime as ApiServiceTime
import youversion.red.organizations.api.model.organizations.organization_profiles.BaseProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.ChurchProfile as ApiChurchProfile
import youversion.red.organizations.api.model.organizations.organization_profiles.LocationProfile as ApiLocationProfile

fun ApiOrganization.toOrganization(): Organization = Organization(
    id = this.id,
    name = this.name,
    websiteUrl = this.websiteUrl,
    phone = this.phone,
    email = this.email,
    description = this.description,
    imageUrl = this.logo.toImageUrl(),
    brandColor = this.backgroundColor,
    parentOrganization = this.parentOrganization?.toParentOrganization()
)

fun OrganizationImage.toImageUrl(): String = this.imageUrl
    .replace("{w}", this.width.toString())
    .replace("{h}", this.height.toString())

fun ApiParentOrganization.toParentOrganization(): ParentOrganization = ParentOrganization(
    id = this.id ?: "",
    name = this.name ?: ""
)

fun ApiServiceTime.toServiceTime(): ChurchServiceTime = ChurchServiceTime(
    id = this.id,
    dayOfWeek = this.dayOfWeek,
    timeOfDay = this.timeOfDay,
    description = this.description
)

fun ApiLocation.toLocation(): Location = Location(
    id = this.id,
    name = this.name,
    addressLine1 = this.addressLine1,
    addressLine2 = this.addressLine2,
    longitude = this.longitude,
    latitude = this.latitude,
    city = this.city,
    state = this.state,
    postalCode = this.postalCode,
    country = this.country,
    timezone = this.timezone,
    distance = this.distance
)

fun BaseProfile.toConcreteBaseProfile() = when (this) {
    is ApiChurchProfile -> this.toChurchProfile()
    is ApiLocationProfile -> this.toLocationProfile()
    else -> throw NullPointerException("This is neither a ChurchProfile nor a LocationProfile")
}

fun ApiChurchProfile.toChurchProfile(): ChurchProfile = ChurchProfile(
    id = this.id,
    organizationId = this.organizationId,
    denomination = this.denomination,
    pastor = this.pastor.toPastor(),
    locationCount = this.locationCount
)

fun ApiPastor.toPastor(): Pastor = Pastor(
    id = this.id,
    name = this.name,
    churchProfileId = this.churchProfileId,
    title = this.title,
    imageUrl = this.image.toImageUrl()
)

fun PastorImage.toImageUrl(): String = this.imageUrl
    .replace("{w}", this.width.toString())
    .replace("{h}", this.height.toString())

fun ApiLocationProfile.toLocationProfile(): LocationProfile = LocationProfile(
    id = this.id,
    organizationId = this.organizationId,
    addressLine1 = this.addressLine1,
    addressLine2 = this.addressLine2,
    longitude = this.longitude,
    latitude = this.latitude,
    city = this.city,
    state = this.state,
    postalCode = this.postalCode,
    country = this.country,
    timezone = this.timezone
)

fun OrganizationMember.toUserId(): Int = this.userId.toInt()
