// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.configuration

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ImageUrls(
  @SerialName("video")
  @ProtoNumber(1)
  @JvmField
  val video: String? = null,
  @SerialName("collection")
  @ProtoNumber(2)
  @JvmField
  val collection: String? = null,
  @SerialName("publisher_logo")
  @ProtoNumber(3)
  @JvmField
  val publisherLogo: String? = null,
  @SerialName("publisher_banner")
  @ProtoNumber(4)
  @JvmField
  val publisherBanner: String? = null,
  @SerialName("video_blur")
  @ProtoNumber(5)
  @JvmField
  val videoBlur: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
