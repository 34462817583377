package youversion.red.podcasts.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class PodcastsSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
