// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations.organization_profiles

import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.threads.freeze

interface BaseProfile : Serializable {
  val id: String

  val organizationId: String

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<BaseProfile> = PolymorphicSerializer(BaseProfile::class)
  }
}
