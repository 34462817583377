// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Results(
  @SerialName("churches")
  @ProtoNumber(13)
  @JvmField
  val churches: ChurchesResults? = null,
  @SerialName("did_you_mean")
  @ProtoNumber(6)
  @JvmField
  val did_you_mean: List<String>? = null,
  @SerialName("images")
  @ProtoNumber(9)
  @JvmField
  val images: ImagesResults? = null,
  /**
   * *LEGACY* Do not use this property any longer, but use order_v3 instead. Podcast and Churches
   * will never appear in this list due to a serialization issue. A list of strings describing the
   * object kind order.
   */
  @SerialName("order")
  @ProtoNumber(1)
  @JvmField
  val order: List<Kind>? = null,
  /**
   * *LEGACY* Do not use this property any longer, but use order_v3 instead. Churches will never
   * appear in this list due to a serialization issue. A list of strings describing the object kind
   * order.
   */
  @SerialName("order_v2")
  @ProtoNumber(12)
  @JvmField
  val orderv2: List<Kind>? = null,
  /**
   * A list of strings describing the object kind order.
   */
  @SerialName("order_v3")
  @ProtoNumber(14)
  @JvmField
  val orderv3: List<Kind>? = null,
  @SerialName("plans")
  @ProtoNumber(2)
  @JvmField
  val plans: PlansResults? = null,
  @SerialName("podcasts")
  @ProtoNumber(11)
  @JvmField
  val podcasts: PodcastsResults? = null,
  /**
   * If a correction was applied, search for the original query instead.
   */
  @SerialName("search_instead_for")
  @ProtoNumber(7)
  @JvmField
  val search_instead_for: String? = null,
  @SerialName("topics")
  @ProtoNumber(3)
  @JvmField
  val topics: TopicsResults? = null,
  @SerialName("verses")
  @ProtoNumber(4)
  @JvmField
  val verses: VersesResults? = null,
  @SerialName("videos")
  @ProtoNumber(5)
  @JvmField
  val videos: VideosResults? = null,
  /**
   * This is the actual query the server used to get the results it returned. Normally, it would be
   * the same as the query the user input, but if it is a spelling mistake, the API might auto-correct
   * it and run a different query than what the user input.
   */
  @SerialName("query")
  @ProtoNumber(8)
  @JvmField
  val query: String? = null,
  /**
   * This is the user_intent the server used to get the results it returned. Normally, it would be
   * the same as the client supplied to get results. *But* if the client did not supply it the API
   * tries to intelligently chose an intent.
   */
  @SerialName("user_intent")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.search.api.model.UserIntentSerializer::class)
  @JvmField
  val user_intent: UserIntent? = null
) : red.Serializable {
  init {
    freeze()
  }
}
