// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.api.model.shows

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Show(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  /**
   * This is the website for the entire show.
   */
  @SerialName("website")
  @ProtoNumber(5)
  @JvmField
  val website: String? = null,
  /**
   * The organization, company, church, author, theologian etc.. who produces podcast content.
   */
  @SerialName("partner")
  @ProtoNumber(9)
  @JvmField
  val partner: String? = null,
  /**
   * The iso 639 language tag of the show. The default value is English.
   */
  @SerialName("language_tag")
  @ProtoNumber(11)
  @JvmField
  val languageTag: String? = null,
  /**
   * Basic image for the podcast show normally pulled from their RSS feed. Should be square aspect
   * ratio. When the admin sends this to the API, it will have taken it from the RSS feed and uploaded
   * into a bucket. The API will handle wrapping the URL in imageproxy.
   */
  @SerialName("image_url")
  @ProtoNumber(13)
  @JvmField
  val imageUrl: String? = null,
  /**
   * Custom hero image for the show. Will be taller than it is wide. Can be masked to use on
   * different views. When the admin sends this to the API, it will have already uploaded it into a
   * bucket. The API will handle wrapping the URL in imageproxy.
   */
  @SerialName("hero_image_url")
  @ProtoNumber(14)
  @JvmField
  val heroImageUrl: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
