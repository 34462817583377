// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.giving

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Referral source of the Giving Cause screen
 */
enum class GivingCauseReferralSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  ABOUT_SCREEN("about_screen", 1),

  GIVING_LANDING_SCREEN("giving_landing_screen", 2),

  GIVING_CAUSE_TAB_SELECT("giving_cause_tab_select", 3),

  DEEP_LINK("deep_link", 4),

  BANNER_CTA("banner_cta", 5);
}

internal class GivingCauseReferralSourceSerializer : KSerializer<GivingCauseReferralSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.giving.GivingCauseReferralSource")

  override fun serialize(encoder: Encoder, value: GivingCauseReferralSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GivingCauseReferralSource {
    if (decoder is JsonDecoder) {
      return GivingCauseReferralSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GivingCauseReferralSource.UNKNOWN
      1 -> GivingCauseReferralSource.ABOUT_SCREEN
      2 -> GivingCauseReferralSource.GIVING_LANDING_SCREEN
      3 -> GivingCauseReferralSource.GIVING_CAUSE_TAB_SELECT
      4 -> GivingCauseReferralSource.DEEP_LINK
      5 -> GivingCauseReferralSource.BANNER_CTA
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
