package youversion.red.prayer.guided.model

import red.platform.threads.freeze
import youversion.red.prayer.guided.service.GuidedPrayerColorBuilder

data class GuidedPrayerColors(
    val w10: String,
    val w30: String,
    val w40: String,
    val w50: String,
    val dayOfYear: Int,
    val colorCount: Int,
    val curDayInt: Int,
    val mode: GuidedPrayerColorBuilder.Mode = GuidedPrayerColorBuilder.Mode.DARK
) {
    private val isDark: Boolean
        get() = GuidedPrayerColorBuilder.Mode.DARK == mode

    val cardBackground: String get() = if (isDark) w40 else w10
    val verticalLineByVerse: String get() = w30
    val verseAndRef: String get() = if (isDark) w10 else w40
    val topIcons: String get() = if (isDark) w10 else w50
    val eyebrow: String get() = if (isDark) w10 else w50
    val regBodyText: String get() = if (isDark) w10 else w50
    val lottieAnimation: String get() = w50
    val addPrayerBtn: String get() = if (isDark) w10 else w50
    val doneBtnBackground: String get() = if (isDark) w10 else w50
    val doneBtnText: String get() = w10

    init {
        freeze()
    }
}
