// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.plans

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AnalyticsDevicePlatform

@Serializable
data class EventPlatform(
  @SerialName("version")
  @ProtoNumber(2)
  @JvmField
  val version: String? = null,
  @SerialName("platform")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.AnalyticsDevicePlatformSerializer::class)
  @JvmField
  val platform: AnalyticsDevicePlatform? = null
) : red.Serializable {
  init {
    freeze()
  }
}
