// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

/**
 * gcs presign parameters
 */
@Serializable
data class GCSPresignParams(
  @SerialName("key")
  @ProtoNumber(1)
  @JvmField
  val key: String? = null,
  @SerialName("bucket")
  @ProtoNumber(2)
  @JvmField
  val bucket: String? = null,
  @SerialName("GoogleAccessId")
  @ProtoNumber(3)
  @JvmField
  val googleaccessid: String? = null,
  @SerialName("policy")
  @ProtoNumber(4)
  @JvmField
  val policy: String? = null,
  @SerialName("signature")
  @ProtoNumber(5)
  @JvmField
  val signature: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
