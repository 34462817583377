/**
 * @module PartnerPortalAuth
 */

import React from 'react'
import {
  accounts,
  partnerPortalAuthClient,
} from '@youversion/api/partner-portal'
import { makeModel, makeStateModel } from '@youversion/api/core'
import * as yup from 'yup'
import { useModel as baseUseModel } from 'hooks/use-model'

/**
 * List of valid async statusTypes possible within PartnerPortalAuthContext
 * `['loading', 'error', 'success', 'idle']`.
 */
const statusTypes = makeStateModel(['loading', 'error', 'success', 'idle'])

/**
 * @typedef {object} PartnerPortalAuthContext
 * @property {Error} error - The auth error.
 * @property {Function} getToken - Gets the auth token.
 * @property {boolean} isError - If auth is in the error state.
 * @property {boolean} isIdle - If auth is in the idle state.
 * @property {boolean} isLoading - If auth is in the loading state.
 * @property {boolean} isSignedIn - If the user is signed in.
 * @property {boolean} isSuccess - If auth is in the success state.
 * @property {Function} signIn - Signs in the user.
 * @property {Function} signOut - Signs out the user.
 * @property {object} user - The user account data.
 */
const PartnerPortalAuthContext = React.createContext({
  error: null,
  getToken: null,
  isError: false,
  isIdle: false,
  isLoading: false,
  isSignedIn: false,
  isSuccess: false,
  signIn: null,
  signOut: null,
  user: null,
})
PartnerPortalAuthContext.displayName = 'PartnerPortalAuthContext'

/**
 * React Hook for accessing PartnerPortalAuthContext.
 *
 * @throws {Error} - Throws an error if not used within the correct context provider.
 *
 * @returns {PartnerPortalAuthContext} - The auth context.
 */
export function usePartnerPortalAuth() {
  const context = React.useContext(PartnerPortalAuthContext)
  if (context === undefined) {
    throw new Error(
      `usePartnerPortalAuth must be used within a PartnerPortalAuthProvider`,
    )
  }
  return context
}

/**
 * React Hook for accessing PartnerPortal Auth Sign In.
 *
 * @returns {object} SignInModel object.
 */
export function usePartnerPortalSignInModel() {
  const { signIn } = usePartnerPortalAuth()

  const queryFn = React.useCallback(() => {
    return Promise.resolve(
      makeModel({
        data: {
          email: '',
          password: '',
        },
        id: 'SignInModel',
        name: 'SignInModel',
        schema: yup.object().shape({
          email: yup.string().email().required('Email is required'),
          password: yup.string().required('Password is required'),
        }),
      }),
    )
  }, [])

  const buildValidationError = React.useCallback((model) => {
    return new Error(`Sign In Error: ${model.$error}`)
  }, [])

  const updateFn = React.useCallback(
    async (model) => {
      if (!model.$valid) {
        throw new Error('Sign in Error')
      }

      await signIn({
        username: model.email.$value,
        password: model.password.$value,
      })
    },
    [signIn],
  )

  return baseUseModel({
    buildValidationError,
    queryConfig: { staleTime: Infinity },
    queryFn,
    queryKey: 'SignInModel:Default',
    updateFn,
  })
}

usePartnerPortalSignInModel.statusTypes = baseUseModel.statusTypes

/**
 * Wrapper for conditionally displaying children when auth is loading.
 *
 * @param {object} props - The component props.
 * @param {React.ReactChildren} props.children - The component children.
 *
 * @returns {React.ReactElement} - The auth loading component.
 */
export function PartnerPortalAuthLoading({ children }) {
  const { isIdle, isLoading } = usePartnerPortalAuth()
  return isIdle || isLoading ? children : null
}

/**
 * Wrapper for conditionally displaying children when auth encounters an error.
 *
 * @param {object} props - The component props.
 * @param {React.ReactChildren} props.children - The component children.
 *
 * @returns {React.ReactElement} - The auth error component.
 */
export function PartnerPortalAuthError({ children }) {
  const { isError, error } = usePartnerPortalAuth()
  return isError
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { error }),
      )
    : null
}

/**
 * Wrapper for conditionally displaying children when auth is available.
 *
 * @param {object} props - The component props.
 * @param {React.ReactChildren} props.children - The component children.
 *
 * @returns {React.ReactElement} - The auth content component.
 */
export function PartnerPortalAuthContent({ children }) {
  const context = usePartnerPortalAuth()
  return context.isSuccess
    ? React.Children.map(children, (child) =>
        React.cloneElement(child, { ...context }),
      )
    : null
}

/**
 * React context provider for Partner Portal authentication.
 *
 * @param {object} [props] - The context props.
 *
 * @returns {React.Provider} - The auth context provider.
 */
export function PartnerPortalAuthProvider(props) {
  const [user, setUser] = React.useState()
  const [status, setStatus] = React.useState(statusTypes.idle)
  const [error, setError] = React.useState()

  const isLoading = status === statusTypes.loading
  const isIdle = status === statusTypes.idle
  const isSuccess = status === statusTypes.success
  const isError = status === statusTypes.error

  const isSignedIn = Boolean(user)

  function updateStatus({ errorState, statusState, userState }) {
    setUser(userState)
    setError(errorState)
    setStatus(statusState)
  }
  /**
   * Run this effect hook once when component mounts
   * - check for a token
   * - Fetch the user
   * - list for native Event for token expiration.
   */
  React.useEffect(() => {
    setStatus(statusTypes.loading)

    if (partnerPortalAuthClient.getToken()) {
      accounts
        .getAccount()
        .then((response) => {
          updateStatus({
            errorState: null,
            statusState: statusTypes.success,
            userState: response,
          })
        })
        .catch((userError) => {
          updateStatus({
            errorState: userError,
            statusState: statusTypes.error,
            userState: null,
          })
        })
    } else {
      updateStatus({
        errorState: null,
        statusState: statusTypes.success,
        userState: null,
      })
    }
  }, [])

  /**
   * Sign In using PartnerPortal Auth.
   *
   * @param {object} config
   * @param {string} config.password
   * @param {string} config.username
   */
  const signIn = React.useCallback(async ({ password, username }) => {
    try {
      const tokenResponse = await partnerPortalAuthClient.signIn({
        password,
        username,
      })
      if (tokenResponse) {
        setUser(await accounts.getAccount())
      } else {
        throw new Error('Bad Auth')
      }
    } catch (signInError) {
      throw new Error('Sign in error')
    }
  }, [])

  /**
   * Sign Out using YouVersion Auth.
   */
  const signOut = React.useCallback(() => {
    partnerPortalAuthClient.signOut(() => {
      setUser(null)
    })
  }, [])

  /**
   * Memoized value to pass to PartnerPortalAuthContextProvider.
   */
  const value = React.useMemo(
    () => ({
      error,
      getToken: partnerPortalAuthClient.getToken,
      isError,
      isIdle,
      isLoading,
      isSignedIn,
      isSuccess,
      signIn,
      signOut,
      user,
    }),
    [
      error,
      user,
      isError,
      isIdle,
      isLoading,
      isSignedIn,
      isSuccess,
      signIn,
      signOut,
    ],
  )

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <PartnerPortalAuthContext.Provider value={value} {...props} />
}
