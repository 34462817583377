/**
 * @module YouVersionEnums
 */
/**
 * Process status types. Useful for determining state of fetch calls or processes.
 *
 * @readonly
 * @enum {string}
 */
export const statusTypes = Object.freeze({
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
  INFO: 'info',
})
