// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.enrollment

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
@SerialName("enrollment.CountryEnrollment")
data class CountryEnrollment(
  @SerialName("country")
  @ProtoNumber(3)
  val country: String = "",
  @SerialName("feature_id")
  @ProtoNumber(1)
  override val featureId: String? = null
) : Enrollment, red.Serializable {
  init {
    freeze()
  }
}
