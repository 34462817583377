// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.media

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import red.Serializable
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@SerialName("media.AbstractMediaPlayEvent")
abstract class AbstractMediaPlayEvent : AbstractAnalyticsEvent(), Serializable {
  /**
   * session for this media event
   */
  abstract val mediasessionid: String?

  /**
   * expected duration (in seconds) of the media
   */
  abstract val duration: Int?

  /**
   * position (in seconds) in the stream when played
   */
  abstract val position: Int?
}
