// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bafk

import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bafk.KidsStoryNotCompletedEvent")
data class KidsStoryNotCompletedEvent(
  @SerialName("story")
  @ProtoNumber(1)
  val story: String? = null,
  @SerialName("scene")
  @ProtoNumber(2)
  val scene: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(story: String? = null, scene: String? = null) : this(story, scene, red.platform.now())
}
