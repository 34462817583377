package youversion.red.users.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.users.deeplink.UsersDeepLinkHandler

@ModuleDependencies("core")
class UsersModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(UsersDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
