package youversion.red.prayer.db

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import youversion.red.prayer.db.prayer.newInstance
import youversion.red.prayer.db.prayer.schema

public interface PrayerDatabase : Transacter {
  public val prayerQueries: PrayerQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = PrayerDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      prayerAdapter: Prayer.Adapter,
      prayer_commentAdapter: Prayer_comment.Adapter,
      prayer_reactionAdapter: Prayer_reaction.Adapter,
      prayer_shareAdapter: Prayer_share.Adapter,
      prayer_updateAdapter: Prayer_update.Adapter,
      prayer_usersAdapter: Prayer_users.Adapter
    ): PrayerDatabase = PrayerDatabase::class.newInstance(driver, prayerAdapter,
        prayer_commentAdapter, prayer_reactionAdapter, prayer_shareAdapter, prayer_updateAdapter,
        prayer_usersAdapter)
  }
}
