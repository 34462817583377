// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.moments

import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent
import youversion.red.dataman.api.model.MomentType

@Serializable
@SerialName("moments.MomentEvent")
data class MomentEvent(
  @SerialName("type")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.dataman.api.model.MomentTypeSerializer::class)
  val type: MomentType? = null,
  @SerialName("usfm")
  @ProtoNumber(2)
  val usfm: String? = null,
  @SerialName("version")
  @ProtoNumber(3)
  val version: Int? = null,
  @SerialName("id")
  @ProtoNumber(4)
  val id: Long? = null,
  @SerialName("color")
  @ProtoNumber(8)
  val color: String? = null,
  @SerialName("update")
  @ProtoNumber(5)
  val update: Boolean? = null,
  @SerialName("success")
  @ProtoNumber(6)
  val success: Boolean? = null,
  @SerialName("error_message")
  @ProtoNumber(7)
  val errorMessage: String? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    type: MomentType? = null,
    usfm: String? = null,
    version: Int? = null,
    id: Long? = null,
    color: String? = null,
    update: Boolean? = null,
    success: Boolean? = null,
    errorMessage: String? = null
  ) : this(type, usfm, version, id, color, update, success, errorMessage, red.platform.now())
}
