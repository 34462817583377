/**
 * @module replaceUrlParams
 */

/**
 * Updates or adds params to a url.
 *
 * @alias module:replaceUrlParams
 *
 * @param {string|Location|URL} source - A path/url string or location/url object.
 * @param {object} params - The params object.
 *
 * @throws {Error} - Throws an error for missing or invalid function params.
 *
 * @returns {string} -  The updated url string.
 *
 * @example
 * // Returns /videos?id=3&lang=en
 * replaceUrlParams('/videos?id=2&lang=de', {id: 3, lang: 'en'})
 */
export function replaceUrlParams(source, params) {
  if (!source) {
    throw new Error('Missing `source` function param.')
  }

  if (
    !(typeof source === 'string' || typeof source === 'object') ||
    Array.isArray(source) ||
    (typeof source === 'object' && typeof source.search === 'undefined')
  ) {
    throw new Error(
      '`source` must be a url or path string, window.location object, or URL object.',
    )
  }

  if (!params || typeof params !== 'object' || Array.isArray(params)) {
    throw new Error('`params` object missing or incorrect type.')
  }

  let sourceObject
  let sourcePrefix = ''

  if (typeof source === 'object' && source.search) {
    sourceObject = source
    sourcePrefix = `${source.protocol}//${source.host}`
  } else if (typeof source === 'string') {
    // This base url is never used again, but is needed by the URL constructor.
    sourceObject = new URL(source, 'http://placeholder.example.com')

    if (source.startsWith('http')) {
      sourcePrefix = `${sourceObject.protocol}//${sourceObject.host}`
    }
  }

  const searchParams = new URLSearchParams(sourceObject.search)

  Object.keys(params).forEach((key) => {
    searchParams.set(key, params[key])
  })

  return `${sourcePrefix}${sourceObject.pathname}?${searchParams.toString()}`
}
