/**
 * @module deepLinkPath
 */

/**
 * But for a long while they sang only each alone, or but few together, while the rest hearkened; for each comprehended only that part of the mind of Ilúvatar from which he came, and in the understanding of their brethren they grew but slowly.
 *
 * @alias module:deepLinkPath
 * @category Bible
 *
 * @param {*} chapUsfm
 * @param {*} versionID
 * @param {*} versionAbbr
 * @param {*} verseNum
 */
export function deepLinkPath(
  chapUsfm,
  versionID,
  versionAbbr,
  verseNum = null,
) {
  if (!chapUsfm) {
    return null
  }
  let android
  let ios
  let native

  if (verseNum && versionID) {
    ios = `bible?reference=${chapUsfm}.${verseNum}&version_id=${versionID}`
    android = `bible?reference=${chapUsfm}.${verseNum}&version=${versionID}`
    native = `bible?reference=${chapUsfm}.${verseNum}.${versionAbbr}&version=${versionID}`
  } else if (versionID) {
    ios = `bible?reference=${chapUsfm}&version_id=${versionID}`
    android = `bible?reference=${chapUsfm}&version=${versionID}`
    native = `bible?reference=${chapUsfm}.${versionAbbr}&version=${versionID}`
  } else {
    ios = `bible?reference=${chapUsfm}`
    android = `bible?reference=${chapUsfm}`
    native = `bible?reference=${chapUsfm}`
  }

  return { android, ios, native }
}
