// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model.guidedprayer

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class GuideModuleViewFireBase(
  /**
   * Id of the guide that was viewed
   */
  @SerialName("guide_id")
  @ProtoNumber(1)
  val guideId: Int = 0,
  /**
   * Id of the guide module that was viewed
   */
  @SerialName("module_id")
  @ProtoNumber(2)
  val moduleId: Int = 0,
  /**
   * Id of the guide module day that was viewed
   */
  @SerialName("day_id")
  @ProtoNumber(3)
  val dayId: Int = 0,
  /**
   * Type of the module
   */
  @SerialName("type")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleTypeSerializer::class)
  val type: GuidedPrayerAnalyticsModuleType =
      youversion.red.guidedprayer.api.model.guidedprayer.GuidedPrayerAnalyticsModuleType.UNSUPPORTED_PLACEHOLDER,
  /**
   * Animation id of the module
   */
  @SerialName("animation_id")
  @ProtoNumber(5)
  val animationId: Int? = null,
  /**
   * Text animation id of the module. Should be one of GuidedPrayerTextAnimation.
   */
  @SerialName("text_animation_id")
  @ProtoNumber(6)
  val textAnimationId: String? = null,
  /**
   * USFM of the module
   */
  @SerialName("reference")
  @ProtoNumber(7)
  val reference: String? = null,
  /**
   * If the user exited the story from this module guide
   */
  @SerialName("exit_guide")
  @ProtoNumber(8)
  val exitGuide: Boolean = false,
  @SerialName("referrer")
  @ProtoNumber(9)
  @Serializable(with=youversion.red.guidedprayer.api.model.guidedprayer.GuideReferrerFireBaseSerializer::class)
  val referrer: GuideReferrerFireBase? = null,
  /**
   * Duration that user viewed module in seconds
   */
  @SerialName("duration")
  @ProtoNumber(10)
  val duration: Int? = null,
  /**
   * The order of the module in the module set
   */
  @SerialName("weight")
  @ProtoNumber(11)
  val weight: Int? = null,
  /**
   * The title of the module
   */
  @SerialName("title")
  @ProtoNumber(12)
  val title: String? = null
) : Event(), red.Serializable {
  override val key: String = "GuideModuleView"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    bundle.putInt("guide_id", guideId)
    bundle.putInt("module_id", moduleId)
    bundle.putInt("day_id", dayId)
    bundle.putString("type", type.serialName)
    animationId?.let { bundle.putInt("animation_id", animationId) }
    textAnimationId?.let { bundle.putString("text_animation_id", textAnimationId) }
    reference?.let { bundle.putString("reference", reference) }
    bundle.putBoolean("exit_guide", exitGuide)
    referrer?.let { bundle.putString("referrer", referrer.serialName) }
    duration?.let { bundle.putInt("duration", duration) }
    weight?.let { bundle.putInt("weight", weight) }
    title?.let { bundle.putString("title", title) }
    return bundle
  }
}
