// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model.events

import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event
import youversion.red.prayer.api.model.PrivacyStatus

@Serializable
data class PrayerRequest(
  /**
   * Id of the prayer that has been shared.
   */
  @SerialName("prayer_id")
  @ProtoNumber(1)
  val prayerId: Int? = null,
  /**
   * If the user owns this prayer
   */
  @SerialName("is_owner")
  @ProtoNumber(2)
  val isOwner: Boolean? = null,
  /**
   * How many times this prayer was shared
   */
  @SerialName("count")
  @ProtoNumber(3)
  val count: Long? = null,
  /**
   * The shareability of the prayer.
   */
  @SerialName("privacy_status")
  @ProtoNumber(4)
  @Serializable(with=youversion.red.prayer.api.model.PrivacyStatusSerializer::class)
  val privacyStatus: PrivacyStatus? = null
) : Event(), red.Serializable {
  override val key: String = "PrayerRequest"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    prayerId?.let { bundle.putInt("prayer_id", prayerId) }
    isOwner?.let { bundle.putBoolean("is_owner", isOwner) }
    count?.let { bundle.putLong("count", count) }
    privacyStatus?.let { bundle.putString("privacy_status", privacyStatus.serialName) }
    return bundle
  }
}
