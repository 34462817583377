package youversion.red.moments.model

object MomentSource {
    const val TODAY: Long = 1L shl 1
    const val COMMUNITY: Long = 1L shl 2
    const val MY_PROFILE: Long = 1L shl 3
    const val FRIEND: Long = 1L shl 4
    const val BOOKMARKS: Long = 1L shl 5
    const val HIGHLIGHTS: Long = 1L shl 6
    const val NOTES: Long = 1L shl 7
    const val RELATED_MOMENTS: Long = 1L shl 8
    const val RELATED_NOTES: Long = 1L shl 9
    const val IMAGES: Long = 1L shl 10
    const val MOMENT: Long = 1L shl 11
    const val PRAYER: Long = 1L shl 12
}
