package youversion.red.prayer.guided.service.js

import kotlin.Boolean
import kotlin.Int
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.JsName
import kotlin.js.Promise
import red.lifecycle.LiveData
import red.platform.localization.Locale
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.guidedprayer.api.model.GuidedPrayerMusic
import youversion.red.prayer.guided.model.GuideModuleItems
import youversion.red.prayer.guided.model.GuidedPrayerColors
import youversion.red.prayer.guided.model.GuidedPrayerDay
import youversion.red.prayer.guided.model.GuidedPrayerGuide
import youversion.red.prayer.guided.model.IBackgroundAudioVariation
import youversion.red.prayer.guided.service.IGuidedPrayerService

public object GuidedPrayerService {
  private val service: IGuidedPrayerService by
      service<youversion.red.prayer.guided.service.IGuidedPrayerService>()

  public val selectedVariation: LiveData<IBackgroundAudioVariation>
    get() = service.selectedVariation

  public val isAudioOptionShown: LiveData<Boolean>
    get() = service.isAudioOptionShown

  @JsName(name = "isSupported")
  public fun isSupported(guideId: Int): Promise<Boolean?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.isSupported(guideId)

  }.toPromise()

  @JsName(name = "isSupportedWithLocale")
  public fun isSupportedWithLocale(guideId: Int, locale: Locale): Promise<Boolean?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.isSupportedWithLocale(guideId, locale)

  }.toPromise()

  @JsName(name = "getGuide")
  public fun getGuide(guideId: Int): Promise<GuidedPrayerGuide?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getGuide(guideId)

  }.toPromise()

  @JsName(name = "getGuideLiveData")
  public fun getGuideLiveData(guideId: Int): LiveData<GuidedPrayerGuide> =
      service.getGuideLiveData(guideId)

  @JsName(name = "getDayLiveData")
  public fun getDayLiveData(guideId: Int, dayOfYear: Int): LiveData<GuidedPrayerDay> =
      service.getDayLiveData(guideId, dayOfYear)

  @JsName(name = "getDay")
  public fun getDay(guideId: Int, dayOfYear: Int): Promise<GuidedPrayerDay?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getDay(guideId, dayOfYear)

  }.toPromise()

  @JsName(name = "getModules")
  public fun getModules(guideId: Int, dayOfYear: Int): Promise<GuideModuleItems?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getModules(guideId, dayOfYear)

  }.toPromise()

  @JsName(name = "getModulesLiveData")
  public fun getModulesLiveData(guideId: Int, dayOfYear: Int): LiveData<GuideModuleItems> =
      service.getModulesLiveData(guideId, dayOfYear)

  @JsName(name = "getMusic")
  public fun getMusic(): Promise<GuidedPrayerMusic?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getMusic()

  }.toPromise()

  @JsName(name = "getVariations")
  public fun getVariations(): Promise<List<IBackgroundAudioVariation>?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.getVariations()

  }.toPromise()

  @JsName(name = "selectVariation")
  public fun selectVariation(variation: IBackgroundAudioVariation): Unit =
      service.selectVariation(variation)

  @JsName(name = "setAudioOptionShown")
  public fun setAudioOptionShown(shown: Boolean): Unit = service.setAudioOptionShown(shown)

  @JsName(name = "clearCache")
  public fun clearCache(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.clearCache()

  }.toPromise()

  @JsName(name = "getColors")
  public fun getColors(isDark: Boolean): GuidedPrayerColors = service.getColors(isDark)
}
