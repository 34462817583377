package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PlanSegment(
    @ProtoNumber(1) val kind: PlanSegmentKind,
    @ProtoNumber(2) val content: String,
    @ProtoNumber(3) val references: List<String> = emptyList()
) {
    init {
        freeze()
    }
}
