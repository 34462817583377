package youversion.red.blue.state

import red.platform.Log
import red.service.ServiceRegistry
import youversion.red.blue.api.model.state.FeatureState
import youversion.red.blue.service.BlueService
import youversion.red.blue.service.IBlueService

enum class OnBoardingGroup {
    UNKNOWN,
    NEITHER,
    TEST,
    CONTROL
}

object OnBoardingState {

    private val blueService by BlueService()

    val enrolled: Boolean
        get() = blueService.isEnrolled(featureId)

    val enabled: Boolean
        get() = blueService.getState(featureId)?.enabled ?: false

    val group: OnBoardingGroup
        get() = blueService.getState(featureId)?.group?.let {
            try {
                OnBoardingGroup.valueOf(it)
            } catch (e: Exception) {
                Log.e("OnBoardingState", "Unknown group: $it")
                null
            }
        } ?: OnBoardingGroup.UNKNOWN

    internal suspend fun setEnabledInternal(enabled: Boolean, group: OnBoardingGroup?) {
        ServiceRegistry[IBlueService::class].setState(
            FeatureState(
                featureId,
                group = group?.name?.takeIf { group != OnBoardingGroup.UNKNOWN },
                enabled = enabled
            )
        )
    }

    const val featureId = "onboarding"
}
