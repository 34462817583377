package youversion.red.blue.service

import red.tasks.CoroutineDispatchers.launch
import youversion.red.cache.CacheClearListener
import youversion.red.cache.CacheType

internal class CacheClearListenerImpl : CacheClearListener {

    private val service by BlueService()

    override fun onClearCache(type: CacheType) {
        launch {
            if (type == CacheType.BLUE) {
                service.clearState()
                service.update()
            }
        }
    }
}
