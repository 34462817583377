package youversion.red.analytics

import red.tasks.CoroutineDispatchers
import youversion.red.dataman.api.model.AnalyticsEvent

internal suspend fun AnalyticsEvent.logSuspend() = DataMan.collect(DataManEventImpl(this))

fun AnalyticsEvent.log() = CoroutineDispatchers.launch {
    logSuspend()
}
