// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class SearchHistory(
  /**
   * Kind of historical search
   */
  @SerialName("kind")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.search.api.model.SearchHistoryKindSerializer::class)
  @JvmField
  val kind: SearchHistoryKind? = null,
  /**
   * This is no longer used. It might be used again in a future version, but currently does nothing.
   */
  @SerialName("query")
  @ProtoNumber(2)
  @JvmField
  val query: String? = null,
  /**
   * Iso formatted date and time in UTC when query was issued
   */
  @SerialName("created_dt")
  @ProtoNumber(3)
  @JvmField
  val created_dt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
