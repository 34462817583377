package youversion.red.analytics.db

import com.squareup.sqldelight.Transacter
import com.squareup.sqldelight.db.SqlDriver
import youversion.red.analytics.db.analytics.newInstance
import youversion.red.analytics.db.analytics.schema

public interface AnalyticsDatabase : Transacter {
  public val analyticsDatabaseQueries: AnalyticsDatabaseQueries

  public companion object {
    public val Schema: SqlDriver.Schema
      get() = AnalyticsDatabase::class.schema

    public operator fun invoke(driver: SqlDriver, eventsAdapter: Events.Adapter): AnalyticsDatabase
        = AnalyticsDatabase::class.newInstance(driver, eventsAdapter)
  }
}
