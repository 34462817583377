// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ServiceTimeI18n(
  @SerialName("language_tag")
  @ProtoNumber(1)
  @JvmField
  val languageTag: String? = null,
  @SerialName("description")
  @ProtoNumber(2)
  @JvmField
  val description: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
