package youversion.red.prayer.guided.model

import red.platform.threads.freeze

data class GuidedPrayerDay(
    val guideId: Int,
    val id: Int,
    val imageUrl: String? = null,
    val title: String? = null,
    val lightBgColor: String? = null,
    val darkBgColor: String? = null
) {
    init {
        freeze()
    }
}
