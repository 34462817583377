// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Suggestion(
  /**
   * Kind of suggestions
   */
  @SerialName("kind")
  @ProtoNumber(1)
  @Serializable(with=youversion.red.search.api.model.KindsSerializer::class)
  @JvmField
  val kind: Kinds? = null,
  /**
   * List of suggestion strings
   */
  @SerialName("values")
  @ProtoNumber(2)
  @JvmField
  val values: List<SuggestionValue>? = null
) : red.Serializable {
  init {
    freeze()
  }
}
