/**
 * @module partnerPortalErrors
 */
/* eslint-disable max-classes-per-file */

/**
 * Grab a useful error message to pass along.
 *
 * @param {object} response - The response object.
 *
 * @returns {string} - The error message string.
 */
export function getErrorMessage(response) {
  if (!response) {
    return ''
  }

  const { message, details } = response

  // Sometimes we get lucky and there's a message string.
  if (message) {
    return message
  }

  // Usually we get details, that can be a string or an object.
  if (details) {
    if (typeof details === 'string') {
      return details
    }

    if (typeof details === 'object') {
      const firstError = Object.values(details)[0]

      // These can be strings or arrays of strings.
      if (typeof firstError === 'string') {
        return firstError
      }
      if (Array.isArray(firstError) && typeof firstError[0] === 'string') {
        return firstError[0]
      }
    }
  }
  return ''
}

/**
 * The generic API error.
 */
export class ApiError extends Error {
  /**
   * ApiError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} [response] - The API response.
   */
  constructor(url, response = {}) {
    const message = getErrorMessage(response)

    super(`General API Error: ${message}`)

    this.message = message
    this.name = 'ApiError'
    this.response = response
    this.url = url
  }
}

/**
 * The API authentication error.
 */
export class AuthenticationError extends Error {
  /**
   * AuthenticationError constructor.
   *
   * @param {object} [response] - The API response.
   */
  constructor(response = {}) {
    const message = getErrorMessage(response)
    super(`Authentication Failed. Server said: '${message}'`)

    this.message = message
    this.name = 'AuthenticationError'
  }
}

/**
 * The API bad request error.
 */
export class BadRequestError extends Error {
  /**
   * BadRequestError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} [response] - The API response.
   */
  constructor(url, response = {}) {
    const message = getErrorMessage(response)

    super(`Bad Request Error at '${url}'. Server said: '${message}'`)

    this.message = message
    this.name = 'BadRequestError'
    this.response = response
    this.url = url
  }
}

/**
 * The API conflict error.
 */
export class ConflictError extends Error {
  /**
   * ConflictError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} [response] - The API response.
   */
  constructor(url, response) {
    const message = getErrorMessage(response)

    super(`Conflict Error at ${url}. Server said: '${message}'`)

    this.message = message
    this.name = 'ConflictError'
    this.response = response
    this.url = url
  }
}

/**
 * The API forbidden error.
 */
export class ForbiddenError extends Error {
  /**
   * ForbiddenError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} [response] - The API response.
   */
  constructor(url, response) {
    const message = getErrorMessage(response)

    super(`Forbidden Error at ${url}. Server said: '${message}'`)

    this.message = message
    this.name = 'ForbiddenError'
    this.response = response
    this.url = url
  }
}

/**
 * The API invalid response error.
 */
export class InvalidResponseError extends Error {
  /**
   * InvalidResponseError constructor.
   *
   * @param {string} url - The fetch url.
   */
  constructor(url) {
    super('Unable to parse the response from the API. Not valid JSON.')
    this.name = 'InvalidResponseError'
    this.url = url
  }
}

/**
 * The API not found error.
 */
export class NotFoundError extends Error {
  /**
   * NotFoundError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} [response] - The API response.
   */
  constructor(url, response = {}) {
    const message = getErrorMessage(response)

    super(`API not found at '${url}'. Server said: '${message}'`)

    this.message = message
    this.name = 'NotFoundError'
    this.response = response
    this.url = url
  }
}

/**
 * The API server error.
 */
export class ServerError extends Error {
  /**
   * ServerError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} response - The API response.
   */
  constructor(url, response) {
    const message = getErrorMessage(response)

    super(`API failed with a 500-level error at ${url}`)

    this.message = message
    this.name = 'ServerError'
    this.response = response
    this.url = url
  }
}

/**
 * The API unauthorized error.
 */
export class UnauthorizedError extends Error {
  /**
   * UnauthorizedError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} response - The API response.
   */
  constructor(url, response) {
    const message = getErrorMessage(response)

    super(`Unauthorized Error at ${url}. Server said: '${message}'`)

    this.message = message
    this.name = 'UnauthorizedError'
    this.response = response
    this.url = url
  }
}

/**
 * The API unprocessable entity error.
 */
export class UnprocessableEntityError extends Error {
  /**
   * UnprocessableEntityError constructor.
   *
   * @param {string} url - The fetch url.
   * @param {object} response - The API response.
   */
  constructor(url, response) {
    const message = getErrorMessage(response)

    super(`Unprocessable Entity Error at ${url}. Server said: '${message}'`)

    this.message = message
    this.name = 'UnprocessableEntityError'
    this.response = response
    this.url = url
  }
}
