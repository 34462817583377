// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Boolean
import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Prayer(
  /**
   * Unique identifier
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The user's id number
   */
  @SerialName("user_id")
  @ProtoNumber(2)
  @JvmField
  val userId: Long? = null,
  /**
   * Title of the prayer created by user.
   */
  @SerialName("title")
  @ProtoNumber(3)
  @JvmField
  val title: String? = null,
  /**
   * Prayer text entered by the user.
   */
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  /**
   * Unified Standard Format Markers
   */
  @SerialName("usfm")
  @ProtoNumber(5)
  @JvmField
  val usfm: String? = null,
  /**
   * Bible version id for text to be referenced in the prayer.
   */
  @SerialName("version_id")
  @ProtoNumber(6)
  @JvmField
  val versionId: Int? = null,
  /**
   * This is the date when a prayer is answered.
   */
  @SerialName("answered")
  @ProtoNumber(7)
  @JvmField
  val answered: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("status")
  @ProtoNumber(8)
  @Serializable(with=youversion.red.prayer.api.model.StatusTypeSerializer::class)
  @JvmField
  val status: StatusType? = null,
  @SerialName("sharing_policy")
  @ProtoNumber(9)
  @Serializable(with=youversion.red.prayer.api.model.SharingPolicySerializer::class)
  @JvmField
  val sharingPolicy: SharingPolicy? = null,
  /**
   * This is the date when the prayer was first created & available for users to consume.
   */
  @SerialName("created_dt")
  @ProtoNumber(10)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer was edited (any property changed.)
   */
  @SerialName("updated_dt")
  @ProtoNumber(11)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * When the creator of the prayer has added a text update to the prayer this will be true until
   * the user clicks on the prayer.
   */
  @SerialName("is_updated")
  @ProtoNumber(12)
  @JvmField
  val isUpdated: Boolean? = null,
  /**
   * This is the time that the client published an official "update" to the prayer.
   */
  @SerialName("last_prayer_update")
  @ProtoNumber(13)
  @JvmField
  val lastPrayerUpdate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * Array of user Ids that shared this prayer with you.
   */
  @SerialName("from_users")
  @ProtoNumber(14)
  @JvmField
  val fromUsers: List<Long>? = null,
  /**
   * This is the date when the prayer update was seen.
   */
  @SerialName("seen_update")
  @ProtoNumber(15)
  @JvmField
  val seenUpdate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer update was seen.
   */
  @SerialName("seen_overview")
  @ProtoNumber(16)
  @JvmField
  val seenOverview: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * Can a prayer receive new comments?
   */
  @SerialName("comment_enabled")
  @ProtoNumber(17)
  @JvmField
  val commentEnabled: Boolean = false
) : red.Serializable {
  init {
    freeze()
  }
}
