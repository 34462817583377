package red

import youversion.red.security.impl.google.GoogleUserManager

internal actual object REDPlatformInit {

    @JsName("initialize")
    fun initialize() = initialize(WebContext(), null, null)

    actual fun initialize(context: AppContext, accessGroup: String?, googleWebClientId: String?) {
        googleWebClientId?.let { GoogleUserManager.googleClientId = it }
    }
}
