// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Suggestion(
  @SerialName("text")
  @ProtoNumber(17)
  @JvmField
  val text: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
