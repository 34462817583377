// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * The shareability of the prayer.
 */
enum class PrivacyStatus(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  INDIVIDUAL("individual", 1),

  CHAIN("chain", 2);
}

internal class PrivacyStatusSerializer : KSerializer<PrivacyStatus> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.prayer.api.model.PrivacyStatus")

  override fun serialize(encoder: Encoder, value: PrivacyStatus) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): PrivacyStatus {
    if (decoder is JsonDecoder) {
      return PrivacyStatus.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> PrivacyStatus.UNKNOWN
      1 -> PrivacyStatus.INDIVIDUAL
      2 -> PrivacyStatus.CHAIN
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
