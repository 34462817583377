// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Which authentication method was used to create account
 */
enum class CreateAccountMethod(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  EMAIL("email", 1),

  FACEBOOK("facebook", 2),

  GOOGLE("google", 3),

  APPLE("apple", 4);
}

internal class CreateAccountMethodSerializer : KSerializer<CreateAccountMethod> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.CreateAccountMethod")

  override fun serialize(encoder: Encoder, value: CreateAccountMethod) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): CreateAccountMethod {
    if (decoder is JsonDecoder) {
      return CreateAccountMethod.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> CreateAccountMethod.UNKNOWN
      1 -> CreateAccountMethod.EMAIL
      2 -> CreateAccountMethod.FACEBOOK
      3 -> CreateAccountMethod.GOOGLE
      4 -> CreateAccountMethod.APPLE
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
