// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api

import kotlin.ByteArray
import kotlin.OptIn
import kotlinx.serialization.DeserializationStrategy
import kotlinx.serialization.SerializationStrategy
import kotlinx.serialization.json.Json
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.polymorphic
import kotlinx.serialization.protobuf.ProtoBuf
import kotlinx.serialization.serializer
import red.platform.http.FormatType
import red.platform.http.Serializer
import red.platform.threads.freeze
import youversion.red.prayer.api.model.events.CreatePrayer
import youversion.red.prayer.api.model.events.PrayerCardAction
import youversion.red.prayer.api.model.events.PrayerCommentAction
import youversion.red.prayer.api.model.events.PrayerRequest
import youversion.red.prayer.api.model.events.PrayerSuggestionAction
import youversion.red.prayer.deeplink.PrayerDeepLinkHandler

object PrayerApiSerializer : Serializer {
  private val context: SerializersModule = SerializersModule {

      }

  private val json: Json = Json { isLenient = true; ignoreUnknownKeys = true;
      allowSpecialFloatingPointValues = true; serializersModule = context }

  @OptIn(kotlinx.serialization.ExperimentalSerializationApi::class)
  private val protobuf: ProtoBuf = ProtoBuf { encodeDefaults = true; serializersModule = context }

  init {
    freeze()}

  override fun <T> serialize(
    type: FormatType,
    serializer: SerializationStrategy<T>,
    obj: T
  ): ByteArray = when (type) {
     FormatType.JSON -> json.encodeToString(serializer, obj).encodeToByteArray()
     FormatType.PROTOBUF -> protobuf.encodeToByteArray(serializer, obj)
  }
  override fun <T> deserialize(
    type: FormatType,
    deserializer: DeserializationStrategy<T>,
    data: ByteArray
  ): T? = when (type) {
     FormatType.JSON -> json.decodeFromString(deserializer, data.decodeToString())
     FormatType.PROTOBUF -> protobuf.decodeFromByteArray(deserializer, data)
  }}
