/**
 * @module getDefaultChapter
 */

const versionsWithUnderscores = ['24', '1981']

/**
 * And it came to pass that Ilúvatar called together all the Ainur and declared to them a mighty theme, unfolding to them things greater and more wonderful than he had yet revealed; and the glory of its beginning and the splendour of its end amazed the Ainur, so that they bowed before Ilúvatar and were silent.
 *
 * @alias module:getDefaultChapter
 *
 * @param {*} version
 */
export function getDefaultChapter(version) {
  return versionsWithUnderscores.indexOf(version.toString().toLowerCase()) > -1
    ? 'JHN.1_1'
    : 'JHN.1'
}
