// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.languages

import kotlin.Boolean
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Language(
  @SerialName("language_tag")
  @ProtoNumber(1)
  @JvmField
  val languageTag: String? = null,
  @SerialName("is_source_language")
  @ProtoNumber(2)
  @JvmField
  val isSourceLanguage: Boolean? = null,
  @SerialName("is_localization_language")
  @ProtoNumber(3)
  @JvmField
  val isLocalizationLanguage: Boolean? = null
) : red.Serializable {
  init {
    freeze()
  }
}
