package youversion.red.prayer.tasks

import com.squareup.sqldelight.internal.AtomicBoolean
import red.platform.Log
import red.platform.LogLevel
import red.platform.currentTimeMillis
import red.platform.network.ConnectivityState
import red.platform.network.NetworkConnectivity
import red.platform.settings.Settings
import red.platform.threads.SharedImmutable
import red.platform.threads.SuspendedLock
import red.platform.threads.freeze
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.security.service.UsersService

@SharedImmutable
internal val changesLock = SuspendedLock().freeze()

inline fun requireNetwork(onCheckFailed: (() -> Unit)) {
    // TODO: check network better?
    if (NetworkConnectivity.state != ConnectivityState.Online) {
        onCheckFailed()
    }
}

suspend inline fun requireUser(onCheckFailed: (() -> Unit)) {
    assertNotMainThread()
    @Suppress("deprecation")
    if (UsersService.getCurrentUser()?.id ?: 0 == 0)
        onCheckFailed()
}

inline fun ensureNotCached(key: String, cacheTime: Long, force: Boolean = false, onCheckFailed: (() -> Unit)) {
    val lastSync = Settings.defaultSettings.getLong(key, 0L)
    if (!force && currentTimeMillis().toLong() < (lastSync + cacheTime)) {
        onCheckFailed()
    }
}

inline fun AtomicBoolean.sync(block: (() -> Unit)) {
    if (this.get()) {
        if (Log.level == LogLevel.DEBUG)
            Log.i("red-prayer", "already syncing task, returning")
        return
    }
    try {
        this.set(true)
        block()
    } finally {
        this.set(false)
    }
}
