package youversion.red.prayer.guided.model

import red.platform.settings.Settings

open class ViewedPrayersDiskCache(private val cacheKey: String) : ViewedPrayersCache {

    private val delimiter = ","

    override var ids: List<String>
        get() = Settings.defaultSettings.getString(cacheKey)
            ?.split(delimiter)
            ?: emptyList()
        set(value) = Settings.defaultSettings.edit()
            .putString(cacheKey, value.joinToString(delimiter))
            .commit()

    override fun clear() {
        ids = emptyList()
    }
}

/**
 * Used to keep track of which prayer list items you've seen in a Guided Prayer in the current cycle through your entire prayer list.
 * This allows us to never show a duplicate prayer until the user has seen all of their prayers.
 */
object FullPrayerListDiskCache : ViewedPrayersDiskCache("red.prayer.guidedprayers.viewed.prayers.prayer-list")

/**
 * Used to keep track of which prayer list items you've seen in the current session.
 * This makes sure we don't see duplicate prayers in the current session.
 */
object SessionDiskCache : ViewedPrayersDiskCache("red.prayer.guidedprayers.viewed.prayers.session")
