package youversion.red.login.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core", "analytics")
class LoginModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
