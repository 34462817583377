package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class Moments(
    @ProtoNumber(1) val moments: List<Moment>? = null,
    @ProtoNumber(2) val nextPage: Int? = null
)
