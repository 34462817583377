// Auto-Generated from OpenAPI Spec
package youversion.red.users.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Where the user subscribed from
 */
enum class PlanSubscribeReferrer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNKNOWN("unknown", 0),

  FEATURED("featured", 1),

  BROWSE("browse", 2),

  MOMENT("moment", 3),

  SAVED_FOR_LATER("saved_for_later", 4),

  EVENT("event", 5),

  DISCOVER("discover", 6),

  DISCOVER_SEARCH("discover_search", 7),

  PLAN_FINDER_HOME_MY_PLANS("plan_finder_home_my_plans", 8),

  PLAN_FINDER_DISCOVER("plan_finder_discover", 9),

  FRIENDS_PLANS("friends_plans", 10),

  COMPLETED_PLANS("completed_plans", 11),

  DEEP_LINK("deep_link", 12),

  SUBSCRIPTION("subscription", 13),

  INVITATION("invitation", 14),

  RELATED_TO_PLAN("related_to_plan", 15),

  RELATED_TO_VERSE("related_to_verse", 16),

  ONBOARDING("onboarding", 17),

  GUEST_PROMPT("guest_prompt", 18),

  CHURCH_PROFILE_FEATURED("church_profile_featured", 19);
}

internal class PlanSubscribeReferrerSerializer : KSerializer<PlanSubscribeReferrer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.users.api.model.PlanSubscribeReferrer")

  override fun serialize(encoder: Encoder, value: PlanSubscribeReferrer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): PlanSubscribeReferrer {
    if (decoder is JsonDecoder) {
      return PlanSubscribeReferrer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> PlanSubscribeReferrer.UNKNOWN
      1 -> PlanSubscribeReferrer.FEATURED
      2 -> PlanSubscribeReferrer.BROWSE
      3 -> PlanSubscribeReferrer.MOMENT
      4 -> PlanSubscribeReferrer.SAVED_FOR_LATER
      5 -> PlanSubscribeReferrer.EVENT
      6 -> PlanSubscribeReferrer.DISCOVER
      7 -> PlanSubscribeReferrer.DISCOVER_SEARCH
      8 -> PlanSubscribeReferrer.PLAN_FINDER_HOME_MY_PLANS
      9 -> PlanSubscribeReferrer.PLAN_FINDER_DISCOVER
      10 -> PlanSubscribeReferrer.FRIENDS_PLANS
      11 -> PlanSubscribeReferrer.COMPLETED_PLANS
      12 -> PlanSubscribeReferrer.DEEP_LINK
      13 -> PlanSubscribeReferrer.SUBSCRIPTION
      14 -> PlanSubscribeReferrer.INVITATION
      15 -> PlanSubscribeReferrer.RELATED_TO_PLAN
      16 -> PlanSubscribeReferrer.RELATED_TO_VERSE
      17 -> PlanSubscribeReferrer.ONBOARDING
      18 -> PlanSubscribeReferrer.GUEST_PROMPT
      19 -> PlanSubscribeReferrer.CHURCH_PROFILE_FEATURED
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
