// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GuidedPrayerDay(
  /**
   * Unique identifier for a Prayer Guide's day details
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * URL to retrieve the image asset used in the home feed as the "cover image" for daily content.
   */
  @SerialName("image_url")
  @ProtoNumber(2)
  @JvmField
  val imageUrl: String? = null,
  /**
   * Overview copy for the day's Prayer Guide
   */
  @SerialName("title")
  @ProtoNumber(3)
  @JvmField
  val title: String? = null,
  /**
   * default hex color code for the background
   */
  @SerialName("light_bg_color")
  @ProtoNumber(4)
  @JvmField
  val lightBgColor: String? = null,
  /**
   * hex color code for the background for dark mode (when device is in "dark mode")
   */
  @SerialName("dark_bg_color")
  @ProtoNumber(5)
  @JvmField
  val darkBgColor: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
