package youversion.red.geoip.service

import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.geoip.model.GeoIP

fun GeoIPService() = service<IGeoIPService>()

@ExportedService
interface IGeoIPService : IService {

    suspend fun getGeoIP(): GeoIP?
}
