// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.bafk

import kotlin.Boolean
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AbstractAnalyticsEvent

@Serializable
@SerialName("bafk.KidsTriviaAnswerEvent")
data class KidsTriviaAnswerEvent(
  @SerialName("story")
  @ProtoNumber(1)
  val story: String? = null,
  @SerialName("question_id")
  @ProtoNumber(2)
  val questionId: String? = null,
  @SerialName("correct")
  @ProtoNumber(3)
  val correct: Boolean? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AbstractAnalyticsEvent(), red.Serializable {
  init {
    freeze()
  }

  constructor(
    story: String? = null,
    questionId: String? = null,
    correct: Boolean? = null
  ) : this(story, questionId, correct, red.platform.now())
}
