// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class LocalePlatform(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ANDROID("android", 1),

  IOS("ios", 2);
}

internal class LocalePlatformSerializer : KSerializer<LocalePlatform> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.locales.api.model.LocalePlatform")

  override fun serialize(encoder: Encoder, value: LocalePlatform) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): LocalePlatform {
    if (decoder is JsonDecoder) {
      return LocalePlatform.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> LocalePlatform.UNSUPPORTED_PLACEHOLDER
      1 -> LocalePlatform.ANDROID
      2 -> LocalePlatform.IOS
      else -> LocalePlatform.UNSUPPORTED_PLACEHOLDER
    }
  }
}
