package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentColorData(
    @ProtoNumber(1) val reference: String? = null,
    @ProtoNumber(2) val color: String? = null
)

@Serializable
data class MomentVerseColors(
    @ProtoNumber(1) val verseColor: List<MomentColorData>? = null
)
