package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
enum class PlanSegmentKind {
    @ProtoNumber(0) UNKNOWN,
    @ProtoNumber(1) DEVOTIONAL,
    @ProtoNumber(2) REFERENCE,
    @ProtoNumber(3) TALK_IT_OVER
}
