// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Float
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.profiles.ProfileStatus

/**
 * This is a specific view of an Organization but still represents an Organization. Here it is
 * combined with its Location Profile if it has one in order to filter/show Organizations relative to
 * their physical location.
 */
@Serializable
data class OrganizationLocation(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("status")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.organizations.api.model.profiles.ProfileStatusSerializer::class)
  @JvmField
  val status: ProfileStatus =
      youversion.red.organizations.api.model.profiles.ProfileStatus.UNSUPPORTED_PLACEHOLDER,
  @SerialName("address_line_1")
  @ProtoNumber(11)
  @JvmField
  val addressLine1: String = "",
  @SerialName("address_line_2")
  @ProtoNumber(12)
  @JvmField
  val addressLine2: String = "",
  @SerialName("city")
  @ProtoNumber(13)
  @JvmField
  val city: String = "",
  @SerialName("state")
  @ProtoNumber(14)
  @JvmField
  val state: String = "",
  @SerialName("postal_code")
  @ProtoNumber(15)
  @JvmField
  val postalCode: String = "",
  @SerialName("country")
  @ProtoNumber(16)
  @JvmField
  val country: String = "",
  @SerialName("timezone")
  @ProtoNumber(17)
  @JvmField
  val timezone: String = "",
  @SerialName("latitude")
  @ProtoNumber(19)
  @JvmField
  val latitude: Float = 0f,
  @SerialName("longitude")
  @ProtoNumber(20)
  @JvmField
  val longitude: Float = 0f,
  /**
   * The distance from the client's location in miles to this location.
   */
  @SerialName("distance")
  @ProtoNumber(21)
  @JvmField
  val distance: Float = 0f
) : red.Serializable {
  init {
    freeze()
  }
}
