package youversion.red.installation.js

import kotlin.String
import kotlin.Unit
import kotlin.js.JsName
import kotlin.js.Promise
import red.service.service
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers
import red.tasks.toPromise
import youversion.red.installation.IInstallationService

public object InstallationService {
  private val service: IInstallationService by
      service<youversion.red.installation.IInstallationService>()

  public val installationId: String?
    get() = service.installationId

  @JsName(name = "install")
  public fun install(): Promise<Unit?> =
      CoroutineDispatchers.async(CoroutineDispatchContext.Default) {
    service.install()

  }.toPromise()
}
