package youversion.red.prayer.service

import red.Error
import red.lifecycle.LiveData
import red.lifecycle.MutableLiveData
import red.platform.newDate
import red.platform.now
import red.platform.threads.currentThread
import red.platform.threads.freeze
import red.platform.threads.sync
import red.platform.toLong
import red.platform.toMillis
import red.service.DefaultService
import red.tasks.CoroutineDispatchContext
import red.tasks.CoroutineDispatchers.async
import red.tasks.CoroutineDispatchers.launch
import red.tasks.CoroutineDispatchers.withMain
import red.tasks.addCallback
import red.tasks.assertNotMainThread
import red.toError
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.ActionType
import youversion.red.prayer.api.model.PrayerReferrerType
import youversion.red.prayer.api.model.PrayerSuggestionType
import youversion.red.prayer.api.model.PrayerUser
import youversion.red.prayer.api.model.PrivacyStatus
import youversion.red.prayer.api.model.SharesSent
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.api.model.events.CreatePrayer
import youversion.red.prayer.api.model.events.PrayerCardAction
import youversion.red.prayer.api.model.events.PrayerCommentAction
import youversion.red.prayer.api.model.events.PrayerRequest
import youversion.red.prayer.api.model.events.PrayerSuggestionAction
import youversion.red.prayer.ext.toDb
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerReaction
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate
import youversion.red.prayer.tasks.PrayerChangesSync
import youversion.red.prayer.tasks.PrayerIdsSync
import youversion.red.prayer.tasks.PrayerSync
import youversion.red.prayer.tasks.PrayerUsersSync
import youversion.red.prayer.tasks.PrayersSync
import youversion.red.prayer.tasks.changesLock
import youversion.red.prayer.tasks.requireUser
import youversion.red.prayer.util.PrayerUtil
import youversion.red.prayer.util.PrayerUtil.STATE_DELETED
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY
import youversion.red.prayer.util.PrayerUtil.STATE_NEW
import youversion.red.security.service.UsersService

@DefaultService(IPrayersService::class)
internal class PrayersServiceImpl : IPrayersService {

    internal val usersService by UsersService()

    override fun savePrayer(
        prayer: DbPrayer,
        referrer: PrayerReferrerType?,
        completion: ((result: DbPrayer?, error: Error?) -> Unit)?
    ): LiveData<DbPrayer> {
        val prayerClientId = prayer.clientId
        try {
            requireNotNull(prayer.title) { "must pass title" }
            require(
                prayer.title.length in 1..70
            ) { "prayer title must be no more than 70 characters and must be more than 1 character" }
            if (prayer.content != null)
                require(prayer.content.length <= IPrayersService.PRAYER_DESCRIPTION_CHARACTER_MAX) { "prayer description must be no more than ${IPrayersService.PRAYER_DESCRIPTION_CHARACTER_MAX} characters" }
            if (prayer.usfm != null) {
                require(prayer.usfm.isNotEmpty()) { "usfm must not be empty if passed" }
                require(prayer.versionId != null) { "version id must be passed with usfm" }
            }
            if (prayer.versionId != null) {
                require(prayer.versionId > 0) { "version id must be greater than 0 if passed" }
                require(prayer.usfm != null) { "usfm must be passed with version id" }
            }
            prayer.freeze()
            async {
                requireUser {
                    throw NullPointerException("User not logged in, not creating prayer")
                }
                val new = prayer.createdDt == null
                val p = DbPrayer( // api 19 couldn't handle .copy so we resort to this for now
                    createdDt = if (new) newDate() else prayer.createdDt,
                    updatedDt = newDate(),
                    state = if (new) prayer.state or STATE_NEW or STATE_DIRTY else prayer.state or STATE_DIRTY,
                    userId = if (new) (usersService.getCurrentUser()!!.id).toLong() else prayer.userId,
                    orderIndex = if (new) -1 else prayer.orderIndex,
                    clientId = prayerClientId,
                    serverId = prayer.serverId,
                    title = prayer.title,
                    answeredDt = prayer.answeredDt,
                    content = prayer.content,
                    sharingPolicy = prayer.sharingPolicy,
                    status = prayer.status,
                    lastPrayerUpdate = prayer.lastPrayerUpdate,
                    usfm = prayer.usfm,
                    versionId = prayer.versionId,
                    reactionCount = prayer.reactionCount,
                    fromUsers = prayer.fromUsers,
                    updated = prayer.updated,
                    seenUpdate = prayer.seenUpdate,
                    lastSync = prayer.lastSync
                )

                if (p.status == StatusType.ARCHIVED)
                    PrayerUtil.deleteLastPrayedKey(p.clientId)

                PrayerStore.addPrayer(p)
                // we wanna make sure our name is always in the prayer users list once we have a prayer,
                // even if we're offline and the prayer users sync fails
                PrayerStore.addPrayerUser(PrayerUser(userId = p.userId, isUpdated = false), -1)

                if (new) {
                    CreatePrayer(
                        prayerId = prayer.serverId,
                        versionId = prayer.versionId,
                        reference = prayer.usfm,
                        referrer = referrer
                    ).log()
                } else {
                    val prevPrayer = PrayerStore.getPrayerSync(prayerClientId)
                    if (prevPrayer != null) {
                        if (prevPrayer.answeredDt == null && prayer.answeredDt != null)
                            PrayerCardAction(
                                prevPrayer.serverId,
                                ActionType.ANSWERED
                            ).log()
                    }
                }

                PrayerStore.getPrayerSync(prayerClientId)
            }.addCallback { dbPrayer, e ->
                launch(CoroutineDispatchContext.Network) {
                    try {
                        PrayerChangesSync.syncPrayers()
                        withMain {
                            completion?.invoke(dbPrayer, e)
                        }
                    } catch (e: Exception) {
                        withMain {
                            dbPrayer?.let { deletePrayer(it.clientId) }
                            completion?.invoke(null, e.toError())
                        }
                    }
                }
            }
        } catch (e: Exception) {
            completion?.invoke(null, e.toError()) ?: throw e
        }

        return PrayerStore.getPrayer(prayerClientId)
    }

    override fun getPrayerUsers(
        page: Int,
        force: Boolean
    ): LiveData<List<PrayerUser>> {
        launch(CoroutineDispatchContext.Network) {
            PrayerUsersSync.process(page, force)
        }
        return PrayerStore.getPrayerUsers()
    }

    override fun getPrayerList(): LiveData<List<DbPrayer>> {
        launch(CoroutineDispatchContext.Network) {
            PrayersSync.process()
        }
        return PrayerStore.getPrayerList()
    }

    override suspend fun getPrayerListSync(): List<youversion.red.prayer.model.Prayer> {
        PrayersSync.process()
        return PrayerStore.getPrayerListSync()
    }

    override fun deletePrayer(clientId: String, completion: ((error: Error?) -> Unit)?) {
        val result = async {
            internalDeletePrayer(clientId)
        }
        completion?.let { result.addCallback(completion) }
    }

    override fun removePendingDelete(clientId: String) {
        launch {
            val old = PrayerStore.getPrayerSync(clientId)
                ?: throw NullPointerException("couldn't get prayer from db: $clientId")
            PrayerStore.updatePrayerState(state = old.state xor STATE_DELETED, clientId)
        }
    }

    override fun getPrayer(clientId: String): LiveData<DbPrayer> {
        launch(CoroutineDispatchContext.Network) {
            PrayerSync.process(clientId = clientId)
        }
        return PrayerStore.getPrayer(clientId)
    }

    override fun updateFriendsPrayerStatus(
        clientId: String,
        status: StatusType,
        completion: ((error: Error?) -> Unit)
    ) {
        async {
            PrayerStore.updatePrayerStatus(clientId, status)
            PrayerChangesSync.updateNextAvailableDeleteSyncTime()
        }.addCallback(completion)
    }

    override fun markPrayerAsSeen(clientId: String) {
        launch {
            markPrayerAsSeenSync(clientId)
        }
    }

    suspend fun markPrayerAsSeenSync(clientId: String) {
        val lockOwner = currentThread()
        changesLock.sync(lockOwner) {
            PrayerStore.getPrayerSync(clientId)?.let { prayer ->
                PrayerStore.updatePrayerAsSeen(clientId)
                PrayerChangesSync.process(lockOwner)
                PrayerUsersSync.syncUnreadState(prayer.userId)
            }
        }
    }

    override fun inviteFriends(
        prayerClientId: String,
        friendIds: List<Long>,
        message: String?,
        completion: ((result: List<PrayerShare>?, error: Error?) -> Unit)?
    ) {
        if (message != null)
            require(message.length <= 1000) { "share message must be no more than 1000 characters" }
        val result = async {
            val serverId =
                findServerId(prayerClientId)
                    ?: throw Exception("couldn't find server id for prayer: $prayerClientId")
            val shares = PrayerApi.sharePrayer(
                prayerId = serverId,
                body = SharesSent(
                    receiverIds = friendIds,
                    createdDt = newDate(),
                    message = message
                )
            ).data.map {
                it.toDb().copy(
                    prayerServerId = serverId,
                    prayerClientId = prayerClientId
                )
            }
            shares.let {
                PrayerStore.processShares(it)
            }
            val prayer = PrayerStore.getPrayerSync(prayerClientId)
            if (prayer != null)
                PrayerRequest(
                    prayerId = serverId,
                    isOwner = prayer.userId == usersService.getCurrentUser()?.id?.toLong(),
                    count = friendIds.size.toLong(),
                    privacyStatus = when (prayer.sharingPolicy) {
                        SharingPolicy.ONLYYOU -> PrivacyStatus.INDIVIDUAL
                        SharingPolicy.FRIENDS -> PrivacyStatus.CHAIN
                        else -> PrivacyStatus.UNKNOWN
                    }
                ).log()
            else
                PrayerRequest(
                    prayerId = serverId,
                    count = friendIds.size.toLong()
                ).log()
            shares
        }
        completion?.let { result.addCallback(completion) }
    }

    override fun getShares(prayerClientId: String): LiveData<List<PrayerShare>> {
        launch(CoroutineDispatchContext.Network) {
            PrayerSync.process(clientId = prayerClientId)
        }
        return PrayerStore.getShares(prayerClientId)
    }

    override suspend fun findServerId(clientId: String): Int? {
        val serverId = PrayerStore.getPrayerSync(clientId)?.serverId
        return serverId ?: PrayerSync.process(clientId)
    }

    override val archivedNextPage = MutableLiveData<Boolean>()
    override fun getArchivedPrayers(page: Int): LiveData<List<DbPrayer>> {
        launch(CoroutineDispatchContext.Network) {
            val nextPage = PrayersSync.process(page, PrayersSync.TYPE_ARCHIVED)
            archivedNextPage.postValue(nextPage)
        }
        return PrayerStore.getPrayersByStatus(StatusType.ARCHIVED)
    }

    override val answeredNextPage = MutableLiveData<Boolean>()
    override fun getAnsweredPrayers(page: Int): LiveData<List<DbPrayer>> {
        launch(CoroutineDispatchContext.Network) {
            val nextPage = PrayersSync.process(page, PrayersSync.TYPE_ANSWERED)
            answeredNextPage.postValue(nextPage)
        }
        return PrayerStore.getAnsweredPrayers()
    }

    override fun getReactionsUserIds(prayerClientId: String): LiveData<List<Long>> {
        launch(CoroutineDispatchContext.Network) {
            PrayerSync.processReactions(prayerClientId)
        }
        return PrayerStore.getReactionsUserIds(prayerClientId)
    }

    override fun savePrayerUpdate(
        prayerClientId: String,
        prayerUpdate: PrayerUpdate,
        completion: ((result: List<PrayerUpdate>?, error: Error?) -> Unit)?
    ): LiveData<List<PrayerUpdate>> {
        prayerUpdate.freeze()
        requireNotNull(prayerUpdate.message)
        require(
            prayerUpdate.message.length in 1..1000
        ) { "prayer update message must be no more than 1000 characters and no less than 1 character" }
        async {
            val prayerServerId = findServerId(prayerClientId)
            val new = prayerUpdate.createdDt == null
            val update = prayerUpdate.copy(
                createdDt = if (new) newDate() else prayerUpdate.createdDt,
                updatedDt = newDate(),
                prayerClientId = prayerClientId,
                prayerServerId = prayerServerId,
                state = if (new) prayerUpdate.state or STATE_NEW or STATE_DIRTY else prayerUpdate.state or STATE_DIRTY
            )
            update.createdDt
                ?: throw NullPointerException("couldn't update last prayer update because prayer update's createdDt was null")
            val prayer = PrayerStore.getPrayerSync(prayerClientId)
                ?: throw NullPointerException("couldn't find prayer associated with update")
            if (prayer.lastPrayerUpdate == null || update.createdDt.toMillis()
                    .toLong() > prayer.lastPrayerUpdate.toMillis().toLong()
            ) {
                PrayerStore.updateLastPrayerUpdate(
                    prayerClientId,
                    update.createdDt
                )
            }
            PrayerStore.addPrayerUpdate(update)

            if (new && prayerServerId != null)
                PrayerCardAction(
                    prayerId = prayerServerId,
                    type = ActionType.UPDATE
                ).log()

            PrayerStore.getPrayerUpdatesByPrayerIdSync(prayerClientId)
        }.addCallback { updates, e ->
            completion?.invoke(updates, e)
            launch(CoroutineDispatchContext.Network) {
                PrayerChangesSync.process()
            }
        }

        return PrayerStore.getPrayerUpdatesByPrayerId(prayerClientId)
    }

    override fun getPrayerUpdates(prayerClientId: String): LiveData<List<PrayerUpdate>> {
        launch(CoroutineDispatchContext.Network) {
            PrayerSync.process(clientId = prayerClientId)
        }
        return PrayerStore.getPrayerUpdatesByPrayerId(prayerClientId)
    }

    override fun addComment(
        prayerClientId: String,
        comment: PrayerComment
    ): LiveData<List<PrayerComment>> {
        requireNotNull(comment.message) {
            "message must not be null"
        }
        require(comment.message.length in 1..IPrayersService.PRAYER_COMMENT_CHARACTER_MAX) {
            "message should be no more than ${IPrayersService.PRAYER_COMMENT_CHARACTER_MAX} characters"
        }

        launch {
            val new = comment.createdDt == null
            val c = PrayerComment(
                createdDt = if (new) newDate() else comment.createdDt,
                state = if (new) comment.state.or(STATE_NEW).or(STATE_DIRTY) else comment.state.or(
                    STATE_DIRTY
                ),
                userId = if (new) usersService.getCurrentUser()?.id?.toLong()
                    ?: return@launch else comment.userId,
                updatedDt = newDate(),
                prayerClientId = prayerClientId,
                prayerServerId = comment.prayerServerId ?: findServerId(prayerClientId),
                message = comment.message,
                lastSync = comment.lastSync,
                clientId = comment.clientId,
                serverId = comment.serverId
            )
            PrayerStore.addComment(c)

            if (new)
                PrayerCommentAction(c.prayerServerId).log()
        }

        launch(CoroutineDispatchContext.Network) {
            PrayerChangesSync.process()
        }

        return getComments(prayerClientId)
    }

    override fun getComments(prayerClientId: String): LiveData<List<PrayerComment>> {
        return PrayerStore.getComments(prayerClientId)
    }

    override val hasNextPageComments: LiveData<Boolean> = PrayerSync.hasNextPageComments
    override fun syncComments(prayerClientId: String, page: Int): LiveData<List<PrayerComment>> {
        launch(CoroutineDispatchContext.Network) {
            PrayerSync.processComments(prayerClientId, page)
        }
        return getComments(prayerClientId)
    }

    override fun deleteComment(clientId: String) {
        launch {
            PrayerStore.updateCommentState(
                clientId = clientId,
                state = STATE_DELETED or STATE_DIRTY
            )
        }
        launch(CoroutineDispatchContext.Network) {
            PrayerChangesSync.process()
        }
    }

    override fun logPrayerView(clientId: String) {
        launch {
            val serverId = findServerId(clientId) ?: return@launch
            PrayerCardAction(
                prayerId = serverId,
                type = ActionType.VIEW
            ).log()
        }
    }

    override fun logSuggestionTap(suggestion: PrayerSuggestionType) {
        PrayerSuggestionAction(suggestion).log()
    }

    override fun clearCache() {
        PrayerIdsSync.clearCache()
        PrayerUsersSync.clearCache()
        PrayersSync.clearCache()
        PrayerUtil.deleteAllLastPrayedKeys()
    }

    override fun deleteAllSync() {
        assertNotMainThread()
        PrayerStore.deleteAllUsers()
        PrayerStore.deleteAllReactions()
        PrayerStore.deleteAllUpdates()
        PrayerStore.deleteAllShares()
        PrayerStore.deleteAllComments()
        PrayerStore.deleteAllPrayers()
    }

    override fun deleteAll() {
        launch {
            deleteAllSync()
        }
    }

    internal suspend fun internalDeletePrayer(clientId: String) {
        PrayerStore.updatePrayerState(STATE_DELETED or STATE_DIRTY, clientId)
        PrayerUtil.deleteLastPrayedKey(clientId)
        PrayerChangesSync.updateNextAvailableDeleteSyncTime() // don't sync immediately, in case they want to undo it
    }

    override suspend fun deletePrayer(clientId: String) {
        internalDeletePrayer(clientId)
    }

    override suspend fun addReaction(prayerClientId: String) {
        assertNotMainThread()
        val prayer = PrayerStore.getPrayerSync(prayerClientId)
        val userId = prayer?.userId ?: usersService.getCurrentUser()?.id?.toLong() ?: return
        val previousTotal = PrayerStore.getReactionSync(prayerClientId, userId)?.total ?: 0
        PrayerStore.addReaction(
            PrayerReaction(
                prayerClientId = prayerClientId,
                prayerServerId = prayer?.serverId,
                userId = userId,
                total = previousTotal + 1,
                updatedDt = newDate(),
                needsSyncing = true
            )
        )
        prayer?.serverId?.let { serverId ->
            PrayerCardAction(
                prayerId = serverId,
                type = ActionType.PRAY_TAP
            ).log()
        }
        PrayerUtil.setLastPrayedTime(prayerClientId, now())
        PrayerChangesSync.process()
    }

    override suspend fun deletePrayerUpdate(clientId: String) {
        PrayerStore.updateUpdateState(STATE_DELETED or STATE_DIRTY, clientId)
        PrayerChangesSync.process()
    }

    override suspend fun findClientId(serverId: Int): String? {
        val clientId = PrayerStore.getPrayerByServerIdSync(serverId)?.clientId
        if (clientId == null)
            PrayerSync.process(serverId = serverId)
        return clientId ?: PrayerStore.getPrayerByServerIdSync(serverId)?.clientId
    }

    override suspend fun hasUnreadPrayer(): Boolean = PrayerStore.hasUnreadSync()

    override fun hasUnreadPrayerLiveData(): LiveData<Boolean> = PrayerStore.hasUnread()
}
