/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Fade, Tooltip, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ErrorIcon from '@material-ui/icons/Error'
import { useAsyncImage } from 'hooks'

const useStyles = makeStyles(() => ({
  imageAspectRatio: {
    aspectRatio: ({ aspectRatio }) => aspectRatio || '',
  },
}))

/**
 * Lazy load an image with Material UI Skeleton.
 *
 * @example
 * <LazyImage alt="Example image" aspectRatio="16 / 9" fallback={<img src="https://example.com/error.jpg"} height={150} src="https://example.com/image.jpg" width="100%" />
 *
 * @param {object} props - The component's prop object.
 * @param {string} [props.alt] - The image alt tag.
 * @param {number|string} [props.aspectRatio] - The image aspect ratio, used for images that are sized dynamically. Check out {@link https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio | this example}.
 * @param {string} [props.className] - The classes to be applied to the loading and image components.
 * @param {React.ReactElement|Node} [props.fallback] - The fallback element for errors. Can be React element or node.
 * @param {number|string} props.height - The image height.
 * @param {string} props.src - The image source URL.
 * @param {number|string} props.width - The image width.
 *
 * @returns {React.ReactElement} - The LazyImage component.
 */
export function LazyImage({
  alt,
  aspectRatio,
  className,
  height,
  fallback,
  src,
  width,
  ...props
}) {
  const classes = useStyles({ aspectRatio })
  const { pending, ready, error } = useAsyncImage(src)

  if (pending) {
    return (
      <Skeleton
        className={clsx(classes.imageAspectRatio, className)}
        data-testid="skeleton-loading-image"
        height={height}
        variant="rect"
        width={width}
      />
    )
  }

  if (error || !src) {
    return (
      <>
        {fallback || (
          <Tooltip title="Failed to display image.">
            <Box
              alignItems="center"
              border={1}
              borderColor="border"
              className={clsx(classes.imageAspectRatio, className)}
              color="text.secondary"
              display="flex"
              height={height}
              justifyContent="center"
              width={width}
            >
              <ErrorIcon color="inherit" height="50%" width="50%" />
            </Box>
          </Tooltip>
        )}
      </>
    )
  }

  if (ready) {
    return (
      <Fade in={true}>
        <img
          alt={alt}
          className={clsx(classes.imageAspectRatio, className)}
          height={height}
          src={src}
          width={width}
          {...props}
        />
      </Fade>
    )
  }

  return null
}

LazyImage.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  fallback: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  src: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

LazyImage.defaultProps = {
  alt: '',
  aspectRatio: '',
  className: '',
  fallback: '',
}
