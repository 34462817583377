// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.guidedprayer

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class GuideReferrer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  UNKNOWN("unknown", 1),

  DEEPLINK("deeplink", 2),

  MOMENT("moment", 3),

  LANDING("landing", 4);
}

internal class GuideReferrerSerializer : KSerializer<GuideReferrer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.guidedprayer.GuideReferrer")

  override fun serialize(encoder: Encoder, value: GuideReferrer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): GuideReferrer {
    if (decoder is JsonDecoder) {
      return GuideReferrer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> GuideReferrer.UNSUPPORTED_PLACEHOLDER
      1 -> GuideReferrer.UNKNOWN
      2 -> GuideReferrer.DEEPLINK
      3 -> GuideReferrer.MOMENT
      4 -> GuideReferrer.LANDING
      else -> GuideReferrer.UNSUPPORTED_PLACEHOLDER
    }
  }
}
