/**
 * @module addEvent
 */
/**
 * Creates an event and event listener.
 *
 * @alias addEvent
 *
 * @param {object} root - The object to attach the event and listener to.
 * @param {string} eventName - The event name.
 * @param {Function} callback - The callback function.
 */
export const addEvent = (root, eventName, callback) => {
  if (root.addEventListener) {
    root.addEventListener(eventName, callback, false)
  } else if (root.attachEvent) {
    root.attachEvent(`on${eventName}`, callback)
  }
}
