package youversion.red.prayer.guided.model

import red.platform.threads.freeze

data class GuidedPrayerGuide(
    val id: Int,
    val languageTags: List<String>
) {
    init {
        freeze()
    }
}
