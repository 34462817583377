// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class FeatureSource(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ADMIN("admin", 1),

  SPEC("spec", 2);
}

internal class FeatureSourceSerializer : KSerializer<FeatureSource> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.search.api.model.FeatureSource")

  override fun serialize(encoder: Encoder, value: FeatureSource) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): FeatureSource {
    if (decoder is JsonDecoder) {
      return FeatureSource.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> FeatureSource.UNSUPPORTED_PLACEHOLDER
      1 -> FeatureSource.ADMIN
      2 -> FeatureSource.SPEC
      else -> FeatureSource.UNSUPPORTED_PLACEHOLDER
    }
  }
}
