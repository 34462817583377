// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.shared

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

/**
 * presign parameters
 */
@Serializable
data class PresignParams(
  @SerialName("policy")
  @ProtoNumber(1)
  @JvmField
  val policy: String? = null,
  @SerialName("AWSAccessKeyId")
  @ProtoNumber(2)
  @JvmField
  val awsaccesskeyid: String? = null,
  @SerialName("acl")
  @ProtoNumber(3)
  @JvmField
  val acl: String? = null,
  @SerialName("key")
  @ProtoNumber(4)
  @JvmField
  val key: String? = null,
  @SerialName("signature")
  @ProtoNumber(5)
  @JvmField
  val signature: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
