/**
 * @module partnerPortalApiClient
 */

import { capitalize, paramsToJSON, paramsToQuery } from '@youversion/utils'
import apiClient from 'core/clients/api-client'
import { makeModel as coreMakeModel } from 'core/model-creators/make-model'
import {
  ApiError,
  BadRequestError,
  ConflictError,
  ForbiddenError,
  InvalidResponseError,
  NotFoundError,
  ServerError,
  UnauthorizedError,
  UnprocessableEntityError,
} from 'partner-portal/core/errors'

/**
 * @typedef BuildApiResponseParams
 * @property {string} key - The API call's query key.
 * @property {Function} [makeModel] - Optional model creator.
 * @property {object} response - The API response object.
 * @property {string} url - The full API call url.
 */
/**
 * Build a magic model from an API response.
 *
 * Partner Portal data comes in three shapes. There are conditionals that check for each of these possible shapes in order to properly format the data.
 *
 * `{ thing: {...properties}, meta: { ...pagination } }`
 * `{ thing: {...properties} }`
 * `{ ...properties }`.
 *
 * @param {BuildApiResponseParams} params - The function params.
 *
 * @returns {object} - The model object.
 */
function buildApiResponse({ key, makeModel = null, response, url }) {
  const finalMakeModel = makeModel || coreMakeModel

  let data = null
  let meta

  // Pop meta off the response data so we can handle it by itself.
  if (response.meta) {
    meta = { ...response.meta }
    delete response.meta
  }

  if (Object.keys(response).length === 0) {
    data = null
  } else if (Object.keys(response).length > 1) {
    const { id: responseId, ...responseData } = response

    data = finalMakeModel({
      data: responseData,
      id: responseId,
      name: `${key}:Response`,
    })
  } else if (Array.isArray(Object.values(response)[0])) {
    // Handle array of data.
    data = Object.values(response)[0].map((item) => {
      // Handle an object.
      if (typeof item === 'object') {
        const { id: itemId, ...itemData } = item

        return finalMakeModel({
          data: itemData,
          id: itemId,
          name: `${key}::ResponseItem`,
        })
      }
      return item
    })
  } else if (typeof Object.values(response)[0] === 'object') {
    // Handle an object.
    data = finalMakeModel({
      data: Object.values(response)[0],
      id: Object.values(response)[0].id || null,
      name: `${key}:Response`,
    })
  }

  return {
    $data: data,
    $id: key,
    $name: 'ApiResponse',
    $nextPage: Boolean(meta?.next_page),
    $page: meta?.current_page,
    $url: url,
    $meta: meta ? Object.freeze(meta) : undefined,
  }
}

function getBaseUrl() {
  return process?.env?.REACT_APP_API_ADDRESS || 'http://localhost:3001'
}

function getUrl(path) {
  return [getBaseUrl(), path].join('/')
}

/**
 * @typedef MakeKeyParams
 * @property {boolean} [auth] - Whether or not auth is used.
 * @property {string} method - The API method, like `get`.
 * @property {string} path - The url path after the base url. Example: `https://api.example.com/{path}.
 */
/**
 * Makes the model and react-query key.
 *
 * @param {MakeKeyParams} params - The function params.
 *
 * @returns {string} - The key string.
 */
function makeKey({ auth, method, path }) {
  const authKey = auth ? 'WithAuth' : ''

  return `${capitalize(method)}::${getUrl(path)}::${authKey}`
}

/**
 * @typedef APIClientParams
 * @property {Function} [makeModel] - Custom makeModel function.
 * @property {object} [options] - Passed through as fetch options.
 * @property {string} path - The API endpoint path. Example: `plans/:id`.
 * @property {string} [token] - If included, sets the `Authorization: Bearer ${token}` header.
 * @property {object} [params] - Passed through as the body of the request.
 */
/**
 * Wrapper on top of fetch for YouVersion Partner Portal API calls.
 *
 * @param {APIClientParams} params - The function params.
 *
 * @throws {ApiError} - Throws a generic error for all other error response status codes.
 * @throws {BadRequestError} - Throws an error if the response status code is 400.
 * @throws {ConflictError} - Throws an error if the response status code is 409.
 * @throws {ForbiddenError} - Throws an error if the response status code is 403.
 * @throws {NotFoundError} - Throws an error if the response status code is 404.
 * @throws {ServerError} - Throws an error if the response status code is >= 500.
 * @throws {UnauthorizedError} - Throws an error if the response status code is 401.
 * @throws {UnprocessableEntityError} - Throws an error if the response status code is 422.
 *
 * @returns {Promise<object>} - The response model.
 */
async function partnerPortalApiClient({
  makeModel,
  options = {},
  path,
  token = null,
}) {
  const key = makeKey({
    auth: Boolean(token),
    method: options.method,
    path: path.split('?')[0],
  })

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...options.headers,
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const fetchOptions = {
    ...options,
    headers,
  }

  const url = getUrl(path)
  const response = await apiClient({
    options: fetchOptions,
    url,
  })

  if (response.status === 204) {
    return buildApiResponse({ key, response: {}, url })
  }

  let json
  try {
    json = await response.json()
  } catch (e) {
    throw new InvalidResponseError(url)
  }

  if (response.status === 400) {
    throw new BadRequestError(url, json)
  }

  if (response.status === 401) {
    throw new UnauthorizedError(url, json)
  }

  if (response.status === 403) {
    throw new ForbiddenError(url, json)
  }

  if (response.status === 404) {
    throw new NotFoundError(url, json)
  }

  if (response.status === 409) {
    throw new ConflictError(url, json)
  }

  if (response.status === 422) {
    throw new UnprocessableEntityError(url, json)
  }

  if (response.status >= 500) {
    throw new ServerError(url, json)
  }

  if (![200, 201].includes(response.status)) {
    throw new ApiError(url, json)
  }

  return buildApiResponse({ key, makeModel, response: json, url })
}

/**
 * Make a GET call to YouVersion Partner Portal API.
 *
 * @param {APIClientParams} params - The function params.
 *
 * @returns {Promise<object>} The response model.
 *
 * @see [Model documentation]{@link https://youversion-web-tools.netlify.app/packages/api/model} for a guide on how to use models.
 *
 * @example
 * // API helper/wrapper function file.
 * import { partnerPortalApiClient } from '@youversion/api/partner-portal'
 *
 * async function getPlan(id) {
 *   await partnerPortalApiClient.get({
 *     path: `plans/${id}`
 *     token: '12345'
 *   })
 * }
 *
 * // Implementation in another file.
 * async function fetchData() {
 *   const planModel = await getPlan(1)
 *   return {
 *     primaryOrgId: planModel.$data.primary_organization.$id,
 *     draftDevotionalContent: planModel.$data.draft_devotional_content_blocks.$value,
 *   }
 * }
 */
export async function get({ options = {}, params, path, ...passThroughProps }) {
  return partnerPortalApiClient({
    options: {
      ...options,
      method: 'GET',
    },
    path: `${path}${params ? `?${paramsToQuery(params)}` : ''}`,
    ...passThroughProps,
  })
}

/**
 * Make a POST call to YouVersion Partner Portal API.
 *
 * @param {APIClientParams} params - The function params.
 *
 * @returns {Promise<object>} - The response model.
 *
 * @see [Model documentation]{@link https://youversion-web-tools.netlify.app/packages/api/model} for a guide on how to use models.
 *
 * @example
 * // API helper/wrapper function file.
 * import { partnerPortalApiClient } from '@youversion/api/partner-portal'
 *
 * async function createPlan(data) {
 *   await partnerPortalApiClient.post({
 *     params: {
 *       ...data
 *     },
 *     path: 'plans'
 *     token: '12345'
 *   })
 * }
 *
 * // Implementation in another file.
 * async function saveData() {
 *   const planModel = await createPlan(data)
 *   return {
 *     primaryOrgId: planModel.$data.primary_organization.$id,
 *     draftDevotionalContent: planModel.$data.draft_devotional_content_blocks.$value,
 *   }
 * }
 */
export async function post({ options = {}, params = {}, ...passThroughProps }) {
  return partnerPortalApiClient({
    options: {
      ...options,
      body: paramsToJSON(params),
      method: 'POST',
    },
    ...passThroughProps,
  })
}

/**
 * Make a PATCH call to YouVersion Partner Portal API.
 *
 * @param {APIClientParams} params - The function params.
 *
 * @returns {Promise<object>} - The response model.
 *
 * @see [Model documentation]{@link https://youversion-web-tools.netlify.app/packages/api/model} for a guide on how to use models.
 *
 * @example
 * // API helper/wrapper function file.
 * import { partnerPortalApiClient } from '@youversion/api/partner-portal'
 *
 * async function updatePlan(id, data) {
 *   await partnerPortalApiClient.patch({
 *     params: {
 *       ...data
 *     },
 *     path: `plans/${id}`
 *     token: '12345'
 *   })
 * }
 *
 * // Implementation in another file.
 * async function saveData(data) {
 *   const planModel = await updatePlan(1, data)
 *   return {
 *     primaryOrgId: planModel.$data.plan.primary_organization.$id,
 *     draftDevotionalContent: planModel.$data.draft_devotional_content_blocks.$value,
 *   }
 * }
 */
export async function patch({
  options = {},
  params = {},
  ...passThroughProps
}) {
  return partnerPortalApiClient({
    options: {
      ...options,
      body: paramsToJSON(params),
      method: 'PATCH',
    },
    ...passThroughProps,
  })
}

/**
 * Make a DELETE call to YouVersion Partner Portal API.
 *
 * @param {APIClientParams} params - The function params.
 *
 * @returns {Promise<object>} - The response model.
 *
 * @example
 * // API helper/wrapper function file.
 * import { partnerPortalApiClient } from '@youversion/api/partner-portal'
 *
 * async function deletePlan(id) {
 *   await partnerPortalApiClient.del({
 *     path: `plans/${id}`
 *     token: '12345'
 *   })
 * }
 *
 * // Implementation in another file.
 * async function saveData() {
 *   await deletePlan(1)
 *   // There is only a success or error status code returned from the delete operation, and therefore no response data.
 * }
 */
export async function del({ options = {}, params, path, ...passThroughProps }) {
  return partnerPortalApiClient({
    options: {
      ...options,
      method: 'DELETE',
    },
    path: `${path}${params ? `?${paramsToQuery(params)}` : ''}`,
    ...passThroughProps,
  })
}
