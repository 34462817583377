package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentVerseOfTheDay(
    @ProtoNumber(1) val day: Int? = null,
    @ProtoNumber(2) val imageId: Int? = null,
    @ProtoNumber(3) val usfm: List<String>? = null
)

@Serializable
data class MomentVotd(
    @ProtoNumber(1) val singleDay: List<MomentVerseOfTheDay>? = null
)
