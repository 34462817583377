package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import youversion.red.analytics.module.AnalyticsModuleInitializer

public object RedAnalyticsModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: AnalyticsModuleInitializer = AnalyticsModuleInitializer()

  public override val name: String = "analytics"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    ModuleRegistry["core"].registerServiceFactories()
    
    ModuleRegistry["installation"].registerServiceFactories()
    
    ModuleRegistry["blue"].registerServiceFactories()
    
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    ModuleRegistry["core"].initialize()
    
    ModuleRegistry["installation"].initialize()
    
    ModuleRegistry["blue"].initialize()
    initializer.initialize()
    
  }
}
