package youversion.red.banner.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import youversion.red.banner.model.blocks.BannerBlock
import youversion.red.banner.model.blocks.BlockAlignment

enum class Placement {
    Top,
    Track
}

enum class DisplayType {
    Simple,
    Rich,
    Block,
    @Deprecated("Colored is deprecated, use Themed") Colored,
    Themed
}

typealias ImageId = String

@Serializable
data class BannerBody(
    @ProtoNumber(1) val image: ImageId? = null,
    @ProtoNumber(2) val content: String? = null,
    @ProtoNumber(3) val alignment: BlockAlignment = BlockAlignment.Leading
)

@Serializable
data class Banner(
    @ProtoNumber(2) val type: DisplayType,
    @ProtoNumber(3) val icon: ImageId? = null,
    @ProtoNumber(4) val title: String? = null,
    @ProtoNumber(5) val body: BannerBody? = null,
    @ProtoNumber(6) val url: String,
    @ProtoNumber(7) val placement: Placement = Placement.Track,
    @ProtoNumber(8) val dismissible: Boolean = true,
    @ProtoNumber(9) val callToAction: String,
    @ProtoNumber(1) val id: String = "",
    @ProtoNumber(10) val index: Int = 0,
    @ProtoNumber(11) val blocks: List<BannerBlock> = emptyList(),
    @ProtoNumber(15) val themes: Themes? = null,
    @ProtoNumber(16) val uuid: String = ""
)

@Serializable
data class Themes(
    @ProtoNumber(1) val lightTheme: Theme? = null,
    @ProtoNumber(2) val darkTheme: Theme? = null
)

@Serializable
data class Theme(
    @ProtoNumber(1) val backgroundColor: String? = null,
    @ProtoNumber(2) val headlineColor: String? = null,
    @ProtoNumber(3) val contentColor: String? = null
)
