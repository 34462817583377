import * as Api4Client from '../../../core/clients/api-client'

const subDomain = 'users-service'
const path = 'me'

/**
 * GET user from OAuth token.
 *
 * @param {object} config
 * @param {string} [config.token] - The YouVersion JWT access token.
 */
export async function get({ token } = {}) {
  if (!token) throw new Error('"token" is required')
  return Api4Client.get({
    subDomain,
    path,
    token,
  })
}
