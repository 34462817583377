package youversion.red.analytics.alps

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.lifecycle.AppLifecycleEventSource
import red.lifecycle.AppLifecycleState
import red.platform.Date
import red.platform.DateSerializer
import red.platform.now
import red.platform.threads.freeze
import youversion.red.security.impl.tokens.YouVersionToken

interface AlpsContext

@Serializable
@SerialName("example")
data class ExampleContext(val blah: String) : AlpsContext

@Serializable
data class AlpsEvent(
    @ProtoNumber(1) val euid: String?,
    @ProtoNumber(2) @SerialName("client_id") val clientId: String = YouVersionToken.clientId,
    @ProtoNumber(3) @Serializable(with = DateSerializer::class) val date: Date = now(),
    @ProtoNumber(4) val state: AppLifecycleState = AppLifecycleState.Unknown,
    @ProtoNumber(5) val source: AppLifecycleEventSource = AppLifecycleEventSource.System,
    @ProtoNumber(6) val context: AlpsContext? = null,
    @ProtoNumber(7) val appCode: Int
) {

    init {
        freeze()
    }
}
