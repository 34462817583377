// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GuidedPrayerMusic(
  /**
   * Array of different music.
   */
  @SerialName("data")
  @ProtoNumber(1)
  @JvmField
  val data: List<GuidedPrayerVariations> = emptyList()
) : red.Serializable {
  init {
    freeze()
  }
}
