package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class MomentLArgs(
    @ProtoNumber(1) val friendName: String? = null,
    @ProtoNumber(2) val name: String? = null,
    @ProtoNumber(3) val percentComplete: String? = null,
    @ProtoNumber(4) val referenceHuman: String? = null,
    @ProtoNumber(5) val segment: String? = null,
    @ProtoNumber(6) val title: String? = null,
    @ProtoNumber(7) val totalSegments: String? = null,
    @ProtoNumber(8) val userName: String? = null,
    @ProtoNumber(9) val versionAbbreviation: String? = null
)
