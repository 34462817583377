// Auto-Generated from OpenAPI Spec
package youversion.red.achievements.api.model

import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Achievement(
  /**
   * Unique identifier
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("category")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.achievements.api.model.AchievementTypeSerializer::class)
  @JvmField
  val category: AchievementType? = null,
  /**
   * Localized achievement title. Localizations are controlled by the admin internal tool and pulled
   * from our localization volunteer resources.
   */
  @SerialName("title")
  @ProtoNumber(3)
  @JvmField
  val title: String? = null,
  /**
   * Localized achievement description. Localizations are controlled by the admin internal tool and
   * pulled from our localization volunteer resources.
   */
  @SerialName("description")
  @ProtoNumber(4)
  @JvmField
  val description: String? = null,
  /**
   * URL to resizable base image. Use variable replacement to request whichever image size is needed
   * for display on the current device. *NOTE* for seasonal badges, there is an additional variable to
   * replace for the year. This is because product wanted to be able to have a different image for each
   * year's challenge.
   */
  @SerialName("image_url")
  @ProtoNumber(5)
  @JvmField
  val imageUrl: String? = null,
  /**
   * This is a list that for certain achievement categories (activity) shows you *how many* of an
   * action must take place to earn the achievement. The 0th element is to achieve level 1 of the
   * badge, etc. For other achievements, this may be null / empty.
   */
  @SerialName("level_counts")
  @ProtoNumber(6)
  @JvmField
  val levelCounts: List<Int>? = null,
  /**
   * If not null, this array indicates that an achievement has a start & end date. The start day is
   * the first element, and the end date is the last element. The format is 2 char month, hyphen, and
   * then 2 character day like "01-01"
   */
  @SerialName("dates")
  @ProtoNumber(7)
  @JvmField
  val dates: List<String>? = null,
  /**
   * This lets the client know whether the badge is discoverable and should be displayed in the
   * trophy case.
   */
  @SerialName("discoverable")
  @ProtoNumber(8)
  @JvmField
  val discoverable: Boolean = false,
  /**
   * This is the date when the badge was first created & available for users to receive.
   */
  @SerialName("created_dt")
  @ProtoNumber(9)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("topic")
  @ProtoNumber(10)
  @Serializable(with=youversion.red.achievements.api.model.TopicTypeSerializer::class)
  @JvmField
  val topic: TopicType? = null,
  /**
   * List of either plan ids or plan collection ids
   */
  @SerialName("topic_ids")
  @ProtoNumber(11)
  @JvmField
  val topicIds: List<Int>? = null,
  /**
   * Call-to-action for a user investigating how to earn this badge
   */
  @SerialName("cta")
  @ProtoNumber(12)
  @JvmField
  val cta: CallToAction? = null
) : red.Serializable {
  init {
    freeze()
  }
}
