// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class ChapterRequestIntent(
  internal val serialName: String,
  internal val serialId: Int
) {
  USER("user", 0),

  BACKGROUND("background", 1),

  READER("reader", 2);
}

internal class ChapterRequestIntentSerializer : KSerializer<ChapterRequestIntent> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.ChapterRequestIntent")

  override fun serialize(encoder: Encoder, value: ChapterRequestIntent) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ChapterRequestIntent {
    if (decoder is JsonDecoder) {
      return ChapterRequestIntent.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ChapterRequestIntent.USER
      1 -> ChapterRequestIntent.BACKGROUND
      2 -> ChapterRequestIntent.READER
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
