package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import com.squareup.sqldelight.db.SqlDriver
import red.database.DateAdapter
import youversion.red.prayer.api.model.SharingPolicy
import youversion.red.prayer.api.model.StatusType

fun createQueryWrapper(driver: SqlDriver): PrayerDatabase {
    return PrayerDatabase(
        driver = driver,
        prayerAdapter = Prayer.Adapter(
            answeredDtAdapter = DateAdapter(),
            createdDtAdapter = DateAdapter(),
            lastSyncAdapter = DateAdapter(),
            updatedDtAdapter = DateAdapter(),
            sharingPolicyAdapter = EnumAdapter { SharingPolicy.valueOf(it) },
            statusAdapter = EnumAdapter { StatusType.valueOf(it) },
            fromUsersAdapter = object : ColumnAdapter<List<Long>, String> {
                override fun decode(databaseValue: String): List<Long> {
                    if (databaseValue.isEmpty()) return emptyList()
                    val split = databaseValue.split(",")
                    return if (split.isNullOrEmpty())
                        emptyList()
                    else
                        split.map { it.toLongOrNull() ?: 0L }
                }

                override fun encode(value: List<Long>) = value.joinToString(separator = ",")
            },
            lastPrayerUpdateAdapter = DateAdapter(),
            seenUpdateAdapter = DateAdapter()
        ),
        prayer_reactionAdapter = Prayer_reaction.Adapter(
            updatedDtAdapter = DateAdapter()
        ),
        prayer_usersAdapter = Prayer_users.Adapter(
            lastSyncAdapter = DateAdapter()
        ),
        prayer_updateAdapter = Prayer_update.Adapter(
            createdDtAdapter = DateAdapter(),
            updatedDtAdapter = DateAdapter(),
            lastSyncAdapter = DateAdapter()
        ),
        prayer_shareAdapter = Prayer_share.Adapter(
            createdDtAdapter = DateAdapter()
        ),
        prayer_commentAdapter = Prayer_comment.Adapter(
            createdDtAdapter = DateAdapter(),
            lastSyncAdapter = DateAdapter(),
            updatedDtAdapter = DateAdapter()
        )
    )
}

object Schema : SqlDriver.Schema by PrayerDatabase.Schema {
    override fun create(driver: SqlDriver) {
        PrayerDatabase.Schema.create(driver)
        createQueryWrapper(driver)
    }
}

// TODO: move to database project
class EnumAdapter<E : Enum<*>>(private val enumFactory: (databaseValue: String) -> E) :
    ColumnAdapter<E, String> {
    override fun decode(databaseValue: String): E {
        return enumFactory(databaseValue)
    }

    override fun encode(value: E): String {
        return value.name
    }
}
