package youversion.red.moments.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class PlansFriends(
    @ProtoNumber(1) val planId: Int? = null,
    @ProtoNumber(2) val userIds: List<Long>? = null
)

@Serializable
data class PlansFriendsCollection(
    @ProtoNumber(1) val plansFriends: List<PlansFriends>? = null,
    @ProtoNumber(2) val pageSize: Int = 0,
    @ProtoNumber(3) val nextPage: Int = 0
)
