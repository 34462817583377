// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.reader

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Reader theme selected
 */
enum class ReaderTheme(
  internal val serialName: String,
  internal val serialId: Int
) {
  WHITE("white", 0),

  WARM("warm", 1),

  SAND("sand", 2),

  PAGES("pages", 3),

  CHARCOAL("charcoal", 4),

  DEEP("deep", 5),

  BLACK("black", 6);
}

internal class ReaderThemeSerializer : KSerializer<ReaderTheme> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.dataman.api.model.reader.ReaderTheme")

  override fun serialize(encoder: Encoder, value: ReaderTheme) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): ReaderTheme {
    if (decoder is JsonDecoder) {
      return ReaderTheme.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> ReaderTheme.WHITE
      1 -> ReaderTheme.WARM
      2 -> ReaderTheme.SAND
      3 -> ReaderTheme.PAGES
      4 -> ReaderTheme.CHARCOAL
      5 -> ReaderTheme.DEEP
      6 -> ReaderTheme.BLACK
      else -> throw IllegalArgumentException("Unknown enum value")
    }
  }
}
