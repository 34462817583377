// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.publishers

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.movies.api.model.shared.PresignParams

@Serializable
data class PublisherPresignResponse(
  /**
   * id
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * Thumbnail upload URL
   */
  @SerialName("upload_url")
  @ProtoNumber(8)
  @JvmField
  val uploadUrl: String? = null,
  @SerialName("params")
  @ProtoNumber(9)
  @JvmField
  val params: PresignParams? = null
) : red.Serializable {
  init {
    freeze()
  }
}
