package youversion.red.moments.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies()
class MomentsSharedModuleInitializer : IModuleInitializer {
    override fun initialize() {
    }
}
