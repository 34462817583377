package youversion.red.versification.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import red.platform.threads.freeze
import youversion.red.cache.CacheClearService
import youversion.red.versification.service.VersificationCacheListener

@ModuleDependencies
class VersificationModuleInitializer : IModuleInitializer {

    private val versificationCacheListener = VersificationCacheListener().freeze()

    override fun initialize() {
        CacheClearService.register(versificationCacheListener)
    }
}
