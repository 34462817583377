// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.Long
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Share(
  /**
   * Unique identifier for the share
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * The sender user's id number
   */
  @SerialName("sender_id")
  @ProtoNumber(2)
  @JvmField
  val senderId: Long? = null,
  /**
   * The receiver user's id number
   */
  @SerialName("receiver_id")
  @ProtoNumber(3)
  @JvmField
  val receiverId: Long? = null,
  /**
   * Id of the prayer that has been shared.
   */
  @SerialName("prayer_id")
  @ProtoNumber(4)
  @JvmField
  val prayerId: Int? = null,
  /**
   * This is the date when the prayer was first shared.
   */
  @SerialName("created_dt")
  @ProtoNumber(5)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * The reason why the sender shared this prayer with the receiver.
   */
  @SerialName("message")
  @ProtoNumber(6)
  @JvmField
  val message: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
