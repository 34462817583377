package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date

public data class Prayer_update(
  public val prayerClientId: String,
  public val prayerServerId: Int?,
  public val clientId: String,
  public val serverId: Int?,
  public val message: String?,
  public val updatedDt: Date?,
  public val createdDt: Date?,
  public val state: Int?,
  public val lastSync: Date?
) {
  public override fun toString(): String = """
  |Prayer_update [
  |  prayerClientId: $prayerClientId
  |  prayerServerId: $prayerServerId
  |  clientId: $clientId
  |  serverId: $serverId
  |  message: $message
  |  updatedDt: $updatedDt
  |  createdDt: $createdDt
  |  state: $state
  |  lastSync: $lastSync
  |]
  """.trimMargin()

  public class Adapter(
    public val updatedDtAdapter: ColumnAdapter<Date, Long>,
    public val createdDtAdapter: ColumnAdapter<Date, Long>,
    public val lastSyncAdapter: ColumnAdapter<Date, Long>
  )
}
