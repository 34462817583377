package youversion.red.moments.model

import red.platform.http.RequestManager
import red.platform.threads.freeze
import youversion.red.bible.reference.BibleReference

interface IMomentsFetchRequest {
    val source: Long
    val userId: Int?
    val reference: BibleReference?
    val label: String?
    val kind: MomentKind?

    val maxItems: Int

    fun isUserIdUseful(): Boolean
    fun nextPage(): PagedMomentsFetchRequest
    fun withPage(page: Int): PagedMomentsFetchRequest
}

data class MomentsFetchRequest(
    override val source: Long,
    override val userId: Int? = null,
    override val reference: BibleReference? = null,
    override val label: String? = null,
    override val kind: MomentKind? = null
) : IMomentsFetchRequest {

    init {
        freeze()
    }

    override val maxItems: Int
        get() = if (RequestManager.staging) 10 else 25

    override fun isUserIdUseful() = source != MomentSource.COMMUNITY && source != MomentSource.RELATED_NOTES

    override fun nextPage(): PagedMomentsFetchRequest = withPage(1)

    override fun withPage(page: Int): PagedMomentsFetchRequest =
        PagedMomentsFetchRequest(this, page)
}

data class PagedMomentsFetchRequest(
    private val request: MomentsFetchRequest,
    val page: Int = 1
) : IMomentsFetchRequest by request {

    init {
        freeze()
    }

    val offset: Long
        get() = (request.maxItems * (page - 1)).toLong()

    val limit: Long
        get() = request.maxItems.toLong()

    override fun nextPage(): PagedMomentsFetchRequest = copy(page = page + 1)

    override fun withPage(page: Int): PagedMomentsFetchRequest = copy(page = page)
}
