// Auto-Generated from OpenAPI Spec
package youversion.red.locales.api.model

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class ApiTag(
  @SerialName("tag")
  @ProtoNumber(1)
  @JvmField
  val tag: String = ""
) : red.Serializable {
  init {
    freeze()
  }
}
