// Auto-Generated from OpenAPI Spec
package youversion.red.guidedprayer.api.model

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class GuidedPrayerVariations(
  /**
   * The title of a background music choice for guided prayer
   */
  @SerialName("title")
  @ProtoNumber(1)
  @JvmField
  val title: String? = null,
  /**
   * The path to a variation asset
   */
  @SerialName("url")
  @ProtoNumber(2)
  @JvmField
  val url: String? = null,
  /**
   * Array of different variations
   */
  @SerialName("variations")
  @ProtoNumber(3)
  @JvmField
  val variations: List<GuidedPrayerVariation> = emptyList(),
  /**
   * Unique Id for each category of variations
   */
  @SerialName("id")
  @ProtoNumber(4)
  @JvmField
  val id: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
