package youversion.red.plans.model

import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class PlanDay(
    @ProtoNumber(1) val day: Int = 0,
    @ProtoNumber(2) val segments: List<PlanSegment> = emptyList()
) {
    init {
        freeze()
    }
}
