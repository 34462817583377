package youversion.red.churches.model

import red.platform.threads.freeze

data class ChurchServiceTime(
    val id: String,
    val dayOfWeek: Int, // 0-6 = Sun-Sat
    val timeOfDay: String, // 24 hour local time (this will be displayed directly no timezone converted needed
    val description: String?
) {
    init {
        freeze()
    }
}
