// Auto-Generated from OpenAPI Spec
package youversion.red.dataman.api.model.discover

import kotlin.Int
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze
import youversion.red.dataman.api.model.AnalyticsEvent
import youversion.red.dataman.api.model.VideoActionType

@Serializable
@SerialName("discover.VideoAction")
data class VideoAction(
  /**
   * Id of the video on which the action occurred.
   */
  @SerialName("video_id")
  @ProtoNumber(1)
  val videoId: Int? = null,
  @SerialName("type")
  @ProtoNumber(2)
  @Serializable(with=youversion.red.dataman.api.model.VideoActionTypeSerializer::class)
  val type: VideoActionType? = null,
  @SerialName("created")
  @ProtoNumber(200)
  override val created: @Serializable(with=red.platform.DateSerializer::class) Date =
      red.platform.now()
) : AnalyticsEvent, red.Serializable {
  init {
    freeze()
  }
}
