/**
 * @module loadScriptAsync
 */
/**
 * Loads a script asynchronously and injects it into the dom.
 *
 * @alias module:loadScriptAsync
 *
 * @param {string} uri - The uri of the script to be loaded.
 * @param {string} [id] - An optional script id.
 *
 * @returns {Promise} Resolves once the script is loaded.
 */
export async function loadScriptAsync(uri, id) {
  return new Promise((resolve, reject) => {
    const tag = document.createElement('script')
    tag.src = uri
    if (id) {
      tag.id = id
    }
    tag.async = true
    tag.onload = () => {
      resolve()
    }
    tag.onerror = () => {
      reject(new Error(`Failed to load async script at ${uri}`))
    }
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
  })
}
