// Auto-Generated from OpenAPI Spec
package youversion.red.blue.api.model.enrollment

import kotlin.String
import kotlin.Suppress
import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import red.Serializable
import red.platform.threads.freeze

interface Enrollment : Serializable {
  val featureId: String?

  companion object {
    @Suppress("unchecked_cast")
    fun serializer(): KSerializer<Enrollment> = PolymorphicSerializer(Enrollment::class)
  }
}
