// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

enum class OrganizationProfileReferrer(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  UNKNOWN("unknown", 1),

  DEEPLINK("deeplink", 2),

  USER_PROFILE("user_profile", 3),

  MORE_SCREEN("more_screen", 4);
}

internal class OrganizationProfileReferrerSerializer : KSerializer<OrganizationProfileReferrer> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.organizations.api.model.organizations.OrganizationProfileReferrer")

  override fun serialize(encoder: Encoder, value: OrganizationProfileReferrer) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): OrganizationProfileReferrer {
    if (decoder is JsonDecoder) {
      return OrganizationProfileReferrer.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> OrganizationProfileReferrer.UNSUPPORTED_PLACEHOLDER
      1 -> OrganizationProfileReferrer.UNKNOWN
      2 -> OrganizationProfileReferrer.DEEPLINK
      3 -> OrganizationProfileReferrer.USER_PROFILE
      4 -> OrganizationProfileReferrer.MORE_SCREEN
      else -> OrganizationProfileReferrer.UNSUPPORTED_PLACEHOLDER
    }
  }
}
