package youversion.red.churches.service

import kotlinx.coroutines.flow.Flow
import red.service.ExportedService
import red.service.IService
import red.service.service
import youversion.red.churches.model.BaseOrganizationProfile
import youversion.red.churches.model.ChurchServiceTime
import youversion.red.churches.model.Location
import youversion.red.churches.model.Organization
import youversion.red.organizations.api.model.organizations.VisibilityPreference
import youversion.red.plans.model.Plan

fun ChurchesService() = service<IChurchesService>()

@ExportedService
interface IChurchesService : IService {
    val savedOrganization: Flow<Organization?>
    suspend fun saveOrganization(id: String, visibilityPreference: VisibilityPreference?)
    suspend fun leaveOrganization(id: String)
    fun getFeaturedPlan(organizationId: String): Flow<Plan?>
    fun getOrganizations(userId: Int? = null): Flow<List<Organization>>
    fun getOrganization(id: String): Flow<Organization?>
    suspend fun getOrganizationSync(id: String): Organization?
    fun getProfiles(organizationId: String): Flow<List<BaseOrganizationProfile>>
    fun getLocations(organizationId: String, latitude: Double? = null, longitude: Double? = null): Flow<List<Location>>
    fun getChurchServiceTimes(organizationId: String): Flow<List<ChurchServiceTime>>
    fun getChurchFriends(organizationId: String, userId: Int? = null): Flow<List<Int>>
    fun getMembersCount(organizationId: String): Flow<Int>
}
