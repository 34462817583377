// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.videos

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Status query string may only be '*' if publisher is also provided
 */
enum class StatusQSEnum(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ALL("*", 1),

  UNREADY("unready", 2),

  FAILED("failed", 3),

  READY("ready", 4),

  PUBLISHED("published", 5);
}

internal class StatusQSEnumSerializer : KSerializer<StatusQSEnum> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.movies.api.model.videos.StatusQSEnum")

  override fun serialize(encoder: Encoder, value: StatusQSEnum) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): StatusQSEnum {
    if (decoder is JsonDecoder) {
      return StatusQSEnum.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> StatusQSEnum.UNSUPPORTED_PLACEHOLDER
      1 -> StatusQSEnum.ALL
      2 -> StatusQSEnum.UNREADY
      3 -> StatusQSEnum.FAILED
      4 -> StatusQSEnum.READY
      5 -> StatusQSEnum.PUBLISHED
      else -> StatusQSEnum.UNSUPPORTED_PLACEHOLDER
    }
  }
}
