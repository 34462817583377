// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.lesson_translations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class Translation(
  /**
   * The id of the lesson produced by the translation
   */
  @SerialName("lesson_id")
  @ProtoNumber(1)
  @JvmField
  val lessonId: Int? = null,
  /**
   * The language of the translation
   */
  @SerialName("language_tag")
  @ProtoNumber(2)
  @JvmField
  val languageTag: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
