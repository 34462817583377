// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.String
import kotlinx.serialization.KSerializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.encoding.Decoder
import kotlinx.serialization.encoding.Encoder
import kotlinx.serialization.json.JsonDecoder
import kotlinx.serialization.json.JsonEncoder
import red.platform.localization.Locales
import red.platform.localization.toUpperCase

/**
 * Comments can either be sorted ascending or descending.
 */
enum class CommentOrder(
  internal val serialName: String,
  internal val serialId: Int
) {
  UNSUPPORTED_PLACEHOLDER("unsupported_placeholder", 0),

  ASC("asc", 1),

  DESC("desc", 2);
}

internal class CommentOrderSerializer : KSerializer<CommentOrder> {
  override val descriptor: SerialDescriptor =
      kotlinx.serialization.descriptors.buildClassSerialDescriptor("youversion.red.prayer.api.model.CommentOrder")

  override fun serialize(encoder: Encoder, value: CommentOrder) {
    if (encoder is JsonEncoder) {
      encoder.encodeString(value.serialName)
    } else {
      encoder.encodeInt(value.serialId)
    }
  }

  override fun deserialize(decoder: Decoder): CommentOrder {
    if (decoder is JsonDecoder) {
      return CommentOrder.valueOf(decoder.decodeString().toUpperCase(Locales.getEnglish()))
    }
    return when (decoder.decodeInt()) {
      0 -> CommentOrder.UNSUPPORTED_PLACEHOLDER
      1 -> CommentOrder.ASC
      2 -> CommentOrder.DESC
      else -> CommentOrder.UNSUPPORTED_PLACEHOLDER
    }
  }
}
