@file:Suppress("unused")

package youversion.red.prayer.service

import red.Error
import red.lifecycle.LiveData
import red.viewmodel.LoadingBaseViewModel
import youversion.red.prayer.api.model.PrayerReferrerType
import youversion.red.prayer.api.model.PrayerUser
import youversion.red.prayer.model.Prayer
import youversion.red.prayer.model.PrayerComment
import youversion.red.prayer.model.PrayerShare
import youversion.red.prayer.model.PrayerUpdate

open class PrayerViewModel : LoadingBaseViewModel() {

    private val prayerService by PrayerService()

    fun getPrayerUsers(page: Int = 1): LiveData<List<PrayerUser>> {
        return prayerService.getPrayerUsers(page)
    }

    fun savePrayer(prayer: Prayer, referrer: PrayerReferrerType? = null): LiveData<Prayer> {
        return prayerService.savePrayer(prayer, referrer)
    }

    fun getPrayer(clientId: String): LiveData<Prayer>? {
        return prayerService.getPrayer(clientId)
    }

    fun getPrayerList(): LiveData<List<Prayer>> {
        return prayerService.getPrayerList()
    }

    fun deletePrayer(clientId: String) {
        async {
            prayerService.deletePrayer(clientId)
        }
    }

    fun inviteFriends(
        friendIds: List<Long>,
        prayerClientId: String,
        message: String? = null,
        completion: (invites: List<PrayerShare>?, error: Error?) -> Unit
    ) {
        prayerService.inviteFriends(
            prayerClientId = prayerClientId,
            friendIds = friendIds,
            message = message,
            completion = completion
        )
    }

    fun getInvites(prayerClientId: String): LiveData<List<PrayerShare>> {
        return prayerService.getShares(prayerClientId = prayerClientId)
    }

    fun addReaction(prayerClientId: String) {
        async {
            prayerService.addReaction(prayerClientId)
        }
    }

    fun getReactionsUserIds(prayerClientId: String): LiveData<List<Long>> {
        return prayerService.getReactionsUserIds(prayerClientId)
    }

    fun savePrayerUpdate(
        prayerClientId: String,
        update: PrayerUpdate
    ): LiveData<List<PrayerUpdate>> {
        return prayerService.savePrayerUpdate(prayerClientId, update)
    }

    fun getPrayerUpdates(prayerClientId: String): LiveData<List<PrayerUpdate>> {
        return prayerService.getPrayerUpdates(prayerClientId)
    }

    fun deletePrayerUpdate(updateClientId: String) {
        async {
            prayerService.deletePrayerUpdate(updateClientId)
        }
    }

    fun addComment(prayerClientId: String, comment: PrayerComment): LiveData<List<PrayerComment>> {
        return prayerService.addComment(prayerClientId, comment)
    }

    fun getComments(prayerClientId: String): LiveData<List<PrayerComment>> {
        return prayerService.getComments(prayerClientId)
    }

    val hasNextPageComments: LiveData<Boolean> = prayerService.hasNextPageComments
    fun syncComments(prayerClientId: String, page: Int): LiveData<List<PrayerComment>> {
        return prayerService.syncComments(prayerClientId, page)
    }

    fun deleteComment(clientId: String) {
        prayerService.deleteComment(clientId)
    }

    val archivedNextPage: LiveData<Boolean> = prayerService.archivedNextPage
    fun getArchivedPrayers(page: Int = 1): LiveData<List<Prayer>> {
        return prayerService.getArchivedPrayers(page)
    }

    val answeredNextPage: LiveData<Boolean> = prayerService.answeredNextPage
    fun getAnsweredPrayers(page: Int = 1): LiveData<List<Prayer>> {
        return prayerService.getAnsweredPrayers(page)
    }

    fun markPrayerAsSeen(prayerClientId: String) {
        prayerService.markPrayerAsSeen(prayerClientId)
    }
}
