package youversion.red.churches.model

import red.platform.threads.freeze

data class Location(
    val id: String,
    val name: String,
    val addressLine1: String,
    val addressLine2: String,
    val longitude: Float,
    val latitude: Float,
    val city: String,
    val state: String,
    val postalCode: String,
    val country: String,
    val timezone: String,
    val distance: Float
) {
    init {
        freeze()
    }
}
