// Auto-Generated from OpenAPI Spec
package youversion.red.search.api.model.events

import kotlin.Int
import kotlin.String
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.analytics.Bundle
import youversion.red.analytics.Event

@Serializable
data class SearchQuery(
  /**
   * text searched for (includes mis-spellings)
   */
  @SerialName("query")
  @ProtoNumber(3)
  val query: String? = null,
  /**
   * User interface language
   */
  @SerialName("app_language")
  @ProtoNumber(9)
  val appLanguage: String? = null,
  /**
   * Plans language
   */
  @SerialName("plan_language")
  @ProtoNumber(10)
  val planLanguage: String? = null,
  /**
   * Bible version id of the history item
   */
  @SerialName("version_id")
  @ProtoNumber(11)
  val versionId: Int? = null,
  /**
   * Entry point to search (ex. unified, plans, emotion, what does the bible say)
   */
  @SerialName("referrer")
  @ProtoNumber(12)
  val referrer: String? = null,
  /**
   * Videos language
   */
  @SerialName("video_language")
  @ProtoNumber(13)
  val videoLanguage: String? = null
) : Event(), red.Serializable {
  override val key: String = "SearchQuery"

  init {
    freeze()
  }

  override fun toBundle(): Bundle {
    val bundle = youversion.red.analytics.Bundle()
    query?.let { bundle.putString("query", query) }
    appLanguage?.let { bundle.putString("app_language", appLanguage) }
    planLanguage?.let { bundle.putString("plan_language", planLanguage) }
    versionId?.let { bundle.putInt("version_id", versionId) }
    referrer?.let { bundle.putString("referrer", referrer) }
    videoLanguage?.let { bundle.putString("video_language", videoLanguage) }
    return bundle
  }
}
