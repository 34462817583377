package youversion.red.movies.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies
import youversion.red.deeplink.AbstractDeepLinkHandler
import youversion.red.deeplink.DeepLinks
import youversion.red.movies.deeplink.MoviesDeepLinkHandler

@ModuleDependencies("core")
class MoviesModuleInitializer : IModuleInitializer {

    override fun initialize() {
        DeepLinks.addHandlers(MoviesDeepLinkHandler as AbstractDeepLinkHandler)
    }
}
