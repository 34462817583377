// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PrayerReaction(
  /**
   * total reactions for this user on this prayer.
   */
  @SerialName("total")
  @ProtoNumber(2)
  @JvmField
  val total: Int? = null,
  /**
   * This is the date when the prayer reaction was updated.
   */
  @SerialName("updated_dt")
  @ProtoNumber(3)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
