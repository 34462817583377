/* cSpell: disable */
// Use export methods like MUI https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/locale/index.js

// TODO: Write script to generate and alphabetize this from JSON `npm locales:generate`
// TODO: Have the script prepend 'This is an autogenerated file. Run `npm locales:generate` to regenerate the file.'

export const afZA = {
  nativeName: 'Afrikaans (South Africa)',
  englishName: 'Afrikaans (South Africa)',
  locale: 'af-ZA',
  momentLocale: 'af-ZA',
  locale2: 'af',
  locale3: 'afr',
  displayName: 'Afrikaans (South Africa)',
}

export const idID = {
  nativeName: 'Bahasa Indonesia',
  englishName: 'Indonesian',
  locale: 'id-ID',
  momentLocale: 'id-ID',
  locale2: 'id',
  locale3: 'ind',
  displayName: 'Bahasa Indonesia',
}

export const msMY = {
  nativeName: 'Bahasa Melayu',
  englishName: 'Malay',
  locale: 'ms-MY',
  momentLocale: 'ms-MY',
  locale2: 'ms',
  locale3: 'msa',
  displayName: 'Bahasa Melayu',
}

export const caES = {
  nativeName: 'Català',
  englishName: 'Catalan',
  locale: 'ca-ES',
  momentLocale: 'ca-ES',
  locale2: 'ca',
  locale3: 'cat',
  displayName: 'Català',
}

export const ptPT = {
  nativeName: 'Português (Portugal)',
  englishName: 'Portuguese (Portugal)',
  locale: 'pt-PT',
  locale2: 'pt',
  locale3: 'por',
  displayName: 'Português (Portugal)',
}

export const csCZ = {
  nativeName: 'Čeština',
  englishName: 'Czech',
  locale: 'cs-CZ',
  momentLocale: 'cs-CZ',
  locale2: 'cs',
  locale3: 'ces',
  displayName: 'Čeština',
}

export const cyGB = {
  nativeName: 'Cymraeg',
  englishName: 'Welsh',
  locale: 'cy-GB',
  momentLocale: 'cy-GB',
  locale2: 'cy',
  locale3: 'cym',
  displayName: 'Cymraeg',
}

export const daDK = {
  nativeName: 'Dansk',
  englishName: 'Danish',
  locale: 'da-DK',
  momentLocale: 'da-DK',
  locale2: 'da',
  locale3: 'dan',
  displayName: 'Dansk',
}

export const deDE = {
  nativeName: 'Deutsch (Deutschland)',
  englishName: 'German (Germany)',
  locale: 'de-DE',
  momentLocale: 'de-DE',
  locale2: 'de',
  locale3: 'deu',
  displayName: 'Deutsch (Deutschland)',
}

export const enGB = {
  nativeName: 'English (UK)',
  englishName: 'English (UK)',
  locale: 'en-GB',
  momentLocale: 'en-GB',
  locale2: 'en',
  locale3: 'eng',
  displayName: 'English (UK)',
}

export const enUS = {
  nativeName: 'English (US)',
  englishName: 'English (US)',
  locale: 'en-US',
  momentLocale: 'en-US',
  locale2: 'en',
  locale3: 'eng',
  displayName: 'English (US)',
}

export const esLA = {
  nativeName: 'Español (Latinoamérica)',
  englishName: 'Spanish (Latin America)',
  locale: 'es-LA',
  momentLocale: 'es-LA',
  locale2: 'es',
  locale3: 'spa',
  displayName: 'Español (Latinoamérica)',
}

export const esES = {
  nativeName: 'Español (España)',
  englishName: 'Spanish (Spain)',
  locale: 'es-ES',
  momentLocale: 'es-ES',
  locale2: 'es',
  locale3: 'spa',
  displayName: 'Español (España)',
}

export const tlPH = {
  nativeName: 'Filipino',
  englishName: 'Filipino',
  locale: 'tl-PH',
  momentLocale: 'tl-PH',
  locale2: 'tl',
  locale3: 'tgl',
  displayName: 'Filipino',
}

export const frFR = {
  nativeName: 'Français (France)',
  englishName: 'French (France)',
  locale: 'fr-FR',
  momentLocale: 'fr-FR',
  locale2: 'fr',
  locale3: 'fra',
  displayName: 'Français (France)',
}

export const he = {
  nativeName: 'עִברִית',
  englishName: 'Hebrew',
  locale: 'he',
  momentLocale: 'he',
  locale2: 'he',
  locale3: 'heb',
  displayName: 'עִברִית',
}

export const hrHR = {
  nativeName: 'Hrvatski',
  englishName: 'Croatian',
  locale: 'hr-HR',
  momentLocale: 'hr-HR',
  locale2: 'hr',
  locale3: 'hrv',
  displayName: 'Hrvatski',
}

export const itIT = {
  nativeName: 'Italiano',
  englishName: 'Italian',
  locale: 'it-IT',
  momentLocale: 'it-IT',
  locale2: 'it',
  locale3: 'ita',
  displayName: 'Italiano',
}

export const kmKH = {
  nativeName: 'ភាសាខ្មែរ',
  englishName: 'Khmer',
  locale: 'km-KH',
  locale2: 'km',
  locale3: 'khm',
  displayName: 'ភាសាខ្មែរ',
}

export const sw = {
  nativeName: 'Kiswahili',
  englishName: 'Swahili',
  locale: 'sw',
  momentLocale: 'sw',
  locale2: 'sw',
  locale3: 'swa',
  displayName: 'Kiswahili',
}

export const lvLV = {
  nativeName: 'Latviešu',
  englishName: 'Latvian',
  locale: 'lv-LV',
  momentLocale: 'lv-LV',
  locale2: 'lv',
  locale3: 'lav',
  displayName: 'Latviešu',
}

export const huHU = {
  nativeName: 'Magyar',
  englishName: 'Hungarian',
  locale: 'hu-HU',
  momentLocale: 'hu-HU',
  locale2: 'hu',
  locale3: 'hun',
  displayName: 'Magyar',
}

export const nlNL = {
  nativeName: 'Nederlands (Nederland)',
  englishName: 'Dutch (Netherlands)',
  locale: 'nl-NL',
  momentLocale: 'nl-NL',
  locale2: 'nl',
  locale3: 'nld',
  displayName: 'Nederlands (Nederland)',
}

export const noNO = {
  englishName: 'Norweigan',
  nativeName: 'Norsk',
  locale: 'no-NO',
  momentLocale: 'nb',
  locale2: 'no',
  locale3: 'nob',
  displayName: 'Norsk',
}

export const plPL = {
  nativeName: 'Polski',
  englishName: 'Polish',
  locale: 'pl-PL',
  momentLocale: 'pl-PL',
  locale2: 'pl',
  locale3: 'pol',
  displayName: 'Polski',
}

export const ptBR = {
  nativeName: 'Português (Brasil)',
  englishName: 'Portuguese (Brazil)',
  locale: 'pt-BR',
  momentLocale: 'pt-BR',
  locale2: 'pt',
  locale3: 'por',
  displayName: 'Português (Brasil)',
}

export const roRO = {
  nativeName: 'Română',
  englishName: 'Romanian',
  locale: 'ro-RO',
  momentLocale: 'ro-RO',
  locale2: 'ro',
  locale3: 'ron',
  displayName: 'Română',
}

export const sqAL = {
  nativeName: 'Shqip',
  englishName: 'Albanian',
  locale: 'sq-AL',
  momentLocale: 'sq-AL',
  locale2: 'sq',
  locale3: 'sqi',
  displayName: 'Shqip',
}

export const skSK = {
  nativeName: 'Slovenčina (Slovakia)',
  englishName: 'Slovak (Slovakia)',
  locale: 'sk-SK',
  momentLocale: 'sk-SK',
  locale2: 'sk',
  locale3: 'slk',
  displayName: 'Slovenčina (Slovakia)',
}

export const fiFI = {
  nativeName: 'Suomi',
  englishName: 'Finnish',
  locale: 'fi-FI',
  momentLocale: 'fi-FI',
  locale2: 'fi',
  locale3: 'fin',
  displayName: 'Suomi',
}

export const svSE = {
  nativeName: 'Svenska',
  englishName: 'Swedish',
  locale: 'sv-SE',
  momentLocale: 'sv-SE',
  locale2: 'sv',
  locale3: 'swe',
  displayName: 'Svenska',
}

export const viVN = {
  nativeName: 'Tiếng Việt',
  englishName: 'Vietnamese',
  locale: 'vi-VN',
  momentLocale: 'vi-VN',
  locale2: 'vi',
  locale3: 'vie',
  displayName: 'Tiếng Việt',
}

export const trTR = {
  nativeName: 'Türkçe',
  englishName: 'Turkish',
  locale: 'tr-TR',
  momentLocale: 'tr-TR',
  locale2: 'tr',
  locale3: 'tur',
  displayName: 'Türkçe',
}

export const mn = {
  nativeName: 'Монгол',
  englishName: 'Mongolian',
  locale: 'mn',
  momentLocale: 'mn',
  locale2: 'mn',
  locale3: 'mon',
  displayName: 'Монгол',
}

export const elGR = {
  nativeName: 'Ελληνικά',
  englishName: 'Greek (Greece)',
  locale: 'el-GR',
  momentLocale: 'el-GR',
  locale2: 'el',
  locale3: 'ell',
  displayName: 'Ελληνικά',
}

export const hiIN = {
  nativeName: 'हिन्दी',
  englishName: 'Hindi',
  locale: 'hi-IN',
  locale2: 'hi',
  locale3: 'hin',
  displayName: 'हिन्दी',
}

export const bgBG = {
  nativeName: 'Български',
  englishName: 'Bulgarian',
  locale: 'bg-BG',
  momentLocale: 'bg-BG',
  locale2: 'bg',
  locale3: 'bul',
  displayName: 'Български',
}

export const mkMK = {
  nativeName: 'Македонски',
  englishName: 'Macedonian',
  locale: 'mk-MK',
  momentLocale: 'mk-MK',
  locale2: 'mk',
  locale3: 'mkd',
  displayName: 'Македонски',
}

export const ruRU = {
  nativeName: 'Русский',
  englishName: 'Russian',
  locale: 'ru-RU',
  momentLocale: 'ru-RU',
  locale2: 'ru',
  locale3: 'rus',
  displayName: 'Русский',
}

export const ukUA = {
  nativeName: 'Українська',
  englishName: 'Ukrainian',
  locale: 'uk-UA',
  momentLocale: 'uk-UA',
  locale2: 'uk',
  locale3: 'ukr',
  displayName: 'Українська',
}

export const ar = {
  nativeName: 'العربية',
  englishName: 'Arabic',
  locale: 'ar',
  momentLocale: 'ar',
  locale2: 'ar',
  locale3: 'ara',
  displayName: 'العربية',
}

export const faIR = {
  nativeName: 'فارسی',
  englishName: 'Farsi',
  locale: 'fa-IR',
  momentLocale: 'fa-IR',
  locale2: 'fa',
  locale3: 'fas',
  displayName: 'فارسی',
}

export const taIN = {
  nativeName: 'தமிழ்',
  englishName: 'Tamil',
  locale: 'ta-IN',
  momentLocale: 'ta-IN',
  locale2: 'ta',
  locale3: 'tam',
  displayName: 'தமிழ்',
}

export const thTH = {
  nativeName: 'ภาษาไทย (ประเทศไทย)',
  englishName: 'Thai (Thailand)',
  locale: 'th-TH',
  momentLocale: 'th-TH',
  locale2: 'th',
  locale3: 'tha',
  displayName: 'ภาษาไทย (ประเทศไทย)',
}

export const my = {
  nativeName: 'မြန်မာယူနီကုတ်',
  englishName: 'Myanmar Burmese (Unicode)',
  locale: 'my',
  momentLocale: 'my',
  locale2: 'my',
  locale3: 'mya',
  displayName: 'မြန်မာယူနီကုတ်',
}

export const myMM = {
  nativeName: 'ျမန္မာေဇာ္ဂ်ီာ',
  englishName: 'Myanmar Burmese (Zawgyi)',
  locale: 'my-MM',
  momentLocale: 'my-MM',
  locale2: 'my',
  locale3: 'myz',
  displayName: 'ျမန္မာေဇာ္ဂ်ီာ',
}

export const koKR = {
  nativeName: '한국어',
  englishName: 'Korean',
  locale: 'ko-KR',
  momentLocale: 'ko-KR',
  locale2: 'ko',
  locale3: 'kor',
  displayName: '한국어',
}

export const zhCN = {
  nativeName: '简体中文',
  englishName: 'Chinese Simplified',
  locale: 'zh-CN',
  momentLocale: 'zh-CN',
  locale2: 'zh',
  locale3: 'zho',
  displayName: '简体中文',
}

export const zhTW = {
  nativeName: '繁體中文',
  englishName: 'Chinese Traditional',
  locale: 'zh-TW',
  momentLocale: 'zh-TW',
  locale2: 'zh',
  locale3: 'zho_tw',
  displayName: '繁體中文',
}

export const jaJP = {
  nativeName: '日本語',
  englishName: 'Japanese',
  locale: 'ja-JP',
  momentLocale: 'ja-JP',
  locale2: 'ja',
  locale3: 'jpn',
  displayName: '日本語',
}

export const zhHK = {
  nativeName: '繁體中文（香港）',
  englishName: 'Chinese Hong Kong',
  locale: 'zh-HK',
  momentLocale: 'zh-HK',
  locale2: 'zh',
  locale3: 'zho_hk',
  displayName: '繁體中文（香港）',
}
