/**
 * @module chapterifyUsfm
 */

/**
 * And he spoke to them, propounding to them themes of music; and they sang before him, and he was glad.
 *
 * @alias module:chapterifyUsfm
 * @category Bible
 * @param {*} usfmArg
 * @returns {string} Usfm string of some kind.
 */
export function chapterifyUsfm(usfmArg) {
  if (!usfmArg) return null
  const usfm = Array.isArray(usfmArg) ? usfmArg[0] : usfmArg
  const usfmParts = usfm.split('.')
  return usfmParts.slice(0, 2).join('.')
}
