package youversion.red.analytics

import red.platform.Log

internal actual fun setAnalyticsUserId(userId: Int?) {
    Log.e("Analytics", "setAnalyticsUserId NOT IMPLEMENTED")
}

internal actual fun setAnalyticsInstallationId(installationId: String) {
    Log.e("Analytics", "setAnalyticsInstallationId NOT IMPLEMENTED")
}

internal actual fun logEvent(key: String, bundle: Bundle): Unit = TODO()
