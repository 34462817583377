/**
 * @module ContentRow
 */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { LazyImage } from 'components/ui/lazy-image'

const MAX_IMAGE_SIZE = 86

const useStyles = makeStyles((theme) => ({
  darkOverlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    borderRadius: theme.spacing(1),
  },
  imageArtwork: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    marginRight: theme.spacing(2),
    minHeight: MAX_IMAGE_SIZE,
    minWidth: 'unset',
    width: ({ imageWidth }) => imageWidth || null,
  },
  listItem: {
    alignItems: 'center',
    boxSizing: 'content-box',
  },
  listItemIcon: {
    marginRight: theme.spacing(2),
    minWidth: 'unset',
    // This is the height of the list item icon, so we're making it the same size.
    width: 24,
  },
  listItemText: {
    margin: 0,
  },
  secondaryActionIcon: {
    color: 'white',
  },
  skeletonActionButton: {
    borderRadius: 99,
  },
}))

/**
 * Content Row Component used to display a variety of content types, such as Plan, Videos, and Podcast content.
 *
 * @alias module:ContentRow
 *
 * @param {object} props - The component props object.
 * @param {object} [props.actionChipProps] - [Material-UI Chip](https://material-ui.com/api/chip/) props spread onto a Chip component to be used for a supplemental action. Action Chip will appear if these props are present.
 * @param {string} [props.caption] - Caption text. Concise, descriptive text about the content in the row.
 * @param {string} [props.description] - Description text. This component will only display the first line, so it's recommended to pass in a substring of the entire description.
 * @param {number} [props.imageAspectRatio] - Aspect ratio of the image provided. This is necessary to properly display the image in its loading and loaded states. Defaults to `1 / 1`. For more information, see [CSS aspect-ratio property](https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio).
 * @param {string} [props.imageArtworkUrl] - Source URL for the image art.
 * @param {boolean} [props.isLoading] - The row loading state is displayed.
 * @param {boolean} [props.isSelected] - The row is selected.
 * @param {React.ReactElement} [props.linkComponent] - A link component, typically from React Router.
 * @param {object} [props.secondaryAction] - Create an overlay over the provided image artwork with an icon button. Example: secondaryAction={{label: 'Add to collection', icon: AddIcon, onClick: () => {}}}.
 * @param {React.ReactElement} [props.startIcon] - An icon element. For instance, a drag handle.
 * @param {boolean} [props.openInNewTab] - Determines if the row will open the link in a new tab. This requires the `to` prop to be passed.
 * @param {string} props.title - Title text for subject matter of the content in the row.
 * @param {string} [props.to] - The URL path to open when the content row is clicked.
 *
 * @returns {React.ReactElement} - The ContentRow component.
 *
 * @example
 * import React from 'react'
 * import { Link } from 'react-router-dom'
 * import { ContentRow } from '@youversion/react'
 * import { List } from '@material-ui/core'
 * import { handleAddToCollection } from './helpers'
 *
 * function MyComponent() {
 *   return (
 *    <List>
 *      <ContentRow
 *        key={1234}
 *        caption="7 Days"
 *        description="Hillsong College"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        linkComponent={Link}
 *        secondaryAction={{
 *          label: 'Add to collection',
 *          icon: AddCircleIcon,
 *          onClick: () => handleAddToCollection(1234),
 *        }}
 *        title="Bold Leaders"
 *        to="https://bible.com/plans/1234"
 *      />
 *      <ContentRow
 *        caption="5 Days"
 *        description="Wisdom Hunters"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        title="The Spiritual Life of a Leader"
 *        to="https://bible.com/plans/1234"
 *      />
 *      <ContentRow
 *        caption="3 Days"
 *        description="Grace Hill Media"
 *        imageArtworkUrl="https://placehold.co/400x400"
 *        title="You Are Unique & You Are Loved"
 *        to="https://bible.com/plans/1234"
 *      />
 *    </List>
 *   )
 * }
 */
export function ContentRow({
  actionChipProps,
  caption,
  startIcon,
  imageAspectRatio,
  imageArtworkUrl,
  isLoading,
  isSelected,
  linkComponent,
  openInNewTab,
  description,
  secondaryAction,
  title,
  to,
  ...props
}) {
  const classes = useStyles({ imageWidth: MAX_IMAGE_SIZE * imageAspectRatio })

  function handleSecondaryAction(event) {
    // These two function calls help the action button be clickable, without navigating the user to
    // another page.
    event.stopPropagation()
    event.preventDefault()

    secondaryAction.onClick()
  }

  function handleActionChipClick(event) {
    // These two function calls help the action button be clickable, without navigating the user to another page.
    event.stopPropagation()
    event.preventDefault()

    const { onClick } = actionChipProps
    if (onClick && typeof onClick === 'function') {
      onClick()
    }
  }

  const actionChip =
    Object.keys(actionChipProps).length > 1 ? (
      <Chip
        // Default to 'outlined' variant, but this can easily be overridden with `actionChipProps`.
        variant="outlined"
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...actionChipProps}
        onClick={handleActionChipClick}
        size="small"
      />
    ) : null

  if (isLoading) {
    return (
      <ListItem
        button={Boolean(to)}
        className={classes.listItem}
        selected={isSelected}
      >
        {startIcon ? (
          <ListItemIcon className={classes.listItemIcon}>
            {startIcon}
          </ListItemIcon>
        ) : null}

        <ListItemAvatar>
          <div>
            <Skeleton
              className={classes.imageArtwork}
              height={MAX_IMAGE_SIZE}
              variant="rect"
              width={MAX_IMAGE_SIZE * imageAspectRatio}
            />
          </div>
        </ListItemAvatar>

        <ListItemText
          className={classes.listItemText}
          disableTypography={true}
          primary={
            <Box width="100%">
              {caption ? (
                <Typography color="textSecondary" variant="caption">
                  <Skeleton width="10%" />
                </Typography>
              ) : null}

              {title ? (
                <Typography color="textPrimary" noWrap={true} variant="body1">
                  <Skeleton width="40%" />
                </Typography>
              ) : null}

              {description ? (
                <Typography
                  color="textSecondary"
                  display="block"
                  gutterBottom={true}
                  noWrap={true}
                  variant="caption"
                >
                  <Skeleton width="100%" />
                </Typography>
              ) : null}

              {actionChip ? (
                <Skeleton
                  className={classes.skeletonActionButton}
                  variant="rect"
                >
                  {actionChip}
                </Skeleton>
              ) : null}
            </Box>
          }
        />

        {to ? (
          <Box color="text.secondary" ml={1}>
            {openInNewTab ? (
              <OpenInNewIcon color="inherit" fontSize="small" />
            ) : (
              <ChevronRightIcon color="inherit" />
            )}
          </Box>
        ) : null}
      </ListItem>
    )
  }

  let component
  if (linkComponent) {
    component = linkComponent
  } else if (to) {
    component = 'a'
  }

  return (
    <ListItem
      aria-label={to ? `Go to ${title}` : null}
      button={Boolean(to)}
      className={classes.listItem}
      component={component}
      href={!linkComponent && to ? to : null}
      rel={!linkComponent && to ? 'noopener noreferrer' : null}
      selected={isSelected}
      target={openInNewTab ? '_blank' : null}
      to={linkComponent && to ? to : null}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {startIcon ? (
        <ListItemIcon className={classes.listItemIcon}>
          {startIcon}
        </ListItemIcon>
      ) : null}

      <ListItemAvatar>
        <Box
          alignItems="center"
          display="flex"
          height="100%"
          maxHeight={MAX_IMAGE_SIZE}
          maxWidth={MAX_IMAGE_SIZE * imageAspectRatio}
          mr={2}
          position="relative"
          width="100%"
        >
          <LazyImage
            alt={`Main image for ${title}`}
            aspectRatio={imageAspectRatio}
            className={classes.imageArtwork}
            height="100%"
            src={imageArtworkUrl}
            width="100%"
          />
          {secondaryAction ? (
            <Box
              alignItems="center"
              className={classes.darkOverlay}
              display="flex"
              height="100%"
              justifyContent="center"
              left={0}
              position="absolute"
              top={0}
              width="100%"
            >
              <IconButton
                aria-label={secondaryAction.label}
                className={classes.secondaryActionIcon}
                onClick={handleSecondaryAction}
              >
                {React.cloneElement(secondaryAction.icon, {
                  fontSize: 'large',
                })}
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </ListItemAvatar>

      <ListItemText
        className={classes.listItemText}
        disableTypography={true}
        primary={
          <>
            {caption ? (
              <Typography color="textSecondary" noWrap={true} variant="caption">
                {caption}
              </Typography>
            ) : null}

            <Typography color="textPrimary" noWrap={true} variant="body1">
              {title}
            </Typography>

            {description ? (
              <Typography
                color="textSecondary"
                display="block"
                gutterBottom={true}
                noWrap={true}
                variant="caption"
              >
                {description}
              </Typography>
            ) : null}

            {actionChip || null}
          </>
        }
      />

      {to ? (
        <Box color="text.secondary">
          {openInNewTab ? (
            <OpenInNewIcon color="inherit" fontSize="small" />
          ) : (
            <ChevronRightIcon color="inherit" />
          )}
        </Box>
      ) : null}
    </ListItem>
  )
}

export const contentRowPropTypes = {
  /**
   * [Material-UI Chip](https://material-ui.com/api/chip/) props spread onto a Chip component to be used for a supplemental action. Action Chip will appear if these props are present.
   */
  actionChipProps: PropTypes.object,
  /**
   * Caption text. Concise, descriptive text about the content in the row.
   */
  caption: PropTypes.string,
  /**
   * Description text. This component will only display the first line, so it's recommended to pass in a substring of the entire description.
   */
  description: PropTypes.string,
  /**
   * Source URL for the image art.
   */
  imageArtworkUrl: PropTypes.string,
  /**
   * Aspect ratio of the image provided. This is necessary to properly display the image in its loading and loaded states. Defaults to `1 / 1`. For more information, see [CSS aspect-ratio property](https://developer.mozilla.org/en-US/docs/Web/CSS/aspect-ratio).
   */
  imageAspectRatio: PropTypes.number,
  /**
   * The row loading state is displayed.
   */
  isLoading: PropTypes.bool,
  /**
   * The row is selected.
   */
  isSelected: PropTypes.bool,
  /**
   * Link component, typically from React Router.
   */
  linkComponent: PropTypes.element,
  /**
   * Determines if the row will open the link in a new tab. This requires the `to` prop to be passed.
   */
  openInNewTab: PropTypes.bool,
  /**
   * Function to add the selected content to a collection. Providing the prop will create an overlay over the provided image artwork with an add icon button.
   */
  secondaryAction: PropTypes.shape({
    icon: PropTypes.element.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  /**
   * An icon component. For instance, a drag handle.
   */
  startIcon: PropTypes.element,
  /**
   * Title text for subject matter of the content in the row.
   */
  title: PropTypes.string.isRequired,
  /**
   * The URL path to open when the content row is clicked.
   */
  to: PropTypes.string,
}

ContentRow.propTypes = contentRowPropTypes

ContentRow.defaultProps = {
  actionChipProps: {},
  caption: null,
  description: null,
  imageArtworkUrl: null,
  imageAspectRatio: 1 / 1,
  isLoading: false,
  isSelected: false,
  linkComponent: undefined,
  openInNewTab: false,
  secondaryAction: undefined,
  startIcon: undefined,
  to: null,
}
