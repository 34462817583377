// Auto-Generated from OpenAPI Spec
package youversion.red.podcasts.api.model.episodes

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Episode(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  /**
   * Specific notes from the podcast creators pertaining to an episode.
   */
  @SerialName("description")
  @ProtoNumber(3)
  @JvmField
  val description: String? = null,
  /**
   * Unique id of show that episode belongs to.
   */
  @SerialName("show_id")
  @ProtoNumber(4)
  @JvmField
  val showId: Int? = null,
  /**
   * This is the length of the episode in seconds.
   */
  @SerialName("length")
  @ProtoNumber(5)
  @JvmField
  val length: Int? = null,
  /**
   * Date that the episode was published. Used to sort episodes inside a show (newest to oldest).
   */
  @SerialName("published_dt")
  @ProtoNumber(6)
  @JvmField
  val publishedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("audio_url")
  @ProtoNumber(7)
  @JvmField
  val audioUrl: String? = null,
  @SerialName("episode_art")
  @ProtoNumber(8)
  @JvmField
  val episodeArt: String? = null
) : red.Serializable {
  init {
    freeze()
  }
}
