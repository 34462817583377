// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.configuration

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze

@Serializable
data class AudioUrl(
  @SerialName("url")
  @ProtoNumber(1)
  @JvmField
  val url: String? = null,
  /**
   * file format
   */
  @SerialName("format")
  @ProtoNumber(2)
  @JvmField
  val format: String? = null,
  /**
   * Audio bitrate in bps
   */
  @SerialName("bitrate")
  @ProtoNumber(3)
  @JvmField
  val bitrate: Int? = null,
  /**
   * Audio sample rate in Hz
   */
  @SerialName("sample_rate")
  @ProtoNumber(4)
  @JvmField
  val sampleRate: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
