// Auto-Generated from OpenAPI Spec
package youversion.red.prayer.api.model

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PrayerUpdate(
  /**
   * Unique identifier
   */
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  /**
   * content of update.
   */
  @SerialName("message")
  @ProtoNumber(2)
  @JvmField
  val message: String? = null,
  /**
   * This is the date when the update was first created & available for users to consume.
   */
  @SerialName("created_dt")
  @ProtoNumber(3)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date when the prayer update was updated.
   */
  @SerialName("updated_dt")
  @ProtoNumber(4)
  @JvmField
  val updatedDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
