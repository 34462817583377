// Auto-Generated from OpenAPI Spec
package youversion.red.stories.api.model.lessons

import kotlin.Int
import kotlin.String
import kotlin.collections.List
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class Lesson(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: Int? = null,
  @SerialName("created_dt")
  @ProtoNumber(2)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("style")
  @ProtoNumber(3)
  @Serializable(with=youversion.red.stories.api.model.lessons.StyleSerializer::class)
  @JvmField
  val style: Style? = null,
  @SerialName("background_color_id")
  @ProtoNumber(4)
  @JvmField
  val backgroundColorId: Int? = null,
  @SerialName("foreground_color_id")
  @ProtoNumber(5)
  @JvmField
  val foregroundColorId: Int? = null,
  @SerialName("image_id")
  @ProtoNumber(6)
  @JvmField
  val imageId: Int? = null,
  @SerialName("status")
  @ProtoNumber(7)
  @Serializable(with=youversion.red.stories.api.model.lessons.StatusSerializer::class)
  @JvmField
  val status: Status? = null,
  @SerialName("references")
  @ProtoNumber(8)
  @JvmField
  val references: List<String>? = null,
  @SerialName("age_group")
  @ProtoNumber(9)
  @Serializable(with=youversion.red.stories.api.model.lessons.AgeGroupWithDefaultSerializer::class)
  @JvmField
  val ageGroup: AgeGroupWithDefault? = null,
  @SerialName("title")
  @ProtoNumber(10)
  @JvmField
  val title: String? = null,
  @SerialName("live_date")
  @ProtoNumber(11)
  @JvmField
  val liveDate: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  @SerialName("tertiary_color_id")
  @ProtoNumber(12)
  @JvmField
  val tertiaryColorId: Int? = null,
  @SerialName("language_tag")
  @ProtoNumber(13)
  @JvmField
  val languageTag: String? = null,
  @SerialName("crowdin_project_id")
  @ProtoNumber(14)
  @JvmField
  val crowdinProjectId: Int? = null,
  @SerialName("crowdin_file_id")
  @ProtoNumber(15)
  @JvmField
  val crowdinFileId: Int? = null
) : red.Serializable {
  init {
    freeze()
  }
}
