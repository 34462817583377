// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.admin.organizations

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class AdminFeaturedPlan(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String? = null,
  @SerialName("organization_id")
  @ProtoNumber(2)
  @JvmField
  val organizationId: String? = null,
  @SerialName("plan_id")
  @ProtoNumber(3)
  @JvmField
  val planId: Int = 0,
  /**
   * This is the date the featured plan started
   */
  @SerialName("start_dt")
  @ProtoNumber(4)
  @JvmField
  val startDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null,
  /**
   * This is the date the featured plan ended, or null if still active.
   */
  @SerialName("end_dt")
  @ProtoNumber(5)
  @JvmField
  val endDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
