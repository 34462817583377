package youversion.red.versification.service

import red.platform.Log
import red.platform.http.RequestManager
import red.platform.http.newURL
import red.tasks.CoroutineDispatchers.launch
import youversion.red.cache.CacheClearListener
import youversion.red.cache.CacheType

internal class VersificationCacheListener : CacheClearListener {

    override fun onClearCache(type: CacheType) {
        if (type == CacheType.VRS) {
            launch {
                Log.w("CacheListener", "Clearing cache: VRS")
                RequestManager.clearCache(newURL("${VersificationInternal.baseUrl}version_to_scheme_id.json"), false)
                for ((_, v) in VersificationInternal.vrsSchemeIds) {
                    if (v != "org") {
                        RequestManager.clearCache(newURL("${VersificationInternal.baseUrl}mappings/org_$v.json"), false)
                        RequestManager.clearCache(newURL("${VersificationInternal.baseUrl}mappings/${v}_org.json"), false)
                    }
                }
            }
        }
    }
}
