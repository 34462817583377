package youversion.red.banner.model.blocks

import kotlinx.serialization.KSerializer
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

enum class BlockAlignment {
    Leading,
    Center,
    Trailing
}

@SerialName("banner.Block")
interface BannerBlock : red.Serializable {

    companion object {
        @Suppress("unchecked_cast")
        fun serializer(): KSerializer<BannerBlock> = PolymorphicSerializer(BannerBlock::class)
    }
}
