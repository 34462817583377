package youversion.red.banner.service

import youversion.red.geoip.service.GeoIPService

interface ParameterResolver {

    fun resolve(name: String): String?
}

internal object InternalParameterResolver {

    private val geoIPService by GeoIPService()

    suspend fun resolve(name: String, type: String?): String? = when (name) {
        "country" -> geoIPService.getGeoIP()?.countryCode
        "state" -> geoIPService.getGeoIP()?.region
        "city" -> geoIPService.getGeoIP()?.city
        "gdpr" -> geoIPService.getGeoIP()?.gdpr?.let { if (it) "true" else "false" }
        "type" -> type
        else -> null
    }
}
