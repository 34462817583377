/**
 * @module Api4Client
 */

/* eslint-disable max-classes-per-file */
import {
  capitalize,
  getAPIEnvironment,
  isInBrowser,
  paramsToJSON,
  paramsToQuery,
} from '@youversion/utils'
import apiClient from 'core/clients/api-client'
// TODO: Should we couple model code here?
import { makeModel } from 'core/model-creators/make-model'
import * as Errors from '4.0/core/errors'

/**
 *
 * @param {object} config
 * @param {object} config.response
 * @param {string} config.url
 * @param {string} config.key
 * @param {Function} [config.customMakeModel]
 *
 * @returns {object} The response model object.
 */
function buildApiResponse({ response, url, key, customMakeModel = null }) {
  const finalMakeModel = customMakeModel || makeModel

  let data = null
  const {
    id: responseId,
    data: responseData,
    page_size: pageSize,
    next_page: nextPage,
    page: currentPage,
    ...remainingProps
  } = response

  // handle null data
  if (Array.isArray(responseData)) {
    // handle array of data
    data = responseData.map((item) => {
      // handle an object
      if (typeof item === 'object') {
        const { id: itemId, ...itemData } = item

        return finalMakeModel({
          name: `${key}::ResponseItem`,
          data: Object.keys(itemData).length ? itemData : { id: itemId },
          id: itemId,
        })
      }
      return item
    })
  } else if (typeof responseData === 'object') {
    // handle an object
    data = finalMakeModel({
      name: `${key}:Response`,
      id: responseId,
      data: responseData,
    })
  } else if (Object.keys(response).length === 0) {
    data = null
  } else {
    data = finalMakeModel({
      name: `${key}:Response`,
      id: responseId,
      data: remainingProps,
    })
  }

  return {
    $name: 'ApiResponse',
    $id: key,
    $url: url,
    $data: data,
    $pageSize: pageSize,
    $nextPage: Boolean(nextPage),
    $page: currentPage,
  }
}

/**
 * Determine the base API url to call.
 *
 * @param {string} subDomain
 *
 * @returns {string} The base API url to call.
 */
function getBaseUrl(subDomain) {
  if (getAPIEnvironment() === 'production') {
    return `https://${subDomain}.youversionapi.com`
  }
  return `https://${subDomain}.youversionapistaging.com`
}

function getUrl({ subDomain, version, path }) {
  return [getBaseUrl(subDomain), version, path].join('/')
}

function makeKey({ subDomain, path, version, method, auth }) {
  const authKey = auth ? 'WithAuth' : ''

  return `${capitalize(method)}::${getUrl({
    subDomain,
    version,
    path,
  })}::${authKey}`
}

/**
 * @typedef Api4ClientOptions
 * @property {object} [headers] - Overrides or adds to the default headers object.
 * @property {Array} [disableHeaders] - Array of keys to be removed from the headers object. Example `disableHeaders: ['Accept-Language'].
 *
 */
/**
 * Wrapper on top of fetch for YouVersion API 4.X calls.
 *
 * @param {object} config - The client configuration object.
 * @param {string} config.subDomain - The url subdomain variable: `https://${subdomain}.example.com/`.
 * @param {string} config.path - The url path variable: `https://subdomain.example.com/${path}.
 * @param {Api4ClientOptions} [config.options] - Passed through as fetch options.
 * @param {string} [config.token] - When true, pass Authorization header with YouVersion token.
 * @param {string} [config.version] - API version to use when building URLs.
 * @param {Function} [config.makeModel] - Custom makeModel function.
 *
 * @throws {Error} - Throws errors on invalid or error API responses.
 *
 * @returns {Promise<object>} The response model object.
 */
async function api4Client({
  subDomain,
  path,
  options = {},
  token = null,
  version = '4.0',
  makeModel: customMakeModel,
}) {
  const key = makeKey({
    subDomain,
    path: path.split('?')[0],
    method: options.method,
    auth: Boolean(token),
    version,
  })

  const headers = {
    'Accept-Language': 'en',
    Accept: 'application/json',
    'X-YouVersion-App-Version': '3',
    'X-YouVersion-Client': 'youversion',
    'X-YouVersion-App-Platform': 'web',
    'Content-Type': 'application/json',
    ...options.headers,
  }

  if (options.disableHeaders && Array.isArray(options.disableHeaders)) {
    options.disableHeaders.forEach((itemKey) => {
      delete headers[itemKey]
    })
  }

  if (!isInBrowser()) {
    headers.Referer = 'https://web.youversionapi.com'
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  const fetchOptions = {
    ...options,
    headers,
  }

  const url = getUrl({ subDomain, version, path })
  const response = await apiClient({
    url,
    options: fetchOptions,
  })

  if (response.status === 204)
    return buildApiResponse({ response: {}, url, key })

  let json
  try {
    json = await response.json()
  } catch (e) {
    throw new Errors.InvalidResponseError(url)
  }

  if (response.status === 404) throw new Errors.NotFoundError(url, json)

  if (response.status === 409) throw new Errors.ConflictError(url, json)

  if (response.status >= 500) throw new Errors.ServerError(url, json)

  if (response.status !== 200) throw new Errors.ApiError(url, json)

  return buildApiResponse({ response: json, url, key, customMakeModel })
}

/**
 * Make a GET call to YouVersion 4.X API.
 *
 * @param {object} config
 * @param {string} config.subDomain
 * @param {string} config.path
 * @param {object} [config.params] - GET params, will be converted to valid query string with support for bracket-less arrays.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {string} [config.token] - The YouVersion JWT access token.
 * @param {string} [config.version] - API version to use when building URLs.
 * @param {Function} [config.makeModel] - Custom makeModel function.
 *
 * @returns {Promise<object>} The response model object.
 */
export async function get({
  path,
  params = {},
  options = {},
  ...passThroughProps
}) {
  const queryParams = paramsToQuery(params)
  return api4Client({
    path: `${path}${queryParams.length > 0 ? `?${queryParams}` : ''}`,
    options: {
      ...options,
      method: 'GET',
    },
    ...passThroughProps,
  })
}

/**
 * Make a POST call to YouVersion 4.X API.
 *
 * @param {object} config
 * @param {string} config.subDomain
 * @param {string} config.path
 * @param {object} [config.params] - POST body params, will be converted to JSON encoded string and added to request body.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {string} [config.token] - The YouVersion JWT access token.
 * @param {string} [config.version] - API version to use when building URLs.
 * @param {Function} [config.makeModel] - Custom makeModel function.
 *
 * @returns {Promise<object>} The response model object.
 */
export async function post({ params = {}, options = {}, ...passThroughProps }) {
  return api4Client({
    options: {
      ...options,
      method: 'POST',
      body: paramsToJSON(params),
    },
    ...passThroughProps,
  })
}

/**
 * Make a PUT call to YouVersion 4.X API.
 *
 * @param {object} config
 * @param {string} config.subDomain
 * @param {string} config.path
 * @param {object} [config.params] - PUT body params, will be converted to JSON encoded string and added to request body.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {string} [config.token] - The YouVersion JWT access token.
 * @param {string} [config.version] - API version to use when building URLs.
 * @param {Function} [config.makeModel] - Custom makeModel function.
 *
 * @returns {Promise<object>} The response model object.
 */
export async function put({ params = {}, options = {}, ...passThroughProps }) {
  return api4Client({
    options: {
      ...options,
      method: 'PUT',
      body: paramsToJSON(params),
    },
    ...passThroughProps,
  })
}

/**
 * Make a DELETE call to YouVersion 4.X API.
 *
 * @param {object} config
 * @param {string} config.subDomain
 * @param {string} config.path
 * @param {object} [config.params] - DELETE params, will be converted to valid query string with support for bracket-less arrays.
 * @param {object} [config.options] - Passed through as fetch options.
 * @param {string} [config.token] - The YouVersion JWT access token.
 * @param {string} [config.version] - API version to use when building URLs.
 * @param {Function} [config.makeModel] - Custom makeModel function.
 *
 * @returns {Promise<object>} The response model object.
 */
export async function del({
  path,
  params = {},
  options = {},
  ...passThroughProps
}) {
  return api4Client({
    path: `${path}?${paramsToQuery(params)}`,
    options: {
      ...options,
      method: 'DELETE',
    },
    ...passThroughProps,
  })
}
