package youversion.red.prayer.tasks

import com.squareup.sqldelight.internal.AtomicBoolean
import red.platform.Log
import red.platform.LogLevel
import red.platform.currentTimeMillis
import red.platform.io.IOException
import red.platform.newDate
import red.platform.settings.Settings
import red.platform.toDateTime
import red.platform.toLong
import red.tasks.assertNotMainThread
import youversion.red.prayer.api.PrayerApi
import youversion.red.prayer.api.model.PrayerAnswer
import youversion.red.prayer.api.model.StatusType
import youversion.red.prayer.ext.toDb
import youversion.red.prayer.model.Prayer as DbPrayer
import youversion.red.prayer.service.PrayerStore
import youversion.red.prayer.util.PrayerUtil.STATE_DIRTY

object PrayersSync {

    private val syncing = AtomicBoolean(false)
    private const val CACHE_TIME = 1200000L // todo: tweak?
    private fun getLastSyncKey(type: Int): String {
        return "red.prayer.prayers.$type.last.sync"
    }

    private fun getLastSyncKey(type: Int, page: Int): String {
        return "red.prayer.prayers.$type.last.sync.page.$page"
    }

    private const val PAGE_SIZE = 25

    suspend fun process(
        page: Int = 1,
        type: Int = TYPE_PRAYER_LIST,
        force: Boolean = false
    ): Boolean {
        assertNotMainThread()
        requireNetwork { return false }
        requireUser { return false }

        var nextPage = false
        syncing.sync {

            PrayerChangesSync.process()

            val updatePrayers = mutableListOf<DbPrayer>()

            try {
                if (type == TYPE_PRAYER_LIST) {
                    if (Log.level == LogLevel.DEBUG)
                        Log.i("red-prayer", "syncing prayer list")
                    PrayerIdsSync.process(force)

                    val prayersNeedingSyncing =
                        PrayerStore.getPrayersNeedingUpdateSync(
                            if (force)
                                newDate()
                            else
                                newDate((currentTimeMillis().toLong() - CACHE_TIME).toDateTime())
                        )
                    if (Log.level == LogLevel.DEBUG)
                        Log.i(
                            "red-prayer",
                            "${prayersNeedingSyncing.size} prayers needing syncing: $prayersNeedingSyncing"
                        )

                    if (prayersNeedingSyncing.isEmpty())
                        return@sync

                    val pagesOfIds = prayersNeedingSyncing
                        .filter { it.serverId != null }
                        .chunked(PAGE_SIZE)
                    pagesOfIds.forEach {
                        val ids = it.joinToString(",") { p -> p.serverId!!.toString() }
                        val prayers = PrayerApi.getPrayers(ids = ids)
                        prayers?.data?.forEach {
                            try {
                                val p = prayersNeedingSyncing.find { dp -> dp.serverId == it.id }
                                requireNotNull(p) {
                                    "couldn't match prayer from api with db $it"
                                }
                                if (p.state.and(STATE_DIRTY) != STATE_DIRTY) {
                                    val updated = it.toDb(p).copy(
                                        lastSync = newDate()
                                    )
                                    updatePrayers.add(updated)
                                } else
                                    Log.i(
                                        "red-prayer",
                                        "prayer still has changes syncing, we're not going to overwrite it from the server: $p"
                                    )
                            } catch (e: Exception) {
                                Log.e("red-prayer", "error syncing prayers, prayer $it", e)
                            }
                        }
                    }
                } else {
                    val lastSyncKey = getLastSyncKey(type, page)
                    ensureNotCached(lastSyncKey, CACHE_TIME, force) {
                        if (Log.level == LogLevel.DEBUG)
                            Log.i("red-prayer", "prayers are cached, returning")
                        return false
                    }

                    val prayers = PrayerApi.getPrayers(
                        page = page,
                        status = if (type == TYPE_ARCHIVED) StatusType.ARCHIVED else StatusType.ALL,
                        answer = if (type == TYPE_ANSWERED) PrayerAnswer.ANSWERED else PrayerAnswer.ALL,
                        pageSize = PAGE_SIZE
                    )
                    nextPage = prayers?.nextPage == true
                    prayers?.data?.forEach {
                        val oldPrayer = PrayerStore.getPrayerByServerIdSync(it.id!!)
                        if (oldPrayer?.state?.and(STATE_DIRTY) != STATE_DIRTY) {
                            val updated = it.toDb(oldPrayer).copy(
                                lastSync = newDate()
                            )
                            updatePrayers.add(updated)
                        } else
                            Log.i(
                                "red-prayer",
                                "prayer still has changes syncing, we're not going to overwrite it from the server: $oldPrayer"
                            )
                    }

                    Settings.defaultSettings.edit().putLong(lastSyncKey, currentTimeMillis().toLong())
                        .commit()
                }
            } catch (e: Exception) {
                // TODO:
                Log.e("red-prayer", "error syncing prayers page: $page", e)
                @Suppress("DEPRECATION_ERROR")
                if (e !is IOException) {
                    throw e
                }
            }

            PrayerStore.process(updatePrayers)
        }

        return nextPage
    }

    internal fun clearCache() {
        val editor = Settings.defaultSettings.edit()
        editor.remove(getLastSyncKey(TYPE_ANSWERED))
        editor.remove(getLastSyncKey(TYPE_ARCHIVED))
        Settings.defaultSettings.keys.filter {
            it.startsWith(getLastSyncKey(TYPE_ARCHIVED)) || it.startsWith(getLastSyncKey(TYPE_ANSWERED))
        }.forEach {
            editor.remove(it)
        }
        editor.commit()
    }

    const val TYPE_PRAYER_LIST = 0
    const val TYPE_ANSWERED = 1
    const val TYPE_ARCHIVED = 2
}
