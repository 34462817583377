package youversion.red.banner.service

import red.platform.Log
import red.platform.currentTimeMillis
import red.platform.settings.Settings
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import red.platform.toLong
import red.service.DefaultService
import red.tasks.CoroutineDispatchers.launch
import youversion.red.banner.api.BannerApi
import youversion.red.banner.model.Banner
import youversion.red.banner.model.BannerConfiguration
import youversion.red.banner.model.BannerId
import youversion.red.banner.model.BannerServerConfiguration
import youversion.red.banner.model.BannerServerConfigurations
import youversion.red.banner.model.Parameter
import youversion.red.banner.model.ParameterSchema

@DefaultService(IBannerService::class)
internal open class BannerServiceImpl : IBannerService {

    private val _parameterResolver = AtomicReference<ParameterResolver?>(null)

    override var parameterResolver: ParameterResolver?
        get() = _parameterResolver.value
        set(value) {
            _parameterResolver.setAssertTrue(value)
        }

    private suspend fun getParameters(type: String?): List<Parameter<*>> =
        ParameterSchema.parameterKeys.mapNotNull { key ->
            try {
                InternalParameterResolver.resolve(key, type)?.let {
                    Parameter(key, it)
                } ?: parameterResolver?.resolve(key)?.let { Parameter(key, it) }
            } catch (e: Exception) {
                Log.e("BannerService", "Failed to resolve parameter: $key", e)
                null
            }
        }

    override suspend fun getConfiguration(): BannerConfiguration =
        BannerApi.getConfiguration() ?: error("Failed to get configuration")

    override suspend fun getBanners() = BannerApi.getBanners() ?: BannerServerConfigurations()
    override suspend fun addBanner(banner: BannerServerConfiguration) =
        BannerApi.addBanner(banner) ?: error("Error adding banner")

    override suspend fun editBanner(banner: BannerServerConfiguration) {
        BannerApi.editBanner(banner)
    }

    override suspend fun deleteBanner(id: BannerId) {
        BannerApi.deleteBanner(id)
    }

    protected open suspend fun findBanner(parameters: List<Parameter<*>>) = BannerApi.findBanner(parameters)

    override suspend fun findBanner(type: String?): Banner? {
        val banner = findBanner(getParameters(type))
        banner?.let {
            Settings
                .defaultSettings
                .edit()
                .putInt(it.id.toIndexKey(), it.index)
                .putLong(it.id.toIndexCreatedKey(), currentTimeMillis().toLong())
                .commit()
            cleanUpKeys()
        }
        return banner
    }

    private fun String.toIndexKey() = "banner.$this.index"
    private fun String.toIndexCreatedKey() = "banner.$this.created"
    private fun cleanUpKeys() {
        launch {
            Settings
                .defaultSettings
                .keys
                .forEach {
                    if (it.startsWith("banner.") && it.endsWith(".created")) {
                        val created = Settings.defaultSettings.getLong(it)
                        if (created + 2628000000L < currentTimeMillis().toLong()) {
                            var id = it.substring("banner.".length)
                            id = id.substring(0, id.length - ".created".length)
                            Settings
                                .defaultSettings
                                .edit()
                                .remove(it)
                                .remove(id.toIndexKey())
                                .commit()
                        }
                    }
                }
        }
    }

    internal fun getBannerIndex(id: String) = Settings.defaultSettings.getInt(id.toIndexKey())

    // KJB: NOTE: It is possible that parameters passed here aren't equal to the parameters sent to fetch the original banner.
    //            But, for now this is the easiest thing to do... so, until this whole thing is proven out... this is what I'm going to do
    override suspend fun click(id: String, type: String?) {
        val index = getBannerIndex(id)
        BannerApi.click(id, index, getParameters(type))
    }

    override suspend fun impression(id: String, type: String?) {
        val index = getBannerIndex(id)
        BannerApi.impression(id, index, getParameters(type))
    }

    override suspend fun dismiss(id: String, type: String?) {
        val index = getBannerIndex(id)
        BannerApi.dismiss(id, index, getParameters(type))
    }
}
