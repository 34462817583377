package youversion.red.prayer.db

import com.squareup.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String
import red.platform.Date

public data class Prayer_share(
  public val id: Int,
  public val prayerServerId: Int?,
  public val prayerClientId: String,
  public val createdDt: Date?,
  public val message: String?,
  public val receiverId: Long?,
  public val senderId: Long?
) {
  public override fun toString(): String = """
  |Prayer_share [
  |  id: $id
  |  prayerServerId: $prayerServerId
  |  prayerClientId: $prayerClientId
  |  createdDt: $createdDt
  |  message: $message
  |  receiverId: $receiverId
  |  senderId: $senderId
  |]
  """.trimMargin()

  public class Adapter(
    public val createdDtAdapter: ColumnAdapter<Date, Long>
  )
}
