package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue
import youversion.red.prayer.module.PrayerModuleInitializer

public object RedPrayerModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: PrayerModuleInitializer = PrayerModuleInitializer()

  public override val name: String = "prayer"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    ModuleRegistry["core"].registerServiceFactories()
    
    ModuleRegistry["analytics"].registerServiceFactories()
    red.service.ServiceRegistry.setFactoryIfNotSet(youversion.red.prayer.service.IPrayersService::class)
        { youversion.red.prayer.service.PrayersServiceImpl() }
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    ModuleRegistry["core"].initialize()
    
    ModuleRegistry["analytics"].initialize()
    initializer.initialize()
    
  }
}
