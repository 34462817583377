package youversion.red.prayer.guided.db

import com.squareup.sqldelight.EnumColumnAdapter
import com.squareup.sqldelight.db.SqlDriver
import red.database.DateAdapter

fun createQueryWrapper(driver: SqlDriver) =
    GuidedPrayerDatabase(
        driver = driver,
        guided_prayer_dayAdapter = Guided_prayer_day.Adapter(DateAdapter()),
        guided_prayer_moduleAdapter = Guided_prayer_module.Adapter(EnumColumnAdapter())
    )

object Schema : SqlDriver.Schema by GuidedPrayerDatabase.Schema {
    override fun create(driver: SqlDriver) {
        GuidedPrayerDatabase.Schema.create(driver)
        createQueryWrapper(driver)
    }
}
