package youversion.red.plans.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber

@Serializable
data class RecommendedCollection(
    @SerialName("reading_plans")
    val plans: List<RecommendedPlanView>? = null
)

@Serializable
data class RecommendedPlanView(
    val id: Int,
    @SerialName("short_url")
    val shortUrl: String? = null,
    val images: List<PlanImage>? = null,
    @SerialName("total_days")
    val totalDays: String? = null,
    @SerialName("created_dt")
    val createdDt: String? = null,
    @SerialName("publisher_url")
    val publisherUrl: String? = null,
    val type: String? = null,
    @SerialName("version_id")
    val versionId: String? = null,
    @SerialName("default_start_dt")
    val defaultStartDt: String? = null,
    val slug: String? = null,
    val about: PlansRecommendedAbout? = null,
    @SerialName("formatted_length")
    val formattedLength: PlansDefault? = null,
    val name: PlansDefault? = null,
    val token: String? = null,
    val copyright: PlansRecommendedCopyright? = null,
    @SerialName("updated_dt")
    val updatedDt: String? = null,
    @SerialName("language_tag")
    val languageTag: String? = null,
    val private: Boolean = false
)

@Serializable
data class PlansRecommendedCopyright(
    @ProtoNumber(1) val text: PlansDefault? = null,
    @ProtoNumber(2) val html: PlansDefault? = null
)

@Serializable
data class PlansRecommendedAbout(
    @ProtoNumber(1) val text: PlansDefault? = null,
    @ProtoNumber(2) val html: PlansDefault? = null
)

@Serializable
data class PlansDefault(
    val default: String? = null
)

@Serializable
internal data class PlansRecommendedUserAvatarUrl(
    @SerialName("px_24x24")
    val px24x24: String? = null,
    @SerialName("px_48x48")
    val px48x48: String? = null,
    @SerialName("px_128x128")
    val px128x128: String? = null,
    @SerialName("px_512x512")
    val px512x512: String? = null
)
