package youversion.red.podcasts.model

import kotlinx.serialization.Serializable
import red.platform.threads.freeze

@Serializable
data class PodcastEpisodes(
    val data: List<PodcastEpisode>? = null,
    val pageSize: Int? = null,
    val nextPage: Boolean? = null
) : red.Serializable {

    init {
        freeze()
    }
}
