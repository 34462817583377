// Auto-Generated from OpenAPI Spec
package youversion.red.moments.deeplink

import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object MomentsDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides")) {
  private val _listener: AtomicReference<MomentsDeepLinkListener?> = AtomicReference(null)

  var listener: MomentsDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/") { context, _, _ -> 
        listener.onHome(context)
    }
    register("/moments") { context, _, _ -> 
        listener.onMoments(context)
    }
    register("/app") { context, _, _ -> 
        listener.onApp(context)
    }
    freeze()
  }
}
