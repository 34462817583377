// Auto-Generated from OpenAPI Spec
package youversion.red.organizations.api.model.organizations

import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.threads.freeze
import youversion.red.organizations.api.model.images.PastorImage

@Serializable
data class Pastor(
  @SerialName("id")
  @ProtoNumber(1)
  @JvmField
  val id: String = "",
  @SerialName("name")
  @ProtoNumber(2)
  @JvmField
  val name: String = "",
  @SerialName("church_profile_id")
  @ProtoNumber(3)
  @JvmField
  val churchProfileId: String = "",
  @SerialName("title")
  @ProtoNumber(4)
  @JvmField
  val title: String = "",
  @SerialName("image")
  @ProtoNumber(5)
  @JvmField
  val image: PastorImage
) : red.Serializable {
  init {
    freeze()
  }
}
