package youversion.red.images.module

import red.module.IModuleInitializer
import red.module.ModuleDependencies

@ModuleDependencies("core")
class ImagesModuleInitializer : IModuleInitializer {

    override fun initialize() {
    }
}
