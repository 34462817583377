/**
 * @module getNewSize
 */

/**
 * @typedef ImageDimensionObject.
 * @param {number} defaultHeight - The default height of the image.
 * @param {number} defaultWidth - The default width of the image.
 * @param {number} newHeight - The desired height of the image.
 * @param {number} newWidth - The desired width of the image.
 */

/**
 * Returns an object with larger or smaller proportionate dimensions of height and width.
 * Either newHeight or newWidth can be provided, but one must be provided.
 * If both are provided, the function will use newHeight as the basis for the size, regardless of the value of newWidth.
 *
 * @alias module:getNewSize
 *
 * @param {ImageDimensionObject} param - The ImageDimensionObject element.
 *
 * @returns {object} - Returns an object with keys of height and width with values proportional to the default height and width.
 *
 * @example
 * // returns {height: 10, width: 10}
 * getNewSize({defaultHeight: 2, defaultWidth: 2, newHeight: 10})
 * getNewSize({defaultHeight: 2, defaultWidth: 2, newHeight: 10, newWidth: 1000})
 * getNewSize({defaultHeight: 2, defaultWidth: 2, newWidth: 10})
 */
export function getNewSize({
  defaultHeight,
  defaultWidth,
  newHeight,
  newWidth,
}) {
  let finalHeight
  let finalWidth

  if (typeof newHeight !== 'undefined') {
    const aspectRatio = defaultWidth / defaultHeight
    finalHeight = newHeight
    finalWidth = finalHeight * aspectRatio
  } else {
    const aspectRatio = defaultWidth / defaultHeight
    finalWidth = newWidth
    finalHeight = newWidth * aspectRatio
  }

  return {
    height: Math.floor(finalHeight),
    width: Math.floor(finalWidth),
  }
}
