// Auto-Generated from OpenAPI Spec
package youversion.red.movies.deeplink

import red.platform.http.query
import red.platform.threads.AtomicReference
import red.platform.threads.freeze
import red.platform.threads.setAssertTrue
import youversion.red.deeplink.AbstractDeepLinkHandler

object MoviesDeepLinkHandler : AbstractDeepLinkHandler(listOf("bible.com", "www.bible.com",
    "app.bible.com", "click.bible.com", "staging.bible.com", "youversion.com", "prayers", "stories",
    "guides")) {
  private val _listener: AtomicReference<MoviesDeepLinkListener?> = AtomicReference(null)

  var listener: MoviesDeepLinkListener
    get() = _listener.value ?: throw NullPointerException("listener not set")
    set(value) {
      _listener.setAssertTrue(value)
      isConfigured = true
    }

  init {
    register("/videos/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        val slug = values[2]
        listener.onVideoWithSlug(context, id, slug, collection)
    }
    register("/videos/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onVideo(context, id, collection)
    }
    register("/videos/$INTEGER_EXPRESSION/series") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onVideoSeries(context, id, collection)
    }
    register("/videos/collections/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        val slug = values[2]
        listener.onCollectionWithSlug(context, id, slug, collection)
    }
    register("/videos/collections/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onCollection(context, id, collection)
    }
    register("/videos/publishers/$INTEGER_EXPRESSION-$STRING_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        val slug = values[2]
        listener.onPublisherWithSlug(context, id, slug, collection)
    }
    register("/videos/publishers/$INTEGER_EXPRESSION") { context, parts, url -> 
        val values = parts.first().groupValues
        val queries = red.platform.http.QueryStringParser.parse(url.query)
        val collection = queries["collection"]?.firstOrNull()?.toIntOrNull()
        val id = values[1].toIntOrNull() ?: return@register false
        listener.onPublisher(context, id, collection)
    }
    freeze()
  }
}
