// Auto-Generated from OpenAPI Spec
package youversion.red.movies.api.model.collections

import kotlin.Int
import kotlin.String
import kotlin.jvm.JvmField
import kotlinx.serialization.PolymorphicSerializer
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.protobuf.ProtoNumber
import red.platform.Date
import red.platform.threads.freeze

@Serializable
data class PostCollection(
  @SerialName("id")
  @ProtoNumber(6)
  @JvmField
  val id: Int? = null,
  @SerialName("title")
  @ProtoNumber(2)
  @JvmField
  val title: String? = null,
  @SerialName("description")
  @ProtoNumber(1)
  @JvmField
  val description: String? = null,
  @SerialName("language")
  @ProtoNumber(4)
  @JvmField
  val language: String? = null,
  @SerialName("start")
  @ProtoNumber(7)
  @JvmField
  val start: Int? = null,
  @SerialName("end")
  @ProtoNumber(8)
  @JvmField
  val end: Int? = null,
  @SerialName("created_dt")
  @ProtoNumber(5)
  @JvmField
  val createdDt: @Serializable(with=red.platform.DateSerializer::class) Date? = null
) : red.Serializable {
  init {
    freeze()
  }
}
