package red.module

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import red.module.IModule
import red.module.ModuleRegistry
import red.platform.threads.AtomicReference
import red.platform.threads.setAssertTrue

public object RedRedFrameworkModule : IModule {
  private val factoriesRegistered: AtomicReference<Boolean> = AtomicReference(false)

  private val initialized: AtomicReference<Boolean> = AtomicReference(false)

  private val initializer: RedModuleInitializer = RedModuleInitializer()

  public override val name: String = "red-framework"

  public override fun registerServiceFactories(): Unit {
    if (factoriesRegistered.value) return
    factoriesRegistered.setAssertTrue(true)
    ModuleRegistry["blue"].registerServiceFactories()
    
    ModuleRegistry["core"].registerServiceFactories()
    
    ModuleRegistry["core-google"].registerServiceFactories()
    
    ModuleRegistry["achievements"].registerServiceFactories()
    
    ModuleRegistry["analytics"].registerServiceFactories()
    
    ModuleRegistry["analytics-dataman"].registerServiceFactories()
    
    ModuleRegistry["banner"].registerServiceFactories()
    
    ModuleRegistry["bible"].registerServiceFactories()
    
    ModuleRegistry["bible-shared"].registerServiceFactories()
    
    ModuleRegistry["downloads"].registerServiceFactories()
    
    ModuleRegistry["geoip"].registerServiceFactories()
    
    ModuleRegistry["giving"].registerServiceFactories()
    
    ModuleRegistry["images"].registerServiceFactories()
    
    ModuleRegistry["installation"].registerServiceFactories()
    
    ModuleRegistry["login"].registerServiceFactories()
    
    ModuleRegistry["moments"].registerServiceFactories()
    
    ModuleRegistry["moments-shared"].registerServiceFactories()
    
    ModuleRegistry["movies"].registerServiceFactories()
    
    ModuleRegistry["plans"].registerServiceFactories()
    
    ModuleRegistry["prayer"].registerServiceFactories()
    
    ModuleRegistry["search"].registerServiceFactories()
    
    ModuleRegistry["settings"].registerServiceFactories()
    
    ModuleRegistry["stories"].registerServiceFactories()
    
    ModuleRegistry["stories-shared"].registerServiceFactories()
    
    ModuleRegistry["users"].registerServiceFactories()
    
    ModuleRegistry["versification"].registerServiceFactories()
    
    ModuleRegistry["podcasts"].registerServiceFactories()
    
    ModuleRegistry["podcasts-shared"].registerServiceFactories()
    
    ModuleRegistry["locales"].registerServiceFactories()
    
  }

  public override fun initialize(): Unit {
    if (initialized.value) return
    initialized.setAssertTrue(true)
    registerServiceFactories()
    ModuleRegistry["blue"].initialize()
    
    ModuleRegistry["core"].initialize()
    
    ModuleRegistry["core-google"].initialize()
    
    ModuleRegistry["achievements"].initialize()
    
    ModuleRegistry["analytics"].initialize()
    
    ModuleRegistry["analytics-dataman"].initialize()
    
    ModuleRegistry["banner"].initialize()
    
    ModuleRegistry["bible"].initialize()
    
    ModuleRegistry["bible-shared"].initialize()
    
    ModuleRegistry["downloads"].initialize()
    
    ModuleRegistry["geoip"].initialize()
    
    ModuleRegistry["giving"].initialize()
    
    ModuleRegistry["images"].initialize()
    
    ModuleRegistry["installation"].initialize()
    
    ModuleRegistry["login"].initialize()
    
    ModuleRegistry["moments"].initialize()
    
    ModuleRegistry["moments-shared"].initialize()
    
    ModuleRegistry["movies"].initialize()
    
    ModuleRegistry["plans"].initialize()
    
    ModuleRegistry["prayer"].initialize()
    
    ModuleRegistry["search"].initialize()
    
    ModuleRegistry["settings"].initialize()
    
    ModuleRegistry["stories"].initialize()
    
    ModuleRegistry["stories-shared"].initialize()
    
    ModuleRegistry["users"].initialize()
    
    ModuleRegistry["versification"].initialize()
    
    ModuleRegistry["podcasts"].initialize()
    
    ModuleRegistry["podcasts-shared"].initialize()
    
    ModuleRegistry["locales"].initialize()
    initializer.initialize()
    
  }
}
