package youversion.red.moments.model

import youversion.red.dataman.api.model.MomentType

enum class MomentKind {
    UNKNOWN,
    BOOKMARK,
    HIGHLIGHT,
    NOTE,
    IMAGE;

    val type: MomentType
        get() = when (this) {
            HIGHLIGHT -> MomentType.HIGHLIGHT
            NOTE -> MomentType.NOTE
            IMAGE -> MomentType.IMAGE
            BOOKMARK -> MomentType.BOOKMARK
            UNKNOWN -> error("Unsupported type")
        }
}
